import React from 'react';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from '../../components/common/Button';
import { CopyIcon } from '../../components/icons/CopyIcon';
import { UrlLink } from '../../components/UrlLink';
import { SeriesVisibilityOption } from '../../components/SeriesVisibilitySelector.jsx';
import { PencilIcon } from '../../components/icons/PencilIcon';
import classNames from '../../helper/classNames';
import { useMeetingInformation } from '../../hooks/useMeetingInformation';
import RichTextMeetingTitle from './RichTextMeetingTitle';
import { ManageMeetingTemplateModal } from '../../components/ManageMeetingTemplateModal';
import { HighlightIcon } from '../../components/icons/HighlightIcon';
import { MovieFilmIcon } from '../../components/icons/MovieFilmIcon';

export const MeetingInformation = React.forwardRef(
  (
    {
      meetingTitle,
      description,
      meetingSeriesUrl,
      visibility,
      series,
      meetingSeriesId,
      localDispatch,
      boxToShow,
      setBoxToShow,
      boxRefs,
      meetingList,
      workspaceId,
      mainBoxRef,
      hasHighlights,
      showModeratorData,
    },
    ref
  ) => {
    const useMeetingInfoArgs = {
      meetingTitle,
      description,
      series,
      meetingSeriesId,
      localDispatch,
    };
    const {
      handleClickPreview,
      updatePreviewMeetingCompleted,
      goToMeetingButtonText,
      previewMeetingCompleted,
      showPreviewInstructions,
      isCurrentUserMeetingController,
      previewInstructionsModalOpen,
      setPreviewInstructionsModalOpen,
      user,
      meetingPreviewButtonText,
      PreviewInstructionsModal,
      handleGoToMeeting,
      updateMeetingTitle,
      joinClicked,
      isEditingTitle,
      setIsEditingTitle,
      handleEditTitle,
      allowForceTerminateMeeting,
      allowTemplateManagement,
      handleMeetingForceTermination,
      showCreateMeetingTemplateModal,
      setShowCreateMeetingTemplateModal,
      showUpdateMeetingTemplateModal,
      setShowUpdateMeetingTemplateModal,
      userId,
      autoLaunchConfig,
    } = useMeetingInformation(useMeetingInfoArgs);

    const { meetingId } = useParams();

    const handleSummaryPageClick = () => {
      meetingList?.length && history.push(`/e/${meetingSeriesId}/highlights`);
    };

    const handleMeetingPageClick = () => {
      history.push(`/e/${meetingSeriesId}?workspace=${workspaceId}`);
    };

    const history = useHistory();
    if (joinClicked) {
      return <Redirect to={`/e/${series.meetingSeriesId}/live`} />;
    }

    const meetingControlButtons = (
      <div className="absolute -bottom-10 right-0 z-[1] flex gap-3">
        {allowTemplateManagement && (
          <>
            <Button
              color={Button.colors.PURPLE}
              onClick={() => setShowCreateMeetingTemplateModal(true)}
              padding={Button.padding.SMALL}
            >
              Create template
            </Button>
            <Button
              color={Button.colors.PURPLE}
              onClick={() => setShowUpdateMeetingTemplateModal(true)}
              padding={Button.padding.SMALL}
            >
              Update {autoLaunchConfig.templateName} template
            </Button>
          </>
        )}
        {allowForceTerminateMeeting && (
          <Button
            color={Button.colors.PURPLE}
            type="submit"
            name="force_end"
            onClick={handleMeetingForceTermination}
            padding={Button.padding.SMALL}
          >
            Force terminate
          </Button>
        )}
      </div>
    );

    return (
      <>
        {meetingId && (
          <div className="w-full text-left -mb-16">
            <div className="bg-white ml-5 max-w-fit">
              <div className="flex bg-purple bg-opacity-[5%] max-w-fit space-x-3 py-2 text-black text-opacity-60 rounded-t-md px-5 font-bold text-sm items-center">
                <div
                  onClick={handleMeetingPageClick}
                  className={classNames(
                    'cursor-pointer text-md flex items-center space-x-1 text-[#696F8C]'
                  )}
                >
                  <MovieFilmIcon width="20" height="20" color="#696F8C" />
                  <span>Meeting</span>
                </div>
                <div className="font-normal">&#62;</div>
                <div
                  onClick={handleSummaryPageClick}
                  className="cursor-pointer"
                >
                  <div
                    className={classNames(
                      'cursor-pointer text-md flex items-center space-x-1 text-purple'
                    )}
                  >
                    <HighlightIcon color="#8B33F7" width="14" height="14" />
                    <span>Highlights</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="md:fixed left-0 top-0 bg-gradient-to-b h-1/4 from-purple/95 to-white w-full z-10" />

        <section
          ref={ref}
          className="flex flex-col w-full md:sticky md:top-[5.5rem] mt-[0.5rem] z-20"
        >
          {meetingControlButtons}
          {showCreateMeetingTemplateModal && (
            <ManageMeetingTemplateModal
              onCancel={() => setShowCreateMeetingTemplateModal(false)}
              autoLaunchConfig={autoLaunchConfig}
              meetingSeriesId={meetingSeriesId}
              userId={userId}
              brandKit={series?.workspace?.brandKit}
            />
          )}
          {showUpdateMeetingTemplateModal && (
            <ManageMeetingTemplateModal
              onCancel={() => setShowUpdateMeetingTemplateModal(false)}
              template={autoLaunchConfig}
              meetingSeriesId={meetingSeriesId}
              userId={userId}
              brandKit={series?.workspace?.brandKit}
            />
          )}
          <div className="bg-purple rounded-l-2xl rounded-r-3xl max-w-full pl-5 shadow-2xl flex flex-col">
            <div className="flex flex-col md:flex-row w-full bg-white rounded-r-2xl p-5">
              {previewInstructionsModalOpen && (
                <PreviewInstructionsModal
                  onCancel={() => setPreviewInstructionsModalOpen(false)}
                  user={user}
                  handleClickPreview={handleClickPreview}
                  meetingPreviewButtonText={meetingPreviewButtonText}
                />
              )}
              {/* Left Section (Title, Description, Components) */}
              <div className="flex-[1_0_0%]">
                <div className="space-y-5">
                  <RichTextMeetingTitle
                    setIsEditingTitle={setIsEditingTitle}
                    isEditingTitle={isEditingTitle}
                    updateMeetingTitle={updateMeetingTitle}
                    meetingTitle={meetingTitle}
                    trigger={
                      <div
                        className="max-w-fit cursor-pointer pl-3"
                        onClick={handleEditTitle}
                      >
                        <PencilIcon
                          className={
                            isEditingTitle ? 'fill-purple' : 'fill-blue-gray'
                          }
                        />
                      </div>
                    }
                  />

                  <div className="flex justify-center space-x-4 md:pb-3">
                    <div className="w-full md:mr-0">
                      <Button
                        onClick={handleGoToMeeting}
                        color={
                          !isCurrentUserMeetingController ||
                          previewMeetingCompleted
                            ? Button.colors.PURPLE
                            : Button.colors.PURPLE_LITE
                        }
                        size={Button.sizes.FULL}
                      >
                        <FontAwesomeIcon icon={['fas', 'external-link-alt']} />
                        <span className="text-base whitespace-nowrap">
                          {goToMeetingButtonText}
                        </span>
                      </Button>
                    </div>
                    <div className="hidden w-full md:block">
                      {isCurrentUserMeetingController && (
                        <Button
                          onClick={() => {
                            if (!previewMeetingCompleted) {
                              updatePreviewMeetingCompleted(true);
                            }
                            if (showPreviewInstructions) {
                              setPreviewInstructionsModalOpen(true);
                            } else {
                              handleClickPreview();
                            }
                          }}
                          color={
                            previewMeetingCompleted
                              ? Button.colors.PURPLE_LITE
                              : Button.colors.PURPLE
                          }
                          size={Button.sizes.FULL}
                        >
                          <FontAwesomeIcon icon="play-circle" />{' '}
                          <span className="text-base whitespace-nowrap">
                            {meetingPreviewButtonText}
                          </span>
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Right Section (Visibility Info, Url) */}
              <div className="flex flex-[1_0_0%] flex-col md:pl-10 md:mx-0 rounded-r-2xl bg-white py-4 md:py-0 justify-center space-y-2.5">
                <SeriesVisibilityOption visibility={visibility} />
                <div className="border-[0.5px] border-solid border-gray" />
                <div className="flex space-x-2 break-words flex-wrap">
                  <UrlLink
                    className=""
                    url={meetingSeriesUrl}
                    textStyle={{
                      color: '#8B33F7',
                      fontSize: '1rem',
                      fontWeight: 'bold',
                    }}
                  >
                    <div className="ml-2">
                      <CopyIcon width={18} height={18} />
                    </div>
                  </UrlLink>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
);
