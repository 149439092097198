import React from 'react';

export const TextIcon = ({
  width = '13px',
  height = '17px',
  className = 'fill-purple',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.43 15.17L7.44 1.17H7.43C7.29 0.78 6.93 0.5 6.5 0.5C6.07 0.5 5.71 0.78 5.57 1.17H5.56L0.56 15.17H0.57C0.53 15.27 0.5 15.38 0.5 15.5C0.5 16.05 0.95 16.5 1.5 16.5C1.93 16.5 2.29 16.22 2.43 15.83H2.44L3.99 11.5H9.01L10.56 15.84H10.57C10.71 16.22 11.07 16.5 11.5 16.5C12.05 16.5 12.5 16.05 12.5 15.5C12.5 15.38 12.47 15.27 12.43 15.17ZM4.7 9.5L6.5 4.47L8.3 9.5H4.7Z"
      />
    </svg>
  );
};
