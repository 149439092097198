import { useEffect, useRef } from 'react';
import { getWorkspaceIdFromUrl } from './useTemplateCreation';
import { addAdminApi } from '../helper/api';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useUserWorkspaces } from './useUserWorkspaces';

export const useClientWorkspaceAccess = (redirect = true) => {
  const hasSentRequest = useRef(false);
  const { switchWorkspace, defaultWorkspace } = useUserWorkspaces();

  const history = useHistory();

  const { user } = useSelector((_st) => _st.auth, shallowEqual);

  const {
    workspaces: userWorkspaces = [],
    privileges = {},
    userId,
  } = user || {};

  const hasClientWorkspaceAccess = !!privileges.clientWorkspaceAccess;

  const workspaceId = getWorkspaceIdFromUrl();

  const isNotAMember = !userWorkspaces.find((w) =>
    workspaceId ? w.workspaceId === workspaceId : true
  );

  const firstWorkspace = userWorkspaces[0];

  useEffect(() => {
    if (isNotAMember && !hasClientWorkspaceAccess) {
      if (firstWorkspace && redirect) {
        switchWorkspace(firstWorkspace.workspaceId, undefined, true);
      }
    }
  }, [
    redirect,
    firstWorkspace,
    switchWorkspace,
    isNotAMember,
    hasClientWorkspaceAccess,
  ]);

  useEffect(() => {
    const workspaceIdFromUrl = getWorkspaceIdFromUrl();
    if (
      workspaceIdFromUrl &&
      isNotAMember &&
      hasClientWorkspaceAccess &&
      !hasSentRequest.current
    ) {
      hasSentRequest.current = true;
      addAdminApi(workspaceIdFromUrl, userId).then(async ({ user, error }) => {
        if (error) {
          if (firstWorkspace) {
            switchWorkspace(firstWorkspace.workspaceId, undefined, true);
          }
        }
        if (user) {
          await switchWorkspace(workspaceIdFromUrl, user?.workspaces, true);
          history.go(0);
        }
      });
    } else if (
      workspaceIdFromUrl &&
      !isNotAMember &&
      defaultWorkspace.workspaceId &&
      defaultWorkspace.workspaceId !== workspaceIdFromUrl
    ) {
      switchWorkspace(workspaceIdFromUrl, user?.workspaces, true);
    }
  }, [
    switchWorkspace,
    history,
    isNotAMember,
    hasClientWorkspaceAccess,
    userId,
    firstWorkspace,
    defaultWorkspace,
    user?.workspaces,
  ]);

  return {
    hasClientWorkspaceAccess,
  };
};
