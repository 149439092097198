import React, { createContext, useMemo } from 'react';
import noop from 'lodash/noop';

import { useChangePlanDuringMeeting } from '../../../hooks/useChangePlanDuringMeeting';

export const SubscriptionActionsContext = createContext({
  /** main function responsible for upgrading plan. Accepts new plan as a string */
  changePlan: noop,

  /** whether and which customer plan is updating. Refer to zync-common/zyncCustomerPlans to check possible customer plans */
  targetCustomerPlanName: null,

  /** current stripe subscription object. null when not set in the workspace  */
  subscription: null,
});

const SubscriptionActionsContextProvider = ({
  children,
  series,
  setSeries,
  subscription,
  refreshSubscription,
}) => {
  const { changePlan, targetCustomerPlanName } = useChangePlanDuringMeeting({
    series,
    setSeries,
    subscription,
    refreshSubscription,
  });

  const value = useMemo(
    () => ({
      changePlan,
      targetCustomerPlanName,
      subscription,
    }),
    [changePlan, targetCustomerPlanName, subscription]
  );

  return (
    <SubscriptionActionsContext.Provider value={value}>
      {children}
    </SubscriptionActionsContext.Provider>
  );
};

export default SubscriptionActionsContextProvider;
