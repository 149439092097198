import React, { lazy, Suspense, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendEvent } from '../../helper/api';

import { useDailyControls } from '../../hooks/useDailyControls';

import '../live.css';
import { Button } from '../common/Button';
import { Modal, ModalOverlay } from '../Modal';
import { isEqual } from 'lodash/fp';
import useMeetingRecordings from '../MeetingControl/buttons/MediaRecordButton/useMeetingRecordings';
import { MobilePlayerOptionsMenu } from './MobilePlayerOptionsMenu';
import { ExitRightArrowIcon } from '../icons/ExitRightArrowIcon';
import { ZyncErrorModal } from '../ZyncErrorModal';
import { MobileChatIcon } from '../icons/MobileChatIcon';
import { getMeetingTitle } from '../richText/helpers';
import { isSafari } from '../../helper';

const Chat = lazy(() => import('../chat/Chat'));

const LiveMeetingPlayer = ({ series, userId }) => {
  const meetingState = useSelector((state) => state.meetingState, isEqual);

  const meetingId = useSelector((state) => state.meetingState.meetingId);

  const { destroy } = useDailyControls();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const dispatch = useDispatch();

  const handleLeaveMeeting = useCallback(() => {
    const leaveMeetingAction = {
      type: 'LEAVE_MEETING',
      userId,
    };
    destroy();
    dispatch(leaveMeetingAction);
    return sendEvent(userId, meetingState.meetingId, leaveMeetingAction);
  }, [userId, meetingState.meetingId, dispatch, destroy]);

  const { meetingStreaming } = useMeetingRecordings();

  const confirmationModal = useMemo(
    () =>
      isConfirmationModalOpen && (
        <ModalOverlay className="fixed inset-0">
          <Modal size="sm">
            <div className="p-4">
              <ModalTitle>Leave Meeting?</ModalTitle>
              <ModalActionButtonsGroup>
                <Button
                  color={Button.colors.WHITE}
                  padding={Button.padding.SMALL}
                  onClick={() => setIsConfirmationModalOpen(false)}
                >
                  <span className="text-base font-medium">Cancel</span>
                </Button>
                <Button
                  color={Button.colors.PURPLE}
                  padding={Button.padding.SMALL}
                  onClick={handleLeaveMeeting}
                >
                  <span className="text-base font-medium">Confirm</span>
                </Button>
              </ModalActionButtonsGroup>
            </div>
          </Modal>
        </ModalOverlay>
      ),
    [handleLeaveMeeting, isConfirmationModalOpen]
  );

  const streamingAssets = Object.values(meetingStreaming?.assets || {});

  const playbackId = streamingAssets[streamingAssets.length - 1]?.playbackId;

  const [showChat, setShowChat] = useState(false);

  const buttonsConfig = [
    {
      label: (
        <span className={`${showChat ? 'text-purple' : 'text-white'}`}>
          Chat
        </span>
      ),
      icon: (
        <MobileChatIcon
          width={24}
          height={24}
          size
          className={showChat ? 'fill-purple' : 'fill-white'}
        />
      ),
      onTouchHandler: () => setShowChat((wasChatOpen) => !wasChatOpen),
      backgroundColor: showChat ? 'white' : 'transparent',
      size: 40,
    },
    {
      label: 'Exit',
      icon: (
        <ExitRightArrowIcon
          width={24}
          height={24}
          size
          className="fill-white"
        />
      ),
      onTouchHandler: () => setIsConfirmationModalOpen(true),
      backgroundColor: 'transparent',
      size: 40,
    },
  ];

  const meetingTitle = getMeetingTitle(series.title);

  return (
    <>
      <style>
        {`
        @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
          html {
            transform: rotate(-90deg);
            transform-origin: left top;
            width: 100vh;
            height: 100vw;
            overflow-x: hidden;
            position: absolute;
            top: ${isSafari ? '100%' : '100vh'};
            left: 0;
          }
        `}
      </style>
      <>
        {playbackId ? (
          <>
            <div className="w-full h-full mx-auto flex grow-1">
              <div className="h-full w-full overflow-hidden">
                <style>
                  {`
                    mux-player {
                      height: 100%;
                      width: 100%; 
                    }
                    `}
                </style>
                <mux-player
                  stream-type="live"
                  playback-id={playbackId}
                  title={meetingTitle}
                  metadata-video-title={meetingTitle}
                  metadata-viewer-user-id={userId}
                  style={{
                    '--media-background-color': 'white',
                  }}
                />
              </div>
              <div
                className={`hidden ${
                  showChat && 'sm-landscape:block'
                } p-2 w-1/4 bg-white rounded-md overflow-hidden`}
              >
                <Suspense fallback={<></>}>
                  <Chat
                    showChat={showChat}
                    sendChatAction={(action) =>
                      sendEvent(userId, meetingId, action)
                    }
                  />
                </Suspense>
              </div>
            </div>
            <MobilePlayerOptionsMenu
              buttonsConfig={buttonsConfig}
              showChat={showChat}
            />
          </>
        ) : (
          <ZyncErrorModal
            title=""
            message="This event is not being streamed yet"
          />
        )}
      </>
      {confirmationModal}
    </>
  );
};

export const ModalActionButtonsGroup = React.memo(({ children }) => (
  <div className="flex justify-end space-x-4">{children}</div>
));

export const ModalTitle = React.memo(({ children }) => (
  <p className="font-medium mb-4">{children}</p>
));

export { LiveMeetingPlayer };
