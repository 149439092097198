import React from 'react';

import { MeetingBox } from './MeetingBox';
import { SlideViewer } from './SlideReview';
import mixpanel from 'mixpanel-browser';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { getMeetingSeriesProps } from 'zync-common/tracking';

export const Scenes = ({ series, meetingSeriesId, isMeetingController }) => {
  const history = useHistory();

  const handleClickCustomize = useCallback(() => {
    //const canCustomizeMeeting = !!user.privileges?.meetingCustomization;

    mixpanel.track('Series - Home Customize Click', {
      ...getMeetingSeriesProps(series),
    });

    const goToAuthoringTool = () =>
      history.push(`/e/${meetingSeriesId}/designer`);
    //const openSignupModal = () => setIsSignupModalOpen(true);

    // TODO: Remove this logic once we have decided that we do not want to have any form of whitelisting for
    // authoring. Till then, we are leaving this logic in case we want to revert back at some point.
    //canCustomizeMeeting ? goToAuthoringTool() : openSignupModal();
    goToAuthoringTool();
  }, [series, meetingSeriesId, history]);

  return (
    <div className="hidden md:block">
      <MeetingBox
        title="Scenes"
        description="These are interactive slides that make up the flow of your event"
        buttonText="Customize"
        isEditableIcon={true}
        onClick={handleClickCustomize}
        showHoverButton={true}
      >
        {isMeetingController && (
          <div className="relative w-full h-40 flex flex-col justify-center items-start">
            <div className="absolute box-border left-0 right-0">
              <SlideViewer
                template={series.autoLaunchConfig}
                showMainScene={false}
                onSceneClick={handleClickCustomize}
                brandKit={series?.workspace?.brandKit}
              />
            </div>
          </div>
        )}
      </MeetingBox>
    </div>
  );
};
