import React from 'react';

export const CopyIcon = ({
  className = 'fill-blue-gray',
  width = 16,
  height = 16,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8 11.2V1.6C12.8 0.716344 12.0837 0 11.2 0H1.6C0.716344 0 0 0.716344 0 1.6V11.2C0 12.0837 0.716344 12.8 1.6 12.8H11.2C12.0837 12.8 12.8 12.0837 12.8 11.2ZM1.6 1.6H11.2V11.2H1.6V1.6ZM14.4 12.8V3.2C15.2837 3.2 16 3.91634 16 4.8V12.8C16 14.5673 14.5673 16 12.8 16H4.8C3.91634 16 3.2 15.2837 3.2 14.4H12.8C13.6837 14.4 14.4 13.6837 14.4 12.8Z"
      />
    </svg>
  );
};
