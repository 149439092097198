import styled, { css } from 'styled-components';
import { baseInputStyle, fieldLabel } from '../../theme/baseStyles';
import { ActionButtonContainer } from '../common/ActionButton';

/**
 * FieldContainer is the overall container for all aspects of the input field,
 * including label, input form element, error message, and explanation.
 */
export const FieldContainer = styled.label`
  position: relative;

  // elements stacked vertically
  display: flex;
  flex-direction: column;
  align-items: stretch;

  // space above and below
  margin: ${({ theme }) => theme.spacing(1.5)}px 0;
`;

/**
 * InputLabel is the container for the text-based label,
 * describing the input. The child of InputLabel should be
 * plain text, or inline elements.
 */
export const InputLabel = styled.div`
  ${fieldLabel};
  margin-bottom: ${({ theme }) => theme.spacing(0.5)}px;
`;

/**
 * InputContainer is the container for the input element(s),
 * whether an HTML input, select, radio options, or a custom
 * input.
 */
export const InputContainer = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  // encourage elements to take up the full available width.
  align-items: stretch;

  ${({ endString, theme }) =>
    endString &&
    css`
      &::after {
        content: '${endString}';
        position: absolute;
        right: 32px;
        top: 11px;
        color: ${theme.palette.text.placeholder};
      }
    `}
`;

/**
 * InputElement is an HTML `input` with default theme and styles
 * applied.
 */
export const InputElement = styled.input`
  ${baseInputStyle}

  ${({ compact }) =>
    compact
      ? css`
          padding: ${({ theme }) => theme.spacing(0.5)}px;
        `
      : null}
`;

/**
 * TextAreaElement is an HTML `textarea` with default theme and
 * styles applied.
 */
export const TextAreaElement = styled.textarea`
  ${baseInputStyle}
`;

/** A horizontal container for a multi-part input (e.g. a text input and button). */
export const TextButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(0.5)}px;

  ${() => ActionButtonContainer} {
    font-size: 18px;
    height: 31px;
  }

  ${() => FieldContainer} {
    margin: ${({ theme }) => theme.spacing(0.5)}px;
  }
`;
