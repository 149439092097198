import React, { useState } from 'react';
import MediaButton from './MediaButton';
import { SignUpModal } from '../SignUpModal';
import Lottie from 'react-lottie';
import { animationOptions } from '../meetingControlAnimationOptions';
import streamAnimation from '../Live meeting icons/Stream.json';

const ACTION_BUTTON_URL = 'https://app.zync.ai';

const MediaLiveStreamingButton = ({
  isQuickStartEnabled = false,
  isDisabled,
  setIsStreamingPopupOpen,
  isStreamingPopupOpen,
}) => {
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [isStreamAnimationStopped, setIsStreamAnimationStopped] =
    useState(true);
  return (
    <>
      <MediaButton
        disabled={isDisabled}
        className="peer cursor-pointer"
        onClick={() => {
          if (isQuickStartEnabled) {
            setShowSignUpModal(true);
          } else {
            setIsStreamingPopupOpen(!isStreamingPopupOpen);
          }
        }}
        onMouseEnter={() => setIsStreamAnimationStopped(false)}
        onMouseLeave={() => setIsStreamAnimationStopped(true)}
      >
        <div className="flex flex-col justify-center items-center">
          <div className="w-8 h-8 text-purple">
            <Lottie
              options={{ ...animationOptions, animationData: streamAnimation }}
              isStopped={isStreamAnimationStopped}
            />
          </div>
          <span className="text-purple text-xxs font-medium">Livestream</span>
        </div>
      </MediaButton>
      {showSignUpModal && (
        <SignUpModal
          title="Ready to Live Stream your meeting?"
          body="With one click you can live stream your meeting to Youtube, LinkedIn, Twitch or any other platform. Not just the video but your theme, background and all interactions just the way you’re seeing it here. Sign up and set up streaming now."
          buttonText="Sign Up"
          handleSubmit={() => window.open(ACTION_BUTTON_URL)}
          handleCancel={() => setShowSignUpModal(false)}
        />
      )}
    </>
  );
};

export default MediaLiveStreamingButton;
