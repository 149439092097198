import { css } from 'styled-components';

/** The standard 16:9 screen size from which to scale pixel-values (such as textbox). */
export const standardScreenSize = { width: 1280, height: 720 };

/**
 * Convert a size value (a string with units, or a number representing a pixel value)
 * to a valid CSS property value.
 */
export const sizeToString = (value, defaultValue = undefined) => {
  const asNumber = sizeToNumber(
    value,
    defaultValue !== undefined ? defaultValue : value
  );
  return typeof asNumber === 'number' ? `${asNumber}px` : asNumber;
};

/**
 * Get the number value of a size string or number. If the value is a non-pixel value or
 * undefined, returns `defaultValue`, which defaults to null.
 */
export const sizeToNumber = (value, defaultValue = null) =>
  (value || value === 0) && `${value}`.indexOf('%') === -1
    ? parseFloat(value)
    : defaultValue;

/** Sum two size values, returning the first value if the summation is not possible. */
export const sumSizes = (value1, value2) => {
  const v1 = sizeToNumber(value1);
  const v2 = sizeToNumber(value2);
  if (
    typeof v1 === 'string' ||
    typeof v2 === 'string' ||
    typeof v1 !== 'number' ||
    typeof v2 !== 'number'
  ) {
    return v1;
  } else {
    return sizeToString(v1 + v2);
  }
};

export const zIndex = (value) => {
  if (!value) {
    return css``;
  }

  return css`
    z-index: ${value};
  `;
};

/** css style within styled-components used to absolutely position a block. */
export const absolutePosition = (position) => {
  if (!position) {
    return css``;
  }
  const { top, left, bottom, right, width, height } = position;
  return css`
    ${[top, left, bottom, right].some((value) => value !== undefined)
      ? 'position: absolute;'
      : ''}
    ${top !== undefined && `top: ${sizeToString(top)};`}
    ${left !== undefined && `left: ${sizeToString(left)};`}
    ${bottom !== undefined && `bottom: ${sizeToString(bottom)};`}
    ${right !== undefined && `right: ${sizeToString(right)};`}
    ${width !== undefined && `width: ${sizeToString(width)};`}
    ${height !== undefined && `height: ${sizeToString(height)};`}
  `;
};

/**
 * Returns scrollbar CSS to enable a custom scrollbar for webkit browsers.
 * If `light` is true, optimizes for a light background, otherwise
 * optimizes for a dark background. Defaults `light` to `true`.
 * If `showBackground` is true (true by default), shows the scrollbar background,
 * otherwise only shows the scrollbar thumb.
 */
export const scrollbarCss = (light = true, showBackground = true) => css`
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background: ${showBackground
      ? light
        ? `rgba(0, 0, 0, 0.1)`
        : `rgba(255, 255, 255, 0.1)`
      : 'rgba(0, 0, 0, 0)'};
  }

  ::-webkit-scrollbar-thumb {
    background: ${light ? `rgba(0, 0, 0, 0.5)` : `rgba(255, 255, 255, 0.5)`};
    border-radius: 12px;
    border: 3px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }
`;

/**
 * Calculates { width, height, left, top }, a rectangle with `aspectRatio`
 * within the width and height given as parameters.
 */
export const constrainRectangle = (width, height, aspectRatio) => {
  if (width / height > aspectRatio) {
    return {
      width: height * aspectRatio,
      height,
      top: 0,
      left: (height * aspectRatio) / 2,
    };
  } else {
    return {
      width,
      height: width * (1 / aspectRatio),
      top: (width * (1 / aspectRatio)) / 2,
      left: 0,
    };
  }
};

/*
 * Calculates coordinates and dimensions for a square centered within the given rectangular bounds (width x height), and optionally
 * offsets the resulting coordinates by (x, y).
 * Returns `{ width, height, left, top }`, where x and y are pixel offsets relative to the given bounds' top left corner plus
 * the offset (x, y), if given.
 */
export const centeredSquareInBounds = (width, height, x = null, y = null) => {
  const ratio = width / height;
  const margin = Math.abs(width - height) / 2;
  const size = Math.min(width, height);
  const topMargin = ratio > 1 ? 0 : margin;
  const leftMargin = ratio > 1 ? margin : 0;

  return {
    width: size,
    height: size,
    left: leftMargin + (x === null ? 0 : x),
    top: topMargin + (y === null ? 0 : y),
  };
};

/**
 * Applies a function `func` to each field in `obj`, returning the resulting object.
 * If `keys` is specified, only includes properties of `obj` included in `keys`.
 */
export const applyFunctionToMap = (obj, func, keys) => {
  const applied = {};
  if (!obj) {
    return obj;
  } else {
    Object.entries(obj).forEach(([key, value]) => {
      if (!keys || keys.includes(key)) {
        applied[key] = func(value, key);
      }
    });
    return applied;
  }
};

/** Convert a block position (or other dictionary) to number-based coordinates (`38` instead of `"38px"`). */
export const convertPositionToNumbers = (position) => {
  return applyFunctionToMap(position, sizeToNumber);
};

/** Convert a block position to pixel-based coordinates (`"38px"` instead of `38`). */
export const convertPositionToPixelStrings = (position) => {
  return applyFunctionToMap(position, sizeToString);
};
