import {
  createNewPresenterSlide,
  createNewQuestionSlide,
} from './useMeetingCreator';
import { episodeBuilder } from '../../helper/episodeBuilder';
import { timelineStyleKeys } from '../../helper/constants';

const slidesConfig = (timelineStyle, questions, eventSpeakers) => {
  switch (timelineStyle) {
    case timelineStyleKeys.basic: {
      return [
        {
          sceneTemplateKey: 'scene_lobby',
        },
        {
          sceneTemplateKey: 'scene_open_discussion',
        },
      ];
    }
    case timelineStyleKeys.discussion_points: {
      return [
        {
          sceneTemplateKey: 'scene_lobby',
        },
        { sceneTemplateKey: 'scene_topic_intro' },
        ...eventSpeakers.map((eventPresenter) => ({
          sceneTemplateKey: 'scene_speaker_intro_2',
          mapper: (scene) => createNewPresenterSlide(scene, eventPresenter),
        })),
        ...questions.map((questionSlide) => ({
          sceneTemplateKey: 'scene_discussion_point',
          mapper: (scene) => createNewQuestionSlide(scene, questionSlide),
        })),
        {
          sceneTemplateKey: 'scene_open_discussion',
        },
        {
          sceneTemplateKey: 'scene_thankyou',
        },
      ];
    }
    case timelineStyleKeys.audience_interaction:
    default: {
      return [
        {
          sceneTemplateKey: 'scene_lobby',
        },
        { sceneTemplateKey: 'scene_topic_intro' },
        ...eventSpeakers.map((eventPresenter) => ({
          sceneTemplateKey: 'scene_speaker_intro_2',
          mapper: (scene) => createNewPresenterSlide(scene, eventPresenter),
        })),
        ...questions.map((questionSlide) => ({
          sceneTemplateKey: 'scene_discussion_point',
          mapper: (scene) => createNewQuestionSlide(scene, questionSlide),
        })),
        { sceneTemplateKey: 'scene_q&a' },
        {
          sceneTemplateKey: 'scene_opinionpoll_host',
        },
        {
          sceneTemplateKey: 'scene_wordcloud_host',
        },
        {
          sceneTemplateKey: 'scene_open_discussion',
        },
        {
          sceneTemplateKey: 'scene_thankyou',
        },
      ];
    }
  }
};

export const createEpisodeWithSpeakers = async ({
  title,
  eventSpeakers,
  eventHost,
  questions,
  timelineStyle,
}) => {
  const { init, build } = episodeBuilder({
    title,
    slides: slidesConfig(timelineStyle, questions, eventSpeakers),
    templateKey: 'meeting_speaker_interview_1x1',
    options: {
      eventPresenters: [...eventSpeakers, eventHost],
    },
  });

  await init();

  return build();
};
