import React from 'react';

export const DoubleAngleIcon = ({
  width = '31px',
  height = '46px',
  className = 'fill-purple',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 31 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M29.8123 22.9447C29.8163 22.5199 29.7648 22.096 29.6593 21.6967C29.5547 21.3009 29.3974 20.9314 29.1911 20.6118L18.1011 1.48503L18.0878 1.46212L18.0722 1.44074C17.6431 0.853855 17.0292 0.47268 16.3359 0.501501C15.6466 0.530156 15.0564 0.956938 14.6548 1.56963C14.2528 2.18299 14.0353 2.97982 14.0208 3.79327C14.0062 4.60657 14.1949 5.41593 14.5704 6.05559C14.5707 6.05605 14.5709 6.05651 14.5712 6.05697L24.3618 22.9445L14.5712 39.8321C14.5709 39.8326 14.5707 39.8331 14.5704 39.8335C14.1949 40.4732 14.0062 41.2825 14.0208 42.0958C14.0353 42.9093 14.2528 43.7061 14.6548 44.3195C15.0564 44.9321 15.6466 45.3589 16.3359 45.3876C17.0292 45.4164 17.6431 45.0352 18.0722 44.4483L18.0878 44.427L18.1011 44.4041L29.1908 25.2778C29.6255 24.608 29.8197 23.7628 29.8123 22.9447ZM29.8123 22.9447C29.8123 22.9464 29.8123 22.9481 29.8123 22.9498L29.3123 22.9445L29.8123 22.9395C29.8123 22.9412 29.8123 22.943 29.8123 22.9447ZM1.13249 44.4325L1.13286 44.4331C1.56744 45.0961 2.1913 45.5 2.88417 45.5C3.57704 45.5 4.20089 45.0961 4.63547 44.4331L4.64297 44.4216L4.64984 44.4098L15.7395 25.2835C16.1741 24.614 16.3683 23.769 16.3611 22.9511C16.3663 22.5259 16.3154 22.1012 16.2097 21.7014C16.105 21.3053 15.947 20.9356 15.7394 20.6167L4.65366 1.48515L4.64035 1.46217L4.62468 1.44074C4.19563 0.853855 3.58172 0.47268 2.88842 0.501501C2.19913 0.530156 1.60893 0.956937 1.20734 1.56963C0.805308 2.18299 0.587803 2.97982 0.573276 3.79327C0.55875 4.6066 0.747433 5.41599 1.12295 6.05566C1.1232 6.0561 1.12346 6.05653 1.12372 6.05697L10.9143 22.9445L1.12897 39.823C0.716939 40.4563 0.500773 41.2828 0.500002 42.1206C0.499226 42.9638 0.716655 43.7962 1.13249 44.4325Z"
        stroke="#101840"
      />
    </svg>
  );
};
