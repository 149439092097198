import React from 'react';
import { Modal, ModalWindow } from './Modal';

export const DesktopOnlyModal = ({ message, onClose }) => {
  return (
    <ModalWindow size={Modal.size.sm} onCancel={onClose} showCancel={true}>
      <div className="flex flex-col gap-2.5 p-8">
        <div className="text-xl text-blue-dark font-semibold">
          Use Desktop device
        </div>
        <div className="text-sm text-blue-gray">{message}</div>
      </div>
    </ModalWindow>
  );
};
