import React, { useEffect, useState } from 'react';
import { CaretIcon } from './icons/CaretIcon';
import { TwitterIcon } from './icons/TwitterIcon';
import { FacebookIcon } from './icons/FacebookIcon';
import { YoutubeIcon } from './icons/YoutubeIcon';
import { LinkedInIcon } from './icons/LinkedInIcon';
import HlsVideoPlayer from './HlsVideoPlayer';
import { DownloadIcon } from './icons/DownloadIcon';
import { ModalOverlay } from './Modal';
import { CopyIcon } from './icons/CopyIcon';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  updateSocialMediaPostProperty,
  createSocialMediaAsset,
  fetchMeetingApi,
  deleteHighlightApi,
  deleteMarketingAssetApi,
  regenerateSocialMediaPost,
} from '../helper/api';
import { convertISOStringToMS } from '../helper/rsvp';
import classNames from '../helper/classNames';
import { Button } from './common/Button';
import { mediaTypes } from 'zync-common/types';
import { equals } from 'lodash/fp';
import { RefreshIcon } from './icons/RefreshIcon';
import { ShareIcon } from './icons/ShareIcon';
import { toast } from 'react-toastify';
import { getTimezoneAbbreviation } from '../helper/rsvp';
import { convertUTCToTimezone } from '../helper/rsvp';
import { TrashIcon } from './icons/TrashIcon';
import { saveAs } from 'file-saver';
import { Document, Packer, Paragraph } from 'docx';
import { getImageDimensions } from './ImageUploader';
import { Link } from 'react-router-dom';

const activatedMarketingOptions = [
  'twitter',
  'linkedIn',
  'youtube',
  'facebook',
];

export const marketingOptions = {
  twitter: {
    icon: (props) => <TwitterIcon {...props} />,
    modalTitle: 'Twitter Post',
    mediaType: mediaTypes.landscape,
    settings: {},
    doesClipMeetCondition: (clip) => {
      return {
        disabled: false,
      };
    },
    modalDimensions: {
      width: '1210px',
      height: '580px',
    },
    videoDimensions: {
      width: '800px',
      height: '450px',
    },
  },
  facebook: {
    modalTitle: 'Facebook Post',
    mediaType: mediaTypes.landscape,
    icon: (props) => <FacebookIcon {...props} />,
    settings: {},
    doesClipMeetCondition: (clip) => {
      return {
        disabled: false,
      };
    },
    modalDimensions: {
      width: '1210px',
      height: '580px',
    },
    videoDimensions: {
      width: '800px',
      height: '450px',
    },
  },
  youtube: {
    icon: (props) => <YoutubeIcon {...props} />,
    modalTitle: 'Youtube Short',
    mediaType: mediaTypes.portrait,
    settings: {},
    doesClipMeetCondition: (clip) => {
      const {
        renderData: { durationSeconds },
      } = clip;

      if (durationSeconds > 60) {
        return {
          disabled: true,
          reason: 'Clip length cannot be over 60 seconds.',
        };
      } else {
        return {
          disabled: false,
        };
      }
    },
    modalDimensions: {
      width: '860px',
      height: '927px',
    },
    videoDimensions: {
      width: '450px',
      height: '800px',
    },
  },
  linkedIn: {
    icon: (props) => <LinkedInIcon {...props} />,
    modalTitle: 'LinkedIn Post',
    mediaType: mediaTypes.square,
    settings: {},
    doesClipMeetCondition: (clip) => {
      return {
        disabled: false,
      };
    },
    modalDimensions: {
      width: '910px',
      height: '630px',
    },
    videoDimensions: {
      width: '500px',
      height: '500px',
    },
  },
};

const hasHighResFile = (files) =>
  files.some((file) => file.name === 'high.mp4');

export const capitalizeFirstLetter = (word) =>
  word.charAt(0).toUpperCase() + word.slice(1);

const lowercaseFirstLetter = (word) =>
  word.charAt(0).toLowerCase() + word.slice(1);

const generateTranscriptInWordDocument = (transcript, localTimezone) => {
  const timezoneAbbreviation = getTimezoneAbbreviation(localTimezone);
  const formattedTranscript = transcript.map((messageObject) => {
    const { timestamp, userName, message } = messageObject;
    return new Paragraph({
      text: `${convertUTCToTimezone(
        timestamp,
        localTimezone,
        'time'
      )} ${timezoneAbbreviation} - ${userName}: ${message}`,
    });
  });

  const doc = new Document({
    sections: [
      {
        children: formattedTranscript,
      },
    ],
  });

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, 'Voice Transcript.docx');
  });
};

export const DownloadLink = ({ text }) => (
  <div className="flex gap-1 decoration-blue-gray underline">
    <DownloadIcon className="fill-blue-light" height="16px" />
    <div className="text-blue-gray font-medium text-xs">{text}</div>
  </div>
);

export const DownloadAssets = ({
  clipText,
  playbackId,
  clipFileName,
  clipFiles,
  transcript,
  localTimezone,
}) => (
  <div className="flex flex-col gap-[15px]">
    <div className="font-medium text-blue-dark text-xs">
      Download Event Assets
    </div>
    <div className="flex flex-wrap gap-[15px]">
      {hasHighResFile(clipFiles) && (
        <a
          href={`https://stream.mux.com/${playbackId}/high.mp4?download=${clipFileName}`}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          <DownloadLink text={clipText} />
        </a>
      )}
      {transcript && transcript.length > 0 && (
        <button
          onClick={() =>
            generateTranscriptInWordDocument(transcript, localTimezone)
          }
        >
          <DownloadLink text="Transcript" />
        </button>
      )}
    </div>
  </div>
);

export const ClipRecording = ({
  playbackId,
  className,
  userId,
  meetingId,
  thumbnailUrl,
}) => (
  <HlsVideoPlayer
    src={`https://stream.mux.com/${playbackId}.m3u8`}
    poster={
      thumbnailUrl
        ? `${thumbnailUrl}`
        : `https://image.mux.com/${playbackId}/thumbnail.jpg?&width=800&height=450`
    }
    className={className}
    userId={userId}
    meetingId={meetingId}
  />
);

export const SocialMediaModalTextArea = ({
  title,
  defaultValue,
  handleOnBlur,
  disabled,
  showRegenerate = false,
  socialMediaType,
  unformattedTranscript,
  meetingId,
  highlightId,
  highlightsDispatch,
  hasModeratorPrivileges,
}) => {
  const [value, setValue] = useState(defaultValue ? defaultValue : '');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleRegenerateSocialMedia = async () => {
    setIsLoading(true);
    const { error, postTitle } = await regenerateSocialMediaPost(
      highlightId,
      meetingId,
      socialMediaType,
      unformattedTranscript
    );
    if (error) {
      setError(error);
      setIsLoading(false);
      return;
    }

    highlightsDispatch({
      type: 'UPDATE_SOCIAL_MEDIA_POST_TEXT',
      value: postTitle,
      property: 'postTitle',
      meetingId,
      highlightId,
      socialMediaType,
    });
    setValue(postTitle);
    setIsLoading(false);
  };

  return (
    <div className="flex flex-col gap-2.5 h-full">
      <div className="flex justify-between text-sm font-medium text-blue-dark">
        <div className="flex gap-1 items-center">
          {title}
          {showRegenerate && hasModeratorPrivileges && (
            <Button
              padding={Button.padding.NONE}
              color={Button.colors.PURPLE}
              border={Button.border.ROUNDED}
              state={isLoading ? Button.states.LOADING : Button.states.DEFAULT}
              onClick={() => {
                handleRegenerateSocialMedia();
              }}
            >
              <div className="text-[10px] h-6 flex justify-center items-center px-2.5">
                Regenerate Post
              </div>
            </Button>
          )}
        </div>
        <div className="group relative flex items-center">
          <CopyToClipboard text={value}>
            <button>
              <CopyIcon />
            </button>
          </CopyToClipboard>
          <div className="group-hover:block hidden rounded-lg bg-blue-dark text-white text-xs font-light absolute w-[73px] p-2 top-6">
            Copy text
          </div>
        </div>
      </div>
      {error && <div className="text-red text-xs font-light">{error}</div>}
      <textarea
        className="h-full resize-none font-[Poppins] border border-gray py-2 px-3 text-sm leading-5 text-blue-gray rounded"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => {
          if (!handleOnBlur) return;
          const trimmedValue = value.trim();
          if (defaultValue !== trimmedValue) handleOnBlur(trimmedValue, title);
        }}
        disabled={disabled}
      />
    </div>
  );
};

export const doesMediaHaveTargetSettingsAndAspectRatio = (
  media,
  aspectRatio,
  settings
) =>
  media.aspectRatio === aspectRatio &&
  equals(media.settings, settings) &&
  media?.renderData?.assetType === 'highlight-transformation';

export const findMediaBySettingsAndAspectRatio = (
  media,
  aspectRatio,
  settings
) =>
  media.find((m) =>
    doesMediaHaveTargetSettingsAndAspectRatio(m, aspectRatio, settings)
  );

export const BASE_SHAREABLE_LINK = (meetingId) =>
  `${
    process.env.REACT_APP_DOMAIN || 'localhost:3000'
  }/highlight/${encodeURIComponent(meetingId)}`;

const SocialMediaModal = ({
  onCancel,
  socialMediaType,
  socialMediaPosts,
  media,
  highlightId,
  meetingId,
  fileName,
  highlightsDispatch,
  hasModeratorPrivileges,
  unformattedTranscript,
  brandKit,
  userId,
}) => {
  const post = socialMediaPosts[socialMediaType];
  const { postTitle } = post;
  const { logoUrl } = brandKit || {};
  const currentMarketingOption = marketingOptions[socialMediaType];
  const mediaType = currentMarketingOption.mediaType;
  const settings = currentMarketingOption.settings;
  const modalDimensions = currentMarketingOption.modalDimensions;
  const targetMedia = findMediaBySettingsAndAspectRatio(
    media,
    mediaType,
    settings
  );
  const { renderData = {}, audioFileUrl = null } = targetMedia || {};
  const { renderId = null, playbackId = null, assetId = null } = renderData;
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickGenerateMedia = async () => {
    setIsLoading(true);
    setError(false);
    const logoDimensions = await getImageDimensions(null, logoUrl);
    const { error, renderId, renderInput } = await createSocialMediaAsset(
      meetingId,
      highlightId,
      mediaType,
      audioFileUrl,
      socialMediaType,
      logoDimensions
    );
    // logging requested by Arjun
    console.log(renderInput);
    setIsLoading(false);

    if (error) {
      setError(
        'Sorry, there was an error generating the asset. Please try again.'
      );
      return;
    }

    highlightsDispatch({
      type: 'GENERATE_SOCIAL_MEDIA_ASSET',
      renderData: { renderId, assetType: 'highlight-transformation' },
      meetingId,
      highlightId,
      mediaType,
      settings,
    });
  };

  const handleClickRegenerateMedia = async () => {
    if (!assetId) return;
    setIsLoading(true);
    const { error } = await deleteMarketingAssetApi(
      meetingId,
      highlightId,
      assetId
    );

    if (error) {
      setIsLoading(false);
      return;
    }

    highlightsDispatch({
      type: 'DELETE_MARKETING_ASSET',
      meetingId,
      highlightId,
      assetId,
    });

    await handleClickGenerateMedia();
  };

  const handleRefreshClick = async () => {
    setIsLoading(true);
    setError(false);
    const meeting = await fetchMeetingApi(meetingId);
    setIsLoading(false);

    if (!meeting) {
      setError('Error getting status of video');
      return;
    }

    const targetHighlight = meeting.highlights.find(
      (highlight) => highlight.highlightId === highlightId
    );

    const targetMedia = findMediaBySettingsAndAspectRatio(
      targetHighlight.media,
      mediaType,
      settings
    );

    if (!targetMedia) return;

    highlightsDispatch({
      type: 'REFRESH_MEDIA',
      newMedia: targetMedia,
      meetingId,
      highlightId,
      aspectRatio: mediaType,
      settings,
    });
  };

  const handleOnBlur = (value, property) => {
    const formattedProperty = property
      .split(' ')
      .map((word, index) => {
        if (index === 0) {
          return lowercaseFirstLetter(word);
        } else {
          return capitalizeFirstLetter(word);
        }
      })
      .join('');

    const formatValue = (value) => {
      switch (formattedProperty) {
        case 'hashtags': {
          return value.split(' ').filter((word) => word.trim().length !== 0);
        }
        default:
          return value;
      }
    };

    const formattedValue = formatValue(value);

    highlightsDispatch({
      type: 'UPDATE_SOCIAL_MEDIA_POST_TEXT',
      value: formattedValue,
      property: formattedProperty,
      meetingId,
      highlightId,
      socialMediaType,
    });

    updateSocialMediaPostProperty(
      highlightId,
      formattedValue,
      meetingId,
      socialMediaType,
      formattedProperty
    );
  };

  return (
    <ModalOverlay
      bg={ModalOverlay.bg.FADE}
      position={ModalOverlay.position.FIXED}
      overflow={false}
    >
      <div
        className="bg-[#F3F3F3] px-[30px] pb-[30px] pt-[21.5px] rounded-[30px] flex flex-col gap-2.5"
        style={{
          width: '100%',
          height: '100%',
          maxWidth: modalDimensions.width,
          maxHeight: modalDimensions.height,
        }}
      >
        <div className="flex justify-between">
          <div className="flex gap-2 justify-center items-center">
            <div className="h-6 w-6 justify-center items-center flex">
              {currentMarketingOption.icon({
                width: '24px',
                height: '24px',
              })}
            </div>
            <div className="font-medium text-blue-dark text-sm">
              {currentMarketingOption.modalTitle}
            </div>
          </div>
          <div className="flex gap-2">
            <CopyToClipboard
              text={`${BASE_SHAREABLE_LINK(meetingId)}/${encodeURIComponent(
                highlightId
              )}/${encodeURIComponent(socialMediaType)}`}
              onCopy={() =>
                toast('Link copied to clipboard.', {
                  autoClose: 2000,
                  pauseOnHover: false,
                })
              }
            >
              <button className="flex justify-center items-center">
                <ShareIcon className="fill-blue-gray" height="16px" />
              </button>
            </CopyToClipboard>
            <button className="text-[20px] text-blue-gray" onClick={onCancel}>
              ×
            </button>
          </div>
        </div>
        <div className="flex w-full h-full max-h-full rounded-[20px] bg-white pl-[30px] min-h-0">
          <div className="flex flex-col gap-8 pt-[25.5px] pr-[30px] w-[350px] h-full pb-2">
            {
              <SocialMediaModalTextArea
                defaultValue={postTitle}
                title="Post Title"
                handleOnBlur={handleOnBlur}
                disabled={!hasModeratorPrivileges}
                showRegenerate={true}
                socialMediaType={socialMediaType}
                unformattedTranscript={unformattedTranscript}
                meetingId={meetingId}
                highlightId={highlightId}
                highlightsDispatch={highlightsDispatch}
                hasModeratorPrivileges={hasModeratorPrivileges}
              />
            }
          </div>
          <div
            className="flex flex-col gap-[15px] justify-end h-full max-h-full pr-2 pb-2"
            style={{
              width: '100%',
              maxWidth: currentMarketingOption.videoDimensions.width,
            }}
          >
            {playbackId && (
              <div className="flex gap-3 justify-end">
                {hasModeratorPrivileges && (
                  <button
                    onClick={() => handleClickRegenerateMedia()}
                    className="h-fit self-end"
                  >
                    <RefreshIcon color="#8B33F7" height="16px" />
                  </button>
                )}
                <a
                  href={`https://stream.mux.com/${playbackId}/high.mp4?download=${fileName}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                  className="flex pt-[15px] gap-1 pr-5 decoration-blue-gray underline"
                >
                  <DownloadIcon className="fill-blue-light" height="16px" />
                  <div className="text-blue-gray font-medium text-xs">
                    Video Clip
                  </div>
                </a>
              </div>
            )}
            <div
              style={{
                height: '100%',
                maxHeight: currentMarketingOption.videoDimensions.height,
              }}
              className={classNames(
                'flex w-full min-h-0',
                !playbackId && 'bg-blue-gray rounded-br-[20px]'
              )}
            >
              {playbackId ? (
                <ClipRecording
                  playbackId={playbackId}
                  className="rounded-br-[20px]"
                  meetingId={meetingId}
                  userId={userId}
                />
              ) : (
                <div className="flex h-full w-full flex-col justify-center items-center gap-3">
                  {!renderId && hasModeratorPrivileges && (
                    <Button
                      color={Button.colors.PURPLE}
                      onClick={handleClickGenerateMedia}
                      state={
                        isLoading
                          ? Button.states.LOADING
                          : Button.states.DEFAULT
                      }
                    >
                      Generate {marketingOptions[socialMediaType].modalTitle}{' '}
                      clip
                    </Button>
                  )}
                  {renderId && hasModeratorPrivileges && (
                    <Button
                      color={Button.colors.PURPLE}
                      onClick={() => handleRefreshClick()}
                      state={
                        isLoading
                          ? Button.states.LOADING
                          : Button.states.DEFAULT
                      }
                    >
                      <div className="text-sm">
                        Your clip will be ready soon, click to refresh status
                      </div>
                    </Button>
                  )}
                  {error && (
                    <div className="text-red text-xs font-light mb-2 bg-white px-5 py-3 rounded">
                      {error}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </ModalOverlay>
  );
};

const MarketingAssets = ({
  highlightId,
  socialMediaPosts,
  media,
  meetingId,
  fileName,
  highlightsDispatch,
  querySocialMedia,
  queryHighlightId,
  hasModeratorPrivileges,
  unformattedTranscript,
  brandKit,
  userId,
}) => {
  const [socialMediaModal, setSocialMediaModal] = useState(
    queryHighlightId === highlightId && querySocialMedia
      ? querySocialMedia
      : false
  );

  const handleMarketingAssetButtonClick = (option) => {
    if (activatedMarketingOptions.includes(option)) {
      setSocialMediaModal(option);
    }
  };

  return (
    <>
      {socialMediaModal && (
        <SocialMediaModal
          onCancel={() => setSocialMediaModal(false)}
          socialMediaType={socialMediaModal}
          socialMediaPosts={socialMediaPosts}
          media={media}
          highlightId={highlightId}
          meetingId={meetingId}
          fileName={fileName}
          highlightsDispatch={highlightsDispatch}
          hasModeratorPrivileges={hasModeratorPrivileges}
          unformattedTranscript={unformattedTranscript}
          brandKit={brandKit}
          userId={userId}
        />
      )}
      <div className="flex flex-col gap-[15px]">
        <div className="font-medium text-blue-dark text-xs">
          Create Marketing Assets
        </div>
        <div className="flex gap-2.5 flex-wrap">
          {Object.entries(marketingOptions).map(([key, option]) => {
            const { disabled = false } = option?.doesClipMeetCondition(
              media[0]
            );

            if (disabled) return null;

            return (
              <button
                className="flex gap-1"
                key={`MarketingOption-${highlightId}-${key}`}
                onClick={() => handleMarketingAssetButtonClick(key)}
              >
                {option.icon()}
                <div className="text-blue-gray font-medium text-xs underline decoration-blue-gray">
                  {option.modalTitle}
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
};

export const Highlight = ({
  highlight,
  index,
  recordings,
  meetingId,
  hasModeratorPrivileges,
  highlightsDispatch,
  queryHighlightId,
  querySocialMedia,
  transcript,
  localTimezone,
  brandKit,
  userId,
  showWatermark,
}) => {
  const {
    media,
    title,
    highlightId,
    socialMediaPosts,
    startTime: startTimeArray,
    endTime: endTimeArray,
    hidden,
    autoGenerateSocialMediaVideo,
  } = highlight;
  const recordingStartMs = convertISOStringToMS(recordings[0].recordingStart);
  const [isCollapsed, setIsCollapsed] = useState(
    index === 0 || highlightId === queryHighlightId ? false : true
  );
  const originalHighlightClip = media.length !== 0 ? media[0] : null;
  const startTime = startTimeArray[0];
  const endTime = endTimeArray[0];
  const fileName = 'Highlight' + (index + 1) + title.replace(/[&\s]+/g, '');
  const [filteredTranscript, setFilteredTranscript] = useState([]);

  const handleDeleteHighlightClick = async () => {
    const { error } = await deleteHighlightApi(meetingId, highlightId);

    if (error) {
      return;
    }

    highlightsDispatch({
      type: 'DELETE_HIGHLIGHT',
      meetingId,
      highlightId,
    });
  };

  useEffect(() => {
    if (transcript.length === 0) return;
    setFilteredTranscript(
      transcript.filter(
        (voiceTranscriptMessage) =>
          voiceTranscriptMessage.timestamp >=
            recordingStartMs + startTime * 1000 &&
          voiceTranscriptMessage.timestamp <= recordingStartMs + endTime * 1000
      )
    );
  }, [endTime, recordingStartMs, startTime, transcript]);

  if (!originalHighlightClip || hidden) {
    return null;
  }

  return (
    <div
      className="flex flex-col shadow-elevation-one rounded-lg border-[#EBEAED] border"
      id={highlightId}
    >
      <div className="flex flex-row justify-between items-center py-3 px-5 h-12 bg-[#F9F5FF]">
        <div className="text-blue-dark text-sm font-medium truncate">
          Highlight #{index + 1} - {title}
        </div>
        <div className="flex gap-5">
          <button onClick={() => setIsCollapsed((prevState) => !prevState)}>
            {isCollapsed ? (
              <CaretIcon width="15px" height="11px" />
            ) : (
              <CaretIcon
                width="15px"
                height="11px"
                className="rotate-180 fill-blue-gray"
              />
            )}
          </button>
        </div>
      </div>
      <div className={classNames('relative', isCollapsed ? 'hidden' : 'block')}>
        {showWatermark && !autoGenerateSocialMediaVideo && (
          <div className="absolute z-10 flex w-full h-full top-0 left-0 rounded-b justify-center items-center">
            <div className="p-8 bg-white w-80 rounded-md flex flex-col gap-2">
              <div className="text-blue-dark text-sm font-medium">
                Free plan limit reached
              </div>
              <div className="text-blue-gray text-xs">
                You have access to only limited highlights under the Free plan.
                Please upgrade to see all highlights.
              </div>
              <Link
                to="/workspace/billing"
                className="bg-purple py-2 w-full text-center text-white rounded-md cursor-pointer"
              >
                Upgrade
              </Link>
            </div>
          </div>
        )}
        <div
          className={classNames(
            'flex flex-col gap-5 p-5 relative',
            showWatermark && !autoGenerateSocialMediaVideo && 'blur'
          )}
        >
          <div
            className={classNames(
              'flex gap-5 md:flex-row flex-col',
              showWatermark && !autoGenerateSocialMediaVideo && 'z-0'
            )}
          >
            <div className="md:min-w-[440px] md:h-[224px] w-full">
              <ClipRecording
                playbackId={originalHighlightClip.renderData.playbackId}
                className="h-full w-full rounded-lg"
                userId={userId}
                meetingId={meetingId}
              />
            </div>
            <div className="flex flex-col gap-[30px]">
              <DownloadAssets
                clipText="Video Clip (Unedited)"
                playbackId={originalHighlightClip.renderData.playbackId}
                clipFileName={fileName}
                clipFiles={originalHighlightClip.renderData.files}
                transcript={filteredTranscript}
                localTimezone={localTimezone}
              />
              <MarketingAssets
                highlightId={highlightId}
                socialMediaPosts={socialMediaPosts}
                media={media}
                meetingId={meetingId}
                fileName={fileName}
                highlightsDispatch={highlightsDispatch}
                querySocialMedia={querySocialMedia}
                queryHighlightId={queryHighlightId}
                hasModeratorPrivileges={hasModeratorPrivileges}
                unformattedTranscript={filteredTranscript}
                brandKit={brandKit}
                userId={userId}
              />
            </div>
          </div>
          {hasModeratorPrivileges && (
            <button
              className="w-fit h-fit flex gap-1 justify-center items-end"
              onClick={handleDeleteHighlightClick}
            >
              <TrashIcon />
              <div className="text-blue-gray font-medium text-xs underline decoration-blue-gray">
                Delete Highlight
              </div>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
