import mixpanel from 'mixpanel-browser';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from '../../helper/classNames';
import { ScenePreview } from '../authoring/Menu/ScenePreview';
import { SlideTrayItemContainer } from '../authoring/Menu/SlideTray';
import { LoadingSpinnerAbsoluteFullCentered } from '../LoadingSpinner';

const sceneWidth = 128;

const SlideSeeker = ({ currentSlideIndex, slides, startSlide, brandKit }) => {
  const slideContainerRef = useRef(null);
  const currentSlideRef = useRef(null);
  const [sceneClicked, setSceneClicked] = useState(null);

  useEffect(() => {
    setSceneClicked(false);
    if (currentSlideRef.current) {
      currentSlideRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  }, [currentSlideIndex]);

  const selectScene = useCallback(
    (index) => {
      mixpanel.track('Meeting Panel - Scene Selection', {
        currentSceneId: slides[currentSlideIndex]?.sceneId,
        newSceneId: slides[index]?.sceneId,
      });
      if (index === currentSlideIndex) return;
      setSceneClicked(index);
      startSlide(index, false);
    },
    [startSlide, slides, currentSlideIndex]
  );

  if (!slides || (slides || []).length === 0) return null;

  return (
    <div className="h-full">
      <SlideSeekerItemsContainer containerRef={slideContainerRef}>
        {slides.map((scene, index) => (
          <SlideSeekerItemContainer
            key={scene.sceneId}
            currentSlideRef={
              index === currentSlideIndex ? currentSlideRef : undefined
            }
            isActive={currentSlideIndex === index}
            onClick={() => selectScene(index)}
          >
            {sceneClicked !== null && sceneClicked === index && (
              <LoadingSpinnerAbsoluteFullCentered />
            )}
            <ScenePreview
              scene={scene}
              width={sceneWidth}
              height={(sceneWidth * 9) / 16}
              brandKit={brandKit}
            />
            <p className="text-xxs text-blue-gray font-semibold mt-1.5">
              {scene.slideName ?? `Scene ${index + 1}`}
            </p>
          </SlideSeekerItemContainer>
        ))}
      </SlideSeekerItemsContainer>
    </div>
  );
};

const SlideSeekerItemsContainer = React.memo(({ containerRef, children }) => (
  <div ref={containerRef} className="flex flex-col items-center gap-2">
    {children}
  </div>
));

const SlideSeekerItemContainer = ({
  children,
  isActive,
  currentSlideRef,
  onClick,
}) => (
  <SlideTrayItemContainer
    ref={currentSlideRef}
    className={classNames(
      'relative p-2 transition-opacity h-unset w-unset border-4 rounded-xl',
      isActive ? 'border-purple' : 'border-white'
    )}
    onClick={onClick}
  >
    {children}
  </SlideTrayItemContainer>
);

export default SlideSeeker;
