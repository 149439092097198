import React, { useCallback, useEffect, useMemo } from 'react';
import styled, { css, keyframes } from 'styled-components/macro';
import { playEmojiSound } from '../helper/audio';
import { throttle } from 'lodash';

const THROTTLE_INTERVAL = 1000;
const DUPLICATE_EMOJI_LIMIT = 10;

const EMOJIS = [
  /*
  Names may not be unique in this list.
  Use the 'code' for any lookup / filtering
  based on a unique identifier.
  */
  {
    name: 'PARTY_POPPERS',
    code: 0x1f389,
  },
  {
    name: 'CLAP',
    code: 0x1f44f,
  },
  {
    name: 'TEARS_OF_JOY',
    code: 0x1f602,
  },
  {
    name: 'RAISED_HANDS',
    code: 0x1f64c,
  },
  {
    name: 'THUMBS_UP',
    code: 0x1f44d,
  },
  {
    name: 'STAR_STRUCK',
    code: 0x1f929,
  },
];

const MORE_EMOJIS = [
  {
    name: 'SIDE_EYES',
    code: 0x1f440,
  },
  {
    name: 'SHOCKED_FACE',
    code: 0x1f62e,
  },
  {
    name: 'THINKING_FACE',
    code: 0x1f914,
  },
  {
    name: 'ROCKET_SHIP',
    code: 0x1f680,
  },
  {
    name: 'BICEP_ARM',
    code: 0x1f4aa,
  },
  {
    name: 'ROCK_HAND',
    code: 0x1f91f,
  },
  {
    name: 'FINGERS_CROSSED',
    code: 0x1f91e,
  },
  {
    name: 'THANKFUL',
    code: 0x1f64f,
  },
];

/** Keyframes for an animated reaction emoji. */
const floater = (left, scale) => keyframes`
  0% {
    opacity: 1.0;
    transform: translateX(${left}px) scale(${scale}) translateY(70vh);
  }
  25% {
    transform: translateX(${left + 10}px) scale(${scale}) translateY(60vh);
  }
  50% {
    transform: translateX(${left}px) scale(${scale}) translateY(50vh);
  }
  75% {
    transform: translateX(${left - 10}px) scale(${scale}) translateY(40vh);
  }
  100% {
    transform: translateX(${left}px) scale(${scale}) translateY(30vh);
  }
`;

const reactionVariants = [
  { left: 100, duration: 2, delay: 1 },
  { left: 650, duration: 2, delay: 2 },
  { left: 1000, duration: 2, delay: 3 },
];

const reactionSizeVariants = {
  l: {
    scale: 1.5,
    animationTiming: 'cubic-bezier(0.1, 0.7, 0.3, 0.1)',
    transitionTiming: 'cubic-bezier(0.1, 0.7, 1, 0.1)',
  },
  m: {
    scale: 1.2,
    animationTiming: 'cubic-bezier(0.1, 0.7, 0.3, 0.1)',
    transitionTiming: 'cubic-bezier(0.1, 0.7, 1, 0.1)',
  },
  s: {
    scale: 1,
    animationTiming: 'cubic-bezier(0.1, 0.7, 1, 0.1)',
    transitionTiming: 'cubic-bezier(0.1, 0.7, 1, 0.1)',
  },
};

export const AnimatedReactionsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  & > div {
    z-index: ${({ theme }) => theme.zIndex?.floatingReaction};
  }
`;

/** The reactions bar sans positioning. */
export const ReactionsBar = ({
  /** The background color. */
  background,
  /** Handler for when a specific emoji is clicked. */
  onClick,
  /** Whether to display the emoji options vertically. */
  vertical,
  /** True / False based on Authoring Block settings.*/
  moreEmojis,
}) => {
  const handleClick = useCallback(
    (e) => onClick && onClick(String.fromCodePoint(e.target.id)),
    [onClick]
  );

  const throttledHandleClick = useMemo(
    () =>
      throttle(handleClick, THROTTLE_INTERVAL, {
        leading: true,
        trailing: false,
      }),
    [handleClick]
  );

  const emojis = moreEmojis ? [...EMOJIS, ...MORE_EMOJIS] : EMOJIS;
  return (
    <div
      style={{ background: `${background ?? '#3d3d46'}` }}
      className={`absolute w-max grid p-3 rounded-xl ${
        vertical
          ? moreEmojis
            ? 'grid-rows-7 grid-cols-2 gap-y-2 gap-x-3'
            : 'grid-rows-6 gap-y-2 gap-x-2'
          : moreEmojis
          ? 'moreReactionsContainer grid-cols-7 grid-rows-2 gap-x-4 gap-y-2'
          : 'reactionsContainer grid-cols-6 gap-x-4 gap-y-2'
      }`}
    >
      {emojis.map((emoji) => (
        <div
          key={emoji.code}
          id={emoji.code}
          onClick={throttledHandleClick}
          className={`${
            Boolean(vertical) && 'mt-0 mr-1 mb-0 ml-1'
          } opacity-90 transition ease-in-out duration-150 hover:opacity-100 active:translate-y-px cursor-pointer text-xl`}
        >
          {String.fromCodePoint(emoji.code)}
        </div>
      ))}
    </div>
  );
};

export const AnimatedReactionElement = styled.div`
  ${({ size, variant }) => {
    const sizeVariant = reactionSizeVariants[size];
    const reactionVariant = reactionVariants[variant];

    return css`
      animation-timing-function: ${sizeVariant.animationTiming};
      transition-timing-function: ${sizeVariant.transitionTiming};

      animation: ${floater(reactionVariant.left, sizeVariant.scale)}
        ${reactionVariant.duration}s 1;
      animation-delay: ${reactionVariant.delay}s;
    `;
  }}

  position: absolute;
  font-size: 30px;
  animation-timing-function: linear;
  left: -50px;
`;

/** Animated reactions for a specific emoji that play over the entire slide. */
export const AnimatedReactions = ({ emoji, enableAudio, outputDeviceId }) => {
  emoji = emoji || String.fromCodePoint(EMOJIS[0].code);

  useEffect(() => {
    if (enableAudio) playEmojiSound(emoji, outputDeviceId);
  }, [emoji, enableAudio, outputDeviceId]);

  // s = small, m = medium, l = large, and the numbers are preset variations on x-axis, animation duration/delay.
  const variants = [
    ['s', 0],
    ['m', 1],
    ['l', 2],
  ];

  return (
    <AnimatedReactionsContainer>
      {variants.map(([size, variant], index) => (
        <AnimatedReactionElement
          key={`${size}-${variant}-${index}`}
          size={size}
          variant={variant}
        >
          {emoji}
        </AnimatedReactionElement>
      ))}
    </AnimatedReactionsContainer>
  );
};

export const LimitedReactions = ({
  reactions,
  disableAudio,
  outputDeviceId,
  userSentReactions,
}) => {
  const limitedReactions = useMemo(() => {
    if (!reactions) return [];
    if (reactions.length === 0) return [];
    const reactionsCount = {};
    const newReactions = [];
    for (let i = reactions.length - 1; i >= 0; i--) {
      if (reactionsCount.hasOwnProperty(reactions[i].emoji)) {
        if (reactionsCount[reactions[i].emoji] === DUPLICATE_EMOJI_LIMIT) {
          continue;
        } else {
          reactionsCount[reactions[i].emoji] += 1;
          newReactions.push(reactions[i]);
        }
      } else {
        reactionsCount[reactions[i].emoji] = 1;
        newReactions.push(reactions[i]);
      }
    }
    return newReactions;
  }, [reactions]);

  return (
    <>
      {userSentReactions &&
        userSentReactions.map((reaction) => (
          <AnimatedReactions
            key={reaction.timestamp}
            emoji={reaction.emoji}
            enableAudio={!disableAudio}
            outputDeviceId={outputDeviceId}
          />
        ))}
      {limitedReactions.map((reaction) => (
        <AnimatedReactions
          key={reaction.timestamp}
          emoji={reaction.emoji}
          enableAudio={!disableAudio}
          outputDeviceId={outputDeviceId}
        />
      ))}
    </>
  );
};
