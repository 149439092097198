import { useClickOutside } from '../../../../helper/useClickOutside';
import React from 'react';

export const NotSupportedPreviewComponent = ({
  onCancel,
  hostNotesComponent,
}) => {
  useClickOutside({ current: document.querySelector('.modal') }, onCancel);

  return (
    <div className="w-full h-full flex flex-col justify-between">
      <p className="mb-2">No configuration for this section</p>
      <div className="mt-auto">{hostNotesComponent}</div>
    </div>
  );
};
