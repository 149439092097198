import React, { useCallback, useContext } from 'react';
import { useAuthoringTool } from './hooks';
import { ConfirmationModal } from '../common/Modal';
import { AlertIcon } from '../common/Icon';
import { UserSettingsContext } from './userSettingsContext';

/** A confirmation modal for deleting a block. */
export const DeleteBlockConfirmation = ({ open, onClose }) => {
  const { handleUpdateSettings } = useContext(UserSettingsContext);

  const { removeSelectedSlideBlock } = useAuthoringTool();

  const handleDoNotShowBlockConfirmation = useCallback(
    (hideDeleteBlock) => {
      handleUpdateSettings({ hideDeleteBlock });
    },
    [handleUpdateSettings]
  );

  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      icon={AlertIcon}
      title="Delete Block"
      text="Are you sure you want to delete this block?"
      color="#EE4540"
      onConfirm={removeSelectedSlideBlock}
      confirmLabel="Delete"
      doNotShowAgainHandler={handleDoNotShowBlockConfirmation}
    />
  );
};
