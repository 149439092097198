import React, { useEffect, useState } from 'react';
import classNames from '../../helper/classNames';
import { LoadingSpinner } from '../../components/LoadingSpinner';

const messages = [
  'Preparing your session.',
  'Generating talking points.',
  'Writing video scripts.',
  'Setting up studio.',
];

const getRandomMessage = () =>
  messages[Math.floor(Math.random() * messages.length)];

let interval;

export const LoadingSoloEpisodeCard = () => {
  const [message, setMessage] = useState(getRandomMessage());

  useEffect(() => {
    interval = setInterval(() => setMessage(getRandomMessage()), 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <article
        className={classNames(
          '',
          `flex md:gap-6 mx gap-2 p-4 pl-6 border border-[#E6E8F0] relative md:items-center md:flex-row flex-col min-h-[72px]`
        )}
      >
        <div className="-m-4 flex items-center gap-3">
          <LoadingSpinner width="50px" thickness="3px" />{' '}
          <span className="text-[#888888] font-semibold">{message}</span>
        </div>
      </article>
    </div>
  );
};
