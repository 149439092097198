import { Checkbox } from '../../Checkbox/Checkbox';
import { MeetingAttendeeRow } from './MeetingAttendeeRow';
import { MenuDownArrow } from '../../icons/MenuDownArrow';
import { useState } from 'react';

/**
 * Contains an array of MeetingAttendee components.
 */
export const MeetingAttendeesTable = ({
  joinedUsers,
  selectedUsers,
  setSelectedUsers,
  searchValue,
  editingUser,
  meetingId,
  roleSuggestions,
  handleEjectMeetingUser,
  isEjectingUserLoading,
  ejectedUser,
  selectedUser,
  setSelectedUser,
  showEjectUserPopup,
}) => {
  const [checkedAttendeesBox, setCheckedAttendeesBox] = useState(false);
  const currentSearchValue = searchValue.toLowerCase();

  let searchedUsers = joinedUsers.filter(
    (user) =>
      user?.userName?.toLowerCase().includes(currentSearchValue) ||
      user?.emailAddress?.includes(currentSearchValue)
  );

  const updateAllSelectedUsers = (value) => {
    if (!value) {
      setSelectedUsers({});
    } else {
      const updatedUsers = {};
      for (let user of searchedUsers) {
        updatedUsers[[user?.userId]] = true;
      }
      setSelectedUsers(updatedUsers);
    }
  };

  const updateSelectedUser = (userToUpdate) => {
    const userId = userToUpdate?.userId;
    let updatedUsers = {};
    if (Object.keys(selectedUsers).includes(userToUpdate?.userId)) {
      const updatedUsersArr = Object.keys(selectedUsers).filter(
        (currentUserId) => currentUserId !== userId
      );
      updatedUsersArr.forEach((userId) => (updatedUsers[[userId]] = true));
    } else {
      updatedUsers = { ...selectedUsers, [userId]: true };
    }

    const checkedUsersLength = Object.entries(updatedUsers).length;

    if (checkedUsersLength === joinedUsers.length) {
      setCheckedAttendeesBox(true);
    } else {
      setCheckedAttendeesBox(false);
    }
    setSelectedUsers(updatedUsers);
  };

  return (
    <table className="w-full">
      <thead className="block h-14">
        <tr className="flex font-medium text-blue-gray h-14 bg-gray bg-opacity-30 px-7 items-center w-full">
          <td className="w-1/2 flex items-center space-x-2">
            <Checkbox
              checked={checkedAttendeesBox}
              handleChange={() => {
                setCheckedAttendeesBox(!checkedAttendeesBox);
                updateAllSelectedUsers(!checkedAttendeesBox);
              }}
            />

            <div className="space-x-1">
              <span>ATTENDEES</span>
              <span>({searchedUsers.length})</span>
            </div>
          </td>
          <td className="w-1/2 relative">
            <span>ROLES</span>
            <div className="absolute left-14 top-1/2 -translate-y-1/2">
              <MenuDownArrow />
            </div>
          </td>
          <td className="w-1/12">ACTIONS</td>
        </tr>
      </thead>
      <tbody className="overflow-auto block h-[50vh]">
        {searchedUsers.map((user) => (
          <MeetingAttendeeRow
            key={user?.userId}
            user={user}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            updateSelectedUser={updateSelectedUser}
            editingUser={editingUser}
            selectedUsers={selectedUsers}
            meetingId={meetingId}
            roleSuggestions={roleSuggestions}
            handleEjectMeetingUser={handleEjectMeetingUser}
            isEjectingUserLoading={isEjectingUserLoading}
            ejectedUser={ejectedUser}
            showEjectUserPopup={showEjectUserPopup}
          />
        ))}
      </tbody>
    </table>
  );
};
