import React from 'react';
import { CloseButtonContainer, defaultCloseButtonSize } from '../CloseButton';

/**
 * @see CloseButton
 */
export const CircularMenuButton = React.forwardRef(
  (
    {
      /** Handles when the button is clicked. */
      onClick,
      /** Whether the button will respond to interaction. */
      isDisabled,
      /** The diameter or size of the button. */
      size = defaultCloseButtonSize,

      content = '⋮',
      ...rest
    },
    ref
  ) => (
    <CloseButtonContainer
      {...rest}
      ref={ref}
      light={rest.light !== undefined ? rest.light : true}
      onClick={isDisabled ? undefined : onClick}
      size={size}
    >
      {content}
    </CloseButtonContainer>
  )
);
