import React from 'react';

export const CrossIcon = ({
  width = 6,
  height = 6,
  className = 'fill-white',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 4 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M3.54491 2.93015C3.59325 2.97552 3.62067 3.03886 3.62067 3.10515C3.62067 3.17145 3.59325 3.23479 3.54491 3.28015L3.27991 3.54515C3.23454 3.59349 3.1712 3.62091 3.10491 3.62091C3.03862 3.62091 2.97528 3.59349 2.92991 3.54515L1.99991 2.62015L1.06991 3.54515C1.02454 3.59349 0.961203 3.62091 0.89491 3.62091C0.828617 3.62091 0.765276 3.59349 0.71991 3.54515L0.45491 3.28015C0.406571 3.23479 0.37915 3.17145 0.37915 3.10515C0.37915 3.03886 0.406571 2.97552 0.45491 2.93015L1.37991 2.00015L0.45491 1.07015C0.406571 1.02479 0.37915 0.961447 0.37915 0.895154C0.37915 0.828861 0.406571 0.765521 0.45491 0.720154L0.71991 0.455154C0.765276 0.406815 0.828617 0.379395 0.89491 0.379395C0.961203 0.379395 1.02454 0.406815 1.06991 0.455154L1.99991 1.38015L2.92991 0.455154C2.97528 0.406815 3.03862 0.379395 3.10491 0.379395C3.1712 0.379395 3.23454 0.406815 3.27991 0.455154L3.54491 0.720154C3.59325 0.765521 3.62067 0.828861 3.62067 0.895154C3.62067 0.961447 3.59325 1.02479 3.54491 1.07015L2.61991 2.00015L3.54491 2.93015Z" />
    </svg>
  );
};
