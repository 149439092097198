import React from 'react';
import { MenuContainer } from './Menu.style';
import { getFormattedCategoryName } from '../../../hooks/useTemplateGallery';
import { CloseButton } from '../../Modal';

/**
 * The component for the retractable portion of the left-hand menu. This is a container
 * which can be opened and closed based on the actions in the LeftBar. Content can be specified
 * via children. Content can be optionally opened in a full screen modal.
 */
export const LeftMenu = ({ open, onClose, children, title }) => {
  return (
    open && (
      <MenuContainer className="box-border border-r border-light-gray">
        <CloseButton
          onCancel={onClose}
          closeButtonColor={CloseButton.color.BLUE_GRAY}
          closeButtonPosition="absolute top-[19px] right-[18px]"
        />
        {title && (
          <div className="text-blue-dark font-medium text-sm">
            {getFormattedCategoryName(title)}
          </div>
        )}
        {children}
      </MenuContainer>
    )
  );
};
