import React from 'react';
import { updateSeriesApi } from '../../../helper/api';
import { MeetingTopic } from '../../Portal/MeetingTopic';

export const PlanTitleCard = ({
  series,
  localDispatch,
  previewComponent,
  title,
  setTitle,
  disableEventPage,
}) => {
  const { title: meetingTitle, meetingSeriesId } = series || {};

  const updateMeetingTitle = async () => {
    if (title === meetingTitle) return;
    const result = await updateSeriesApi(meetingSeriesId, { title });
    localDispatch({ type: 'REFRESH_SERIES', series: result });
  };

  return (
    <>
      <div className="px-5 flex w-full gap-[43px]">
        <div className="w-[434px]">
          <MeetingTopic
            title={title}
            setTitle={setTitle}
            borderColor={MeetingTopic.borderColor.LIGHT_GRAY}
            borderRadius={MeetingTopic.borderRadius.ROUNDED}
            borderWidth={MeetingTopic.borderWidth.THIN}
            handleOnBlur={updateMeetingTitle}
            disabled={disableEventPage}
          />
        </div>
        {previewComponent}
      </div>
    </>
  );
};
