import hark from 'hark';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';

// Clones the active microphone MediaTrack and emits events when the volume changes.
//
// This will continue to fire even when the microphone is muted.
export const useDailyMicVolume = (onVolumeChange, disabled) => {
  const localTrackState = useSelector((state) => state.callState.tracks.local);
  const dailyMicTrack = localTrackState?.audioTrackState?.persistentTrack;
  const [audioTrack, setAudioTrack] = useState(null);

  const stopMicVolumeTrack = useCallback(() => {
    if (audioTrack) audioTrack.stop();
  }, [audioTrack]);

  useEffect(() => {
    if (!dailyMicTrack || disabled) return;
    const activeMicClone = dailyMicTrack.clone();
    activeMicClone.enabled = true;
    setAudioTrack(activeMicClone);

    return () => {
      if (activeMicClone) activeMicClone.stop();
    };
  }, [dailyMicTrack, disabled]);

  useEffect(() => {
    if (!audioTrack) return;
    const volumeEvents = hark(new MediaStream([audioTrack]), {});
    volumeEvents.on('volume_change', function (volume) {
      if (onVolumeChange) onVolumeChange(volume);
    });
    return () => {
      volumeEvents.stop();
    };
  }, [audioTrack, onVolumeChange]);

  return { stopMicVolumeTrack };
};
