import { useFeedPage } from './useFeedPage';
import React, { useEffect, useRef } from 'react';
import classNames from '../../helper/classNames';

export const PlayListItem = ({
  borderColor,
  thumbnailUrl,
  title,
  onClick,
  isSelected,
}) => {
  return (
    <button
      className={classNames(
        'block md:flex gap-4 items-center border-2 hover:bg-white/10 py-3 px-2 rounded-lg w-full',
        isSelected ? 'selected' : ''
      )}
      style={{
        backgroundColor: isSelected ? 'rgba(255,255,255,0.1)' : undefined,
        borderColor: isSelected ? borderColor : 'transparent',
      }}
      onClick={onClick}
    >
      <div className="w-full md:w-[130px] md:shrink-0 mb-2 md:mb-0">
        <img
          src={thumbnailUrl}
          alt=""
          width={130}
          className="rounded-lg w-full"
        />
      </div>
      <h3 className="text-[#101840] font-semibold text-xl md:text-base line-clamp-2 text-left">
        {title}
      </h3>
    </button>
  );
};

export const PlayList = () => {
  const {
    allHighlights,
    selectedHighlight,
    setSelectedHighlight,
    isLoading,
    workspace,
  } = useFeedPage();

  const listRef = useRef(null);

  const selectedHighlightId = selectedHighlight?.highlightId;

  const handleScrollTop = () => {
    if (listRef.current) {
      listRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (selectedHighlightId && listRef.current) {
      const elementToScroll = document.querySelector('.selected');

      if (elementToScroll) {
        setTimeout(() => {
          listRef.current.scrollTo({
            top: elementToScroll.offsetTop - listRef.current.offsetTop,
            behavior: 'smooth',
          });
        }, 300);
      }
    }
  }, [selectedHighlightId]);

  if (isLoading) {
    return null;
  }

  return (
    <div>
      <button
        onClick={handleScrollTop}
        className="cursor-pointer text-[#101840] font-semibold md:font-medium text-xl md:text-base ml-2 uppercase"
      >
        More Video
      </button>
      <ul
        className="flex-[415_0_0%] h-full md:max-w-[415px] space-y-3 max-h-[425px] overflow-auto transparent-scrollbar"
        ref={listRef}
      >
        {allHighlights.map((renderedHighlight) => (
          <li key={renderedHighlight.highlightId}>
            <PlayListItem
              title={renderedHighlight.title}
              thumbnailUrl={renderedHighlight.HIGHLIGHT_THUMBNAIL.imageUrl}
              onClick={() => setSelectedHighlight(renderedHighlight)}
              isSelected={
                selectedHighlight?.highlightId === renderedHighlight.highlightId
              }
              borderColor={workspace?.brandKit.accentColor}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
