import { logerror } from './contextualLogger';

export const SeriesVisibility = {
  PUBLIC: 'public',
  PRIVATE: 'private',
  RESTRICTED: 'restricted',
  RSVP: 'rsvp',
};

export const isAttendee = (user, series) => {
  const attendee = (series?.attendees || []).find(
    (attendee) => attendee.emailAddress === user.userId
  );
  return !!attendee;
};
const isWorkspaceMember = (user, workspaceId) =>
  workspaceId &&
  !!(user.workspaces || []).find((w) => w.workspaceId === workspaceId);

export const isAuthorized = (user, series) => {
  if (!series) return false;
  const { userId, recordingUser = false } = user || {};
  if (recordingUser) return true;
  let result = false;
  const { meetingSeriesId, workspace: { workspaceId } = {} } = series;
  switch (series.visibility) {
    case SeriesVisibility.RSVP:
    case SeriesVisibility.PUBLIC:
      result = true;
      break;
    case SeriesVisibility.PRIVATE:
      if (!user) return false;
      result = isAttendee(user, series);
      break;
    case SeriesVisibility.RESTRICTED:
      if (!user) return false;
      result = isWorkspaceMember(user, workspaceId) || isAttendee(user, series);
      break;
    default:
      logerror({
        userId,
        meetingSeriesId,
        message: 'Invalid visibility setting for meeting series.',
      });
  }
  return result;
};
