import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import classNames from '../helper/classNames';
import { AngleIcon } from './icons/AngleIcon';
import { useClickOutside } from '../helper/useClickOutside';
import { CalendarIcon } from './icons/CalendarIcon';

const DAYS_OF_WEEK = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

export const DatePicker = ({
  daySelected,
  setDaySelected,
  label = '',
  disabled,
}) => {
  const [dateSelectionOpen, setDateSelectionOpen] = useState(false);
  const [calendar, setCalendar] = useState([]);
  const [momentState, setMomentState] = useState(moment());
  const dateSelectionRef = useRef(null);

  const handleClickOutside = () => {
    setDateSelectionOpen(false);
  };

  useClickOutside(dateSelectionRef, handleClickOutside);

  // Build the calendar array
  useEffect(() => {
    const startDay = momentState.clone().startOf('month').startOf('week');
    const endDay = momentState.clone().endOf('month').endOf('week');
    const day = startDay.clone().subtract(1, 'day');
    const calendarArray = [];
    while (day.isBefore(endDay, 'day')) {
      calendarArray.push(
        Array(7)
          .fill(0)
          .map(() => day.add(1, 'day').clone())
      );
    }
    setCalendar(calendarArray);
  }, [momentState]);

  const getPrevMonth = () => momentState.clone().subtract(1, 'month');

  const getNextMonth = () => momentState.clone().add(1, 'month');

  const getCurrMonthName = () => momentState.format('MMMM');

  const getCurrYear = () => momentState.format('YYYY');

  const isCalendarOnThisMonth = () => momentState.isSame(new Date(), 'month');

  const beforeToday = (day) => day.isBefore(new Date(), 'day');

  const isDaySelected = (day) => daySelected.isSame(day, 'day');

  return (
    <div className="flex flex-col relative w-full">
      <div className="text-blue-dark text-sm font-medium">{label}</div>
      <button
        className={classNames(
          `flex justify-between p-2 border border-solid bg-white rounded text-sm text-blue-gray w-full mt-2`,
          disabled && 'bg-gray/30 opacity-50 pointer-events-none'
        )}
        style={{ borderColor: dateSelectionOpen ? '#8B33F7' : '#D8DAE5' }}
        onClick={() =>
          setDateSelectionOpen((wasDateSelectionOpen) => !wasDateSelectionOpen)
        }
      >
        {daySelected.format('Do MMM, YYYY')}
        <CalendarIcon color={dateSelectionOpen ? '#8B33F7' : '#696F8C'} />
      </button>
      {dateSelectionOpen && (
        <div
          className="flex flex-col p-5 shadow-md rounded-[4px] absolute top-16 bg-white z-[1002]"
          ref={dateSelectionRef}
        >
          <div className="flex justify-between">
            <div className="text-base font-medium">
              {getCurrMonthName()} {getCurrYear()}
            </div>
            <div className="flex gap-8 items-center">
              {!isCalendarOnThisMonth() && (
                <button
                  className="flex justify-center items-center"
                  onClick={() => setMomentState(getPrevMonth())}
                >
                  <AngleIcon />
                </button>
              )}
              <button
                className="flex justify-center items-center rotate-180"
                onClick={() => setMomentState(getNextMonth())}
              >
                <AngleIcon />
              </button>
            </div>
          </div>
          <div className="flex text-black/50">
            {DAYS_OF_WEEK.map((d, index) => (
              <div
                className="w-9 h-9 flex justify-center items-center text-xs"
                key={d + index}
              >
                {d}
              </div>
            ))}
          </div>
          {calendar.map((week, index) => (
            <div className="flex" key={'week' + index}>
              {week.map((day, index) => (
                <button
                  className={classNames(
                    'w-9 h-9 flex justify-center items-center text-sm rounded-full',
                    isDaySelected(day, 'day')
                      ? 'bg-purple text-white'
                      : 'text-black/90 hover:bg-purple/20',
                    beforeToday(day) && 'hover:bg-black/20'
                  )}
                  onClick={() => !beforeToday(day) && setDaySelected(day)}
                  key={day.format('D').toString() + index + 'day'}
                >
                  {day.format('D').toString()}
                </button>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
