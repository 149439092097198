export const Checkbox = ({ checked = false, handleChange }) => {
  return (
    <label className="relative flex justify-center items-center">
      <svg
        width="10"
        height="8"
        viewBox="0 0 10 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="cursor-pointer absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 fill-white"
      >
        <path d="M9 0.5C8.72 0.5 8.47 0.61 8.29 0.79L4 5.09L1.71 2.79C1.53 2.61 1.28 2.5 1 2.5C0.45 2.5 0 2.95 0 3.5C0 3.78 0.11 4.03 0.29 4.21L3.29 7.21C3.47 7.39 3.72 7.5 4 7.5C4.28 7.5 4.53 7.39 4.71 7.21L9.71 2.21C9.89 2.03 10 1.78 10 1.5C10 0.95 9.55 0.5 9 0.5Z" />
      </svg>
      <input
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        className="cursor-pointer bg-white appearance-none border border-solid border-gray w-4 h-4 rounded-sm checked:bg-purple checked:border-purple"
      />
    </label>
  );
};
