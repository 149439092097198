import React from 'react';

export const ImageIcon = ({
  width = '20px',
  height = '20px',
  className = 'fill-purple',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.666 3.33203H3.33268C2.41221 3.33203 1.66602 4.07822 1.66602 4.9987V14.9987C1.66602 15.9192 2.41221 16.6654 3.33268 16.6654H16.666C17.5865 16.6654 18.3327 15.9192 18.3327 14.9987V4.9987C18.3327 4.07822 17.5865 3.33203 16.666 3.33203ZM16.666 14.0237C16.6683 14.1349 16.6251 14.2422 16.5465 14.3209C16.4679 14.3995 16.3605 14.4427 16.2493 14.4404C16.1176 14.4431 15.9929 14.3808 15.916 14.2737L12.2077 9.33203C12.1271 9.23012 12.0043 9.17069 11.8743 9.17069C11.7444 9.17069 11.6216 9.23012 11.541 9.33203L9.16602 12.4237C9.08649 12.5276 8.96353 12.5891 8.83268 12.5904C8.70995 12.5905 8.59366 12.5354 8.51602 12.4404L7.28268 10.982C7.20244 10.8857 7.08307 10.8306 6.95768 10.832C6.83146 10.8349 6.71278 10.8928 6.63268 10.9904L4.07435 14.2737C3.99741 14.3752 3.87671 14.434 3.74935 14.432C3.63815 14.4343 3.53084 14.3912 3.45219 14.3125C3.37355 14.2339 3.33039 14.1266 3.33268 14.0154V4.9987H16.666V14.0237ZM7.91602 6.66536C7.22566 6.66536 6.66602 7.22501 6.66602 7.91536C6.66602 8.60572 7.22566 9.16537 7.91602 9.16537C8.60637 9.16537 9.16602 8.60572 9.16602 7.91536C9.16602 7.22501 8.60637 6.66536 7.91602 6.66536Z"
    />
  </svg>
);
