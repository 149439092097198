import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchStripeConfigApi } from '../helper/api';
import { logerror } from '../helper/contextualLogger';

export const useStripeConfig = () => {
  const userId = useSelector((state) => state.auth.user?.userId);
  const [stripeConfig, setStripeConfig] = useState(null);

  // Load the latest plan prices from the server.
  useEffect(() => {
    async function fetchStripConfig() {
      const { result, error } = await fetchStripeConfigApi();

      if (!result) {
        logerror({
          userId,
          message: 'Error fetching stripe configuration from API: ' + error,
        });
      }

      setStripeConfig(result);
    }

    fetchStripConfig();
  }, [userId]);

  return {
    stripeConfig,
  };
};
