export const TOP_BAR_HEIGHT_PX = 64;
export const BOTTOM_BAR_HEIGHT_PX = 145;
export const LEFT_TOOLBAR_WIDTH_PX = 90;
export const RIGHT_MENU_WIDTH_PX = 270;
export const BACKDROP_MENU_WIDTH_PX = 285;
export const BLOCKS_MENU_WIDTH_PX = 161;

export const TD_SUBMENUS = {
  BLOCKS: 'blocks',
  IMAGES: 'image',
  IMPORT: 'import',
  ACTIVITIES: 'activities',
};

export const COLORS = {
  primaryHeading: '#101840',
  paragraph: '#696F8C',
  active: '#00a3a5',
  inactive: '#e0e0e0',
};

export const DEFAULT_BLOCK_SIZE_PX = 100;
export const BLOCK_CORNER_HANDLE_SIZE_PX = 20;
export const BLOCK_EDGE_HANDLE_SIZE_PX = 7.5;
