import classNames from '../../helper/classNames';
import React from 'react';

export const FormSteps = ({
  checkpointsData,
  getProgressState,
  ProgressStates,
}) => {
  return (
    <div className="bg-white h-20 flex space-x-1 md:space-x-8 justify-center items-center text-light-gray">
      {Object.keys(checkpointsData).map((checkpoint, currentIdx) => (
        <div
          key={currentIdx}
          className={classNames('flex space-x-0.5 md:space-x-2 items-center')}
        >
          <div
            className={classNames(
              'bg-opacity-[5%] text-[0.5rem] font-bold h-fit rounded-sm',
              getProgressState(checkpoint) === ProgressStates.CURRENT &&
                'text-blue-light bg-blue-light',
              getProgressState(checkpoint) === ProgressStates.AFTER &&
                'text-purple bg-purple',
              getProgressState(checkpoint) === ProgressStates.BEFORE &&
                'text-light-gray bg-light-gray bg-opacity-100'
            )}
          >
            {getProgressState(checkpoint) === ProgressStates.AFTER ? (
              <div className="px-1 md:px-2 py-1"> &#10004; </div>
            ) : (
              <div className="px-[9px] py-[2px] text-xxs">{currentIdx + 1}</div>
            )}
          </div>
          <div
            className={classNames(
              'text-xxs md:text-xs font-bold whitespace-nowrap',
              getProgressState(checkpoint) === ProgressStates.CURRENT &&
                'text-blue-light',
              getProgressState(checkpoint) === ProgressStates.AFTER &&
                'text-purple',
              getProgressState(checkpoint) === ProgressStates.BEFORE &&
                'text-light-gray'
            )}
          >
            {checkpointsData[checkpoint].heading}
          </div>
        </div>
      ))}
    </div>
  );
};
