const { cloneDeep } = require('lodash/fp');
const {
  DynamicBlocks,
} = require('../../client/src/blocks/helper/dynamicBlockVariables');
const moment = require('moment-timezone');
const { getTimezoneAbbreviation } = require('./timezone');

const getDynamicValueMatch = (string) => {
  if (typeof string !== 'string') {
    return [];
  }

  return string.match(/\{\{([\s\S]*)\}\}/) || [];
};

/** overrideDynamicValue function accepts value that can be either dynamic or static, and valueMap object
 * When dynamic variable, such as "{{primaryColor}} is detected, it tells to get the value from valueMap object (the same key)
 * For example, this allows for getting dynamic values set in brandKit (workspace) context
 * */
const overrideDynamicValue = (value, valueMap) => {
  const dynamicValueMatch = getDynamicValueMatch(value);

  const dynamicValueProperty = dynamicValueMatch[1];

  return dynamicValueProperty && valueMap
    ? valueMap[dynamicValueProperty]
    : value;
};

const isTextual = (value) => !!value && typeof value === 'string';
const convertToDynamicValue = (value, valueMap) => {
  for (let key in valueMap) {
    if (valueMap[key] === value && isTextual(value)) {
      return `{{${key}}}`;
    }
  }

  return value;
};

const getUserNoBgImageFromUrl = (userAvatarUrl) =>
  userAvatarUrl?.replace('/bg/', '/no_bg/');

const getNoBgSpeakerImage = (speakerImageUrl) =>
  speakerImageUrl?.replace('/presenter/', '/presenter-no-bg/');

const findAndOverrideDynamicSettings = (brandKit) => (settings, blockId) => {
  const result = {};

  for (const setting in settings) {
    result[setting] = overrideDynamicValue(settings[setting], brandKit);
  }

  if (blockId === 'logo') {
    result.imageUrl = '{{logoUrl}}';
  }

  return result;
};

const overrideSceneSettings = (brandKit) => (scene) => {
  const newScene = findAndOverrideDynamicSettings(brandKit)(scene);

  return {
    ...newScene,
    slideConfig: {
      ...newScene.slideConfig,
      slideBlocks: newScene.slideConfig.slideBlocks.map((block) => ({
        ...block,
        settings: findAndOverrideDynamicSettings(brandKit)(
          block.settings,
          block.blockId
        ),
      })),
    },
  };
};
const overrideDynamicSceneAndBlockSettings = (series, meetingConfig, users) => {
  if (!series || !meetingConfig) {
    return meetingConfig;
  }

  const newConfig = cloneDeep(meetingConfig);

  newConfig.slides = meetingConfig.slides.map(
    overrideSceneSettings(series.workspace?.brandKit)
  );

  newConfig.slides.forEach((slide) => {
    slide.slideConfig.slideBlocks.forEach((block) => {
      block.settings = findAndOverrideDynamicSettings(
        series.workspace?.brandKit
      )(block.settings);
    });
  });

  const host = series?.settings?.eventPresenters.find((p) => p.type === 'host');

  const speakers = series?.settings?.eventPresenters.filter(
    (p) => p.type === 'speaker'
  );

  newConfig.slides = newConfig.slides.map((slide) => {
    const dynamicBlocks = new DynamicBlocks(slide.slideConfig.slideBlocks);
    dynamicBlocks
      .replaceWith('{event_title}', series?.title)
      .replaceWith(
        '{event_date}',
        series
          ? moment(series?.scheduledEvent?.startDate).format('D MMMM, yyyy')
          : undefined
      )
      .replaceWith(
        '{event_time}',
        series
          ? moment(series?.scheduledEvent?.startDate).format('h:mm A') +
              ' - ' +
              moment(series?.scheduledEvent?.endDate).format('h:mm A') +
              ' ' +
              getTimezoneAbbreviation(
                series?.scheduledEvent?.timezone ||
                  series?.scheduledEvent?.timezoneSelected
              )
          : undefined
      );

    const dynamicUsersMap = [
      ['{host_name}', host?.user?.userName],
      ['{host_bio}', host?.user?.bio || ' '],
      ['{host_image}', host?.user?.avatarUrl],
      ['{host_image_no_bg}', getUserNoBgImageFromUrl(host?.user?.avatarUrl)],
      ...users.map((user) => [
        `{${user._id}_name}`.replace(/\+/g, '\\+'),
        user.fullName,
      ]),
      ...users.map((user) => [
        `{${user._id}_bio}`.replace(/\+/g, '\\+'),
        user.jobTitle || ' ',
      ]),
      ...users.map((user) => [
        `{${user.emailAddress}_name}`.replace(/\+/g, '\\+'),
        user.fullName,
      ]),
      ...users.map((user) => [
        `{${user.emailAddress}_bio}`.replace(/\+/g, '\\+'),
        user.jobTitle || ' ',
      ]),
      ...speakers.map((user, i) => [
        `{speaker_${i + 1}_bio}`.replace(/\+/g, '\\+'),
        user.jobTitle || ' ',
      ]),
      ...speakers.map((user, i) => [
        `{speaker_${i + 1}_name}`.replace(/\+/g, '\\+'),
        user.fullName,
      ]),
      ...speakers.map((user, i) => [
        `{speaker_${i + 1}_image}`.replace(/\+/g, '\\+'),
        user.presenterPictureUrl,
      ]),
      ...speakers.map((user, i) => [
        `{speaker_${i + 1}_image_no_bg}`.replace(/\+/g, '\\+'),
        getNoBgSpeakerImage(user.presenterPictureUrl),
      ]),
    ];

    dynamicUsersMap.forEach(([dynamicVariable, staticValue]) => {
      dynamicBlocks.replaceWith(dynamicVariable, staticValue);
    });

    return {
      ...slide,
      slideConfig: {
        ...slide.slideConfig,
        slideBlocks: dynamicBlocks.getAll(),
      },
    };
  });

  return newConfig;
};

module.exports = {
  getDynamicValueMatch,
  convertToDynamicValue,
  overrideSceneSettings,
  overrideDynamicValue,
  overrideDynamicSceneAndBlockSettings,
  getUserNoBgImageFromUrl,
  getNoBgSpeakerImage,
};
