import React, {
  createContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import mixpanel from 'mixpanel-browser';
import { getMeetingSeriesProps } from 'zync-common/tracking';
import { logerror } from '../../helper/contextualLogger';
import { usePrevious } from '../../hooks/usePrevious';
import MeetingConfigStackManager from './MeetingConfigStackManager';
import { getSceneIdFromUrl } from './util';
import { cloneDeep, isEqual } from 'lodash/fp';

/** AuthoringTool Actions */
export const MATA = {
  INITIALIZE_STATE_FROM_EXTERNAL_DATA: 'INITIALIZE_STATE_FROM_EXTERNAL_DATA',
  REFRESH_STATE_FROM_SNAPSHOT: 'REFRESH_STATE_FROM_SNAPSHOT',
  SELECT_SUBMENU: 'SELECT_SUBMENU',
  UPDATE_TEMPLATE: 'UPDATE_TEMPLATE',
  UPDATE_SERIES_TITLE: 'UPDATE_SERIES_TITLE',
  ADD_BACKDROP_IMPORT: 'ADD_BACKDROP_IMPORT',
  UPDATE_TEMPLATE_SLIDES_ORDER: 'UPDATE_TEMPLATE_SLIDES_ORDER',
  UPDATE_BLOCK_SETTINGS: 'UPDATE_BLOCK_SETTINGS',
  ADD_SCENE: 'ADD_SCENE',
  COPY_SCENE: 'COPY_SCENE',
  REMOVE_SCENE: 'REMOVE_SCENE',
  UPDATE_SCENE_SETTINGS: 'UPDATE_SCENE_SETTINGS',
  UPDATE_SCENE_BLOCKS: 'UPDATE_SCENE_BLOCKS',
  SET_SLIDE: 'SET_SLIDE',
  OPEN_SLIDE: 'OPEN_SLIDE',
  OPEN_CURRENT_SLIDE_SETTINGS: 'OPEN_CURRENT_SLIDE_SETTINGS',
  CLOSE_SLIDE: 'CLOSE_SLIDE',
  ADD_SLIDE_BLOCK: 'ADD_SLIDE_BLOCK',
  SET_SLIDE_BLOCK: 'SET_SLIDE_BLOCK',
  COPY_BLOCK: 'COPY_BLOCK',
  REMOVE_SELECTED_SLIDE_BLOCK: 'REMOVE_SELECTED_SLIDE_BLOCK',
  SET_SCENE_LIBRARY_OPEN: 'SET_SCENE_LIBRARY_OPEN',
  SET_RIGHT_PANEL_OPEN: 'SET_RIGHT_PANEL_OPEN',
  SET_ACTIVE_MENU: 'SET_ACTIVE_MENU',
  SELECT_TEMPLATE_CONFIG: 'SELECT_TEMPLATE_CONFIG',
  SELECT_BACKDROP_TAB: 'SELECT_BACKDROP_TAB',
  START_UPDATE_STATE: 'START_UPDATE_STATE',
  FINISH_UPDATE_STATE: 'FINISH_UPDATE_STATE',
  REFRESH_SCENE_TEMPLATES: 'REFRESH_SCENE_TEMPLATES',
  UPDATE_AUTHORING_MODE: 'UPDATE_AUTHORING_MODE',
};

const getMixpanelProps = (state) => {
  return {
    ...getMeetingSeriesProps(state?.currentSeries),
    sceneId: state?.selectedSlide?.sceneId,
  };
};

export const BACKDROP_TABS = ['Uploaded', 'Google Slides', 'Gallery'];

const initialState = {
  blocks: [],
  currentBackdropTab: BACKDROP_TABS[2],
  currentSubmenu: null,
  currentSeries: {
    autoLaunchConfig: {
      slides: [],
      templateId: undefined,
    },
    workspace: {},
    settings: {
      allowAdvancedAuthoring: false,
    },
  },
  selectedSlide: null,

  selectedSlideBlock: null,

  sceneTemplates: [],

  rightPanelOpen: true,
  slideOptionsOpen: true,

  status: {
    templatesLoading: true,
    templatesFailed: false,
    selectedTemplateLoading: true,
    selectedTemplateFailed: false,
    leftMenu: null,
    sceneLibrary: false,
    isUpdatingState: false,
  },

  defaultBackdrops: {},

  copiedBlock: null,
  copiedScene: null,
};

/**
 * Updates the current slide object to `updatedSlide`, maintaining a correct state.
 * @returns The updated state object.
 */
export function updateSlideObject(state, updatedSlide) {
  const { currentSeries } = state;
  const slides = [...currentSeries.autoLaunchConfig.slides];
  const currentSlideIndex = slides.findIndex(
    (slide) => slide.sceneId === updatedSlide.sceneId
  );

  slides[currentSlideIndex] = updatedSlide;

  const updatedState = {
    ...state,
    currentSeries: {
      ...currentSeries,
      autoLaunchConfig: {
        ...currentSeries.autoLaunchConfig,
        slides,
      },
    },
    selectedSlide: updatedSlide ?? null,
  };

  return updatedState;
}

const updateSlidesIndexes = (slides) => {
  if (slides) {
    slides.forEach((slide, index) => {
      slide.index = index;
    });
  }
};

/**
 * Updates the current selected block to `updatedBlock`, maintaining a correct state.
 * @returns The updated state object.
 */
function updateBlockObject(state, updatedBlock) {
  const { selectedSlide: slide } = state;
  if (!slide) {
    return state;
  }

  const blocks = [...slide.slideConfig.slideBlocks];
  const blockIndex = blocks.findIndex(
    (block) => block.blockInstanceId === updatedBlock.blockInstanceId
  );

  blocks[blockIndex] = updatedBlock;

  const updatedSlide = {
    ...slide,
    slideConfig: {
      ...slide.slideConfig,
      slideBlocks: blocks,
    },
  };

  const updatedState = updateSlideObject(state, updatedSlide, updatedBlock);

  return {
    ...updatedState,
    selectedSlideBlock:
      updatedState.selectedSlideBlock?.blockInstanceId ===
      updatedBlock.blockInstanceId
        ? updatedBlock
        : updatedState.selectedSlideBlock,
  };
}

const localReducer = (state, action) => {
  switch (action.type) {
    case MATA.SELECT_SUBMENU: {
      return {
        ...state,
        currentSubmenu:
          action.payload === state.currentSubmenu ? null : action.payload,
      };
    }

    case MATA.INITIALIZE_STATE_FROM_EXTERNAL_DATA: {
      const { selectedSlide: currentSelectedSlide } = state;
      const sceneId = getSceneIdFromUrl();

      const slides = action.payload.series.autoLaunchConfig.slides;

      const authoringMode = action.payload.series.authoringMode;

      const slideBySceneId =
        sceneId && slides.find((slide) => slide.sceneId === sceneId);
      const fallbackSlide = slides[0];

      const selectedSlide =
        currentSelectedSlide || slideBySceneId || fallbackSlide || null;

      mixpanel.track('Authoring - Page View', {
        ...getMixpanelProps(state),
      });

      const newState = {
        ...state,
        blocks: action.payload.blocks,
        sceneTemplates: action.payload.sceneTemplates,
        currentSeries: action.payload.series,
        defaultBackdrops: action.payload.defaultBackdrops,
        selectedSlide,
        selectedSlideBlock: null,
        rightPanelOpen: Boolean(selectedSlide),
        slideOptionsOpen: Boolean(selectedSlide),
        status: {
          ...state.status,
          templatesLoading: false,
          selectedTemplateLoading: false,
        },
        authoringMode,
      };

      return newState;
    }

    case MATA.REFRESH_STATE_FROM_SNAPSHOT: {
      const newState = action.payload;

      const updatedState = {
        currentSeries: newState.currentSeries,
        currentSubmenu: newState.currentSubmenu,
        selectedSlide: newState.selectedSlide,
        selectedSlideBlock: newState.selectedSlideBlock,
        rightPanelOpen: newState.rightPanelOpen,
        slideOptionsOpen: newState.slideOptionsOpen,
      };

      return { ...state, ...updatedState };
    }

    case MATA.REMOVE_SCENE: {
      const { newScenes, sceneId, nextSelectedSceneIndex } = action.payload;
      const { currentSeries } = state;
      updateSlidesIndexes(newScenes);
      const nextSelectedScene = newScenes[nextSelectedSceneIndex] ?? null;

      mixpanel.track('Authoring - Scene Removed', {
        ...getMixpanelProps(state),
        sceneId,
      });

      const updatedState = {
        ...state,
        currentSeries: {
          ...currentSeries,
          autoLaunchConfig: {
            ...currentSeries.autoLaunchConfig,
            slides: newScenes,
          },
        },
        selectedSlide: nextSelectedScene,
        status: {
          ...state.status,
          slideOptionsOpen:
            state.status.slideOptionsOpen && nextSelectedScene !== null,
        },
      };

      return updatedState;
    }

    case MATA.ADD_SCENE: {
      const { newScenes, trackScenes } = action.payload;
      const { currentSeries } = state;
      const { autoLaunchConfig } = currentSeries;
      updateSlidesIndexes(newScenes);

      for (const scene of trackScenes) {
        const { slideUrl, slideName, sceneId } = scene;
        mixpanel.track('Authoring - Scene Added', {
          ...getMixpanelProps(state),
          slideUrl,
          slideName,
          sceneId,
        });
      }

      const updatedState = {
        ...state,
        currentSeries: {
          ...currentSeries,
          autoLaunchConfig: {
            ...autoLaunchConfig,
            slides: newScenes,
          },
        },
        selectedSlide:
          newScenes.find((s) => s.sceneId === trackScenes[0].sceneId) ?? null,
      };

      const clearCopied = state.copiedScene ? { copiedScene: null } : {};
      const selectBlock = {
        selectedSlideBlock: null,
      };

      return {
        ...updatedState,
        ...clearCopied,
        ...selectBlock,
      };
    }

    case MATA.UPDATE_SCENE_SETTINGS: {
      const { sceneId, settings } = action.payload;
      const { slides } = state.currentSeries.autoLaunchConfig;
      const slide = slides.find((s) => s.sceneId === sceneId);
      if (slide) {
        const updatedSlide = {
          ...slide,
          ...settings,
        };

        mixpanel.track('Authoring - Scene Settings Updated', {
          ...getMixpanelProps(state),
          sceneId: slide.sceneId,
        });

        const updatedState = updateSlideObject(state, updatedSlide);
        return updatedState;
      } else {
        logerror({
          message: `No matching slide for ${sceneId}.`,
          stacktrace: new Error().stack,
          sceneId,
          settings,
        });
        return state;
      }
    }

    case MATA.UPDATE_TEMPLATE_SLIDES_ORDER: {
      const scenes = action.payload;
      updateSlidesIndexes(scenes);
      mixpanel.track('Authoring - Slides Reordered', {
        ...getMixpanelProps(state),
      });

      return {
        ...state,
        currentSeries: {
          ...state.currentSeries,
          autoLaunchConfig: {
            ...state.currentSeries.autoLaunchConfig,
            slides: scenes,
          },
        },
      };
    }

    case MATA.UPDATE_SERIES_TITLE: {
      const { title } = action.payload;

      mixpanel.track('Authoring - Series Title Updated', {
        ...getMixpanelProps(state),
        title,
      });

      return {
        ...state,
        currentSeries: {
          ...state.currentSeries,
          title,
        },
      };
    }

    // Unused?
    case MATA.UPDATE_TEMPLATE: {
      const newTemplate = action.payload.autoLaunchConfig;

      let selectedSlide = null;
      let updatedState = state;
      for (let slide of state.currentSeries.autoLaunchConfig.slides) {
        if (slide.sceneId === state.selectedSlide?.sceneId) {
          selectedSlide = slide;
        }
        updatedState = updateSlideObject(updatedState, slide);
      }

      if (!selectedSlide) {
        selectedSlide =
          updatedState.currentSeries.autoLaunchConfig.slides[0] ?? null;
      }

      return {
        ...state,
        currentSeries: {
          ...state.currentSeries,
          autoLaunchConfig: newTemplate,
        },
        selectedSlide,
        selectedSlideBlock: null,
      };
    }

    case MATA.UPDATE_SCENE_BLOCKS: {
      return {
        ...state,
        selectedSlide: {
          ...state.selectedSlide,
          slideConfig: {
            ...state.selectedSlide.slideConfig,
            slideBlocks: action.payload,
          },
        },
      };
    }

    case MATA.ADD_BACKDROP_IMPORT: {
      const slidesImport = action.payload;
      return {
        ...state,
        currentSeries: {
          ...state.currentSeries,
          autoLaunchConfig: {
            ...state.currentSeries.autoLaunchConfig,
            imports: [
              ...(state.currentSeries.autoLaunchConfig.imports || []).filter(
                ({ presentationId }) =>
                  presentationId !== slidesImport.presentationId
              ),
              slidesImport,
            ],
          },
        },
      };
    }

    // Unused?
    case MATA.SET_SLIDE: {
      const slideIndex = action.payload;

      const selectedSlide =
        state.currentSeries.autoLaunchConfig.slides[slideIndex];

      return {
        ...state,
        selectedSlide,
        selectedSlideBlock: null,
      };
    }

    case MATA.SET_ACTIVE_MENU: {
      const { menu } = action.payload;

      switch (menu) {
        case 'backdrop': {
          mixpanel.track('Authoring - Backdrop Menu Opened', {
            ...getMixpanelProps(state),
          });
          break;
        }
        case 'blocks': {
          mixpanel.track('Authoring - Blocks Menu Opened', {
            ...getMixpanelProps(state),
          });
          break;
        }
        default:
      }

      return {
        ...state,
        status: {
          ...state.status,
          leftMenu: menu ?? null,
        },
      };
    }

    case MATA.SET_SCENE_LIBRARY_OPEN: {
      mixpanel.track('Authoring - Scene Library Viewed', {
        ...getMixpanelProps(state),
      });

      const open = action.payload;
      return {
        ...state,
        status: {
          ...state.status,
          sceneLibrary: open,
        },
      };
    }

    case MATA.OPEN_SLIDE: {
      const slideIndex = action.payload;
      const selectedSlide =
        state.currentSeries.autoLaunchConfig.slides[slideIndex];

      mixpanel.track('Authoring - Select Slide', {
        ...getMixpanelProps(state),
        sceneId: selectedSlide?.sceneId,
      });

      return {
        ...state,
        selectedSlide,
        selectedSlideBlock: null,
        slideOptionsOpen: true,
      };
    }

    case MATA.OPEN_CURRENT_SLIDE_SETTINGS: {
      const isSelected = Boolean(state.selectedSlide);

      if (!state.status.slideOptionsOpen && isSelected) {
        mixpanel.track('Authoring - View Slide Settings', {
          ...getMixpanelProps(state),
        });
      }

      return {
        ...state,
        rightPanelOpen: isSelected,
        slideOptionsOpen: isSelected,
      };
    }

    case MATA.CLOSE_SLIDE: {
      return {
        ...state,
        rightPanelOpen: false,
        slideOptionsOpen: false,
      };
    }

    case MATA.UPDATE_BLOCK_SETTINGS: {
      const {
        settings: updatedSettings,
        blockInstanceId: updateBlockInstanceId,
      } = action.payload;
      const blockInstanceId =
        updateBlockInstanceId ||
        state.selectedSlideBlock?.blockInstanceId ||
        null;
      if (!blockInstanceId || !state.selectedSlide) {
        logerror({
          message: `No block instance ID attached to UPDATE_BLOCK_SETTING action, and no current block selected.`,
          payload: action.payload,
          sceneId: state.selectedSlide?.sceneId ?? null,
        });
        return state;
      }
      const block = state.selectedSlide.slideConfig.slideBlocks.find(
        (b) => b.blockInstanceId === blockInstanceId
      );
      if (!block) {
        logerror({
          message:
            'Block instance ID exists, but block not found in slideBlocks.',
          payload: action.payload,
          sceneId: state.selectedSlide?.sceneId ?? null,
        });
        return state;
      }
      const updatedBlock = {
        ...block,
        settings: {
          ...block.settings,
          ...updatedSettings,
        },
      };
      mixpanel.track('Authoring - Block Updated', {
        ...getMixpanelProps(state),
        blockInstanceId,
        blockId: block?.blockId,
      });
      return updateBlockObject(state, updatedBlock);
    }

    case MATA.SET_SLIDE_BLOCK: {
      if (!state.selectedSlide) {
        logerror({
          message:
            'No selected slide, cannot select a block with SET_SLIDE_BLOCK.',
          stacktrace: new Error().stack,
          blockInstanceId: action.payload,
        });
        return state;
      }

      const blockInstanceId = action.payload;
      const block =
        state.selectedSlide.slideConfig.slideBlocks.find(
          (b) => b.blockInstanceId === blockInstanceId
        ) ?? null;

      if (blockInstanceId) {
        mixpanel.track('Authoring - Select Block', {
          ...getMixpanelProps(state),
          blockInstanceId,
          blockId: block?.blockId,
        });
      }

      return {
        ...state,
        selectedSlideBlock: block,
        slideOptionsOpen: true,
      };
    }

    case MATA.ADD_SLIDE_BLOCK: {
      const slide = state.selectedSlide;

      if (!slide) {
        return state;
      }

      const block = action.payload;
      const updatedBlocks = [...slide.slideConfig.slideBlocks, block];

      const updatedSlide = {
        ...slide,
        slideConfig: {
          ...slide.slideConfig,
          slideBlocks: updatedBlocks,
        },
      };

      const updatedState = updateSlideObject(state, updatedSlide);
      const selectBlock = {
        rightPanelOpen: true,
        slideOptionsOpen: true,
        selectedSlideBlock: block,
      };

      mixpanel.track('Authoring - Block Added to Scene', {
        ...getMixpanelProps(state),
        blockId: block?.blockId,
        blockInstanceId: block?.blockInstanceId,
      });

      return {
        ...updatedState,
        ...selectBlock,
      };
    }

    case MATA.COPY_BLOCK: {
      const copiedBlock = action.payload;

      mixpanel.track('Authoring - Block Copied', {
        ...getMixpanelProps(state),
        blockId: copiedBlock?.blockId,
        blockInstanceId: copiedBlock?.blockInstanceId,
      });

      return {
        ...state,
        copiedBlock,
      };
    }

    case MATA.COPY_SCENE: {
      const copiedScene = action.payload;
      mixpanel.track('Authoring - Scene Copied', {
        ...getMixpanelProps(state),
        sceneId: copiedScene?.sceneId,
      });

      return {
        ...state,
        copiedScene,
      };
    }

    case MATA.REMOVE_SELECTED_SLIDE_BLOCK: {
      const { blockInstanceId } = action.payload;

      const blockToRemove = state.selectedSlide.slideConfig.slideBlocks.find(
        (block) => block.blockInstanceId === blockInstanceId
      );
      const updatedSlide = {
        ...state.selectedSlide,
        slideConfig: {
          ...state.selectedSlide.slideConfig,
          slideBlocks: state.selectedSlide.slideConfig.slideBlocks.filter(
            (block) => block.blockInstanceId !== blockInstanceId
          ),
        },
      };

      const updatedState = updateSlideObject(state, updatedSlide);

      mixpanel.track('Authoring - Block Deleted', {
        ...getMixpanelProps(state),
        blockInstanceId: blockInstanceId,
        blockId: blockToRemove?.blockId,
      });

      return {
        ...updatedState,
        selectedSlideBlock: null,
        slideOptionsOpen: true,
      };
    }

    case MATA.SELECT_BACKDROP_TAB: {
      return {
        ...state,
        currentBackdropTab: action.payload,
      };
    }

    /* useful event for any bigger state updates, such as from snapshot */
    case MATA.START_UPDATE_STATE: {
      return {
        ...state,
        status: {
          ...state.status,
          isUpdatingState: true,
        },
      };
    }

    /* event signalling end of the state update*/
    case MATA.FINISH_UPDATE_STATE: {
      return {
        ...state,
        status: {
          ...state.status,
          isUpdatingState: false,
        },
      };
    }

    case MATA.REFRESH_SCENE_TEMPLATES: {
      return {
        ...state,
        sceneTemplates: action.payload.sceneTemplates,
      };
    }

    case MATA.UPDATE_AUTHORING_MODE: {
      const { authoringMode } = action.payload;
      return {
        ...state,
        authoringMode,
      };
    }

    default: {
      return state;
    }
  }
};

export const AuthoringToolContext = createContext({
  reducer: [initialState],
  meetingConfigStackManager: null,
});

/* React binding for slides and MeetingConfigStackManager */
export const useStateMeetingConfigStackManager = (state, isUpdating) => {
  const meetingConfigStackManager = useMemo(
    () => new MeetingConfigStackManager(),
    []
  );

  const [, setCommand] = useState(undefined);

  const previousState = usePrevious(getStateFieldsToSave(state));

  useEffect(() => {
    if (state?.currentSeries.autoLaunchConfig.templateKey) {
      const isConfigChanged = !isEqual(
        cloneDeep(previousState.currentSeries.autoLaunchConfig),
        cloneDeep(state.currentSeries.autoLaunchConfig)
      );

      const isSceneChanged = !isEqual(
        cloneDeep(previousState.selectedSlide),
        cloneDeep(state.selectedSlide)
      );

      if ((isConfigChanged || isSceneChanged) && !isUpdating) {
        meetingConfigStackManager.execute(state);
        setCommand({
          ...state.selectedSlide,
          ...state.currentSeries.autoLaunchConfig,
        });
      }
    }
  }, [previousState, meetingConfigStackManager, state, isUpdating]);

  return meetingConfigStackManager;
};

/* helper for getting relevant state fields to be saved */
const getStateFieldsToSave = (state) => ({
  currentSeries: {
    meetingSeriesId: state.currentSeries.meetingSeriesId,
    autoLaunchConfig: state.currentSeries.autoLaunchConfig,
    workspace: state.currentSeries.workspace,
    settings: state.currentSeries.settings,
  },
  currentSubmenu: state.currentSubmenu,
  selectedSlide: state.selectedSlide,
  selectedSlideBlock: state.selectedSlideBlock,
  rightPanelOpen: state.rightPanelOpen,
  slideOptionsOpen: state.slideOptionsOpen,
});

export const AuthoringToolContextProvider = ({ children }) => {
  const reducer = useReducer(localReducer, initialState);

  const stateToSave = useMemo(
    () => getStateFieldsToSave(reducer[0]),
    [reducer]
  );
  const isUpdating = reducer[0].status.isUpdatingState;
  const meetingConfigStackManager = useStateMeetingConfigStackManager(
    stateToSave,
    isUpdating
  );

  const value = useMemo(
    () => ({
      reducer,
      meetingConfigStackManager,
    }),
    [meetingConfigStackManager, reducer]
  );

  return (
    <AuthoringToolContext.Provider value={value}>
      {children}
    </AuthoringToolContext.Provider>
  );
};
