import React, { useEffect, useState } from 'react';
import { presenterTypes } from '../../components/series/settings/EventPresenters';
import { fetchWorkspaceApi, fetchUserApi } from '../../helper/api';
import { logerror } from '../../helper/contextualLogger';
import { DropdownSelect } from '../../components/common/Dropdown';
import { useSelector } from 'react-redux';
import { LoadingSpinner } from '../../components/LoadingSpinner';

export const AddHost = ({ workspaceId, setEventHost, defaultHostId }) => {
  const userId = useSelector((_st) => _st.auth.user?.userId);
  const [workspaceMembers, setWorkspaceMembers] = useState(null);
  const [selectedMember, setSelectedMember] = useState(undefined);

  useEffect(() => {
    if (!workspaceId) return;
    const getWorkspaceMembers = async () => {
      const json = await fetchWorkspaceApi(workspaceId);
      const { result, error } = json;
      if (error) {
        logerror({
          message: `Error getting workspace with id ${workspaceId} in set host card`,
        });
      }
      const members =
        result?.members?.filter((m) => !m.hidden || m.userId === userId) || [];

      setWorkspaceMembers(members);

      const userId = members.find(
        (m) => m.userId === defaultHostId || m.userId === userId
      )?.userId;

      setSelectedMember(userId);
    };
    getWorkspaceMembers();
  }, [userId, workspaceId, defaultHostId, setEventHost]);

  const handleSelect = async (userId) => {
    setSelectedMember(userId);

    const user = await fetchUserApi(userId);

    const newHost = {
      linkedInUrl: user?.linkedInUrl || '',
      fullName: user?.userName || '',
      jobTitle: user?.bio || '',
      presenterPictureUrl: user?.avatarUrl || null,
      emailAddress: user?.emailAddress,
      invited: false,
      type: presenterTypes.HOST,
    };

    setEventHost(newHost);
  };

  if (!workspaceMembers) {
    return (
      <div className="flex w-[400px] justify-center items-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="self-stretch">
      <h4 className="text-base font-semibold text-blue-dark">Your host</h4>
      <div className="flex gap-[49px] py-[30px]">
        <div className="flex flex-col w-[400px] gap-5 z-10">
          <DropdownSelect
            options={workspaceMembers
              .filter((member) => member.userName)
              .map((member) => ({
                key: member.emailAddress,
                title: member.userName,
              }))}
            onSelect={(userId) => handleSelect(userId)}
            backgroundColor={DropdownSelect.backgroundColor.WHITE}
            selectedKey={selectedMember}
            fontColor={DropdownSelect.fontColor.BLUE_GRAY}
            borderSize={DropdownSelect.borderSize.ONE}
            borderColor={DropdownSelect.borderColor.BLUE_GRAY}
            angleColor="fill-blue-gray"
            autoScrollToSelection={false}
          />
        </div>
      </div>
    </div>
  );
};
