import React from 'react';

import { Cards } from '../Cards';
import { UpgradeButton } from '../UpgradeButton';

import MessagePartials from './MessagePartials';

const MeetingFullSuperAdmin = ({
  plan,
  isSubscriptionSetup,
  isSubscriptionActive,
}) => {
  const { maxAttendees, name } = plan;

  return (
    <div className="flex justify-between items-center">
      <div className="flex-grow w-full px-5">
        <MessagePartials.UpgradeFreePlan
          maxAttendees={maxAttendees}
          planName={name}
        />
        <MessagePartials.UnlockPotential />
      </div>
      {isSubscriptionSetup ? (
        <Cards plan={plan} isSubscriptionActive={isSubscriptionActive} />
      ) : (
        <UpgradeButton />
      )}
    </div>
  );
};

export default MeetingFullSuperAdmin;
