import React, { useCallback, useRef, useState } from 'react';
import { useClickOutside } from '../../../helper/useClickOutside';
import { AddRoleInput } from './InviteTab.styled';

const MAX_CHARACTERS_ALLOWED = 32;

const lowercaseNoSpaces = (str) => {
  return str.replace(/ /g, '').toLowerCase();
};

export const AddRolePopupWindow = ({
  user,
  open,
  onClose,
  lockedRoles,
  roleSuggestions,
  onAddUserRole,
}) => {
  const ref = useRef();
  const [roleName, setRoleName] = useState('');

  const handleChange = useCallback((e) => {
    const value = e.target.value;

    if (value.length <= MAX_CHARACTERS_ALLOWED) {
      setRoleName(e.target.value);
    }
  }, []);

  const formattedRole = lowercaseNoSpaces(roleName);

  const isNewRole = roleName && !user.roles.includes(formattedRole);

  const onRoleAdd = useCallback(() => {
    onAddUserRole(user.userId, formattedRole);
    setRoleName('');
    setTimeout(() => onClose(), 0);
  }, [formattedRole, user.userId, onAddUserRole, onClose]);

  useClickOutside(ref, onClose);

  if (!open) {
    return null;
  }

  return (
    <div
      className="box-border bg-white h-44 absolute left-0 m-2 rounded-md z-10 shadow-xl p-5 flex flex-col gap-y-2 border border-solid border-gray"
      ref={ref}
    >
      <div className="box-border text-sm rounded-md border border-solid border-gray bg-white flex justify-between py-2 px-2">
        <AddRoleInput
          value={roleName}
          onChange={handleChange}
          placeholder="Enter a new role"
        />
        <button
          className="text-purple text-sm font-semibold cursor-pointer px-1 opacity-40 hover:opacity-100 duration-200"
          disabled={!isNewRole || lockedRoles.includes(formattedRole)}
          onClick={onRoleAdd}
        >
          Add
        </button>
      </div>
      <div className="overflow-auto -mb-4">
        {roleSuggestions
          .filter((roleName) => !lockedRoles.includes(roleName))
          .map((roleName) => (
            <button
              className="text-sm block my-2 font-medium text-blue-gray hover:text-white hover:bg-purple hover:bg-opacity-80 duration-200 bg-gray bg-opacity-60 py-1 px-2 w-full text-left rounded-full"
              key={roleName}
              onClick={() => setRoleName(roleName)}
            >
              {roleName}
            </button>
          ))}
      </div>
    </div>
  );
};
