const DAY_MS = 1000 * 60 * 60 * 24;

/* A simple cache for storing items in memory, with optional time-to-live parameter. Good for tracking items we want to eventually remove, such as User Reactions */
class InMemoryCache {
  constructor({ items = [], ttl = DAY_MS }) {
    this.items = items;
    this.ttl = ttl;
  }

  push(item) {
    const now = new Date();

    this.items.push({ ...item, expiry: now.getTime() + this.ttl });

    return this;
  }

  get() {
    const now = new Date();

    this.items = this.items.reduce((returnedItems, item) => {
      if (now.getTime() > item.expiry) {
        return returnedItems;
      }

      returnedItems.push(item);

      return returnedItems;
    }, []);

    return this.items;
  }
}

export const reactionsCache = new InMemoryCache({ ttl: 5_000 });
