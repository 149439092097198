import { callState } from './callState';
import { userActivity } from './userActivity';
import authentication from './authenticationReducer';
import chatDetails from './chatDetails';
import clientDetails from './clientDetails';
import meetingState from './meetingState';
import uiState from './uiState';
import onboarding from './onboarding';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  userActivity,
  auth: authentication,
  callState,
  clientDetails,
  meetingState,
  chatDetails,
  uiState,
  onboarding,
});

export default rootReducer;
