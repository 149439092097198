import React, { useCallback, useState } from 'react';
import { logout } from '../../auth';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { SignOutIcon } from '../../components/icons/SignOutIcon';
import { SettingsIcon } from '../../components/icons/SettingsIcon';
import { PersonProfileIcon } from '../../components/icons/PersonProfileIcon';
import classNames from '../../helper/classNames';
import { signout } from './NavbarComponents';
import { Link } from 'react-router-dom';
import { UserIcon } from '../../components/icons/UserIcon';
import { getWorkspaceIdFromUrl } from '../../hooks/useTemplateCreation';
import { useWorkspaceSelector } from '../../hooks/useWorkspaceSelector';
import { WorkspaceOptionRightMenu } from './HomeNavbar';
import { BuildingIcon } from '../../components/icons/BuildingIcon';
import { isMobile } from '../../helper';
import { WorkspaceCreateModal } from '../../components/WorkspaceCreateModal';
import { isZyncStaff } from '../../helper/isZyncStaff';
import { PLANS } from 'zync-common/zyncCustomerPlans';
import mixpanel from 'mixpanel-browser';
import { LinkIcon } from '../../components/icons/LinkIcon';

export const NavbarUserDropdownMenu = ({ pageTitle }) => {
  const dispatch = useDispatch();
  const auth = useSelector((_st) => _st.auth, shallowEqual);
  const { user, workspaceId } = auth;

  const [isDropdownMenuExpanded, setIsDropdownMenuExpanded] = useState(false);

  const {
    userWorkspaces,
    selectWorkspace,
    currentWorkspaceId,
    workspaceMenuRef,
    setShowCreateWorkspaceModal,
    handleWorkspaceCreated,
    showCreateWorkspaceModal,
    currentWorkspace,
  } = useWorkspaceSelector({
    onAfterSelect: () => setIsDropdownMenuExpanded(false),
  });

  const isSoloWorkspace = currentWorkspace?.workspacePlan === PLANS.solo.id;

  const workspaceSettings = {
    name: 'Settings',
    Icon: SettingsIcon,
    url: isSoloWorkspace
      ? `/workspace/${encodeURIComponent('solo studio settings')}`
      : '/workspace/settings',
  };

  const userProfile = {
    name: 'User Profile',
    Icon: UserIcon,
    url: '/profile',
  };

  const signOut = {
    name: 'Sign Out',
    Icon: SignOutIcon,
    url: '',
  };

  const support = {
    name: 'Support',
    Icon: LinkIcon,
    url: '',
  };

  let userDropdownOptions = [];

  switch (pageTitle) {
    default:
      userDropdownOptions = isSoloWorkspace
        ? [workspaceSettings, userProfile, support, signOut]
        : [workspaceSettings, userProfile, support, signOut];
  }

  const [isHoveringOverListItems, setIsHoveringOverListItems] = useState(
    new Array(userDropdownOptions.length).fill(false)
  );
  const handleDropdownMenuClick = () => {
    setIsDropdownMenuExpanded(!isDropdownMenuExpanded);
  };
  const handleHoverOverListItems = (idx, isHovering) => {
    const updatedListItemsHoverState = new Array(
      userDropdownOptions.length
    ).fill(false);

    updatedListItemsHoverState[idx] = isHovering;
    setIsHoveringOverListItems(updatedListItemsHoverState);
  };

  const appendWorkspaceId = useCallback(
    (url) => {
      const workspaceIdFromUrl = getWorkspaceIdFromUrl();

      if (!workspaceId && !workspaceIdFromUrl) {
        return url;
      }

      return url + `?workspaceId=${workspaceId || workspaceIdFromUrl}`;
    },
    [workspaceId]
  );

  const ListItem = ({ dropdownOption, idx, onClick = null }) => {
    const userId = useSelector((st) => st.auth?.user?.userId);
    const { name, Icon } = dropdownOption;
    return (
      <li
        onMouseEnter={() => handleHoverOverListItems(idx, true)}
        onMouseLeave={() => handleHoverOverListItems(idx, false)}
        onClick={() => {
          mixpanel.track(`Menu: ${name} Clicked`, { distinct_id: userId });
          onClick && onClick();
        }}
        className={classNames(
          'cursor-pointer items-center gap-2 bg-white border-b border-solid py-2 border-b-gray flex justify-start px-3 font-medium text-[#696F8C] text-sm hover:text-purple hover:bg-purple hover:bg-opacity-5 duration-100',
          idx === userDropdownOptions.length && 'rounded-b-md border-b-white'
        )}
      >
        <Icon
          color={isHoveringOverListItems[idx] ? '#8B33F7' : '#696F8C'}
          className={
            isHoveringOverListItems[idx] ? 'fill-[#8B33F7]' : 'fill-[#696F8C]'
          }
          width={15}
        />
        <span className="whitespace-nowrap">{name}</span>
      </li>
    );
  };

  const userId = useSelector((st) => st.auth?.user?.userId);

  return (
    <>
      <ul className="flex gap-3" ref={workspaceMenuRef}>
        <li
          className={classNames(
            'font-medium text-xs cursor-default self-center',
            isDropdownMenuExpanded ? 'text-purple' : 'text-blue-gray'
          )}
        >
          Hi, {user?.userName}
        </li>
        <li className="relative">
          <ul className="flex">
            <div onClick={handleDropdownMenuClick}>
              <li
                className={classNames(
                  ' p-2 rounded-full cursor-pointer self-center',
                  isDropdownMenuExpanded
                    ? 'bg-purple bg-opacity-10'
                    : 'bg-gray  bg-opacity-60'
                )}
              >
                <PersonProfileIcon
                  width="20"
                  height="20"
                  color={isDropdownMenuExpanded ? '#8B33F7' : '#696F8C'}
                />
              </li>
            </div>
          </ul>
          {isDropdownMenuExpanded && (
            <ul className="z-30 bg-white absolute right-0 top-[3.12rem] drop-shadow-xl rounded-b-md w-max">
              {userDropdownOptions.map((dropdownOption, idx) => {
                const { url } = dropdownOption;
                if (dropdownOption === signOut) {
                  return (
                    <ListItem
                      dropdownOption={dropdownOption}
                      idx={idx}
                      onClick={() => {
                        mixpanel.track('Menu: Signout clicked', {
                          distinct_id: userId,
                        });
                        signout(auth, dispatch, logout);
                      }}
                      key={dropdownOption.name}
                    />
                  );
                }

                if (dropdownOption === support) {
                  return (
                    <ListItem
                      dropdownOption={dropdownOption}
                      idx={idx}
                      onClick={() => {
                        mixpanel.track('Menu: Support clicked', {
                          distinct_id: userId,
                        });
                        window.open('http://support.zync.ai', '_blank');
                      }}
                      key={dropdownOption.name}
                    />
                  );
                }
                return (
                  <Link to={appendWorkspaceId(url)} key={dropdownOption.name}>
                    <ListItem dropdownOption={dropdownOption} idx={idx} />
                  </Link>
                );
              })}
              <li
                className={classNames(
                  'items-center gap-2 border-b border-solid py-2 border-b-gray flex justify-start px-3 font-medium text-sm text-[#696F8C] bg-purple bg-opacity-5 duration-100'
                )}
              >
                <BuildingIcon />
                <span className="whitespace-nowrap">Workspace Select</span>
              </li>
              <div className="max-h-[40vh] overflow-auto purple-scrollbar">
                {userWorkspaces.map((w) => (
                  <div key={w.workspaceId}>
                    <WorkspaceOptionRightMenu
                      handleSelectWorkspace={() => selectWorkspace(w)}
                      isActive={currentWorkspaceId === w.workspaceId}
                    >
                      <span>{w.name}</span>
                    </WorkspaceOptionRightMenu>
                  </div>
                ))}
              </div>
              {!isMobile && isZyncStaff(user?.userId) && (
                <button
                  onClick={() => setShowCreateWorkspaceModal(true)}
                  className="bg-purple text-white text-xs py-3 rounded-b-sm w-full block font-medium"
                >
                  <span>+ New Workspace</span>
                </button>
              )}
            </ul>
          )}
        </li>
      </ul>
      {showCreateWorkspaceModal && (
        <WorkspaceCreateModal
          onWorkspaceCreated={handleWorkspaceCreated}
          onCancel={() => setShowCreateWorkspaceModal(false)}
        />
      )}
    </>
  );
};
