import React, { Suspense, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useDeepCompareMemo } from 'use-deep-compare';
import {
  AuthoringIconContainer,
  AuthoringIconImage,
} from '../components/authoring/AuthoringTool.styled';
import { sendEvent } from '../helper/api';
import { logerror } from '../helper/contextualLogger';
import { lazy } from '../helper/lazy';
import { BlockFunctionConstants } from './constants';
import { useMeeting } from '../hooks/useMeeting';

const Empty = () => <></>;
const BlockComponents = {};
const AuthoringBlockComponents = {};

const getComponent = (fileId, componentType) => {
  if (!fileId) return <></>;
  if (!BlockComponents[fileId]) {
    BlockComponents[fileId] = {
      Block: lazy(() =>
        import(`./${fileId}.jsx`).then((module) => ({
          default: module.Block || Empty,
        }))
      ),
      Settings: lazy(() =>
        import(`./${fileId}.jsx`).then((module) => ({
          default: module.Settings || Empty,
        }))
      ),
      Summary: lazy(() =>
        import(`./${fileId}.jsx`).then((module) => ({
          default: module.Summary || Empty,
        }))
      ),
    };
  }
  return BlockComponents[fileId][componentType];
};

const exportedBlockFunctions = {};

const getBlockFunction = async (fileId, funcName) => {
  try {
    const module = await import(`./${fileId}.jsx`);
    if (!exportedBlockFunctions[fileId]) {
      exportedBlockFunctions[fileId] = { ...module?.BlockBuilderFunctions };
    }
    return exportedBlockFunctions[fileId][funcName];
  } catch (err) {
    logerror({
      message: `Error attempting to import: ./${fileId}.jsx. ${err.message}`,
      stacktrace: err.stack | err,
    });
  }
};

const getAuthoringBlockComponent = (fileId, blockId, iconUrl, title) => {
  const blockIdRenderer = () => <div style={{ color: '#333' }}>{blockId}</div>;
  if (!fileId) return blockIdRenderer;
  if (!AuthoringBlockComponents[fileId]) {
    let c;
    if (iconUrl) {
      c = () => (
        <AuthoringIconContainer>
          <AuthoringIconImage
            alt={'block icon'}
            src={iconUrl}
            draggable={false} // this is HTML property and should be set to false - this way images cannot be "dragged" natively (we use Draggable component at the top of the tree anyways)
          />
        </AuthoringIconContainer>
      );
    } else if (title) {
      c = () => <div style={{ color: '#333' }}>{title}</div>;
    } else {
      c = blockIdRenderer;
    }

    AuthoringBlockComponents[fileId] = lazy(() =>
      import(`./${fileId}.jsx`).then((module) => ({
        default: module.AuthoringBlock || c,
      }))
    );
  }
  return AuthoringBlockComponents[fileId];
};

export const useRenderer = () => {
  const { user: currentAuthUser } = useSelector((st) => st.auth);
  const { userId } = currentAuthUser || {};
  const { users, invisibleUsers, meetingId } = useMeeting();
  const currentUser = users[userId] || invisibleUsers[userId];

  const joinedUsers = useDeepCompareMemo(() => {
    return Object.values(users).filter((u) => u.joined);
  }, [users]);
  const userIdToTrackStates = useSelector(
    (_st) => _st.callState.tracks.allUsers
  );

  return useDeepCompareMemo(
    () => ({
      currentUser,
      joinedUsers,
      meetingId,
      userIdToTrackStates,
    }),
    [currentUser, joinedUsers, meetingId, userIdToTrackStates]
  );
};

export const BlockRenderer = React.memo(({ code, block, accentColor }) => {
  const { currentUser, joinedUsers, meetingId, userIdToTrackStates } =
    useRenderer();
  const { clientSide } = code || {};
  const { userId } = currentUser;
  const { blockId, blockInstanceId } = block || {};

  const blockData = useDeepCompareMemo(() => {
    return block;
  }, [block]);

  const eventDispatch = useCallback(
    (action, withBlockData = true) => {
      const payload = action;

      if (withBlockData) {
        payload.blockId = blockId;
        payload.blockInstanceId = blockInstanceId;
      }

      sendEvent(userId, meetingId, payload);
    },
    [userId, meetingId, blockId, blockInstanceId]
  );
  const Block = useMemo(() => {
    return getComponent(clientSide, 'Block');
  }, [clientSide]);

  return (
    <Suspense fallback={<></>}>
      <Block
        block={blockData}
        users={joinedUsers}
        user={currentUser}
        eventDispatch={eventDispatch}
        userIdToTrackStates={userIdToTrackStates}
        accentColor={accentColor}
      />
    </Suspense>
  );
});

export const SummaryRenderer = ({
  code,
  block,
  compact,
  liveMeetingSummary,
}) => {
  const { clientSide } = code || {};
  const Summary = useMemo(() => {
    return getComponent(clientSide, 'Summary');
  }, [clientSide]);
  return (
    <Suspense fallback={<></>}>
      {Summary && (
        <Summary
          block={block}
          liveMeetingSummary={liveMeetingSummary}
          compact={compact}
        />
      )}
    </Suspense>
  );
};

export const hasSummary = async (clientSide, block) => {
  try {
    const func = await getBlockFunction(
      clientSide,
      BlockFunctionConstants.hasSummaryContent
    );
    if (func) {
      return func(block);
    }
    return false;
  } catch (err) {
    const blockId = block?.blockId || 'Unknown';
    logerror({
      message: `Error attempting to call getBlockFunction inside of hasSummary. Block Id: ${blockId}. ${err.message}`,
      stacktrace: err.stack | err,
    });
  }
};

export const AuthoringBlockRenderer = ({
  block,
  inlineEditable = false,
  onUpdate,
  users,
  accentColor,
  brandKit,
}) => {
  const { iconUrl, title } = block.details || {};
  const AuthoringBlock = useMemo(
    () =>
      getAuthoringBlockComponent(
        block.code.clientSide,
        block.blockId,
        iconUrl,
        title
      ),
    [block.blockId, iconUrl, title, block.code.clientSide]
  );

  return (
    <Suspense fallback={<></>}>
      <AuthoringBlock
        block={block}
        inlineEditable={inlineEditable}
        onUpdate={onUpdate}
        users={users}
        accentColor={accentColor}
        brandKit={brandKit}
      />
    </Suspense>
  );
};
