import React from 'react';

export const CaretIcon = ({
  width = '7px',
  height = '5px',
  className = 'fill-blue-gray',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 7 5"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M6.82364 0.575011C6.77661 0.526192 6.71146 0.499043 6.64368 0.500026L0.354914 0.500026C0.287134 0.499043 0.221977 0.526192 0.174949 0.575011L0.0749683 0.674992C0.0276494 0.721924 0.00103331 0.78581 0.00103331 0.852457C0.00103331 0.919103 0.0276494 0.982989 0.0749683 1.02992L3.32433 4.42426C3.36969 4.47258 3.43302 4.5 3.4993 4.5C3.56558 4.5 3.6289 4.47258 3.67426 4.42426L6.92362 1.02992C6.97094 0.982989 6.99756 0.919103 6.99756 0.852457C6.99756 0.78581 6.97094 0.721924 6.92362 0.674992L6.82364 0.575011Z" />
  </svg>
);
