import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  lazy,
  Suspense,
  useMemo,
  useContext,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from '../helper';
import { sendEvent } from '../helper/api';
import classNames from '../helper/classNames';
import { useLiveMeeting } from '../hooks/useLiveMeeting';
import { ScrollbarOnHoverContainer } from './common/ScrollbarOnHoverContainer';
import { SlideSeeker } from './liveMeetingComponents';
import './live.css';
import { Slide } from './Slide';
import styled from 'styled-components';
import { ScreenShareScene } from './ScreenShareScene';
import { MeetingControl } from './MeetingControl/MeetingControl';
import { AudioOutput } from './AudioOutput';
import mixpanel from 'mixpanel-browser';
import { PositionedCloseButton } from './common/CloseButton';
import { loginfo } from '../helper/contextualLogger';
import { Timeline } from './liveMeetingComponents/Timeline';
import { isEqual } from 'lodash/fp';
import { Questions } from './liveMeetingComponents/Questions';
import { useLiveTab } from '../hooks/useLiveTab';
import { ChunkedLocalRecordingContext } from './Meeting/context/ChunkedLocalRecordingContextProvider';

const Chat = lazy(() => import('./chat/Chat'));

export const CenterSpace = styled.section`
  margin-left: ${(props) => props.liveTabWidth || 0}px;
  margin-right: 0;
  margin-bottom: ${isMobile ? 0 : 70}px;
  z-index: 1;
  overflow: hidden;
  position: relative;
  min-height: 50px;
  height: ${isMobile ? '100%' : 'initial'};
`;

const ScreenShareContainer = () => {
  const screenShareTrack = useSelector(
    (_st) => _st.callState.tracks.mainScreenShare
  );

  const userIdToTrackStates =
    useSelector((_st) => {
      let result = {};

      for (let key in _st.callState.tracks.allUsers) {
        result[key] = _st.callState.tracks.allUsers[key]?.sessionId;
      }
      return result;
    }, isEqual) || {};

  const findScreenSharingUser = (
    screenShareObject,
    joinedUsers,
    userIdToTrackStates
  ) => {
    const screenSharingUserSessionId = screenShareObject.sessionId;
    const screenSharingUserId = Object.entries(userIdToTrackStates || []).find(
      ([_, sessionId]) => sessionId === screenSharingUserSessionId
    )?.[0];
    return joinedUsers.find((user) => user.userId === screenSharingUserId);
  };

  const users = useSelector(
    (state) => state.meetingState.state?.users,
    isEqual
  );

  const joinedUsers = useMemo(() => {
    return Object.values(users || {}).filter((u) => u.joined);
  }, [users]);

  const screenShare = screenShareTrack
    ? {
        ...screenShareTrack,
        user: findScreenSharingUser(
          screenShareTrack,
          joinedUsers,
          userIdToTrackStates
        ),
      }
    : null;
  return screenShare ? (
    <ScreenShareScene screenShareTrackStates={screenShare} />
  ) : null;
};

export const SeriesInfoContext = React.createContext({
  logo: null,
  seriesTitle: null,
  showName: null,
  primaryContrast: null,
  primaryColor: null,
  accentColor: null,
  accentContrast: null,
  speakersCount: null,
  moveLeft: () => {},
  moveRight: () => {},
});

const LocalRecordingContainer = () => {
  return (
    <>
      <canvas
        id="local-recording-container"
        style={{ visibility: 'hidden', display: 'none' }}
      />
    </>
  );
};

const LiveMeeting = React.memo(
  ({ isPreview, series, userId, subscriberOnly }) => {
    const menuContainerRef = useRef(null);

    const { liveTab, allSlides, user, startSlide, isSwitchingScenes } =
      useLiveMeeting({ series, userId });

    const { stopLocalRecording, isLocalRecording } = useContext(
      ChunkedLocalRecordingContext
    );

    const currentSceneIndex = useSelector(
      (state) => state.meetingState.state?.slides?.currentSlideIndex
    );

    useEffect(() => {
      mixpanel.track(
        `Studio: ${
          isPreview ? 'Rehearsal Studio Loaded' : 'Live Studio Loaded'
        }`,
        { distinct_id: userId }
      );
    }, [userId, isPreview]);

    const allSlidesCount = allSlides?.length || 0;

    const lobbySceneIndex = series.autoLaunchConfig.slides.findIndex(
      (scene) => scene.sceneTemplateKey === 'scene_lobby'
    );

    const moveLeft = useCallback(() => {
      const newSceneIndex = currentSceneIndex - 1;
      if (newSceneIndex < (lobbySceneIndex === -1 ? 0 : 1)) {
        return;
      }
      if (isLocalRecording) {
        stopLocalRecording();
      }
      startSlide(newSceneIndex);
      mixpanel.track('Studio - Previous Scene Click', {
        distinct_id: userId,
        currentSceneIndex,
        currentSceneId: allSlides[currentSceneIndex]?.sceneId,
        newSceneId: allSlides[newSceneIndex]?.sceneId,
      });
    }, [
      userId,
      currentSceneIndex,
      lobbySceneIndex,
      isLocalRecording,
      startSlide,
      allSlides,
      stopLocalRecording,
    ]);

    const moveRight = useCallback(() => {
      const newSceneIndex = currentSceneIndex + 1;
      if (newSceneIndex >= allSlidesCount) {
        return;
      }
      if (isLocalRecording) {
        stopLocalRecording();
      }
      startSlide(newSceneIndex);
      mixpanel.track('Meeting - Next Scene Click', {
        distinct_id: userId,
        currentSceneIndex,
        currentSceneId: allSlides[currentSceneIndex]?.sceneId,
        newSceneId: allSlides[newSceneIndex]?.sceneId,
      });
    }, [
      userId,
      currentSceneIndex,
      allSlidesCount,
      isLocalRecording,
      startSlide,
      allSlides,
      stopLocalRecording,
    ]);

    const hideLeftBar = series.autoLaunchConfig?.features?.hideLeftBar;

    if (hideLeftBar) {
      loginfo({ message: 'experimental meeting feature: hideLeftBar' });
    }

    const logo = series?.workspace?.brandKit?.logoUrl;
    const seriesTitle = series?.title;
    const episodeType = series?.settings?.episodeType;
    const showName = series?.workspace?.show?.name;
    const primaryContrast = series?.workspace?.brandKit?.primaryContrast;
    const primaryColor = series?.workspace?.brandKit?.primaryColor;
    const accentColor = series?.workspace?.brandKit?.accentColor;
    const accentContrast = series?.workspace?.brandKit?.accentContrast;
    const speakersCount = series?.settings?.eventPresenters.filter(
      (ep) => ep.type === 'speaker'
    ).length;

    const seriesInfoContextValue = useMemo(
      () => ({
        logo,
        seriesTitle,
        showName,
        primaryContrast,
        primaryColor,
        accentColor,
        accentContrast,
        speakersCount,
        moveRight,
        moveLeft,
        episodeType,
      }),
      [
        logo,
        seriesTitle,
        showName,
        primaryContrast,
        primaryColor,
        accentColor,
        accentContrast,
        speakersCount,
        moveRight,
        moveLeft,
        episodeType,
      ]
    );

    const [areNotesOpen, setAreNotesOpen] = useState(true);

    const handleCloseNotes = useCallback(() => {
      setAreNotesOpen(false);
    }, []);

    const handleOpenNotes = useCallback(() => {
      setAreNotesOpen(true);
    }, []);

    const { liveTabWidth } = useLiveTab();

    return (
      <>
        <AudioOutput />

        <div
          id="header-container"
          className="ml-auto"
          style={{ width: `calc(100% - ${liveTabWidth}px` }}
        ></div>

        <div ref={menuContainerRef}>
          <SideBar
            user={user}
            liveTab={liveTab}
            startSlide={startSlide}
            slides={allSlides}
            isSwitchingScenes={isSwitchingScenes}
            brandKit={series?.workspace?.brandKit}
            handleCloseNotes={handleCloseNotes}
            handleOpenNotes={handleOpenNotes}
            episodeType={episodeType}
          />
        </div>

        <SeriesInfoContext.Provider value={seriesInfoContextValue}>
          <Slide
            key="slide-component"
            slides={allSlides}
            startSlide={startSlide}
            userId={userId}
            isSwitchingScenes={isSwitchingScenes}
            brandKit={series?.workspace?.brandKit}
            screenshareComponent={ScreenShareContainer}
            timelineOn={series?.settings?.timelineOn}
            handleCloseNotes={handleCloseNotes}
            areNotesOpen={areNotesOpen}
            moveLeft={moveLeft}
            moveRight={moveRight}
          />
        </SeriesInfoContext.Provider>

        <MeetingControl
          series={series}
          subscriberOnly={subscriberOnly}
          onRecordChanges={series?.visibility === 'rsvp'}
        />

        <LocalRecordingContainer
          userId={userId}
          meetingSeriesId={series?.meetingSeriesId}
          workspaceId={series?.workspace?.workspaceId}
        />
      </>
    );
  }
);

export const menuWidth = 330;

const SideBarContainer = styled.div`
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 70px;
  transform: translateX(${({ open }) => (open ? 0 : `${-(menuWidth + 60)}px`)});
  opacity: ${({ open }) => (open ? 1 : 0)};
  transition: all 0.2s ease;
  background: transparent;
`;

const MenuCloseButton = styled(PositionedCloseButton)`
  top: 10px;
  right: -44px;
  z-index: 900;
`;

const SideBar = ({
  user,
  liveTab,
  slides,
  startSlide: handleStartSlide,
  isSwitchingScenes,
  brandKit,
  handleOpenNotes,
  episodeType,
}) => {
  const { userId } = user;

  const dispatch = useDispatch();

  const currentSceneIndex = useSelector(
    (state) => state.meetingState.state?.slides?.currentSlideIndex
  );
  const meetingId = useSelector((state) => state.meetingState.meetingId);

  const handleClose = useCallback(
    () => dispatch({ type: 'SELECT_LIVE_TAB', tab: null }),
    [dispatch]
  );
  const startSlide = useCallback(
    (slideIndex, closeTab = false) => {
      handleStartSlide(slideIndex);
      if (closeTab) {
        handleClose();
      }
    },
    [handleStartSlide, handleClose]
  );

  // Handle tracking sidebar (live tab) clicks.
  const [previousLiveTab, setPreviousLiveTab] = useState(null);

  useEffect(() => {
    if (previousLiveTab === liveTab) return;
    setPreviousLiveTab(liveTab);
    if (previousLiveTab) {
      mixpanel.track('Meeting Panel - Close Pane', {
        pane: liveTab,
      });
    }
    if (!liveTab) return;

    mixpanel.track('Meeting Panel - Open Pane', {
      pane: liveTab,
    });
  }, [liveTab, previousLiveTab]);

  const showChat = liveTab === 'chat';

  return (
    <SideBarContainer
      open={!!liveTab}
      className={classNames(
        (liveTab === 'timeline' || liveTab === 'questions') &&
          'shadow-[1px_0px_4px_rgba(0,0,0,0.1)]'
      )}
    >
      {liveTab !== 'timeline' && liveTab !== 'questions' && (
        <MenuCloseButton onClose={handleClose} />
      )}
      <ScrollbarOnHoverContainer className="h-full">
        <Suspense fallback={<></>}>
          <ChatWrapper showChat={showChat}>
            <Chat
              showChat={showChat}
              sendChatAction={(action) => sendEvent(userId, meetingId, action)}
            />
          </ChatWrapper>
        </Suspense>
        {liveTab === 'scenes' && (
          <ScenesWrapper>
            <SlideSeeker
              slides={slides}
              currentSlideIndex={currentSceneIndex}
              startSlide={startSlide}
              isSwitchingScenes={isSwitchingScenes}
              brandKit={brandKit}
            />
          </ScenesWrapper>
        )}
        {liveTab === 'timeline' && (
          <TimelineWrapper>
            <Timeline
              scenes={slides}
              currentSceneIndex={currentSceneIndex}
              startSlide={startSlide}
              onClose={handleClose}
              handleOpenNotes={handleOpenNotes}
              episodeType={episodeType}
            />
          </TimelineWrapper>
        )}
        {liveTab === 'questions' && (
          <QuestionsWrapper>
            <Questions scenes={slides} onClose={handleClose} />
          </QuestionsWrapper>
        )}
      </ScrollbarOnHoverContainer>
    </SideBarContainer>
  );
};

const ChatWrapper = React.memo(({ children, showChat }) => (
  <div
    className={classNames(
      'h-full w-[330px] bg-[#3d3d46] p-2.5 rounded-br-xl rounded-tr-xl',
      !showChat && 'hidden'
    )}
  >
    {children}
  </div>
));

const ScenesWrapper = React.memo(({ children }) => (
  <div className="min-h-full w-[172px] bg-white p-2.5 overflow-auto max-h-full">
    {children}
  </div>
));

const TimelineWrapper = React.memo(({ children }) => (
  <div className="min-h-full w-[330px] bg-white overflow-auto max-h-full">
    {children}
  </div>
));

const QuestionsWrapper = React.memo(({ children }) => (
  <div className="min-h-full w-[345px] bg-white flex flex-col justify-between max-h-full">
    {children}
  </div>
));
export { LiveMeeting };
