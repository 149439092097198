import styled from 'styled-components';
import { OuterContainer } from '../../common/Container';
import { scrollbarCss } from '../../../helper/css';
import { TabBarContainer } from '../../common/TabBar';

/**
 * Menu container for backdrops, blocks, etc. Content should be defined
 * within this component.
 */
export const MenuContainer = styled(OuterContainer)`
  position: relative;
  border-radius: 0px;
  box-shadow: none;
  overflow-y: auto;
  ${scrollbarCss(true, false)}

  z-index: 1;

  // general tab styles for any use of tab in left-hand menu.
  ${() => TabBarContainer} {
    gap: 4px;
    > div {
      font-size: 10px !important;
      line-height: 15px !important;
      padding: 4px 0 !important;
    }
  }
`;
