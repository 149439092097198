import React from 'react';

export const RichTextBoldIcon = ({
  width = 24,
  height = 24,
  className = 'fill-black',
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.25 11.8C15.7397 11.5018 16.1529 11.0934 16.4567 10.6073C16.7605 10.1212 16.9466 9.57077 17 9C17.0093 8.48388 16.9168 7.971 16.7278 7.49063C16.5388 7.01027 16.2571 6.57184 15.8986 6.20039C15.5402 5.82894 15.1121 5.53174 14.6387 5.32578C14.1654 5.11981 13.6561 5.00911 13.14 5H6.65002V19H13.65C14.1412 18.9948 14.6265 18.8929 15.0782 18.7001C15.53 18.5073 15.9394 18.2274 16.283 17.8764C16.6265 17.5253 16.8976 17.1101 17.0807 16.6543C17.2638 16.1985 17.3553 15.7112 17.35 15.22V15.1C17.3504 14.4071 17.1529 13.7285 16.781 13.144C16.409 12.5594 15.8778 12.0931 15.25 11.8V11.8ZM8.65002 7H12.85C13.2762 6.98681 13.6962 7.10428 14.0537 7.33665C14.4112 7.56902 14.6891 7.90517 14.85 8.3C15.0129 8.82779 14.9602 9.39859 14.7035 9.88765C14.4468 10.3767 14.0069 10.7443 13.48 10.91C13.2754 10.97 13.0632 11.0003 12.85 11H8.65002V7ZM13.25 17H8.65002V13H13.25C13.6762 12.9868 14.0962 13.1043 14.4537 13.3367C14.8112 13.569 15.0891 13.9052 15.25 14.3C15.4129 14.8278 15.3602 15.3986 15.1035 15.8877C14.8468 16.3767 14.4069 16.7443 13.88 16.91C13.6754 16.97 13.4632 17.0003 13.25 17V17Z" />
  </svg>
);
