import { useMemo } from 'react';
import { isRolesMeetingController } from '../../helper/roles';

export const useInvitePermissions = (currentUser, editedUser) => {
  const isSelfEditing = currentUser?.userId === editedUser?.userId;

  return useMemo(
    () => ({
      canEditRole: currentUser && isRolesMeetingController(currentUser.roles),
      canEjectUser:
        currentUser &&
        isRolesMeetingController(currentUser.roles) &&
        !isSelfEditing,
      canMuteUser: currentUser && isRolesMeetingController(currentUser.roles), // assuming - user can mute himself
    }),
    [currentUser, isSelfEditing]
  );
};
