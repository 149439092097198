export const TwoPersonPlusIcon = ({
  color = '#696F8C',
  width = '16',
  height = '9',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33327 4.33308C8.12183 4.33459 8.83767 3.8726 9.16111 3.15343C9.48456 2.43425 9.35523 1.59215 8.83087 1.00318C8.30652 0.414216 7.48503 0.188336 6.73327 0.426414C7.53214 1.572 7.53214 3.09416 6.73327 4.23975C6.92735 4.30121 7.12968 4.33269 7.33327 4.33308ZM3.99994 4.33308C5.10451 4.33308 5.99994 3.43765 5.99994 2.33308C5.99994 1.22851 5.10451 0.33308 3.99994 0.33308C2.89537 0.33308 1.99994 1.22851 1.99994 2.33308C1.99994 3.43765 2.89537 4.33308 3.99994 4.33308ZM7.9266 8.03308C8.03058 8.23943 8.0203 8.4849 7.89943 8.68183C7.77856 8.87875 7.56433 8.99905 7.33327 8.99975H0.666603C0.435541 8.99905 0.221317 8.87875 0.100445 8.68183C-0.0204273 8.4849 -0.0307099 8.23943 0.0732696 8.03308L0.739936 6.76641C1.07445 6.10232 1.74978 5.67863 2.49327 5.66641H5.5066C6.26212 5.66618 6.95329 6.09171 7.29327 6.76641L7.9266 8.03308ZM13.9999 3.66641H15.6666C15.8507 3.66641 15.9999 3.81565 15.9999 3.99975V4.66641C15.9999 4.85051 15.8507 4.99975 15.6666 4.99975H13.9999V6.66641C13.9999 6.85051 13.8507 6.99975 13.6666 6.99975H12.9999C12.8158 6.99975 12.6666 6.85051 12.6666 6.66641V4.99975H10.9999C10.8158 4.99975 10.6666 4.85051 10.6666 4.66641V3.99975C10.6666 3.81565 10.8158 3.66641 10.9999 3.66641H12.6666V1.99975C12.6666 1.81565 12.8158 1.66641 12.9999 1.66641H13.6666C13.8507 1.66641 13.9999 1.81565 13.9999 1.99975V3.66641ZM8.83994 5.66641C9.59545 5.66618 10.2866 6.09171 10.6266 6.76641L11.2599 8.03308C11.3639 8.23943 11.3536 8.4849 11.2328 8.68183C11.1119 8.87875 10.8977 8.99905 10.6666 8.99975H9.21327C9.39899 8.48752 9.36769 7.9217 9.1266 7.43308L8.49327 6.17308C8.40238 5.99473 8.29527 5.82512 8.17327 5.66641H8.83994Z"
        fill={color}
      />
    </svg>
  );
};
