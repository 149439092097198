import { useCallback, useEffect, useState } from 'react';
import { fetchStripeSubscriptionApi } from '../helper/api';
import { fromError, logerror } from '../helper/contextualLogger';
import {
  getDefaultPaymentExpirationTimeInMonths,
  checkIsSubscriptionCanceled,
} from '../pages/Workspaces/useWorkspaceBillingInfo.helpers';
import {
  checkIsCanceledBeforeBilling,
  checkIsSubscriptionPastDue,
} from 'zync-common/zyncCustomerPlans';

export const useWorkspaceSubscription = (subscriptionId) => {
  const [subscription, setSubscription] = useState(null);

  const [subscriptionLoaded, setSubscriptionLoaded] = useState(null);

  const refreshSubscription = useCallback(async () => {
    if (!subscriptionId) {
      return;
    }
    try {
      const result = await fetchStripeSubscriptionApi(subscriptionId);
      setSubscription(result?.subscription);
      setSubscriptionLoaded(true);
    } catch (error) {
      setSubscriptionLoaded(false);
      throw error;
    }
  }, [subscriptionId]);

  // Fetch the current stripe subscription whenever present in the workspace.
  useEffect(() => {
    refreshSubscription().catch((err) => logerror(fromError(err)));
  }, [refreshSubscription, subscriptionId]);

  const isPastDue = subscription
    ? checkIsSubscriptionPastDue(subscription)
    : false;

  const isCanceled = subscription
    ? checkIsSubscriptionCanceled(subscription)
    : false;

  const isCanceledBeforeBilling = subscription
    ? checkIsCanceledBeforeBilling(subscription)
    : false;

  const creditCardExpirationInMonths = subscription
    ? getDefaultPaymentExpirationTimeInMonths(subscription)
    : null;

  return {
    refreshSubscription,
    subscriptionLoaded,
    setSubscriptionLoaded,
    subscription,
    setSubscription,
    isPastDue,
    isCanceled,
    isCanceledBeforeBilling,
    creditCardExpirationInMonths,
  };
};
