import React, { Suspense } from 'react';
import { AuthoringSettingsTemplate } from './authoring/AuthoringSettingsTemplate';
import { useAuthoringTool } from './authoring/hooks';

export * from './SettingsInput';

const BlockComponents = {};
const Empty = () => <></>;
const getBlockComponent = (fileId, componentType) => {
  if (!fileId) return <></>;
  if (!BlockComponents[fileId]) {
    BlockComponents[fileId] = {
      Settings: React.lazy(() =>
        import(`../blocks/${fileId}.jsx`).then((module) => ({
          default: module.Settings || Empty,
        }))
      ),
    };
  }
  return BlockComponents[fileId][componentType];
};

export const BlockSettings = () => {
  const {
    selectedSlideBlock: selectedBlock,
    blocks,
    updateSlideBlock,
    currentSeries,
  } = useAuthoringTool();

  const blockDetails = blocks.find(
    (block) => block.blockId === selectedBlock?.blockId
  );

  if (!blockDetails) {
    return null;
  }

  const { code, settings, settingsSpec, details } = blockDetails;

  const blockSettings = {
    ...settings,
    ...selectedBlock.settings,
  };

  const SettingsComponent = getBlockComponent(code.clientSide, 'Settings');

  return (
    <Suspense fallback={<></>}>
      {settingsSpec ? (
        <AuthoringSettingsTemplate
          meetingSeriesId={currentSeries?.meetingSeriesId}
          blockInstanceId={selectedBlock?.blockInstanceId}
          settings={blockSettings}
          updateSettings={updateSlideBlock}
          settingsSpec={settingsSpec}
          details={details}
        />
      ) : (
        <SettingsComponent
          settings={blockSettings}
          updateSettings={updateSlideBlock}
        />
      )}
    </Suspense>
  );
};
