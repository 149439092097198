import UIFx from '../helper/UIFx';

export const SOUNDS = {
  rimshot: new UIFx(
    'https://storage.googleapis.com/zync-media/assets/audio/rimshot.mp3',
    {
      volume: 0.2,
      throttleMs: 2000,
    }
  ),

  fakenews: new UIFx(
    'https://storage.googleapis.com/zync-media/assets/audio/fakenews.mp3',
    {
      volume: 0.2,
      throttleMs: 2000,
    }
  ),
  airhorn: new UIFx(
    'https://storage.googleapis.com/zync-media/assets/audio/airhorn.mp3',
    {
      volume: 0.2,
      throttleMs: 2000,
    }
  ),
};
