import React, { useCallback, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { InputContainer } from './Input.style';
import {
  SelectButton,
  SelectItemContainer,
  SelectItemsContainer,
} from './SelectInput.style';
import { TextInput } from '.';
import * as Icons from '../common/Icon';
import { InputExplanation, FieldContainer, InputLabel } from '../SettingsInput';

/**
 * An input for when there is a discrete set of possible values. Displays the currently selected
 * value, with the option to click on the value to select another value from a dropdown area.
 */
export const SelectInput = ({
  /**
   * The select options. Either an array of strings, or an array of { value, title, icon } objects.
   * If `title` is specified, uses the plain text title as the visible select option.
   * If `icon` is specified, uses the matching icon from components/common/Icon.jsx
   */
  options = [],

  /** The label value to briefly describe the input. */
  label = '',

  /** A longer explanation of the input's purpose, if needed. */
  explanation = '',

  /** Whether the input is disabled. false by default. */
  disabled = false,

  /** The default Value of the input, if uncontrolled. */
  defaultValue = '',

  /** A handler for when the select value changes. Passes the new selected value (a string). */
  onChange,

  /** Whether to make all options visible without opening the dropdown. */
  flat = false,

  /** Whether to use a horizontal layout for the options. */
  horizontal = false,

  /** Whether to enable a custom text input below the select. */
  custom = false,

  /** (Optional, applies if custom is true.) The label for the custom label. */
  customLabel,
}) => {
  // convert an array of strings into an array of { value, title } if not already in the target format.
  options = options.map((option) =>
    typeof option !== 'object' ? { value: option, title: option } : option
  );

  // the current value of the select input.
  const [currentValue, setCurrentValue] = useState(defaultValue);

  // if the custom label option is selected.
  const [customSelected, setCustomSelected] = useState(
    () => custom && !options.some(({ value }) => value === currentValue)
  );

  // track the open state of the dropdown container.
  const [open, setOpen] = useState(false);

  /** Toggle the dropdown container. */
  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, [setOpen]);

  /** Handle selecting a new item from the dropdown. */
  const handleSelectItem = useCallback(
    (value) => {
      onChange && onChange(value);
      setCurrentValue(value);
      setCustomSelected(false);
      setTimeout(() => setOpen(false), 0);
    },
    [setCurrentValue, setCustomSelected, setOpen, onChange]
  );

  const handleSelectCustom = useCallback(() => {
    setCustomSelected(true);
    setOpen(false);
  }, [setCustomSelected, setOpen]);

  const handleSetCustomValue = useCallback(
    (value) => {
      setCurrentValue(value);
      onChange && onChange(value);
    },
    [setCurrentValue, onChange]
  );

  // The display value of the current value.
  const currentValueOption =
    options.find((option) => option.value === currentValue) ?? null;

  const currentValueTitle = customSelected
    ? customLabel ?? '(Custom Value)'
    : currentValueOption?.title ?? '';

  return (
    <FieldContainer>
      {label && <InputLabel>{label}</InputLabel>}

      <InputContainer>
        {!flat && (
          <SelectButton
            onClick={disabled ? undefined : handleToggle}
            disabled={disabled}
            className="select"
          >
            {currentValueTitle ? (
              <span>{currentValueTitle}</span>
            ) : (
              <span>Select a value</span>
            )}
            <FaChevronDown />
          </SelectButton>
        )}

        {(flat || (open && !disabled)) && (
          <SelectItemsContainer
            absolute={!flat}
            horizontal={horizontal}
            className="select-items-container"
          >
            {custom ? (
              <SelectItemContainer
                key={customLabel}
                onClick={handleSelectCustom}
                active={customSelected}
              >
                {customLabel ?? '(Custom Value)'}
              </SelectItemContainer>
            ) : null}

            {(options || []).map(({ value, title, icon, style }) => (
              <SelectItemContainer
                key={value}
                onClick={() => handleSelectItem(value)}
                active={value === currentValue}
                style={style}
              >
                {optionToDisplayValue({ value, title, icon })}
              </SelectItemContainer>
            ))}
          </SelectItemsContainer>
        )}
      </InputContainer>

      {custom && customSelected && (
        <TextInput
          value={currentValue}
          label={customLabel}
          onChange={(event, value) => handleSetCustomValue(value)}
        />
      )}

      {explanation && <InputExplanation> {explanation}</InputExplanation>}
    </FieldContainer>
  );
};

function optionToDisplayValue({ value, title, icon }) {
  const Icon = icon && typeof icon === 'string' ? Icons[icon] : null;
  const iconElement = icon && typeof icon !== 'string' ? icon : null;
  return (
    <>
      {Icon ? <Icon /> : null}
      {iconElement}
      {title ?? null}
      {!Icon && !title ? value : null}
    </>
  );
}
