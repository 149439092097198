export const VideoEditorLinkIcon = ({ width = 31, height = 31 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1474_827)">
        <path
          d="M3.41799 18.6754C3.85295 19.0898 4.39763 19.3997 5.0246 19.4346C6.78403 19.5275 8.84518 19.5043 10.3107 19.512C11.9291 19.5198 11.4902 21.4643 10.3852 21.4604C8.54346 21.4565 6.72133 21.4643 4.97757 21.4682C2.64212 21.4682 0.514345 19.2293 0.533938 16.9517C0.565286 13.1091 0.443811 8.87926 0.533938 4.96702C0.592716 2.57706 2.69306 0.721651 5.09905 0.508608C5.09905 0.508608 20.6557 0.48924 25.8634 0.508608C29.0218 0.520228 30.3384 2.8792 30.3737 5.28077C30.4129 7.83342 30.3188 11.0291 30.3737 13.3222C30.264 14.5462 28.3792 14.6237 28.3831 13.4384C28.3948 10.785 28.5908 7.89539 28.3831 5.26915C28.2616 3.75461 27.086 2.59256 25.5539 2.47248H5.4047C3.84511 2.5577 2.57158 3.72749 2.51281 5.26915C2.37174 8.98385 2.4697 13.0239 2.51281 16.7619C2.52064 17.4281 2.92033 18.2105 3.40232 18.6754H3.41799Z"
          fill="white"
        />
        <path
          d="M21.8588 22.7522L24.5743 24.4643C25.5304 23.7013 26.4513 23.2635 27.717 23.5076C30.5501 24.0537 31.3495 27.7491 28.9944 29.4031C26.6394 31.057 23.4144 29.1242 23.7592 26.312L19.9661 23.9801L14.4644 27.3307C13.2654 27.6329 12.6698 26.2926 13.5632 25.576L18.0695 22.7794L13.4143 19.8858C12.7129 19.053 13.4809 17.8755 14.5428 18.2783L19.9661 21.6251L23.7318 19.2777C23.4379 16.3416 26.8823 14.3777 29.2256 16.3571C31.6786 18.4294 29.9623 22.3998 26.7413 22.1325C25.8909 22.0628 25.2013 21.6289 24.5743 21.0983L21.8588 22.7522ZM27.9913 17.9181C26.7805 16.7328 24.9074 18.5843 26.1104 19.7774C27.3486 21.0092 29.2374 19.1383 27.9913 17.9181ZM27.9913 25.7852C26.7805 24.5999 24.9074 26.4514 26.1104 27.6445C27.3486 28.8763 29.2374 27.0054 27.9913 25.7852Z"
          fill="white"
        />
        <path
          d="M13.0772 5.99483C13.5827 5.91349 13.8961 5.96384 14.3154 6.21562C16.1454 7.31182 18.3476 8.67142 20.0248 9.84897C20.9143 10.4726 20.9378 11.6347 19.6133 12.4558C17.8343 13.5559 16.0592 14.7218 14.2527 15.8219C12.9518 16.616 11.8232 15.6244 11.7213 14.1834C11.6586 13.2886 11.7213 10.9955 11.7213 10.9955C11.7213 10.9955 11.6586 8.68692 11.7213 7.78826C11.7801 6.95933 12.1602 6.13815 13.0772 5.99095V5.99483Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1474_827">
          <rect
            width="30"
            height="30"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
