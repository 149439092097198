import React from 'react';
import styled from 'styled-components';

const LoadingSpinnerDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${(props) => props.width};
  cursor: default;

  @supports (aspect-ratio: 1 / 1) {
    aspect-ratio: 1 / 1;
    padding-top: 0;
  }

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    margin: 8px;
    border: ${(props) => props.thickness || '4px'} solid currentColor;
    border-radius: 50%;
    will-change: transform;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: currentColor transparent transparent transparent;
    padding-top: 100%;
    @supports (aspect-ratio: 1 / 1) {
      aspect-ratio: 1 / 1;
      padding-top: 0;
      width: calc(${(props) => props.width} * 0.8);
    }
  }

  div:nth-child(1) {
    display: none;
    animation-delay: -0.45s;
    @supports (aspect-ratio: 1 / 1) {
      display: block;
    }
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
    display: none;
    @supports (aspect-ratio: 1 / 1) {
      display: block;
    }
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
    display: none;
    @supports (aspect-ratio: 1 / 1) {
      display: block;
    }
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const DivStretchingToParent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const AbsoluteDivStretchingToParent = styled(DivStretchingToParent)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
`;

export const LoadingSpinner = ({
  className,
  width = '80px',
  style,
  thickness,
}) => {
  return (
    <LoadingSpinnerDiv
      className={className}
      width={width}
      style={style}
      thickness={thickness}
    >
      <div className="text-purple" style={style} />
      <div className="text-purple" style={style} />
      <div className="text-purple" style={style} />
      <div className="text-purple" style={style} />
    </LoadingSpinnerDiv>
  );
};

export const LoadingSpinnerFullCentered = () => {
  return (
    <DivStretchingToParent>
      <LoadingSpinner />
    </DivStretchingToParent>
  );
};

export const LoadingSpinnerAbsoluteFullCentered = () => {
  return (
    <AbsoluteDivStretchingToParent>
      <LoadingSpinner />
    </AbsoluteDivStretchingToParent>
  );
};
