export const SettingsIcon = ({
  color = '#696F8C',
  width = '14',
  height = '14',
  className = 'fill-[#696F8C]',
  style,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill={'none'}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4669 8.38243L13.0002 8.82331C13.2533 9.02893 13.3209 9.38769 13.1602 9.67166L12.3869 11.0077C12.227 11.2678 11.9113 11.3861 11.6202 11.2949L10.9535 11.0544C10.5601 10.9071 10.1204 10.9541 9.76688 11.1813C9.61016 11.2898 9.44522 11.3859 9.27355 11.4686C8.89841 11.6613 8.63637 12.0205 8.56688 12.4372L8.45355 13.1052C8.40073 13.4319 8.11714 13.6705 7.78688 13.6663H6.25355C5.92329 13.6705 5.6397 13.4319 5.58688 13.1052L5.47355 12.4372C5.40407 12.0205 5.14202 11.6613 4.76688 11.4686C4.59522 11.3859 4.43027 11.2898 4.27355 11.1813C3.92002 10.9541 3.48031 10.9071 3.08688 11.0544L2.42022 11.2949C2.11739 11.4076 1.77746 11.2866 1.61355 11.0077L0.840216 9.67166C0.679499 9.38769 0.747162 9.02893 1.00022 8.82331L1.53355 8.38243C1.85692 8.11346 2.0348 7.70748 2.01355 7.28691V6.99967V6.71244C2.0348 6.29187 1.85692 5.88589 1.53355 5.61692L1.00022 5.17604C0.747162 4.97042 0.679499 4.61166 0.840216 4.32769L1.61355 2.99169C1.77624 2.71917 2.10792 2.59908 2.40688 2.70445L3.07355 2.94493C3.46698 3.09226 3.90669 3.04523 4.26022 2.81801C4.41694 2.70953 4.58188 2.61349 4.75355 2.53077C5.11845 2.3317 5.36987 1.97357 5.43355 1.56218L5.54688 0.894181C5.5997 0.567492 5.88329 0.328805 6.21355 0.333064H7.74688C8.07714 0.328805 8.36073 0.567492 8.41355 0.894181L8.52688 1.56218C8.59637 1.97883 8.85842 2.33801 9.23355 2.53077C9.40522 2.61349 9.57016 2.70953 9.72688 2.81801C10.0804 3.04523 10.5201 3.09226 10.9135 2.94493L11.5802 2.70445C11.883 2.59171 12.223 2.71275 12.3869 2.99169L13.1602 4.32769C13.3209 4.61166 13.2533 4.97042 13.0002 5.17604L12.4669 5.61692C12.1435 5.88589 11.9656 6.29187 11.9869 6.71244V6.99967V7.28691C11.9656 7.70748 12.1435 8.11346 12.4669 8.38243ZM5.00022 6.99967C5.00022 8.10645 5.89565 9.00367 7.00022 9.00367C7.53065 9.00367 8.03936 8.79253 8.41443 8.41671C8.7895 8.04089 9.00022 7.53117 9.00022 6.99967C9.00022 5.8929 8.10478 4.99568 7.00022 4.99568C5.89565 4.99568 5.00022 5.8929 5.00022 6.99967Z"
      />
    </svg>
  );
};
