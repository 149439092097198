import { css } from 'styled-components';

export const typography = {
  paragraphRegular: css`
    font-family: ${({ theme: { typography } }) =>
      typography.fontFamily.regular};
    font-weight: ${({ theme: { typography } }) =>
      typography.fontWeight.regular};
    font-size: ${({ theme: { typography } }) => typography.fontSize.regular}px;
    line-height: ${({ theme: { typography } }) =>
      typography.lineHeight.regular}px;
  `,
  paragraphMedium: css`
    font-family: ${({ theme: { typography } }) =>
      typography.fontFamily.regular};
    font-weight: ${({ theme: { typography } }) =>
      typography.fontWeight.regular};
    font-size: ${({ theme: { typography } }) => typography.fontSize.regular}px;
    line-height: 150%;
  `,
  bodyLight: css`
    font-family: ${({ theme: { typography } }) =>
      typography.fontFamily.regular};
    font-size: ${({ theme: { typography } }) =>
      typography.fontSize.regular15}px;
    font-weight: ${({ theme: { typography } }) =>
      typography.fontWeight.regular};
    line-height: ${({ theme: { typography } }) =>
      typography.lineHeight.regular}px;
    letter-spacing: 0.25px;
  `,
  bodyHeavy: css`
    font-family: ${({ theme: { typography } }) =>
      typography.fontFamily.regular};
    font-size: ${({ theme: { typography } }) =>
      typography.fontSize.regular15}px;
    font-weight: ${({ theme: { typography } }) => typography.fontWeight.bolder};
    line-height: ${({ theme: { typography } }) =>
      typography.lineHeight.regular}px;
    letter-spacing: 0.25px;
  `,
  h1: css`
    font-family: ${({ theme: { typography } }) =>
      typography.fontFamily.regular};
    font-size: ${({ theme: { typography } }) => typography.fontSize.h1}px;
    font-weight: ${({ theme: { typography } }) => typography.fontWeight.bold};
    line-height: ${({ theme: { typography } }) => typography.lineHeight.h1}px;
    letter-spacing: 0.25px;
  `,
  h3: css`
    font-family: ${({ theme: { typography } }) =>
      typography.fontFamily.regular};
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  `,
  h4: css`
    font-family: ${({ theme: { typography } }) =>
      typography.fontFamily.regular};
    font-weight: ${({ theme: { typography } }) => typography.fontWeight.bold};
    font-size: ${({ theme: { typography } }) => typography.fontSize.medium}px;
    line-height: ${({ theme: { typography } }) =>
      typography.lineHeight.medium}px;
    margin: 0;
  `,
  h4a: css`
    font-family: ${({ theme: { typography } }) =>
      typography.fontFamily.regular};
    font-weight: ${({ theme: { typography } }) => typography.fontWeight.medium};
    font-size: ${({ theme: { typography } }) => typography.fontSize.regular}px;
    line-height: ${({ theme: { typography } }) =>
      typography.lineHeight.regular}px;
  `,
  captionHeavy: css`
    font-family: ${({ theme: { typography } }) =>
      typography.fontFamily.regular};
    font-size: ${({ theme: { typography } }) => typography.fontSize.small}px;
    font-weight: ${({ theme: { typography } }) => typography.fontWeight.bold};
    letter-spacing: 0.4px;
    line-height: 16px;
  `,
  titleHeavy: css`
    //table headers
    font-family: ${({ theme: { typography } }) =>
      typography.fontFamily.regular};
    font-size: ${({ theme: { typography } }) =>
      typography.fontSize.titleHeavy}px;
    font-weight: ${({ theme: { typography } }) => typography.fontWeight.bold};
    line-height: 28px;
    letter-spacing: 0.15px;
  `,
};

export const fieldLabel = css`
  font-family: ${({ theme: { typography } }) => typography.fontFamily.regular};
  font-weight: ${({ theme: { typography } }) => typography.fontWeight.medium};
  font-size: ${({ theme: { typography } }) => typography.fontSize.small}px;
  line-height: ${({ theme: { typography } }) => typography.lineHeight.small}px;
  color: ${({ theme: { palette } }) => palette.text.label};

  span {
    margin-bottom: ${({ theme }) => theme.spacing(0.5)}px;
  }
`;

export const baseInputStyle = css`
  ${typography.paragraphRegular};
  background-color: ${({ theme }) => theme.palette.background.field};
  border: 1px solid ${({ theme }) => theme.palette.border.field};
  border-radius: ${({ theme }) => theme.borderRadius.field}px;
  color: ${({ theme }) => theme.palette.text.paragraph};
  display: block;
  font-size: ${({ theme }) => theme.typography.fontSize.regular}px;
  padding: ${({ theme }) => theme.spacing(1.5)}px;
  outline-color: ${({ theme }) => theme.palette.border.hover};
  width: 100%;
  transition: background-color 300ms, color 300ms;
  box-sizing: border-box;

  &:disabled {
    background-color: ${({ theme }) => theme.palette.background.disabled};
    border-color: ${({ theme }) => theme.palette.border.disabled};
    color: ${({ theme }) => theme.palette.text.disabled};
  }

  &::placeholder {
    color: ${({ theme }) => theme.palette.text.placeholder};
  }

  &:hover {
    background-color: ${({ disabled, theme }) =>
      disabled
        ? theme.palette.background.disabled
        : theme.palette.background.hover};
    border-color: ${({ disabled, theme }) =>
      disabled ? theme.palette.border.disabled : theme.palette.border.hover};
  }
`;

export const baseButtonStyles = css`
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.field}px;
  cursor: pointer;
  display: inline-block;
  padding: ${({ theme }) => theme.spacing(1.5)}px;
  text-align: center;
  width: 100%;
  transition: background-color 300ms, color 300ms, opacity 300ms;

  :disabled {
    cursor: default;
    opacity: 0.9;
  }

  &:active {
    transform: translateY(1px);
  }
`;
