export const MaskIcon = ({
  className = 'fill-[#7612C4]',
  width = 24,
  height = 25,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2.39307H18C19.1046 2.39307 20 3.2885 20 4.39307V14.3931C20 18.8113 16.4183 22.3931 12 22.3931C7.58172 22.3931 4 18.8113 4 14.3931V4.39307C4 3.2885 4.89543 2.39307 6 2.39307ZM15 9.37721C14.1476 9.37721 13.3888 9.91751 13.11 10.7231C13.0562 10.8786 13.0824 11.0506 13.18 11.1831C13.2732 11.3169 13.4269 11.3957 13.59 11.3931H16.41C16.5731 11.3957 16.7268 11.3169 16.82 11.1831C16.9176 11.0506 16.9438 10.8786 16.89 10.7231C16.6112 9.91751 15.8524 9.37721 15 9.37721ZM7.11 10.7231C7.38881 9.91751 8.14756 9.37721 9 9.37721C9.85244 9.37721 10.6112 9.91751 10.89 10.7231C10.9438 10.8786 10.9176 11.0506 10.82 11.1831C10.7268 11.3169 10.5731 11.3957 10.41 11.3931H7.59C7.4269 11.3957 7.27319 11.3169 7.18 11.1831C7.0824 11.0506 7.05622 10.8786 7.11 10.7231ZM7 14.9531C7.28631 17.4938 9.44326 19.4091 12 19.3931C14.5453 19.3938 16.6849 17.4823 16.97 14.9531C16.9886 14.8118 16.9448 14.6694 16.85 14.5631C16.7525 14.456 16.6148 14.3943 16.47 14.3931H7.53C7.37718 14.3827 7.22857 14.4458 7.13 14.5631C7.02776 14.6654 6.9796 14.8099 7 14.9531Z"
      />
    </svg>
  );
};
