import React from 'react';

export const TwoPersonFilledIcon = ({
  width = '20',
  height = '14',
  className = 'fill-[#8B33F7]',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3C10 4.65685 8.65645 6 6.99909 6C5.34174 6 3.99818 4.65685 3.99818 3C3.99818 1.34315 5.34174 0 6.99909 0C8.65645 0 10 1.34315 10 3ZM14.5014 2C13.1202 2 12.0006 3.11929 12.0006 4.5C12.0006 5.88071 13.1202 7 14.5014 7C15.8825 7 17.0021 5.88071 17.0021 4.5C17.0021 3.11929 15.8825 2 14.5014 2ZM12.4507 9.66C11.9418 8.64084 10.8994 7.99776 9.75993 8H4.23826C3.09881 7.99776 2.05639 8.64084 1.54744 9.66L0.107007 12.55C-0.0490094 12.8595 -0.0335807 13.2277 0.147782 13.5231C0.329146 13.8185 0.650578 13.999 0.997276 14H13.0009C13.3476 13.999 13.669 13.8185 13.8504 13.5231C14.0318 13.2277 14.0472 12.8595 13.8912 12.55L12.4507 9.66ZM18.9427 10.65L19.893 12.55C20.049 12.8595 20.0336 13.2277 19.8522 13.5231C19.6709 13.8185 19.3494 13.999 19.0027 14H15.8318C16.1014 13.2319 16.0472 12.3874 15.6817 11.66L14.3513 9H16.2619C17.3955 8.99965 18.4326 9.63795 18.9427 10.65Z"
      />
    </svg>
  );
};
