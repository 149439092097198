import { useCallback, useContext, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import { logerror, loginfo } from '../helper/contextualLogger';
import { createWorkspaceApi, updateUserApi } from '../helper/api';
import { getWorkspaceIdFromUrl } from './useTemplateCreation';
import { getHostedBy } from '../pages/Workspaces/WorkspaceSettings';
import { setWorkspaceUrlParams } from '../helper/setWorkspaceUrlParams';
import { WorkspaceContext } from '../App';
import { episodeTypes } from 'zync-common/types';

/** Hook for user workspaces-related actions and state. */
export const useUserWorkspaces = ({ selectedWorkspaceId } = {}) => {
  const dispatch = useDispatch();

  const { workspaceId, user } = useSelector((_st) => _st.auth, shallowEqual);

  const {
    workspaces: userWorkspaces = [],
    defaultWorkspace = {},
    userId,
  } = user || {};
  const currentWorkspace =
    userWorkspaces.find(
      (w) =>
        w.workspaceId === selectedWorkspaceId ||
        w.workspaceId === workspaceId ||
        w.workspaceId === getWorkspaceIdFromUrl()
    ) || {};

  // Ensure that the current workspace id is still in the user's workspaces.
  // This way, if the user leaves the workspace, the UI will adjust accordingly when
  // this becomes null.
  const currentWorkspaceId =
    currentWorkspace?.workspaceId || getWorkspaceIdFromUrl();
  const currentWorkspaceName = currentWorkspace?.name;
  const [hostName, setHostName] = useState(null);
  const workspacePlanValidUntil = currentWorkspace?.workspacePlanValidUntil;

  const params = new URLSearchParams(window.location.search);
  const wasInvitedToWorkspace = params.get('invite') === 'true';

  const isFirstWorkspaceSolo =
    userWorkspaces.length === 1 &&
    userWorkspaces[0].workspacePlan === episodeTypes.solo;

  const isWorkspaceOnboardingRequired =
    userWorkspaces.length === 0 ||
    (userWorkspaces.length === 1 && wasInvitedToWorkspace);

  const isWorkspaceSuperAdmin =
    currentWorkspace?.billingOwnerUserId === user?.userId;
  const isWorkspaceAdmin = currentWorkspace?.isAdmin;
  const isWorkspaceProducer = currentWorkspace?.isProducer;
  const isWorkspaceHiddenAdmin =
    currentWorkspace?.isAdmin && currentWorkspace?.hidden;

  const { workspace, showPageData } = useContext(WorkspaceContext);

  const {
    creatorUserName,
    creatorUserId,
    show: workspaceShow,
  } = workspace || {};

  const isWorkspaceCreator = creatorUserId === userId;

  const hostedByText = workspaceShow?.hostedByText;

  const { hostedBy, hosts } = showPageData || {};

  useEffect(() => {
    const getHost = async () => {
      setHostName(null);

      setHostName(getHostedBy(hostedBy, hosts, hostedByText, creatorUserName));
    };
    getHost();
  }, [
    hostedBy,
    hosts,
    creatorUserName,
    creatorUserId,
    userId,
    hostedByText,
    workspaceId,
  ]);

  /* Switches to the provided workspaceId. If the user is not a member of the workspace, this is a no-op. */
  const switchWorkspace = useCallback(
    async (
      newWorkspaceId,
      workspaces = userWorkspaces,
      saveUserDefaultWorkspace = false
    ) => {
      if (!newWorkspaceId) {
        logerror({
          message: 'Workspace ID not passed to switchWorkspace, but expected.',
        });
        return;
      }
      if (!workspaces.find((w) => w.workspaceId === newWorkspaceId)) {
        return;
      }

      setWorkspaceUrlParams(newWorkspaceId);

      mixpanel.track('Menu: Workspace Shifted', {
        distinct_id: userId,
        workspaceId: newWorkspaceId,
      });

      dispatch({ type: 'SET_WORKSPACE', workspaceId: newWorkspaceId });

      if (saveUserDefaultWorkspace) {
        await updateUserApi(user.userId, {
          defaultWorkspaceId: newWorkspaceId,
        });
      }
    },
    [userWorkspaces, dispatch, user, userId]
  );

  const createWorkspace = useCallback(
    async (name, userId, options) => {
      const { result, error } = await createWorkspaceApi(name, userId, options);
      if (error) {
        loginfo({
          message: 'Error creating workspace.',
          error,
        });
        return { error };
      }

      const { workspace, user: updatedUser, autoCreatedMeeting } = result;
      mixpanel.track('Workspace Created', {
        workspaceName: workspace?.name,
        workspaceId: workspace?.workspaceId,
      });

      dispatch({
        type: 'SET_USER',
        user: updatedUser,
      });

      await switchWorkspace(
        workspace?.workspaceId,
        updatedUser.workspaces,
        true
      );

      return { user: updatedUser, workspace, autoCreatedMeeting };
    },
    [dispatch, switchWorkspace]
  );

  const brandKit = currentWorkspace?.brandKit || {};
  const show = currentWorkspace?.show;
  const soloSettings = currentWorkspace?.soloSettings;

  const result = {
    userWorkspaces,
    defaultWorkspace,
    isWorkspaceOnboardingRequired,
    currentWorkspaceId,
    currentWorkspaceName,
    isWorkspaceSuperAdmin,
    isWorkspaceAdmin,
    isWorkspaceProducer,
    isWorkspaceHiddenAdmin,
    isWorkspaceCreator,
    userId: user?.userId,
    switchWorkspace,
    createWorkspace,
    currentWorkspace,
    brandKit,
    show,
    soloSettings,
    hostName,
    workspacePlanValidUntil,
    creatorUserName,
    creatorUserId,
    isFirstWorkspaceSolo,
  };

  return result;
};
