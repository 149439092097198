import { useHistory } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';

import { TextInput } from '../../../components/Input';
import { ModalButton } from '../../../components/Modal';
import { LogoSpinnerFullCentered } from '../../../components/LogoSpinner';
import { FuturisticBackground } from '../../../components/FuturisticBackground';
import { SignOnHeader } from './SignOnHeader';
import { SignOnContainer } from './SignOnContainer';
import {
  triggerAppSumoOnboarding,
  validateAppSumoLicense,
} from '../../../helper/api';
import { WarningTriangleIcon } from '../../../components/icons/WarningTriangleIcon';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { isEmail } from '../../../helper/roles';
import { Button } from '../../../components/common/Button';
import noop from 'lodash/noop';
import { HeavyFullViewBackdropBlur } from '../../../components/HeavyFullViewBackdropBlur';

const LoginForm = ({ licenseData }) => {
  const history = useHistory();

  const [error, setError] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isCorrectEmail, setIsCorrectEmail] = useState(false);
  const [emailAddress, setEmailAddress] = useState();
  const [fullName, setFullName] = useState('');
  const [showLoginLink, setShowLoginLink] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const { userId, licenseKey } = licenseData;

  const validateEmail = async () => {
    if (!licenseData) {
      return;
    }

    if (isEmail(emailAddress) && isEmail(userId) && emailAddress !== userId) {
      return setError(
        "It looks like this license has already been activated using a different email address. If you're not sure which email address was used, please check your mailboxes for registration emails from us!"
      );
    }

    if (isEmail(emailAddress) && emailAddress === userId) {
      return setShowLoginLink(true);
    }

    if (isEmail(emailAddress)) {
      return setIsCorrectEmail(true);
    } else {
      setError('Please make sure to use the correct email address.');
    }
  };

  const onSubmitLogin = async (e) => {
    e.preventDefault();

    setError('');
    setIsLoading(true);

    try {
      await triggerAppSumoOnboarding(fullName, emailAddress, licenseKey);

      setIsSuccess(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={onSubmitLogin}>
      <div className="text-blue-gray font-normal text-sm pt-2 mb-6 -mt-5">
        Sign up and start using Zync
      </div>
      <TextInput
        autoFocus
        value={emailAddress}
        onChange={(event, value) => {
          setError('');
          setEmailAddress(value?.toLowerCase());
        }}
        onBlurNoValidation={true}
        label="Email"
        onBlur={isSuccess ? noop : validateEmail}
        placeholder="name@example.com"
        type="email"
        required={true}
      />
      {error ? (
        <p className="text-red font-normal text-sm p-0 mt-1 mb-2">{error}</p>
      ) : null}
      {showLoginLink ? null : (
        <TextInput
          value={fullName}
          onChange={(event, value) => {
            setFullName(value);
          }}
          onBlurNoValidation={true}
          label="Full name"
          placeholder="Enter your full name here"
          type="text"
          required={true}
        />
      )}
      {!error && !showLoginLink && isCorrectEmail ? (
        <div className="w-full flex h-12 my-8">
          {isSuccess ? (
            <div className="text-sm font-medium my-2 text-cyan">
              All set! Check you email from <strong>Zync.ai</strong>
            </div>
          ) : (
            <ModalButton
              type="submit"
              buttonText="Sign Up"
              disabled={isLoading}
            />
          )}
        </div>
      ) : null}
      {!error && showLoginLink ? (
        <div className="flex gap-2 flex-col">
          <p className="text-xs font-medium text-cyan">
            Hey there! Welcome back! Your account is ready. Just click this
            button to log in.
          </p>
          <Button
            color={Button.colors.PURPLE}
            onClick={() => {
              history.push(
                '/?intent=signin&email=' + encodeURIComponent(emailAddress)
              );
            }}
          >
            <span className="text-sm font-medium">Login</span>
          </Button>
        </div>
      ) : null}
      {isLoading && (
        <div className="w-full my-8">
          <LogoSpinnerFullCentered />
        </div>
      )}
    </form>
  );
};

const ValidationError = ({ error }) => {
  return (
    <div className="text-blue-gray font-normal text-center text-sm flex flex-col items-center gap-4">
      <WarningTriangleIcon className="fill-red" width={30} height={30} />
      <p className="font-medium">{error}</p>
    </div>
  );
};

export const SumoSignIn = ({ setPageMode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [licenseData, setLicenseData] = useState(null);

  const [error, setError] = useState('');

  useEffect(() => {
    const initialize = async () => {
      const params = new URLSearchParams(window.location.search);

      const authorizationCode = params.get('code');

      setIsLoading(true);

      try {
        const { error, license } = await validateAppSumoLicense(
          authorizationCode
        );

        if (error) {
          setError(error);
        }

        setLicenseData(license);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    initialize();
  }, []);

  const Form = (props) =>
    useMemo(() => {
      switch (true) {
        case isLoading: {
          return <LoadingSpinner />;
        }
        case Boolean(error): {
          return (
            <SignOnContainer pageMode="sumo" setPageMode={setPageMode}>
              <ValidationError error={error} />
            </SignOnContainer>
          );
        }
        case Boolean(licenseData?.userId && licenseData?.workspaceId): {
          return (
            <SignOnContainer pageMode="sumo" setPageMode={setPageMode}>
              <ValidationError
                error={
                  <span>
                    This license is already activated. Please contact{' '}
                    <strong>support@zync.ai</strong> if you think this is an
                    error.
                  </span>
                }
              />
            </SignOnContainer>
          );
        }
        case !!licenseData: {
          return (
            <SignOnContainer pageMode={'sumo'} setPageMode={setPageMode}>
              <LoginForm licenseData={licenseData} {...props} />
            </SignOnContainer>
          );
        }

        default: {
          return <div>Unhandled error</div>;
        }
      }
    }, [props]);

  return (
    <div className="md:fixed top-0 right-0 bottom-0 left-0 gap-4 md:gap-2 flex flex-col items-center md:justify-center opacity-100">
      <HeavyFullViewBackdropBlur />
      <FuturisticBackground topGradientOpacity="medium" />
      <SignOnHeader />

      <Form error={error} setError={setError} />
    </div>
  );
};
