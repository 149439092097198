/**
 * Timer Block. Enables the display of a configurable countdown timer with a progress bar.
 * Once the countdown reaches 0, counts up with an alarm bell and red coloring.
 */

import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { getStaticAssetUrl } from '../helper/getStaticAssetUrl';
import { BlockFunctionConstants } from './constants';
import { AbsolutePositioned } from './helper/Layout';
import { isUserMeetingController } from '../helper/roles';
import { PauseIcon } from '../components/icons/PauseIcon';
import { PlayIcon } from '../components/icons/PlayIcon';
import { RefreshIcon } from '../components/icons/RefreshIcon';
import { getSynchronizedTimestamp } from './helper/TimeSynchronization';

const alarmBellImage = getStaticAssetUrl('alarm-bell.gif');

export const BlockBuilderFunctions = {
  [BlockFunctionConstants.hasSummaryContent]: () => false,
};

/** Format a time span in milliseconds to a HH:MM:SS or MM:SS string. */
export const getTimerDisplayValue = (remaining, join = ' : ') => {
  const pad = (num) => (num < 10 ? `0${num}` : `${num}`);
  remaining = remaining > 0 ? remaining + 1000 : -remaining;
  const seconds = Math.floor(remaining / 1000);
  const minutes = Math.floor(remaining / (1000 * 60));
  const hours = Math.floor(remaining / (1000 * 60 * 60));

  const parts = [
    // only include hour timer if hours are set.
    ...(hours > 0 ? [pad(hours)] : []),
    pad(minutes % 60),
    pad(seconds % 60),
  ];

  return parts.join(join);
};

/** Gets the duration value in milliseconds from the block settings object. */
const getDurationFromSettings = (settings) => {
  settings = settings || {};
  return (
    (Number(settings.durationHours || 0) * 60 * 60 +
      Number(settings.durationMinutes || 0) * 60 +
      Number(settings.durationSeconds || 0)) *
    1000
  );
};

/** The progress bar height. */
const progressBarHeight = '10px';

/** Colors for the bars and timer. */
const colors = {
  bar: {
    neutral: '#D8DAE5',
    complete: '#d62324',
    inProgress: '#59ba65',
  },
  timerText: {
    inProgress: '#606481',
    complete: '#ffffff',
  },
  timerBackground: {
    inProgress: {
      Gray: '#D8DAE5',
      White: '#ffffff',
    },
    complete: '#d62324',
  },
};

/** The outermost container for the block. */
const BlockContainer = styled(AbsolutePositioned)`
  display: flex;
  flex-direction: column;
  min-height: 50px;
  width: 100%;
  gap: ${progressBarHeight};
`;

/** The progress bar container. */
const ProgressContainer = styled.div`
  > * {
    transition: all 240ms ease-out;
  }

  .neutral-bar,
  .progress-bar {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: ${progressBarHeight};
  }

  .neutral-bar {
    background: ${colors.bar.neutral};
  }

  // progress bar width and color are
  // specified inline with the component
  // for performance.
`;

/** The timer rectangle. */
const TimerContainer = styled.div`
  > .display-value {
    flex-grow: 1;
    text-align: center;
  }

  box-sizing: border-box;

  font-weight: 700;
  font-size: 18px;

  // expand the box to the right when the progress bar is complete
  transition: all 280ms ease-in-out 80ms;
  ${({ complete, backgroundColor }) =>
    complete
      ? css`
          background: ${colors.timerBackground.complete};
          > .display-value {
            color: ${colors.timerText.complete};
          }
        `
      : css`
          background: ${colors.timerBackground.inProgress[
            backgroundColor ?? 'Gray'
          ]};
          > .display-value {
            color: ${colors.timerText.inProgress};
          }
        `}
`;

/** Container for the animated bell image. */
const TimerBellAnimationContainer = styled.div`
  height: 27px;
  width: 27px;
  padding-right: 8px;
  overflow: hidden;

  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    transform: scale(1.7);
  }

  // show the bell animation when the timer has elapsed
  transition: opacity 180ms ease-out;
  opacity: ${(props) => (props.show ? 1 : 0)};
`;

/** Timer Alarm Bell animation which fades in the animated gif. */
const TimerBellAnimation = () => {
  const [loaded, setLoaded] = useState(false);

  return (
    <TimerBellAnimationContainer show={loaded}>
      <img onLoad={() => setLoaded(true)} src={alarmBellImage} alt="" />
    </TimerBellAnimationContainer>
  );
};

/** A countdown timer positioned at the top of the scene with digital readout and alarm bell. */
const CountdownTimer = ({
  /** The duration of the timer in milliseconds. */
  duration,
  /** The elapsed time in milliseconds. */
  elapsed = 0,
  /** The background color of the timer according to settings. */
  timerBackground = 'Gray',
  position,
  onRestartTimerEventHandler,
  user,
  onPauseTimerEventHandler,
  onUnpauseTimerEventHandler,
  paused,
}) => {
  /** Whether the timer is complete. */
  const complete = duration > 0 && elapsed > duration;
  /** Progress as a ratio of elapsed time / total duration, from 0 to 1. */
  const progress = Math.min(elapsed / duration, 1);
  /** Display timer value. */
  const timerDisplayValue = getTimerDisplayValue(duration - elapsed);

  return (
    <BlockContainer position={position}>
      <ProgressContainer>
        <div className="neutral-bar" />
        <div
          className="progress-bar"
          style={{
            background: complete ? colors.bar.complete : colors.bar.inProgress,
            width: `${Math.min(100, progress * 100)}%`,
          }}
        />
      </ProgressContainer>
      <TimerContainer
        complete={complete}
        backgroundColor={timerBackground}
        className="flex flex-row items-center justify-center gap-2 self-end py-1 px-2"
      >
        {isUserMeetingController(user) && (
          <div className="flex gap-2">
            <button
              className="flex justify-center items-center box-border cursor-pointer"
              onClick={onRestartTimerEventHandler}
            >
              <RefreshIcon
                width={20}
                color={complete ? '#ffba69' : '#8B33F7'}
              />
            </button>
            <button
              className="flex justify-center items-center box-border cursor-pointer"
              onClick={
                paused ? onUnpauseTimerEventHandler : onPauseTimerEventHandler
              }
            >
              {paused ? (
                <PlayIcon color={complete ? '#ffba69' : '#8B33F7'} width={20} />
              ) : (
                <PauseIcon
                  color={complete ? '#ffba69' : '#8B33F7'}
                  width={20}
                />
              )}
            </button>
          </div>
        )}
        <div className="flex flex-row gap-2 items-center">
          <div className="display-value flex items-center w-44 justify-center">
            {timerDisplayValue}
          </div>
          {complete && <TimerBellAnimation />}
        </div>
      </TimerContainer>
    </BlockContainer>
  );
};

/** The display block. */
const Block = ({ block, user, eventDispatch }) => {
  const { settings = {}, position, state = {} } = block;
  const { totalPauseTime = 0, paused = false, startTime } = state;

  // calculate duration in milliseconds from settings hours/minutes/seconds.
  const duration = getDurationFromSettings(settings);

  // Stores the elapsed time in ms, by which the timer value is updated.
  const [elapsed, setElapsed] = useState(null);

  /** Animate progress bars and update timer values by updating `elapsed` state. */
  useEffect(() => {
    if (startTime !== null) {
      const update = () => {
        if (paused) return;
        const elapsed =
          getSynchronizedTimestamp(user) - startTime - totalPauseTime;
        setElapsed(elapsed);

        handle = window.requestAnimationFrame(update);
      };
      let handle = window.requestAnimationFrame(update);

      return () => window.cancelAnimationFrame(handle);
    }
  }, [startTime, setElapsed, totalPauseTime, paused, user]);

  const handleRestartTimer = useCallback(() => {
    setElapsed(null);
    eventDispatch({ type: 'LAUNCH_BLOCK' });
  }, [eventDispatch]);

  const handlePauseTimer = useCallback(() => {
    eventDispatch({ type: 'PAUSE_TIMER' });
  }, [eventDispatch]);

  const handleUnpauseTimer = useCallback(() => {
    eventDispatch({ type: 'UNPAUSE_TIMER' });
  }, [eventDispatch]);

  return elapsed !== null ? (
    <CountdownTimer
      duration={duration}
      elapsed={elapsed}
      timerBackground={settings.timerColor || 'Gray'}
      position={position}
      onRestartTimerEventHandler={handleRestartTimer}
      user={user}
      paused={paused}
      onPauseTimerEventHandler={handlePauseTimer}
      onUnpauseTimerEventHandler={handleUnpauseTimer}
    />
  ) : null;
};

/**
 * The authoring block. Shows the configured duration by default
 * with progress bar at _66%_.
 *
 */
const AuthoringBlock = ({ block }) => {
  const { position } = block;
  const duration = getDurationFromSettings(block.settings) ?? 30 * 1000;
  const timerBackground = block.settings?.timerColor ?? 'Gray';
  const elapsed = Math.floor((duration * 2) / 3 / 1000) * 1000;
  const exampleUser = {
    userId: 'abcde.two',
    roles: ['moderator'],
  };

  return (
    <CountdownTimer
      duration={duration + elapsed - 1000}
      elapsed={elapsed}
      timerBackground={timerBackground}
      position={position}
      onRestartTimeEventHandler={() => {}}
      user={exampleUser}
      paused={false}
      onPauseTimerEventHandler={() => {}}
      onUnpauseTimerEventHandler={() => {}}
    />
  );
};

export { Block, AuthoringBlock };
