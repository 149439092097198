import { useEffect, useRef, useState } from 'react';
import { useUserWorkspaces } from '../../hooks/useUserWorkspaces';
import { fetchWorkspaceApi } from '../../helper/api';
import noop from 'lodash/noop';

export const useCreateNewProfileBasedSoloInterview = ({ createNewEvent }) => {
  const [workspace, setWorkspace] = useState(null);
  const { brandKit } = workspace || {};
  const ran = useRef(false);

  const [isLoading] = useState(true);

  const { currentWorkspaceId } = useUserWorkspaces();

  useEffect(() => {
    const getWorkspace = async () => {
      const { result } = await fetchWorkspaceApi(currentWorkspaceId);
      if (!result) return;
      setWorkspace(result);
    };
    getWorkspace();
  }, [currentWorkspaceId]);

  useEffect(() => {
    if (ran.current) {
      return;
    }

    if (workspace) {
      ran.current = true;
      createNewEvent();
    }
  }, [createNewEvent, workspace]);

  return {
    brandKit,
    isLoading,

    checkpoint: null,
    checkpointsData: [],

    getProgressState: noop,

    handlePreviousClick: noop,
    handleNextClick: noop,

    previousButtonStyling: '',
    nextButtonStyling: '',

    styleToDisablePreviousButtonAndDisableNextButton: noop,
    styleToEnablePreviousButtonAndNextButton: noop,

    areDatesValid: true,
  };
};
