import React from 'react';

export const MeetingControlMicSlash = ({ width = '26', height = '25' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="2.0"
        y1="2.0"
        x2="26.0"
        y2="25.0"
        stroke="#F8155C"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.9998 7L9.9998 11C9.9998 13.2091 11.7907 15 13.9998 15C16.2089 15 17.9998 13.2091 17.9998 11V7C17.9998 4.79086 16.2089 3 13.9998 3C11.7907 3 9.9998 4.79086 9.9998 7ZM20.8598 14.2C20.9668 14.2463 21.0475 14.338 21.0798 14.45C21.1065 14.5665 21.0769 14.6887 20.9998 14.78V14.86C19.7646 17.0971 17.535 18.6095 14.9998 18.93L14.9998 21H16.4998C16.7759 21 16.9998 21.2239 16.9998 21.5V22.5C16.9998 22.7761 16.7759 23 16.4998 23H11.4998C11.2237 23 10.9998 22.7761 10.9998 22.5V21.5C10.9998 21.2239 11.2237 21 11.4998 21H12.9598V18.98C10.4245 18.6595 8.19497 17.1471 6.9598 14.91C6.90533 14.7822 6.90533 14.6378 6.9598 14.51C6.99911 14.3772 7.0939 14.2678 7.2198 14.21L8.1298 13.79C8.36505 13.679 8.64602 13.7712 8.7698 14C9.84783 15.8939 11.868 17.0544 14.0472 17.0316C16.2263 17.0087 18.2217 15.8061 19.2598 13.89C19.366 13.6931 19.6078 13.614 19.8098 13.71L20.8598 14.2Z"
        fill="#8B33F7"
      />
    </svg>
  );
};
