import { useEffect } from 'react';

/**
 * When `active`, calls a handler `onClickOutside` when anything outside of
 * the element pointed to by `ref` is clicked.
 */
export const useOnClickOutside = (ref, active, onClickOutside) => {
  useEffect(() => {
    if (!active || !ref || !ref.current || !onClickOutside) {
      return;
    }

    const handler = (event) => {
      if (!ref.current || !onClickOutside) {
        return;
      }
      if (!ref.current.contains(event.target)) {
        onClickOutside();
      }
    };

    document.addEventListener('click', handler);
    document.addEventListener('touchstart', handler);

    return () => {
      document.removeEventListener('click', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, [active, ref, onClickOutside]);
};
