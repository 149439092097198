import styled from 'styled-components';
import { AbsolutePositioned } from '../../blocks/helper/Layout';

export const SoundBoardContainer = styled(AbsolutePositioned)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  box-sizing: border-box;
  padding: 10px 10px;
`;

export const Item = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 12px 12px 30px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  width: 91px;
  height: 91px;
  background-color: #ffffff;
  border: none;
  gap: 5px;
  padding: 0px 0px;
  min-width: 91px;
  min-height: 91px;
  overflow: hidden;

  &:hover {
    cursor: pointer;
    ${(props) => props.hoverEffect && 'background-color: #eeeeee;'}
  }
`;

export const ItemImg = styled.img`
  width: 50%;
  object-fit: contain;
  ${(props) => props.scale !== 0 && `transform: scale(${props.scale});`}
  position: relative;
  z-index: 1;
`;

export const ItemCaption = styled.div`
  font-family: Space Grotesk;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  z-index: 2;
`;
