import React, { useCallback, useEffect, useState } from 'react';

import classNames from '../../helper/classNames';

const Tabs = ({ tabs, onTabSelect, initialTabSelected, tabSelectedLabel }) => {
  const [currentTab, setCurrentTab] = useState(initialTabSelected.label);

  const handleSelectTab = useCallback(
    (tab) => {
      if (tab.label === currentTab) {
        return;
      } else {
        setCurrentTab(tab.label);
        onTabSelect(tab);
      }
    },
    [onTabSelect, currentTab, setCurrentTab]
  );

  useEffect(() => {
    setCurrentTab(tabSelectedLabel);
  }, [tabSelectedLabel]);

  return (
    <div
      className="flex justify-center space-x-[3.5%] bg-purple bg-opacity-5 pt-3 border-t border-light-gray border-b"
      role="listbox"
    >
      {tabs.map(({ label, Icon }) => (
        <button
          className={classNames(
            label !== currentTab && 'border-opacity-0 text-blue-gray',
            'border-b-2 border-purple border-opacity-100 pb-3 font-semibold'
          )}
          onClick={() => handleSelectTab({ label, Icon })}
          key={label}
        >
          {Icon ? (
            <span
              className={classNames(
                'flex items-center space-x-2 fill-blue',
                label === currentTab && 'text-purple'
              )}
            >
              <Icon width="18px" height="18px" color="currentColor" />
              <span>{label}</span>
            </span>
          ) : (
            label
          )}
        </button>
      ))}
    </div>
  );
};

export default Tabs;
