import { fetchUserApi } from './helper/api';
import jwt_decode from 'jwt-decode';
import { logwarn } from './helper/contextualLogger';
import { exponentialFetchRetry } from './helper/exponentialFetchRetry';

const AUTHKEY = 'AUTHKEY';
const auth = JSON.parse(window.localStorage.getItem(AUTHKEY) || '{}');
const getAuth = () => {
  return {
    authenticated: !!auth.token,
    token: auth.token,
  };
};

const getUserFromToken = async (jwtToken) => {
  if (!jwtToken) return undefined;
  const { userId } = jwt_decode(jwtToken) || {};
  if (!userId) return undefined;
  const result = await fetchUserApi(userId);
  if (!result) {
    logwarn({
      userId,
      message: `Expected to fetch user with userId: ${userId}, but encountered a failure.`,
    });
    return undefined;
  }
  return result;
};

const refreshUser = async (dispatch, userId) => {
  const updatedUser = await fetchUserApi(userId);
  dispatch({
    type: 'SET_USER',
    user: updatedUser,
  });
};

const getAuthHeader = () => {
  return {
    Authorization: `Bearer ${auth.token}`,
  };
};

const setAuth = (token) => {
  auth.token = token;
  window.localStorage.setItem(AUTHKEY, JSON.stringify({ token }));
};

const logout = () => {
  auth.token = null;
  auth.user = null;
  window.localStorage.removeItem(AUTHKEY);
};

const login = async ({ emailAddress, password }) => {
  return exponentialFetchRetry({
    fetchHandler: async () => {
      const response = await fetch('/auth/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          emailAddress: emailAddress,
          password: password,
        }),
      });
      return response;
    },
    jsonResponseHandler: async (response) => {
      const responseCode = response.status;
      const responseJson = await response.json();

      if (responseJson.error) {
        return { error: responseJson.error, status: responseCode };
      }

      const token = ((responseJson || {}).result || {}).token;

      if (!token) {
        return { error: 'Authentication failed - no token', status: 500 };
      }

      const user = ((responseJson || {}).result || {}).user;

      setAuth(token);

      return { user, token, status: 200 };
    },
    attributesToLog: {
      userId: emailAddress,
    },
  });
};

const passwordlessLogin = async ({ userId, accessCode, meetingSeriesId }) => {
  return exponentialFetchRetry({
    fetchHandler: async () => {
      const response = await fetch('/auth/passwordless-login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          meetingSeriesId,
          userId,
          accessCode,
        }),
      });
      return response;
    },
    jsonResponseHandler: async (response) => {
      const responseCode = response.status;

      const responseJson = await response.json();

      if (responseJson.error) {
        return { error: responseJson.error, status: responseCode };
      }

      const user = ((responseJson || {}).result || {}).user;

      return user;
    },
    attributesToLog: {
      meetingSeriesId,
      userId,
    },
  });
};

const register = async ({
  emailAddress,
  registrationSourceId,
  redirectUrl,
  userName,
}) => {
  return exponentialFetchRetry({
    fetchHandler: async () => {
      const response = await fetch('/auth/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          emailAddress,
          registrationSourceId,
          userName,
          redirectUrl,
        }),
      });
      return response;
    },
    jsonResponseHandler: async (response) => {
      const responseCode = response.status;
      const responseJson = await response.json();
      if (responseJson.error) {
        return { error: responseJson.error, status: responseCode };
      }
      return { status: 200 };
    },
    attributesToLog: { userId: emailAddress },
  });
};

const completeRegistration = async ({
  emailAddress,
  password,
  userName,
  registrationCode,
}) => {
  return exponentialFetchRetry({
    fetchHandler: async () => {
      const response = await fetch('/auth/completeRegistration', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          emailAddress,
          password,
          userName,
          registrationCode,
        }),
      });
      return response;
    },
    jsonResponseHandler: async (response) => {
      const responseCode = response.status;
      const responseJson = await response.json();
      if (responseJson.error) {
        return { error: responseJson.error, status: responseCode };
      }
      const token = ((responseJson || {}).result || {}).token;
      if (!token) {
        return { error: 'Authentication failed - no token', status: 500 };
      }
      const user = ((responseJson || {}).result || {}).user;
      setAuth(token, user);
      return { user, token, status: 200 };
    },
    attributesToLog: {
      userId: emailAddress,
    },
  });
};

const inviteToSeries = async ({
  emailAddress,
  meetingSeriesId,
  inviterUserName,
  initialRoles,
  workspaceId,
}) => {
  return exponentialFetchRetry({
    fetchHandler: async () => {
      const response = await fetch('/auth/invite', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          emailAddress,
          meetingSeriesId,
          inviterUserName,
          initialRoles,
          workspaceId,
        }),
      });
      return response;
    },
    jsonResponseHandler: async (response) => {
      const responseCode = response.status;

      const responseJson = await response.json();
      if (responseJson.error) {
        return { error: responseJson.error, status: responseCode };
      }
      return { series: responseJson.result, status: 200 };
    },
    attributesToLog: {
      userId: emailAddress,
      meetingSeriesId,
    },
  });
};

export {
  getAuth,
  getUserFromToken,
  setAuth,
  login,
  refreshUser,
  passwordlessLogin,
  logout,
  register,
  completeRegistration,
  inviteToSeries,
  getAuthHeader,
};
