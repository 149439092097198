import React from 'react';

export const MediaIcon = ({
  width = '21px',
  height = '17px',
  className = 'fill-purple',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 3.5C19.6046 3.5 20.5 4.39543 20.5 5.5V14.5C20.5 15.6046 19.6046 16.5 18.5 16.5H2.5C1.39543 16.5 0.5 15.6046 0.5 14.5V5.5C0.5 4.39543 1.39543 3.5 2.5 3.5H3.67C4.20304 3.50042 4.7142 3.28804 5.09 2.91L6.62 1.38C7.18207 0.81723 7.94462 0.5007 8.74 0.5H12.26C13.0554 0.5007 13.8179 0.81723 14.38 1.38L15.91 2.91C16.2858 3.28804 16.797 3.50042 17.33 3.5H18.5ZM6.5 9.5C6.5 11.7091 8.29086 13.5 10.5 13.5C11.5609 13.5 12.5783 13.0786 13.3284 12.3284C14.0786 11.5783 14.5 10.5609 14.5 9.5C14.5 7.29086 12.7091 5.5 10.5 5.5C8.29086 5.5 6.5 7.29086 6.5 9.5Z"
      />
    </svg>
  );
};
