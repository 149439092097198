import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useClickOutside } from '../../../helper/useClickOutside';
import { Button } from '../../common/Button';
import { TextInput } from '../../Input';
import { Checkbox } from '../../Checkbox/Checkbox';
import { Roles } from '../../../helper/roles';

const MAX_CHARACTERS_ALLOWED = 32;

const lowercaseNoSpaces = (str) => {
  return str.replace(/ /g, '').toLowerCase();
};

const defaultRoles = Object.values(Roles);

export const AddRolesPopupWindow = ({
  setIsBulkAssignLoading,
  users,
  onClose,
  roleSuggestions,
  onAddUsersRoles,
  showBulkAssign = false,
  setIsAddSingleUserRolesLoading,
}) => {
  const ref = useRef(null);
  const [roleName, setRoleName] = useState('');
  const [checkedRoles, setCheckedRoles] = useState({});
  const formattedRole = lowercaseNoSpaces(roleName);
  const [allRoles, setAllRoles] = useState(roleSuggestions);

  useEffect(() => {
    // Combines new roles which the user types in (inside of checked roles) & the existing roles (inside of roleSuggestions).
    // Creates a Set so duplicates are removed.
    // Spread the data in the Set back into an array.

    setAllRoles(
      Array.from(
        new Set([
          ...defaultRoles,
          ...roleSuggestions,
          ...Object.keys(checkedRoles),
        ])
      )
    );
  }, [checkedRoles, roleSuggestions]);

  const handleChange = useCallback((e) => {
    const value = e.target.value;

    if (value.length <= MAX_CHARACTERS_ALLOWED) {
      setRoleName(e.target.value);
    }
  }, []);

  const onBulkRolesAdd = () => {
    const containsRole = roleSuggestions.find(
      (roleName) => lowercaseNoSpaces(roleName) === formattedRole
    );
    if (!containsRole && formattedRole.length) {
      setCheckedRoles({ ...checkedRoles, [formattedRole]: true });
      setRoleName('');
    }
  };

  const getIsNewRolesAdded = useCallback(
    (rolesList) => {
      for (let currentUser of users) {
        for (let role in rolesList) {
          if (!currentUser.roles.includes(role)) {
            return true;
          }
        }
      }
      return false;
    },
    [users]
  );

  const onRolesAdd = useCallback(() => {
    let updatedCheckedRoles = { ...checkedRoles };
    if (formattedRole.length) {
      updatedCheckedRoles = { ...updatedCheckedRoles, [formattedRole]: true };
      setCheckedRoles(updatedCheckedRoles);
    }
    if (users) {
      // Checks if new roles are being added - used to show the loading spinner.
      const isNewRolesAdded = getIsNewRolesAdded(
        Object.keys(updatedCheckedRoles)
      );
      if (isNewRolesAdded) {
        if (!showBulkAssign) {
          setIsAddSingleUserRolesLoading(true);
        } else {
          setIsBulkAssignLoading(true);
        }
      }
      onAddUsersRoles(users, Object.keys(updatedCheckedRoles));
    }
    setRoleName('');
  }, [
    formattedRole,
    onAddUsersRoles,
    users,
    getIsNewRolesAdded,
    setIsAddSingleUserRolesLoading,
    setIsBulkAssignLoading,
    checkedRoles,
    showBulkAssign,
  ]);

  const handleUpdateCheckbox = (role) => {
    if (role in checkedRoles) {
      const updatedCheckedRoles = { ...checkedRoles };
      delete updatedCheckedRoles[role];
      setCheckedRoles(updatedCheckedRoles);
    } else {
      setCheckedRoles({ ...checkedRoles, [role]: true });
    }
  };

  useClickOutside(ref, onClose);

  return (
    <div
      className="box-border absolute bg-white max-h-72 m-2 rounded-md z-[10000] shadow-xl flex flex-col border border-solid border-gray w-60"
      ref={ref}
    >
      <div className="flex space-x-1 items-center mx-1">
        <TextInput
          value={roleName}
          onChange={handleChange}
          placeholder="Enter a new role"
        />

        <Button
          color={Button.colors.PURPLE}
          padding={Button.padding.MEDIUM}
          onClick={() => {
            if (showBulkAssign) {
              onBulkRolesAdd();
            } else {
              onRolesAdd();
            }
            onClose();
          }}
        >
          <span className="text-sm">Add</span>
        </Button>
      </div>

      <div className="overflow-auto h-full">
        {allRoles.map((role, idx) => (
          <div
            key={idx}
            className="flex space-x-2 py-3 hover:bg-purple hover:bg-opacity-20 px-5"
          >
            <Checkbox
              className="cursor-pointer"
              checked={role in checkedRoles}
              handleChange={() => handleUpdateCheckbox(role)}
            />
            <div key={role}>
              <span className="capitalize text-blue-gray">{role}</span>
            </div>
          </div>
        ))}
      </div>

      {showBulkAssign && (
        <Button
          onClick={() => {
            onRolesAdd();
          }}
          color={Button.colors.PURPLE}
          padding={Button.padding.MEDIUM}
        >
          <span className="text-sm">Bulk Assign</span>
        </Button>
      )}
    </div>
  );
};
