import React from 'react';

export const ShareIcon = ({
  width = '24px',
  height = '24px',
  className = 'fill-purple',
}) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 268.000000 353.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,353.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M995 3180 l-340 -340 70 -70 70 -70 215 215 c118 118 218 215 222
215 5 0 8 -427 8 -950 l0 -950 100 0 100 0 2 949 3 949 220 -213 220 -214 70
70 70 69 -340 340 c-187 187 -342 340 -345 340 -3 0 -158 -153 -345 -340z"
        />
        <path
          d="M230 2451 c-83 -26 -150 -82 -191 -161 l-24 -45 0 -1010 0 -1010 23
-46 c31 -63 86 -117 152 -148 l55 -26 1095 0 1095 0 55 26 c66 31 121 85 152
148 l23 46 0 1010 0 1010 -24 45 c-32 61 -76 107 -136 138 l-50 27 -402 3
-403 3 0 -101 0 -100 360 0 c294 0 367 -3 396 -15 68 -28 65 20 62 -1028 -3
-1018 -1 -971 -55 -996 -35 -16 -2113 -15 -2147 0 -58 27 -56 -7 -56 1008 0
795 2 937 15 967 26 62 39 64 445 64 l360 0 0 100 0 100 -387 -1 c-214 0 -399
-4 -413 -8z"
        />
      </g>
    </svg>
  );
};
