import React from 'react';

export const AudienceQAIcon = ({
  width = 18,
  height = 19,
  className = 'fill-[#066DE8]',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_18889_17892)">
      <path d="M19.7498 16.4177C19.7498 16.3811 19.7417 16.3486 19.7295 16.3161C19.7295 16.3121 19.7254 16.308 19.7214 16.2999C19.7132 16.2836 19.7051 16.2633 19.6929 16.2471L17.6862 13.6228V3.54025C17.6862 2.84966 17.154 2.28906 16.504 2.28906H15.0741C14.9116 2.28906 14.7776 2.42312 14.7776 2.58561C14.7776 2.7481 14.9116 2.88216 15.0741 2.88216H16.504C16.8331 2.88216 17.0971 3.1787 17.0971 3.54431V13.4319H2.91162V9.26401C2.91162 9.10152 2.77756 8.96746 2.61507 8.96746C2.45258 8.96746 2.31853 9.10152 2.31853 9.26401V13.6269L0.311755 16.2471C0.311755 16.2471 0.291443 16.2836 0.283319 16.2999C0.283319 16.304 0.279257 16.308 0.275194 16.3161C0.263007 16.3486 0.254883 16.3811 0.254883 16.4177C0.254883 16.4177 0.254883 16.4258 0.254883 16.4299V17.3155C0.254883 17.9695 0.787043 18.4976 1.43701 18.4976H18.5717C19.2217 18.4976 19.7539 17.9695 19.7539 17.3155V16.4299C19.7539 16.4299 19.7539 16.4218 19.7539 16.4177H19.7498ZM2.76131 14.021H17.2434L18.8602 16.1333H1.14452L2.76131 14.021ZM19.1608 17.3155C19.1608 17.6404 18.8967 17.9086 18.5717 17.9086H1.43295C1.10796 17.9086 0.843915 17.6445 0.843915 17.3155V16.7264H19.1608V17.3155Z" />
      <path d="M1.4331 8.08023H4.0939V12.0044C4.0939 12.1669 4.22796 12.3009 4.39045 12.3009H15.6186C15.7811 12.3009 15.9152 12.1669 15.9152 12.0044V4.32261C15.9152 4.16012 15.7811 4.02607 15.6186 4.02607H13.8921V1.68213C13.8921 1.03216 13.36 0.5 12.71 0.5H1.4331C0.783136 0.5 0.250977 1.0281 0.250977 1.68213V6.8981C0.250977 7.54807 0.783136 8.08023 1.4331 8.08023ZM13.8881 6.8981V4.61916H15.318V11.7079H4.68293V8.08023H8.31868L11.1785 10.1114C11.2313 10.1479 11.2882 10.1642 11.3491 10.1642C11.3938 10.1642 11.4426 10.152 11.4832 10.1317C11.5807 10.0829 11.6416 9.98138 11.6416 9.86764V8.07617H12.7019C13.3519 8.07617 13.884 7.54401 13.884 6.89404L13.8881 6.8981ZM0.840008 1.68213C0.840008 1.35714 1.10406 1.09309 1.42904 1.09309H12.7019C13.0269 1.09309 13.2909 1.35714 13.2909 1.68213V6.8981C13.2909 7.22309 13.0269 7.48714 12.7019 7.48714H11.3451C11.1826 7.48714 11.0485 7.62119 11.0485 7.78368V9.29892L8.5746 7.54401C8.52586 7.50745 8.46492 7.4912 8.40399 7.4912H1.4331C1.10812 7.4912 0.844071 7.22715 0.844071 6.90217V1.68213H0.840008Z" />
      <path d="M7.36393 3.42122H11.2028C11.3653 3.42122 11.4993 3.28716 11.4993 3.12467C11.4993 2.96218 11.3653 2.82812 11.2028 2.82812H7.36393C7.20144 2.82812 7.06738 2.96218 7.06738 3.12467C7.06738 3.28716 7.20144 3.42122 7.36393 3.42122Z" />
      <path d="M2.93229 3.42122H5.58903C5.75152 3.42122 5.88557 3.28716 5.88557 3.12467C5.88557 2.96218 5.75152 2.82812 5.58903 2.82812H2.93229C2.7698 2.82812 2.63574 2.96218 2.63574 3.12467C2.63574 3.28716 2.7698 3.42122 2.93229 3.42122Z" />
      <path d="M2.93229 5.74934H9.1354C9.29789 5.74934 9.43195 5.61529 9.43195 5.4528C9.43195 5.29031 9.29789 5.15625 9.1354 5.15625H2.93229C2.7698 5.15625 2.63574 5.29031 2.63574 5.4528C2.63574 5.61529 2.7698 5.74934 2.93229 5.74934Z" />
    </g>
    <defs>
      <clipPath id="clip0_18889_17892">
        <rect
          width="19.499"
          height="18"
          fill="white"
          transform="translate(0.250977 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
