import { useCallback, useState } from 'react';
import { debounce } from 'lodash/function';
import { updateSeriesApi } from '../../../helper/api';
import { fromError, logerror } from '../../../helper/contextualLogger';

export const useChangeAuthoringMode = (series, localDispatch) => {
  const [authoringModeEnabled, setAuthoringModeEnabled] = useState(
    series.settings.allowAdvancedAuthoring
  );

  const meetingSeriesId = series?.meetingSeriesId;

  const onChangeAuthoringMode = useCallback(
    async (newValue) => {
      try {
        const result = await updateSeriesApi(meetingSeriesId, {
          settings: {
            allowAdvancedAuthoring: newValue,
          },
        });
        if (!result) return;
        setAuthoringModeEnabled(result.settings.allowAdvancedAuthoring);
        localDispatch({ type: 'REFRESH_SERIES', series: result });
      } catch (error) {
        logerror(fromError(error));
      }
    },
    [localDispatch, meetingSeriesId]
  );

  const handleChangeAuthoringMode = debounce(onChangeAuthoringMode, 1_000);

  return {
    handleChangeAuthoringMode,
    authoringModeEnabled,
  };
};
