import { ConfirmationModal, Modal } from './Modal';
import { Button } from './common/Button';
import React from 'react';

export const DevicesAlreadyInUseModal = ({ onDismiss }) => {
  return (
    <ConfirmationModal
      title="Camera and microphone are already in use in different application"
      text={
        <div className="flex flex-col gap-5 -mt-4">
          <span>
            Your camera/mic are currently in use by another application. In
            order for Zync to be able to access your media streams, you would
            need to stop those applications and reload Zync.
          </span>
        </div>
      }
      size={Modal.size.md}
      actionButtons={
        <div>
          <Button
            size={Button.sizes.FULL}
            color={Button.colors.PURPLE}
            onClick={onDismiss}
          >
            <span className="text-base font-bold">Dismiss</span>
          </Button>
        </div>
      }
      width={512}
      height={290}
      shrinkOnResize
    />
  );
};
