import React, { createContext, useMemo, useReducer } from 'react';

export const AUTHORING_MODALS_ACTIONS = {
  SHOW_AUTHORING_MODE_POPUP: 'SHOW_AUTHORING_MODE_POPUP',
  HIDE_AUTHORING_MODE_POPUP: 'HIDE_AUTHORING_MODE_POPUP',
  SHOW_DELETE_SCENE_CONFIRMATION_MODAL: 'SHOW_DELETE_BLOCK_CONFIRMATION_MODAL',
  HIDE_DELETE_SCENE_CONFIRMATION_MODAL: 'HIDE_DELETE_BLOCK_CONFIRMATION_MODAL',
};

const initialState = {
  deleteSceneConfirmationModalOpen: false,
};

const localReducer = (state, action) => {
  switch (action.type) {
    case AUTHORING_MODALS_ACTIONS.SHOW_DELETE_SCENE_CONFIRMATION_MODAL: {
      const { sceneId } = action.data;
      return {
        ...state,
        deleteSceneConfirmationModalOpen: sceneId,
      };
    }
    case AUTHORING_MODALS_ACTIONS.HIDE_DELETE_SCENE_CONFIRMATION_MODAL: {
      return {
        ...state,
        deleteSceneConfirmationModalOpen: false,
      };
    }
    default: {
      return state;
    }
  }
};

export const AuthoringModalsContext = createContext({
  reducer: initialState,
});

export const AuthoringModalsContextProvider = ({ children }) => {
  const reducer = useReducer(localReducer, initialState);

  const value = useMemo(() => reducer, [reducer]);

  return (
    <AuthoringModalsContext.Provider value={value}>
      {children}
    </AuthoringModalsContext.Provider>
  );
};
