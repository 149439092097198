export const getRemainingTime = (remaining) => {
  const pad = (num) => (num < 10 ? `0${num}` : `${num}`);
  remaining = remaining > 0 ? remaining + 1000 : -remaining;
  const seconds = Math.floor(remaining / 1000);
  const minutes = Math.floor(remaining / (1000 * 60));
  const hours = Math.floor(remaining / (1000 * 60 * 60));

  return [
    // only include hour timer if hours are set.
    ...(hours > 0 ? [pad(hours)] : []),
    pad(minutes % 60),
    pad(seconds % 60),
  ];
};
