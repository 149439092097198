export const CheckInCircleIcon = ({
  color = '#DFE1E6',
  width = '31',
  height = '30',
  checkColor = '#FFFFFF',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15.5725" cy="15" r="15" fill={color} />
      <path
        d="M20.8059 11.8336L13.7392 18.9002C13.6766 18.9633 13.5914 18.9988 13.5025 18.9988C13.4137 18.9988 13.3285 18.9633 13.2659 18.9002L9.67254 15.3069C9.60944 15.2443 9.57394 15.1591 9.57394 15.0702C9.57394 14.9814 9.60944 14.8962 9.67254 14.8336L10.1392 14.3669C10.2018 14.3038 10.287 14.2683 10.3759 14.2683C10.4648 14.2683 10.55 14.3038 10.6125 14.3669L13.4992 17.2536L19.8659 10.8869C19.9978 10.7591 20.2073 10.7591 20.3392 10.8869L20.8059 11.3602C20.869 11.4228 20.9045 11.508 20.9045 11.5969C20.9045 11.6858 20.869 11.771 20.8059 11.8336Z"
        fill={checkColor}
      />
    </svg>
  );
};
