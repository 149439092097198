import React, { useCallback, useState, useRef } from 'react';
import { TextButtonContainer } from './Input.style';
import { TextInput } from './TextInput';
import { makeRandomId } from '../../helper';
import { Button } from '../common/Button';
import { InputExplanation } from '../SettingsInput';

/** An input allowing for an array of strings to be edited. */
export const TextArrayInput = ({
  /** The label value to briefly describe the input. */
  label = '',

  /** A longer explanation of the input's purpose, if needed. */
  explanation = '',

  /** Whether the input is disabled. false by default. */
  disabled = false,

  /** The default value of the input, if uncontrolled. An array of strings. */
  defaultValue,

  /** The value of the input, if controlled. An array of strings. */
  value,

  /**
   * Props to pass to the individual `TextInput` components.
   * @see TextInput
   */
  textInputProps = {
    type: 'text',
  },

  /**
   * A handler for when the input changes. Passes (values, index, op), where `values`
   * is an array of strings, and `index` is the array index of the changed, deleted
   * or new value, and `op` is either insert, change, or delete.
   */
  onChange,

  /**
   * A validation function that takes the value of one input and returns null or
   * undefined on success, or an error message string if it does not pass validation.
   */
  onValidate,

  /** A label for the Add Value button. */
  addLabel,

  /** An upper limit for the number of values. */
  maxLength,

  /** An upper limit for the number of characters. */
  textLength,

  /** Function to run when the input is out of focus */
  onBlur,

  onBlurNoValidation = false,
}) => {
  const refs = useRef([]);
  const [error, setError] = useState({});

  const [values, setValues] = useState(
    (value || defaultValue || []).map((v) => ({
      key: makeRandomId(4),
      value: v,
    }))
  );

  // prevent memory leaks by trimming refs array.
  refs.current = refs.current.slice(0, values.length);

  const insertValue = useCallback(() => {
    if (maxLength && values.length === maxLength) {
      return;
    }
    const changed = [...values, { key: makeRandomId(4), value: '' }];
    setValues(changed);
    onChange(
      changed.map(({ value }) => value),
      values.length,
      'insert'
    );
    setTimeout(() => {
      const element = refs.current[values.length];
      element && element.focus();
    }, 10);
  }, [setValues, onChange, refs, values, maxLength]);

  const deleteValue = useCallback(
    (index) => {
      const changed = [...values.slice(0, index), ...values.slice(index + 1)];
      setValues(changed);
      onChange(
        changed.map(({ value }) => value),
        index,
        'delete'
      );
      const element =
        refs.current[values.length - 1 === index ? index - 1 : index];
      element && element.focus();
    },
    [setValues, onChange, values]
  );

  const changeValue = useCallback(
    (value, index, event) => {
      const changed = [
        ...values.slice(0, index),
        { cursor: event.target.selectionStart, value, key: values[index].key },
        ...values.slice(index + 1),
      ];
      setValues(changed);

      const validationResult = onValidate ? onValidate(value) : null;
      if (validationResult) {
        setError((err) => ({ ...err, [index]: validationResult }));
      } else {
        setError((err) => ({ ...err, [index]: null }));
        onChange(
          changed.map(({ value }) => value),
          index,
          'change'
        );
      }
    },
    [setValues, onChange, values, setError, onValidate]
  );

  const handleEnterPressed = useCallback(
    (index) => {
      if (maxLength && values.length === maxLength) {
        return;
      }
      if (index === values.length - 1) {
        // enter presssed in last element. create a new element.
        insertValue();
      } else {
        setTimeout(() => {
          const element = refs.current[index + 1];
          element && element.focus();
        }, 10);
      }
    },
    [insertValue, values.length, maxLength]
  );

  return (
    <div className="flex flex-col items-stretch px-2">
      {label && (
        <div className="text-blue-dark font-medium text-sm mb-2">{label}</div>
      )}
      <div>
        {values.map(({ key, value }, index) => (
          <TextButtonContainer key={key} compact>
            <TextInput
              ref={(ref) => {
                refs.current[index] = ref;
                if (ref) {
                  ref.selectionStart = values[index].cursor;
                  ref.selectionEnd = values[index].cursor;
                }
              }}
              {...textInputProps}
              disabled={disabled}
              type="text"
              defaultValue={value}
              error={error[index] ?? undefined}
              onChange={(event, newValue) => {
                changeValue(newValue, index, event);
              }}
              onEnter={() => handleEnterPressed(index)}
              compact
              textLength={textLength ? textLength : ''}
              onBlur={onBlur}
              onBlurNoValidation={onBlurNoValidation}
            />
            <div
              className="cursor-pointer text-lg border border-solid border-light-gray px-2 text-blue-gray font-medium rounded-md mr-10 hover:bg-red hover:text-white duration-200"
              onClick={() => deleteValue(index)}
            >
              &times;
            </div>
          </TextButtonContainer>
        ))}
      </div>
      <div className="mb-1">
        <Button
          color={Button.colors.PURPLE}
          size={Button.sizes.FULL}
          onClick={insertValue}
          disabled={maxLength && values.length === maxLength}
        >
          <div className="text-xs flex items-center font-medium">
            <div> &#x2b;</div>
            <div className="pl-1">{addLabel ?? 'Add Option'}</div>
          </div>
        </Button>
      </div>
      {explanation && <InputExplanation> {explanation}</InputExplanation>}
    </div>
  );
};
