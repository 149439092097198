import React from 'react';

export const SpeakerIntroIcon = ({
  width = 18,
  height = 18,
  className = 'fill-[#7612C4]',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_18361_44446)">
      <path d="M8.99906 4.125C7.33406 4.125 5.97656 5.4825 5.97656 7.1475C5.97656 8.8125 7.33406 10.17 8.99906 10.17C10.6641 10.17 12.0216 8.8125 12.0216 7.1475C12.0216 5.4825 10.6641 4.125 8.99906 4.125ZM8.99906 9.42C7.74656 9.42 6.72656 8.4 6.72656 7.1475C6.72656 5.895 7.74656 4.875 8.99906 4.875C10.2516 4.875 11.2716 5.895 11.2716 7.1475C11.2716 8.4 10.2516 9.42 8.99906 9.42Z" />
      <path d="M12.8699 13.6853C12.9749 13.8653 13.2074 13.9253 13.3799 13.8203C13.5599 13.7153 13.6199 13.4903 13.5149 13.3103C12.6899 11.8928 11.1599 11.0078 9.52495 11.0078H8.46745C6.82495 11.0078 5.29495 11.8928 4.47745 13.3103C4.37245 13.4903 4.43245 13.7153 4.61245 13.8203C4.67245 13.8578 4.73995 13.8728 4.79995 13.8728C4.92745 13.8728 5.05495 13.8053 5.12245 13.6853C5.81245 12.4928 7.09495 11.7578 8.46745 11.7578H9.52495C10.8974 11.7578 12.1799 12.5003 12.8699 13.6853Z" />
      <path d="M15.525 0H12C11.79 0 11.625 0.165 11.625 0.375C11.625 0.585 11.79 0.75 12 0.75H15.525C16.4775 0.75 17.25 1.5225 17.25 2.475V6.0675C17.25 6.2775 17.415 6.4425 17.625 6.4425C17.835 6.4425 18 6.2775 18 6.0675V2.475C18 1.11 16.89 0 15.525 0Z" />
      <path d="M0.375 6.4425C0.585 6.4425 0.75 6.2775 0.75 6.0675V2.475C0.75 1.5225 1.5225 0.75 2.475 0.75H6C6.21 0.75 6.375 0.585 6.375 0.375C6.375 0.165 6.21 0 6 0H2.475C1.11 0 0 1.11 0 2.475V6.0675C0 6.2775 0.165 6.4425 0.375 6.4425Z" />
      <path d="M17.625 11.6953C17.415 11.6953 17.25 11.8603 17.25 12.0703V15.5278C17.25 16.4803 16.4775 17.2528 15.525 17.2528H12C11.79 17.2528 11.625 17.4178 11.625 17.6278C11.625 17.8378 11.79 18.0028 12 18.0028H15.525C16.89 18.0028 18 16.8928 18 15.5278V12.0703C18 11.8603 17.835 11.6953 17.625 11.6953Z" />
      <path d="M2.475 18.0028H6C6.21 18.0028 6.375 17.8378 6.375 17.6278C6.375 17.4178 6.21 17.2528 6 17.2528H2.475C1.5225 17.2528 0.75 16.4803 0.75 15.5278V12.0703C0.75 11.8603 0.585 11.6953 0.375 11.6953C0.165 11.6953 0 11.8603 0 12.0703V15.5278C0 16.8928 1.11 18.0028 2.475 18.0028Z" />
    </g>
    <defs>
      <clipPath id="clip0_18361_44446">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
