import { useContext, useEffect } from 'react';
import { FeedPageContext } from './FeedPageContextProvider';
import { useParams } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

export const useFeedPage = () => {
  const { highlightId: highlightIdFromParams } = useParams();

  const {
    feed,
    workspace,
    state: { selectedHighlight, setSelectedHighlight, isLoading },
  } = useContext(FeedPageContext);

  const user = useSelector((_st) => _st.auth.user, shallowEqual);
  const { userId } = user || {};
  const { members = [] } = workspace || {};
  const isWorkspaceMember = members.some((m) => m.userId === userId);

  const allHighlights =
    feed?.highlights?.reduce((highlights, episodeHighlights) => {
      const { hidden = false } = episodeHighlights || {};
      if (hidden && !isWorkspaceMember) return highlights;
      return highlights.concat(episodeHighlights);
    }, []) || [];

  const defaultHighlight = allHighlights[0];
  const highlightFromParams = allHighlights.find(
    (highlight) => highlight.highlightId === highlightIdFromParams
  );
  useEffect(() => {
    if (!selectedHighlight) {
      if (highlightFromParams) {
        setSelectedHighlight(highlightFromParams);
      } else {
        setSelectedHighlight(defaultHighlight);
      }
    }
  }, [
    selectedHighlight,
    setSelectedHighlight,
    defaultHighlight,
    highlightFromParams,
  ]);

  return {
    workspace,
    selectedHighlight,
    setSelectedHighlight,
    allHighlights,
    isLoading,
  };
};
