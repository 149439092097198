import { hotjar } from 'react-hotjar';

import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const hjid = 2231482;
const hjsv = 6;

export const useHotjar = () => {
  const userId = useSelector((st) => st.auth.user?.userId);
  const userName = useSelector((st) => st.auth.user?.userName);

  useEffect(() => {
    hotjar.initialize(hjid, hjsv);
  }, []);

  const isInitialized = hotjar.initialized();

  useEffect(() => {
    if (isInitialized) {
      hotjar.identify(userId, { userName, userId });
    }
  }, [userId, userName, isInitialized]);
};
