import React, { memo, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { absolutePosition } from '../helper/css';
import { PlayIcon } from './icons/PlayIcon';
import { PauseIcon } from './icons/PauseIcon';

const VideoContainer = styled.div`
  ${(props) => absolutePosition(props.position)};
`;

const IconWrapper = ({ children }) => {
  return (
    <div className="absolute bg-black/50 p-4 rounded-full animate-[fadeOutAndGrow_1000ms_both]">
      {children}
    </div>
  );
};
const VideoController = ({
  children,
  isUsed,
  toggleVideo,
  showPauseIcon,
  showPlayIcon,
}) => {
  return isUsed ? (
    <div
      className="relative flex items-center justify-center ani"
      onClick={toggleVideo}
    >
      {showPauseIcon && (
        <IconWrapper>
          <PlayIcon color="white" />
        </IconWrapper>
      )}
      {showPlayIcon && (
        <IconWrapper>
          <PauseIcon color="white" />
        </IconWrapper>
      )}
      {children}
    </div>
  ) : (
    children
  );
};

/**
 * VideoPlayer component used in custom activity (set via Design tab)
 * Supports video players of the most popular providers, with natural fallback to simple <video /> tag
 * Supported:
 * - YouTube, SoundCloud, Facebook, Vimeo, Twitch, Streamable, Wistia, DailyMotion, Mixcloud, Vidyard
 * - Files and custom urls (like CDNs) fallback to <video /> and use browser built-in player
 */
export const VideoPlayer = memo(
  ({ autoPlay, showControls, position, videoUrl, muted }) => {
    const [isPlaying, setIsPlaying] = useState(autoPlay);
    const [areInternalControlsUsed, setAreInternalControlsUsed] =
      useState(false);

    useEffect(() => {
      setIsPlaying(autoPlay);
    }, [autoPlay]);

    const playerRef = (ref) => {
      if (ref) {
        if (showControls) {
          return;
        }

        setTimeout(() => {
          const internalPlayer = ref.getInternalPlayer();

          // check if this is a native HTML element that does not support play/pause on click when controls are off
          if (internalPlayer?.tagName === 'VIDEO') {
            setAreInternalControlsUsed(true);
          }
        }, 1_000);
      }
    };

    return (
      <VideoContainer position={position}>
        <VideoController
          isUsed={areInternalControlsUsed}
          toggleVideo={() => setIsPlaying((wasPlaying) => !wasPlaying)}
          showPauseIcon={isPlaying}
          showPlayIcon={!isPlaying}
        >
          <ReactPlayer
            ref={playerRef}
            className="react-player"
            width="100%"
            height="100%"
            url={videoUrl}
            playing={isPlaying}
            controls={showControls}
            light={false}
            loop={false}
            volume={1}
            muted={!!muted}
            pip={false}
          />
        </VideoController>
      </VideoContainer>
    );
  }
);
