import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
  useContext,
} from 'react';
import {
  checkIsSubscriptionPastDue,
  PLANS,
} from 'zync-common/zyncCustomerPlans';
import { checkIsSubscriptionCanceled } from '../../pages/Workspaces/useWorkspaceBillingInfo.helpers';
import { RedButton, SimpleButton } from '../Button';
import '../meetingControl.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { isMobile } from '../../helper';
import { DailyMediaSettings } from '../DailyMediaSettings';
import {
  deleteDailyMeetingRoom,
  fetchWorkspaceApi,
  sendEvent,
  startRecording,
  stopRecording,
  updateWorkspaceIntegrationApi,
} from '../../helper/api';
import { isMeetingController } from '../../helper/roles';
import { useDailyControls } from '../../hooks/useDailyControls';
import { RadioWaveIcon } from '../icons/RadioWaveIcon';
import { useMeetingControls } from '../../hooks/useMeetingControls';
import { useDailyMicVolume } from '../../hooks/useDailyMicVolume';
import { throttle } from 'lodash';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import mixpanel from 'mixpanel-browser';
import { plans } from '../../helper/constants';
import { toast } from 'react-toastify';
import { micRelatedToastProps } from '../../helper/audio';
import { LoadingSpinner } from '../LoadingSpinner';
import { Button } from '../common/Button';
import { SelectInput, TextInput } from '../Input';
import { SubscriptionActionsContext } from '../planEnforcement/context/SubscriptionActionsContextProvider';
import { useWorkspacePlanEnforcementMessages } from '../useWorkspacePlanEnforcementMessages';
import {
  MediaChatButton,
  MediaLiveStreamingButton,
  MediaRecordButton,
  MediaScenePickerButton,
  MediaQuestionsButton,
} from './buttons';
import { MeetingControlButton } from './MeetingControlButton';
import { TwoPersonFilledIcon } from '../icons/TwoPersonFilledIcon';
import { AttendeesSettingsModal } from './PeopleControl/AttendeesSettingsModal';
import classNames from '../../helper/classNames';
import {
  ModalBody,
  ModalWindow,
  ModalButtonsGroup,
  ModalButton,
  Modal,
  ModalTitle,
} from '../Modal';
import { episodeTypes, meetingTypes } from 'zync-common/types';
import { useClickOutside } from '../../helper/useClickOutside';
import cameraDisabledAnimation from './Live meeting icons/Camera_Disabled.json';
import cameraAnimation from './Live meeting icons/Camera.json';
import exitAnimation from './Live meeting icons/exit.json';
import micAnimation from './Live meeting icons/Mic.json';
import micDisabledAnimation from './Live meeting icons/MicDisabled.json';
import settingsAnimation from './Live meeting icons/Settings.json';
import shareAnimation from './Live meeting icons/share.json';
import Lottie from 'react-lottie';
import { animationOptions } from './meetingControlAnimationOptions';
import { getBlockRoles } from './PeopleControl/getBlockRoles';
import { getUserRoles } from '../../pages/Series/MeetingAttendees';
import { useMeeting } from '../../hooks/useMeeting';
import { v4 as uuidv4 } from 'uuid';
import { fromError, logerror, logwarn } from '../../helper/contextualLogger';
import { useUserMediaPermissions } from '../../hooks/useUserMediaPermissions';
import { DisabledCameraIcon } from '../icons/DisabledCameraIcon';
import { DisabledMicIcon } from '../icons/DisabledMicIcon';
import { CrossIcon } from '../icons/CrossIcon';
import { PermissionsDeniedModal } from '../PermissionsDeniedModal';
import { useAreLocalTracksReady } from '../../hooks/useAreLocalTracksReady';
import { UnableToUseDevicesModal } from '../UnableToAccessDevicesModal';
import { FuturisticBackground } from '../FuturisticBackground';
import NavigationPrompt from 'react-router-navigation-prompt';
import { TroubleshootIcon } from '../icons/TroubleshootIcon';
import { OnGoLiveBar } from './OnGoLiveBar';
import { useLocalRecording } from '../../hooks/useLocalRecording';
import { checkIsSoloEpisode } from '../../pages/Portal/shared';
const OPEN_MIC_DETECTION_THRESHOLD = -30;

// Maximum frequency a user will be notified that their mic is off when trying to speak.
const MIC_OFF_WARNING_THROTTLE_DURATION = 20000;

// Id for toast handler used to detect is we are already displaying this kind of notification
const MIC_OFF_WARNING_ID = 'micOffWarning';

const endingSegment = [100, 100];

export const MeetingControl = React.memo(
  ({ series, subscriberOnly, onRecordChanges }) => {
    const { type: meetingType, settings } = series;
    const { timelineOn, includeEventQuestions } = settings || {};
    const { user: registeredUser } = useSelector((_st) => _st.auth);
    const { userId } = registeredUser || {};
    const [showEndMeetingConfirmation, setShowEndMeetingConfirmation] =
      useState(false);
    const [showEndLiveMeetingConfirmation, setShowEndLiveMeetingConfirmation] =
      useState(false);
    const {
      meetingConfig,
      streaming,
      recording,
      users,
      workspaceId,
      meetingId,
      isPreview,
      isLive,
      wentLive,
      wentLiveTimeStamp,
    } = useMeeting();

    const plan = useSelector((state) => state.meetingState.plan, shallowEqual);
    const showConnectionSettings = useSelector(
      (state) => state.clientDetails.activeModal
    );

    const meetingControlRef = useRef(null);

    const recordingState = useMemo(() => {
      return {
        ...(recording || {}),
        ...(streaming || {}),
      };
    }, [streaming, recording]);

    const [selectedMeetingControlButton, setSelectedMeetingControlButton] =
      useState('');

    const { subscription } = useContext(SubscriptionActionsContext);

    const updateWorkspace = useCallback(() => {
      if (!workspaceId) return;

      return fetchWorkspaceApi(workspaceId).then(
        ({ result: updatedWorkspace }) => {
          setWorkspace(updatedWorkspace);
        }
      );
    }, [workspaceId]);

    const [workspace, setWorkspace] = useState(null);
    useEffect(() => {
      updateWorkspace();
    }, [updateWorkspace, workspaceId]);

    const streamIntegrations = workspace?.integrations?.streaming || [];
    const liveStreamName = streamIntegrations.find(
      (i) => i.streamId === recordingState.selectedStreamId
    )?.name;

    const user = (users || {})[userId];

    const isScreenShareButtonShown = series.settings
      ?.moderatorsCanScreenShareOnly
      ? !subscriberOnly
      : true;

    const workspaceStreams = series?.workspace?.integrations?.streaming || [];

    const joinedUsers = useMemo(() => {
      return Object.values(users || {}).filter((u) => u.joined);
    }, [users]);

    const userIdToTrackStates =
      useSelector((_st) => _st.callState.tracks.allUsers) || {};

    const usersWithStream = Object.values(joinedUsers || {}).map((user) => ({
      ...user,
      trackStates: userIdToTrackStates[user.userId] || undefined,
    }));

    const { forceMicOn, forceMicOff } = user || {};

    const {
      dispatchRecordingUnsupportedEnforcementToast,
      dispatchStreamingUnsupportedEnforcementToast,
    } = useWorkspacePlanEnforcementMessages({ series, user, subscription });

    const {
      localCameraOn,
      localMicOn,
      localCameraAvailable,
      localMicAvailable,
      toggleMic,
      toggleCamera,
      startScreenShare,
      stopScreenShare,
      destroy,
      screenShareActive,
      isLocalUserScreenSharing,
      isLocalCameraUpdating,
      isLocalMicUpdating,
    } = useDailyControls();

    const meetingSeriesId = series.meetingSeriesId;
    const {
      startRecording: startLocalRecording,
      stopRecording: stopLocalRecording,
      userDevicesPrecheck,
    } = useLocalRecording(checkIsSoloEpisode(series));

    const workspacePlan = workspace?.workspacePlan;

    const handleStopLocalRecording = useCallback(
      () => stopLocalRecording({ workspaceId, meetingSeriesId, meetingId }),
      [workspaceId, meetingSeriesId, stopLocalRecording, meetingId]
    );

    const userRoles = user?.roles || [];

    const isHostOrSpeaker =
      userRoles.includes('speaker') || userRoles.includes('host');

    const mediaButtonsProps = useMediaButtons({
      meetingId,
      userId,
      recordingState,
      plan,
      onRecordingForbidden: dispatchRecordingUnsupportedEnforcementToast,
      onStreamingForbidden: dispatchStreamingUnsupportedEnforcementToast,
      startLocalRecording:
        isHostOrSpeaker && !isPreview ? startLocalRecording : undefined,
      stopLocalRecording:
        isHostOrSpeaker && !isPreview ? handleStopLocalRecording : undefined,
      userDevicesPrecheck:
        isHostOrSpeaker && !isPreview ? userDevicesPrecheck : undefined,
      episodeType: series?.settings?.episodeType,
    });

    const meetingControlTools = useMemo(() => {
      return {
        screenShareControls: {
          startScreenShare,
          stopScreenShare,
          screenShareActive,
          isLocalUserScreenSharing,
        },
        cameraOn: localCameraOn,
        micOn: localMicOn,
        toggleMic,
        toggleCamera,
        localCameraAvailable,
        localMicAvailable,
        isLocalCameraUpdating,
        isLocalMicUpdating,
      };
    }, [
      localCameraOn,
      localCameraAvailable,
      localMicOn,
      localMicAvailable,
      toggleMic,
      toggleCamera,
      startScreenShare,
      stopScreenShare,
      screenShareActive,
      isLocalUserScreenSharing,
      isLocalCameraUpdating,
      isLocalMicUpdating,
    ]);
    const isController = useMemo(() => {
      return isMeetingController(user);
    }, [user]);
    const dispatch = useDispatch();

    const [leaveMeetingControlsOpen, setLeaveMeetingControlsOpen] =
      useState(false);

    useEffect(() => {
      mixpanel.track('Studio: Camera Button Clicked', {
        distinct_id: userId,
        on: localCameraOn,
      });
    }, [localCameraOn, userId]);

    useEffect(() => {
      if (forceMicOff) {
        toggleMic(false);
        return;
      }
      if (forceMicOn) {
        toggleMic(true);
        return;
      }
    }, [toggleMic, forceMicOn, forceMicOff]);

    const sendMicOffWarning = useMemo(() => {
      return throttle(
        () => {
          if (!toast.isActive(MIC_OFF_WARNING_ID)) {
            toast('Are you trying to speak? Your mic is off.', {
              ...micRelatedToastProps,
              toastId: MIC_OFF_WARNING_ID,
            });
          }
        },
        MIC_OFF_WARNING_THROTTLE_DURATION,
        { trailing: false }
      );
    }, []);

    const onVolumeChange = useCallback(
      (volume) => {
        if (localMicOn || forceMicOff) return;
        if (volume > OPEN_MIC_DETECTION_THRESHOLD) {
          sendMicOffWarning();
        }
      },
      [localMicOn, forceMicOff, sendMicOffWarning]
    );

    // Kill volume tracking before allowing switching devices, or two mics will be activated
    // at once for a render cycle, which blows up some versions of Firefox.
    useDailyMicVolume(
      onVolumeChange,
      showConnectionSettings === 'settings' ||
        showConnectionSettings === 'cameraPermissions'
    );
    const [isEndMeetingAnimationStopped, setIsEndMeetingAnimationStopped] =
      useState(false);

    const handleEndMeeting = useCallback(async () => {
      // If using daily, first delete the daily room, this will automatically kick everyone out of the meeting
      await deleteDailyMeetingRoom(meetingId);
      destroy();
      mixpanel.track('Studio - End Session Clicked', { distinct_id: userId });
      const endMeetingAction = { type: 'END_MEETING', userId };
      dispatch(endMeetingAction);
      sendEvent(userId, meetingId, endMeetingAction);
    }, [userId, meetingId, dispatch, destroy]);

    const handleLeaveMeeting = useCallback(async () => {
      if (isLive) {
        return setShowEndLiveMeetingConfirmation(true);
      }
      const leaveMeetingAction = { type: 'LEAVE_MEETING', userId };
      // Clean up daily object.
      destroy();
      mixpanel.track('Studio - Exit Session Clicked', { distinct_id: userId });
      dispatch(leaveMeetingAction);
    }, [userId, dispatch, destroy, isLive]);

    const exitControlsRef = useRef(null);
    const peopleButtonRef = useRef(null);

    useOnClickOutside(exitControlsRef, leaveMeetingControlsOpen, () =>
      setLeaveMeetingControlsOpen(false)
    );

    useClickOutside(peopleButtonRef, () => setSelectedMeetingControlButton(''));

    const {
      isStreamingPopupOpen,
      setIsStreamingPopupOpen,
      onStreamingStop,
      onStreamingStart,
      isStreaming,
      areMuxActionsDisabled,
      recordingStartTime,
      watchUrl,
      setIsRecording,
      setIsStreaming,
    } = mediaButtonsProps;

    const isSubscriptionSetupAndInactive =
      subscription &&
      (checkIsSubscriptionPastDue(subscription) ||
        checkIsSubscriptionCanceled(subscription));

    const [actionButtonClicked, setActionButtonClicked] = useState(null);

    const blockRoles = useMemo(
      () => getBlockRoles(meetingConfig?.slides || []),
      [meetingConfig]
    );

    const roleSuggestions = useMemo(
      () => [...getUserRoles(joinedUsers), ...blockRoles],
      [joinedUsers, blockRoles]
    );

    const [isPermissionsDeniedModalOpen, setIsPermissionsDeniedModalOpen] =
      useState(false);
    const [
      isUnableToAccessDevicesModalOpen,
      setUnableToAccessDevicesModalOpen,
    ] = useState(false);

    useEffect(() => {
      if (isPermissionsDeniedModalOpen) {
        logwarn({
          message: `"Permissions denied" modal shown for ${userId} during Live Meeting ${meetingId}`,
          userId,
          meetingId,
        });
      }
    }, [isPermissionsDeniedModalOpen, userId, meetingId]);

    useEffect(() => {
      if (isUnableToAccessDevicesModalOpen) {
        logwarn({
          message: `"Unable to access devices" modal shown for ${userId} during Live Meeting ${meetingId}`,
          userId,
          meetingId,
        });
      }
    }, [isUnableToAccessDevicesModalOpen, userId, meetingId]);

    const { areDevicePermissionsDenied } = useUserMediaPermissions(
      null,
      subscriberOnly
    );

    const { isLocalAudioTrackReady, isLocalVideoTrackReady } =
      useAreLocalTracksReady();

    const isDisabledMicrophoneButtonShown =
      areDevicePermissionsDenied || !isLocalAudioTrackReady || subscriberOnly;

    const isDisabledCameraButtonShown =
      areDevicePermissionsDenied || !isLocalVideoTrackReady || subscriberOnly;

    const handleShowRelevantMicrophoneErrorModal = useCallback(() => {
      switch (true) {
        case areDevicePermissionsDenied: {
          setIsPermissionsDeniedModalOpen(true);
          break;
        }
        case isDisabledMicrophoneButtonShown: {
          setUnableToAccessDevicesModalOpen(true);
          break;
        }
        default: {
          // do nothing
        }
      }
    }, [isDisabledMicrophoneButtonShown, areDevicePermissionsDenied]);

    const handleShowRelevantCameraErrorModal = useCallback(() => {
      switch (true) {
        case areDevicePermissionsDenied: {
          setIsPermissionsDeniedModalOpen(true);
          break;
        }
        case isDisabledCameraButtonShown: {
          setUnableToAccessDevicesModalOpen(true);
          break;
        }
        default: {
          // do nothing
        }
      }
    }, [isDisabledCameraButtonShown, areDevicePermissionsDenied]);

    // Don't show the bottom bar if the user is the recording bot.
    if (registeredUser.recordingUser) {
      return null;
    }

    const primaryContrast = workspace?.brandKit?.primaryContrast;
    const primaryColor = workspace?.brandKit?.primaryColor;

    return (
      <>
        <NavigationPrompt when={isPreview}>
          {({ isActive, onConfirm, onCancel }) => {
            if (isActive)
              return (
                <ModalWindow size={Modal.size.sm}>
                  <ModalBody>
                    <div className="text-center">
                      Are you sure you want to go back?
                    </div>
                  </ModalBody>
                  <div className="flex gap-2 px-8 pb-8 justify-center items-center">
                    <Button
                      color={Button.colors.PURPLE}
                      onClick={() => {
                        if (isController) {
                          handleEndMeeting();
                        } else {
                          handleLeaveMeeting();
                        }
                      }}
                    >
                      Ok
                    </Button>
                    <Button color={Button.colors.GRAY} onClick={onCancel}>
                      Cancel
                    </Button>
                  </div>
                </ModalWindow>
              );
          }}
        </NavigationPrompt>
        <div
          ref={meetingControlRef}
          className="meeting-control w-full"
          style={{
            zIndex: 49,
          }}
        >
          {showEndMeetingConfirmation && (
            <div>
              <EndMeetingPopup
                setShowEndMeetingConfirmation={setShowEndMeetingConfirmation}
                handleEndMeeting={handleEndMeeting}
                onRecordChanges={onRecordChanges}
              />
            </div>
          )}
          {showEndLiveMeetingConfirmation && (
            <EndLiveMeetingConfirmationPopup
              setShowEndLiveMeetingConfirmation={
                setShowEndLiveMeetingConfirmation
              }
              handleEndMeeting={handleEndMeeting}
            />
          )}
          <div className="meeting-control--wrapper" style={{ width: 'auto' }}>
            <MediaButtons
              {...mediaButtonsProps}
              streamIntegrations={streamIntegrations}
              isController={isController}
              isSubscriptionSetupAndInactive={isSubscriptionSetupAndInactive}
              selectedMeetingControlButton={selectedMeetingControlButton}
              setSelectedMeetingControlButton={setSelectedMeetingControlButton}
              meetingType={meetingType}
              setActionButtonClicked={setActionButtonClicked}
              actionButtonClicked={actionButtonClicked}
              isStreamingPopupOpen={isStreamingPopupOpen}
              attendeesCount={Object.keys(joinedUsers).length}
              timelineOn={timelineOn}
              includeEventQuestions={includeEventQuestions}
              meetingId={meetingId}
              userId={userId}
              setIsRecording={setIsRecording}
              setIsStreaming={setIsStreaming}
              isPreview={isPreview}
              onRecordChanges={onRecordChanges}
              isLive={isLive}
              wentLive={wentLive}
              primaryContrast={primaryContrast}
              primaryColor={primaryColor}
              wentLiveTimeStamp={wentLiveTimeStamp}
              liveStreamName={liveStreamName}
              workspacePlan={workspacePlan}
              liveStreamModeOn={series?.workspace?.settings?.liveStreamModeOn}
              workspaceStreams={workspaceStreams}
              episodeType={series?.settings?.episodeType}
              userDevicesPrecheck={userDevicesPrecheck}
            />

            <div>
              <ControlButtons
                togglePowerOff={() =>
                  setLeaveMeetingControlsOpen(!leaveMeetingControlsOpen)
                }
                forceMicOff={forceMicOff}
                {...meetingControlTools}
                selectedMeetingControlButton={selectedMeetingControlButton}
                setSelectedMeetingControlButton={
                  setSelectedMeetingControlButton
                }
                handleShowRelevantCameraErrorModal={
                  handleShowRelevantCameraErrorModal
                }
                handleShowRelevantMicrophoneErrorModal={
                  handleShowRelevantMicrophoneErrorModal
                }
                isDisabledCameraButtonShown={isDisabledCameraButtonShown}
                isDisabledMicrophoneButtonShown={
                  isDisabledMicrophoneButtonShown
                }
                isScreenShareButtonShown={isScreenShareButtonShown}
                subscriberOnly={subscriberOnly}
                episodeType={series?.settings?.episodeType}
              />
            </div>
            <div className="absolute right-6 flex gap-[15px]">
              <a
                className="w-16 h-14 active:translate-y-1 duration-100 cursor-pointer rounded-xl hover:bg-opacity-10 hover:bg-purple flex justify-center items-center flex-col"
                href="https://www.zync.ai/troubleshooting-guide"
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  mixpanel.track('Studio: Need Help Button Clicked', {
                    distinct_id: userId,
                  });
                }}
              >
                <TroubleshootIcon />
                <div className="text-xxs text-purple font-semibold">
                  Need help?
                </div>
              </a>
              <button
                className="active:translate-y-1 duration-100 cursor-pointer h-14 w-14 rounded-lg hover:bg-opacity-10 hover:bg-red"
                onClick={() =>
                  setLeaveMeetingControlsOpen(!leaveMeetingControlsOpen)
                }
                onMouseEnter={() => setIsEndMeetingAnimationStopped(false)}
                onMouseLeave={() => setIsEndMeetingAnimationStopped(true)}
                title="Leave Meeting"
              >
                <div className="flex flex-col items-center justify-center">
                  <div className="flex flex-col justify-center items-center">
                    <div className="h-8 w-8">
                      <Lottie
                        options={{
                          ...animationOptions,
                          animationData: exitAnimation,
                        }}
                        isStopped={isEndMeetingAnimationStopped}
                      />
                    </div>
                  </div>
                  <div className="text-xxs text-red -mt-2.5 font-medium">
                    Exit
                  </div>
                </div>
              </button>
            </div>
          </div>
          <div
            ref={exitControlsRef}
            className={
              !leaveMeetingControlsOpen
                ? `meeting-control--animate`
                : `meeting-control--animate show`
            }
          >
            {leaveMeetingControlsOpen && (
              <>
                {isController && !isPreview && (
                  <div className="absolute right-6 bottom-[7.65rem] w-[142px]">
                    <EndButton
                      setShowEndMeetingConfirmation={
                        setShowEndMeetingConfirmation
                      }
                      setLeaveMeetingControlsOpen={setLeaveMeetingControlsOpen}
                      onRecordChanges={onRecordChanges}
                    />
                  </div>
                )}
                <div className="absolute right-6 bottom-[4.55rem] w-[142px]">
                  {isController && isPreview ? (
                    <EndButton
                      setShowEndMeetingConfirmation={
                        setShowEndMeetingConfirmation
                      }
                      setLeaveMeetingControlsOpen={setLeaveMeetingControlsOpen}
                      onRecordChanges={onRecordChanges}
                    />
                  ) : (
                    <LeaveButton
                      handleLeaveMeeting={handleLeaveMeeting}
                      onRecordChanges={onRecordChanges}
                    />
                  )}
                </div>
              </>
            )}
          </div>
          {isStreamingPopupOpen ? (
            isStreaming ? (
              <YouAreNowLivePopup
                watchUrl={watchUrl}
                onStreamingStop={onStreamingStop}
                areMuxActionsDisabled={areMuxActionsDisabled}
                setIsStreamingPopupOpen={setIsStreamingPopupOpen}
                setActionButtonClicked={setActionButtonClicked}
                isStreamingPopupOpen={isStreamingPopupOpen}
                isStreaming={isStreaming}
                recordingStartTime={recordingStartTime}
              />
            ) : (
              <GoLivePopup
                streamIntegrations={streamIntegrations}
                onStreamingStart={onStreamingStart}
                areMuxActionsDisabled={areMuxActionsDisabled}
                setIsStreamingPopupOpen={setIsStreamingPopupOpen}
                setActionButtonClicked={setActionButtonClicked}
                updateWorkspace={updateWorkspace}
                workspaceId={workspaceId}
                isPreview={isPreview}
                canLiveStream={(streamIntegrations || []).length > 0}
              />
            )
          ) : null}
        </div>
        {selectedMeetingControlButton === 'people' && isController && (
          <div ref={peopleButtonRef}>
            <AttendeesSettingsModal
              setSelectedMeetingControlButton={setSelectedMeetingControlButton}
              joinedUsers={usersWithStream}
              user={user}
              roleSuggestions={roleSuggestions}
            />
          </div>
        )}
        {showConnectionSettings === 'MEDIA_SETTINGS' && (
          <>
            <FuturisticBackground />
            <DailyMediaSettings
              type="update"
              onJoin={() => dispatch({ type: 'CLOSE_MODAL_WINDOW' })}
              onCancel={() => dispatch({ type: 'CLOSE_MODAL_WINDOW' })}
              subscriberOnly={subscriberOnly}
              accentColor={workspace?.brandKit?.accentColor}
            />
          </>
        )}
        {isUnableToAccessDevicesModalOpen && (
          <UnableToUseDevicesModal
            onDismiss={() => setUnableToAccessDevicesModalOpen(false)}
            isCameraReady={isLocalVideoTrackReady}
            isMicReady={isLocalAudioTrackReady}
            isLiveMeeting={true}
          />
        )}
        {isPermissionsDeniedModalOpen && (
          <PermissionsDeniedModal
            onDismiss={() => setIsPermissionsDeniedModalOpen(false)}
          />
        )}
      </>
    );
  }
);

const LeaveButton = ({ handleLeaveMeeting, onRecordChanges }) => {
  return (
    <Button
      onClick={handleLeaveMeeting}
      color={Button.colors.WHITE}
      size={Button.sizes.FULL}
    >
      <span className="text-sm font-medium w-max">
        {onRecordChanges ? 'Leave Studio' : 'Leave Meeting'}
      </span>
    </Button>
  );
};

const EndButton = ({
  setShowEndMeetingConfirmation,
  setLeaveMeetingControlsOpen,
  onRecordChanges,
}) => {
  return (
    <Button
      onClick={() => {
        setShowEndMeetingConfirmation(true);
        setLeaveMeetingControlsOpen(false);
      }}
      color={Button.colors.PINK}
      size={Button.sizes.FULL}
    >
      <span className="text-sm font-medium w-max px-2">
        {onRecordChanges ? 'End Session' : 'End Meeting'}
      </span>
    </Button>
  );
};

const EndMeetingPopup = ({
  handleEndMeeting,
  setShowEndMeetingConfirmation,
  onRecordChanges,
}) => {
  const userId = useSelector((st) => st.auth?.user?.userId);

  useEffect(() => {
    mixpanel.track('Studio - Studio End Warning Shown', {
      distinct_id: userId,
    });
  }, [userId]);

  return (
    <ModalWindow
      zyncLogo={false}
      size={onRecordChanges ? Modal.size.md : Modal.size.xs}
      showCancel={true}
    >
      <ModalBody>
        {onRecordChanges ? (
          <p className="text-center">
            Are you sure you want to end the Studio?
          </p>
        ) : (
          <p className="text-center">
            Are you sure you want to end your meeting?
          </p>
        )}
      </ModalBody>
      <ModalButtonsGroup>
        <ModalButton
          handleOnClick={() => setShowEndMeetingConfirmation(false)}
          buttonText={'Cancel'}
          color={ModalButton.color.WHITE}
        />
        <ModalButton
          color={Button.colors.RED}
          handleOnClick={handleEndMeeting}
          buttonText={
            onRecordChanges ? (
              <span className="text-sm">End Session and Exit Studio</span>
            ) : (
              'End Meeting'
            )
          }
        />
      </ModalButtonsGroup>
    </ModalWindow>
  );
};

const EndLiveMeetingConfirmationPopup = ({
  handleEndMeeting,
  setShowEndLiveMeetingConfirmation,
}) => (
  <ModalWindow zyncLogo={false} size={Modal.size.md} showCancel={true}>
    <ModalTitle>Your Session is still running</ModalTitle>
    <ModalBody>
      <p>Are you sure you want to leave the live Studio session?</p>
    </ModalBody>
    <ModalButtonsGroup>
      <ModalButton
        handleOnClick={() => setShowEndLiveMeetingConfirmation(false)}
        buttonText="Cancel"
        color={ModalButton.color.WHITE}
      />
      <ModalButton
        color={Button.colors.RED}
        handleOnClick={handleEndMeeting}
        buttonText={
          <span className="text-sm">End Session and Exit Studio</span>
        }
      />
    </ModalButtonsGroup>
  </ModalWindow>
);

const ControlButtons = ({
  micOn,
  cameraOn,
  screenShareControls,
  toggleMic,
  forceMicOff,
  localCameraAvailable,
  localMicAvailable,
  toggleCamera,
  isLocalCameraUpdating,
  handleShowRelevantMicrophoneErrorModal,
  handleShowRelevantCameraErrorModal,
  isDisabledCameraButtonShown,
  isDisabledMicrophoneButtonShown,
  isScreenShareButtonShown,
  subscriberOnly,
  episodeType,
}) => {
  const {
    screenShareActive,
    startScreenShare,
    stopScreenShare,
    isLocalUserScreenSharing,
  } = screenShareControls || {};

  const isSolo = episodeType === episodeTypes.solo;

  const dispatch = useDispatch();

  const [isSettingsAnimationStopped, setIsSettingsAnimationStopped] =
    useState(true);

  const [isShareAnimationStopped, setIsShareAnimationStopped] = useState(true);

  // Mic Animation State
  const micAnimationOnMount = useRef(true);
  const micClick = useRef(false);
  const [micAnimationToShow, setMicAnimationToShow] = useState(
    micOn ? micAnimation : micDisabledAnimation
  );
  const [initialMicSegment, setInitialMicSegment] = useState(
    micOn ? null : endingSegment
  );
  const [isMicAnimationStopped, setIsMicAnimationStopped] = useState(true);

  // Camera Animation State
  const cameraAnimationOnMount = useRef(true);
  const cameraClick = useRef(false);
  const [cameraAnimationToShow, setCameraAnimationToShow] = useState(
    cameraOn ? cameraAnimation : cameraDisabledAnimation
  );
  const [initialCameraSegment, setInitialCameraSegment] = useState(
    cameraOn ? null : endingSegment
  );
  const [isCameraAnimationStopped, setIsCameraAnimationStopped] =
    useState(true);

  const userId = useSelector((st) => st.auth?.user?.userId);

  const toggleScreenShare = useCallback(() => {
    if (isLocalUserScreenSharing) {
      stopScreenShare();
      mixpanel.track('Studio: Stop Screen Share', { distinct_id: userId });
    } else {
      startScreenShare();
      mixpanel.track('Studio: Start Screen Share', { distinct_id: userId });
    }
  }, [userId, isLocalUserScreenSharing, startScreenShare, stopScreenShare]);

  // Screen sharing is disabled if you are not the meeting controller or if someone else is sharing the screen.
  const screenSharingDisabled = screenShareActive && !isLocalUserScreenSharing;

  /* 
   Mic props & camera props for the
   updateAudioVideoMouseAnimation function.
  */
  const micAnimationProps = {
    enabledAnimation: micAnimation,
    setAnimation: setMicAnimationToShow,
    setInitialSegment: setInitialMicSegment,
    setAnimationStopped: setIsMicAnimationStopped,
    isResourceOn: micOn,
  };

  const cameraAnimationProps = {
    enabledAnimation: cameraAnimation,
    setAnimation: setCameraAnimationToShow,
    setInitialSegment: setInitialCameraSegment,
    setAnimationStopped: setIsCameraAnimationStopped,
    isResourceOn: cameraOn,
  };

  const mouseActionsEnum = {
    ENTER: 'ENTER',
    LEAVE: 'LEAVE',
    CLICK: 'CLICK',
  };

  const { selfToggleMic, isLocalMicUpdating } = useMeetingControls();

  useEffect(() => {
    if (micAnimationOnMount.current) {
      micAnimationOnMount.current = false;
      return;
    }
    // If the mic is turned on via the meeting control bar.
    if (micClick.current) {
      setIsMicAnimationStopped(false);
      setMicAnimationToShow(micOn ? micAnimation : micDisabledAnimation);
      micClick.current = false;
      return;
    }
    // If the mic is turned on via an outside source (Settings).
    setIsMicAnimationStopped(true);
    setInitialMicSegment(micOn ? null : endingSegment);
    setMicAnimationToShow(micOn ? micAnimation : micDisabledAnimation);
  }, [micOn, isLocalMicUpdating]);

  useEffect(() => {
    if (cameraAnimationOnMount.current) {
      cameraAnimationOnMount.current = false;
      return;
    }
    // If the camera is turned on via the meeting control bar.
    if (cameraClick.current) {
      setIsCameraAnimationStopped(false);
      setCameraAnimationToShow(
        cameraOn ? cameraAnimation : cameraDisabledAnimation
      );
      cameraClick.current = false;
      return;
    }
    // If the camera is turned on via an outside source (Settings).
    setIsCameraAnimationStopped(true);
    setInitialCameraSegment(cameraOn ? null : endingSegment);
    setCameraAnimationToShow(
      cameraOn ? cameraAnimation : cameraDisabledAnimation
    );
  }, [cameraOn, isLocalCameraUpdating]);

  /**
   * Updates Animation on mouse enter/leave for camera/mic.
   */
  const updateAudioVideoMouseAnimation = ({
    setAnimationStopped,
    setInitialSegment,
    setAnimation,
    enabledAnimation,
    isResourceOn = true,
    mouseAction = mouseActionsEnum.ENTER,
    clickRef = null,
  }) => {
    switch (mouseAction) {
      case mouseActionsEnum.ENTER:
        if (isResourceOn) {
          setAnimation(enabledAnimation);
          setAnimationStopped(false);
        }
        break;
      case mouseActionsEnum.LEAVE:
        if (isResourceOn) {
          setInitialSegment(null);
          setAnimationStopped(true);
        } else {
          setInitialSegment(endingSegment);
        }
        break;
      case mouseActionsEnum.CLICK:
        setInitialSegment(null);
        clickRef.current = true;
        break;
      default:
        return;
    }
  };

  return (
    <>
      <div className="flex gap-x-3.5">
        {!isMobile && !subscriberOnly && (
          <button
            onMouseEnter={() => setIsSettingsAnimationStopped(false)}
            onMouseLeave={() => setIsSettingsAnimationStopped(true)}
            className="active:translate-y-1 duration-100 cursor-pointer h-14 w-14 rounded-lg hover:bg-opacity-10 hover:bg-purple"
            onClick={() => {
              mixpanel.track('Studio: Settings Button Clicked', {
                distinct_id: userId,
              });
              dispatch({ type: 'LAUNCH_MODAL', modal: 'MEDIA_SETTINGS' });
            }}
          >
            <div className="flex flex-col justify-center items-center">
              <div className="h-8 w-8">
                <Lottie
                  options={{
                    ...animationOptions,
                    animationData: settingsAnimation,
                  }}
                  isStopped={isSettingsAnimationStopped}
                />
              </div>
              <span className="text-xxs text-purple font-medium">Settings</span>
            </div>
          </button>
        )}

        {/* MIC BUTTON */}
        {isDisabledMicrophoneButtonShown ? (
          <DisabledMicButton
            onClick={() => {
              if (subscriberOnly) return;
              handleShowRelevantMicrophoneErrorModal();
            }}
          />
        ) : (
          <button
            className="active:translate-y-1 duration-100 cursor-pointer h-14 w-14 rounded-lg hover:bg-opacity-10 hover:bg-purple"
            onClick={() => {
              mixpanel.track('Studio: Microphone Button Clicked', {
                distinct_id: userId,
                on: micOn,
              });
              selfToggleMic && !forceMicOff && selfToggleMic();
              updateAudioVideoMouseAnimation({
                ...micAnimationProps,
                mouseAction: mouseActionsEnum.CLICK,
                clickRef: micClick,
              });
            }}
            disabled={!toggleMic || forceMicOff || !localMicAvailable}
            onMouseEnter={() =>
              updateAudioVideoMouseAnimation(micAnimationProps)
            }
            onMouseLeave={() =>
              updateAudioVideoMouseAnimation({
                ...micAnimationProps,
                mouseAction: mouseActionsEnum.LEAVE,
              })
            }
          >
            <div className="flex flex-col justify-center items-center w-full h-full">
              {isLocalMicUpdating ? (
                <div className="animate-[fadeIn_100ms]">
                  <LoadingSpinner width="40px" />
                </div>
              ) : (
                <div className="h-8 w-8">
                  <Lottie
                    options={{
                      ...animationOptions,
                      animationData: micAnimationToShow,
                      initialSegment: initialMicSegment,
                    }}
                    isStopped={isMicAnimationStopped}
                  />
                </div>
              )}
              <span className="text-xxs text-purple font-medium">Mic</span>
            </div>
          </button>
        )}
        {/* CAMERA BUTTON */}
        {isDisabledCameraButtonShown ? (
          <DisabledCameraButton
            onClick={() => {
              if (subscriberOnly) return;
              handleShowRelevantCameraErrorModal();
            }}
          />
        ) : (
          <button
            className="active:translate-y-1 duration-100 cursor-pointer h-14 w-14 rounded-lg hover:bg-opacity-10 hover:bg-purple"
            disabled={!localCameraAvailable}
            onClick={() => {
              mixpanel.track('Studio: Camera Button Clicked', {
                distinct_id: userId,
                on: cameraOn,
              });
              toggleCamera();
              updateAudioVideoMouseAnimation({
                ...cameraAnimationProps,
                mouseAction: mouseActionsEnum.CLICK,
                clickRef: cameraClick,
              });
            }}
            onMouseEnter={() =>
              updateAudioVideoMouseAnimation(cameraAnimationProps)
            }
            onMouseLeave={() =>
              updateAudioVideoMouseAnimation({
                ...cameraAnimationProps,
                mouseAction: mouseActionsEnum.LEAVE,
              })
            }
          >
            <div className="flex flex-col justify-center items-center w-full h-full">
              {isLocalCameraUpdating ? (
                <LoadingSpinner width="40px" />
              ) : (
                <div className="h-8 w-8">
                  <Lottie
                    options={{
                      ...animationOptions,
                      animationData: cameraAnimationToShow,
                      initialSegment: initialCameraSegment,
                    }}
                    isStopped={isCameraAnimationStopped}
                  />
                </div>
              )}
              <span className="text-xxs text-purple font-medium ">Camera</span>
            </div>
          </button>
        )}
        {isScreenShareButtonShown && !isMobile && !isSolo && (
          <>
            <button
              className="active:translate-y-1 duration-100 cursor-pointer h-14 w-14 rounded-lg hover:bg-opacity-10 hover:bg-purple disabled:bg-white disabled:pointer-events-none disabled:opacity-70"
              disabled={screenSharingDisabled}
              onClick={() => !screenSharingDisabled && toggleScreenShare()}
            >
              <div className="flex flex-col justify-center items-center ">
                {/* button to add */}
                <button
                  className="h-8 w-8"
                  onMouseEnter={() => setIsShareAnimationStopped(false)}
                  onMouseLeave={() => setIsShareAnimationStopped(true)}
                >
                  <Lottie
                    options={{
                      ...animationOptions,
                      animationData: shareAnimation,
                    }}
                    isStopped={isShareAnimationStopped}
                  />
                </button>

                <span
                  className={classNames(
                    'text-xxs  font-medium',
                    screenSharingDisabled ? 'text-blue-gray' : 'text-purple'
                  )}
                >
                  Share
                </span>
              </div>
            </button>
          </>
        )}
      </div>
    </>
  );
};

const MediaButtonsContainer = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  left: 20px;
`;

/* MediaButtons
  Left-hand part of Meeting Control toolbar containing media related utilities,
  such as meeting recording and live streaming.
*/
const MediaButtons = ({
  isController = false,
  areMuxActionsDisabled = false,
  isStreaming = false,
  isStreamingPopupOpen,
  setIsStreamingPopupOpen,
  isRecording = false,
  recordingStartTime,
  onRecordingStart,
  onRecordingStop,
  plan = plans.FREE,
  selectedMeetingControlButton,
  setSelectedMeetingControlButton,
  meetingType,
  setActionButtonClicked,
  // we only want to check whether there was a problem with existing subscription
  isSubscriptionSetupAndInactive,
  attendeesCount,
  timelineOn,
  includeEventQuestions,
  meetingId,
  userId,
  setIsRecording,
  setIsStreaming,
  isPreview,
  onRecordChanges,
  onGoLive,
  onGoOffline,
  isLive,
  isLiveStreaming,
  wentLive,
  primaryColor,
  primaryContrast,
  wentLiveTimeStamp,
  liveStreamName,
  workspacePlan,
  liveStreamModeOn,
  workspaceStreams,
  episodeType,
  userDevicesPrecheck,
}) => {
  const isForceLiveStreamEnabled = plan !== plans.EVENT; // we force enable this button just to show the plan enforcement modal. The "real" action will be blocked
  const isQuickStartMeeting = meetingType === meetingTypes.QUICK_START;

  const isSettingUp = (isStreaming || isRecording) && !recordingStartTime;
  const isSolo = episodeType === episodeTypes.solo;

  return (
    <>
      <MediaButtonsContainer>
        <div className="space-x-3 flex items-start">
          {!isSolo && <MediaChatButton />}
          {includeEventQuestions && !isSolo && <MediaQuestionsButton />}
          {isController && <MediaScenePickerButton timelineOn={timelineOn} />}
          {isController && !isSolo && (
            <div className="relative">
              <MeetingControlButton
                ButtonIcon={TwoPersonFilledIcon}
                buttonTitle="Attendees"
                meetingAction="people"
                colorTheme="#8B33F7"
                selectedMeetingControlButton={selectedMeetingControlButton}
                setSelectedMeetingControlButton={
                  setSelectedMeetingControlButton
                }
              />
              <div
                className={`absolute bg-red text-white top-0 -translate-y-1/4 right-0 translate-x-1/4 text-xxs font-bold leading-[15px] text-center rounded-[12px] flex items-center justify-center ${
                  attendeesCount <= 10 ? 'w-[15px]' : 'px-1'
                }`}
              >
                {attendeesCount}
              </div>
            </div>
          )}
          {isController && !onRecordChanges && (
            <MediaRecordButton
              isDisabled={isSubscriptionSetupAndInactive || isStreaming}
              onRecordingStart={onRecordingStart}
              onRecordingStop={onRecordingStop}
              isStreaming={isStreaming}
              isRecording={isRecording}
              recordingStartTime={recordingStartTime}
              areMuxActionsDisabled={areMuxActionsDisabled}
              isQuickStartEnabled={isQuickStartMeeting}
              setActionButtonClicked={setActionButtonClicked}
              meetingId={meetingId}
              userId={userId}
              setIsRecording={setIsRecording}
              setIsStreaming={setIsStreaming}
              isPreview={isPreview}
            />
          )}
          {isController && !onRecordChanges && (
            <MediaLiveStreamingButton
              isDisabled={
                isForceLiveStreamEnabled || isQuickStartMeeting
                  ? false
                  : isSubscriptionSetupAndInactive || isRecording
              }
              isStreamingPopupOpen={isStreamingPopupOpen}
              setIsStreamingPopupOpen={setIsStreamingPopupOpen}
              isQuickStartEnabled={isQuickStartMeeting}
            />
          )}
        </div>
      </MediaButtonsContainer>
      {isController && onRecordChanges && (
        <OnGoLiveBar
          isLive={isLive}
          wentLive={wentLive}
          isPreview={isPreview}
          onGoLive={onGoLive}
          onGoOffline={onGoOffline}
          recordingStartTime={recordingStartTime}
          isRecording={isRecording}
          isStreaming={isStreaming}
          primaryColor={primaryColor}
          primaryContrast={primaryContrast}
          wentLiveTimeStamp={wentLiveTimeStamp}
          isLiveStreaming={isLiveStreaming}
          liveStreamName={liveStreamName}
          workspacePlan={workspacePlan}
          liveStreamModeOn={liveStreamModeOn}
          isSettingUp={isSettingUp}
          workspaceStreams={workspaceStreams}
          episodeType={episodeType}
          userDevicesPrecheck={userDevicesPrecheck}
        />
      )}
    </>
  );
};

/* dedicated hook for MediaButtons containing logic and state management */
const useMediaButtons = ({
  userId,
  meetingId,
  recordingState,
  plan,
  onRecordingForbidden,
  onStreamingForbidden,
  startLocalRecording,
  stopLocalRecording,
  userDevicesPrecheck,
  episodeType,
}) => {
  const dispatch = useDispatch();
  const [isStreaming, setIsStreaming] = useState(false);
  const [isStreamingPopupOpen, setIsStreamingPopupOpen] = useState(false);
  const isRecording = useSelector((state) => state.uiState.isRecording);

  const {
    webInputId,
    recordingStartTime: recordingStartTimeStr,
    watchUrl,
  } = recordingState;

  const isLiveStreaming = Boolean(recordingState.simulcastTargetId);

  const isWebInputActive = !!webInputId;

  const recordingStartTime = recordingStartTimeStr
    ? new Date(recordingStartTimeStr)
    : null;

  useEffect(() => {
    if (isWebInputActive && !watchUrl) {
      dispatch({
        type: 'UI_RECORDING_STARTED',
      });
    }

    setIsStreaming(isWebInputActive && watchUrl);
  }, [dispatch, isWebInputActive, watchUrl]);

  const onRecordingStart = useCallback(() => {
    if (plan === PLANS.free.id) {
      return onRecordingForbidden();
    }

    dispatch({
      type: 'UI_RECORDING_STARTED',
    });

    startRecording({ meetingId });
  }, [dispatch, meetingId, plan, onRecordingForbidden]);

  const onRecordingStop = useCallback(() => {
    dispatch({
      type: 'UI_RECORDING_STOPPED',
    });
    stopRecording(meetingId);
  }, [dispatch, meetingId]);

  const onStreamingStart = useCallback(
    (streamId) => {
      setIsStreaming(true);

      startRecording({ meetingId, streamId });
    },
    [meetingId]
  );

  const onStreamingStop = useCallback(() => {
    setIsStreaming(false);
    setIsStreamingPopupOpen(false);
    stopRecording(meetingId);
  }, [meetingId]);

  const areMuxActionsDisabled =
    (isRecording || isStreaming) && !isWebInputActive;

  const handleSetStreamingPopupOpen = useCallback(
    (shouldOpen) => {
      if (shouldOpen) {
        if (plan !== PLANS.event.id) {
          onStreamingForbidden();
          setIsStreamingPopupOpen(false);
          return;
        }
      }
      setIsStreamingPopupOpen(shouldOpen);
    },
    [plan, onStreamingForbidden]
  );

  const onGoLive = useCallback(async () => {
    dispatch({
      type: 'SELECT_LIVE_TAB',
      tab: 'timeline',
    });

    return await sendEvent(userId, meetingId, {
      type: 'GO_LIVE',
      userId,
    });
  }, [dispatch, meetingId, userId]);

  const onGoOffline = useCallback(async () => {
    await sendEvent(userId, meetingId, {
      type: 'GO_OFFLINE',
      userId,
    });
  }, [meetingId, userId]);

  const isLive = useSelector((state) => state.meetingState.state?.live);
  const wentLive = useSelector((state) => state.meetingState.state?.wentLive);

  window.startLocalRecording = startLocalRecording;

  useEffect(() => {
    if (episodeType !== episodeTypes.solo && startLocalRecording && isLive) {
      startLocalRecording();
    }
  }, [episodeType, startLocalRecording, isLive]);

  useEffect(() => {
    if (
      stopLocalRecording &&
      wentLive &&
      !isLive &&
      episodeType !== episodeTypes.solo
    ) {
      stopLocalRecording();
    }
  }, [episodeType, stopLocalRecording, wentLive, isLive]);

  return {
    isStreaming,
    isLiveStreaming,
    isStreamingPopupOpen,
    setIsStreamingPopupOpen: handleSetStreamingPopupOpen,
    areMuxActionsDisabled,
    isRecording,
    recordingStartTime,
    onRecordingStart,
    onRecordingStop,
    onStreamingStart,
    onStreamingStop,
    watchUrl,
    plan: plan || plans.FREE,
    setIsStreaming,
    onGoLive,
    onGoOffline,
  };
};

const StreamIntegrationForm = ({ onSubmit, stream, workspaceId }) => {
  const [formKey, setFormKey] = useState(uuidv4());
  const [isDirty, setIsDirty] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const streamId = stream.streamId;

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { streamUrl, watchUrl } = Object.fromEntries(
      new FormData(event.target).entries()
    );

    const streamIntegration = {
      streamId,
      streamUrl,
      watchUrl: watchUrl || null,
    };

    try {
      setIsSubmitting(true);

      const { result: workspace } = await updateWorkspaceIntegrationApi(
        workspaceId,
        streamIntegration
      );

      onSubmit && (await onSubmit(workspace));
    } catch (error) {
      toast(
        'We could not save the integration settings. Please try again later.'
      );
      logerror(fromError(error));
    }

    setIsSubmitting(false);
    setIsDirty(false);
  };

  const handleChange = () => {
    setIsDirty(true);
  };

  const handleClear = () => {
    setIsDirty(false);
    setFormKey(uuidv4());
  };

  return (
    <form
      className="border border-gray my-4 px-4 pb-4 -mx-4 rounded-md"
      key={formKey}
      onSubmit={handleSubmit}
      onChange={handleChange}
    >
      <TextInput
        name="streamUrl"
        label="Stream URL"
        defaultValue={stream.streamUrl}
      />
      <TextInput label="Stream Key" disabled defaultValue={stream.streamKey} />
      <TextInput
        name="watchUrl"
        label="Watch URL (Optional)"
        explanation="The URL of the livestream that will be linked to viewers during the meeting"
        defaultValue={stream.watchUrl}
      />
      <div
        className={classNames(
          'flex gap-4 justify-end',
          isDirty ? 'visible' : 'opacity-0 pointer-events-none'
        )}
      >
        <Button
          color={Button.colors.WHITE_LITE}
          onClick={handleClear}
          state={isSubmitting ? Button.states.DISABLED : Button.states.DEFAULT}
        >
          <span className="text-sm">Clear</span>
        </Button>
        <Button
          color={Button.colors.PURPLE_LITE}
          type="submit"
          state={isSubmitting ? Button.states.LOADING : Button.states.DEFAULT}
        >
          <span className="text-sm">Update</span>
        </Button>
      </div>
    </form>
  );
};

const GoLivePopup = ({
  areMuxActionsDisabled,
  onStreamingStart,
  streamIntegrations,
  setIsStreamingPopupOpen,
  setActionButtonClicked,
  updateWorkspace,
  workspaceId,
  isPreview,
  canLiveStream,
}) => {
  const popupRef = useRef(null);

  const [selectedStreamId, setSelectedStreamId] = useState(null);

  const streamOptions = useMemo(() => {
    return [
      { value: '', title: 'Select stream destination' },
      ...streamIntegrations.map((s) => {
        return { value: s.streamId, title: s.name };
      }),
    ];
  }, [streamIntegrations]);

  useClickOutside(popupRef, () => {
    setIsStreamingPopupOpen(false);
  });

  const selectedStream = streamIntegrations.find(
    (integration) => integration.streamId === selectedStreamId
  );

  return (
    <PopupWrapper ref={popupRef}>
      {isPreview && (
        <div className="text-blue-dark text-xl font-semibold">
          Streaming not enabled for rehearsals.
        </div>
      )}
      {!isPreview && !canLiveStream && (
        <div className="text-blue-dark text-xl font-semibold">
          Please set up your stream integration in your workspace settings.
        </div>
      )}
      {canLiveStream && !isPreview && (
        <>
          <RadioWaveIcon color="#8B33F7" width={25} height={25} />
          <PopupBody>
            <p>Go live</p>
            <p className="text-sm">All livestreams are recorded by default.</p>
            <SelectInput
              options={streamOptions}
              onChange={(value) => setSelectedStreamId(value)}
            />
            <SimpleButton
              disabled={areMuxActionsDisabled || !selectedStreamId}
              onClick={() => {
                setActionButtonClicked('Streaming');
                onStreamingStart(selectedStreamId);
              }}
              size="medium"
              label="Go Live!"
              height="36px"
            />
            {selectedStream && (
              <StreamIntegrationForm
                onSubmit={updateWorkspace}
                stream={selectedStream}
                workspaceId={workspaceId}
              />
            )}
          </PopupBody>
        </>
      )}
    </PopupWrapper>
  );
};

const YouAreNowLivePopup = ({
  areMuxActionsDisabled,
  onStreamingStop,
  recordingStartTime,
  watchUrl,
  setIsStreamingPopupOpen,
  setActionButtonClicked,
}) => {
  const popupRef = useRef(null);

  useClickOutside(popupRef, () => {
    setIsStreamingPopupOpen(false);
  });

  return (
    <PopupWrapper ref={popupRef}>
      <RadioWaveIcon color="#DB2929" width={25} height={25} />
      <PopupBody>
        {recordingStartTime ? <p>You are now live</p> : <p>Going live</p>}
        {watchUrl && <p className="text-purple">{watchUrl}</p>}
        <p className="text-sm">All livestreams are recorded by default.</p>
        <RedButton
          disabled={areMuxActionsDisabled}
          onClick={() => {
            setActionButtonClicked('Streaming');
            onStreamingStop();
          }}
          size="medium"
          label="End stream"
          height="36px"
          width="180px"
        />
      </PopupBody>
    </PopupWrapper>
  );
};

const PopupWrapper = styled.div`
  position: absolute;
  top: -${({ theme }) => theme.spacing(2)}px;
  left: calc(57px + ${({ theme }) => theme.spacing(2)}px);
  transform: translateY(-100%);
  background-color: #fff;
  box-shadow: 0 0 10px rgba(102, 102, 102, 0.25);
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  padding: ${({ theme }) => theme.spacing(2)}px;
  max-width: 450px;
  padding-right: 50px;

  img {
    width: 25px;
  }
`;

const PopupBody = styled.div`
  padding-left: ${({ theme }) => theme.spacing(2)}px;
  color: #707070;

  p {
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  }

  button {
    padding: 5px 35px;
    height: 30px;
  }
`;

const DisabledDeviceButton = ({ icon, label, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="relative w-[57px] flex justify-center items-center"
    >
      <span className="sr-only">Click here to open troubleshooting dialog</span>
      <div className="relative">
        {icon}
        <span className="text-xxs -mt-0.5 block">{label}</span>
        <div className="absolute bg-red p-1 rounded-full bottom-0 right-0 -translate-y-4">
          <CrossIcon />
        </div>
      </div>
    </button>
  );
};

const DisabledCameraButton = ({ onClick }) => {
  return (
    <DisabledDeviceButton
      icon={<DisabledCameraIcon />}
      label="Camera"
      onClick={onClick}
    />
  );
};

const DisabledMicButton = ({ onClick }) => {
  return (
    <DisabledDeviceButton
      icon={<DisabledMicIcon />}
      label={'Mic'}
      onClick={onClick}
    />
  );
};
