import React from 'react';

export const SpeechIcon = ({
  width = '20px',
  height = '20px',
  className = 'fill-purple',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9993 1.66797C16.8403 1.66797 18.3327 3.16035 18.3327 5.0013V11.668C18.3327 13.5089 16.8403 15.0013 14.9993 15.0013H6.66602C5.99815 14.9886 5.35296 15.2436 4.87435 15.7096L2.37435 18.2096C2.29795 18.2889 2.19278 18.334 2.08268 18.3346C1.85256 18.3346 1.66602 18.1481 1.66602 17.918V5.0013C1.66602 3.16035 3.1584 1.66797 4.99935 1.66797H14.9993ZM6.66602 13.3346H14.9993C15.9198 13.3346 16.666 12.5884 16.666 11.668V5.0013C16.666 4.08083 15.9198 3.33464 14.9993 3.33464H4.99935C4.07887 3.33464 3.33268 4.08083 3.33268 5.0013V14.9013L3.71602 14.5513C4.49894 13.7704 5.56019 13.3327 6.66602 13.3346ZM5.41602 10.8346H11.2493C11.4795 10.8346 11.666 10.6481 11.666 10.418V9.58464C11.666 9.35452 11.4795 9.16797 11.2493 9.16797H5.41602C5.1859 9.16797 4.99935 9.35452 4.99935 9.58464V10.418C4.99935 10.6481 5.1859 10.8346 5.41602 10.8346ZM14.5827 7.5013H5.41602C5.1859 7.5013 4.99935 7.31475 4.99935 7.08464V6.2513C4.99935 6.02118 5.1859 5.83464 5.41602 5.83464H14.5827C14.8128 5.83464 14.9993 6.02118 14.9993 6.2513V7.08464C14.9993 7.31475 14.8128 7.5013 14.5827 7.5013Z"
    />
  </svg>
);
