import React from 'react';
import { TextInput } from '.';

/**
 * Input for entering numeric values with optional min, max and step properties.
 *
 * @see TextInput
 */
export const NumberInput = (props) => (
  <TextInput
    {...props}
    type="number"
    onChange={(event, value) =>
      props.onChange && props.onChange(event, Number(value))
    }
    onBlur={(event, value) =>
      props.onBlur && props.onBlur(event, Number(value))
    }
  />
);
