import styled, { css, keyframes } from 'styled-components/macro';
import {
  BLOCK_CORNER_HANDLE_SIZE_PX,
  BLOCK_EDGE_HANDLE_SIZE_PX,
  BOTTOM_BAR_HEIGHT_PX,
  RIGHT_MENU_WIDTH_PX,
  LEFT_TOOLBAR_WIDTH_PX,
  COLORS,
  TOP_BAR_HEIGHT_PX,
  BACKDROP_MENU_WIDTH_PX,
  BLOCKS_MENU_WIDTH_PX,
} from './constants';
import { LeftToolbarContainer } from './Menu/LeftToolbar';
import { MenuContainer } from './Menu/Menu.style';
import { SlideTrayContainer } from './Menu/SlideTray';
import {
  resizableSideImageData,
  resizableCornerImageData,
} from './resizableImages';
import { containerPadding } from './shared.styled';
import { absolutePosition, scrollbarCss } from '../../helper/css';

export const AuthoringGridContainer = styled.div`
  width: 100vw;
  overflow: hidden;

  display: grid;
  grid-template-areas:
    'top   top   top    top'
    'left  menu  slide  settings'
    'left  menu  tray   tray';

  grid-template-rows:
    0 calc(100vh - ${TOP_BAR_HEIGHT_PX + BOTTOM_BAR_HEIGHT_PX}px)
    ${BOTTOM_BAR_HEIGHT_PX}px;
  grid-template-columns: ${LEFT_TOOLBAR_WIDTH_PX}px ${(props) =>
      props.leftMenu
        ? props.leftMenu === 'backdrop'
          ? `${BACKDROP_MENU_WIDTH_PX}px`
          : `${BLOCKS_MENU_WIDTH_PX}px`
        : '0px'} 1fr ${(props) =>
      props.rightMenu ? `${RIGHT_MENU_WIDTH_PX}px` : '0px'};

  // b: LeftToolbar
  ${() => LeftToolbarContainer} {
    grid-area: left;
  }

  // c: LeftMenu
  ${() => MenuContainer} {
    grid-area: menu;
  }

  // d: Scene Area
  ${() => SceneContentContainer} {
    grid-area: slide;

    ${() => MainContentPresentationWrapper} {
      height: calc(100vh - ${TOP_BAR_HEIGHT_PX + BOTTOM_BAR_HEIGHT_PX + 40}px);
    }
  }

  // e: Block Settings
  ${() => RightPanelContainer} {
    grid-area: settings;
  }

  // f: Slide Tray
  ${() => SlideTrayContainer} {
    grid-area: tray;
  }
`;

const FadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const RightPanelDiv = styled.div`
  animation: ${FadeInAnimation} 300ms ease-in;
  ${containerPadding};
  background-color: #fff;
  padding: 10px;
  box-shadow: -1px 1px 0 rgba(0, 0, 0, 0.05);
  z-index: ${({ theme }) => theme.zIndex.sideMenuOuter};
`;

export const RightPanelContainer = styled.div`
  position: relative;
  overflow-y: auto;
  ${scrollbarCss(true, false)}
`;

export const MainContentSlideImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  border-radius: 8px;
  object-position: center;
  object-fit: cover;
  overflow: hidden;
  border-radius: 8px;
`;

export const SceneContentContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;
  background: #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MainContentPresentationWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  > :not(${MainContentSlideImage}) {
    // img causes lost focus and inability to paste when main scene is clicked
    z-index: 1;
  }
`;

const REMOVE_BUTTON_SIZE = 30;
export const SmallRemoveButton = styled.button`
  border: none;
  background: #eb5757;
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: block;
  width: ${REMOVE_BUTTON_SIZE}px;
  height: ${REMOVE_BUTTON_SIZE}px;

  transition: color 300ms ease-out;
  opacity: 0;
  visibility: hidden;

  &:hover {
    color: orangered;
  }
`;

export const SlideBlockPositionDiv = styled.div`
  ${(props) => absolutePosition(props)}

  .react-resizable-handle {
    display: ${(props) => (props.active ? 'block' : 'none')};
  }

  .react-resizable-handle-nw,
  .react-resizable-handle-ne,
  .react-resizable-handle-sw,
  .react-resizable-handle-se {
    width: ${BLOCK_CORNER_HANDLE_SIZE_PX}px;
    height: ${BLOCK_CORNER_HANDLE_SIZE_PX}px;
    transform: rotate(0);
    background-size: contain;
    background-image: url(${resizableCornerImageData});
  }

  .react-resizable-handle {
    ${({ resizable }) => !resizable && 'cursor: initial'};
  }

  .react-resizable-handle-nw {
    top: -${(BLOCK_CORNER_HANDLE_SIZE_PX * 8) / 20 + 0.52}px;
    left: -${(BLOCK_CORNER_HANDLE_SIZE_PX * 8) / 20 + 0.54}px;
  }

  .react-resizable-handle-ne {
    top: -${(BLOCK_CORNER_HANDLE_SIZE_PX * 8) / 20 + 0.52}px;
    right: -${(BLOCK_CORNER_HANDLE_SIZE_PX * 15) / 20 - 0.14}px;
  }

  .react-resizable-handle-sw {
    left: -${(BLOCK_CORNER_HANDLE_SIZE_PX * 8) / 20 + 0.54}px;
    bottom: -${(BLOCK_CORNER_HANDLE_SIZE_PX * 15) / 20 - 0.46}px;
  }

  .react-resizable-handle-se {
    right: -${(BLOCK_CORNER_HANDLE_SIZE_PX * 15) / 20 - 0.14}px;
    bottom: -${(BLOCK_CORNER_HANDLE_SIZE_PX * 15) / 20 - 0.46}px;
  }
  .react-resizable-handle-e,
  .react-resizable-handle-w {
    margin-top: 0;
    background-repeat: repeat-y !important;
    height: 100% !important;
    top: 0;
  }

  .react-resizable-handle-n,
  .react-resizable-handle-s {
    margin-left: 0;
    background-repeat: repeat-x !important;
    width: 100% !important;
    left: 0;
  }

  .react-resizable-handle-e {
    right: -${BLOCK_EDGE_HANDLE_SIZE_PX}px;
  }
  .react-resizable-handle-s {
    bottom: -${BLOCK_EDGE_HANDLE_SIZE_PX}px !important;
  }
  .react-resizable-handle-w {
    left: -${BLOCK_EDGE_HANDLE_SIZE_PX / 2}px;
  }
  .react-resizable-handle-n {
    top: -${BLOCK_EDGE_HANDLE_SIZE_PX / 2}px;
  }

  .react-resizable-handle-n,
  .react-resizable-handle-e,
  .react-resizable-handle-s,
  .react-resizable-handle-w {
    transform: rotate(0);
    width: ${BLOCK_EDGE_HANDLE_SIZE_PX + 0.5}px;
    height: ${BLOCK_EDGE_HANDLE_SIZE_PX + 0.5}px;
    background-size: contain;
    // prettier-ignore
    background-image: url("${resizableSideImageData}");
  }
`;

export const SlideBlockLayoutClickable = styled.div`
  border: none;
  cursor: ${({ isDraggable, editable, active }) =>
    editable ? (active ? 'text' : 'move') : 'move'};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ active }) => (active ? COLORS.active : COLORS.inactive)};

  ${({ active }) =>
    !active
      ? css`
          &:hover {
            border: 1px solid #00a3a5;
            background-color: rgba(0, 163, 165, 0.2);
          }
        `
      : null}

  ${({ blockId }) => blockId === 'faq' && 'margin-bottom: auto'};

  width: 100%;
  height: 100%;

  > * > * {
    pointer-events: none;
  }
`;

export const AuthoringIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: white;
  padding: 7%;
`;

export const AuthoringIconImage = styled.img`
  max-width: 75%;
  max-height: 75%;
`;

const FloatingButton = styled.button`
  position: absolute;
  top: 2px;
  display: none;
  z-index: 100;
  border: none;
  color: #fff;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  cursor: pointer;
`;

export const SlideRemoveButton = styled(FloatingButton)`
  right: 2px;
  background-color: ${({ theme }) => theme.palette.error.main};

  :hover {
    background-color: ${({ theme }) => theme.palette.error.dark};
  }
`;

export const SlideContentWrapper = styled.div`
  position: relative;

  :hover ${SlideRemoveButton} {
    display: block;
  }
`;

export const AuthoringBlocksContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
  border-radius: 8px;
  overflow: hidden;
  pointer-events: none;

  ${({ editable }) =>
    editable
      ? css`
          z-index: 500;
          > * > * {
            pointer-events: auto;
          }
        `
      : css`
          > * > * {
            pointer-events: none;
          }
        `}
`;

export const EditingControlsContainer = styled.div`
  .react-draggable {
    z-index: 50;
  }

  .react-draggable-inactive {
    z-index: 40;
  }
`;
