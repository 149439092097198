import { useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import { useCallback } from 'react';

export const useTrackActivity = (namespace) => {
  const distinct_id = useSelector((st) => st.auth?.user?.userId);

  const track = useCallback(
    (message, data = {}) =>
      mixpanel.track(namespace ? `${namespace} ${message}` : message, {
        distinct_id,
        ...data,
      }),
    [distinct_id, namespace]
  );

  return track;
};
