import { fetchEventTemplateApi } from '../../helper/api';
import { useEffect, useState } from 'react';

export const useExampleMeetingTemplate = () => {
  const [template, setTemplate] = useState(null);

  useEffect(() => {
    fetchEventTemplateApi('trial_episode_template').then((template) => {
      template.settings = {
        eventPresenters: [],
      };

      template.scheduledEvent = {
        startDate: Date.now().toString(),
        endDate: Date.now().toString(),
      };

      template.title = template.templateName;

      setTemplate(template);
    });
  }, []);

  return {
    template,
    setTemplate,
  };
};
