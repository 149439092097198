import styled from 'styled-components/macro';
import { fieldLabel } from '../../theme/baseStyles';
import { H4Text, SecondaryText } from '../common/Text';

export const RightPanelAttributesDiv = styled.div``;

export const RightPanelHeader = styled(H4Text)`
  margin-bottom: 0;
  text-align: left;
  max-width: calc(100% - 36px);

  position: relative;

  display: flex;
  align-items: center;
  gap: 10px;

  > img:first-child {
    height: 33px;
    width: 40px;
    object-fit: contain;
    object-position: center;
  }

  > img:last-child {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
`;

export const RightPanelHeaderBlockIcon = styled.img`
  width: 43px;
  height: 30px;
  object-fit: contain;
  object-position: center;
`;

export const RightPanelAttributeHeader = styled(H4Text).attrs({
  secondary: true,
})`
  text-align: left;
  padding-left: 0;
`;

export const RightPanelAttributeHeaderNote = styled(SecondaryText)``;

export const RightPanelBlockAttributesContainerDiv = styled.div`
  > div,
  > label {
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  }
`;

export const RightPanelBlockAttribute = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin-top: 0.5em;
  flex-basis: ${({ fullWidth }) => (fullWidth ? '100%' : '50%')};
`;

export const AttributeTitleH4 = styled.span`
  ${fieldLabel};
  text-transform: uppercase;
  line-height: 20px;
`;
