import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { fromError, logerror } from '../../helper/contextualLogger';
import { uploadUserImage } from '../../helper/api';
import { ImageUploader } from '../../components/ImageUploader';
import { Button } from '../../components/common/Button';
import { PersonInCircleIcon } from '../../components/icons/PersonInCircleIcon';
import classNames from '../../helper/classNames';
import { notifyUser } from '../../components/authoring/hooks';

export const WelcomeSpeakerAvatarUploader = ({
  speakerId,
  avatarUrl,
  handleAvatarChange,
}) => {
  const [isSubmittingFile, setIsSubmittingFile] = useState(false);

  const handleSubmitUserImage = async (buffer, type, name) => {
    setIsSubmittingFile(true);

    try {
      const uploadResult = await uploadUserImage(
        speakerId + '/' + name,
        buffer,
        type
      );

      if (uploadResult) {
        const newUrl =
          'https://storage.googleapis.com/zync-media/' +
          uploadResult?.result?.metadata?.name;

        await handleAvatarChange(newUrl);
      }
    } catch (error) {
      logerror(fromError(error));
    }
    setIsSubmittingFile(false);
  };

  const handleRemoveAvatar = async () => {
    handleAvatarChange(null);
  };

  return (
    <>
      {avatarUrl && (
        <div
          className={classNames(
            'relative shrink-0',
            isSubmittingFile && 'opacity-75'
          )}
        >
          <img
            className="md:w-[108px] md:h-[108px] w-[80px] h-[80px] self-start object-contain"
            src={avatarUrl}
            alt="Your poster"
          />
          <button
            type="button"
            onClick={handleRemoveAvatar}
            className="absolute top-0 right-0 border border-red rounded-full w-5 h-5 flex items-center justify-center scale-80"
          >
            <span className="sr-only">Click to remove the uploaded logo</span>
            <div className="shrink-0 w-5 h-5  flex items-center justify-center bg-[#fa2a66] rounded-full">
              <FontAwesomeIcon icon="trash" size="xs" color="white" />
            </div>
          </button>
          {avatarUrl ? (
            <ImageUploader
              disabled={isSubmittingFile}
              label={
                <div className="flex gap-2 items-center justify-center">
                  Change
                </div>
              }
              buttonBorder={Button.border.STANDARD}
              buttonColor={Button.colors.PURPLE_LITE}
              buttonPadding={Button.padding.SMALL}
              buttonSize={Button.sizes.FULL}
              onImageSubmit={handleSubmitUserImage}
              appendTimeStampToFile={false}
              onFileSizeExceeded={() =>
                notifyUser('Please use a file smaller than 5 MB')
              }
            />
          ) : null}
        </div>
      )}
      {!!avatarUrl || (
        <ImageUploader
          disabled={isSubmittingFile}
          loading={isSubmittingFile}
          buttonPadding={Button.padding.NONE}
          label={
            <div>
              {avatarUrl ? (
                <div className="grid justify-items-center place-content-center justify-center md:w-[108px] md:h-[108px] w-[80px] h-[80px] bg-white">
                  <img src={avatarUrl} alt="" width={108} height={108} />
                </div>
              ) : (
                <div className="bg-[#E9E9EE] grid justify-items-center place-content-center justify-center md:w-[108px] md:h-[108px] w-[80px] h-[80px]">
                  <div className="md:block hidden">
                    <PersonInCircleIcon
                      width={70}
                      height={70}
                      color="rgba(0,0,0,0.1)"
                    />
                  </div>
                  <div className="md:hidden block">
                    <PersonInCircleIcon
                      width={42}
                      height={42}
                      color="rgba(0,0,0,0.1)"
                    />
                  </div>
                  <span className="block text-xxs font-medium text-blue-gray">
                    Upload Image
                  </span>
                </div>
              )}
            </div>
          }
          buttonColor={Button.colors.PURPLE}
          onImageSubmit={handleSubmitUserImage}
          appendTimeStampToFile={false}
          onFileSizeExceeded={() =>
            notifyUser('Please use a file smaller than 5 MB')
          }
        />
      )}
    </>
  );
};
