import { DropdownSelect } from './common/Dropdown';
import { BackdropUpload } from './authoring/Menu/BackdropMenu';
import React, { useCallback, useEffect, useState } from 'react';
import { getStaticAssetUrl } from '../helper/getStaticAssetUrl';
import { getUploadedSeriesImages } from '../helper/api';
import { notifyUser } from './authoring/hooks';

const sceneBackdropOptions = [
  { url: undefined, title: 'Select a backdrop' },
  {
    url: getStaticAssetUrl('16_9-3.png'),
    title: 'Lines',
  },
  {
    url: getStaticAssetUrl('16_9-4.png'),
    title: 'Arrows',
  },
  {
    url: getStaticAssetUrl('16_9-5.png'),
    title: 'Balls',
  },
  {
    url: getStaticAssetUrl('16_9-6.png'),
    title: 'Spiral',
  },
  {
    url: getStaticAssetUrl('16_9-7.png'),
    title: 'Angular',
  },
  {
    url: getStaticAssetUrl('16_9-8.png'),
    title: 'Diamond',
  },
  {
    url: getStaticAssetUrl('16_9-9.png'),
    title: 'Wheel',
  },
  {
    url: getStaticAssetUrl('16_9-10.png'),
    title: 'Network',
  },
  {
    url: getStaticAssetUrl('16_9-11.png'),
    title: 'Isometric',
  },
  {
    url: getStaticAssetUrl('16_9-12.png'),
    title: 'Net',
  },
  {
    url: getStaticAssetUrl('16_9-13.png'),
    title: 'Chaos',
  },
  {
    url: getStaticAssetUrl('honey_comb_backdrop.png'),
    title: 'Honey Comb',
  },
  {
    url: getStaticAssetUrl('animated_triangles.gif'),
    title: 'Animated - Triangles',
  },
];

const SUFFIX = '-scene-backdrop';

export const ShowBackdrops = ({ workspaceId, selectedKey, onSelect }) => {
  const [customBackdrops, setCustomBackdrops] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchUploadedImages = useCallback(async () => {
    const images = await getUploadedSeriesImages(workspaceId + SUFFIX);

    const backdrops = images
      .slice()
      .sort(
        (entryA, entryB) =>
          new Date(entryA.timeCreated) - new Date(entryB.timeCreated)
      )
      .map((image) => ({
        url: image.publicUrl,
        title: 'Custom Background',
      }));

    setCustomBackdrops(backdrops);

    return backdrops;
  }, [workspaceId]);

  useEffect(() => {
    fetchUploadedImages();
  }, [fetchUploadedImages]);

  const customBackdropSelected = customBackdrops.find(
    (backdrop) => backdrop.url === selectedKey
  );

  return (
    <div className="flex-[1_0_0%]">
      <DropdownSelect
        selectedKey={selectedKey}
        options={sceneBackdropOptions
          .concat(customBackdropSelected || [])
          .map(({ url, title }) => ({
            key: url,
            title: (
              <div className="flex gap-2 h-[48px] w-[85px] items-center">
                <img
                  src={url}
                  className="shrink-0 object-cover h-full w-full"
                  alt=""
                />
                {title}
              </div>
            ),
          }))}
        onSelect={onSelect}
        backgroundColor={DropdownSelect.backgroundColor.WHITE}
        borderColor={DropdownSelect.borderColor.GRAY}
        borderSize={DropdownSelect.borderSize.ONE}
      />
      <div className="flex gap-2 items-center my-2">
        <BackdropUpload
          loading={isLoading}
          appendTimeStampToFile
          imageNamescape={workspaceId + SUFFIX}
          enforcedAspectRatio={16 / 9} // standard landscape video aspect ratio
          onUploadStart={() => setIsLoading(true)}
          onAspectRatioExceeded={() => {
            notifyUser(
              'You must provide an image of the exact aspect ratio of 16:9'
            );
          }}
          onFileSizeExceeded={() =>
            notifyUser('Please use file smaller than 5 MB')
          }
          onUploadComplete={async () => {
            const uploadedImages = await fetchUploadedImages();

            notifyUser(
              'Your backdrop was successfully uploaded and automatically selected. You may now save the Show settings or you can select the Custom Background from the list later'
            );

            onSelect(uploadedImages.at(-1)?.url);

            setIsLoading(false);
          }}
        />
      </div>
    </div>
  );
};
