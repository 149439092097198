import React, { useMemo } from 'react';
import { ThumbnailGrid } from '../../common/Container';
import { useAuthoringTool } from '../hooks';

export const BlocksMenu = ({ blockCategory }) => {
  const { blocks, addSlideBlock } = useAuthoringTool();

  const blocksToShow = useMemo(
    () =>
      blocks.filter((block) =>
        block.details.categories.includes(blockCategory)
      ),
    [blockCategory, blocks]
  );

  return (
    <ThumbnailGrid
      fill={false}
      items={blocksToShow.map((block) => ({
        id: block.blockId,
        title: block.details.title,
        image: block.details.iconUrl,
        onClick: () => addSlideBlock(block),
      }))}
    />
  );
};
