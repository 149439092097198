import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import classNames from '../../helper/classNames';
import { MeetingBox } from './MeetingBox';

const getFormattedDate = (isoString) => {
  const date = new Date(isoString);
  return [
    date.getDate(),
    date.toLocaleString('default', { month: 'short' }),
    date.getFullYear(),
  ];
};

const modifyFormattedDateForDisplay = (day, month, year) => {
  return day && month && year
    ? `${day} ${month}  '${year.toString().substring(2)}`
    : null;
};

export const HighlightsInformation = ({
  totalMeetings,
  lastMeetingDate,
  seriesCreationDate,
  meetingSeriesId,
  meetingList,
}) => {
  const history = useHistory();

  const [creationDateDay, creationDateMonth, creationDateYear] =
    getFormattedDate(seriesCreationDate);

  const [lastMeetingDateDay, lastMeetingDateMonth, lastMeetingDateYear] =
    getFormattedDate(lastMeetingDate);

  const [lastUpdatedDay, lastUpdatedMonth, lastUpdatedYear] =
    getFormattedDate(lastMeetingDate);

  const handleHighlightsClick = useCallback(
    () =>
      meetingList?.length && history.push(`/e/${meetingSeriesId}/highlights`),
    [history, meetingSeriesId, meetingList]
  );

  return (
    <>
      <MeetingBox
        title="Highlights"
        description="Analyze the key information documented from your past events"
        onClick={handleHighlightsClick}
        buttonText="See Highlights"
        showHoverButton={true}
      >
        <div className="md:flex md:h-28 hidden md:visible mx-3">
          <InnerBlock
            title="events in total"
            data={totalMeetings}
            dataText="To date"
          />
          <InnerBlock
            title="last event"
            data={modifyFormattedDateForDisplay(
              lastMeetingDateDay,
              lastMeetingDateMonth,
              lastMeetingDateYear
            )}
            dataText="To date"
          />
          <InnerBlock
            title="created on"
            data={modifyFormattedDateForDisplay(
              creationDateDay,
              creationDateMonth,
              creationDateYear
            )}
            dataText="To date"
          />
          <InnerBlock
            title="last edited"
            data={modifyFormattedDateForDisplay(
              lastUpdatedDay,
              lastUpdatedMonth,
              lastUpdatedYear
            )}
            dataText=""
            hasRightBorder={false}
          />
        </div>
      </MeetingBox>
    </>
  );
};

const InnerBlock = ({ title, data, dataText, hasRightBorder = true }) => (
  <div className="flex justify-between w-full ml-4">
    <div className=" w-full flex flex-col justify-around">
      <span className="uppercase font-medium">{title}</span>
      <div className="flex flex-col">
        <span className="font-extrabold text-3xl">{data || ''}</span>
        <span
          className={classNames(
            'text-xs font-light text-blue-gray',
            !dataText && 'mb-4'
          )}
        >
          {data && (dataText || '')}
        </span>
      </div>
    </div>
    <div
      className={classNames(
        'h-full w-[1px] bg-gray rounded-full',
        hasRightBorder ? 'visible' : 'hidden'
      )}
    />
  </div>
);
