import moment from 'moment-timezone';
import { getLocalTimezoneAreaName } from './rsvp';
import { uniq } from 'lodash';

const filteredTimezoneNames = uniq([
  'Africa/Abidjan',
  'Africa/Algiers',
  'Africa/Cairo',
  'Africa/Nairobi',
  'Asia/Dubai',
  'Asia/Karachi',
  'Asia/Kolkata',
  'Asia/Dhaka',
  'Asia/Bangkok',
  'Asia/Singapore',
  'Asia/Tokyo',
  'Pacific/Guam',
  'Pacific/Noumea',
  'Pacific/Auckland',
  'Atlantic/Cape_Verde',
  'America/Noronha',
  'America/Sao_Paulo',
  'America/Caracas',
  'America/New_York',
  'America/Chicago',
  'America/Denver',
  'America/Los_Angeles',
  'America/Anchorage',
  'Pacific/Honolulu',
  'Pacific/Midway',
  getLocalTimezoneAreaName(),
]);

const sortedTimezoneNames = (timezones, date) =>
  timezones.sort((a, b) => {
    const offsetA = moment(date).tz(a.key).utcOffset();
    const offsetB = moment(date).tz(b.key).utcOffset();

    return offsetA - offsetB;
  });

/* To be used for timezone selectors */
export const timezonesOptions = (date) =>
  sortedTimezoneNames(
    filteredTimezoneNames.map((timezone) => {
      const offset = moment(date).tz(timezone).format('Z');
      const abbreviation = moment(date).tz(timezone).format('z');

      const zoneName = `(GMT${offset}) ${timezone
        .replace(/_/g, ' ')
        .replace(/\//g, ' - ')} (${abbreviation})`;

      return {
        key: timezone,
        title: zoneName,
      };
    }),
    date
  );
