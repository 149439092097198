import React from 'react';
import { useFeedPage } from './useFeedPage';
import { FeedPageContextProvider } from './FeedPageContextProvider';
import { getStaticAssetUrl } from '../../helper/getStaticAssetUrl';
import { PageHeaderWhite } from './PageHeaderWhite';
import { NoDataScreen } from './NoDataScreen';
import { MainVideo } from './MainVideo';
import { PlayList } from './Playlist';
const PageContainer = React.memo(({ children }) => {
  return <div className="min-h-full pb:4 lg:pb-8 relative">{children}</div>;
});

const missingHighlightsGif = getStaticAssetUrl('missingHighlights.gif');
const missingHighlightsVideoToolbar = getStaticAssetUrl(
  'missingHighlightsVideoToolbar.svg'
);

const NotPublishedBanner = () => {
  const { selectedHighlight } = useFeedPage();

  if (!selectedHighlight?.hidden) return null;

  return (
    <div className="h-[49px] py-2.5 px-5 w-full bg-blue-dark flex relative z-50 mb-12">
      <div className="text-white font-inter text-sm font-medium flex items-center justify-center w-full gap-[5px]">
        <span className="text-base">⚠️</span> This highlight is not published
        and is not visible to others.
      </div>
    </div>
  );
};

export const LoadingVideo = () => {
  return (
    <div className="bg-[#f4f5f7] rounded-xl overflow-hidden flex flex-col aspect-video">
      <div className="flex-[10_0_0] flex items-center justify-center">
        <img
          src={missingHighlightsGif}
          className="w-1/2 h-[75%] object-contain"
          alt=""
        />
      </div>
      <div className="flex-[1_0_0%]">
        <img
          src={missingHighlightsVideoToolbar}
          alt=""
          className="w-full relative bottom-2"
        />
      </div>
    </div>
  );
};

const MainContainer = ({ children }) => {
  return (
    <div className="flex flex-col px-6 lg:px-16 lg:flex-row gap-11 min-h-[75vh] justify-center">
      {children}
    </div>
  );
};

export const FeedPage = () => {
  return (
    <FeedPageContextProvider>
      <PageContainer>
        <PageHeaderWhite />
        <NotPublishedBanner />
        <MainContainer>
          <NoDataScreen />
          <MainVideo />
          <PlayList />
        </MainContainer>
      </PageContainer>
    </FeedPageContextProvider>
  );
};
