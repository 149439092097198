import { getRecordingBotUserId } from '.';

export const Roles = {
  SPEAKER: 'speaker',
  MODERATOR: 'moderator',
  GUEST: 'guest',
  HOST: 'host',
};

// TODO: This function needs to be removed in preference to isUserMeetingController once we deprecate the role attribute on a user.
// Trello bug: https://trello.com/c/mPyT6qyZ/941-deprecate-role-in-preference-to-roles-once-the-series-page-work-is-completed
// TLDR: user isUserMeetingController instead.
export const isMeetingController = (user) => {
  const { roles } = user || {};
  if (roles && isRolesMeetingController(roles)) return true;
  return false;
};

export const isRolesMeetingController = (roles) => {
  if (!roles) return false;
  return roles.indexOf(Roles.MODERATOR) !== -1;
};

export const isUserMeetingController = (user) => {
  const { roles } = user || {};
  return isRolesMeetingController(roles);
};

export const isUserRecordingBot = (user) => {
  return user?.userId && user?.userId === getRecordingBotUserId();
};

export const isAttendeeMeetingController = (attendee) => {
  return isRolesMeetingController(attendee?.roles);
};

export const isUserAnX = (user, role) => {
  const { roles } = user || {};
  return !!(roles || []).find((r) => r === role);
};

export const isRolesAnX = (roles, role) => {
  return !!(roles || []).find((r) => r === role);
};

export const isRolesAGuest = (roles) => {
  return isRolesAnX(roles, Roles.GUEST);
};

/** Filters the array of users by the block settings (using userIds, userId, role, and roles fields). */
export const filterUsersFromSettings = (
  users,
  { userIds, userId, roles, role }
) => {
  return (users ?? []).filter((user) => {
    if (userIds && userIds.length > 0) {
      return userIds.includes(user.userId);
    } else if (userId) {
      return userId === user.userId;
    } else if ((roles && roles.length > 0) || role) {
      const matches = user.roles.find((userRole) =>
        role.split(',').includes(userRole)
      );
      if (matches) return true;
      const values =
        roles && roles.length > 0
          ? typeof roles === 'string'
            ? roles.split(',').map((r) => r.trim())
            : roles
          : [role];
      // old block settings might contain uppercase role names
      return values.some((role) => isUserAnX(user, role.toLowerCase()));
    } else {
      return true;
    }
  });
};

export const isEmail = (value) => /(.+)@(.+){2,}\.(.+){2,}/.test(value);
