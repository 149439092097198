import { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal, ModalTitle, ModalWindow } from './Modal';
import { Section } from './series/MeetingSettingsModal';
import { TextInput } from './Input';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Button } from './common/Button';
import axios from 'axios';
import { PROCESSOR_BASE_URL } from '../helper/constants';
import { makeRandomId } from '../helper';
import { updateEpisodeSettingsApi } from '../helper/api';
import { delay } from '../helper/exponentialFetchRetry';

const getDuration = (start, end) => {
  const duration = end - start;
  const minutes = Math.floor(duration / 60);
  const remainingSeconds = Math.floor(duration % 60);

  return `${String(minutes).padStart(2, '0')}:${String(
    remainingSeconds
  ).padStart(2, '0')}`;
};

export const ClipEditorModal = ({
  series,
  localDispatch,
  handleClose,
  refreshContentKit,
  localRecordingSentences,
  hasAllLocalRecordings,
  workspace,
  shorts,
}) => {
  const { meetingSeriesId } = series || {};
  const { workspaceId } = workspace || {};
  const [settings, setSettings] = useState(series?.settings);
  const { manualShorts = [] } = settings || {};
  const [startSentenceId, setStartSentenceId] = useState('');
  const [endSentenceId, setEndSentenceId] = useState('');
  const [clipLength, setClipLength] = useState('00:00');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const statusList = useMemo(
    () =>
      manualShorts
        .map((ms) => {
          const short = shorts.find((s) => s.highlightId === ms.highlightId);
          const { startTime, endTime } = ms;
          const startSentence = localRecordingSentences.find(
            (s) => s.start === startTime[0]
          );
          const endSentence = localRecordingSentences.find(
            (s) => s.end === endTime[0]
          );
          if (!startSentence || !endSentence) return null;
          if (!short) {
            return {
              status: 'processing',
              duration: getDuration(startTime[0], endTime[0]),
              startSentence,
              endSentence,
              ...ms,
            };
          } else {
            return {
              status: 'complete',
              duration: getDuration(startTime[0], endTime[0]),
              startSentence,
              endSentence,
              ...ms,
            };
          }
        })
        .filter((ms) => ms !== null),
    [localRecordingSentences, manualShorts, shorts]
  );

  useEffect(() => {
    const startSentence = localRecordingSentences.find(
      (s) => s.id === startSentenceId
    );
    const endSentence = localRecordingSentences.find(
      (s) => s.id === endSentenceId
    );
    if (!endSentence || !startSentence) {
      setClipLength('00:00');
      return;
    }
    const { start } = startSentence;
    const { end } = endSentence;
    if (end <= start) {
      setClipLength('00:00');
      return;
    }
    setClipLength(getDuration(start, end));
  }, [startSentenceId, endSentenceId, localRecordingSentences]);

  const updateSettings = useCallback(
    (field, value) => {
      if (!series) return;
      setSettings((prevSettings) => ({ ...prevSettings, [field]: value }));
      if (error) {
        setError(null);
      }
    },
    [error, series]
  );

  const handleClipClick = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    const startSentence = localRecordingSentences.find(
      (s) => s.id === startSentenceId
    );
    const endSentence = localRecordingSentences.find(
      (s) => s.id === endSentenceId
    );
    if (!startSentence) {
      setError('Start sentence id is invalid');
      setIsLoading(false);
      return;
    }
    if (!endSentence) {
      setError('End sentence id is invalid');
      setIsLoading(false);
      return;
    }
    const { start } = startSentence;
    const { end } = endSentence;
    if (end <= start) {
      setError('End sentence is before start sentence');
      setIsLoading(false);
      return;
    }
    const duration = end - start;
    if (duration > 180) {
      setError("Clip can't be more than 3 minutes");
      setIsLoading(false);
      return;
    }

    const highlightId = makeRandomId(6);

    const payload = {
      highlightId,
      startTime: [start],
      endTime: [end],
    };

    const taskDetails = {
      meetingSeriesId,
      workspaceId,
      processorId: 'INTERESTING_MOMENTS_SHORTS_METADATA',
    };

    const body = {
      taskDetails,
      payload,
      forceRun: true,
    };

    axios.post(`${PROCESSOR_BASE_URL}/task`, body);

    const newManualShorts = [...manualShorts, { ...payload }];

    updateSettings('manualShorts', newManualShorts);

    await delay(5_000);

    const result = await updateEpisodeSettingsApi(meetingSeriesId, {
      manualShorts: newManualShorts,
    });

    localDispatch({ type: 'REFRESH_SERIES', series: result });

    setIsLoading(false);
  }, [
    endSentenceId,
    localDispatch,
    localRecordingSentences,
    manualShorts,
    meetingSeriesId,
    startSentenceId,
    updateSettings,
    workspaceId,
  ]);

  return (
    <ModalWindow
      size={Modal.size.xxl}
      onCancel={handleClose}
      onOutsideClick={handleClose}
      overflow="overflow-visible"
    >
      <ModalTitle>Short Clip Editor</ModalTitle>
      <div className="px-6 my-6 md:px-8 md:my-8 h-[60vh] overflow-auto">
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-2">
            <div className="flex gap-2 items-end">
              <TextInput
                label="Start sentence id"
                value={startSentenceId}
                onChange={(event, value) => setStartSentenceId(value)}
              />
              <TextInput
                label="End sentence id"
                value={endSentenceId}
                onChange={(event, value) => setEndSentenceId(value)}
              />
              <span className="mb-1 text-blue-dark">
                Clip length: {clipLength}
              </span>
              <div className="mb-2">
                <Button
                  color={Button.colors.PURPLE}
                  padding={Button.padding.SMALL}
                  size={Button.sizes.FIT}
                  onClick={handleClipClick}
                  state={
                    isLoading ? Button.states.LOADING : Button.states.DEFAULT
                  }
                >
                  <span className="text-sm">Clip</span>
                </Button>
              </div>
            </div>
            {error && (
              <div className="text-red text-xs font-light">{error}</div>
            )}
          </div>

          {statusList.length > 0 && (
            <Section title="Submitted Clips">
              {statusList.map((s) => (
                <div className="flex gap-2" key={s.highlightId}>
                  <span>{s.highlightId}</span>
                  <span>Start: {s.startSentence.id}</span>
                  <span>End: {s.endSentence.id}</span>
                  <span>({s.duration} min)</span>
                  <span>Status: {s.status}</span>
                </div>
              ))}
            </Section>
          )}

          <Section title="Sentences">
            <div className="max-h-80 flex flex-col gap-2 h-80 overflow-auto">
              {hasAllLocalRecordings ? (
                localRecordingSentences.map((s) => (
                  <CopyToClipboard text={s.id} key={s.id}>
                    <button className="flex gap-1 flex-wrap items-center">
                      <span className="text-blue-gray text-sm">{s.id}</span>
                      <span className="text-blue-dark font-medium">
                        {s.name}:
                      </span>
                      <span className="text-blue-dark text-left">{s.text}</span>
                    </button>
                  </CopyToClipboard>
                ))
              ) : (
                <span>N/A</span>
              )}
            </div>
          </Section>
        </div>
      </div>
    </ModalWindow>
  );
};
