import React from 'react';

export const TroubleshootIcon = ({
  width = '21px',
  height = '20px',
  className = 'fill-purple',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 20"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 0C5 0 0.5 4.5 0.5 10C0.5 15.5 5 20 10.5 20C16 20 20.5 15.5 20.5 10C20.5 4.5 16 0 10.5 0ZM18.5 10H15.5C15.5 8.2 14.5 6.6 13 5.7L14.5 3.1C17 4.5 18.5 7.1 18.5 10ZM10.5 7C8.8 7 7.5 8.3 7.5 10C7.5 11.7 8.8 13 10.5 13C12.2 13 13.5 11.7 13.5 10C13.5 8.3 12.2 7 10.5 7ZM6.5 3.1L8 5.7C6.5 6.6 5.5 8.2 5.5 10H2.5C2.5 7.1 4 4.5 6.5 3.1ZM8 14.3L6.5 16.9C9 18.4 12 18.4 14.5 16.9L13 14.3C11.5 15.2 9.5 15.2 8 14.3Z"
    />
  </svg>
);
