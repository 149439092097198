import { CloseButton } from '../CloseButton';
import styled from 'styled-components';

/** A close button positioned at the top right corner of its container. */
export const PositionedCloseButton = styled(CloseButton)`
  position: absolute;
  top: 8px;
  right: 8px;
`;

export const DeleteItemButton = styled(CloseButton).attrs({ shadow: true })`
  position: absolute;
  background: white;
  top: -20px;
  right: -20px;
  transform: scale(0.7);
  z-index: 3;
`;

export * from '../CloseButton';
