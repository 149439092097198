import React from 'react';

export const PencilIcon = ({
  className = 'fill-purple',
  width = '16px',
  height = '16px',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M11.7464 0.464844C11.5477 0.464844 11.3482 0.535348 11.2072 0.675599C10.7902 1.0926 9.28944 2.59336 8.95644 2.92711L2.20269 9.68088L1.45194 10.4309C1.34769 10.5359 1.29369 10.6844 1.26444 10.8299L0.514438 14.5821C0.409438 15.1071 0.856442 15.5541 1.38144 15.4499C1.85094 15.3554 4.66494 14.7929 5.13369 14.6991C5.27919 14.6699 5.4277 14.6159 5.53269 14.5116L6.28269 13.7609L13.0364 7.00712C13.3702 6.67412 14.8709 5.17259 15.2879 4.75634C15.4282 4.61534 15.4987 4.41584 15.4987 4.21709C15.4987 2.98934 15.1859 2.11184 14.5372 1.44959C13.8824 0.781336 13.0079 0.464844 11.7464 0.464844ZM12.0419 1.97908C12.7192 2.01883 13.1579 2.17412 13.4587 2.48162C13.7662 2.79512 13.9604 3.23012 14.0024 3.89387C13.5914 4.30412 12.9899 4.89659 12.4972 5.38934C11.9024 4.79459 11.1689 4.06111 10.5742 3.46636C11.0677 2.97361 11.6317 2.38933 12.0419 1.97908ZM9.49569 4.54485L11.4187 6.46788L5.74344 12.1431L3.82044 10.2201L9.49569 4.54485ZM2.74194 11.2986L4.66494 13.2216L4.59444 13.2921C4.09944 13.3911 3.09969 13.6004 2.17869 13.7849L2.67144 11.3691L2.74194 11.2986Z" />
    </svg>
  );
};
