import { episodeBuilder } from '../../helper/episodeBuilder';
import { createNewQuestionSlide } from './useMeetingCreator';
import { timelineStyleKeys } from '../../helper/constants';

const slidesConfig = (timelineStyle, questions) => {
  switch (timelineStyle) {
    case timelineStyleKeys.basic: {
      return [
        {
          sceneTemplateKey: 'scene_lobby',
        },
        {
          sceneTemplateKey: 'scene_open_discussion',
        },
      ];
    }
    case timelineStyleKeys.discussion_points: {
      return [
        {
          sceneTemplateKey: 'scene_lobby',
        },
        { sceneTemplateKey: 'scene_topic_intro' },
        ...questions.map((questionSlide) => ({
          sceneTemplateKey: 'scene_discussion_point_no_speaker',
          mapper: (scene) => createNewQuestionSlide(scene, questionSlide),
        })),
        {
          sceneTemplateKey: 'scene_thankyou_no_speaker',
        },
      ];
    }
    case timelineStyleKeys.audience_interaction:
    default: {
      return [
        {
          sceneTemplateKey: 'scene_lobby',
        },
        { sceneTemplateKey: 'scene_topic_intro' },
        ...questions.map((questionSlide) => ({
          sceneTemplateKey: 'scene_discussion_point_no_speaker',
          mapper: (scene) => createNewQuestionSlide(scene, questionSlide),
        })),
        { sceneTemplateKey: 'scene_q&a_no_speaker' },
        {
          sceneTemplateKey: 'scene_opinionpoll_host',
        },
        {
          sceneTemplateKey: 'scene_wordcloud_host',
        },
        {
          sceneTemplateKey: 'scene_thankyou_no_speaker',
        },
      ];
    }
  }
};

export const createEpisodeWithoutSpeakers = async ({
  title,
  eventHost,
  questions,
  timelineStyle,
}) => {
  const { init, build } = episodeBuilder({
    title,
    slides: slidesConfig(timelineStyle, questions),
    templateKey: 'meeting_speaker_interview_1x1',
    options: {
      eventPresenters: [eventHost],
    },
  });

  await init();

  return build();
};
