import React, { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  checkIsSubscriptionPastDue,
  enforcementMessagesReceivers,
  planEnforcementTypes,
} from 'zync-common/zyncCustomerPlans';

import { isMeetingController } from '../helper/roles';
import { useUserWorkspaces } from '../hooks/useUserWorkspaces';
import { checkIsSubscriptionCanceled } from '../pages/Workspaces/useWorkspaceBillingInfo.helpers';

import { PlanEnforcementMessage } from './planEnforcement/components/PlanEnforcementMessage';
import { PlanEnforcementMessagesMap } from './planEnforcement/components/PlanEnforcementMessage/PlanEnforcementMessage';
import mixpanel from 'mixpanel-browser';
import { useWorkspacePlan } from '../hooks/useWorkspacePlan';

const PLAN_ENFORCEMENT_TOAST_ID = 'planEnforcementToast';

const toastOptions = {
  toastId: PLAN_ENFORCEMENT_TOAST_ID,
  position: 'top-left',
  className: '!fixed !top-0 !left-0 !w-full !bg-[#FFF7ED]',
  draggable: false,
  autoClose: false,
  closeOnClick: false,
  closeButton: ({ closeToast }) => (
    <button
      className="text-blue-dark self-start mr-6 ml-3"
      onClick={closeToast}
    >
      <FontAwesomeIcon icon="times" />
    </button>
  ),
};

const checkIsToastActive = (toastId = PLAN_ENFORCEMENT_TOAST_ID) =>
  toast.isActive(toastId);

const createToast = (message, options) => toast(message, options);
const updateToast = (message, options, toastId = PLAN_ENFORCEMENT_TOAST_ID) =>
  toast.update(toastId, { ...options, render: message });

export const dispatchToast = (message) => {
  const isToastActive = checkIsToastActive();

  /* We want to reuse the same toast and override content, when a new message is coming */
  isToastActive ? updateToast(message) : createToast(message);
};

export const useWorkspacePlanEnforcementMessages = ({
  series,
  user,
  subscription,
}) => {
  const { currentWorkspace, isWorkspaceSuperAdmin, isWorkspaceAdmin } =
    useUserWorkspaces({ selectedWorkspaceId: series?.workspace?.workspaceId });

  /* user might not be a member of this workspace (ie. guest with added "moderator" roles */
  const selectedWorkspace = currentWorkspace.workspaceId
    ? currentWorkspace
    : series?.workspace;

  const billingOwnerId = selectedWorkspace?.billingOwnerUserId;

  const isSubscriptionSetup = !!selectedWorkspace?.stripeSubscriptionId;
  const isModerator = isMeetingController(user);

  const { workspacePlan: currentMeetingPlan } = useWorkspacePlan();

  const isSubscriptionActive = !(
    checkIsSubscriptionCanceled(subscription) ||
    checkIsSubscriptionPastDue(subscription)
  );

  const toastMessageProps = useMemo(
    () => ({
      isSubscriptionSetup,
      currentMeetingPlan,
    }),
    [isSubscriptionSetup, currentMeetingPlan]
  );

  const userType = isWorkspaceSuperAdmin
    ? enforcementMessagesReceivers.SUPER_ADMIN
    : isWorkspaceAdmin
    ? enforcementMessagesReceivers.ADMIN
    : isModerator
    ? enforcementMessagesReceivers.MODERATOR
    : enforcementMessagesReceivers.GUEST;

  const dispatchToast = useCallback(
    (messageProps, options = toastOptions) => {
      mixpanel.track('Studio Time Limit Warning Displayed');

      const { enforcementType } = messageProps;

      const Message = PlanEnforcementMessagesMap[enforcementType][userType] ? (
        <PlanEnforcementMessage
          {...messageProps}
          {...toastMessageProps}
          userType={userType}
          billingAdminEmail={billingOwnerId}
          isSubscriptionActive={isSubscriptionActive}
          workspaceId={selectedWorkspace?.workspaceId}
        />
      ) : null;

      if (!Message) {
        return;
      }

      const isToastActive = checkIsToastActive();

      /* We want to reuse the same toast and override content, when a new message is coming */
      isToastActive
        ? updateToast(Message, options)
        : createToast(Message, options);
    },
    [
      userType,
      toastMessageProps,
      billingOwnerId,
      isSubscriptionActive,
      selectedWorkspace?.workspaceId,
    ]
  );

  const dispatchMeetingFullEnforcementToast = useCallback(() => {
    dispatchToast({
      enforcementType: planEnforcementTypes.MEETING_FULL,
    });
  }, [dispatchToast]);

  const dispatchMeetingEndingSoonEnforcementToast = useCallback(
    (meetingCountdownProperties) => {
      /* when we display dynamic countdown, it is very late, and we want to prevent dismissing the toast */
      const hideCloseButton = !!meetingCountdownProperties.showDynamicCountdown;

      const newToastOptions = {
        ...toastOptions,
      };

      if (hideCloseButton) {
        newToastOptions.closeButton = false;
      }

      dispatchToast(
        {
          enforcementType: planEnforcementTypes.TIME_EXPIRING,
          meetingCountdownProperties,
        },
        newToastOptions
      );
    },
    [dispatchToast]
  );

  const dispatchRecordingUnsupportedEnforcementToast = useCallback(() => {
    dispatchToast({
      enforcementType: planEnforcementTypes.RECORDING_NOT_SUPPORTED,
    });
  }, [dispatchToast]);

  const dispatchStreamingUnsupportedEnforcementToast = useCallback(() => {
    dispatchToast({
      enforcementType: planEnforcementTypes.STREAMING_NOT_SUPPORTED,
    });
  }, [dispatchToast]);

  return {
    dispatchMeetingFullEnforcementToast,
    dispatchRecordingUnsupportedEnforcementToast,
    dispatchStreamingUnsupportedEnforcementToast,
    dispatchMeetingEndingSoonEnforcementToast,
  };
};
