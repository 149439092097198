export const BoomerangDownArrow = ({
  width = '12',
  height = '8',
  className = 'fill-[#696F8C]',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M6.72181 7.28016C6.58129 7.42086 6.39065 7.49999 6.19181 7.50016H5.81181C5.61339 7.49786 5.42353 7.41905 5.28181 7.28016L0.151805 2.14016C0.0571491 2.04628 0.00390625 1.91848 0.00390625 1.78516C0.00390625 1.65185 0.0571491 1.52405 0.151805 1.43016L0.861805 0.720165C0.953969 0.626101 1.08012 0.573093 1.21181 0.573093C1.3435 0.573093 1.46964 0.626101 1.56181 0.720165L6.00181 5.17016L10.4418 0.720165C10.5357 0.625508 10.6635 0.572266 10.7968 0.572266C10.9301 0.572266 11.0579 0.625508 11.1518 0.720165L11.8518 1.43016C11.9465 1.52405 11.9997 1.65185 11.9997 1.78516C11.9997 1.91848 11.9465 2.04628 11.8518 2.14016L6.72181 7.28016Z" />
    </svg>
  );
};
