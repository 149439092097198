import React from 'react';

export const DiscussionPointIcon = ({
  width = 18,
  height = 19,
  className = 'fill-[#7612C4]',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_18425_77031)">
      <path d="M17.0775 1.25H15.375C15.165 1.25 15 1.415 15 1.625C15 1.835 15.165 2 15.375 2H17.0775C17.1675 2 17.25 2.075 17.25 2.1725V17.585C17.25 17.675 17.175 17.7575 17.0775 17.7575H0.9225C0.8325 17.7575 0.75 17.6825 0.75 17.585V2.1725C0.75 2.0825 0.825 2 0.9225 2H2.625C2.835 2 3 1.835 3 1.625C3 1.415 2.835 1.25 2.625 1.25H0.9225C0.4125 1.25 0 1.6625 0 2.1725V17.585C0 18.095 0.4125 18.5075 0.9225 18.5075H17.085C17.595 18.5075 18.0075 18.095 18.0075 17.585V2.1725C18.0075 1.6625 17.595 1.25 17.085 1.25H17.0775Z" />
      <path d="M3.4275 2.5775C2.985 2.5775 2.625 2.9375 2.625 3.38V16.0325C2.625 16.475 2.985 16.835 3.4275 16.835H14.58C15.0225 16.835 15.3825 16.475 15.3825 16.0325V3.38C15.3825 2.9375 15.0225 2.5775 14.58 2.5775H13.86C13.7775 1.8275 13.1475 1.25 12.375 1.25H11.745C11.5875 0.815 11.175 0.5 10.6875 0.5H7.3125C6.825 0.5 6.4125 0.815 6.255 1.25H5.625C4.8525 1.25 4.23 1.835 4.14 2.5775H3.42H3.4275ZM7.3125 1.25H10.6875C10.8975 1.25 11.0625 1.415 11.0625 1.625C11.0625 1.835 10.8975 2 10.6875 2H7.3125C7.1025 2 6.9375 1.835 6.9375 1.625C6.9375 1.415 7.1025 1.25 7.3125 1.25ZM5.625 2H6.255C6.4125 2.435 6.825 2.75 7.3125 2.75H10.6875C11.175 2.75 11.5875 2.435 11.745 2H12.375C12.7875 2 13.125 2.3375 13.125 2.75C13.125 3.1625 12.7875 3.5 12.375 3.5H5.625C5.2125 3.5 4.875 3.1625 4.875 2.75C4.875 2.3375 5.2125 2 5.625 2ZM5.625 4.25H12.375C12.99 4.25 13.5225 3.875 13.755 3.3425L14.625 3.38L14.5725 16.0775L3.375 16.025L3.4275 3.3275H4.245C4.47 3.8675 5.01 4.25 5.625 4.25Z" />
      <path d="M6 7.07813H12C12.21 7.07813 12.375 6.91313 12.375 6.70313C12.375 6.49313 12.21 6.32812 12 6.32812H6C5.79 6.32812 5.625 6.49313 5.625 6.70313C5.625 6.91313 5.79 7.07813 6 7.07813Z" />
      <path d="M6 10.0781H12C12.21 10.0781 12.375 9.91312 12.375 9.70312C12.375 9.49312 12.21 9.32812 12 9.32812H6C5.79 9.32812 5.625 9.49312 5.625 9.70312C5.625 9.91312 5.79 10.0781 6 10.0781Z" />
      <path d="M6 13.0781H12C12.21 13.0781 12.375 12.9131 12.375 12.7031C12.375 12.4931 12.21 12.3281 12 12.3281H6C5.79 12.3281 5.625 12.4931 5.625 12.7031C5.625 12.9131 5.79 13.0781 6 13.0781Z" />
    </g>
    <defs>
      <clipPath id="clip0_18425_77031">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
