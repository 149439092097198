import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useUserWorkspaces } from './useUserWorkspaces';
import { useClientWorkspaceAccess } from './useClientWorkspaceAccess';
import { useSpeakersCrm } from './useSpeakersCrm';
import { getTrialDateUntil, PLANS } from 'zync-common/zyncCustomerPlans';
import { useWorkspacePlan } from './useWorkspacePlan';

export const useSpeakersPage = (workspaceIdProps) => {
  const { authenticated, user } = useSelector((_st) => _st.auth);
  const [redirectToLanding, setRedirectToLanding] = useState(false);
  const [showAddSpeakerModal, setShowAddSpeakerModal] = useState(false);

  const [selectedSpeaker, setSelectedSpeaker] = useState(null);
  const [modalType, setModalType] = useState('');

  const {
    brandKit,
    show,
    currentWorkspaceId: workspaceId,
    isWorkspaceOnboardingRequired,
    isWorkspaceAdmin,
    isWorkspaceSuperAdmin,
    currentWorkspace,
    currentWorkspaceName,
  } = useUserWorkspaces();

  const { speakers, isLoading, refreshSpeakers } = useSpeakersCrm(
    workspaceIdProps || workspaceId
  );

  const { isTrial, plan } = useWorkspacePlan();

  useClientWorkspaceAccess();

  const isAdmin = isWorkspaceAdmin || isWorkspaceSuperAdmin;

  const workspacePlan = currentWorkspace.workspacePlan;

  const displayCalendlyButton = Boolean(
    (workspacePlan === PLANS.admin.id ||
      workspacePlan === PLANS.business.id ||
      workspacePlan === PLANS.pilot.id) &&
      currentWorkspace.calendlyOwnerUserId === user.userId &&
      currentWorkspace?.calendlyEventUrl
  );

  const calendlyEventUrl = displayCalendlyButton
    ? `${currentWorkspace?.calendlyEventUrl}?utm_source=${workspaceId}`
    : '';

  const showName = show?.name;
  const hostName = currentWorkspace?.creatorUserName;

  return {
    authenticated,
    isWorkspaceOnboardingRequired,
    redirectToLanding,
    setRedirectToLanding,
    user,
    isAdmin,
    currentWorkspaceName,
    brandKit,
    workspaceId,
    show,
    trialUntil: getTrialDateUntil(currentWorkspace),
    isTrial,
    plan,
    showAddSpeakerModal,
    setShowAddSpeakerModal,
    speakers,
    isLoading,
    refreshSpeakers,
    selectedSpeaker,
    setSelectedSpeaker,
    modalType,
    setModalType,
    calendlyEventUrl,
    hostName,
    showName,
  };
};
