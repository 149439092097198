export const MenuDownArrow = ({
  color = '#8F95B2',
  width = '6',
  height = '4',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 6 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 0.5C6 0.29 5.835 0.125 5.625 0.125H0.375C0.165 0.125 0 0.29 0 0.5C0 0.5975 0.0375 0.68 0.0975 0.7475L2.7225 3.7475C2.79 3.8225 2.8875 3.875 3 3.875C3.1125 3.875 3.21 3.8225 3.2775 3.7475L5.9025 0.7475C5.9625 0.68 6 0.5975 6 0.5Z"
        fill={color}
      />
    </svg>
  );
};
