import React from 'react';
import { useDailyAudio } from '../hooks/useDailyAudio';

export const UserAudio = ({ trackStates, outputDeviceId }) => {
  const { audioTrack, audioElement } = useDailyAudio(
    trackStates,
    outputDeviceId
  );

  return (
    <>
      {audioTrack && !trackStates.local && trackStates.micOn && (
        <audio className="user-audio" autoPlay playsInline ref={audioElement} />
      )}
    </>
  );
};
