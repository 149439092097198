export const ExitIcon = ({
  width = '18',
  height = '18',
  className = 'fill-[#696F8C]',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.305 12.4633C10.4909 12.5421 10.7058 12.5013 10.85 12.36L13.85 9.36C14.0418 9.16219 14.0418 8.84781 13.85 8.65L10.85 5.65C10.7072 5.50994 10.4946 5.46851 10.3096 5.54468C10.1246 5.62086 10.0028 5.79996 10 6V8H0.5C0.223858 8 0 8.22386 0 8.5L0 9.5C0 9.77614 0.223858 10 0.5 10H10V12C9.99881 12.2019 10.1191 12.3846 10.305 12.4633ZM18 0.5V17.5C18 17.7761 17.7761 18 17.5 18H16.5C16.2239 18 16 17.7761 16 17.5V0.5C16 0.223858 16.2239 0 16.5 0H17.5C17.7761 0 18 0.223858 18 0.5Z"
      />
    </svg>
  );
};
