import React, { useCallback, useState } from 'react';
import { Button } from '../../../../common/Button';

import { ConfirmationModal } from '../../../../common/Modal';

const useConfirmationModal = ({ onConfirm, modalTitle, modalText }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  return {
    Modal: (
      <ConfirmationModal
        title={modalTitle}
        text={modalText}
        open={isOpen}
        onClose={handleClose}
        onConfirm={onConfirm}
        showCloseButton={false}
        modalActions={
          <div className="flex justify-end space-x-4">
            <Button
              onClick={handleClose}
              color={Button.colors.WHITE}
              padding={Button.padding.SMALL}
            >
              <span className="inline-block font-medium text-sm py-2 w-[120px]">
                Cancel
              </span>
            </Button>
            <Button
              onClick={onConfirm}
              color={Button.colors.PURPLE}
              padding={Button.padding.SMALL}
            >
              <span className="inline-block font-medium text-sm py-2 w-[120px]">
                Confirm
              </span>
            </Button>
          </div>
        }
      />
    ),
    handleOpen,
    handleClose,
  };
};

export default useConfirmationModal;
