import React from 'react';
import {
  RightPanelAttributeHeader,
  RightPanelAttributesDiv,
  RightPanelAttributeHeaderNote,
} from './RightPanel.styled';
import { SettingsInput } from '../blockHelpers';

/**
 * A functional settings pane for the right-hand authoring pane, based on the `settingsSpec` field of
 * the block definition in blockList.js
 */
export const AuthoringSettingsTemplate = ({
  blockInstanceId,
  meetingSeriesId,
  settingsSpec,
  updateSettings,
  settings,
  details,
}) => {
  if (!settingsSpec) {
    throw new Error(
      'AuthoringSettingsTemplate requires that the block define a settingsSpec.'
    );
  }

  const allowRotation = Boolean(settingsSpec.allowRotation);

  const fields = [...(settingsSpec.fields || [])];
  if (allowRotation) {
    fields.push({
      type: 'number',
      label: 'Rotation',
      name: 'rotation',
      min: -360,
      max: 360,
      step: 10,
      unit: 'deg',
    });
  }

  return (
    <RightPanelAttributesDiv>
      {fields.map(({ type, ...field }) => {
        const props = {
          ...field,
          uniqueKey: blockInstanceId,
          label: field.label,
          field: field.name,
          explanation: field.explanation,
          placeholder: field.placeholder,
          settings,
          updateSettings,
        };

        return (
          <div key={`${type}-${field.name || field.label}`}>
            {type === 'text' && (
              <SettingsInput type="text" {...props} updateOnBlur />
            )}

            {type === 'textarea' && (
              <SettingsInput
                {...props}
                type="textarea"
                rows={props.rows ?? 6}
                updateOnBlur
              />
            )}

            {(type === 'boolean' || type === 'checkbox') && (
              <SettingsInput {...props} type="boolean" />
            )}

            {type === 'number' && (
              <SettingsInput
                {...props}
                type="number"
                min={field.min}
                max={field.max}
                step={field.step}
              />
            )}

            {type === 'select' && (
              <SettingsInput
                {...props}
                type="select"
                selectOptions={field.options}
                flat={field.flat ?? false}
                horizontal={field.horizontal ?? false}
              />
            )}

            {type === 'dropdown' && (
              <SettingsInput
                {...props}
                type="dropdown"
                selectOptions={field.options}
              />
            )}

            {type === 'header' && (
              <>
                <RightPanelAttributeHeader>
                  {field.label}
                </RightPanelAttributeHeader>
                {field.explanation && (
                  <RightPanelAttributeHeaderNote>
                    {field.explanation}
                  </RightPanelAttributeHeaderNote>
                )}
              </>
            )}

            {(type === 'color' || type === 'color-select') && (
              <SettingsInput
                {...props}
                type="color-select"
                colors={field.colors}
                custom={field.custom}
              />
            )}

            {(type === 'text-array' || type === 'user-array') && (
              <SettingsInput {...props} type={type} />
            )}

            {type === 'image' && (
              <SettingsInput
                {...props}
                folderName={meetingSeriesId}
                type="image"
              />
            )}

            {type === 'svg' && (
              <SettingsInput
                {...props}
                folderName={meetingSeriesId}
                type="svg"
              />
            )}
          </div>
        );
      })}
    </RightPanelAttributesDiv>
  );
};
