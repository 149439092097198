import React, { useState } from 'react';
import { CaretIcon } from '../../../components/icons/CaretIcon';
import ReactPlayer from 'react-player';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyIcon } from '../../../components/icons/CopyIcon';
import { downloadImage } from '../../../helper/image';
import { DownloadLink } from '../../../components/Highlights';
import { DownloadIcon } from '../../../components/icons/DownloadIcon';
import classNames from '../../../helper/classNames';
import { Button } from '../../../components/common/Button';
import { UploadToYoutubeModal } from './UploadToYoutubeModal';

const getHighestQualityDownloadUrl = (files) => {
  const result = [...files];
  const getRank = (name) => {
    switch (name) {
      case 'high.mp4':
        return 1;
      case 'medium.mp4':
        return 2;
      case 'low.mp4':
        return 3;
      default:
        return 4;
    }
  };
  result.sort((f1, f2) => getRank(f1.name) - getRank(f2.name));
  return result[0].downloadUrl;
};

export const SocialMediaPost = ({
  playbackUrl,
  title,
  thumbnailUrl,
  startCollapsed = true,
  postTitle,
  files,
  canUploadToYoutube = false,
  handlePublish,
  highlightId,
  uploadedToYoutube,
  saveHighlightSocialMediaPost,
  socialMediaType,
  aspectRatio = 'landscape',
  createdByProcessorId,
  alternateVideoUrl = '',
  posterUrl,
  useCollapse = true,
  backgroundColor,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(startCollapsed);
  const [editedPostTitle, setEditedPostTitle] = useState(postTitle);
  const [isSavingPostTitleLoading, setIsSavingPostTitleLoading] =
    useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);

  const videoDownloadUrl = getHighestQualityDownloadUrl(files);

  const isPostTitleEdited =
    editedPostTitle && postTitle
      ? editedPostTitle.trim() !== postTitle.trim()
      : false;

  return (
    <div
      className={classNames(
        useCollapse ? 'w-[953px]' : 'w-full',
        'flex flex-col shadow-elevation-one rounded-lg border-[#EBEAED] border'
      )}
      style={{
        backgroundColor,
      }}
    >
      {showPublishModal && (
        <UploadToYoutubeModal
          highlightId={highlightId}
          title={title}
          description={postTitle}
          createdByProcessorId={createdByProcessorId}
          handlePublish={handlePublish}
          setShowPublishModal={setShowPublishModal}
          thumbnailUrl={thumbnailUrl}
          uploadedToYoutube={uploadedToYoutube}
          playbackUrl={playbackUrl}
          alternateVideoUrl={alternateVideoUrl}
          aspectRatio={aspectRatio}
          posterUrl={posterUrl}
        />
      )}
      {useCollapse && (
        <div className="flex flex-row justify-between items-center py-3 px-5 h-12 bg-[#F9F5FF]">
          <div className="text-blue-dark text-sm font-medium truncate">
            {title}
          </div>
          <div className="flex gap-5">
            <button onClick={() => setIsCollapsed((prevState) => !prevState)}>
              {isCollapsed ? (
                <CaretIcon width="15px" height="11px" />
              ) : (
                <CaretIcon
                  width="15px"
                  height="11px"
                  className="rotate-180 fill-blue-gray"
                />
              )}
            </button>
          </div>
        </div>
      )}
      {(!useCollapse || (useCollapse && !isCollapsed)) && (
        <div>
          {!useCollapse && (
            <h2 className="pl-5 pt-5 font-medium text-blue-dark">{title}</h2>
          )}
          <div className="flex w-full gap-5 p-5 h-fit max-w-full">
            {postTitle && (
              <div className="flex flex-col w-1/2 max-h-full">
                <div className="flex justify-between">
                  <div className="font-medium text-blue-dark md:text-base text-sm">
                    Post
                  </div>
                  <div className="flex gap-2">
                    {isPostTitleEdited && (
                      <Button
                        padding={Button.padding.NONE}
                        color={Button.colors.PURPLE}
                        border={Button.border.ROUNDED}
                        onClick={async () => {
                          await saveHighlightSocialMediaPost(
                            setIsSavingPostTitleLoading,
                            editedPostTitle,
                            highlightId,
                            socialMediaType
                          );
                        }}
                        state={
                          isSavingPostTitleLoading
                            ? Button.states.LOADING
                            : Button.states.DEFAULT
                        }
                      >
                        <div className="text-[10px] h-6 flex justify-center items-center px-2.5">
                          Save
                        </div>
                      </Button>
                    )}
                    <div className="group relative md:flex hidden items-center">
                      <CopyToClipboard text={postTitle}>
                        <button>
                          <CopyIcon />
                        </button>
                      </CopyToClipboard>
                      <div className="group-hover:block hidden rounded-lg bg-blue-dark text-white text-xs font-light absolute w-[73px] p-2 top-6">
                        Copy text
                      </div>
                    </div>
                  </div>
                </div>
                <textarea
                  className="max-w-full max-h-full w-full h-full border border-gray py-2 px-3 text-sm leading-5 text-blue-gray rounded overflow-auto flex flex-col resize-none"
                  value={editedPostTitle}
                  onChange={(event) => setEditedPostTitle(event.target.value)}
                  disabled={isSavingPostTitleLoading}
                />
              </div>
            )}
            <div
              className={classNames(
                'flex flex-col gap-2 h-full',
                postTitle ? 'w-1/2' : 'w-full',
                aspectRatio !== 'landscape' && 'items-center'
              )}
            >
              <div className="flex gap-3 justify-end items-start">
                {canUploadToYoutube && (
                  <Button
                    padding={Button.padding.NONE}
                    color={Button.colors.PURPLE}
                    border={Button.border.ROUNDED}
                    onClick={async () => {
                      if (handlePublish) {
                        setShowPublishModal(true);
                      }
                    }}
                    state={
                      uploadedToYoutube
                        ? Button.states.DISABLED
                        : Button.states.DEFAULT
                    }
                  >
                    <div className="text-[10px] h-6 flex justify-center items-center px-2.5">
                      Publish
                    </div>
                  </Button>
                )}
                {thumbnailUrl && (
                  <button
                    onClick={() => {
                      downloadImage(thumbnailUrl, `${title}_thumbnail.png`);
                    }}
                  >
                    <DownloadLink text="Download Thumbnail"></DownloadLink>
                  </button>
                )}
                <a
                  href={videoDownloadUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                  className="flex gap-1  decoration-blue-gray underline"
                >
                  <DownloadIcon className="fill-blue-light" height="16px" />
                  <div className="text-blue-gray font-medium text-xs">
                    Video Clip
                  </div>
                </a>
              </div>
              <ReactPlayer
                className="react-player aspect-video"
                width={aspectRatio === 'landscape' ? '100%' : '30%'}
                height="auto"
                url={
                  alternateVideoUrl.trim().length > 0
                    ? alternateVideoUrl
                    : playbackUrl
                }
                playing={!!thumbnailUrl}
                controls={true}
                light={thumbnailUrl}
                loop={false}
                volume={1}
                muted={false}
                pip={false}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
