import { useState, useRef, useEffect, useCallback } from 'react';

/**
 * Tracks the height, width, and bounding rect of a given element when the provided ref
 * is used.
 *
 * @example
 *
 * const { width, height, bounds, ref } = useElementSize();
 * ...
 * <div ref={ref} />
 */
export const useElementSize = () => {
  const elementRef = useRef(null);
  const observerRef = useRef(null);

  // We cannot set width / height synchronously for elements not yet attached to the DOM.
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const [bounds, setBounds] = useState(null);

  // Whenver the element ref changes, the following fired.
  const setElementRef = useCallback((newElement) => {
    // Element has not changed, nothing to do.
    if (newElement === elementRef.current) return;

    // Lazy load the resizeable observer. This gets destroyed in the useEffect shutdown.
    if (!observerRef.current) {
      observerRef.current = new ResizeObserver((entries) => {
        window.requestAnimationFrame(() => {
          entries.forEach((entry) => {
            const rect = entry.contentRect;
            setWidth(rect.width);
            setHeight(rect.height);
            setBounds(rect);
          });
        });
      });
    }

    // Stop observing the old reference.
    if (elementRef.current) {
      observerRef.current.unobserve(elementRef.current);
    }

    // Begin observing the new reference.
    if (!newElement) return;
    observerRef.current.observe(newElement);
    elementRef.current = newElement;
  }, []);

  useEffect(() => {
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  return {
    ref: setElementRef,
    width,
    height,
    bounds,
    element: elementRef.current ?? null,
  };
};
