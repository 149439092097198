import React from 'react';

export const AngleIcon = ({
  className = 'fill-purple',
  width = '8px',
  height = '13px',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M7.70492 1.91L6.29492 0.5L0.294922 6.5L6.29492 12.5L7.70492 11.09L3.12492 6.5L7.70492 1.91Z" />
    </svg>
  );
};
