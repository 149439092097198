import UIFx from '../blocks/helper/UIFx';
import { getStaticAssetUrl } from './getStaticAssetUrl';

const celebrationSound = getStaticAssetUrl('celebration.mp3');
const laughSound = getStaticAssetUrl('crowd-laugh.mp3');
const applauseSound = getStaticAssetUrl('applause.mp3');
const partyPopperSound = getStaticAssetUrl('fireworks.mp3');

// String keys / messages for when a user is muted during a meeting.
// The message is displayed as a toast.
const GLOBAL_UNMUTED_LIMIT = 'GLOBAL_UNMUTED_LIMIT';
const MUTED_BY_MODERATOR = 'MUTED_BY_MODERATOR';
const MUTING_BLOCK = 'MUTING_BLOCK';

const displayMuteReason = (reason) => {
  let message;
  switch (reason) {
    case GLOBAL_UNMUTED_LIMIT:
      message =
        'You were muted because there are too many people speaking right now.';
      break;
    case MUTED_BY_MODERATOR:
      message = 'You were muted by a moderator.';
      break;
    case MUTING_BLOCK:
      message = 'Everyone was automatically muted.';
      break;
    default:
      message = reason;
  }
  return message;
};

// When sending a toast about mutes or the mic, use these props.
const micRelatedToastProps = {
  position: 'bottom-center',
  closeButton: false,
};

const emojiSoundMap = {
  '😂': new UIFx(laughSound, {
    volume: 0.15,
    throttleMs: 1000,
  }),
  '🙌': new UIFx(celebrationSound, {
    volume: 0.03,
    throttleMs: 1000,
  }),
  '👏': new UIFx(applauseSound, {
    volume: 0.08,
    throttleMs: 1000,
  }),
  '🎉': new UIFx(partyPopperSound, {
    volume: 0.1,
    throttleMs: 1000,
  }),
};

const playEmojiSound = (emoji, outputDeviceId) => {
  const sound = emojiSoundMap[emoji];

  if (sound) {
    sound.play({ outputDeviceId });
  }
};

export {
  displayMuteReason,
  GLOBAL_UNMUTED_LIMIT,
  MUTED_BY_MODERATOR,
  MUTING_BLOCK,
  micRelatedToastProps,
  playEmojiSound,
};
