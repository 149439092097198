import { useState, useCallback } from 'react';
import { useInvitePermissions } from '../components/MeetingControl/useInvitePermissions';
import { sendEvent } from '../helper/api';
import { v4 as uuidv4 } from 'uuid';
import { useStartLoading } from './useStartLoading';
import { useProcessedActions } from '../helper/useProcessedActions';

/**
 * @param Object user: The user issuing the eject action.
 * @param string meetingId
 */
export const useRemoveUserMeetingRole = ({ user, meetingId }) => {
  const { canEditRole } = useInvitePermissions(user, user);
  const [roleRemoveActionId, setRoleRemoveActionId] = useState(null);
  const [roleToRemove, setRoleToRemove] = useState(null);
  const { hasActionCompleted: hasRoleRemoveActionCompleted } =
    useProcessedActions(roleRemoveActionId);

  /**
   * Removes a role from a single user.
   */
  const removeUserRole = useCallback(
    async (userId, role) => {
      const actionId = uuidv4();
      setRoleRemoveActionId(actionId);
      sendEvent(userId, meetingId, {
        type: 'REMOVE_USER_ROLE',
        targetUserId: userId,
        role,
        actionId,
      });
    },
    [meetingId]
  );

  /**
   * @param string role - Role to be removed.
   * @param Object targetUser - User to be remove the role from.
   * @param Function An optional callback.
   */
  const handleRemoveUserMeetingRoleHelper = (role, targetUser, cb) => {
    if (canEditRole) {
      cb?.();
      setRoleToRemove(role);
      removeUserRole(targetUser?.userId, role);
    }
  };

  const handleRemoveUserMeetingRole = (role, targetUser, cb) => {
    onClickWithLoading(role, targetUser, cb);
  };

  /**
   * Loading Logic for removing a role from a user.
   */
  const {
    isLoading: isRemovingUserMeetingRoleLoading,
    onClickWithLoading,
    disableLoading: disableRemoveRoleLoading,
  } = useStartLoading(
    handleRemoveUserMeetingRoleHelper,
    undefined,
    undefined,
    'Remove User Role'
  );

  if (
    isRemovingUserMeetingRoleLoading &&
    hasRoleRemoveActionCompleted(roleRemoveActionId)
  ) {
    disableRemoveRoleLoading();
  }

  return {
    handleRemoveUserMeetingRole,
    isRemovingUserMeetingRoleLoading,
    roleToRemove,
  };
};
