import React from 'react';

export const CameraIcon = ({
  width = '24px',
  height = '24px',
  className = 'fill-purple',
  style,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.41 7H21.5C21.7761 7 22 7.22386 22 7.5V16.5C22 16.7761 21.7761 17 21.5 17H21.41C21.1477 16.9989 20.8963 16.8947 20.71 16.71L18 14V17C18 18.1046 17.1046 19 16 19H4C2.89543 19 2 18.1046 2 17V7C2 5.89543 2.89543 5 4 5H16C17.1046 5 18 5.89543 18 7V10L20.71 7.29C20.8963 7.10526 21.1477 7.0011 21.41 7ZM5 10.38C5 10.6561 5.22386 10.88 5.5 10.88H9.5C9.77614 10.88 10 10.6561 10 10.38V9.62C10 9.34386 9.77614 9.12 9.5 9.12H5.5C5.22386 9.12 5 9.34386 5 9.62V10.38Z"
      />
    </svg>
  );
};
