/**
 * Fetches roles from every block in a meeting.
 *
 * @param {Array<object>} slides List of meeting slides
 * @returns {Array<string>} List of roles.
 */
export const getBlockRoles = (slides) => {
  const roles = new Set();
  for (let slide of slides) {
    const { slideBlocks } = slide?.slideConfig;
    for (let slideBlock of slideBlocks) {
      const { settings } = slideBlock;
      const { role } = settings;
      if (role) {
        roles.add(role);
      }
    }
  }
  return Array.from(roles);
};
