import mixpanel from 'mixpanel-browser';
import { getMeetingProps, meetingPropNames } from 'zync-common/tracking';

import { withCatchAllAndLogging } from '../helper/contextualLogger';

const meetingState = (state = {}, action) => {
  switch (action.type) {
    case 'REFRESH_MEETING_STATE':
      mixpanel.register({
        ...withCatchAllAndLogging(getMeetingProps)(
          action.meetingState,
          action.userId
        ),
      });

      return {
        ...state,
        ...action.meetingState,
      };
    case 'SET_MEETING_CONFIG':
      return {
        ...state,
        meetingConfig: action.meetingConfig,
      };
    case 'END_MEETING':
    case 'LEAVE_MEETING':
    case 'CLEAR_MEETING_STATE':
      for (const propName of meetingPropNames) {
        mixpanel.unregister(propName);
      }
      return {};
    default: {
      return state;
    }
  }
};

export default meetingState;
