import { useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * A hook that returns a function that checks if the action associated with the actionId is completed.
 *
 * @param {string|number} actionId A unique identifier associated with a given action.
 * @returns {function hasActionCompleted(actionId) => actionId | null
 */

export const useProcessedActions = (actionId) => {
  const meetingState = useSelector((state) => state.meetingState, shallowEqual);
  const { state } = meetingState || {};
  const { processedActions } = state || {};

  /**
   * Returns the actionId if the actionId argument
   * exists in the processedActions array.
   *
   * Returns null otherwise.
   */
  const hasActionCompleted = useCallback(
    () =>
      processedActions.find((o) => {
        const key = Object.keys(o)[0];
        return key === actionId?.toString() ? actionId : null;
      }),
    [processedActions, actionId]
  );

  return {
    hasActionCompleted,
  };
};
