import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { PrimaryText } from './Text';

/** A tab bar container. */
export const TabBarContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 16px;

  ${(props) =>
    props.fullWidth &&
    css`
      box-sizing: border-box;
      justify-content: stretch;

      > ${() => TabBarValue} {
        flex-grow: 1;
        flex-basis: 0;
        flex-shrink: 0;
        text-align: center;
      }
    `}
`;

/** One option in the tab bar. Can be marked `active` if selected. */
export const TabBarValue = styled(PrimaryText)`
  cursor: pointer;
  padding: 10px 0;
  color: ${({ active, theme }) =>
    active ? '#8B33F7' : theme.palette.text.paragraph};
  border-bottom: 2px solid
    ${({ active, theme }) => (active ? '#8B33F7' : 'rgba(0, 0, 0, 0)')};

  ${({ active, theme }) =>
    active &&
    css`
      &:hover {
        border-color: ${theme.palette.text.paragraph};
      }
    `}
`;

/** A tab bar used for selecting pages by tab. */
export const TabBar = ({
  /** Possible tab values (an array of strings). */
  tabs,
  /** The initial selected tab. */
  initialSelectedTab,
  /** Handler for when a different tab is selected. */
  onSelectTab,
  /** Whether the TabBar should extend to the full width of its container. */
  fullWidth = false,
}) => {
  const [currentTab, setCurrentTab] = useState(initialSelectedTab);

  const handleSelectTab = useCallback(
    (value) => {
      if (value === currentTab) {
        return;
      } else {
        setCurrentTab(value);
        onSelectTab(value);
      }
    },
    [onSelectTab, currentTab, setCurrentTab]
  );

  return (
    <div className="flex gap-4">
      {tabs.map((tab) => (
        <TabBarValue
          key={tab}
          onClick={() => handleSelectTab(tab)}
          active={tab === currentTab}
        >
          {tab}
        </TabBarValue>
      ))}
    </div>
  );
};
