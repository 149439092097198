import React from 'react';

import { RichTextBoldIcon } from '../../icons/RichTextBoldIcon';
import { RichTextItalicIcon } from '../../icons/RichTextItalicIcon';
import { RichTextUnderlineIcon } from '../../icons/RichTextUnderlineIcon';

import ToolbarButton from './ToolbarButton';
import ToolbarDropdown from './ToolbarDropdown';
import { RichTextStrikeThroughIcon } from '../../icons/RichTextStrikeThroughIcon';

import {
  getColorsOptions,
  fontFamiliesOptions,
  fontSizesOptions,
  customStylesPrefixes,
} from '../helpers';

import ColorPicker from './ColorPicker';

const INLINE_STYLES = [
  { label: 'Bold', Icon: RichTextBoldIcon, style: 'BOLD' },
  { label: 'Italic', Icon: RichTextItalicIcon, style: 'ITALIC' },
  { label: 'Underline', Icon: RichTextUnderlineIcon, style: 'UNDERLINE' },
  {
    label: 'Strikethrough',
    Icon: RichTextStrikeThroughIcon,
    style: 'STRIKETHROUGH',
  },
];

const findValueSelected = (currentInlineStyle, options) =>
  options.find((option) => currentInlineStyle.has(option.value));

const toOption = (value, label = value) => ({
  value,
  label,
});

/* This component renders inline style controls (such as colors, font sizes etc.) that are used with draft js library */
export const InlineStyleControls = React.memo(
  ({ editorState, onToggle, onSelect, initialValues, bestPosition }) => {
    const currentStyle = editorState.getCurrentInlineStyle();

    return (
      <>
        <ToolbarDropdown
          selected={findValueSelected(currentStyle, fontFamiliesOptions)}
          initialSelected={toOption(initialValues.fontFamily)}
          options={fontFamiliesOptions}
          onSelect={onSelect}
          menuPosition={bestPosition}
          prefix={customStylesPrefixes.FONT_FAMILY}
        />

        <ToolbarDropdown
          selected={findValueSelected(currentStyle, fontSizesOptions)}
          initialSelected={toOption(initialValues.fontSize)}
          options={fontSizesOptions}
          onSelect={onSelect}
          menuPosition={bestPosition}
          prefix={customStylesPrefixes.FONT_SIZE}
        />
        <ColorPicker
          onSelect={onSelect}
          selected={findValueSelected(currentStyle, getColorsOptions())}
          initialSelected={toOption(initialValues.color)}
        />
        {INLINE_STYLES.map((type) => (
          <ToolbarButton
            key={type.label}
            active={currentStyle.has(type.style)}
            label={type.label}
            Icon={type.Icon}
            onToggle={onToggle}
            style={type.style}
          />
        ))}
      </>
    );
  }
);
