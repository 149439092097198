import { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchTemplatesApi } from '../helper/api';
import { useUserWorkspaces } from './useUserWorkspaces';
import { userPersonas as personasMap } from '../helper/categories';
import { exponentialFetchRetry } from '../helper/exponentialFetchRetry';
import { useClientWorkspaceAccess } from './useClientWorkspaceAccess';
import { getTrialDateUntil } from 'zync-common/zyncCustomerPlans';
import { episodeTypes } from 'zync-common/types';
import { WorkspaceContext } from '../App';
import { useWorkspacePlan } from './useWorkspacePlan';

/* add custom properties for specific needs, such as hueRotation as per design */
const decorateTemplate = (template) => {
  return {
    ...template,
    hueRotation: 0,
  };
};

/**
 * There is a subset of templates that is matched to a given user, based on
 * that user's personas.
 *
 * If that subset is less than 'minimumRequiredTemplates', then 'backupTemplates' is used as a filler.
 * This ensures that the template selection on the Portal page is never short/empty.
 *
 */
export const backupTemplateData = [
  { key: 'company_all_hands' }, //company all hands
  { key: 'employee_onboarding' }, //employee onboarding
  { key: 'personal_networking' }, //personal networking
  { key: 'sprint_retrospective' }, //sprint retrospective
  { key: 'daily_standup' }, //daily standup
  { key: 'masterclass' }, //masterclass
  { key: 'panel_event' }, //panel event
  { key: 'marketing_webinar' }, //webinar
];

export const fetchUserSeriesApi = async (workspaceId, emailAddress) => {
  return exponentialFetchRetry({
    fetchHandler: async () => {
      let query = `/api/meetingseries?emailAddress=${encodeURIComponent(
        emailAddress
      )}&type=moderator`;
      if (workspaceId) {
        query += `&workspaceId=${encodeURIComponent(workspaceId)}`;
      }
      const response = await fetch(query, {});
      return response;
    },
    jsonResponseHandler: async (response) => {
      const json = await response.json();
      if (json.error) {
        return { error: json.error, result: [] };
      }
      return json.result;
    },
    attributesToLog: {
      userId: emailAddress,
    },
  });
};

export const isInCurrentMonth = (date) => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const inputMonth = date.getMonth();
  const inputYear = date.getFullYear();

  return inputMonth === currentMonth && inputYear === currentYear;
};

export const usePortal = () => {
  const { workspace } = useContext(WorkspaceContext);
  const history = useHistory();
  const { authenticated, user } = useSelector((_st) => _st.auth);
  const [authMode, setAuthMode] = useState();
  const [redirectToLanding, setRedirectToLanding] = useState(false);
  const { emailAddress, consents } = user || {};
  const { acceptedPrivacyPolicy = false } = consents || {};
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(
    !acceptedPrivacyPolicy
  );
  const [templatesList, setTemplatesList] = useState([]);

  const [seriesListLoading, setSeriesListLoading] = useState(false);
  const [seriesList, setSeriesList] = useState([]);

  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const {
    brandKit,
    show,
    currentWorkspaceId: workspaceId,
    isWorkspaceOnboardingRequired,
    isWorkspaceAdmin,
    isWorkspaceSuperAdmin,
    isWorkspaceHiddenAdmin,
    currentWorkspace,
    currentWorkspaceName,
    hostName,
    workspacePlanValidUntil,
    creatorUserName,
    creatorUserId,
    isFirstWorkspaceSolo,
  } = useUserWorkspaces();

  const { isTrial, plan, currentPeriodEnd } = useWorkspacePlan();

  useClientWorkspaceAccess();

  const currentSubscriptionRenewalTime = currentPeriodEnd || 0;

  const navigateToGallery = useCallback(() => {
    history.push('/templates?workspaceId=' + workspaceId);
  }, [history, workspaceId]);

  const fetchNewMeetings = useCallback(
    async (doNotDisplayLoadingSpinner = false) => {
      if (!emailAddress) return;
      // Ensure that a workspace was selected before loading meetings.
      if (!workspaceId) return;
      !doNotDisplayLoadingSpinner && setSeriesListLoading(true);
      let result = (await fetchUserSeriesApi(workspaceId, emailAddress)) || [];

      if (Array.isArray(result)) {
        setSeriesList(result);

        const someEpisodesArePlaceholders = result.some(
          (series) => series.settings.episodeType === episodeTypes.placeholder
        );

        if (someEpisodesArePlaceholders) {
          const doNotDisplayLoadingSpinner = true;
          setTimeout(() => fetchNewMeetings(doNotDisplayLoadingSpinner), 5_000);
        }
      }

      setSeriesListLoading(false);
    },
    [workspaceId, emailAddress]
  );

  useEffect(() => {
    if (authenticated) {
      setAuthMode(null);
      fetchNewMeetings();
    } else setAuthMode('signin');
  }, [authenticated, fetchNewMeetings, workspaceId]);

  /**
   * Return true if at least one category in the template
   * matches a category associated with the user's personas, otherwise false.
   */
  const isUserAndTemplateCategoriesMatching = (
    userCategories,
    templateCategories
  ) => {
    for (let userCategory of userCategories) {
      const foundMatchingCategory = templateCategories.find(
        (templateCategory) => templateCategory === userCategory
      );
      if (foundMatchingCategory) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    const { personas } = user;
    const minimumRequiredTemplates = 8;

    let userCategories = [];
    personas.forEach((persona) => {
      for (const [key, value] of Object.entries(personasMap)) {
        const { templateCategories } = value;
        if (key === persona) {
          userCategories = [...userCategories, ...templateCategories];
        }
      }
    });

    /**
     *  Fetch templates, filter by user's categories, sort by display importance.
     *  If minimum number of templates cannot be generated, use templates that match
     *  the backup categories.
     */
    const fetchAndOrganizeTemplates = async () => {
      let templates = await fetchTemplatesApi();

      templates = templates.filter(
        (template) => template.visibility === 'public'
      );

      let matchingTemplates = templates
        .filter((template) =>
          isUserAndTemplateCategoriesMatching(
            userCategories,
            template.categories
          )
        )
        .slice()
        .sort((a, b) => a.displayImportance >= b.displayImportance);

      if (matchingTemplates?.length < minimumRequiredTemplates) {
        const backupTemplates = templates
          .filter((template) => {
            for (let obj of backupTemplateData) {
              if (obj?.key === template.templateKey) {
                return true;
              }
            }
            return false;
          })
          .slice()
          .sort((a, b) => a.displayImportance >= b.displayImportance);
        matchingTemplates = [...matchingTemplates, ...backupTemplates];
      }

      const decoratedTemplates = matchingTemplates.map(decorateTemplate);
      setTemplatesList(decoratedTemplates);
    };

    fetchAndOrganizeTemplates();
  }, [user]);

  const isAdmin = isWorkspaceAdmin || isWorkspaceSuperAdmin;

  return {
    authMode,
    authenticated,
    isWorkspaceOnboardingRequired,
    isFirstWorkspaceSolo,
    redirectToLanding,
    seriesList,
    seriesListLoading,
    setSeriesListLoading,
    templatesList,
    setRedirectToLanding,
    user,
    navigateToGallery,
    selectedTemplate,
    setSelectedTemplate,
    isAdmin,
    currentWorkspaceName,
    hostName,
    brandKit,
    workspaceId,
    isTrial,
    plan,
    show,
    soloSettings: workspace?.soloSettings || {},
    trialUntil: getTrialDateUntil(currentWorkspace),
    workspacePlanValidUntil,
    currentSubscriptionRenewalTime,
    showPrivacyPolicyModal,
    setShowPrivacyPolicyModal,
    workspacePlan: currentWorkspace?.workspacePlan,
    creatorUserName,
    creatorUserId,
    fetchNewMeetings,
    requiresOnboarding:
      currentWorkspace?.requiresOnboarding && !isWorkspaceHiddenAdmin,
  };
};
