import React, { useState, useCallback, useReducer } from 'react';
import { ModalWindow, Modal, ModalBody } from './Modal';
import { TextInput } from './Input';
import { SelectInput } from './Input';
import { Button } from './common/Button';
import {
  createMeetingTemplateApi,
  updateMeetingTemplateApi,
} from '../helper/api';
import { logerror } from '../helper/contextualLogger';
import { CheckBox } from './common/CheckBox';
import { getFormattedCategoryName } from '../hooks/useTemplateGallery';
import { RadioButton } from './common/RadioButton';
import { demoUsers } from '../helper/constants';
import { categories as categoryChoices } from '../helper/categories';
import { PencilIconTwo } from './common/Icon';
import classNames from '../helper/classNames';
import { convertBlockSettings } from './authoring/hooks';

const visibilityOptions = ['public', 'private'];

const displayImportanceChoices = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
];

const fakeUsersReducer = (state, action) => {
  const { key, userId, userName, fakeVideoStream, currentRoleIdx, role } =
    action.payload || {};

  switch (action.type) {
    case 'ADD_USER': {
      let updatedUsers = {
        ...state,
        [key]: { userId: key, userName: '', fakeVideoStream: '', roles: [] },
      };
      return updatedUsers;
    }
    case 'UPDATE_USER_ID': {
      let updatedUser = { ...state[key] };
      updatedUser = { ...updatedUser, userId };
      return { ...state, [key]: updatedUser };
    }
    case 'UPDATE_USERNAME': {
      let updatedUser = { ...state[key] };
      updatedUser = { ...updatedUser, userName };
      return { ...state, [key]: updatedUser };
    }
    case 'UPDATE_FAKE_VIDEO_STREAM': {
      let updatedUser = { ...state[key] };
      updatedUser = { ...updatedUser, fakeVideoStream };
      return { ...state, [key]: updatedUser };
    }
    case 'UPDATE_ROLES': {
      let updatedUser = { ...state[key] };
      updatedUser['roles'][currentRoleIdx] = role;
      return { ...state, [key]: updatedUser };
    }
    case 'REMOVE_ROLE': {
      let updatedUser = { ...state[key] };
      const updatedRoles = updatedUser['roles'].filter(
        (_, idx) => currentRoleIdx !== idx
      );
      updatedUser = { ...updatedUser, roles: updatedRoles };
      return { ...state, [key]: updatedUser };
    }
    case 'ADD_ROLE': {
      let updatedUser = { ...state[key] };
      const updatedRoles = [...updatedUser['roles'], ''];
      updatedUser = { ...updatedUser, roles: updatedRoles };
      return { ...state, [key]: updatedUser };
    }
    case 'REMOVE_USER': {
      const updatedUsers = { ...state };
      for (let currKey in updatedUsers) {
        if (currKey === key) {
          delete updatedUsers[currKey];
        }
      }
      return updatedUsers;
    }
    default:
      throw new Error(
        'fakeUsersReducer in ManageMeetingTemplateModal.jsx (Invalid action type) - ' +
          action.type
      );
  }
};

export const ManageMeetingTemplateModal = ({
  onCancel,
  autoLaunchConfig,
  meetingSeriesId,
  userId,
  template = null,
  brandKit,
}) => {
  const [editingUser, setEditingUser] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState(
    template ? template.categories : []
  );

  const [templateName, setTemplateName] = useState(
    template ? template.templateName : ''
  );
  const [templateDescription, setTemplateDescription] = useState(
    template ? template.templateDescription : ''
  );
  const [templateImageUrl, setTemplateImageUrl] = useState(
    template ? template.templateImageUrl : ''
  );
  const [visibility, setVisibility] = useState(
    template ? template.visibility : visibilityOptions[0]
  );
  const [displayImportance, setDisplayImportance] = useState(
    template
      ? template.displayImportance.toString()
      : displayImportanceChoices[0]
  );
  const [templateKey, setTemplateKey] = useState(
    template ? template.templateKey : ''
  );
  const [lastError, setLastError] = useState('');
  const { slides = [] } = template ? template : autoLaunchConfig || {};

  const [fakeUsers, dispatchFakeUsers] = useReducer(
    fakeUsersReducer,
    demoUsers
  );

  const isCategorySelected = useCallback(
    (categoryTarget) => {
      return categories.some((category) => category === categoryTarget);
    },
    [categories]
  );

  const handleChangeCategories = useCallback(
    (value, category) => {
      if (value) {
        setCategories([...categories, category]);
      } else {
        setCategories(categories.filter((c) => c !== category));
      }
    },
    [categories]
  );

  const onCreateOrUpdateMeetingTemplate = async (e) => {
    e.preventDefault();
    setLastError('');
    setIsLoading(true);

    if (slides.length === 0) {
      setIsLoading(false);
      setLastError('Templates must have at least one scene.');
      return;
    }

    if (
      categories.length > 0 &&
      templateName.trim().length > 0 &&
      templateDescription.trim().length > 0 &&
      templateImageUrl.trim().length > 0 &&
      templateKey.trim().length > 0
    ) {
      // ensure blocks variables are updated back to dynamic values when applicable
      const unflattenedSlides = slides.map((slide) => ({
        ...slide,
        slideConfig: {
          ...slide.config,
          slideBlocks: slide.slideConfig.slideBlocks.map((block) => ({
            ...block,
            settings: convertBlockSettings(
              block.settings,
              brandKit,
              block.blockId
            ),
          })),
        },
      }));

      try {
        const backdropUrls = Object.keys(
          (slides ?? []).reduce(
            (urls, current) => ({
              ...urls,
              [current.slideUrl]: true,
            }),
            {}
          )
        );

        const imports = [
          {
            title: 'Template Backdrops',
            slides: backdropUrls,
          },
        ];
        const fakeUsersList = Object.values(fakeUsers);

        if (template) {
          const { error } = await updateMeetingTemplateApi(
            categories,
            templateName,
            templateDescription,
            templateImageUrl,
            visibility,
            templateKey,
            imports,
            unflattenedSlides,
            parseInt(displayImportance, 10),
            userId,
            template.templateId,
            fakeUsersList
          );

          error ? setLastError(error) : onCancel();
        } else {
          const { error } = await createMeetingTemplateApi(
            categories,
            templateName,
            templateDescription,
            templateImageUrl,
            visibility,
            templateKey,
            imports,
            unflattenedSlides,
            parseInt(displayImportance, 10),
            meetingSeriesId,
            userId,
            fakeUsersList
          );

          error ? setLastError(error) : onCancel();
        }
      } catch (error) {
        logerror({
          userId,
          meetingSeriesId,
          message:
            `Caught exception attempting to ${
              template ? 'update' : 'create'
            } meeting template: ` + error.message,
          stacktrace: error.stack || error,
        });
        setLastError('Internal server error');
      }
    } else {
      setIsLoading(false);
      setLastError('All inputs must be filled out.');
      return;
    }
    setIsLoading(false);
  };

  return (
    <ModalWindow onCancel={onCancel} size={Modal.size.lg}>
      <div className="max-h-[80vh] overflow-auto">
        <ModalBody>
          <form
            className="flex flex-col"
            onSubmit={onCreateOrUpdateMeetingTemplate}
          >
            <div className="flex flex-col">
              <div className="text-blue-dark font-medium text-sm mb-2">
                Categories
              </div>
              <div className="flex flex-wrap gap-4">
                {Object.values(categoryChoices).map((category) => (
                  <CheckBox
                    label={getFormattedCategoryName(category)}
                    value={isCategorySelected(category)}
                    onChange={(value) =>
                      handleChangeCategories(value, category)
                    }
                    key={category}
                  />
                ))}
              </div>
            </div>
            <TextInput
              label="Template name"
              value={templateName}
              onChange={(event, value) => setTemplateName(value)}
            />
            <TextInput
              label="Template description"
              value={templateDescription}
              onChange={(event, value) => setTemplateDescription(value)}
            />
            <TextInput
              label="Template image url"
              value={templateImageUrl}
              onChange={(event, value) => setTemplateImageUrl(value)}
            />
            <TextInput
              label="Template key"
              value={templateKey}
              onChange={(event, value) => setTemplateKey(value)}
            />
            <div className="w-fit">
              <SelectInput
                label="Visibility"
                options={visibilityOptions}
                flat
                horizontal
                defaultValue={visibility}
                onChange={(value) => setVisibility(value)}
              />
            </div>
            <div className="flex flex-col mb-5">
              <div className="text-blue-dark font-medium text-sm mb-2">
                Display importance (highest - lowest)
              </div>
              <div className="flex flex-wrap gap-4">
                {displayImportanceChoices.map((importance) => (
                  <RadioButton
                    key={'importance' + importance}
                    value={importance}
                    name="displayImportance"
                    checked={displayImportance === importance}
                    onChange={(value) => setDisplayImportance(value)}
                    label={importance}
                  />
                ))}
              </div>
            </div>
            {fakeUsers && (
              <div className="">
                <span className="text-blue-dark font-medium text-sm inline-block mb-5">
                  Template Bot Information
                </span>
                <div className="pl-3 pt-2 border border-purple border-solid rounded-md mb-5 h-80 overflow-auto">
                  <div className="text-purple text-sm font-semibold ">
                    Update Existing Users:
                  </div>
                  <div className="min-w-full flex flex-wrap">
                    {Object.entries(fakeUsers)?.map((fakeUser) => {
                      return (
                        <FakeUser
                          dispatchFakeUsers={dispatchFakeUsers}
                          fakeUserKey={fakeUser[0]}
                          fakeUser={fakeUser[1]}
                          setEditingUser={setEditingUser}
                          editingUser={editingUser}
                        />
                      );
                    })}
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="bg-purple p-1 rounded-md bg-opacity-10 text-sm font-semibold text-purple">
                      Or
                    </div>
                    <div className="my-3">
                      <Button
                        onClick={() => {
                          const id = Math.floor(
                            Math.random() * 1000
                          ).toString();
                          setEditingUser({
                            user: {
                              userId: id,
                              userName: '',
                              fakeVideoStream: '',
                              roles: [],
                            },
                            key: id,
                          });
                          dispatchFakeUsers({
                            type: 'ADD_USER',
                            payload: {
                              key: id,
                            },
                          });
                        }}
                        color={Button.colors.PURPLE}
                        padding={Button.padding.SMALL}
                      >
                        <div className="text-sm">&#x2b; Add a new user</div>
                      </Button>
                    </div>
                  </div>
                  {editingUser && (
                    <FakeUserInfo
                      dispatchFakeUsers={dispatchFakeUsers}
                      editingUser={editingUser}
                      setEditingUser={setEditingUser}
                    />
                  )}
                </div>
              </div>
            )}

            <div className="flex w-full gap-5">
              <Button
                color={Button.colors.PURPLE}
                size={Button.sizes.FULL}
                type="submit"
                state={loading ? Button.states.LOADING : Button.states.DEFAULT}
              >
                {template ? 'Update' : 'Create'}
              </Button>
              <Button onClick={onCancel} size={Button.sizes.FULL}>
                Cancel
              </Button>
            </div>
            {!!lastError.length && (
              <div className="text-red font-bold text-lg">{lastError}</div>
            )}
            {template && (
              <div className="font-bold text-lg uppercase">
                this will change the existing template {template.templateName}
              </div>
            )}
          </form>
        </ModalBody>
      </div>
    </ModalWindow>
  );
};

const FakeUserInfo = ({ dispatchFakeUsers, setEditingUser, editingUser }) => {
  const { key, user } = editingUser || {};

  return (
    <div className="p-3 pb-8">
      {user && (
        <div className="text-sm text-blue-gray font-medium">
          You are editing the user with userId:
          <span className="text-purple"> {user?.userId}</span>
        </div>
      )}

      <TextInput
        label="userId"
        value={user?.userId || ''}
        onChange={(e) => {
          setEditingUser({ user: { ...user, userId: e.target.value }, key });
          dispatchFakeUsers({
            type: 'UPDATE_USER_ID',
            payload: {
              key,
              userId: e.target.value,
            },
          });
        }}
      />
      <TextInput
        label="userName"
        value={user?.userName || ''}
        onChange={(e) => {
          setEditingUser({
            user: { ...user, userName: e.target.value },
            key,
          });
          dispatchFakeUsers({
            type: 'UPDATE_USERNAME',
            payload: {
              key,
              userName: e.target.value,
            },
          });
        }}
      />
      <TextInput
        label="fakeVideoStream"
        value={user?.fakeVideoStream || ''}
        onChange={(e) => {
          setEditingUser({
            user: { ...user, fakeVideoStream: e.target.value },
            key,
          });
          dispatchFakeUsers({
            type: 'UPDATE_FAKE_VIDEO_STREAM',
            payload: {
              key,
              fakeVideoStream: e.target.value,
            },
          });
        }}
      />
      <div>
        <span className="text-blue-dark font-medium text-sm">Roles</span>
        {(user?.roles || []).map((role, currentRoleIdx) => (
          <div className="flex items-center gap-x-2">
            <TextInput
              value={role}
              onChange={(e) => {
                let newRoles = [...user?.roles];
                newRoles[currentRoleIdx] = e.target.value;
                setEditingUser({
                  user: { ...user, roles: newRoles },
                  key,
                });
                dispatchFakeUsers({
                  type: 'UPDATE_ROLES',
                  payload: {
                    key,
                    currentRoleIdx,
                    role: e.target.value,
                  },
                });
              }}
            />
            <div
              onClick={() => {
                let newRoles = user?.roles.filter(
                  (_, idx) => idx !== currentRoleIdx
                );
                setEditingUser({
                  user: { ...user, roles: newRoles },
                  key,
                });
                dispatchFakeUsers({
                  type: 'REMOVE_ROLE',
                  payload: {
                    key,
                    fakeUser: { ...user, roles: [...newRoles] },
                  },
                });
              }}
              className=" text-xl rounded-sm bg-red text-white h-5 w-5 flex justify-center items-center cursor-pointer"
            >
              <span>&#8722;</span>
            </div>
          </div>
        ))}
        <div className="mt-2">
          <Button
            onClick={() => {
              setEditingUser({
                user: { ...user, roles: [...user?.roles, ''] },
                key,
              });
              dispatchFakeUsers({
                type: 'ADD_ROLE',
                payload: {
                  key,
                },
              });
            }}
            color={Button.colors.PURPLE}
            padding={Button.padding.SMALL}
          >
            <div className="text-sm">&#x2b; Add role</div>
          </Button>
        </div>
      </div>
    </div>
  );
};

const FakeUser = ({
  fakeUser,
  fakeUserKey,
  dispatchFakeUsers,
  setEditingUser,
  editingUser,
}) => {
  return (
    <div className="m-3 relative">
      <div className="absolute top-2 left-2 z-10">
        <div
          onClick={() => {
            if (editingUser?.key === fakeUserKey) {
              setEditingUser(null);
            }
            dispatchFakeUsers({
              type: 'REMOVE_USER',
              payload: { key: fakeUserKey },
            });
          }}
          className=" text-xl rounded-sm bg-red text-white h-4 w-4 flex justify-center items-center cursor-pointer"
        >
          <span>&#8722;</span>
        </div>
      </div>
      <div className="absolute top-2 left-7 z-10">
        <div
          onClick={() => {
            setEditingUser({ user: fakeUser, key: fakeUserKey });
          }}
          className=" text-xl rounded-sm h-4 w-4 flex justify-center items-center cursor-pointer bg-white"
        >
          <PencilIconTwo color="white" width="12px" height="12px" />
        </div>
      </div>

      <div
        className={classNames(
          editingUser?.key === fakeUserKey
            ? 'bg-purple bg-opacity-50 rounded-md duration-100'
            : 'bg-red bg-opacity-10 rounded-md'
        )}
      >
        <video
          className="w-36 rounded-lg p-1"
          src={fakeUser?.fakeVideoStream}
          alt={fakeUser.userName}
        />
      </div>
    </div>
  );
};
