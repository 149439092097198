import Lottie from 'react-lottie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useStartLoading } from '../../../../hooks/useStartLoading';
import { Button } from '../../../common/Button';
import MediaButton from '../MediaButton';
import CurrentMeetingRecordings from './CurrentMeetingRecordings';
import RecordingTimer from './RecordingTimer';
import { SignUpModal } from '../../SignUpModal';
import activeRecordingAnimation from '../../Live meeting icons/Record_Active.json';
import recordAnimation from '../../Live meeting icons/Record.json';
import { animationOptions } from '../../meetingControlAnimationOptions';

const RECORDING_SETUP_TIMEOUT = 60_000;
const ACTION_BUTTON_URL = 'https://app.zync.ai';

export const ConfirmationPopup = ({ children, handleClose, isOpen }) => {
  return (
    isOpen && (
      <div
        role="alert"
        className="absolute top-0 -translate-y-[calc(100%+30px)] bg-white p-8 shadow-400 rounded-lg w-[400px]"
      >
        {handleClose && (
          <button
            className="absolute top-8 right-8 -translate-y-1"
            onClick={handleClose}
          >
            <FontAwesomeIcon icon="times" color="#8F95B2" size="1x" />
          </button>
        )}
        {children}
      </div>
    )
  );
};

const MediaRecordButton = ({
  isDisabled,
  onRecordingStart,
  onRecordingStop,
  isRecording,
  isStreaming,
  recordingStartTime,
  areMuxActionsDisabled,
  isQuickStartEnabled,
  setActionButtonClicked,
  meetingId,
  userId,
  setIsRecording,
  setIsStreaming,
  isPreview,
}) => {
  const {
    onClickWithLoading: onRecordingStartWithLoading,
    isLoading: isStartingRecording,
  } = useStartLoading(
    onRecordingStart,
    recordingStartTime || isRecording,
    RECORDING_SETUP_TIMEOUT
  );

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [isRecordAnimationStopped, setIsRecordAnimationStopped] =
    useState(true);

  const hasRecordingStarted =
    Boolean(recordingStartTime) && (isStreaming || isRecording);

  return (
    <div className="relative">
      <MediaButton
        disabled={isDisabled}
        onClick={() => {
          if (isQuickStartEnabled) {
            setShowSignUpModal(true);
          } else if (isPreview) {
            setIsPopupOpen((wasOpen) => !wasOpen);
          } else {
            setIsPopupOpen((wasOpen) => !wasOpen);
          }
        }}
        onMouseEnter={() => setIsRecordAnimationStopped(false)}
        onMouseLeave={() => setIsRecordAnimationStopped(true)}
      >
        <div className="flex flex-col justify-center items-center">
          {isRecording ? (
            <div className="w-8 h-8 text-purple">
              <Lottie
                options={{
                  ...animationOptions,
                  animationData: activeRecordingAnimation,
                  loop: true,
                }}
              />
            </div>
          ) : (
            <div className="w-8 h-8 text-purple">
              <Lottie
                options={{
                  ...animationOptions,
                  animationData: recordAnimation,
                }}
                isStopped={isRecordAnimationStopped}
              />
            </div>
          )}
          <span className="text-purple text-xxs font-medium">
            {isRecording ? (
              <div>
                {(isRecording || isStreaming) && (
                  <RecordingTimer
                    recordingStartTime={recordingStartTime}
                    isLiveStream={isStreaming}
                    setIsRecording={setIsRecording}
                    setIsStreaming={setIsStreaming}
                  />
                )}
              </div>
            ) : (
              <div>Record</div>
            )}
          </span>
        </div>
      </MediaButton>
      <ConfirmationPopup
        isOpen={isPopupOpen}
        handleClose={() => setIsPopupOpen(false)}
      >
        {isPreview ? (
          <div className="text-blue-dark text-xl font-semibold">
            Recording not enabled for rehearsals.
          </div>
        ) : (
          <div className="space-y-10">
            <div className="space-y-2.5">
              <h3 className="text-blue-dark text-xl font-semibold">
                {hasRecordingStarted ? 'Stop Recording' : 'Start Recording'}
              </h3>
              {hasRecordingStarted ? (
                <p className="text-blue-gray text-sm font-normal">
                  All audiences will be notified that this meeting is no longer
                  recorded. You can find a video recording here once it is
                  started, or in the event highlights after it is completed.
                </p>
              ) : (
                <p className="text-blue-gray text-sm font-normal">
                  All audiences will be notified that this meeting is being
                  recorded.
                </p>
              )}
            </div>
            {hasRecordingStarted ? (
              <CurrentMeetingRecordings meetingId={meetingId} userId={userId} />
            ) : null}
            {isRecording && !isStartingRecording ? (
              <Button
                onClick={onRecordingStop}
                color={Button.colors.RED}
                size={Button.sizes.FULL}
                state={!recordingStartTime && Button.states.DISABLED}
              >
                <span className="text-base font-medium">
                  {recordingStartTime ? 'Stop Recording' : 'Setting up...'}
                </span>
              </Button>
            ) : (
              <Button
                onClick={() => {
                  setActionButtonClicked('Recording');
                  onRecordingStartWithLoading();
                }}
                color={Button.colors.PURPLE}
                size={Button.sizes.FULL}
                state={
                  isStartingRecording
                    ? Button.states.LOADING
                    : areMuxActionsDisabled
                    ? Button.states.DISABLED
                    : Button.states.DEFAULT
                }
              >
                <span className="text-base font-medium">Start Recording</span>
              </Button>
            )}
          </div>
        )}
      </ConfirmationPopup>
      {showSignUpModal && (
        <SignUpModal
          title="Ready to Record your meeting?"
          body="With one click, you can record everything in your meeting just as you see it here! This includes your theme, background and all interactions. Once recorded, you can rewatch it right here on Zync! Sign up today and record your meetings now."
          buttonText="Sign Up"
          handleSubmit={() => window.open(ACTION_BUTTON_URL)}
          handleCancel={() => setShowSignUpModal(false)}
        />
      )}
    </div>
  );
};

export default MediaRecordButton;
