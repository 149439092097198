import React, { useCallback, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import MediaButton from './MediaButton';
import Lottie from 'react-lottie';
import questionsAnimation from '../Live meeting icons/Questions.json';
import { animationOptions } from '../meetingControlAnimationOptions';

const MediaQuestionsButton = () => {
  const dispatch = useDispatch();
  const [isQuestionsAnimationStopped, setIsQuestionsAnimationStopped] =
    useState(true);

  const pendingQuestionsCount = useSelector(
    (state) =>
      (state.meetingState.state?.eventQuestions || []).filter(
        (question) => !question.answered && !question.dismissed
      ).length,
    shallowEqual
  );

  const liveTab = useSelector((st) => st.clientDetails.liveTab);

  const isQuestionsTabActive = liveTab === 'questions';

  const handleToggleQuestions = useCallback(() => {
    dispatch({
      type: 'SELECT_LIVE_TAB',
      tab: isQuestionsTabActive ? null : 'questions',
    });
  }, [dispatch, isQuestionsTabActive]);

  return (
    <MediaButton
      disabled={false}
      className="cursor-pointer"
      onClick={handleToggleQuestions}
      isSelected={isQuestionsTabActive}
      onMouseEnter={() => setIsQuestionsAnimationStopped(false)}
      onMouseLeave={() => setIsQuestionsAnimationStopped(true)}
    >
      <div className="relative">
        {pendingQuestionsCount > 0 && (
          <div
            className={`absolute bg-red text-white -top-[8px] -right-[4px] text-xxs font-bold leading-[15px] text-center rounded-[12px] flex items-center justify-center ${
              pendingQuestionsCount <= 10 ? 'w-[15px]' : 'px-1'
            }`}
          >
            {pendingQuestionsCount || 0}
          </div>
        )}
        <div className=" flex flex-col justify-center items-center">
          <div className="w-8 h-8 text-purple">
            <Lottie
              options={{
                ...animationOptions,
                animationData: questionsAnimation,
              }}
              isStopped={isQuestionsAnimationStopped}
            />
          </div>
          <span className="text-purple text-xxs font-medium">Questions</span>
        </div>
      </div>
    </MediaButton>
  );
};

export default MediaQuestionsButton;
