export const lightTheme = {
  type: 'light', // implementing in case of plans for supporting dark theme / other themes in future
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg'],
    values: {
      xs: 480,
      sm: 768,
      md: 1024,
      lg: 1280,
    },
  },
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    primary: {
      main: '#00A3A5',
      main2: '#8B33F7', // soon to replace 'main' color
      light: '#00A3A5',
      dark: '#00A3A5',
      neutral: '#8ea0aa',
      contrastText: '#fff',
    },
    secondary: {
      main: '#C8ECA1',
      light: '#C8ECA1',
      dark: '#C8ECA1',
      contrastText: '#fff',
    },
    error: {
      light: '#e57373',
      main: '#dB2929',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    warning: {
      light: '#ffb74d',
      main: '#ff9800',
      dark: '#f57c00',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    info: {
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2',
      contrastText: '#fff',
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      primaryContrast: 'rgba(255, 255, 255, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      secondaryContrast: 'rgba(255, 255, 255, 0.54)',
      disabled: '#97A0AF',
      paragraph: '#696F8C',
      placeholder: '#97A0AF',
      label: '#84818A',
      explanation: 'rgba(0, 0, 0, 0.33)',
      primaryHeadline: '#101840',
    },
    border: {
      field: '#EBEAED',
      hover: '#00a3a5',
      disabled: '#DFE1E6',
      neutral: '#696F8C',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    background: {
      default: '#fff',
      disabled: '#F4F5F7',
      field: '#FBFAFC',
      hover: '#fff',
      selectHover: '#ebe6f0',
      formFillStroke: '#EBEAED',
    },
    action: {},
  },
  shadows: {
    400: '0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08)',
    800: '0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14)',
  },
  spacing: (num) => num * 8,
  typography: {
    fontFamily: {
      regular: 'Poppins, Arial, sans-serif',
    },
    fontWeight: {
      light: 300,
      regular: 400,
      medium: 500,
      bold: 600,
      bolder: 700,
    },
    fontSize: {
      small: 12,
      regular: 14,
      regular15: 15,
      medium: 16,
      titleHeavy: 20,
      h1: 48,
    },
    lineHeight: {
      small: 18,
      regular: 21,
      medium: 24,
      h1: 64,
    },
  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      standard: 300,
    },
  },
  borderRadius: {
    light: 2,
    field: 4,
    tooltip: 8,
    faqCard: 24,
  },
  zIndex: {
    floatingReaction: 10,
    draggedItem: 1000,
    sideMenuInner: 1098,
    sideMenuOuter: 1099,
    topBar: 1100,
    modal: 1200,
    tooltip: 1300,
  },
  direction: 'ltr',
};
