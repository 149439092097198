import React, { useCallback, useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { formatDistanceToNow } from 'date-fns';
import { Button } from '../../common/Button';
import { H5Text, CaretText, CaretTextContainer } from '../../common/Text';
import {
  getUploadedSeriesImages,
  uploadSeriesSlideImage,
} from '../../../helper/api';
import { useAuthoringTool } from '../hooks';
import { ThumbnailGrid } from '../../common/Container';
import { ActionButton } from '../../common/ActionButton';
import { ImageUploader, readContents } from '../../ImageUploader';
import { TextInput } from '../../Input/TextInput';
import { TabBar } from '../../common/TabBar';
import { getMeetingSeriesProps } from 'zync-common/tracking';
import mixpanel from 'mixpanel-browser';
import { GoogleSlidesIcon } from '../../icons/GoogleSlidesIcon';
import { PlusIcon } from '../../icons/PlusIcon';
import { RefreshIconTwo } from '../../icons/RefreshIconTwo';
import { removeBackground as imglyRemoveBackground } from '@imgly/background-removal';

const rotateKeyframes = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const rotateAnimation = ({
  easingFunction = 'linear',
  duration = '2s',
}) => css`
  animation: ${rotateKeyframes} ${duration} ${easingFunction} infinite;
`;

export const DropdownHeader = styled(H5Text)`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  > ${() => CaretTextContainer} {
    width: 17px;
    flex-basis: 0;
  }
  > svg {
    flex-shrink: 0;
    flex-basis: 15px;
  }
`;

const tabs = ['Uploaded', 'Google Slides', 'Gallery'];

/** The content of the backdrop menu. */
export const BackdropMenu = () => {
  const {
    currentSeries,
    currentSeries: {
      autoLaunchConfig: { imports },
    },
    defaultBackdrops,
    updateTemplateSlide,
    addImagesAsTemplateSlides,
    importGoogleSlides,
    currentBackdropTab,
    setCurrentBackdropTab,
  } = useAuthoringTool();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleUploadComplete = useCallback(async () => {
    mixpanel.track('Authoring - Backdrop Upload Complete', {
      ...getMeetingSeriesProps(currentSeries),
    });
    try {
      const images = await getUploadedSeriesImages(
        currentSeries.meetingSeriesId
      );
      setImages(images);
    } finally {
      setLoading(false);
    }
  }, [setImages, currentSeries]);

  const handleImportGoogleSlides = useCallback(
    async (presentationId) => {
      setLoading(true);
      try {
        await importGoogleSlides(presentationId);
        mixpanel.track('Authoring - Google Slides Imported', {
          ...getMeetingSeriesProps(currentSeries),
        });
      } finally {
        setLoading(false);
      }
    },
    [setLoading, importGoogleSlides, currentSeries]
  );

  const setBackdrop = useCallback(
    (image) => {
      mixpanel.track('Authoring - Backdrop Selected', {
        mediaUrl: image,
        ...getMeetingSeriesProps(currentSeries),
      });
      updateTemplateSlide(image, 'slideUrl');
    },
    [updateTemplateSlide, currentSeries]
  );

  useEffect(() => {
    getUploadedSeriesImages(currentSeries.meetingSeriesId).then(setImages);
  }, [currentSeries, setImages]);

  const defaultCategories = Object.entries(defaultBackdrops).map(
    ([category, slides]) => ({
      title: category,
      collapsed: false,
      slides,
    })
  );

  const googleSlidesCategories = [
    ...(imports || []).map((slide) => ({
      ...slide,
      collapsed: true,
      onAddAll: () => addImagesAsTemplateSlides(slide.slides),
    })),
  ];

  const uploadedCategories =
    images && images.length > 0
      ? [
          {
            title: 'Uploaded backdrops',
            collapsed: false,
            slides: images.map((image) => image.publicUrl),
          },
        ]
      : [];

  return (
    <>
      <TabBar
        tabs={tabs}
        initialSelectedTab={currentBackdropTab}
        onSelectTab={(tab) => setCurrentBackdropTab(tab)}
        fullWidth
      />

      {currentBackdropTab === 'Uploaded' && (
        <>
          <BackdropUpload
            loading={loading}
            imageNamescape={currentSeries.meetingSeriesId}
            onUploadStart={() => setLoading(true)}
            onUploadComplete={handleUploadComplete}
          />

          {uploadedCategories.map(({ title, slides, timestamp, collapsed }) => (
            <SlideImagesCategory
              key={title}
              title={title}
              slides={slides}
              timestamp={timestamp}
              onAddScenes={undefined}
              onRefreshCategory={undefined}
              initialCollapsed={collapsed}
              onSetBackdrop={setBackdrop}
            />
          ))}
        </>
      )}

      {currentBackdropTab === 'Google Slides' && (
        <>
          <GoogleSlidesImport
            onImportStart={(presentationId) =>
              handleImportGoogleSlides(presentationId)
            }
            loading={loading}
          />
          {googleSlidesCategories.map(
            ({
              title,
              slides,
              presentationId,
              timestamp,
              collapsed,
              onAddAll,
            }) => (
              <SlideImagesCategory
                key={title}
                title={title}
                slides={slides}
                timestamp={timestamp}
                onAddScenes={onAddAll}
                onRefreshCategory={
                  presentationId
                    ? () => handleImportGoogleSlides(presentationId)
                    : undefined
                }
                initialCollapsed={collapsed}
                onSetBackdrop={setBackdrop}
              />
            )
          )}
        </>
      )}

      {currentBackdropTab === 'Gallery' && (
        <>
          {defaultCategories.map(({ title, slides, onAddAll }) => (
            <SlideImagesCategory
              key={title}
              title={title}
              slides={slides}
              onAddScenes={onAddAll}
              onRefreshCategory={undefined}
              initialCollapsed={false}
              onSetBackdrop={setBackdrop}
            />
          ))}
        </>
      )}
    </>
  );
};

/** A collapsible slide image category. */
export const SlideImagesCategory = ({
  /** The title of the category. */
  title,
  /** The slides in the category (an array of image urls). */
  slides,
  /** The timestamp for when the category was last imported. */
  timestamp,
  /** Whether the category is initially collapsed. */
  initialCollapsed = true,
  /** Optional, for Add All To Scenes button, a handler for when this button is clicked. */
  onAddScenes,
  /** Handler for when the user clicks on one scene to set as a backdrop. */
  onSetBackdrop,
  /** Handler for when the user refreshes the import. */
  onRefreshCategory,
}) => {
  const [collapsed, setCollapsed] = useState(initialCollapsed);

  return (
    <>
      <DropdownHeader onClick={() => setCollapsed(!collapsed)} role="button">
        <CaretText direction={collapsed ? 'right' : 'down'} />
        {onRefreshCategory && <GoogleSlidesIcon />}
        {title}
      </DropdownHeader>
      {!collapsed && (
        <>
          {onRefreshCategory && (
            <LastSynced timestamp={timestamp} onRefresh={onRefreshCategory} />
          )}
          {onAddScenes && (
            <AddAllButton
              images={slides}
              onAddScenes={() => onAddScenes(slides)}
            />
          )}
          <ThumbnailGrid
            width={100}
            height={(100 * 9) / 16}
            items={slides.map((image, index) => {
              // originally slides object contained string (URL) of an image.
              // currently ThumbnailGrid is used in few places, but only Backdrop Gallery API was updated
              // the new API returns object ({ imageUrl and thumbnailImageUrl }), therefore we need to perform typeof check here
              const id =
                (typeof image === 'object' ? image.thumbnailImageUrl : image) +
                index;
              const thumbnailImage =
                typeof image === 'object' ? image.thumbnailImageUrl : image;
              const originalImage =
                typeof image === 'object' ? image.imageUrl : image;

              return {
                id,
                image: thumbnailImage,
                onClick: () => onSetBackdrop(originalImage),
              };
            })}
          />
        </>
      )}
    </>
  );
};

/** Button for adding all imported images as new scenes. */
export const AddAllButton = ({ images, onAddScenes }) => {
  return (
    <Button onClick={onAddScenes} color={Button.colors.BLUE}>
      <PlusIcon />
      <span className="text-xs font-medium">
        Add All {images.length} Slides
      </span>
    </Button>
  );
};

/** A form for importing from google slides. */
export const GoogleSlidesImport = ({
  // importing from slides
  onImportStart,
  onImportComplete,

  loading,
}) => {
  const [slidesUrl, setSlidesUrl] = useState('');

  const handleImportGoogleSlides = async () => {
    await onImportStart(slidesUrl);
    if (onImportComplete) {
      onImportComplete();
    }
  };

  return (
    <>
      <TextInput
        label="Google Slides URL"
        value={slidesUrl}
        onChange={(event, value) => setSlidesUrl(value)}
        unit="://"
        explanation="The slide deck must have public permissions to upload."
      />
      <Button
        color={Button.colors.PURPLE}
        onClick={handleImportGoogleSlides}
        state={
          loading
            ? Button.states.LOADING
            : !isValidGoogleSlidesLink(slidesUrl)
            ? Button.states.DISABLED
            : Button.states.DEFAULT
        }
      >
        <span className="text-xs font-medium">Import</span>
      </Button>
    </>
  );
};

const getFileExtension = (name) => {
  const pattern = new RegExp(/\.[0-9a-z]+$/i);

  return name.match(pattern)[0];
};

/** UI functionality for uploading an image from local hard drive. */
export const BackdropUpload = ({
  imageNamescape,

  // uploading from local hard drive
  onUploadStart,
  onUploadComplete,

  loading,

  fileName,

  enforcedAspectRatio,

  onAspectRatioExceeded,
  onFileSizeExceeded,
  appendTimeStampToFile,
  removeImageBackground,
  label,
}) => {
  return (
    <ImageUploader
      disabled={loading}
      loading={loading}
      label={label || 'Upload'}
      multiple
      onImageDimensionsExceeded={onAspectRatioExceeded}
      onFileSizeExceeded={onFileSizeExceeded}
      enforcedAspectRatio={enforcedAspectRatio}
      buttonColor={Button.colors.PURPLE}
      appendTimeStampToFile={appendTimeStampToFile}
      onImageSubmit={async (buffer, type, name) => {
        onUploadStart();

        let fileBufferToUse = buffer;

        if (removeImageBackground) {
          const blob = await imglyRemoveBackground(buffer);
          fileBufferToUse = await readContents(blob);
        }

        const uploadResult = await uploadSeriesSlideImage(
          imageNamescape +
            '/' +
            (fileName ? fileName + getFileExtension(name) : name),
          fileBufferToUse,
          type
        );

        onUploadComplete(uploadResult);
      }}
    />
  );
};

export const LastSyncedContainer = styled.div`
  font-family: Poppins, Inter, sans-serif;
  font-weight: 300;
  strong {
    font-weight: 500;
  }
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #8ea0aa;

  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    margin-right: 10px;
  }

  svg {
    ${(props) =>
      props.isLoading && rotateAnimation({ easingFunction: 'ease-in-out' })}
  }
`;

/** Last Sync'd area */
export const LastSynced = ({
  /** The unix timestamp for when the last sync occurred. */
  timestamp,
  /** An asynchronous handler for when the refresh button is clicked. */
  onRefresh,
}) => {
  const [loading, setLoading] = useState(false);
  const [timeAgo, setTimeAgo] = useState('');

  // re-calculate timeago value every 30 seconds.
  useEffect(() => {
    const calculateValue = () =>
      timestamp
        ? formatDistanceToNow(new Date(timestamp), { addSuffix: true })
        : 'Unknown';

    setTimeAgo(calculateValue());

    const timer = setInterval(() => {
      const value = calculateValue();
      setTimeAgo(value);
    }, 1000);
    return () => clearInterval(timer);
  }, [timestamp, setTimeAgo]);

  const handleRefresh = useCallback(async () => {
    setLoading(true);
    try {
      await onRefresh();
    } finally {
      setLoading(false);
    }
  }, [onRefresh, setLoading]);

  return (
    <LastSyncedContainer isLoading={loading}>
      <span>
        Last synced: <strong>{timeAgo}</strong>
      </span>
      <ActionButton compact onClick={handleRefresh} tertiary disabled={loading}>
        <RefreshIconTwo />
      </ActionButton>
    </LastSyncedContainer>
  );
};

/** Returns true if the given url is a valid google slides url. */
function isValidGoogleSlidesLink(url) {
  return /https:[/][/]docs.google.com[/]presentation[/]d[/][0-9A-Za-z-_]{40,50}[/]/.test(
    url
  );
}
