import { useParams } from 'react-router-dom';
import { YoutubePosts } from './YoutubePosts';
import { LinkedInPosts } from './LinkedInPosts';
import { TwitterPosts } from './TwitterPosts';
import { Link } from 'react-router-dom';
import { CONTENT_KIT_STATES, initializeMenus } from './PublishMenus';
import { makeRandomId } from '../../../helper';
import { EventNotCompletedView } from './EventNotCompletedView';
import { ProcessingContentKitView } from './ProcessingContentKitView';
import {
  ContentKitErrorView,
  MonthlyClipLimitReachedView,
} from './ContentKitErrorView';
import { CSVLink } from 'react-csv';
import Masonry from 'react-masonry-css';
import './MenuStyles.css';
import { OtherContent } from './OtherContent';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SpeakerEmails } from './SpeakerEmails';
import { ManageEpisodeSettingsModal } from '../../../components/series/settings/ManageEpisodeSettingsModal';
import { CompositeHighlights } from './CompositeHighlights';
import { updateEpisodeSettingsApi } from '../../../helper/api';
import { delay } from '../../../helper/exponentialFetchRetry';
import { TikTokPosts } from './TikTokPosts';
import { InstagramPosts } from './InstagramPosts';
import { RSSFeed } from './RSSFeed';
import { SettingsIcon } from '../../../components/icons/SettingsIcon';
import { useSelector } from 'react-redux';
import { isZyncStaff } from '../../../helper/isZyncStaff';
import { TriangleAlertIcon } from '../../../components/icons/TriangleAlertIcon';
import { checkIsSoloEpisode } from '../../Portal/shared';
import { ClipEditorModal } from '../../../components/ClipEditorModal';
import { LockIcon } from '../../../components/icons/LockIcon';
import { getStaticAssetUrl } from '../../../helper/getStaticAssetUrl';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from '../../../components/common/Button';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { useTrackActivity } from '../../../hooks/track';
import { useWorkspacePlan } from '../../../hooks/useWorkspacePlan';
import { VideoEditorLinkIcon } from '../../VideoEditorPage/icons/videoEditorLinkIcon';

const MenuItemIcon = ({ item, disabled }) => (
  <div className="w-9 h-9 rounded-full bg-purple/5 flex items-center justify-center">
    {item.icon({ disabled })}
  </div>
);

const MenuItemText = ({
  item,
  disabled,
  contentKitState,
  useEllipsis = false,
  isLoading = false,
}) => {
  return contentKitState === CONTENT_KIT_STATES.processing ? (
    <div className="bg-[#EBEBEB] w-[189px] h-[15px] rounded-sm"></div>
  ) : (
    <div
      className={`font-medium flex gap-3 items-center ${
        contentKitState === CONTENT_KIT_STATES.incomplete || disabled
          ? 'text-[#97A0AF]'
          : 'text-black'
      } ${
        contentKitState === CONTENT_KIT_STATES.complete &&
        'group-hover:text-purple duration-300'
      }`}
    >
      <div
        className={`underline decoration-transparent ${
          contentKitState === CONTENT_KIT_STATES.complete &&
          'group-hover:decoration-purple duration-300'
        } ${useEllipsis && 'truncate max-w-[200px]'}`}
      >
        {item.text}
      </div>
      {item?.additionalText &&
        contentKitState === CONTENT_KIT_STATES.complete && (
          <div className="px-2 rounded-[89px] bg-purple/10 text-sm font-medium text-purple">
            {item.additionalText}
          </div>
        )}
      {item?.getNum &&
        contentKitState === CONTENT_KIT_STATES.complete &&
        item?.getNum() > 0 && (
          <div className="h-5 w-5 rounded-full bg-purple/10 text-purple flex justify-center items-center p-1">
            {item.getNum()}
          </div>
        )}
      {item?.hoverIcon && !isLoading && (
        <div
          className={`opacity-0 ${
            contentKitState === CONTENT_KIT_STATES.complete &&
            'group-hover:opacity-100 duration-300'
          }`}
        >
          {item.hoverIcon}
        </div>
      )}
      {isLoading && <LoadingSpinner width="20px" />}
      {item?.noShortsAndLocalRecordings && (
        <div
          className={`group w-5 h-5 rounded-full bg-[#F8155C]/10 flex items-center justify-center relative ${
            contentKitState === CONTENT_KIT_STATES.complete
              ? 'opacity-100'
              : 'opacity-0'
          }`}
        >
          <div className="group-hover:opacity-100 bg-blue-dark text-white font-light text-xs p-2 absolute xl:w-[376px] lg:w-[300px] md:w-[160px] z-10 rounded-lg opacity-0 left-0 -top-10">
            Content unavailable due to lack of high definition recordings
          </div>
          <TriangleAlertIcon />
        </div>
      )}
    </div>
  );
};

const MenuDownloadUrl = ({ item, contentKitState }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { text, disabled } = item || {};

  return (
    <button
      onClick={() => {
        if (isLoading) return;
        item.getDownloadUrl(text, setIsLoading);
      }}
      className={`flex gap-[15px] items-center px-5 transition-all group ${
        disabled && 'pointer-events-none'
      }`}
    >
      <MenuItemIcon item={item} disabled={disabled} />
      <MenuItemText
        item={item}
        disabled={disabled}
        contentKitState={contentKitState}
        useEllipsis
        isLoading={isLoading}
      />
    </button>
  );
};

const MenuDownloadGCS = ({ item, contentKitState, thumbnailUrl, audioUrl }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { disabled } = item || {};

  return (
    <button
      onClick={() => {
        if (isLoading) return;
        item.getGCSDownload(setIsLoading);
      }}
      className={`flex gap-[15px] items-center px-5 transition-all group ${
        disabled && 'pointer-events-none'
      }`}
    >
      <MenuItemIcon item={item} disabled={disabled} />
      <MenuItemText
        item={item}
        disabled={disabled}
        contentKitState={contentKitState}
        isLoading={isLoading}
      />
    </button>
  );
};

const MenuDownloads = ({
  downloads,
  contentKitState,
  thumbnailUrl,
  audioUrl,
}) => {
  return downloads.map((d) => {
    const { disabled = false, hidden = false } = d;

    if (hidden) return null;

    switch (d.type) {
      case 'url': {
        return (
          <MenuDownloadUrl
            item={d}
            contentKitState={contentKitState}
            key={`download-${makeRandomId(6)}`}
          />
        );
      }
      case 'word': {
        return (
          <button
            onClick={() => d.generateWordDocument()}
            className={`flex gap-[15px] items-center px-5 transition-all group ${
              disabled && 'pointer-events-none'
            }`}
            key={`download-${makeRandomId(6)}`}
          >
            <MenuItemIcon item={d} disabled={disabled} />
            <MenuItemText
              item={d}
              disabled={disabled}
              contentKitState={contentKitState}
            />
          </button>
        );
      }
      case 'gcs': {
        return (
          <MenuDownloadGCS
            item={d}
            contentKitState={contentKitState}
            key={`download-${makeRandomId(6)}`}
            thumbnailUrl={thumbnailUrl}
            audioUrl={audioUrl}
          />
        );
      }
      case 'csv': {
        return (
          <div
            className={`${disabled && 'pointer-events-none'}`}
            key={`download-${makeRandomId(6)}`}
          >
            <CSVLink
              data={d.getCSVData()}
              target="_blank"
              filename={d.getFileName()}
            >
              <div className="flex gap-[15px] items-center px-5 transition-all group">
                <MenuItemIcon item={d} disabled={disabled} />
                <MenuItemText
                  item={d}
                  disabled={disabled}
                  contentKitState={contentKitState}
                />
              </div>
            </CSVLink>
          </div>
        );
      }
      case 'txt': {
        return (
          <button
            onClick={() => d.generateTextFile()}
            className={`flex gap-[15px] items-center px-5 transition-all group ${
              disabled && 'pointer-events-none'
            }`}
            key={`download-${makeRandomId(6)}`}
          >
            <MenuItemIcon item={d} disabled={disabled} />
            <MenuItemText
              item={d}
              disabled={disabled}
              contentKitState={contentKitState}
            />
          </button>
        );
      }
      default:
        return null;
    }
  });
};

const MenuLinks = ({ links, contentKitState }) => {
  const track = useTrackActivity();

  return links.map((l) => {
    const { disabled = false, type = 'page', hidden = false } = l;

    if (hidden) return null;

    switch (type) {
      case 'page': {
        return (
          <Link
            to={l.link}
            className={`flex gap-[15px] items-center px-5 transition-all ${
              !disabled ? 'group' : 'cursor-default'
            }`}
            key={`link-${makeRandomId(6)}`}
            target={l?.opensNewPage ? '_blank' : '_self'}
            rel="noopener noreferrer"
            disabled={disabled}
            onClick={() => {
              track(`Content Kit: ${l.text} Clicked`);
            }}
          >
            <MenuItemIcon item={l} disabled={disabled} />
            <MenuItemText
              item={l}
              disabled={disabled}
              contentKitState={contentKitState}
            />
          </Link>
        );
      }
      case 'pdf': {
        return (
          <a
            href={l.link}
            className={`flex gap-[15px] items-center px-5 transition-all group ${
              disabled && 'pointer-events-none'
            }`}
            key={`link-${makeRandomId(6)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <MenuItemIcon item={l} disabled={disabled} />
            <MenuItemText
              item={l}
              disabled={disabled}
              contentKitState={contentKitState}
            />
          </a>
        );
      }
      default:
        return null;
    }
  });
};

export const Menu = ({
  menu,
  meetingSeriesId,
  contentKitState = 'incomplete',
  recording,
  workspace,
}) => {
  const { header, links, downloads } = menu || {};
  const { type, showRestrictions } = header || {};
  const { thumbnailUrl, audioUrl } = recording || {};
  const history = useHistory();

  return (
    <div
      className={`flex flex-col h-fit w-full rounded-[20px] shadow-inner-one border border-black/10 mb-10 ${
        contentKitState !== 'complete' && 'pointer-events-none'
      }`}
    >
      <div className="border-b border-black/10 flex gap-2.5 px-5 py-2.5 items-center">
        {type === 'youtube' ? (
          <img src={header.iconUrl} alt="" className="h-8 object-contain" />
        ) : (
          <div className="w-9 h-9 flex items-center justify-center">
            {header?.iconUrl ? (
              <img src={header.iconUrl} alt="" />
            ) : (
              header.iconElement()
            )}
          </div>
        )}
        <div
          className={`font-semibold text-purple ${
            contentKitState === CONTENT_KIT_STATES.incomplete
              ? 'text-[#97A0AF]'
              : 'text-purple'
          }`}
        >
          {header.text}
        </div>
        {showRestrictions &&
          contentKitState === CONTENT_KIT_STATES.complete && <LockIcon />}
      </div>
      {showRestrictions ? (
        <div className="flex flex-col gap-5 py-5 group">
          <div className="hidden group-hover:flex flex-col gap-2 w-full max-w-full justify-center items-center  h-[92px]">
            <span className="text-sm text-blue-dark font-medium text-center">
              Upgrade to unlock content for Youtube, Twitter, Instagram and
              Tiktok
            </span>
            <Button
              color={Button.colors.ORANGE}
              padding={Button.padding.SEMI_SMALL}
              onClick={() => {
                history.push(
                  `/workspace/billing?workspaceId=${workspace.workspaceId}`
                );
              }}
            >
              <div className="flex gap-1.5 items-center">
                <img
                  width={24}
                  height={24}
                  src={getStaticAssetUrl('chargingIcon.png')}
                  alt=""
                />
                <span className="text-sm">Upgrade</span>
              </div>
            </Button>
          </div>
          <div className="flex flex-col gap-5 group-hover:hidden">
            {links && (
              <MenuLinks links={links} contentKitState={contentKitState} />
            )}
            {downloads && (
              <MenuDownloads
                downloads={downloads}
                contentKitState={contentKitState}
                meetingSeriesId={meetingSeriesId}
                thumbnailUrl={thumbnailUrl}
                audioUrl={audioUrl}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-5 py-5">
          <div className="flex flex-col gap-5">
            {links && (
              <MenuLinks links={links} contentKitState={contentKitState} />
            )}
            {downloads && (
              <MenuDownloads
                downloads={downloads}
                contentKitState={contentKitState}
                meetingSeriesId={meetingSeriesId}
                thumbnailUrl={thumbnailUrl}
                audioUrl={audioUrl}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export const PublishV2 = ({
  isLoading,
  contentKitData,
  startedContentKit,
  shared = false,
  localDispatch,
  series,
  workspace,
  refreshContentKit,
  refreshSeries,
  promotionKitData,
}) => {
  const { workspacePlan } = useWorkspacePlan();
  const userId = useSelector((st) => st.auth?.user?.userId);
  const [showEventSettingsModal, setShowEventSettingsModal] = useState(false);
  const [showEditorModal, setShowEditorModal] = useState(false);
  const { subpage, meetingSeriesId } = useParams();
  const { workspaceId } = workspace || {};
  const { autoLaunchConfig, title } = series || {};
  const { slides = [] } = autoLaunchConfig || {};
  const isRecordedInSegments = slides.some((slide) =>
    (slide?.sceneTemplateKey || '').includes('solo_studio_zync_prompter')
  );

  const {
    recording,
    highlights = [],
    publicPageLinks,
    youtubeDescription,
    blocks,
    speakerKits,
    status,
    episodeSummary,
    teaserEditedLandscape,
    teaserEditedPortrait,
    rssFeedUrl,
    episodeSettingsEpisodeVideoUrl = '',
    shorts = [],
    localRecordingSentences = [],
    hasAllLocalRecordings = false,
    clipLimitReached,
  } = contentKitData || {};
  const { type } = status || {};
  const { eventPosterUrl = '' } = promotionKitData || {};
  const isSoloEpisode = checkIsSoloEpisode(series);

  const saveHighlightSocialMediaPost = useCallback(
    async (setIsLoading, text, highlightId, socialMediaType, setError) => {
      setIsLoading(true);

      let result = null;
      if (highlightId) {
        const highlights = {
          ...(series?.settings?.highlights || {}),
          [highlightId]: {
            ...series?.settings?.highlights?.[highlightId],
            socialMediaPosts: {
              ...series?.settings?.highlights?.[highlightId]?.socialMediaPosts,
              [socialMediaType]: {
                text,
              },
            },
          },
        };

        result = await updateEpisodeSettingsApi(meetingSeriesId, {
          highlights,
        });
      } else {
        result = await updateEpisodeSettingsApi(meetingSeriesId, {
          youtubeDescription: text,
        });
      }

      if (!result) {
        setError('There was an error saving your change, please try again.');
        setIsLoading(false);
        return;
      }

      localDispatch({ type: 'REFRESH_SERIES', series: result });
      await delay(5_000);
      await refreshContentKit();
      setIsLoading(false);
    },
    [
      localDispatch,
      meetingSeriesId,
      refreshContentKit,
      series?.settings?.highlights,
    ]
  );

  const saveShortsSocialMediaPost = useCallback(
    async (setIsLoading, text, highlightId, socialMediaType, setError) => {
      setIsLoading(true);

      let shorts = null;

      if (series?.settings?.shorts) {
        shorts = {
          ...(series?.settings?.shorts || {}),
          [highlightId]: {
            ...series?.settings?.shorts?.[highlightId],
            shortTextData: {
              ...series?.settings?.shorts?.[highlightId]?.shortTextData,
              [socialMediaType]: text,
            },
          },
        };
      } else {
        shorts = {
          [highlightId]: {
            shortTextData: {
              [socialMediaType]: text,
            },
          },
        };
      }

      const result = await updateEpisodeSettingsApi(meetingSeriesId, {
        shorts,
      });

      if (!result) {
        setError('There was an error saving your change, please try again.');
        setIsLoading(false);
        return;
      }

      localDispatch({ type: 'REFRESH_SERIES', series: result });
      await delay(5_000);
      await refreshContentKit();
      setIsLoading(false);
    },
    [
      localDispatch,
      meetingSeriesId,
      refreshContentKit,
      series?.settings?.shorts,
    ]
  );

  const menus = useMemo(() => {
    if (!contentKitData && !startedContentKit) {
      return initializeMenus(
        contentKitData,
        CONTENT_KIT_STATES.incomplete,
        meetingSeriesId,
        shared,
        isSoloEpisode,
        workspaceId,
        workspacePlan,
        isRecordedInSegments,
        title
      );
    }

    if (!contentKitData && startedContentKit) {
      return initializeMenus(
        contentKitData,
        CONTENT_KIT_STATES.processing,
        meetingSeriesId,
        shared,
        isSoloEpisode,
        workspaceId,
        workspacePlan,
        isRecordedInSegments,
        title
      );
    }

    return initializeMenus(
      contentKitData,
      CONTENT_KIT_STATES.complete,
      meetingSeriesId,
      shared,
      isSoloEpisode,
      workspaceId,
      workspacePlan,
      isRecordedInSegments,
      title
    );
  }, [
    contentKitData,
    isRecordedInSegments,
    isSoloEpisode,
    meetingSeriesId,
    shared,
    startedContentKit,
    title,
    workspaceId,
    workspacePlan,
  ]);

  const track = useTrackActivity();

  useEffect(() => {
    track('Content Kit: Page Loaded');
  }, [track]);

  if (isLoading || startedContentKit === null || (!shared && !series))
    return null;

  if (startedContentKit === false)
    return <EventNotCompletedView menus={menus} />;

  if (type !== 'COMPLETE' && !recording && startedContentKit)
    return (
      <ProcessingContentKitView
        menus={menus}
        meetingSeriesId={meetingSeriesId}
      />
    );

  if (clipLimitReached && isSoloEpisode)
    return <MonthlyClipLimitReachedView isSoloEpisode={isSoloEpisode} />;

  if (
    (!isSoloEpisode && (!recording || !publicPageLinks)) ||
    (isSoloEpisode && !hasAllLocalRecordings) ||
    (isSoloEpisode && highlights.length === 0)
  )
    return <ContentKitErrorView isSoloEpisode={isSoloEpisode} />;

  const firstHighlightId = highlights[0]?.highlightId;

  return (
    <div className="flex flex-col justify-center items-center py-[60px] gap-5">
      {!subpage && (
        <>
          {showEventSettingsModal && (
            <ManageEpisodeSettingsModal
              handleClose={() => setShowEventSettingsModal(false)}
              series={series}
              localDispatch={localDispatch}
              episodeSummary={episodeSummary}
              refreshContentKit={refreshContentKit}
              highlights={highlights}
              workspace={workspace}
              shorts={shorts}
            />
          )}
          {showEditorModal && (
            <ClipEditorModal
              handleClose={() => setShowEditorModal(false)}
              series={series}
              localDispatch={localDispatch}
              refreshContentKit={refreshContentKit}
              localRecordingSentences={localRecordingSentences}
              hasAllLocalRecordings={hasAllLocalRecordings}
              workspace={workspace}
              shorts={shorts}
            />
          )}
          <div className="flex flex-col items-center">
            <div className="text-blue-dark font-semibold text-2xl mb-4 text-center">
              🎉 Congratulations ! Your Content Kit is Ready 🎉
            </div>
            {!isSoloEpisode && (
              <div className="text-blue-gray text-sm font-medium text-center mb-10">
                Zync has generated {highlights.length} bite-sized video clips,{' '}
                {highlights.length} Social Media Post(s), 1 episode page and
                updated your Highlights Reel and Show Page.
              </div>
            )}
            {!shared && (
              <div className="flex gap-2 ml-10 justify-end w-[744px]">
                {isSoloEpisode && (
                  <Link
                    to={`/clip/${firstHighlightId}/edit?workspaceId=${workspaceId}`}
                    className="flex bg-purple w-fit text-sm font-medium items-center gap-2.5 mb-5 group relative cursor-pointer py-2 px-4 rounded-md hover:scale-105 transition-transform"
                  >
                    <VideoEditorLinkIcon width={20} height={20} color="white" />
                    <span className="text-base uppercase text-white font-medium">
                      Video Editor
                    </span>
                  </Link>
                )}
                <button
                  className="flex w-fit text-sm text-purple font-medium items-center gap-1 mb-5 group relative"
                  onClick={() => setShowEventSettingsModal(true)}
                >
                  <SettingsIcon className="fill-purple" />
                  Settings
                  <div className="w-full h-[1px] bg-purple hidden group-hover:block absolute bottom-0"></div>
                </button>
                {isZyncStaff(userId) && !isSoloEpisode && (
                  <button
                    className="flex w-fit text-sm text-purple font-medium items-center gap-1 mb-5 group relative"
                    onClick={() => setShowEditorModal(true)}
                  >
                    <SettingsIcon className="fill-purple" />
                    Editor
                    <div className="w-full h-[1px] bg-purple hidden group-hover:block absolute bottom-0"></div>
                  </button>
                )}
                {isZyncStaff(userId) && !isSoloEpisode && (
                  <button
                    className="flex w-fit text-sm text-purple font-medium items-center gap-1 mb-5 group relative"
                    onClick={() => setShowEditorModal(true)}
                  >
                    <SettingsIcon className="fill-purple" />
                    Editor
                    <div className="w-full h-[1px] bg-purple hidden group-hover:block absolute bottom-0"></div>
                  </button>
                )}
              </div>
            )}
            <Masonry
              breakpointCols={2}
              className="masonry-grid"
              columnClassName="masonry-grid_column"
            >
              {menus.map((m) => {
                const { hidden = false } = m;

                if (hidden) return null;

                return (
                  <Menu
                    menu={m}
                    meetingSeriesId={meetingSeriesId}
                    key={`menu-${makeRandomId(6)}`}
                    contentKitState={CONTENT_KIT_STATES.complete}
                    recording={recording}
                    workspace={workspace}
                  />
                );
              })}
            </Masonry>
          </div>
        </>
      )}
      {subpage &&
        subpage !== 'youtube' &&
        subpage !== 'instagram' &&
        subpage !== 'tiktok' &&
        subpage !== 'linkedIn' &&
        subpage !== 'twitter' && (
          <Link
            to={
              shared
                ? `/e/${meetingSeriesId}/contentkit`
                : `/e/${meetingSeriesId}/details/publish`
            }
          >
            <div className="text-blue-dark font-medium text-sm underline">
              Content Kit
            </div>
          </Link>
        )}
      {subpage === 'youtube' && (
        <YoutubePosts
          recording={recording}
          highlights={highlights}
          youtubeDescription={youtubeDescription}
          workspace={workspace}
          refreshContentKit={refreshContentKit}
          saveHighlightSocialMediaPost={saveHighlightSocialMediaPost}
          saveShortsSocialMediaPost={saveShortsSocialMediaPost}
          teaserEditedLandscape={teaserEditedLandscape}
          teaserEditedPortrait={teaserEditedPortrait}
          episodeSettingsEpisodeVideoUrl={episodeSettingsEpisodeVideoUrl}
          refreshSeries={refreshSeries}
          localDispatch={localDispatch}
          posterUrl={eventPosterUrl || series?.settings?.eventPosterImage}
          shared={shared}
          shorts={shorts}
          episodeTitle={series?.title}
          isSoloEpisode={isSoloEpisode}
          series={series}
        />
      )}
      {subpage === 'linkedIn' && (
        <LinkedInPosts
          highlights={highlights}
          saveHighlightSocialMediaPost={saveHighlightSocialMediaPost}
          saveShortsSocialMediaPost={saveShortsSocialMediaPost}
          shorts={shorts}
          workspace={workspace}
          posterUrl={series?.settings?.eventPosterImage}
          meetingSeriesId={meetingSeriesId}
          shared={shared}
          series={series}
          carouselPdfUrl={contentKitData.carouselPdfUrl}
          refreshContentKit={refreshContentKit}
          isSoloEpisode={isSoloEpisode}
          refreshSeries={refreshSeries}
          localDispatch={localDispatch}
          breadcrumbs={
            <Link
              to={
                shared
                  ? `/e/${meetingSeriesId}/contentkit`
                  : `/e/${meetingSeriesId}/details/publish`
              }
              className="self-start pl-64"
            >
              <div className="font-medium text-sm">
                <span className="text-[#97A0AF]">
                  Content Kit /{'  '}
                  <span className="text-blue-dark">LinkedIn Highlights</span>
                </span>
              </div>
            </Link>
          }
        />
      )}
      {subpage === 'twitter' && (
        <TwitterPosts
          highlights={highlights}
          saveHighlightSocialMediaPost={saveHighlightSocialMediaPost}
          saveShortsSocialMediaPost={saveShortsSocialMediaPost}
          shorts={shorts}
          workspace={workspace}
          posterUrl={series?.settings?.eventPosterImage}
          meetingSeriesId={meetingSeriesId}
          shared={shared}
          series={series}
          isSoloEpisode={isSoloEpisode}
        />
      )}
      {subpage === 'other' && <OtherContent blocksData={blocks} />}
      {subpage === 'experimental' && (
        <CompositeHighlights highlights={highlights} />
      )}
      {subpage === 'speakerEmails' && (
        <SpeakerEmails speakerKits={speakerKits} />
      )}
      {subpage === 'tiktok' && (
        <TikTokPosts
          teaserEditedPortrait={teaserEditedPortrait}
          shorts={shorts}
          workspace={workspace}
          posterUrl={series?.settings?.eventPosterImage}
          saveHighlightSocialMediaPost={saveHighlightSocialMediaPost}
          saveShortsSocialMediaPost={saveShortsSocialMediaPost}
          meetingSeriesId={meetingSeriesId}
          shared={shared}
          highlights={highlights}
          isSoloEpisode={isSoloEpisode}
          series={series}
        />
      )}
      {subpage === 'instagram' && (
        <InstagramPosts
          teaserEditedPortrait={teaserEditedPortrait}
          shorts={shorts}
          workspace={workspace}
          posterUrl={series?.settings?.eventPosterImage}
          saveHighlightSocialMediaPost={saveHighlightSocialMediaPost}
          saveShortsSocialMediaPost={saveShortsSocialMediaPost}
          meetingSeriesId={meetingSeriesId}
          shared={shared}
          highlights={highlights}
          isSoloEpisode={isSoloEpisode}
          series={series}
        />
      )}
      {subpage === 'rssfeed' && <RSSFeed rssFeedUrl={rssFeedUrl} />}
      {isZyncStaff(userId || '') && (
        <a
          href={`https://contentkit.zync.ai/api/processor/statustext?meetingSeriesId=${meetingSeriesId}`}
          target="_blank"
          rel="noopener noreferrer"
          className="cursor-pointer"
        >
          Content kit status
        </a>
      )}
    </div>
  );
};
