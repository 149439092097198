export const AttendeeMicMuted = ({
  width = '19',
  height = '21',
  className = 'fill-[#696F8C]',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00276 4.79L6.2821 3.07C6.87279 1.52746 8.35122 0.506468 10.0035 0.5C12.2135 0.5 14.0051 2.29086 14.0051 4.5V8.5C14.0069 9.1408 13.8524 9.77239 13.5549 10.34L12.0043 8.77C12.0093 8.68007 12.0093 8.58993 12.0043 8.5V4.5C12.0043 3.39543 11.1085 2.5 10.0035 2.5C8.89854 2.5 8.00276 3.39543 8.00276 4.5V4.79ZM17.0062 12.36C17.0555 12.2312 17.0555 12.0888 17.0062 11.96C16.9593 11.8283 16.8622 11.7205 16.7361 11.66L15.8358 11.24C15.6005 11.121 15.3132 11.2094 15.1855 11.44L15.1855 11.44C15.1155 11.56 15.0455 11.68 14.9655 11.8L16.396 13.23C16.6205 12.9553 16.8244 12.6645 17.0062 12.36ZM18.9985 18.47C18.9985 18.6026 18.9437 18.7293 18.847 18.82L18.3268 19.35C18.236 19.4467 18.1093 19.5015 17.9766 19.5015C17.844 19.5015 17.7173 19.4467 17.6265 19.35L13.795 15.53C12.9297 16.0032 11.9827 16.3086 11.0039 16.43V18.5H12.5045C12.7808 18.5 13.0047 18.7239 13.0047 19V20C13.0047 20.2761 12.7808 20.5 12.5045 20.5H7.50257C7.22632 20.5 7.00238 20.2761 7.00238 20V19C7.00238 18.7239 7.22632 18.5 7.50257 18.5H8.95313V16.43C6.42042 16.1002 4.19379 14.5904 2.95081 12.36C2.90155 12.2312 2.90155 12.0888 2.95081 11.96C2.99774 11.8283 3.09482 11.7205 3.22091 11.66L4.12126 11.24C4.35655 11.121 4.64386 11.2094 4.77151 11.44C6.2643 14.0794 9.49233 15.1907 12.2944 14.03L10.6938 12.43C10.4663 12.4741 10.2353 12.4975 10.0035 12.5C7.79354 12.5 6.00199 10.7091 6.00199 8.5V7.74L1.15011 2.88C1.0534 2.78927 0.998535 2.66259 0.998535 2.53C0.998535 2.39741 1.0534 2.27073 1.15011 2.18L1.68032 1.65C1.77109 1.55332 1.89782 1.49848 2.03045 1.49848C2.16309 1.49848 2.28982 1.55332 2.38059 1.65L18.847 18.12C18.9437 18.2107 18.9985 18.3374 18.9985 18.47Z"
      />
    </svg>
  );
};
