export const ExitRightArrowIcon = ({ height = 28, width = 28 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1275 19.3775L13.25 21.5L20.75 14L13.25 6.5L11.1275 8.6225L15.005 12.5H0.5V15.5H15.005L11.1275 19.3775ZM24.5 0.5H3.5C1.8425 0.5 0.5 1.8425 0.5 3.5V9.5H3.5V3.5H24.5V24.5H3.5V18.5H0.5V24.5C0.5 26.1575 1.8425 27.5 3.5 27.5H24.5C26.1575 27.5 27.5 26.1575 27.5 24.5V3.5C27.5 1.8425 26.1575 0.5 24.5 0.5Z"
        fill="white"
      />
    </svg>
  );
};
