import React, { useEffect, useState } from 'react';
import { ScenePreview } from '../../components/authoring/Menu/ScenePreview';
import { SlideTray } from '../../components/authoring/Menu/SlideTray';
import {
  Modal,
  ModalWindow,
  ModalTitle,
  ModalBody,
  ModalButton,
} from '../../components/Modal';
import { SlideContainerRefContext } from '../../components/Slide';
import { useElementSize } from '../../hooks/useElementSize';
import { useBlockTemplates } from '../../hooks/useBlockTemplates';
import mixpanel from 'mixpanel-browser';
import { useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Button } from '../../components/common/Button';
import { ConfirmationModal } from '../../components/common/Modal';
import { deleteMeetingTemplateApi } from '../../helper/api';
import { logerror } from '../../helper/contextualLogger';

export const TemplatePreviewModal = ({
  isOpen,
  title,
  description,
  onButtonClick,
  onClose,
  template,
  brandKit,
}) => {
  const { user = {} } = useSelector((st) => st.auth, shallowEqual);
  const { privileges, userId } = user || {};
  const { allowTemplateManagement = false } = privileges || {};
  const [
    showDeleteMeetingTemplateConfirmation,
    setShowDeleteMeetingTemplateConfirmation,
  ] = useState(false);

  useEffect(() => {
    if (!isOpen) return;
    mixpanel.track('Template Preview - View', {
      templateId: template.templateId,
    });
  }, [template, isOpen]);

  const proceedToTemplateCreation = useCallback(() => {
    mixpanel.track('Template Preview - Create Meeting Click', {
      templateId: template.templateId,
    });
    onButtonClick();
  }, [onButtonClick, template]);

  const cancel = useCallback(() => {
    mixpanel.track('Template Preview - Cancel Click', {
      templateId: template.templateId,
    });
    onClose();
  }, [onClose, template]);

  const onDeleteMeetingTemplate = async () => {
    try {
      await deleteMeetingTemplateApi(template.templateId);
      setShowDeleteMeetingTemplateConfirmation(false);
      cancel();
    } catch (error) {
      logerror({
        userId,
        templateId: template.templateId,
        message:
          'Caught exception attempting to delete meeting template: ' +
          error.message,
        stacktrace: error.stack || error,
      });
    }
  };

  if (showDeleteMeetingTemplateConfirmation) {
    return (
      <ConfirmationModal
        onClose={() => setShowDeleteMeetingTemplateConfirmation(false)}
        title="Delete event template"
        text={`Are you sure you want to delete ${template.templateName}?`}
        open={showDeleteMeetingTemplateConfirmation}
        onConfirm={onDeleteMeetingTemplate}
      />
    );
  }

  return (
    isOpen && (
      <ModalWindow size={Modal.size.lg} onCancel={cancel}>
        {allowTemplateManagement ? (
          <div className="flex -mb-8 items-center">
            <div>
              <ModalTitle>{title}</ModalTitle>
            </div>
            <div className="flex items-stretch">
              <Button
                padding={Button.padding.SMALL}
                color={Button.colors.RED}
                onClick={() => setShowDeleteMeetingTemplateConfirmation(true)}
              >
                Delete
              </Button>
            </div>
          </div>
        ) : (
          <ModalTitle>{title}</ModalTitle>
        )}
        <ModalBody>
          <div className="flex flex-row items-center gap-4 my-8">
            <p className="text-blue-gray w-9/12">{description}</p>
            <div className="h-12 w-3/12 flex items-stretch">
              <ModalButton
                buttonText={'Create Event'}
                handleOnClick={proceedToTemplateCreation}
              />
            </div>
          </div>
          <SlideViewer template={template} brandKit={brandKit} />
        </ModalBody>
      </ModalWindow>
    )
  );
};

export const SlideViewer = ({
  template,
  /* determines of main scene is visible or not */
  showMainScene = true,
  /* overrides default behaviour when clicking on a scene */
  onSceneClick,
  /* brandKit object (see brandKitSchema) for injecting dynamic color and font values */
  brandKit,
}) => {
  const blockTemplates = useBlockTemplates();
  const { slides } = template || {};
  const [selectedSlideIndex, setSelectedSlideIndex] = useState(0);
  const selectedSlide = (slides || [])[selectedSlideIndex];
  const { slideUrl, slideConfig } = selectedSlide || {};
  const [, setUpdatedSlideConfig] = useState({});

  const { width, ref: slideContainerRef } = useElementSize();

  useEffect(() => {
    setUpdatedSlideConfig(JSON.stringify(selectedSlide || {}, null, 2));
  }, [setUpdatedSlideConfig, slideConfig, selectedSlide]);

  const constrainedSlideDimensions = {
    width: width,
    height: (width * 9) / 16,
  };

  if (!slideUrl) {
    return <div>No slides</div>;
  }

  return (
    <SlideContainerRefContext.Provider value={slideContainerRef}>
      {showMainScene && (
        <div className="flex w-full justify-center">
          <div
            className="relative flex tall:w-full w-7/12"
            ref={slideContainerRef}
          >
            <ScenePreview
              scene={selectedSlide}
              {...constrainedSlideDimensions}
              getBlockById={(blockId) =>
                (blockTemplates ?? []).find((b) => b.blockId === blockId)
              }
              brandKit={brandKit}
            />
          </div>
        </div>
      )}
      <div className="relative mx-10 flex min-h-[170px] overflow-hidden">
        <SlideTray
          slides={slides}
          selectedSlide={showMainScene ? selectedSlide : -1}
          enableReorder={false}
          enableDelete={true}
          blockTemplates={blockTemplates ?? []}
          onSelectScene={onSceneClick || setSelectedSlideIndex}
          brandKit={brandKit}
        />
      </div>
    </SlideContainerRefContext.Provider>
  );
};
