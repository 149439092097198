import React, { useEffect, useState } from 'react';
import data from './highlight_aggregator.json';
import { useParams } from 'react-router-dom';
import { fetchFeedDataApi, fetchWorkspaceApi } from '../../helper/api';
import { isMobile } from '../../helper';
import { ToastOutlet } from '../../components/ToastOutlet';

const useFeedPageProvider = () => {
  const { workspaceId } = useParams();

  const [workspace, setWorkspace] = useState(null);
  const [feed, setFeed] = useState(null);
  const [selectedHighlight, setSelectedHighlight] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchWorkspace = async () => {
      if (workspaceId) {
        try {
          const [workspaceRequestResult, feedRequestResult] = await Promise.all(
            [fetchWorkspaceApi(workspaceId), fetchFeedDataApi(workspaceId)]
          );

          setWorkspace(workspaceRequestResult.result);
          setFeed(feedRequestResult.feedPage);
        } catch (error) {
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchWorkspace();
  }, [workspaceId]);

  const state = {
    selectedHighlight,
    setSelectedHighlight,
    isLoading,
  };

  return {
    feed,
    workspace,
    state,
  };
};
export const FeedPageContext = React.createContext(data);

export const FeedPageContextProvider = ({ children }) => {
  const value = useFeedPageProvider();

  return (
    <FeedPageContext.Provider value={value}>
      <ToastOutlet isMobile={+isMobile} />
      {children}
    </FeedPageContext.Provider>
  );
};
