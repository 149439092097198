import React, { useContext, useRef } from 'react';
import { TimelineIcon } from '../icons/TimelineIcon';
import classNames from '../../helper/classNames';
import { CircledArrowIcon } from '../icons/CircledArrowIcon';
import { useMeeting } from '../../hooks/useMeeting';
import { MaskIcon } from '../icons/MaskIcon';
import { templateIdToIconMap } from '../../pages/EventLanding/Prepare/QuickScenes';
import { FileIcon } from '../icons/FileIcon';
import { ChunkedLocalRecordingContext } from '../Meeting/context/ChunkedLocalRecordingContextProvider';
import { useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';

export const Timeline = ({
  scenes,
  currentSceneIndex,
  startSlide,
  onClose,
  handleOpenNotes,
}) => {
  const slideRefs = useRef([]);

  const isSolo = useSelector(
    (state) => state?.meetingState?.meetingConfig?.isSolo
  );

  const { stopLocalRecording, isLocalRecording } = useContext(
    ChunkedLocalRecordingContext
  );

  const userId = useSelector((st) => st.auth?.user?.userId);

  const selectScene = (index) => {
    if (isLocalRecording) {
      stopLocalRecording();
    }
    mixpanel.track('Studio: Timeline clicked', {
      distinct_id: userId,
      currentSceneIndex,
      nextSceneIndex: index,
    });
    startSlide(index);
  };

  const onRecordScenes = scenes.slice(1);

  const { isLive, wentLive, isPreview } = useMeeting();

  return (
    <div className="flex flex-col">
      <div
        className={`flex px-3 py-2.5 gap-4 border-b-2 border-[#DFE1E6] w-full justify-between ${
          currentSceneIndex === 0
            ? 'text-purple bg-purple/10'
            : 'text-blue-dark h-[50px]'
        }`}
      >
        <div>
          {!isSolo && (
            <div className="flex">
              <div className="w-8">
                <MaskIcon />
              </div>
              <div className="text-blue-dark font-medium text-sm flex gap-2 justify-between w-full items-center">
                Lobby
              </div>
            </div>
          )}
          {currentSceneIndex === 0 && (
            <p className="pl-8 text-[13px] text-blue-dark">
              Offstage area to connect with speakers before and after the show
            </p>
          )}
        </div>
        <button
          onClick={onClose}
          className="shrink-0 w-5 h-5 bg-blue-gray rounded-full text-white text-lg flex items-center justify-center"
        >
          ×
        </button>
      </div>
      <div className="flex flex-col relative">
        {!isLive && (
          <div className="absolute group top-11 left-0 bottom-1 w-full z-20 flex justify-center items-center">
            <div className="opacity-0 hover:opacity-100 w-full text-center px-5 h-full grid place-content-center backdrop-blur-sm transition-all">
              <div className="bg-blue-dark px-3.5 py-2.5 text-white text-center rounded-lg text-xs font-light">
                {isPreview && !wentLive && (
                  <div>
                    <p className="font-semibold">Timeline not Active.</p>
                    <p>Start Rehearsal to access timeline</p>
                  </div>
                )}
                {isPreview && wentLive && (
                  <div>
                    <p className="font-semibold">Timeline Not Active.</p>
                    <p>Rehearsal Completed</p>
                  </div>
                )}
                {!isPreview && !wentLive && (
                  <div>
                    <p className="font-semibold">Timeline not Active.</p>
                    <p>Start Episode to access timeline</p>
                  </div>
                )}
                {!isPreview && wentLive && (
                  <div>
                    <p className="font-semibold">Timeline not Active.</p>
                    <p>Episode Completed</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="flex px-3 pb-2.5 gap-4 border-b-2 border-[#DFE1E6] w-full justify-between pt-2.5">
          <div className="flex gap-4">
            <TimelineIcon />
            <div className="text-blue-dark font-medium text-sm">Timeline</div>
          </div>
        </div>
        <div className="relative flex flex-col">
          {onRecordScenes.length > 0 &&
            onRecordScenes.map((scene, index) => {
              const TemplateIcon =
                templateIdToIconMap[scene.sceneTemplateKey] || (() => <></>);

              return (
                <div
                  className={classNames(
                    'flex gap-2.5 items-center z-10 relative p-2.5 cursor-pointer',
                    currentSceneIndex === index + 1
                      ? 'text-purple bg-purple/10'
                      : 'text-blue-dark'
                  )}
                  ref={(el) => (slideRefs.current[index] = el)}
                  onClick={() => selectScene(index + 1)}
                  key={scene.sceneId}
                >
                  <div
                    className="absolute bottom-0 border-b border-[#DFE1E6] h-[1px] left-8"
                    style={{ width: 'calc(100% - 32px)' }}
                  />
                  <div
                    className={classNames(
                      'absolute left-4 border-l border-[#DFE1E6] z-0',
                      index === 0 && 'bottom-0',
                      index === (scenes || []).length - 1 && 'top-0'
                    )}
                    style={{
                      height:
                        index === 0 || index === (scenes || []).length - 1
                          ? slideRefs.current[index]?.offsetHeight / 2 + 5
                          : slideRefs.current[index]?.offsetHeight,
                    }}
                  />
                  <div
                    className={classNames(
                      'min-h-[12px] min-w-[12px] rounded-full border border-purple z-10 flex justify-center items-center',
                      scene?.hostNote ? 'bg-purple' : 'bg-white'
                    )}
                  >
                    {scene?.hostNote && <FileIcon />}
                  </div>
                  <div className="flex justify-between group items-center w-full min-h-[20px]">
                    <div className="flex gap-2.5 items-center w-full pr-2">
                      <div className="w-fit">
                        <TemplateIcon />
                      </div>
                      <div className="flex justify-between items-center gap-2 w-full">
                        <span className="text-blue-dark text-[13px]">
                          {scene.slideName}
                        </span>
                        {currentSceneIndex === index + 1 && scene.hostNote ? (
                          <button
                            onClick={handleOpenNotes}
                            className="block text-xxs font-medium text-white bg-purple px-1.5 py-1 rounded-md"
                          >
                            Notes
                          </button>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className={classNames(
                        'invisible',
                        currentSceneIndex !== index && 'group-hover:visible'
                      )}
                    >
                      <CircledArrowIcon width="20px" height="20px" />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
