import React, { useEffect } from 'react';
import { DatePicker } from '../../components/DatePicker';
import { TimePicker } from '../../components/TimePicker';
import { DropdownSelect } from '../../components/common/Dropdown';
import { Checkbox } from '../../components/Checkbox/Checkbox';
import { timezonesOptions } from '../../helper/timezone-formatter';

export const SetDate = ({
  scheduledEvent,
  setScheduledEvent,
  isCreatingWithDate,
  setIsCreatingWithDate,
  setWhenDateWasSetForTheFirstTime,
  whenDateWasSetForTheFirstTime,
  areDatesValid,
}) => {
  const handleChangeScheduledEvent = (key, value) => {
    setScheduledEvent((prevScheduledEvent) => ({
      ...prevScheduledEvent,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (!whenDateWasSetForTheFirstTime) {
      setWhenDateWasSetForTheFirstTime(true);
      setIsCreatingWithDate(true);
    }
  }, [
    setIsCreatingWithDate,
    setWhenDateWasSetForTheFirstTime,
    whenDateWasSetForTheFirstTime,
  ]);

  return (
    <div className="flex flex-grow flex-col gap-2 self-start min-w-0">
      <h2 className="text-blue-dark font-semibold">When is your event?</h2>
      <div className="flex flex-col w-full p-5 rounded-lg gap-5">
        <div className="flex flex-col w-full gap-8">
          <div className="flex gap-8">
            <div className="flex-[1_0_0%]">
              <DatePicker
                daySelected={scheduledEvent.daySelected}
                setDaySelected={(daySelected) =>
                  handleChangeScheduledEvent('daySelected', daySelected)
                }
                label="Date"
                disabled={!isCreatingWithDate}
              />
            </div>
            <div className="flex flex-col gap-2 flex-[1_0_0%] w-1/2 min-w-0">
              <div className="text-blue-dark text-sm font-medium">
                Time Zone
              </div>
              <DropdownSelect
                options={timezonesOptions(scheduledEvent.daySelected)}
                disabled={!isCreatingWithDate}
                selectedKey={scheduledEvent.timezoneSelected}
                onSelect={(selectKey) => {
                  handleChangeScheduledEvent('timezoneSelected', selectKey);
                }}
                fontSize={DropdownSelect.fontSize.SMALL}
                backgroundColor={DropdownSelect.backgroundColor.WHITE}
                fontColor={DropdownSelect.fontColor.BLUE_GRAY}
                borderSize={DropdownSelect.borderSize.ONE}
                borderColor={DropdownSelect.borderColor.GRAY}
                angleColor="#696F8C"
                optionWidth={DropdownSelect.optionWidth.SMALL}
                menuWidth={370}
              />
            </div>
          </div>
          <div className="flex gap-8">
            <div className="flex-1">
              <TimePicker
                time={scheduledEvent.startTime}
                setTime={(time) =>
                  handleChangeScheduledEvent('startTime', time)
                }
                disabled={!isCreatingWithDate}
                label="Start Time"
              />
            </div>
            <div className="flex-1">
              <TimePicker
                time={scheduledEvent.endTime}
                setTime={(time) => handleChangeScheduledEvent('endTime', time)}
                label="End Time"
                disabled={!isCreatingWithDate}
              />
            </div>
          </div>
          <div className="flex gap-2">
            <Checkbox
              checked={!isCreatingWithDate}
              handleChange={() => {
                setIsCreatingWithDate((wasOn) => !wasOn);
              }}
            />
            <span>Not yet decided</span>
          </div>
        </div>
      </div>
      {!areDatesValid && isCreatingWithDate ? (
        <div className="bg-white w-fit">
          <div className="bg-orange-light/20 border border-orange-light rounded-md p-2 text-xs font-medium text-orange-light">
            You selected a past time
          </div>
        </div>
      ) : null}
    </div>
  );
};
