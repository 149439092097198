import { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { fetchTemplatesApi, fetchWorkspaceApi } from '../helper/api';
import { useUserWorkspaces } from './useUserWorkspaces';
import { getWorkspaceIdFromUrl } from './useTemplateCreation';

const ALL_CATEGORY = 'all';

export const getFormattedCategoryName = (category) =>
  category
    .split('_')
    .map((word) => word[0].toUpperCase() + word.substr(1))
    .join(' ');

export const useTemplateGallery = () => {
  const { user = {} } = useSelector((st) => st.auth, shallowEqual);
  const { privileges } = user || {};
  const { privateTemplates, allowTemplateManagement = false } =
    privileges || {};
  const { templateIds } = privateTemplates || {};

  const [workspace, setWorkspace] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const { currentWorkspaceId } = useUserWorkspaces();
  const workspaceId = currentWorkspaceId || getWorkspaceIdFromUrl();

  const allowedTemplatesFilter = (
    template,
    templateIds,
    allowTemplateManagement
  ) => {
    if (allowTemplateManagement || template.visibility === 'public') {
      return true;
    }

    return templateIds?.indexOf(template.templateId) >= 0;
  };

  const categories = useMemo(() => {
    return templates.length
      ? [
          ALL_CATEGORY,
          ...new Set(templates.flatMap((template) => template.categories)),
        ].map((category) => ({
          label: getFormattedCategoryName(category),
          value: category,
        }))
      : [];
  }, [templates]);

  const filteredTemplates = templates.filter(
    (template) =>
      selectedCategory?.value === ALL_CATEGORY ||
      template.categories.includes(selectedCategory?.value)
  );

  useEffect(() => {
    fetchTemplatesApi().then((newTemplates) => {
      setTemplates(
        newTemplates.filter((template) =>
          allowedTemplatesFilter(template, templateIds, allowTemplateManagement)
        )
      );
    });
  }, [templateIds, allowTemplateManagement]);

  useEffect(() => {
    fetchWorkspaceApi(workspaceId).then(({ result: updatedWorkspace }) => {
      setWorkspace(updatedWorkspace);
    });
  }, [workspaceId]);

  useEffect(() => {
    const selectFirstCategoryOnLoad = () => {
      if (categories.length && !selectedCategory) {
        setSelectedCategory(categories[0]);
      }
    };
    selectFirstCategoryOnLoad();
  }, [categories, selectedCategory]);

  const brandKit = workspace?.brandKit;

  return {
    categories,
    filteredTemplates,
    templates,
    selectedCategory,
    setSelectedCategory,
    brandKit,
  };
};
