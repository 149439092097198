import React, { useCallback, useRef, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components/macro';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { SecondaryText } from './Text';
import { VideoPlayer } from '../VideoPlayer';
import { VideoPlayIcon } from './Icon';
import { Modal } from './Modal';

export const ToolTipContainer = styled.div`
  cursor: ${({ dismissal }) =>
    dismissal === 'click-outside' ? 'default' : 'pointer'};
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 140px;
  max-width: 280px;

  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px;

  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ?? theme.palette.primary.main};
  &,
  > * {
    color: ${({ textColor }) => textColor ?? '#FFFFFF'} !important;
  }

  z-index: 8000;
`;

const tooltipRootElement = document.getElementById('tooltip-root');

/** A tooltip which shows information about its reference element, positioning automatically below it. */
export const ToolTip = ({
  /** The reference DOM element next to which the tooltip will be positioned. */
  referenceElement,
  /** The tooltip content, may be a string or JSX. */
  content,
  /** Whether the tooltip is open. */
  open,
  /** Handler for a close action (when the user clicks outside the tooltip). */
  onClose,
  /** Dismissal method: click-outside (click outside the tooltip), click (click inside the tooltip). */
  dismissal = 'click-outside',
  /** Background color. Defaults to the primary blue color. */
  backgroundColor = '#00A3A5',
  /** Text color. Defaults to white. */
  textColor = '#FFFFFF',
  /** The corner of the tooltip that should align to referenceElement. Supports "top right" and "top left". */
  location = 'top right',
}) => {
  const containerRef = useRef(null);

  useOnClickOutside(
    containerRef,
    open && dismissal === 'click-outside',
    onClose
  );

  const position = useMemo(
    () => open && referenceElement && referenceElement.getBoundingClientRect(),
    [referenceElement, open]
  );

  const containerElement = containerRef.current;
  const containerPosition = useMemo(
    () =>
      position && containerElement && containerElement.getBoundingClientRect(),
    [position, containerElement]
  );

  let positionStyle = undefined;
  let caretPositionStyle = undefined;
  if (position) {
    const isTop = location.includes('top');
    const isBottom = location.includes('bottom');
    const isLeft = location.includes('left');
    const isRight = location.includes('right');

    positionStyle = {};
    caretPositionStyle = { position: 'absolute' };

    if (isTop) {
      positionStyle.top = position.y + position.height + 16;
      caretPositionStyle.top = -8;
    } else if (isBottom) {
      positionStyle.top = position.y - 16 - (containerPosition?.height ?? 0);
      caretPositionStyle.bottom = -8;
      caretPositionStyle.transform = 'rotate(180deg)';
    } else {
      positionStyle.top =
        position.y + position.height / 2 - (containerPosition?.height ?? 0) / 2;
      if (isLeft) {
        positionStyle.left = position.x + position.width + 12;
        caretPositionStyle.left = -10;
        caretPositionStyle.transform = 'rotate(-90deg)';
      } else {
        positionStyle.right = window.innerWidth - position.x + 12;
        caretPositionStyle.right = -10;
        caretPositionStyle.transform = 'rotate(90deg)';
      }
    }

    if (isTop || isBottom) {
      if (isLeft) {
        positionStyle.left = position.x + position.width / 2 - 6;
        caretPositionStyle.left = 6;
      } else if (isRight) {
        caretPositionStyle.right = 6;
        positionStyle.right =
          window.innerWidth - position.x - 16 - position.width / 2;
      } else {
        positionStyle.left =
          position.x + position.width / 2 - containerPosition?.width / 2;
      }
    }
  }

  return open && position
    ? createPortal(
        <ToolTipContainer
          ref={containerRef}
          style={positionStyle}
          backgroundColor={backgroundColor}
          textColor={textColor}
          onClick={dismissal === 'click' ? onClose : undefined}
          dismissal={dismissal}
        >
          <svg width="20" height="14" style={caretPositionStyle}>
            <polygon points="10,0 0,14 20,14" style={{ fill: '#00a3a5' }} />
          </svg>
          {typeof content === 'string' ? (
            <SecondaryText>{content}</SecondaryText>
          ) : (
            content
          )}
        </ToolTipContainer>,
        tooltipRootElement
      )
    : null;
};

/**
 * A tooltip with a video at the bottom, useful for tutorials or explanations.
 * @see ToolTip
 */
export const VideoToolTip = ({
  /** Text or content before the video link. */
  text,
  /** The URL of the video. */
  videoUrl,
  /** A brief description or call to action for the video. */
  videoDescription,
  /** Optional: { width, height } for the video player. */
  ...props
}) => {
  const [showVideo, setShowVideo] = useState(false);
  const propsOnClose = props.onClose;
  const handleVideoClick = useCallback(() => {
    setShowVideo(true);
    propsOnClose();
  }, [setShowVideo, propsOnClose]);

  return (
    <>
      <ToolTip
        {...props}
        content={
          <div>
            {text}
            {videoUrl && (
              <VideoPlayContainer onClick={handleVideoClick}>
                <VideoPlayIcon />
                <div>{videoDescription}</div>
              </VideoPlayContainer>
            )}
          </div>
        }
      />
      <Modal open={showVideo} onClose={() => setShowVideo(false)} wide>
        <VideoPlayer
          autoPlay
          position={{ width: '100%', height: '100%' }}
          showControls
          videoUrl={videoUrl}
          muted={false}
        />
      </Modal>
    </>
  );
};

/** A container for the video play button and call to action. */
export const VideoPlayContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  cursor: pointer;

  padding-top: 12px;

  &:hover {
    > svg {
      transform: scale(1.1);
    }
  }

  > svg {
    height: 25px;
    width: 25px;
    object-fit: contain;
    object-position: center;
    transition: transform 120ms ease;
  }

  > div {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
  }
`;
