import React from 'react';

export const DocumentIcon = ({
  width = '21px',
  height = '21px',
  className = 'fill-purple',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 21 21"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.50016 2.07031H15.5002C16.4206 2.07031 17.1668 2.8165 17.1668 3.73698V12.7036C17.1662 13.3665 16.9025 14.0019 16.4335 14.4703L12.9002 18.0036C12.4318 18.4726 11.7963 18.7364 11.1335 18.737H5.50016C4.57969 18.737 3.8335 17.9908 3.8335 17.0703V3.73698C3.8335 2.8165 4.57969 2.07031 5.50016 2.07031ZM15.5002 12.0703V3.73698H5.50016V17.0703H10.5002V12.9036C10.5002 12.4434 10.8733 12.0703 11.3335 12.0703H15.5002ZM7.5835 7.07031H13.4168C13.6469 7.07031 13.8335 6.88376 13.8335 6.65365V5.82031C13.8335 5.59019 13.6469 5.40365 13.4168 5.40365H7.5835C7.35338 5.40365 7.16683 5.59019 7.16683 5.82031V6.65365C7.16683 6.88376 7.35338 7.07031 7.5835 7.07031ZM10.9168 10.4036H7.5835C7.35338 10.4036 7.16683 10.2171 7.16683 9.98698V9.15365C7.16683 8.92353 7.35338 8.73698 7.5835 8.73698H10.9168C11.1469 8.73698 11.3335 8.92353 11.3335 9.15365V9.98698C11.3335 10.2171 11.1469 10.4036 10.9168 10.4036Z"
    />
  </svg>
);
