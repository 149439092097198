import { TROUBLESHOOTING_URL } from '../helper/constants';

export const SCREENSHARE_HELP_ID = 'screenshareHelp';

export const screenshareToastOptions = {
  position: 'top-center',
  autoClose: false,
  toastId: SCREENSHARE_HELP_ID,
};
export const ScreenshareHelpToast = () => (
  <>
    If you experience problems sharing your screen, visit our{' '}
    <a
      className="underline decoration-purple decoration-solid decoration-1 text-purple"
      target="_blank"
      rel="noreferrer"
      href={TROUBLESHOOTING_URL}
    >
      Troubleshooting Guide
    </a>
    .
  </>
);
