import React, { useState } from 'react';
import classNames from '../../helper/classNames';

export const ScrollbarOnHoverContainer = ({ children, className }) => {
  const [hasScroll, setHasScroll] = useState(false);

  const setContainerWidths = (ref) => {
    if (ref) {
      setHasScroll(ref.offsetWidth > ref.scrollWidth);
    }
  };

  return (
    <div
      ref={setContainerWidths}
      className={classNames(
        className,
        hasScroll && 'purple-scrollbar-on-hover'
      )}
    >
      {children}
    </div>
  );
};
