import { useSelector } from 'react-redux';

import { ALLOWED_TRACK_STATES_TO_JOIN_MEETING } from './dailyConstants';

export const useAreLocalTracksReady = () => {
  const localTrackState = useSelector((state) => state.callState.tracks.local);
  // --- Memoized helpers ---
  const isLocalAudioTrackReady =
    localTrackState.audioTrackState &&
    ALLOWED_TRACK_STATES_TO_JOIN_MEETING.indexOf(
      localTrackState.audioTrackState.state
    ) > -1;

  const isLocalVideoTrackReady =
    localTrackState.videoTrackState &&
    ALLOWED_TRACK_STATES_TO_JOIN_MEETING.indexOf(
      localTrackState.videoTrackState.state
    ) > -1;

  const allLocalTracksReady = isLocalAudioTrackReady && isLocalVideoTrackReady;

  return {
    allLocalTracksReady,
    isLocalAudioTrackReady,
    isLocalVideoTrackReady,
  };
};
