import React from 'react';
import { WarningTriangleIcon } from './icons/WarningTriangleIcon';

export const Notification = ({ title, message }) => (
  <div className="bg-white rounded-xl md:w-1/2 w-full">
    <div className="bg-orange-light py-4 px-4 bg-opacity-5 border border-orange-light rounded-xl">
      <div className="flex space-x-5">
        <WarningTriangleIcon className="fill-orange-light shrink-0 mt-0.5" />
        <div className="flex flex-col">
          <span className="font-medium text-orange-light underline text-sm pb-1">
            {title}
          </span>
          <span className="text-blue-gray text-sm font-normal">{message}</span>
        </div>
      </div>
    </div>
  </div>
);

export const PurpleNotification = ({ title, message }) => (
  <div className="bg-white rounded-xl w-full">
    <div className="bg-purple py-4 px-4 bg-opacity-5 border border-purple rounded-xl">
      <div className="flex flex-col">
        <span className="font-medium text-purple text-sm pb-1">{title}</span>
        <span className="text-blue-gray text-sm font-normal">{message}</span>
      </div>
    </div>
  </div>
);

export const Notifications = ({ render }) => {
  return render
    .filter(({ when }) => when)
    .map(({ component }, i) => (
      <React.Fragment key={i}>{component}</React.Fragment>
    ));
};
