import { shallowEqual, useSelector } from 'react-redux';

const useMeetingRecordings = () => {
  const meetingRecording = useSelector(
    (state) => state.meetingState.recording,
    shallowEqual
  );

  const meetingStreaming = useSelector(
    (state) => state.meetingState.state.streaming,
    shallowEqual
  );

  const hasRecordings = Boolean(meetingRecording?.liveStreamId);

  const recordings = Object.values(meetingStreaming?.assets || {})
    .slice()
    .sort((a, b) => new Date(a.recordingStart) - new Date(b.recordingStart));

  return {
    hasRecordings,
    recordings,
    meetingStreaming,
  };
};

export default useMeetingRecordings;
