import React, { useCallback, useState } from 'react';
import { TwoPersonFilledIcon } from '../icons/TwoPersonFilledIcon';
import { shallowEqual, useSelector } from 'react-redux';
import { CaretIcon } from '../icons/CaretIcon';
import { TextArea } from '../common/TextArea';
import classNames from '../../helper/classNames';
import { sendEvent } from '../../helper/api';
import { useMeeting } from '../../hooks/useMeeting';
import { makeRandomId } from '../../helper';
import { isMeetingController } from '../../helper/roles';
import { sceneTemplateKeyMap } from 'zync-common/types';

const VoteButton = ({
  upvotes,
  user,
  questionId,
  handleVoteButtonClick,
  disabled,
}) => {
  const { userId } = user || {};
  const hasUpvoted = (upvotes || {}).hasOwnProperty(userId);

  return (
    <button
      className={classNames(
        'min-w-[44px] h-[38px] flex flex-col rounded shadow-100 items-center justify-center font-medium text-sm',
        hasUpvoted
          ? 'bg-purple text-white'
          : 'bg-white text-blue-gray border-gray border'
      )}
      onClick={() => handleVoteButtonClick(questionId, hasUpvoted)}
      disabled={disabled}
    >
      <CaretIcon
        className={classNames(
          'rotate-180',
          hasUpvoted ? 'fill-white ' : 'fill-blue-gray'
        )}
        width="14px"
        height="8px"
      />
      {Object.keys(upvotes || {}).length}
    </button>
  );
};

const Question = ({
  question,
  user,
  handleVoteButtonClick,
  isModerator,
  handleRemoveQuestion,
  handleStageQuestion,
  qaSceneIndex,
  isLive,
}) => {
  const { asker, text, upvotes, id, staged, answered } = question || {};
  const disabled = answered || staged;
  const askDisabled = !isLive;

  return (
    <div
      className={classNames(
        'flex gap-2.5 w-full border-b border-[#DFE1E6] p-2.5',
        disabled && 'opacity-70 bg-gray bg-opacity-30'
      )}
    >
      <VoteButton
        upvotes={upvotes}
        user={user}
        questionId={id}
        handleVoteButtonClick={handleVoteButtonClick}
        disabled={disabled}
      />
      <div className="flex flex-col gap-1 w-full max-w-full min-w-0">
        <span className="text-sm text-blue-dark break-words">{text}</span>
        <div className="flex items-center justify-between">
          <span className="text-blue-gray text-xs font-light max-w-full break-words">
            {asker ? asker : 'Anonymous'}
          </span>
          {isModerator && (
            <div className="flex gap-1 min-w-fit">
              <button
                onClick={() => handleRemoveQuestion(id)}
                className="text-white bg-red rounded px-2 py-1 text-xxs font-semibold"
              >
                Dismiss
              </button>
              {qaSceneIndex !== -1 && (
                <button
                  onClick={() => handleStageQuestion(id)}
                  className={classNames(
                    'rounded px-2 py-1 text-xxs font-semibold',
                    disabled || askDisabled
                      ? 'text-[#97A0AF] border border-[#EBEAED]'
                      : 'bg-purple text-white'
                  )}
                  disabled={disabled || askDisabled}
                >
                  Ask
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Ask = ({ user, meetingId }) => {
  const { userId } = user || {};
  const [askAnonymously, setAskAnonymously] = useState(false);
  const [inputQuestion, setInputQuestion] = useState('');

  const handleAskQuestion = () => {
    const trimmedQuestion = inputQuestion.trim();
    if (trimmedQuestion.length === 0) return;
    const question = {
      text: trimmedQuestion,
      id: makeRandomId(6),
      timestamp: Date.now(),
      asker: askAnonymously ? null : user?.userName,
      moderatorQuestion: false,
      AIGenerated: false,
      upvotes: {},
    };
    sendEvent(userId, meetingId, {
      type: 'ASK_QUESTION',
      userId,
      data: { question },
    });
    setInputQuestion('');
  };

  return (
    <div className="min-h-[144px] w-full bg-purple/5 flex flex-col">
      <div className="flex gap-x-2 justify-around border-b border-[#DFE1E6] h-[49px] px-4">
        <button
          className="text-sm font-medium text-blue-dark relative"
          onClick={() => setAskAnonymously(false)}
        >
          {!askAnonymously && (
            <span className="absolute bottom-0 w-full h-[3px] bg-purple"></span>
          )}
          &#x1F914; Ask
        </button>
        <button
          className="text-sm font-medium text-blue-dark relative"
          onClick={() => setAskAnonymously(true)}
        >
          {askAnonymously && (
            <span className="absolute bottom-0 w-full h-[3px] bg-purple"></span>
          )}
          &#x1F92B; Ask Anonymously
        </button>
      </div>
      <div className="pt-5 pb-[25px] px-2.5">
        <div className="h-[50px] flex flex-col group relative">
          <TextArea
            value={inputQuestion}
            onChange={(value) => setInputQuestion(value)}
            maxLength={150}
            placeholder={
              askAnonymously
                ? `Type your question. \nIt will be anonymous`
                : 'Type your question.'
            }
            hideScrollbar={true}
          />
          {inputQuestion.length > 0 && (
            <button
              onClick={() => handleAskQuestion()}
              className="absolute text-xs font-medium px-1.5 py-0.5 rounded-md cursor-pointer bottom-1 right-1 hover:shadow-md duration-200 text-white bg-purple"
            >
              Submit
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const TABS = {
  RECENT: 'Recent',
  UPVOTED: 'Upvoted',
};

export const Questions = ({ scenes, onClose }) => {
  const eventQuestions = useSelector(
    (state) => state.meetingState.state?.eventQuestions,
    shallowEqual
  );
  const { user } = useSelector((_st) => _st.auth, shallowEqual);
  const { userId } = user || {};
  const { meetingId, users, isLive } = useMeeting();
  const currentUser = users[userId];
  const isModerator = isMeetingController(currentUser);
  const [selectedTab, setSelectedTab] = useState(TABS.RECENT);

  const sortedQuestions = (eventQuestions || [])
    .slice()
    .filter((q) => !q?.dismissed)
    .sort((q1, q2) => {
      if (selectedTab === TABS.RECENT) {
        return (
          new Date(q1.timestamp).getTime() - new Date(q2.timestamp).getTime()
        );
      } else {
        return (
          Object.keys(q2?.upvotes || {}).length -
          Object.keys(q1?.upvotes || {}).length
        );
      }
    })
    .sort((q1, q2) => {
      if (q1?.answered === q2?.answered) {
        return 0;
      }
      if (q1?.answered && !q2?.answered) {
        return 1;
      }
      if (q2?.answered && !q1?.answered) {
        return -1;
      }
      return 0;
    });

  const qaSceneIndex = (scenes || []).findIndex(
    (scene) =>
      scene.sceneTemplateKey === sceneTemplateKeyMap['q&a'] ||
      scene.sceneTemplateKey === sceneTemplateKeyMap['q&a_no_speaker']
  );

  const handleVoteButtonClick = useCallback(
    (questionId, hasUpvoted) => {
      sendEvent(userId, meetingId, {
        type: hasUpvoted ? 'REMOVE_VOTE_QUESTION' : 'UPVOTE_QUESTION',
        userId,
        data: { questionId },
      });
    },
    [meetingId, userId]
  );

  const handleRemoveQuestion = useCallback(
    (questionId) => {
      sendEvent(userId, meetingId, {
        type: 'REMOVE_QUESTION',
        userId,
        data: { questionId },
      });
    },
    [meetingId, userId]
  );

  const handleStageQuestion = useCallback(
    async (questionId) => {
      sendEvent(userId, meetingId, {
        type: 'STAGE_QUESTION',
        userId,
        data: { questionId },
      });
    },
    [meetingId, userId]
  );

  return (
    <>
      <div className="flex flex-col max-h-full overflow-auto purple-scrollbar">
        <div className="px-3 pt-3 flex flex-col border-b border-[#DFE1E6] gap-2">
          <div className="flex justify-between">
            <div className="flex gap-3 font-medium text-blue-dark text-sm items-center">
              <TwoPersonFilledIcon width="20px" height="14px" />
              Audience Questions
            </div>
            <button
              onClick={onClose}
              className="w-5 h-5 bg-blue-gray rounded-full text-white text-lg flex items-center justify-center"
            >
              ×
            </button>
          </div>
          <div className="flex gap-3 font-medium text-sm ">
            <button
              onClick={() => setSelectedTab(TABS.RECENT)}
              className={classNames(
                'relative pb-3',
                selectedTab === TABS.RECENT
                  ? 'text-blue-dark'
                  : 'text-blue-gray'
              )}
            >
              {selectedTab === TABS.RECENT && (
                <span className="absolute bottom-0 w-full h-[3px] bg-purple"></span>
              )}
              {TABS.RECENT}
            </button>
            <button
              onClick={() => setSelectedTab(TABS.UPVOTED)}
              className={classNames(
                'relative pb-3',
                selectedTab === TABS.UPVOTED
                  ? 'text-blue-dark'
                  : 'text-blue-gray'
              )}
            >
              {selectedTab === TABS.UPVOTED && (
                <span className="absolute bottom-0 w-full h-[3px] bg-purple"></span>
              )}
              {TABS.UPVOTED}
            </button>
          </div>
        </div>
        <div className="flex flex-col">
          {sortedQuestions &&
            sortedQuestions.map((q) => (
              <Question
                question={q}
                user={user}
                handleVoteButtonClick={handleVoteButtonClick}
                key={q.id}
                isModerator={isModerator}
                handleRemoveQuestion={handleRemoveQuestion}
                handleStageQuestion={handleStageQuestion}
                qaSceneIndex={qaSceneIndex}
                isLive={isLive}
              />
            ))}
        </div>
      </div>
      <Ask user={user} meetingId={meetingId} />
    </>
  );
};
