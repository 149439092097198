import { getAuth } from '../auth';
import mixpanel from 'mixpanel-browser';

const initialState = getAuth();
const authentication = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: action.user,
        viewedBlocks: action.user.viewedBlocks ?? [],
      };
    case 'LOGIN_SUCCESS':
    case 'REGISTER_SUCCESS':
      return {
        authenticated: true,
        user: action.user,
        viewedBlocks: action.user.viewedBlocks ?? [],
        token: action.token,
        recordingUser: action.user.recordingUser || false,
      };
    case 'LOGIN_FAILURE':
      return {
        ...state,
        error: action.error,
      };
    case 'LOGOUT':
      return {
        authenticated: false,
      };
    case 'SET_WORKSPACE':
      mixpanel.register({ workspaceId: action.workspaceId });
      return {
        ...state,
        workspaceId: action.workspaceId,
      };
    case 'ADD_VIEWED_BLOCK':
      return {
        ...state,
        viewedBlocks: [...(state.viewedBlocks ?? []), action.viewedBlock],
      };
    case 'SET_USER_PERSONAS':
      return {
        ...state,
        personas: action.personas,
      };
    default:
      return state;
  }
};

export default authentication;
