import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { InputContainer } from './Input.style';
import { TextInput } from './TextInput';
import { InputExplanation, FieldContainer } from '../SettingsInput';
import { useAuthoringTool } from '../authoring/hooks';
import { getDynamicValueMatch } from 'zync-common/helper/overrideDynamicValue';
import { getContrastColor } from 'zync-common/helper/color';

/** The default color select palette. */
export const defaultColorSelectOptions = [
  '#ca2e5b',
  '#dc507a',
  '#cb3c34',
  '#d75744',
  '#f2b24c',
  '#fffd54',
  '#47875b',
  '#7ac078',
  '#60a6b9',
  '#44a1a4',
  '#2860a4',
  '#4177d3',
  '#b475da',
  '#844ba7',
  '#834820',
  '#ffffff',
  '#808080',
  '#000000',
];

export const brandColorOptions = [
  '#8B33F7',
  '#0315E1',
  '#066DE8',
  '#F8155C',
  '#FF5315',
  '#FF8B28',
];

/** The size of an individual color box. */
const colorItemSize = '20px';

/** A container for the color grid. */
const ColorsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  margin: -${({ theme }) => theme.spacing(0.5)}px;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.7;
      > ${() => ColorItemContainer} {
        cursor: default;
      }
    `}
`;

/** One color box in the color grid. Accepts `color` and `active` properties. */
export const ColorItemContainer = styled.div`
  width: ${colorItemSize};
  height: ${colorItemSize};
  flex: 0 0 ${colorItemSize};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: ${({ theme }) => theme.spacing(0.5)}px;

  ${({ color, active }) => css`
    // the color of the item
    background-color: ${color};
    // the active item has a border.
    box-shadow: ${active ? `0 0 1px 2px white, 0 0 1px 3px black` : 'none'};
    &:hover {
      box-shadow: 0 0 1px 2px white, 0 0 1px 3px rgba(0, 0, 0, 0.5) !important;
    }
  `}
`;

/**
 * A grid of colors with the ability to select a color
 * and listen to changes in color selection.
 */
export const SelectColorGrid = ({
  /** The selected color. */
  value,

  /** Handle when a new value is selected. */
  onChange,

  /**
   * List of available colors (any valid CSS color value).
   * Defaults to @see defaultColorSelectOptions
   */
  colors,

  /** Whether to show the colors as disabled and prevent selection. */
  disabled = false,

  brandKitColors,
}) => {
  const { currentSeries } = useAuthoringTool();

  const brandKitColorSelected = getMatchingBrandKitColor(
    value,
    currentSeries.workspace.brandKit
  );

  const isDynamicColorSelected = (key) =>
    brandKitColors.find((color) => color.key.includes(brandKitColorSelected))
      ?.key === key;

  const rejectBrandKitFromStandardColors = (newColor) => {
    const colors = brandKitColors.map(({ color }) => color);

    return !colors.includes(newColor);
  };

  return (
    <ColorsContainer disabled={disabled}>
      {brandKitColors.filter(Boolean).map(({ color, label, key }) => (
        <ColorItemContainer
          key={key}
          color={color}
          active={value === key || isDynamicColorSelected(key)}
          onClick={disabled ? undefined : () => onChange(key)}
        >
          <span
            className="h-full w-full grid place-content-center"
            style={{
              color: getContrastColor(color),
            }}
          >
            {label}
          </span>
        </ColorItemContainer>
      ))}
      {(colors?.length ? colors : defaultColorSelectOptions)
        .filter(rejectBrandKitFromStandardColors)
        .map((color) => (
          <ColorItemContainer
            key={color}
            color={color}
            active={value === color}
            onClick={disabled ? undefined : () => onChange(color)}
          />
        ))}
    </ColorsContainer>
  );
};

const getMatchingBrandKitColor = (color, brandKit, asVariable = false) => {
  for (const field in brandKit) {
    if (color === brandKit[field]) {
      return asVariable ? `{{${field}}` : field;
    }
  }
};

/**
 * An input for selecting a color from a list of predetermined color choices.
 */
export const ColorSelectInput = ({
  /** Colors available for selection. */
  colors = [],

  /** The label value to briefly describe the input. */
  label = '',

  /** A longer explanation of the input's purpose, if needed. */
  explanation = '',

  /** Whether the input is disabled. false by default. */
  disabled = false,

  /** The default value. Must be a value in the colors array. */
  defaultValue = '',

  /** Whether to allow custom input values (arbitrary CSS color strings). Defaults to false. */
  enableCustomInput = true,

  /** Handles a color selection change. Passes the value of the color. */
  onChange,
}) => {
  const [color, setColor] = useState(defaultValue ?? '');
  const colorInOptions = useMemo(() => colors.includes(color), [colors, color]);
  const isDynamicColor = !!getDynamicValueMatch(color)[1];

  const handleChange = useCallback(
    (color) => {
      setColor(color);
      onChange(color);
    },
    [onChange, setColor]
  );

  const isEmpty = color === '';
  useEffect(() => {
    if (isEmpty && defaultValue) {
      setColor(defaultValue);
    }
  }, [defaultValue, isEmpty]);

  const { currentSeries } = useAuthoringTool();

  const {
    accentColor,
    accentContrast,
    primaryColor,
    backgroundColor,
    primaryContrast,
  } = currentSeries?.workspace?.brandKit || {};

  const brandKitColors = [
    { key: '{{accentColor}}', color: accentColor, label: 'A' },
    {
      key: '{{accentContrast}}',
      color: accentContrast,
      label: <span className="text-xxs">Ac</span>,
    },
    { key: '{{primaryColor}}', color: primaryColor, label: 'P' },
    { key: '{{primaryContrast}}', color: primaryContrast, label: 'C' },
    { key: '{{backgroundColor}}', color: backgroundColor, label: 'B' },
  ].filter(({ color }) => color);

  return (
    <FieldContainer>
      {label && (
        <label className="text-blue-dark font-medium text-sm mb-2">
          {label}
        </label>
      )}
      <InputContainer>
        <SelectColorGrid
          colors={
            colorInOptions || isDynamicColor ? colors : [...colors, color]
          }
          disabled={disabled}
          value={color}
          onChange={handleChange}
          brandKitColors={brandKitColors}
        />
        {enableCustomInput && (
          <TextInput
            value={
              brandKitColors.find((bkColor) => bkColor.color === color)?.key ||
              color
            }
            onChange={(event, value) => handleChange(value)}
            type="text"
            placeholder="Custom Color"
          />
        )}
      </InputContainer>

      {explanation && <InputExplanation> {explanation}</InputExplanation>}
    </FieldContainer>
  );
};
