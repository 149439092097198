import React, { useEffect, useRef, useState } from 'react';
import { Editor, EditorState, RichUtils } from 'draft-js';
import getDefaultKeyBinding from 'draft-js/lib/getDefaultKeyBinding';

import { useKeyboardShortcuts } from 'use-keyboard-shortcuts';
import {
  fromRawContentState,
  getCustomStylesFromState,
  inlineStyleMap,
  toRawContentState,
} from '../components/richText/helpers';
import { strategyDecorator } from '../components/richText/editor/helper';

const customCommands = {
  SHIFT_ENTER_SAVE: 'shift-enter-save', // draft.js convention
};

const customKeyBindingFn = (e) => {
  if (e.keyCode === 13) {
    if (e.nativeEvent.shiftKey) {
      return customCommands.SHIFT_ENTER_SAVE;
    }
  }

  return getDefaultKeyBinding(e);
};

const createFromContent = (initialValue) => {
  const newEditorState = EditorState.createWithContent(
    fromRawContentState(initialValue),
    strategyDecorator
  );

  getCustomStylesFromState(newEditorState, 'color');

  return newEditorState;
};

/** Use with draft.js Editor component */
export const useRichTextEditor = ({
  initialValue = '', // must be falsy or be the raw content state (see toRawContentState usages for examples)
  handleUpdate,
  isActive,
  maxLength,
  onBlur,
  placeholder,
}) => {
  const editorRef = useRef(null);

  const [editorState, setEditorState] = useState(
    initialValue
      ? createFromContent(initialValue)
      : EditorState.createEmpty(strategyDecorator)
  );

  useEffect(() => {
    const newEditorState = EditorState.createWithContent(
      fromRawContentState(initialValue),
      strategyDecorator
    );

    getCustomStylesFromState(newEditorState, 'color');

    setEditorState(newEditorState);
  }, [initialValue]);

  const textValue = editorState
    ? toRawContentState(editorState?.getCurrentContent())
    : null;

  useEffect(() => {
    if (isActive) {
      setEditorState(EditorState.moveFocusToEnd(editorState));
    }
    /* we only want to run this effect when isActive changes */
    /* eslint-disable-next-line */
  }, [isActive]);

  useKeyboardShortcuts(
    [
      {
        keys: ['shift', 'Enter'],
        onEvent: () => {
          handleUpdate(textValue);
        },
      },
    ],
    isActive,

    [textValue]
  );

  const handleKeyCommand = (command) => {
    if (command === customCommands.SHIFT_ENTER_SAVE) {
      return 'handled';
    }

    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      setEditorState(newState);
      return 'handled';
    }

    return 'not-handled';
  };

  const handleBeforeInput = (input) => {
    const content = editorState.getCurrentContent();
    const currentLength = content.getPlainText().length;
    if (currentLength >= maxLength) {
      return 'handled';
    }
    return 'not-handled';
  };

  const handlePastedText = (text) => {
    const content = editorState.getCurrentContent();
    const currentLength = content.getPlainText().length;
    if (currentLength + text.length > maxLength) {
      return 'handled';
    }
    return 'not-handled';
  };

  return {
    editorState,
    setEditorState,
    textValue: editorState?.getCurrentContent().getPlainText(),
    Editor: (
      <Editor
        editorRef={editorRef}
        editorState={editorState}
        onChange={(editorState) => {
          setEditorState(editorState);
        }}
        handleBeforeInput={handleBeforeInput}
        handlePastedText={handlePastedText}
        onBlur={
          onBlur ? () => onBlur(editorState) : () => handleUpdate(textValue)
        }
        stripPastedStyles
        handleKeyCommand={handleKeyCommand}
        readOnly={!isActive}
        keyBindingFn={customKeyBindingFn}
        customStyleMap={inlineStyleMap}
        placeholder={placeholder}
      />
    ),
  };
};
