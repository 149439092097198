const defaultBrandkitCombinations = [
  ['#003049', '#FDF0D5'],
  ['#003049', '#FFEC88'],
  ['#570007', '#FEE440'],
  ['#141212', '#FF350E'],
  ['#141212', '#00F5D4'],
  ['#141212', '#FFFF3F'],
  ['#121212', '#1ED760'],
  ['#121212', '#FFFFFF'],
  ['#5C4B51', '#F2EBBF'],
  ['#534945', '#FFE4D9'],
  ['#CDD5C6', '#485E66'],
  ['#181722', '#EBEBEB'],
  ['#DE5757', '#F2F2F2'],
  ['#2C323C', '#BBE3FA'],
  ['#29402F', '#E7FF0F'],
  ['#780000', '#EEEEEE'],
  ['#FDF0D3', '#FF0059'],
  ['#311955', '#FFECFE'],
];

const getRandomColorCombination = () => {
  const randomIndex = Math.floor(
    Math.random() * defaultBrandkitCombinations.length
  );
  return defaultBrandkitCombinations[randomIndex];
};

module.exports = {
  getRandomColorCombination,
  defaultBrandkitCombinations,
};
