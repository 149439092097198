import React from 'react';

import { Cards } from '../Cards';
import { UpgradeButton } from '../UpgradeButton';

import MessagePartials from './MessagePartials';

const StreamingUnsupportedSuperAdmin = ({
  plan,
  isSubscriptionSetup,
  isSubscriptionActive,
}) => {
  const { name } = plan;

  return (
    <div className="flex justify-between items-center">
      <div className="flex-grow w-full px-5">
        <MessagePartials.FeatureNotSupported
          planName={name}
          featureName="streaming"
        />
        <MessagePartials.UnlockPotential />
      </div>
      {isSubscriptionSetup ? (
        <Cards plan={plan} isSubscriptionActive={isSubscriptionActive} />
      ) : (
        <UpgradeButton />
      )}
    </div>
  );
};

export default StreamingUnsupportedSuperAdmin;
