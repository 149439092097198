import { CompositeDecorator } from 'draft-js';

const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
};

const Link = ({ children, contentState, entityKey }) => {
  const { url } = contentState.getEntity(entityKey).getData();
  return (
    <a href={url} target="_blank" rel="noreferrer" className="underline italic">
      {children}
    </a>
  );
};

/** Link decorator we need to add to state, in order to teach DraftJS about a new entity */
export const strategyDecorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link,
  },
]);

/**
 * Function returns collection of currently selected DraftJS blocks.
 */
export const getSelectedBlocksMap = (editorState) => {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const startKey = selectionState.getStartKey();
  const endKey = selectionState.getEndKey();
  const blockMap = contentState.getBlockMap();
  return blockMap
    .toSeq()
    .skipUntil((_, k) => k === startKey)
    .takeUntil((_, k) => k === endKey)
    .concat([[endKey, blockMap.get(endKey)]]);
};

/**
 * Function returns collection of currently selected DraftJS blocks.
 */
export function getSelectedBlocksList(editorState) {
  return getSelectedBlocksMap(editorState).toList();
}

/**
 * Function returns the first selected DraftJS block.
 */
export function getSelectedBlock(editorState) {
  if (editorState) {
    return getSelectedBlocksList(editorState).get(0);
  }
  return undefined;
}

/**
 * This function returns the entity applicable to SelectionState.
 * Two or more blocks cannot contain the same entity (such as Link)
 */
export const getSelectionEntity = (editorState) => {
  let entity;
  const selection = editorState.getSelection();
  let start = selection.getStartOffset();
  let end = selection.getEndOffset();
  if (start === end && start === 0) {
    end = 1;
  } else if (start === end) {
    start -= 1;
  }
  const block = getSelectedBlock(editorState);

  for (let i = start; i < end; i += 1) {
    const currentEntity = block.getEntityAt(i);
    if (!currentEntity) {
      entity = undefined;
      break;
    }
    if (i === start) {
      entity = currentEntity;
    } else if (entity !== currentEntity) {
      entity = undefined;
      break;
    }
  }
  return entity;
};
