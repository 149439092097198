import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Draggable from 'react-draggable';
import styled from 'styled-components/macro';
import classNames from '../../../helper/classNames';
import { DeleteSceneConfirmation } from '../../Modal';
import { UserSettingsContext } from '../userSettingsContext';
import { PlusIcon } from '../../icons/PlusIcon';
import { ScenePreview } from './ScenePreview';
import { useAuthoringTool } from '../hooks';
import { useElementSize } from '../../../hooks/useElementSize';
import { H4Text } from '../../common/Text';
import { DeleteItemButton } from '../../common/CloseButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../common/Button';
import { useStartLoading } from '../../../hooks/useStartLoading';
import {
  AuthoringContextMenuStandardActions,
  ContextMenu,
} from '../../ContextMenu';
import { debounce } from 'lodash';

const SCROLLABLE_CONTAINER_TAG_ID = 'scrollableContainer';
const SMOOTH_ANIMATION_LENGTH = 1000;

const SLIDE_DRAG_DIRECTIONS = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};

/* Helper function for determining whether an element overflows parent edges. Useful for SlideTray */
const checkIsElementOverParentsEdge = (containerElement, element) => {
  const currentScrollLeft = containerElement.scrollLeft;
  const trayVisibleWidth = containerElement.clientWidth;

  const targetOffset = element.offsetLeft;
  const targetWidth = element.clientWidth;

  const isOffscreenToLeft = targetOffset < currentScrollLeft;
  const isOffscreenToRight =
    targetOffset + targetWidth > currentScrollLeft + trayVisibleWidth;

  return {
    targetOffset,
    isOffscreenToRight,
    isOffscreenToLeft,
  };
};

export const SlideTrayContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  position: relative;
  z-index: 2;
  flex: 1;

  > ${() => SlideTrayScrollableContainer} {
    flex-grow: 1;
  }
`;

export const SlideTrayScrollableContainer = styled.div`
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  gap: 20px;
  padding: 20px;
  ${({ isDragging }) => isDragging && 'user-select: none'};
  & .react-draggable-dragging {
    z-index: 100;
  }
  > div {
    scroll-snap-align: center;
  }
`;

export const SlideTrayItemContainer = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: ${({ width, vertical }) =>
    vertical ? (width * 9) / 16 : width}px;
  width: ${({ width }) => width}px;
  height: ${({ width }) => (width * 9) / 16}px;
  cursor: pointer;

  > img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }

  &.react-draggable-dragging {
    z-index: 5;
  }

  ${() => DeleteItemButton} {
    display: none;
  }

  &:hover ${() => DeleteItemButton} {
    display: flex;
  }
`;

export const SlideTrayAddSceneContainer = styled(SlideTrayItemContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;

  background: #ffffff;

  border: 1px solid #ebeaed;
  box-sizing: border-box;

  box-shadow: inset 0px -2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  > img {
    width: unset;
    height: unset;
  }

  &:hover {
    box-shadow: inset 0px -2px 6px rgba(0, 0, 0, 0.1);
  }

  &:active {
    background: rgba(0, 0, 0, 0.01);
    box-shadow: inset 0px -2px 8px rgba(0, 0, 0, 0.1);

    > * {
      transform: translateY(2px);
    }
  }
`;

const angleStyle = {
  color: '#FFFFFF',
  fontSize: '12px',
};
/** A left or right slide button */
const ScrollSlideButton = ({ direction, onScroll }) => {
  return (
    <button
      className="min-w-[32px] min-h-[32px] flex items-center justify-center self-center border-0 bg-black/60 rounded-full p-0 hover:cursor-pointer"
      onClick={() => onScroll(direction)}
    >
      <FontAwesomeIcon
        icon={direction === 'left' ? faAngleLeft : faAngleRight}
        style={angleStyle}
      />
    </button>
  );
};

const DraggableSlide = ({
  children,
  onDrag,
  onStart,
  onStop,
  disabled = false,
  nodeRef,
}) => {
  const position = useRef({ x: 0, y: 0 });

  return (
    <Draggable
      axis="x"
      onStart={onStart}
      onStop={disabled ? undefined : onStop}
      position={position.current}
      nodeRef={nodeRef}
      disabled={disabled}
      onDrag={onDrag}
    >
      {children}
    </Draggable>
  );
};

export const getSlideName = (slide, index) => {
  return slide.slideName || 'Scene ' + (index + 1);
};

/** Returns an array with elements swapped between oldIndex and newIndex. */
export function swap(array, oldIndex, newIndex) {
  const newArray = array.slice();
  newArray.splice(newIndex, 0, newArray.splice(oldIndex, 1)[0]);
  return newArray;
}

const handleContainerWheel =
  (containerElement, handleUpdateOffset) => (event) => {
    event.preventDefault();

    if (containerElement) {
      containerElement.scrollBy({
        left: event.deltaY + event.deltaX,
      });

      handleUpdateOffset(containerElement.scrollLeft);

      return false;
    }
  };

/** A horizontal scrollable slide tray to select and reorder scenes in the series. */
export const SlideTray = ({
  /** Array of slides from the meeting series. */
  slides,
  /** Optional handler for adding a scene. If specified, includes an Add Scene button. */
  onAddScene,
  /** Optional handler for clicking on a scene. If not specified, defaults to `openTemplateSlide` from authoring context. */
  onSelectScene,
  /** Optional handler for reordering the scenes. If not specified, defaults to `reorderTemplateSlides` from authoring context. */
  onReorderScenes,
  /** Whether reordering is enabled. */
  enableReorder = true,
  /** The selected slide from `slides`. Defaults to `selectedSlide` from authoring context. */
  selectedSlide,
  /** Whether deletion is enabled. */
  enableDelete = true,
  /** Optional. A list of block templates. */
  blockTemplates,
  /** Optional handler for when a slide tray item gains focus. */
  onFocus,
  /** Optional handler for when a slide tray item loses focus. */
  onBlur,
  /** Optional handler for when the user scrolls the slide tray. */
  onScroll,
  showDeleteSceneConfirmationModal,
  hideDeleteSceneConfirmationModal,
  deleteSceneConfirmationModalOpen = false,
  brandKit,
  users,
}) => {
  const { isDeleteSceneHidden } = useContext(UserSettingsContext);
  const {
    openTemplateSlide,
    selectedSlide: authoringSelectedSlide,
    reorderTemplateSlides,
    getBlockById: authoringGetBlockById,
    deleteTemplateScene,
    copySelectedScene,
    addNewSceneFromTemplate,
    handleCopy,
    copiedScene,
    pasteScene,
    currentSeries,
  } = useAuthoringTool();

  const {
    settings: { allowAdvancedAuthoring = false },
  } = currentSeries || {};

  onSelectScene = onSelectScene ?? openTemplateSlide;
  onReorderScenes = onReorderScenes ?? reorderTemplateSlides;
  selectedSlide = selectedSlide ?? authoringSelectedSlide;

  const getBlockById = useCallback(
    () =>
      authoringGetBlockById ??
      ((blockId) =>
        (blockTemplates ?? []).find((b) => b.blockId === blockId) ?? null),
    [authoringGetBlockById, blockTemplates]
  );
  const [displayedSlides, setDisplayedSlides] = useState(slides);
  const [showNavigationArrows, setShowNavigationArrows] = useState(false);
  const [nextIndexWhileDragging, setNextIndexWhileDragging] = useState(null);

  const {
    ref: setContainerRef,
    width: containerSize,
    element: containerElement,
  } = useElementSize();

  const [currentTargetOffset, setCurrentTargetOffset] = useState(null);

  const handleScroll = useCallback(
    (direction) => {
      if (!containerElement) {
        return;
      }
      const currentScrollLeft = containerElement.scrollLeft;
      const child = containerElement.querySelector('div');
      const childBounding = child.getBoundingClientRect();
      const width = childBounding.width;
      const clientWidth = containerElement.clientWidth;
      const maximumScrollLeft = containerElement.scrollWidth - clientWidth;
      const targetLeft =
        direction === 'right'
          ? Math.min(maximumScrollLeft, currentScrollLeft + width)
          : Math.max(0, currentScrollLeft - width);
      containerElement.scrollTo({
        left: targetLeft,
        top: 0,
        behavior: 'smooth',
      });
      setTimeout(() => {
        setCurrentTargetOffset(targetLeft);
      }, SMOOTH_ANIMATION_LENGTH);
      onScroll && onScroll();
    },
    [containerElement, onScroll]
  );

  useEffect(() => {
    const checkIfOverflow = (el) => {
      const currentOverflow = el.style.overflow;

      if (!currentOverflow || currentOverflow === 'visible')
        el.style.overflow = 'hidden';

      const isOverflowing = el.clientWidth < el.scrollWidth;

      el.style.overflow = currentOverflow;

      return isOverflowing;
    };

    if (containerElement) {
      const isOverflow = checkIfOverflow(containerElement);
      setShowNavigationArrows(isOverflow);
    }
  }, [
    setShowNavigationArrows,
    displayedSlides,
    containerSize,
    containerElement,
  ]);

  const [indicesForVisibleBlocks, setIndicesForVisibleBlocks] = useState({
    min: 0,
    max: 0,
  });

  useEffect(() => {
    if (slides.length <= 0) return;
    if (containerElement === null) return;
    let result = [];

    Array.from(containerElement.children).forEach((element, index) => {
      const { isOffscreenToLeft, isOffscreenToRight } =
        checkIsElementOverParentsEdge(containerElement, element);

      if (!isOffscreenToLeft && !isOffscreenToRight) {
        result.push(index);
      }

      const adjustment = 1; // display 1 more to the left and to the right for better UX when scrolling

      setIndicesForVisibleBlocks({
        min: result[0] - adjustment,
        max: result[result.length - 1] + adjustment,
      });
    });
  }, [containerElement, slides, currentTargetOffset, containerSize]);

  useEffect(() => {
    if (slides.length <= 0) return;

    const slideIndex = displayedSlides.findIndex(
      (slide) => slide?.sceneId === selectedSlide?.sceneId
    );
    if (slideIndex !== -1 && containerElement !== null) {
      const element = containerElement.children.item(slideIndex);
      if (element) {
        const { targetOffset, isOffscreenToLeft, isOffscreenToRight } =
          checkIsElementOverParentsEdge(containerElement, element);

        if (isOffscreenToLeft || isOffscreenToRight) {
          containerElement.scrollTo({
            left: targetOffset,
            top: 0,
            behavior: 'smooth',
          });
        }
        setTimeout(() => {
          setCurrentTargetOffset(targetOffset);
        }, SMOOTH_ANIMATION_LENGTH);
      }
    }
  }, [selectedSlide, displayedSlides, containerElement, slides.length]);

  useEffect(() => {
    setDisplayedSlides(slides);
  }, [slides]);

  const initialDraggableNodeRef = useRef(null);
  const draggableNodeRefs = useRef([]);
  const dragPosition = useRef(null);

  const findSlideIndices = useCallback(
    (elementKey, event, drag) => {
      const width = drag.node.getBoundingClientRect().width;
      const distanceX = drag.x;

      const oldIndex = displayedSlides.findIndex(
        (slide) => slide.sceneId === elementKey
      );

      const gapBetweenNodes =
        parseInt(getComputedStyle(drag.node.parentElement).gap) || 0;

      let newIndex = Math.min(
        oldIndex +
          Math.floor(Math.round(distanceX / (width + gapBetweenNodes / 2))),
        displayedSlides.length
      );

      return {
        oldIndex,
        newIndex,
      };
    },
    [displayedSlides]
  );

  const onStart = (index) => () => {
    openTemplateSlide(index);
    containerElement?.focus();
  };

  const onDrag = (elementKey) => async (event, drag) => {
    if (drag.lastX === 0) {
      return;
    }

    const { oldIndex, newIndex } = findSlideIndices(elementKey, event, drag);

    switch (true) {
      case oldIndex > newIndex: {
        dragPosition.current = SLIDE_DRAG_DIRECTIONS.LEFT;

        break;
      }
      case oldIndex < newIndex: {
        dragPosition.current = SLIDE_DRAG_DIRECTIONS.RIGHT;

        break;
      }
      default: {
        break;
      }
    }

    if (oldIndex !== newIndex) {
      setNextIndexWhileDragging(newIndex);
    } else {
      setNextIndexWhileDragging(null);
    }
  };

  const onDragEnd = (elementKey) => async (event, drag) => {
    if (drag.lastX === 0) {
      return;
    }

    const { oldIndex, newIndex } = findSlideIndices(elementKey, event, drag);

    const newSlides = swap(displayedSlides, oldIndex, newIndex);

    setDisplayedSlides(newSlides);
    setNextIndexWhileDragging(null);

    await onReorderScenes(newSlides);
  };

  const isDragging = nextIndexWhileDragging !== null;

  const handleUpdateOffset = useCallback(
    (targetOffset) => setCurrentTargetOffset(targetOffset),
    []
  );

  const element = document.getElementById(SCROLLABLE_CONTAINER_TAG_ID);

  useEffect(() => {
    if (!element) {
      return;
    }

    const wheelHandler = handleContainerWheel(
      element,
      debounce(handleUpdateOffset, 100)
    );

    // React attaches all event listeners to the body element.
    // Events, that are attached to the body element will be considered a passive event
    // Passive events have event.preventDefault() calls ignored
    // Therefore, we need to add wheel event listener directly
    element.addEventListener('wheel', wheelHandler);

    return () => {
      element.removeEventListener('wheel', wheelHandler);
    };
  }, [element, handleUpdateOffset]);

  return (
    <SlideTrayContainer>
      {enableDelete && deleteSceneConfirmationModalOpen && (
        <DeleteSceneConfirmation
          activateKeyboardShortcuts={Boolean(deleteSceneConfirmationModalOpen)}
          onCancel={hideDeleteSceneConfirmationModal}
        />
      )}
      {showNavigationArrows && (
        <ScrollSlideButton
          direction="left"
          onScroll={() => handleScroll('left')}
        />
      )}
      <SlideTrayScrollableContainer
        ref={setContainerRef}
        isDragging={isDragging}
        className="purple-scrollbar-on-hover"
        id={SCROLLABLE_CONTAINER_TAG_ID}
        tabIndex={0}
      >
        {slides.length > 0 &&
          displayedSlides.map((slide, index) => (
            <DraggableSlide
              disabled={!enableReorder}
              onDrag={onDrag(slide.sceneId)}
              onStart={onStart(index)}
              onStop={onDragEnd(slide.sceneId)}
              key={slide.sceneId}
              nodeRef={
                draggableNodeRefs.current[index] || initialDraggableNodeRef
              }
            >
              <div
                ref={(ref) =>
                  (draggableNodeRefs.current[index] = { current: ref })
                }
              >
                <SlideTrayItem
                  onSelectScene={onSelectScene}
                  enableDelete={enableDelete}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  isActive={selectedSlide?.index === index}
                  index={index}
                  draggableNodeRef={initialDraggableNodeRef}
                  onPasteScene={async () => {
                    if (copiedScene) {
                      await pasteScene();
                    }
                  }}
                  onCopyScene={async () => {
                    await handleCopy();
                  }}
                  onDeleteScene={() => deleteTemplateScene(slide.sceneId)}
                  onDuplicateScene={async () => {
                    await addNewSceneFromTemplate(
                      copySelectedScene(slide),
                      true
                    );
                  }}
                  allowAdvancedAuthoring={allowAdvancedAuthoring}
                >
                  {
                    <ThumbnailDragMarkerLeft
                      isShown={
                        dragPosition.current === SLIDE_DRAG_DIRECTIONS.LEFT &&
                        nextIndexWhileDragging === index
                      }
                    />
                  }
                  {
                    <ThumbnailDragMarkerRight
                      isShown={
                        dragPosition.current === SLIDE_DRAG_DIRECTIONS.RIGHT &&
                        nextIndexWhileDragging === index
                      }
                    />
                  }
                  {enableDelete ? (
                    <>
                      <DeleteSceneButton
                        isDeleteSceneHidden={isDeleteSceneHidden}
                        deleteTemplateScene={deleteTemplateScene}
                        sceneId={slide.sceneId}
                        showDeleteSceneConfirmationModal={
                          showDeleteSceneConfirmationModal
                        }
                      />
                      <ScenePreview
                        scene={slide}
                        getBlockById={getBlockById}
                        hasBlocksVisible={
                          index >= indicesForVisibleBlocks.min &&
                          index <= indicesForVisibleBlocks.max
                        }
                        brandKit={brandKit}
                        users={users}
                      />
                    </>
                  ) : (
                    <ScenePreview
                      scene={slide}
                      getBlockById={getBlockById}
                      width={(120 * 16) / 9}
                      height={120}
                      hasBlocksVisible={
                        index >= indicesForVisibleBlocks.min &&
                        index <= indicesForVisibleBlocks.max
                      }
                      brandKit={brandKit}
                      users={users}
                    />
                  )}
                  <div
                    draggable={false}
                    className={classNames(
                      'block text-xxs text-left mt-2.5 whitespace-nowrap text-ellipsis overflow-hidden leading-[15px] tracking-normal',
                      selectedSlide?.index === index
                        ? 'text-blue-dark font-bold'
                        : 'text-blue-gray font-semibold'
                    )}
                  >
                    {getSlideName(slide, index)}
                  </div>
                </SlideTrayItem>
              </div>
            </DraggableSlide>
          ))}
        {onAddScene && allowAdvancedAuthoring && (
          <SlideTrayAddSceneContainer
            onClick={onAddScene}
            draggable={false}
            vertical={false}
            width={(80 * 16) / 9}
          >
            <PlusIcon /> <H4Text>New Scene</H4Text>
          </SlideTrayAddSceneContainer>
        )}
      </SlideTrayScrollableContainer>
      {showNavigationArrows && (
        <ScrollSlideButton
          direction="right"
          onScroll={() => handleScroll('right')}
        />
      )}
    </SlideTrayContainer>
  );
};

const DeleteSceneButton = ({
  isDeleteSceneHidden,
  deleteTemplateScene,
  sceneId,
  showDeleteSceneConfirmationModal,
  disabled,
}) => {
  const { isLoading, startLoading } = useStartLoading();

  return (
    <div
      className={`hidden absolute -top-4 -right-4 z-[3] h-7 w-7 group-hover:flex rounded-full shadow-800 ${
        disabled && 'opacity-0'
      }`}
    >
      <Button
        onClickCapture={
          isDeleteSceneHidden
            ? (event) => {
                startLoading();
                deleteTemplateScene(sceneId);
                event.stopPropagation();
              }
            : () => showDeleteSceneConfirmationModal(sceneId)
        }
        padding={Button.padding.NONE}
        border={Button.border.ROUNDED}
        size={Button.sizes.FULL}
        state={
          disabled ? Button.states.DISABLED : isLoading && Button.states.LOADING
        }
      >
        <span className="text-black">&times;</span>
      </Button>
    </div>
  );
};

const ThumbnailDragMarkerLeft = ({ isShown }) => {
  return (
    isShown && (
      <div className="absolute w-1 h-4/5 bg-purple z-10 left-0 -translate-x-3" />
    )
  );
};

const ThumbnailDragMarkerRight = ({ isShown }) => {
  return (
    isShown && (
      <div className="absolute w-1 h-4/5 bg-purple z-10 right-0 translate-x-3 " />
    )
  );
};

const SlideTrayItem = memo(
  ({
    children,
    index,
    isActive,
    enableDelete,
    onFocus,
    onBlur,
    draggableNodeRef,
    onSelectScene,
    onCopyScene,
    onPasteScene,
    onDuplicateScene,
    onDeleteScene,
    allowAdvancedAuthoring,
  }) => {
    const contentRef = useRef(null);

    const [isSelected, setIsSelected] = useState(false);
    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

    const handleSelectScene = useCallback(() => {
      setTimeout(() => {
        setIsSelected(true);
        setTimeout(() => {
          onSelectScene(index);
        }, 0);
      }, 0);
    }, [index, onSelectScene]);

    const handleOpenContextMenu = (event) => {
      event.preventDefault();

      if (allowAdvancedAuthoring) {
        onSelectScene(index);
        setIsContextMenuOpen(true);
      }
    };

    const handleCloseContextMenu = () => {
      setIsContextMenuOpen(false);
    };

    const handleCopyScene = () => {
      onCopyScene && onCopyScene();
      setIsContextMenuOpen(false);
    };
    const handlePasteScene = () => {
      onPasteScene && onPasteScene();
      setIsContextMenuOpen(false);
    };

    const handleDuplicateScene = () => {
      onDuplicateScene && onDuplicateScene();
      setIsContextMenuOpen(false);
    };

    const handleRemoveScene = () => {
      onDeleteScene && onDeleteScene();
      setIsContextMenuOpen(false);
    };

    useEffect(() => {
      if (!isActive) {
        setIsSelected(false);
      }
    }, [isActive]);

    return (
      <SlideTrayItemContainer
        ref={draggableNodeRef}
        onClick={handleSelectScene}
        draggable={false}
        width={enableDelete ? (80 * 16) / 9 : (120 * 16) / 9}
        vertical={false}
        onFocus={onFocus}
        onBlur={onBlur}
        tabIndex={0}
        onContextMenu={handleOpenContextMenu}
        className={classNames(
          'text-transparent border-4 border-solid rounded-sm box-content group',
          isSelected && !isActive && 'border-purple border-opacity-50',
          isActive && 'border-purple border-opacity-100'
        )}
      >
        <div ref={contentRef}>{children}</div>
        {isContextMenuOpen && (
          <ContextMenu
            onClose={handleCloseContextMenu}
            className="w-[142px] -translate-y-[calc(100%+15px)]"
            containerId="modal-root"
            parentRef={contentRef}
          >
            <AuthoringContextMenuStandardActions
              handleCopy={handleCopyScene}
              handlePaste={handlePasteScene}
              handleDuplicate={handleDuplicateScene}
              handleRemove={handleRemoveScene}
            />
          </ContextMenu>
        )}
      </SlideTrayItemContainer>
    );
  }
);
