export const OpenHamburgerIcon = () => {
  return (
    <svg
      width="28"
      height="22"
      viewBox="0 0 28 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 2C0.5 1.60218 0.658035 1.22064 0.93934 0.93934C1.22064 0.658036 1.60218 0.5 2 0.5H26C26.3978 0.5 26.7794 0.658036 27.0607 0.93934C27.342 1.22064 27.5 1.60218 27.5 2C27.5 2.39782 27.342 2.77936 27.0607 3.06066C26.7794 3.34196 26.3978 3.5 26 3.5H2C1.60218 3.5 1.22064 3.34196 0.93934 3.06066C0.658035 2.77936 0.5 2.39782 0.5 2ZM0.5 11C0.5 10.6022 0.658035 10.2206 0.93934 9.93934C1.22064 9.65804 1.60218 9.5 2 9.5H26C26.3978 9.5 26.7794 9.65804 27.0607 9.93934C27.342 10.2206 27.5 10.6022 27.5 11C27.5 11.3978 27.342 11.7794 27.0607 12.0607C26.7794 12.342 26.3978 12.5 26 12.5H2C1.60218 12.5 1.22064 12.342 0.93934 12.0607C0.658035 11.7794 0.5 11.3978 0.5 11ZM2 18.5C1.60218 18.5 1.22064 18.658 0.93934 18.9393C0.658035 19.2206 0.5 19.6022 0.5 20C0.5 20.3978 0.658035 20.7794 0.93934 21.0607C1.22064 21.342 1.60218 21.5 2 21.5H26C26.3978 21.5 26.7794 21.342 27.0607 21.0607C27.342 20.7794 27.5 20.3978 27.5 20C27.5 19.6022 27.342 19.2206 27.0607 18.9393C26.7794 18.658 26.3978 18.5 26 18.5H2Z"
        fill="white"
      />
    </svg>
  );
};
