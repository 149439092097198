import React, { useCallback, useMemo, useState } from 'react';
import { DropdownSelect } from '../../../components/common/Dropdown';
import { DatePicker } from '../../../components/DatePicker';
import { TimePicker } from '../../../components/TimePicker';
import {
  getDatesInUTC,
  getTimezoneAbbreviation,
  validateTimes,
} from '../../../helper/rsvp';
import { timezones } from '../../../helper/timezones';
import {
  ModalWindow,
  ModalTitle,
  Modal,
  ModalBody,
} from '../../../components/Modal';
import { Button } from '../../../components/common/Button';
import { SeriesVisibility } from '../../../helper/visibility';

export const RSVPSettings = ({ scheduledEvent, setScheduledEvent }) => {
  const { startTime, endTime, daySelected, timezoneSelected } =
    scheduledEvent || {};

  const timezoneAbbreviation = useMemo(
    () => getTimezoneAbbreviation(timezoneSelected || 'Pacific Standard Time'),
    [timezoneSelected]
  );

  const setScheduledEventDaySelected = useCallback(
    (day) => {
      setScheduledEvent((oldScheduledEvent) => {
        return { ...oldScheduledEvent, daySelected: day };
      });
    },
    [setScheduledEvent]
  );

  const setScheduledEventStartTime = useCallback(
    (time) => {
      setScheduledEvent((oldScheduledEvent) => {
        return { ...oldScheduledEvent, startTime: time };
      });
    },
    [setScheduledEvent]
  );

  const setScheduledEventEndTime = useCallback(
    (time) => {
      setScheduledEvent((oldScheduledEvent) => {
        return { ...oldScheduledEvent, endTime: time };
      });
    },
    [setScheduledEvent]
  );

  const setScheduledEventTimezone = useCallback(
    (timezone) => {
      setScheduledEvent((oldScheduledEvent) => {
        return { ...oldScheduledEvent, timezoneSelected: timezone };
      });
    },
    [setScheduledEvent]
  );

  return (
    <div className="flex flex-col w-full mt-6 p-5 bg-purple/5 rounded-lg gap-5">
      <div className="flex w-full gap-8">
        <div className="flex-1 w-0">
          <DatePicker
            daySelected={scheduledEvent.daySelected}
            setDaySelected={setScheduledEventDaySelected}
            label="Date"
          />
        </div>
        <div className="flex-1 w-0">
          <TimePicker
            time={startTime}
            setTime={setScheduledEventStartTime}
            label="Start Time"
          />
        </div>
        <div className="flex-1 w-0">
          <TimePicker
            time={endTime}
            setTime={setScheduledEventEndTime}
            label="End Time"
          />
        </div>
        <div className="flex flex-col gap-2 flex-1 w-0">
          <div className="text-blue-dark text-xs font-medium">Time Zone</div>
          <DropdownSelect
            options={[
              ...timezones.map((tz) => {
                return {
                  key: tz.value,
                  title: tz.abbr,
                };
              }),
            ]}
            selectedKey={timezoneSelected}
            onSelect={(selectKey) => {
              setScheduledEventTimezone(selectKey);
            }}
            fontSize={DropdownSelect.fontSize.SMALL}
            backgroundColor={DropdownSelect.backgroundColor.WHITE}
            fontColor={DropdownSelect.fontColor.BLUE_GRAY}
            borderSize={DropdownSelect.borderSize.ONE}
            borderColor={DropdownSelect.borderColor.BLUE_GRAY}
            angleColor="#696F8C"
            optionWidth={DropdownSelect.optionWidth.SMALL}
          />
        </div>
      </div>
      <div className="text-blue-gray text-sm font-medium text-center">
        {' '}
        This event will take place on {daySelected.format(
          'Do MMM, YYYY'
        )} from {startTime} to {endTime} {timezoneAbbreviation}.
      </div>
    </div>
  );
};

export const UpdateRSVPSettingsModal = ({
  scheduledEvent,
  setScheduledEvent,
  onCancel,
  onSave,
  setSelectedValue,
  setShowUpdateRSVPSettingsModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleOnSaveClick = useCallback(async () => {
    setIsLoading(true);
    const { startTime, endTime, daySelected, timezoneSelected } =
      scheduledEvent;
    const { startDate, endDate, currentDate } = getDatesInUTC(
      daySelected,
      startTime,
      endTime,
      timezoneSelected
    );
    if (!validateTimes(currentDate, startDate, endDate)) {
      setError('The times you have selected are invalid.');
    } else {
      await onSave({
        startDate,
        endDate,
        timezone: timezoneSelected,
      });
      setSelectedValue(SeriesVisibility.RSVP);
      setShowUpdateRSVPSettingsModal(false);
    }
    setIsLoading(false);
  }, [
    onSave,
    scheduledEvent,
    setSelectedValue,
    setShowUpdateRSVPSettingsModal,
  ]);

  return (
    <ModalWindow
      onCancel={() => {
        onCancel();
        setShowUpdateRSVPSettingsModal(false);
      }}
      size={Modal.size.xl}
    >
      <ModalTitle>Edit RSVP settings</ModalTitle>
      <ModalBody>
        <RSVPSettings
          scheduledEvent={scheduledEvent}
          setScheduledEvent={setScheduledEvent}
        />
        <div className="text-red text-sm my-8">{error}</div>
      </ModalBody>
      <div className="flex my-8 mx-auto gap-4 w-1/2">
        <Button
          color={Button.colors.WHITE}
          size={Button.sizes.FULL}
          onClick={() => {
            onCancel();
            setShowUpdateRSVPSettingsModal(false);
          }}
          state={isLoading ? Button.states.DISABLED : Button.states.DEFAULT}
        >
          Cancel
        </Button>
        <Button
          color={Button.colors.PURPLE}
          size={Button.sizes.FULL}
          onClick={handleOnSaveClick}
          state={isLoading ? Button.states.LOADING : Button.states.DEFAULT}
        >
          Save
        </Button>
      </div>
    </ModalWindow>
  );
};
