import React from 'react';
import styled from 'styled-components';
import { PencilIcon } from '../icons/PencilIcon';

const BadgeEditable = ({ onClick, isEditable }) => {
  return isEditable ? (
    <Button onClick={onClick}>
      <PencilIcon />
    </Button>
  ) : null;
};

const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

export default BadgeEditable;
