const { mediaTypes } = require('../types');
const { PLANS } = require('../zyncCustomerPlans');

const marketingOptions = {
  twitter: {
    mediaType: mediaTypes.landscape,
  },
  facebook: {
    mediaType: mediaTypes.landscape,
  },
  youtube: {
    mediaType: mediaTypes.portrait,
  },
  linkedIn: {
    mediaType: mediaTypes.square,
  },
};

const areHighlightsFinishedGenerating = (highlights) => {
  if (!highlights || highlights.length === 0) return false;

  for (let i = 0; i < highlights.length; i++) {
    const highlight = highlights[i];
    if (!highlight.hasOwnProperty('media')) return false;
    if (highlight.media.length === 0) return false;
  }

  return true;
};

const isContentKitCompleted = (highlights = []) => {
  if (!areHighlightsFinishedGenerating(highlights)) return false;

  const publishableHighlights = highlights.filter(
    (h) => !!h.autoGenerateSocialMediaVideo
  );

  if (publishableHighlights.length === 0) return true;

  const finishedGeneratingPublishableHighlights = publishableHighlights.filter(
    (h) => {
      const { media = [] } = h || {};

      const reversedMedia = media.slice().reverse();
      const socialMediaVideoIndex = reversedMedia.findIndex(
        (m) =>
          m.aspectRatio ===
            marketingOptions[h.autoGenerateSocialMediaVideo]['mediaType'] &&
          m?.renderData?.playbackId
      );

      const lastIndex = media.length - 1 - socialMediaVideoIndex;
      return lastIndex !== -1 && lastIndex !== 0 ? true : false;
    }
  );

  return publishableHighlights.length ===
    finishedGeneratingPublishableHighlights.length
    ? true
    : false;
};

const isContentKitLimitReached = (
  numContentKitCompletedCurrentMonth,
  isTrial,
  plan
) => {
  if (isTrial) {
    return false;
  } else {
    switch (true) {
      case plan === PLANS.starter.id:
      case plan === PLANS.starter_annual.id:
      case plan === PLANS.enterprise.id: {
        if (numContentKitCompletedCurrentMonth >= PLANS.starter.maxContentKit) {
          return true;
        }
        return false;
      }
      case plan === PLANS.free.id: {
        if (numContentKitCompletedCurrentMonth >= PLANS.free.maxContentKit) {
          return true;
        }
        return false;
      }
      default: {
        return false;
      }
    }
  }
};

module.exports = {
  areHighlightsFinishedGenerating,
  isContentKitCompleted,
  isContentKitLimitReached,
};
