import { equals } from 'lodash/fp';
import {
  CALL_STATE_INITIALIZING,
  CALL_STATE_UNLOADED,
  BACKGROUND_TYPE_NONE,
} from '../hooks/dailyConstants';
import { logerror, loginfo } from '../helper/contextualLogger';

/**
 * Call state is comprised of:
 * - "track / track states" (audio & video inputs to the call, i.e. participants or shared screens)
 * - UI state that depends on call items (for now, just whether to show "click allow" message)
 *
 * Track states are groups in three ways:
 * - `local` for the current participant
 * - `allUsers` a map of all user ids (including the local user) to their track states
 * - the `allUsers` map also includes keys of `<id>-screen` for each shared screen
 *
 * userIdsAudioFilter: When set, only the users that appear in this array will have their audio rendered.
 * Only one block should set this value at a time.
 */
const initialCallState = {
  daily: null,
  outputDeviceId: null,
  screenShare: {
    active: false,
  },
  inputSettings: {
    video: {
      processor: {
        type: BACKGROUND_TYPE_NONE,
      },
    },
  },
  tracks: {
    local: {
      videoTrackState: null,
      audioTrackState: null,
    },
    allUsers: {},
  },
  videoSubscriptions: {},
  audioSubscriptions: {},
  pendingSubscriptions: {},
  userIdsAudioFilter: null,
  clickAllowTimeoutFired: false,
  camOrMicError: null,
  fatalError: null,
  activeSpeaker: null,
  state: CALL_STATE_UNLOADED,
};

// --- Actions ---

/**
 * DAILY_CALL_OBJECT_CREATED action structure:
 * - type: string
 * - callObject: Daily Call Object
 */
const DAILY_CALL_OBJECT_CREATED = 'DAILY_CALL_OBJECT_CREATED';

/**
 * DAILY_CALL_OBJECT_DESTROYED
 */
const DAILY_CALL_OBJECT_DESTROYED = 'DAILY_CALL_OBJECT_DESTROYED';

/**
 * CALL_STATE_CHANGE
 *  - newState: string
 */
const CALL_STATE_CHANGE = 'CALL_STATE_CHANGE';

/**
 * CLICK_ALLOW_TIMEOUT action structure:
 * - type: string
 */
const CLICK_ALLOW_TIMEOUT = 'CLICK_ALLOW_TIMEOUT';

/**
 * PARTICIPANTS_CHANGE action structure:
 * - type: string
 * - participants: Object (from Daily callObject.participants())
 */
const PARTICIPANTS_CHANGE = 'PARTICIPANTS_CHANGE';

/**
 * CLEANUP action structure:
 */
const CLEANUP = 'CLEANUP';

/**
 * CAM_OR_MIC_ERROR action structure:
 * - type: string
 * - message: string
 */
const CAM_OR_MIC_ERROR = 'CAM_OR_MIC_ERROR';

/**
 * FATAL_ERROR action structure:
 * - type: string
 * - message: string
 */
const FATAL_ERROR = 'FATAL_ERROR';

/**
 * NONFATAL_ERROR action structure:
 * - type: string
 * - message: string
 */
const NONFATAL_ERROR = 'NONFATAL_ERROR';

/**
 * SET_MUTE action structure:
 *  - type: string
 *  - userId: string
 *  - mute: bool: True if the user should be muted.
 * - reason: string: string key of why the user was muted.
 */
const SET_MUTE = 'SET_MUTE';

/**
 * SET_UNMUTE action structure:
 *  - type: string
 *  - userId: string
 *  - mute: bool:  True if the user should be unmuted.
 */
const SET_UNMUTE = 'SET_UNMUTE';

/**
 * SUBSCRIBE_VIDEO action structure:
 *  - type: string
 *  - sessionId: string
 */
const SUBSCRIBE_VIDEO = 'SUBSCRIBE_VIDEO';

/**
 * UNSUBSCRIBE_VIDEO action structure:
 *  - type: string
 *  - sessionId: string
 */
const UNSUBSCRIBE_VIDEO = 'UNSUBSCRIBE_VIDEO';

/**
 * SUBSCRIBE_AUDIO action structure:
 *  - type: string
 *  - sessionId: string
 */
const SUBSCRIBE_AUDIO = 'SUBSCRIBE_AUDIO';

/**
 * UNSUBSCRIBE_AUDIO action structure:
 *  - type: string
 *  - sessionId: string
 */
const UNSUBSCRIBE_AUDIO = 'UNSUBSCRIBE_AUDIO';

/**
 * SUBSCRIPTIONS_UPDATED action structure:
 *  - type: string
 *  - sessionId: string
 */
const SUBSCRIPTIONS_UPDATED = 'SUBSCRIPTIONS_UPDATED';

/**
 * ACTIVE_SPEAKER_CHANGE action structure:
 *  - type: string
 *  - sessionId: string
 *  - userId: string
 *  - local: bool
 */
const ACTIVE_SPEAKER_CHANGE = 'ACTIVE_SPEAKER_CHANGE';

/**
 * SET_USER_AUDIO_FILTER action structure:
 *  - type: string
 *  - userIds: array<string>
 */
const SET_USER_AUDIO_FILTER = 'SET_USER_AUDIO_FILTER';

/**
 * CLEAR_USER_AUDIO_FILTER action structure:
 *  - type: string
 */
const CLEAR_USER_AUDIO_FILTER = 'CLEAR_USER_AUDIO_FILTER';

/**
 * UPDATE_INPUT_SETTINGS action structure:
 * https://docs.daily.co/reference/daily-js/instance-methods/update-input-settings#main
 */
const UPDATE_INPUT_SETTINGS = 'UPDATE_INPUT_SETTINGS';

/**
 * UPDATE_OUTPUT_DEVICE_ID action structure:
 * - outputDeviceId: string
 */

const UPDATE_OUTPUT_DEVICE_ID = 'UPDATE_OUTPUT_DEVICE_ID';

// --- Reducer and helpers --

const callState = (state = initialCallState, action) => {
  switch (action.type) {
    case DAILY_CALL_OBJECT_CREATED: {
      loginfo({
        message: `Got CallState action ${action.type} for user ${
          action.userId
        } created callObject: ${!!action.callObject}`,
      });
      return {
        ...state,
        state: CALL_STATE_INITIALIZING,
        daily: action.callObject,
      };
    }
    case DAILY_CALL_OBJECT_DESTROYED: {
      loginfo({
        message: `Got CallState action ${action.type} for user ${action.userId} `,
      });

      return {
        ...state,
        state: CALL_STATE_UNLOADED,
        daily: null,
        inputSettings: {
          video: {
            processor: {
              type: BACKGROUND_TYPE_NONE,
            },
          },
        },
      };
    }
    case CALL_STATE_CHANGE:
      return {
        ...state,
        state: action.newState,
      };
    case CLICK_ALLOW_TIMEOUT:
      return {
        ...state,
        clickAllowTimeoutFired: true,
      };
    case PARTICIPANTS_CHANGE: {
      return handleParticipantsChanged(state, action.participants);
    }
    case CLEANUP:
      return {
        ...initialCallState,
      };
    case CAM_OR_MIC_ERROR:
      return { ...state, camOrMicError: action.message };
    case FATAL_ERROR:
      return { ...state, fatalError: action.message };
    case NONFATAL_ERROR:
      return { ...state, nonfatalError: action.message };
    case SET_MUTE: {
      const { mute, reason } = action;
      return {
        ...state,
        pendingMute: { mute, reason },
      };
    }
    case SET_UNMUTE: {
      const { unmute } = action;
      return {
        ...state,
        pendingUnmute: unmute,
      };
    }
    case SUBSCRIBE_VIDEO: {
      const { sessionId, videoQuality } = action;
      const newPendingSubscriptions = { ...state.pendingSubscriptions };
      const currentReferenceCount =
        state.videoSubscriptions?.[sessionId]?.[videoQuality] || 0;
      const newReferenceCount = currentReferenceCount + 1;

      newPendingSubscriptions[sessionId] =
        (newPendingSubscriptions[sessionId] || 0) + 1;

      const newState = {
        ...state,
        videoSubscriptions: {
          ...state.videoSubscriptions,
          [sessionId]: {
            ...state.videoSubscriptions[sessionId],
            [videoQuality]: newReferenceCount,
          },
        },
        pendingSubscriptions: newPendingSubscriptions,
      };

      return newState;
    }
    case UNSUBSCRIBE_VIDEO: {
      const { sessionId, videoQuality } = action;
      const currentReferenceCount =
        state.videoSubscriptions?.[sessionId]?.[videoQuality] || 0;
      const newReferenceCount = currentReferenceCount - 1;
      const totalReferenceCount = getTotalReferenceCount(
        state.videoSubscriptions[sessionId]
      );
      const newPendingSubscriptions = { ...state.pendingSubscriptions };
      const newVideoSubscriptions = { ...state.videoSubscriptions };

      newPendingSubscriptions[sessionId] =
        (newPendingSubscriptions[sessionId] || 0) + 1;

      if (totalReferenceCount - 1 < 1) {
        delete newVideoSubscriptions[sessionId];
      } else {
        newVideoSubscriptions[sessionId][videoQuality] = newReferenceCount;
      }

      const newState = {
        ...state,
        videoSubscriptions: newVideoSubscriptions,
        pendingSubscriptions: newPendingSubscriptions,
      };

      return newState;
    }
    case SUBSCRIBE_AUDIO: {
      const { sessionId } = action;
      const newPendingSubscriptions = { ...state.pendingSubscriptions };
      const currentReferenceCount = state.audioSubscriptions[sessionId] || 0;
      const newReferenceCount = currentReferenceCount + 1;
      if (currentReferenceCount === 0) {
        newPendingSubscriptions[sessionId] =
          (newPendingSubscriptions[sessionId] || 0) + 1;
      }
      const newState = {
        ...state,
        audioSubscriptions: {
          ...state.audioSubscriptions,
          [sessionId]: newReferenceCount,
        },
        pendingSubscriptions: newPendingSubscriptions,
      };
      return newState;
    }
    case UNSUBSCRIBE_AUDIO: {
      const { sessionId } = action;
      const currentReferenceCount = state.audioSubscriptions[sessionId] || 0;
      let newReferenceCount = currentReferenceCount - 1;

      const newPendingSubscriptions = { ...state.pendingSubscriptions };
      const newAudioSubscriptions = { ...state.audioSubscriptions };

      if (newReferenceCount < 1) {
        newPendingSubscriptions[sessionId] =
          (newPendingSubscriptions[sessionId] || 0) + 1;
        delete newAudioSubscriptions[sessionId];
      }

      const newState = {
        ...state,
        audioSubscriptions: newAudioSubscriptions,
        pendingSubscriptions: newPendingSubscriptions,
      };
      return newState;
    }
    case SUBSCRIPTIONS_UPDATED: {
      const { sessionId } = action;
      const newPendingSubscriptions = { ...state.pendingSubscriptions };
      if (newPendingSubscriptions[sessionId]) {
        newPendingSubscriptions[sessionId] =
          newPendingSubscriptions[sessionId] - 1;
        if (newPendingSubscriptions[sessionId] === 0) {
          delete newPendingSubscriptions[sessionId];
        }
      }
      return {
        ...state,
        pendingSubscriptions: newPendingSubscriptions,
      };
    }
    case ACTIVE_SPEAKER_CHANGE: {
      const { sessionId, local, userId } = action;
      return {
        ...state,
        activeSpeaker: {
          sessionId,
          local,
          userId,
        },
      };
    }
    case SET_USER_AUDIO_FILTER:
      const { userIds } = action;
      return { ...state, userIdsAudioFilter: userIds };
    case CLEAR_USER_AUDIO_FILTER:
      return { ...state, userIdsAudioFilter: null };
    case UPDATE_INPUT_SETTINGS:
      const { inputSettings } = action;
      return { ...state, inputSettings };
    case UPDATE_OUTPUT_DEVICE_ID:
      const { outputDeviceId } = action;
      return { ...state, outputDeviceId };
    default:
      return state;
  }
};

function handleParticipantsChanged(initialState, participants) {
  try {
    let localTrackStates = createTrackStateFromParticipant(participants.local);
    const { userIdToUserTrackStates, userIdToScreenShareTrackStates } =
      createUserIdToTrackStatesMaps(participants);

    // Compare old track states to new track states, and only update references if things actually changed.
    if (equals(initialState.tracks.local, localTrackStates)) {
      localTrackStates = initialState.tracks.local;
    }
    for (let [userId, newValue] of Object.entries(userIdToUserTrackStates)) {
      const oldValue = initialState.tracks.allUsers[userId];
      if (equals(oldValue, newValue)) {
        userIdToUserTrackStates[userId] = oldValue;
      }
    }

    for (let [userId, newValue] of Object.entries(
      userIdToScreenShareTrackStates
    )) {
      const oldValue = initialState.tracks.screenShares[userId];
      if (equals(oldValue, newValue)) {
        userIdToScreenShareTrackStates[userId] = oldValue;
      }
    }

    const screenShareTracks = Object.values(userIdToScreenShareTrackStates);
    return {
      ...initialState,
      tracks: {
        local: localTrackStates,
        allUsers: userIdToUserTrackStates,
        screenShares: userIdToScreenShareTrackStates,
        mainScreenShare: screenShareTracks[0] ?? null,
      },
    };
  } catch (err) {
    logerror({
      message: err.message,
      stacktrack: err.stack,
    });
  }
  return { ...initialState };
}

export function createTrackStateFromParticipant(participant, isScreenShare) {
  // Adding properties here may incur significant performance penalties and cause
  // unnecessary re-renders of video / audio elements.
  // The following changes are safe:
  //   * Properties that *should* rerender audio / video
  //   * Properties that do not change during a meeting, such as 'local'
  return {
    sessionId: participant.session_id,
    videoTrackState: isScreenShare
      ? participant.tracks.screenVideo
      : participant.tracks.video,
    audioTrackState: isScreenShare
      ? participant.tracks.screenAudio
      : participant.tracks.audio,
    cameraOn: Boolean(participant.video),
    micOn: Boolean(participant.audio || participant.isFake),
    local: Boolean(participant.local),
    joinedAt: participant.joined_at || participant.joinedAt,
    isFake: participant.isFake,
  };
}

function createUserIdToTrackStatesMaps(participants) {
  const userIdToUserTrackStates = {};
  const userIdToScreenShareTrackStates = {};
  for (const participant of Object.values(participants)) {
    // user_name represents the zync user id. If none is set, it may be the "local" user setting up their camera, which can be skipped here.
    // Additionally, it may not appear in the participant list during the 'joined_meeting' events.
    // It seems to be later set in 'participant_updated'.
    if (!participant.user_name) {
      continue;
    }

    const key = participant.user_name;
    userIdToUserTrackStates[key] = mostRecentOf(
      userIdToUserTrackStates[key],
      createTrackStateFromParticipant(participant)
    );

    if (shouldIncludeScreenshareTrackStates(participant)) {
      const key = participant.user_name;
      userIdToScreenShareTrackStates[key] = mostRecentOf(
        userIdToScreenShareTrackStates[key],
        createTrackStateFromParticipant(participant, true)
      );
    }
  }
  return { userIdToUserTrackStates, userIdToScreenShareTrackStates };
}

// Use the latest stream by join date for each participant.
function mostRecentOf(existingParticipant, participant) {
  if (!existingParticipant) return participant;
  return participant.joinedAt >= existingParticipant.joinedAt
    ? participant
    : existingParticipant;
}

function shouldIncludeScreenshareTrackStates(participant) {
  const trackStatesForInclusion = ['loading', 'playable', 'interrupted'];
  return (
    trackStatesForInclusion.includes(participant.tracks.screenVideo.state) ||
    trackStatesForInclusion.includes(participant.tracks.screenAudio.state)
  );
}

function getTotalReferenceCount(videoSubscriptions) {
  let count = 0;
  if (!videoSubscriptions) return count;
  Object.values(videoSubscriptions).forEach((value) => {
    count += value;
  });
  return count;
}

export {
  callState,
  DAILY_CALL_OBJECT_CREATED,
  DAILY_CALL_OBJECT_DESTROYED,
  CALL_STATE_CHANGE,
  CLICK_ALLOW_TIMEOUT,
  PARTICIPANTS_CHANGE,
  ACTIVE_SPEAKER_CHANGE,
  CLEANUP,
  CAM_OR_MIC_ERROR,
  FATAL_ERROR,
  NONFATAL_ERROR,
  SUBSCRIPTIONS_UPDATED,
  SUBSCRIBE_VIDEO,
  UNSUBSCRIBE_VIDEO,
  SUBSCRIBE_AUDIO,
  UNSUBSCRIBE_AUDIO,
  SET_USER_AUDIO_FILTER,
  CLEAR_USER_AUDIO_FILTER,
};
