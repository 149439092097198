import React, { useEffect, useState } from 'react';
import { usePrevious } from '../../hooks/usePrevious';
import { hasChanged, InputExplanation } from '../SettingsInput';
import classNames from '../../helper/classNames';

/**
 * An input field for all manner of text-based inputs including email, number, password, and text.
 * This element replaces the previous <TextInput /> and provides the same attributes.
 */
export const TextAreaInput = ({
  /** The label value to briefly describe the input. */
  label = '',

  /** A longer explanation of the input's purpose, if needed. */
  explanation = '',

  /** Whether the input is disabled. false by default. */
  disabled = false,

  /** The default Value of the input, if uncontrolled. */
  defaultValue,

  /** The value of the input, if controlled. */
  value,

  /** A handler for when the input changes. Passes (event, value), where value is a string or number. */
  onChange,

  /** Any style props to be applied directly to the input. */
  style = {},

  /** A handler for when the input loses focus. Passes (event, value). */
  onBlur,

  /** Whether the text area is resizable or not */
  resizable = true,

  /** If there is an error with the input */
  error = false,

  /** Any other props to be passed directly to the native input. */
  ...props
}) => {
  const [visibleValue, setVisibleValue] = useState(value);

  const previousValue = usePrevious(value);

  useEffect(() => {
    setVisibleValue(value);
  }, [value]);

  return (
    <label className="flex flex-col items-stretch my-1 mx-0">
      {label && (
        <div className="text-blue-dark font-medium text-sm mb-2">{label}</div>
      )}
      <div className="flex flex-col items-stretch">
        <textarea
          className={classNames(
            'rounded-md border border-solid px-2 pt-1 text-sm text-[#696F8C] outline-none focus:border-purple w-full',
            !resizable && 'resize-none',
            error ? 'border-red focus:border-red' : 'border-gray'
          )}
          disabled={disabled ?? false}
          onChange={(event) =>
            onChange
              ? onChange(event, event.currentTarget.value)
              : setVisibleValue(event.currentTarget.value)
          }
          defaultValue={defaultValue}
          value={visibleValue}
          onBlur={(event) =>
            hasChanged(event, previousValue) &&
            onBlur &&
            onBlur(event, event.currentTarget.value)
          }
          {...props}
        />
      </div>

      {explanation && <InputExplanation> {explanation}</InputExplanation>}
    </label>
  );
};
