import { shallowEqual, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useUserWorkspaces } from '../hooks/useUserWorkspaces';
import { logerror } from '../helper/contextualLogger';
import { LogoSpinner } from './LogoSpinner';
import { FuturisticBackground } from './FuturisticBackground';
import classNames from '../helper/classNames';
import { WelcomeBrandKitForm } from '../pages/Welcome/WelcomeBrandKitForm';
import { createPortalStarterKit } from '../pages/Welcome';
import { defaultBrandkit } from '../helper/constants';
import { Modal, ModalBody, ModalWindow } from './Modal';
import { useExampleMeetingTemplate } from '../pages/Portal/useExampleMeetingTemplate';
import { makeRandomId } from '../helper';
import { PreviewSlide } from './PreviewSlide';
import { buttonFunctionalityClasses } from './onboarding/constants';
import { FormSteps } from './onboarding/FormSteps';
import { StageArrows } from './onboarding/StageArrows';
import { ProgressBar } from './onboarding/ProgressBar';

const checkpointNames = {
  CHOOSE_WORKSPACE: 'CHOOSE_WORKSPACE',
  SETUP_BRANDKIT: 'SETUP_BRANDKIT',
  SHOW_DESCRIPTION: 'SHOW_DESCRIPTION',
  SHOW_AUDIENCE: 'SHOW_AUDIENCE',
};
export const WorkspaceCreateModal = ({ onWorkspaceCreated, onCancel }) => {
  const user = useSelector((_st) => _st.auth.user, shallowEqual);
  const { template } = useExampleMeetingTemplate();
  const { userId, userPreferences } = user;
  const checkpoints = {
    [checkpointNames.CHOOSE_WORKSPACE]: {
      heading: 'Show name',
      topics: [
        'What do you want to call your show?',
        'You can change the show name later',
      ],
    },
    [checkpointNames.SHOW_DESCRIPTION]: {
      heading: 'Show description',
      topics: ['About', 'What is this show about?'],
    },
    [checkpointNames.SHOW_AUDIENCE]: {
      heading: 'Show audience',
      topics: ['Your Audience', 'Who should tune in to this show?'],
    },

    [checkpointNames.SETUP_BRANDKIT]: {
      heading: 'Setup Brand Kit',
      topics: [
        'Setup your Brand Kit',
        'You can adjust these settings in your Workspace Settings Later',
      ],
    },
  };

  const [checkpointsData] = useState(checkpoints);
  const [CHOOSE_WORKSPACE, SHOW_DESCRIPTION, SHOW_AUDIENCE, SETUP_BRANDKIT] = [
    checkpointsData[checkpointNames.CHOOSE_WORKSPACE],
    checkpointsData[checkpointNames.SHOW_DESCRIPTION],
    checkpointsData[checkpointNames.SHOW_AUDIENCE],

    checkpointsData[checkpointNames.SETUP_BRANDKIT],
  ];

  const { createWorkspace } = useUserWorkspaces();
  const [checkpoint, setCheckpoint] = useState(CHOOSE_WORKSPACE);
  const [workspaceName, setWorkspaceName] = useState('');
  const [showDescription, setShowDescription] = useState('');
  const [audienceDescription, setAudienceDescription] = useState('');

  const [redirectToDesigner, setRedirectToDesigner] = useState(false);
  const [autoCreatedMeetingSeriesId, setAutoCreatedMeetingSeriesId] =
    useState('');
  const [nextButtonStyling, setNextButtonStyling] = useState(
    buttonFunctionalityClasses.ENABLE
  );
  const [previousButtonStyling, setPreviousButtonStyling] = useState(
    buttonFunctionalityClasses.DISABLE
  );
  const [showInputError, setShowInputError] = useState(false);
  const [inputErrorMessage, setInputErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [brandKit, setBrandKit] = useState(defaultBrandkit);

  const maxInputCharacters = 32;

  const isShortWorkspaceName = !workspaceName.length;
  const isLongWorkspaceName = workspaceName.length > maxInputCharacters;
  const isInvalidWorkspaceName = isShortWorkspaceName || isLongWorkspaceName;

  const styleToDisableNextButton = () => {
    setPreviousButtonStyling(buttonFunctionalityClasses.DISABLE);
    setNextButtonStyling(buttonFunctionalityClasses.DISABLE);
  };

  const styleToEnableNextButton = () => {
    setPreviousButtonStyling(buttonFunctionalityClasses.DISABLE);
    setNextButtonStyling(buttonFunctionalityClasses.ENABLE);
  };

  const styleToEnablePreviousButtonAndNextButton = () => {
    setPreviousButtonStyling(buttonFunctionalityClasses.ENABLE);
    setNextButtonStyling(buttonFunctionalityClasses.ENABLE);
  };

  /** Previous and Next button styling. */
  useEffect(() => {
    switch (checkpoint) {
      case CHOOSE_WORKSPACE:
        if (isInvalidWorkspaceName) {
          styleToDisableNextButton();
        } else {
          styleToEnableNextButton();
        }
        break;
      default:
        styleToEnablePreviousButtonAndNextButton();
        break;
    }
  }, [workspaceName, checkpoint, CHOOSE_WORKSPACE, isInvalidWorkspaceName]);

  /** Workspace Input error handling. */
  useEffect(() => {
    if (checkpoint === CHOOSE_WORKSPACE) {
      if (isShortWorkspaceName) {
        return;
      } else if (isLongWorkspaceName) {
        return;
      }
    }
    setShowInputError(false);
    setInputErrorMessage('');
  }, [
    workspaceName,
    checkpoint,
    CHOOSE_WORKSPACE,
    isShortWorkspaceName,
    isLongWorkspaceName,
  ]);

  const handleChangeWorkspaceName = (event) => {
    const value = event.target.value;

    if (!value) {
      setShowInputError(true);
      setInputErrorMessage('Please enter a name for your workspace.');
    } else if (value.length > 32) {
      setShowInputError(true);
      setInputErrorMessage('Workspace name cannot exceed 32 characters.');
    }
    setWorkspaceName(event.target.value);
  };

  const setPreviousCheckpoint = (currentCheckpoint = checkpoint) => {
    switch (currentCheckpoint) {
      case SHOW_DESCRIPTION: {
        setCheckpoint(CHOOSE_WORKSPACE);
        break;
      }
      case SHOW_AUDIENCE: {
        setCheckpoint(SHOW_DESCRIPTION);
        break;
      }
      case SETUP_BRANDKIT: {
        setCheckpoint(SHOW_AUDIENCE);
        break;
      }
      default:
    }
  };

  const setNextCheckpoint = (currentCheckpoint = checkpoint) => {
    switch (currentCheckpoint) {
      case CHOOSE_WORKSPACE:
        setCheckpoint(SHOW_DESCRIPTION);
        break;
      case SHOW_DESCRIPTION:
        setCheckpoint(SHOW_AUDIENCE);
        break;
      case SHOW_AUDIENCE:
        setCheckpoint(SETUP_BRANDKIT);
        break;
      default:
    }
  };

  const handlePreviousClick = () => {
    // If the DISABLE class is applied, do not allow previous click.
    if (previousButtonStyling === buttonFunctionalityClasses.DISABLE) {
      return;
    }
    setPreviousCheckpoint();
  };

  const handleNextClick = () => {
    // If the DISABLE class is applied, do not allow next click.
    if (nextButtonStyling === buttonFunctionalityClasses.DISABLE) {
      return;
    }
    if (checkpoint === SETUP_BRANDKIT) {
      setIsLoading(true);
      createWorkspaceAndUpdateUser();
    } else {
      setNextCheckpoint();
    }
  };

  const createWorkspaceAndUpdateUser = async () => {
    try {
      if (userId) {
        const { workspace, autoCreatedMeeting } = await createWorkspace(
          workspaceName,
          userId,
          {
            brandKit: brandKit.brandKit,
            showDescription,
            audienceDescription,
          }
        );

        const workspaceId = workspace.workspaceId;

        if (
          userPreferences?.quickStartOnboardingTemplateKey &&
          autoCreatedMeeting
        ) {
          setRedirectToDesigner(true);
          setAutoCreatedMeetingSeriesId(autoCreatedMeeting.meetingSeriesId);
        } else {
          await createPortalStarterKit(workspaceId, template, user, [
            {
              text: 'Why should I launch a show?',
              id: makeRandomId(6),
              timestamp: Date.now(),
              asker: user.userName,
              moderatorQuestion: true,
              AIGenerated: false,
              index: 0,
            },
            {
              text: 'How is a show different from a podcast / webinar?',
              id: makeRandomId(6),
              timestamp: Date.now(),
              asker: user.userName,
              moderatorQuestion: true,
              AIGenerated: false,
              index: 1,
            },
            {
              text: 'What should the first episode of a show be about?',
              id: makeRandomId(6),
              timestamp: Date.now(),
              asker: user.userName,
              moderatorQuestion: true,
              AIGenerated: false,
              index: 2,
            },
            {
              text: 'How would you define your show audience?',
              id: makeRandomId(6),
              timestamp: Date.now(),
              asker: user.userName,
              moderatorQuestion: true,
              AIGenerated: false,
              index: 3,
            },
            {
              text: 'What about special equipments - Mic / Camera?',
              id: makeRandomId(6),
              timestamp: Date.now(),
              asker: user.userName,
              moderatorQuestion: true,
              AIGenerated: false,
              index: 4,
            },
            {
              text: 'How do you find what’s the best fit for you to run a show?',
              id: makeRandomId(6),
              timestamp: Date.now(),
              asker: user.userName,
              moderatorQuestion: true,
              AIGenerated: false,
              index: 5,
            },
          ]);
          onWorkspaceCreated();
        }
      }
    } catch (err) {
      setIsLoading(false);
      logerror({
        message: `Error attempting to call either createWorkspace or updateUserApi from the Welcome page. ${err.message}`,
        stacktrace: err.stack | err,
      });
    }
  };

  /** Returns true/false depending on if the checkpoint in state comes before the argument, checkpoint. */
  const isBeforeCheckpoint = (currentCheckpointName) => {
    const currentCheckpoint = checkpointsData[currentCheckpointName];
    const stateCheckpoint = checkpoint;
    if (currentCheckpoint === stateCheckpoint) {
      return false;
    }
    for (let checkpoint in checkpointsData) {
      // found the state checkpoint first
      if (stateCheckpoint === checkpointsData[checkpoint]) {
        return true;
      }
      // found the current checkpoint first
      if (currentCheckpoint === checkpointsData[checkpoint]) {
        return false;
      }
    }
  };

  /** Returns true/false depending on if the checkpoint in state is at the argument, checkpoint. */
  const isAtCheckpoint = (currentCheckpointName) => {
    const currentCheckpoint = checkpointsData[currentCheckpointName];
    return currentCheckpoint === checkpoint;
  };

  const ProgressStates = {
    BEFORE: 'BEFORE',
    CURRENT: 'CURRENT',
    AFTER: 'AFTER',
  };

  /** Every checkpoint is looped over to determine its relationship to the checkpoint in state.
   *  This is used to determine the color & attributes that the currentCheckpoint should contain.
   *  If the checkpoint in state comes before currentCheckpoint, then its state is BEFORE
   *  If the checkpoint in state is at currentCheckpoint, then its state is CURRENT
   *  If the checkpoint in state is after currentCheckpoint, then its state is AFTER
   */
  const getProgressState = (currentCheckpoint) => {
    const isBefore = isBeforeCheckpoint(currentCheckpoint);
    const isAt = isAtCheckpoint(currentCheckpoint);
    if (isBefore) {
      return ProgressStates.BEFORE;
    }
    if (isAt) {
      return ProgressStates.CURRENT;
    }
    return ProgressStates.AFTER;
  };

  if (isLoading) {
    return (
      <ModalWindow size={Modal.size.fullscreen} showCancel={false}>
        <ModalBody>
          <div className="w-full h-full flex items-center justify-center flex-col">
            <LogoSpinner />
            <span>Creating your show...</span>
          </div>
        </ModalBody>
      </ModalWindow>
    );
  }
  if (redirectToDesigner && autoCreatedMeetingSeriesId) {
    return <Redirect to={`/e/${autoCreatedMeetingSeriesId}/designer`} />;
  }

  const firstName = user.userName.split(' ')[0];

  return (
    <ModalWindow size={Modal.size.fullscreen} showCancel onCancel={onCancel}>
      <ModalBody>
        <div className="w-screen md:h-screen">
          <FuturisticBackground />
          <div className="z-50 flex flex-col absolute w-[calc(100%-52px)] mx-auto md:min-w-[800px] md:max-w-[1100px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white px-4 md:px-0 shadow-800 rounded-xl">
            <div className="bg-white flex flex-col justify-center items-center mt-12 mb-6  space-y-3 md:space-y-0 gap-2">
              <span className="text-base md:text-xl font-semibold md:font-semibold text-blue-dark text-center md:text-left">
                {checkpoint?.topics[0]}
                {checkpoint === SHOW_DESCRIPTION && ` ${workspaceName}`}
              </span>
              {checkpoint?.topics[1] && (
                <span className="text-xs md:text-base font-medium md:font-semibold text-blue-gray md:text-[#696F8C] text-center md:text-left">
                  {checkpoint?.topics[1]}
                </span>
              )}
            </div>

            {/* Data */}
            <div className="bg-white flex justify-between items-center md:px-3 space-x-2">
              <div className="flex bg-purple bg-opacity-[5%] grow min-h-[153px] md:min-h-[250px] md:mx-4 md:mb-5 rounded-md justify-between items-center">
                {checkpoint === CHOOSE_WORKSPACE && (
                  <div className="flex flex-col min-w-full items-center space-y-2 px-4 md:px-0 pt-4 md:pt-unset">
                    <input
                      className={classNames(
                        'w-full md:w-1/2 p-3 border-solid border box-border border-gray rounded-md',
                        showInputError
                          ? 'focus:border-red focus:outline-none outline-red'
                          : 'focus:outline-none focus:border-purple outline-purple'
                      )}
                      placeholder={`${firstName}'s Show`}
                      onChange={handleChangeWorkspaceName}
                      value={workspaceName}
                    />
                    <span className="text-xs text-[#D14343] font-bold h-2 text-center md:text-left">
                      {showInputError && inputErrorMessage}
                    </span>
                  </div>
                )}

                {checkpoint === SHOW_DESCRIPTION && (
                  <div className="flex flex-col min-w-full items-center md:space-y-2 px-5 md:px-0 py-5 md:pt-4 md:pb-0">
                    <textarea
                      className={classNames(
                        'w-full md:w-1/2 p-3 border-solid border box-border border-gray rounded-md md:text-base text-sm resize-none',
                        showInputError
                          ? 'focus:border-red focus:outline-none outline-red'
                          : 'focus:outline-none focus:border-purple outline-purple'
                      )}
                      maxLength={1000}
                      placeholder={
                        'How would you describe this show to someone?'
                      }
                      onChange={(event) =>
                        setShowDescription(event.target.value)
                      }
                      value={showDescription}
                      rows={3}
                    ></textarea>
                    {showInputError && (
                      <span className="text-xs text-[#D14343] font-bold h-2 text-center md:text-left">
                        {inputErrorMessage}
                      </span>
                    )}
                  </div>
                )}

                {checkpoint === SHOW_AUDIENCE && (
                  <div className="flex flex-col min-w-full items-center md:space-y-2 px-5 md:px-0 py-5 md:pt-4 md:pb-0">
                    <textarea
                      className={classNames(
                        'w-full md:w-1/2 p-3 border-solid border box-border border-gray rounded-md md:text-base text-sm resize-none',
                        showInputError
                          ? 'focus:border-red focus:outline-none outline-red'
                          : 'focus:outline-none focus:border-purple outline-purple'
                      )}
                      maxLength={1000}
                      placeholder={'Who should tune in to this show?'}
                      onChange={(event) =>
                        setAudienceDescription(event.target.value)
                      }
                      value={audienceDescription}
                      rows={3}
                    ></textarea>
                    {showInputError && (
                      <span className="text-xs text-[#D14343] font-bold h-2 text-center md:text-left">
                        {inputErrorMessage}
                      </span>
                    )}
                  </div>
                )}

                {/*checkpoint === SHOW_CHANNELS && (
                  <div className="flex flex-col min-w-full items-center md:space-y-2 px-5 md:px-0 py-5 md:pt-4 md:pb-0">
                    <div className="flex gap-1 flex-wrap">
                      {[
                        'linkedIn',
                        'twitter',
                        'facebook',
                        'youtube',
                        'newsletter',
                        'website',
                        'instagram',
                        'tiktok',
                      ].map((channelName) => (
                        <button
                          key={channelName}
                          onClick={() => {
                            if (channels.includes(channelName)) {
                              setChannels((prevState) =>
                                prevState.filter((c) => c !== channelName)
                              );
                            } else {
                              setChannels((prevState) => [
                                ...prevState,
                                channelName,
                              ]);
                            }
                          }}
                          className={classNames(
                            'cursor-pointer capitalize text-white text-sm p-2 font-bold rounded-full min-w-fit my-2 duration-300',
                            channels.includes(channelName)
                              ? 'bg-blue'
                              : 'bg-blue-light bg-opacity-60'
                          )}
                        >
                          {channelName}
                        </button>
                      ))}
                    </div>
                  </div>
                          )*/}

                {checkpoint === SETUP_BRANDKIT && (
                  <div className="flex gap-6 w-full mx-5 items-center justify-around">
                    <WelcomeBrandKitForm
                      workspace={defaultBrandkit}
                      onBrandKitChange={setBrandKit}
                    />
                    <PreviewSlide
                      brandKit={brandKit.brandKit}
                      sceneTemplateId="scene_interview_question_preview"
                      width={600}
                      height={(600 / 16) * 9}
                    />
                  </div>
                )}
              </div>
            </div>
            <FormSteps
              getProgressState={getProgressState}
              checkpointsData={checkpointsData}
              ProgressStates={ProgressStates}
            />
            <StageArrows
              previousButtonStyling={previousButtonStyling}
              nextButtonStyling={nextButtonStyling}
              handleNextClick={handleNextClick}
              handlePreviousClick={handlePreviousClick}
            />
            <ProgressBar
              checkpointsData={checkpointsData}
              getProgressState={getProgressState}
            />
          </div>
        </div>
      </ModalBody>
    </ModalWindow>
  );
};
