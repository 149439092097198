import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getTimerDisplayValue } from '../../../../blocks/CountdownBlock';
import { limitEnforcements } from 'zync-common/types';

const RecordingTimer = React.memo(
  ({
    recordingStartTime,
    isLiveStream,
    setIsRecording,
    setIsStreaming,
    label = 'Recording',
    labelClassName = 'text-red relative',
    timerClassName = 'text-red absolute bottom-0 translate-y-[100%] w-full',
  }) => {
    const [elapsedTime, setElapsedTime] = useState(0);

    useEffect(() => {
      const updateTimer = () => {
        if (recordingStartTime) setElapsedTime(Date.now() - recordingStartTime);
      };
      updateTimer();
      const interval = setInterval(() => {
        updateTimer();
      }, 1000);

      return () => clearInterval(interval);
    }, [recordingStartTime]);

    useEffect(() => {
      if (!recordingStartTime) return;
      if (
        elapsedTime / 1000 >=
        limitEnforcements.MAX_RECORDING_TIME_IN_SECONDS
      ) {
        setIsRecording(false);
        setIsStreaming(false);
      }
    }, [elapsedTime, recordingStartTime, setIsRecording, setIsStreaming]);

    return (
      <>
        <div className={labelClassName}>
          {recordingStartTime ? (
            isLiveStream ? (
              <>
                <FontAwesomeIcon size="sm" icon="circle" /> Live
              </>
            ) : (
              label || ''
            )
          ) : (
            'Setting Up'
          )}
          <div className={timerClassName}>
            {recordingStartTime ? getTimerDisplayValue(elapsedTime) : '-- : --'}
          </div>
        </div>
      </>
    );
  }
);

export default RecordingTimer;
