export const BoomerangUpArrow = ({
  width = '12',
  height = '8',
  className = 'fill-[#696F8C]',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M5.28015 0.719835C5.42066 0.579143 5.6113 0.50001 5.81015 0.499835H6.19015C6.38856 0.502139 6.57843 0.580951 6.72015 0.719835L11.8501 5.85984C11.9448 5.95372 11.998 6.08152 11.998 6.21484C11.998 6.34815 11.9448 6.47595 11.8501 6.56984L11.1401 7.27984C11.048 7.3739 10.9218 7.42691 10.7901 7.42691C10.6585 7.42691 10.5323 7.3739 10.4401 7.27984L6.00015 2.82984L1.56015 7.27984C1.46626 7.37449 1.33847 7.42773 1.20515 7.42773C1.07183 7.42773 0.944031 7.37449 0.850147 7.27984L0.150147 6.56984C0.0554914 6.47595 0.00224876 6.34815 0.00224876 6.21484C0.00224876 6.08152 0.0554914 5.95372 0.150147 5.85984L5.28015 0.719835Z" />
    </svg>
  );
};
