export const ChevronLeftIcon = ({
  width = 17,
  height = 16,
  className = 'fill-[#696F8C]',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7324 4.18414C10.9621 4.423 10.9546 4.80282 10.7158 5.0325L7.56557 8L10.7158 10.9675C10.9546 11.1972 10.9621 11.577 10.7324 11.8159C10.5027 12.0547 10.1229 12.0622 9.88404 11.8325L6.28404 8.4325C6.16639 8.31938 6.0999 8.16321 6.0999 8C6.0999 7.83679 6.16639 7.68062 6.28404 7.5675L9.88404 4.1675C10.1229 3.93782 10.5027 3.94527 10.7324 4.18414Z"
      />
    </svg>
  );
};
