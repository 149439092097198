import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { LEFT_TOOLBAR_WIDTH_PX } from '../constants';
import { useAuthoringTool } from '../hooks';
import { BackdropIcon } from '../../icons/BackdropIcon';
import { BackdropMenu } from './BackdropMenu';
import { BlocksMenu } from './BlocksMenu';
import { LeftMenu } from './LeftMenu';
import { TextIcon } from '../../icons/TextIcon';
import { MediaIcon } from '../../icons/MediaIcon';
import { WandIcon } from '../../icons/WandIcon';
import classNames from '../../../helper/classNames';
import { JoystickIcon } from '../../icons/JoystickIcon';
import { getFormattedCategoryName } from '../../../hooks/useTemplateGallery';
import { CameraIcon } from '../../icons/CameraIcon';
import { PersonIcon } from '../../icons/PersonIcon';
import { CircleIcon } from '../../icons/CircleIcon';

export const LeftToolbarContainer = styled.nav`
  width: ${LEFT_TOOLBAR_WIDTH_PX}px;
  z-index: 1;
  background: white;
`;

const UserVideoIcon = ({ isActive }) => (
  <div
    className={classNames(
      'w-6 h-6 flex justify-center relative items-center rounded-full z-20 group-hover:bg-purple/10',
      isActive ? 'bg-purple/10' : 'bg-blue-gray/10'
    )}
  >
    <PersonIcon
      width="10px"
      className={classNames(
        'group-hover:fill-purple',
        isActive ? 'fill-purple' : 'fill-blue-gray'
      )}
    />
    <div className="absolute -top-1.5 -right-[1px]">
      <CameraIcon
        width="10px"
        className={classNames(
          'group-hover:fill-purple',
          isActive ? 'fill-purple' : 'fill-blue-gray'
        )}
      />
    </div>
  </div>
);

const options = {
  backdrop: {
    icon: BackdropIcon,
    label: 'backdrop',
    render: (props) => <BackdropMenu {...props} />,
  },
  textBlocks: {
    icon: TextIcon,
    label: 'text',
    render: (props) => <BlocksMenu {...props} />,
  },
  mediaBlocks: {
    icon: MediaIcon,
    label: 'media',
    render: (props) => <BlocksMenu {...props} />,
  },
  userVideoBlocks: {
    icon: UserVideoIcon,
    label: 'user_video',
    render: (props) => <BlocksMenu {...props} />,
  },
  interactiveBlocks: {
    icon: WandIcon,
    label: 'interactive',
    render: (props) => <BlocksMenu {...props} />,
  },
  funBlocks: {
    icon: JoystickIcon,
    label: 'fun',
    render: (props) => <BlocksMenu {...props} />,
  },
  shapesBlocks: {
    icon: CircleIcon,
    label: 'shapes',
    render: (props) => <BlocksMenu {...props} />,
  },
};

/** The left-hand toolbar in authoring (blocks, backdrops, etc). */
export const LeftToolbar = () => {
  const {
    setActiveMenu,
    status: { leftMenu: activeMenu },
    isAdvancedAuthoringModeEnabled,
  } = useAuthoringTool();

  const handleCloseMenu = useCallback(
    () => setActiveMenu(null),
    [setActiveMenu]
  );

  const menuContent = useMemo(
    () =>
      activeMenu
        ? options[activeMenu].render({
            onClose: handleCloseMenu,
            blockCategory: options[activeMenu].label,
          })
        : null,
    [activeMenu, handleCloseMenu]
  );

  return (
    <>
      <LeftToolbarContainer className="box-border border-r border-black/5">
        <div className="flex flex-col w-full">
          {Object.entries(options).map(
            ([name, { icon: IconComponent, label }], index) => (
              <div className="flex flex-col" key={name}>
                {(index === 0 || isAdvancedAuthoringModeEnabled) && (
                  <div
                    onClick={() =>
                      setActiveMenu(name === activeMenu ? null : name)
                    }
                    className={classNames(
                      'w-full flex flex-col justify-center items-center gap-2 hover:cursor-pointer group p-2',
                      name === activeMenu && 'bg-purple/10'
                    )}
                  >
                    <IconComponent
                      className={classNames(
                        'group-hover:fill-purple',
                        name === activeMenu ? 'fill-purple' : 'fill-blue-gray'
                      )}
                      isActive={name === activeMenu}
                    />
                    <div
                      className={classNames(
                        'font-medium text-xs group-hover:text-purple',
                        name === activeMenu ? 'text-purple' : 'text-blue-gray'
                      )}
                    >
                      {getFormattedCategoryName(label)}
                    </div>
                  </div>
                )}
                {index === 0 && isAdvancedAuthoringModeEnabled && (
                  <div className="w-full h-0 border border-dashed border-light-gray" />
                )}
              </div>
            )
          )}
        </div>
      </LeftToolbarContainer>

      <LeftMenu
        open={activeMenu !== null}
        onClose={() => setActiveMenu(null)}
        title={activeMenu !== null ? options[activeMenu].label : null}
      >
        {menuContent}
      </LeftMenu>
    </>
  );
};
