import { useCallback, useState } from 'react';
import { debounce } from 'lodash/function';
import { updateSeriesApi } from '../../../helper/api';
import { fromError, logerror } from '../../../helper/contextualLogger';

export const useChangeDoNotUseAsUpcomingEpisode = (series, localDispatch) => {
  const [doNotUseAsUpcomingEpisode, setDoNotUseAsUpcomingEpisode] = useState(
    series?.settings?.doNotUseAsUpcomingEpisode
  );

  const meetingSeriesId = series?.meetingSeriesId;

  const onChangeDoNotUseAsUpcomingEpisode = useCallback(
    async (newValue) => {
      try {
        const result = await updateSeriesApi(meetingSeriesId, {
          settings: { doNotUseAsUpcomingEpisode: newValue },
        });
        if (!result) return;
        setDoNotUseAsUpcomingEpisode(result.settings.doNotUseAsUpcomingEpisode);
        localDispatch({ type: 'REFRESH_SERIES', series: result });
      } catch (error) {
        logerror(fromError(error));
      }
    },
    [localDispatch, meetingSeriesId]
  );

  const handleChangeDoNotUseAsUpcomingEpisode = debounce(
    onChangeDoNotUseAsUpcomingEpisode,
    1_000
  );

  return {
    handleChangeDoNotUseAsUpcomingEpisode,
    doNotUseAsUpcomingEpisode,
  };
};
