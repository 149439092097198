import React from 'react';
import PropTypes from 'prop-types';
import './button.css';

/**
 * Primary UI component for user interaction
 *
 * Deprecated. Do not use.
 */
export const Button = React.forwardRef(
  (
    {
      top,
      bottom,
      width,
      height,
      backgroundColor,
      children,
      className,
      color,
      flex,
      fontFamily,
      fontSize,
      label,
      margin,
      position,
      primary,
      right,
      size,
      style,
      type = 'button',
      ...props
    },
    ref
  ) => {
    const mode = primary
      ? 'storybook-button--primary'
      : 'storybook-button--secondary';
    return (
      <button
        ref={ref}
        {...props}
        type={type}
        className={[
          'storybook-button',
          `storybook-button--${size}`,
          mode,
          className,
        ].join(' ')}
        style={{
          ...(style || {}),
          ...(backgroundColor && { backgroundColor }),
          ...(color && { color }),
          ...(width && { width }),
          ...(height && { height }),
          ...(position && { position, right, bottom, top }),
          ...(margin && { margin }),
          ...(flex && {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }),
          textAlign: 'center',
          ...(fontFamily && { fontFamily }),
          ...(fontSize && { fontSize }),
        }}
        disabled={props.disabled}
      >
        {!children ? label : children}
      </button>
    );
  }
);

Button.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  primary: PropTypes.bool,
  /**
   * What background color to use
   */
  backgroundColor: PropTypes.string,
  /**
   * What color to use
   */
  color: PropTypes.string,
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * Button contents
   */
  label: PropTypes.string.isRequired,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
};

Button.defaultProps = {
  backgroundColor: null,
  color: null,
  primary: false,
  size: 'medium',
  onClick: undefined,
};

const DisabledButton = ({ label, className }) => {
  return (
    <Button
      disabled={true}
      backgroundColor={'#ECECEC'}
      color={'#9C9C9C'}
      size="large"
      label={label}
      width="180px"
      height="48px"
      className={className}
    />
  );
};

export const SimpleButton = ({
  label,
  onClick,
  backgroundColor,
  disabled,
  className,
  size = 'large',
  height = '48px',
}) => {
  if (disabled) {
    return <DisabledButton label={label} className={className} />;
  }
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      backgroundColor={backgroundColor || '#8B33F7'}
      color={'#fff'}
      size={size}
      label={label}
      width="180px"
      height={height}
      className={className}
    />
  );
};
export const RedButton = ({
  label,
  onClick,
  className,
  disabled,
  margin,
  size = 'large',
  height = '48px',
  width = 'auto',
}) => {
  return (
    <Button
      onClick={onClick}
      backgroundColor="#DB2929"
      disabled={disabled}
      color="#fff"
      size={size}
      label={label}
      width={width}
      height={height}
      margin={margin}
      className={className}
    />
  );
};
