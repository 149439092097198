import React from 'react';
import classNames from '../../helper/classNames';
import mixpanel from 'mixpanel-browser';
import { useSelector } from 'react-redux';

const BORDER_WIDTH_VARIANTS = {
  NONE: 'NONE',
  THIN: 'THIN',
};

const BORDER_WIDTH_VARIANTS_CLASSNAMES = {
  NONE: 'border-0',
  THIN: 'border',
};

const BORDER_COLOR_VARIANTS = {
  TRANSPARENT: 'TRANSPARENT',
  LIGHT_GRAY: 'LIGHT_GRAY',
};

const BORDER_COLOR_VARIANTS_CLASSNAMES = {
  TRANSPARENT: 'border-transparent',
  LIGHT_GRAY: 'border-[#EBEAED]',
};

const BORDER_RADIUS_VARIANTS = {
  NONE: 'NONE',
  ROUNDED: 'ROUNDED',
};

const BORDER_RADIUS_VARIANTS_CLASSNAMES = {
  NONE: 'rounded-none',
  ROUNDED: 'rounded',
};

export const MeetingTopic = React.memo(
  ({
    title,
    setTitle,
    borderWidth = BORDER_WIDTH_VARIANTS.NONE,
    borderColor = BORDER_COLOR_VARIANTS.TRANSPARENT,
    borderRadius = BORDER_RADIUS_VARIANTS.NONE,
    handleOnBlur,
    disabled = false,
  }) => {
    const userId = useSelector((st) => st.auth?.user?.userId);

    return (
      <div className="flex flex-grow flex-col gap-2 self-start min-w-0">
        <input
          type="text"
          defaultValue={title}
          onChange={(event) => setTitle(event.currentTarget.value)}
          className={classNames(
            'px-9 py-6 font-medium placeholder:text-[#97A0AF] text-blue-gray',
            BORDER_WIDTH_VARIANTS_CLASSNAMES[borderWidth],
            BORDER_COLOR_VARIANTS_CLASSNAMES[borderColor],
            BORDER_RADIUS_VARIANTS_CLASSNAMES[borderRadius]
          )}
          maxLength={90}
          onBlur={() => {
            if (handleOnBlur) {
              mixpanel.track('New Session: Topic Updated ', {
                distinct_id: userId,
              });

              handleOnBlur();
            }
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
            }
          }}
          placeholder="Your Topic"
          disabled={disabled}
        />
      </div>
    );
  }
);

MeetingTopic.borderWidth = BORDER_WIDTH_VARIANTS;
MeetingTopic.borderColor = BORDER_COLOR_VARIANTS;
MeetingTopic.borderRadius = BORDER_RADIUS_VARIANTS;
