export const timezones = [
  {
    value: 'Universal Time Coordinated',
    abbr: 'UTC',
    text: 'Universal Time Coordinated',
    utc: [
      'Etc/UCT',
      'Etc/UTC',
      'Etc/Universal',
      'Etc/Zulu',
      'UCT',
      'UTC',
      'Universal',
      'Zulu',
    ],
  },
  {
    value: 'Greenwich Mean Time',
    abbr: 'GMT',
    text: '(UTC+00:00) Greenwich Mean Time',
    utc: [
      'Africa/Abidjan',
      'Africa/Accra',
      'Africa/Bamako',
      'Africa/Banjul',
      'Africa/Bissau',
      'Africa/Conakry',
      'Africa/Dakar',
      'Africa/Freetown',
      'Africa/Lome',
      'Africa/Monrovia',
      'Africa/Nouakchott',
      'Africa/Ouagadougou',
      'Africa/Sao_Tome',
      'Africa/Timbuktu',
      'America/Danmarkshavn',
      'Atlantic/Reykjavik',
      'Atlantic/St_Helena',
      'Etc/GMT',
      'Etc/GMT+0',
      'Etc/GMT-0',
      'Etc/GMT0',
      'Etc/Greenwich',
      'GMT',
      'GMT+0',
      'GMT-0',
      'GMT0',
      'Greenwich',
      'Iceland',
      'Europe/Belfast',
      'Europe/Guernsey',
      'Europe/Isle_of_Man',
      'Europe/Jersey',
      'Europe/London',
      'Europe/Dublin',
      'Eire',
      'GB',
      'GB-Eire',
    ],
  },
  {
    value: 'British Summer Time',
    abbr: 'BST',
    text: '(UTC+01:00) British Summer Time',
    utc: [
      'Europe/Belfast',
      'Europe/Guernsey',
      'Europe/Isle_of_Man',
      'Europe/Jersey',
      'Europe/London',
      'GB',
      'GB-Eire',
    ],
  },
  {
    value: 'East Africa Time',
    abbr: 'EAT',
    text: '(UTC+03:00) East Africa Time',
    utc: [
      'Africa/Addis_Ababa',
      'Africa/Asmara',
      'Africa/Asmera',
      'Africa/Dar_es_Salaam',
      'Africa/Djibouti',
      'Africa/Kampala',
      'Africa/Mogadishu',
      'Africa/Nairobi',
      'Indian/Antananarivo',
      'Indian/Comoro',
      'Indian/Mayotte',
    ],
  },
  {
    value: 'Central European Time',
    abbr: 'CET',
    text: '(UTC+01:00) Central European Time',
    utc: [
      'Africa/Algiers',
      'Africa/Tunis',
      'Africa/Ceuta',
      'Arctic/Longyearbyen',
      'Atlantic/Jan_Mayen',
      'CET',
      'Europe/Amsterdam',
      'Europe/Andorra',
      'Europe/Belgrade',
      'Europe/Berlin',
      'Europe/Bratislava',
      'Europe/Brussels',
      'Europe/Budapest',
      'Europe/Busingen',
      'Europe/Copenhagen',
      'Europe/Gibraltar',
      'Europe/Ljubljana',
      'Europe/Luxembourg',
      'Europe/Madrid',
      'Europe/Malta',
      'Europe/Monaco',
      'Europe/Oslo',
      'Europe/Paris',
      'Europe/Podgorica',
      'Europe/Prague',
      'Europe/Rome',
      'Europe/San_Marino',
      'Europe/Sarajevo',
      'Europe/Skopje',
      'Europe/Stockholm',
      'Europe/Tirane',
      'Europe/Vaduz',
      'Europe/Vatican',
      'Europe/Vienna',
      'Europe/Warsaw',
      'Europe/Zagreb',
      'Europe/Zurich',
      'Poland',
    ],
  },
  {
    value: 'West Africa Time',
    abbr: 'WAT',
    text: '(UTC+01:00) West Africa Time',
    utc: [
      'Africa/Bangui',
      'Africa/Brazzaville',
      'Africa/Douala',
      'Africa/Kinshasa',
      'Africa/Lagos',
      'Africa/Libreville',
      'Africa/Luanda',
      'Africa/Malabo',
      'Africa/Ndjamena',
      'Africa/Niamey',
      'Africa/Porto-Novo',
    ],
  },
  {
    value: 'Central Africa Time',
    abbr: 'CAT',
    text: '(UTC+02:00) Central Africa Time',
    utc: [
      'Africa/Blantyre',
      'Africa/Bujumbura',
      'Africa/Gaborone',
      'Africa/Harare',
      'Africa/Juba',
      'Africa/Khartoum',
      'Africa/Kigali',
      'Africa/Lubumbashi',
      'Africa/Lusaka',
      'Africa/Maputo',
      'Africa/Windhoek',
    ],
  },
  {
    value: 'Eastern European Time',
    abbr: 'EET',
    text: '(UTC+02:00) Eastern European Time',
    utc: [
      'Africa/Cairo',
      'Africa/Tripoli',
      'Egypt',
      'Europe/Kaliningrad',
      'Libya',
      'Asia/Amman',
      'Asia/Beirut',
      'Asia/Damascus',
      'Asia/Famagusta',
      'Asia/Gaza',
      'Asia/Hebron',
      'Asia/Nicosia',
      'EET',
      'Europe/Athens',
      'Europe/Bucharest',
      'Europe/Chisinau',
      'Europe/Helsinki',
      'Europe/Kiev',
      'Europe/Mariehamn',
      'Europe/Nicosia',
      'Europe/Riga',
      'Europe/Sofia',
      'Europe/Tallinn',
      'Europe/Tiraspol',
      'Europe/Uzhgorod',
      'Europe/Vilnius',
      'Europe/Zaporozhye',
    ],
  },
  {
    value: 'Eastern European Summer Time',
    abbr: 'EEST',
    text: '(UTC+03:00) Eastern European Summer Time',
    utc: [
      'Asia/Amman',
      'Asia/Beirut',
      'Asia/Damascus',
      'Asia/Famagusta',
      'Asia/Gaza',
      'Asia/Hebron',
      'Asia/Nicosia',
      'EET',
      'Europe/Athens',
      'Europe/Bucharest',
      'Europe/Chisinau',
      'Europe/Helsinki',
      'Europe/Kiev',
      'Europe/Mariehamn',
      'Europe/Nicosia',
      'Europe/Riga',
      'Europe/Sofia',
      'Europe/Tallinn',
      'Europe/Tiraspol',
      'Europe/Uzhgorod',
      'Europe/Vilnius',
      'Europe/Zaporozhye',
    ],
  },
  {
    value: 'Central European Summer Time',
    abbr: 'CEST',
    text: '(UTC+02:00) Central European Summer Time',
    utc: [
      'Africa/Ceuta',
      'Arctic/Longyearbyen',
      'Atlantic/Jan_Mayen',
      'CET',
      'Europe/Amsterdam',
      'Europe/Andorra',
      'Europe/Belgrade',
      'Europe/Berlin',
      'Europe/Bratislava',
      'Europe/Brussels',
      'Europe/Budapest',
      'Europe/Busingen',
      'Europe/Copenhagen',
      'Europe/Gibraltar',
      'Europe/Ljubljana',
      'Europe/Luxembourg',
      'Europe/Madrid',
      'Europe/Malta',
      'Europe/Monaco',
      'Europe/Oslo',
      'Europe/Paris',
      'Europe/Podgorica',
      'Europe/Prague',
      'Europe/Rome',
      'Europe/San_Marino',
      'Europe/Sarajevo',
      'Europe/Skopje',
      'Europe/Stockholm',
      'Europe/Tirane',
      'Europe/Vaduz',
      'Europe/Vatican',
      'Europe/Vienna',
      'Europe/Warsaw',
      'Europe/Zagreb',
      'Europe/Zurich',
      'Poland',
    ],
  },
  {
    value: 'South African Standard Time',
    abbr: 'SAST',
    text: '(UTC+02:00) South African Standard Time',
    utc: ['Africa/Johannesburg', 'Africa/Maseru', 'Africa/Mbabane'],
  },
  {
    value: 'Hawaii Daylight Time',
    abbr: 'HDT',
    text: '(UTC-09:00) Hawaii Daylight Time',
    utc: ['America/Adak', 'America/Atka', 'US/Aleutian'],
  },
  {
    value: 'Hawaii Standard Time',
    abbr: 'HST',
    text: '(UTC-10:00) Hawaii Standard Time',
    utc: [
      'HST',
      'Pacific/Honolulu',
      'Pacific/Johnston',
      'US/Hawaii',
      'America/Adak',
      'America/Atka',
      'US/Aleutian',
    ],
  },
  {
    value: 'Alaska Daylight Time',
    abbr: 'AKDT',
    text: '(UTC-08:00) Alaska Daylight Time',
    utc: [
      'America/Anchorage',
      'America/Juneau',
      'America/Metlakatla',
      'America/Nome',
      'America/Sitka',
      'America/Yakutat',
      'US/Alaska',
    ],
  },
  {
    value: 'Alaska Standard Time',
    abbr: 'AKST',
    text: '(UTC-09:00) Alaska Standard Time',
    utc: [
      'America/Anchorage',
      'America/Juneau',
      'America/Metlakatla',
      'America/Nome',
      'America/Sitka',
      'America/Yakutat',
      'US/Alaska',
    ],
  },
  {
    value: 'Atlantic Standard Time',
    abbr: 'AST',
    text: '(UTC-04:00) Atlantic Standard Time',
    utc: [
      'America/Anguilla',
      'America/Antigua',
      'America/Aruba',
      'America/Barbados',
      'America/Blanc-Sablon',
      'America/Curacao',
      'America/Dominica',
      'America/Grenada',
      'America/Guadeloupe',
      'America/Kralendijk',
      'America/Lower_Princes',
      'America/Marigot',
      'America/Martinique',
      'America/Montserrat',
      'America/Port_of_Spain',
      'America/Puerto_Rico',
      'America/Santo_Domingo',
      'America/St_Barthelemy',
      'America/St_Kitts',
      'America/St_Lucia',
      'America/St_Thomas',
      'America/St_Vincent',
      'America/Tortola',
      'America/Virgin',
      'America/Glace_Bay',
      'America/Goose_Bay',
      'America/Halifax',
      'America/Moncton',
      'America/Thule',
      'Atlantic/Bermuda',
      'Canada/Atlantic',
    ],
  },
  {
    value: 'Atlantic Daylight Time',
    abbr: 'ADT',
    text: '(UTC-03:00) Atlantic Daylight Time',
    utc: [
      'America/Glace_Bay',
      'America/Goose_Bay',
      'America/Halifax',
      'America/Moncton',
      'America/Thule',
      'Atlantic/Bermuda',
      'Canada/Atlantic',
    ],
  },
  {
    value: 'Australian Western Standard Time',
    abbr: 'AWST',
    text: '(UTC+08:00) Australian Western Standard Time',
    utc: ['Australia/Perth', 'Australia/West'],
  },
  {
    value: 'Australian Eastern Standard Time',
    abbr: 'AEST',
    text: '(UTC+10:00) Australian Eastern Standard Time',
    utc: [
      'Antarctica/Macquarie',
      'Australia/ACT',
      'Australia/Brisbane',
      'Australia/Canberra',
      'Australia/Currie',
      'Australia/Hobart',
      'Australia/Lindeman',
      'Australia/Melbourne',
      'Australia/NSW',
      'Australia/Queensland',
      'Australia/Sydney',
      'Australia/Tasmania',
      'Australia/Victoria',
    ],
  },
  {
    value: 'Australian Eastern Daylight Time',
    abbr: 'AEDT',
    text: '(UTC+11:00) Australian Eastern Daylight Time',
    utc: [
      'Antarctica/Macquarie',
      'Australia/ACT',
      'Australia/Brisbane',
      'Australia/Canberra',
      'Australia/Currie',
      'Australia/Hobart',
      'Australia/Lindeman',
      'Australia/Melbourne',
      'Australia/NSW',
      'Australia/Queensland',
      'Australia/Sydney',
      'Australia/Tasmania',
      'Australia/Victoria',
    ],
  },
  {
    value: 'UTC+00:00',
    abbr: '+00',
    text: 'UTC+00:00',
    utc: ['America/Scoresbysund', 'Atlantic/Azores'],
  },
  {
    value: 'UTC+01:00',
    abbr: '+01',
    text: 'UTC+01:00',
    utc: ['Africa/Casablanca', 'Africa/El_Aaiun', 'Etc/GMT-1'],
  },
  {
    value: 'UTC+02:00',
    abbr: '+02',
    text: 'UTC+02:00',
    utc: ['Antarctica/Troll', 'Etc/GMT-2'],
  },
  {
    value: 'UTC+03:00',
    abbr: '+03',
    text: 'UTC+03:00',
    utc: [
      'Antarctica/Syowa',
      'Asia/Aden',
      'Asia/Baghdad',
      'Asia/Bahrain',
      'Asia/Istanbul',
      'Asia/Kuwait',
      'Asia/Qatar',
      'Asia/Riyadh',
      'Etc/GMT-3',
      'Europe/Istanbul',
      'Europe/Kirov',
      'Europe/Minsk',
      'Europe/Volgograd',
      'Turkey',
    ],
  },
  {
    value: 'UTC+03:30',
    abbr: '+0330',
    text: 'UTC+03:30',
    utc: ['Asia/Tehran'],
  },
  {
    value: 'UTC+04:00',
    abbr: '+04',
    text: 'UTC+04:00',
    utc: [
      'Asia/Baku',
      'Asia/Dubai',
      'Asia/Muscat',
      'Asia/Tbilisi',
      'Asia/Yerevan',
      'Etc/GMT-4',
      'Europe/Astrakhan',
      'Europe/Samara',
      'Europe/Saratov',
      'Europe/Ulyanovsk',
      'Indian/Mahe',
      'Indian/Mauritius',
      'Indian/Reunion',
    ],
  },
  {
    value: 'UTC+04:30',
    abbr: '+0430',
    text: 'UTC+04:30',
    utc: ['Asia/Kabul', 'Asia/Tehran', 'Iran'],
  },
  {
    value: 'UTC+05:00',
    abbr: '+05',
    text: 'UTC+05:00',
    utc: [
      'Antarctica/Mawson',
      'Asia/Aqtau',
      'Asia/Aqtobe',
      'Asia/Ashgabat',
      'Asia/Ashkhabad',
      'Asia/Atyrau',
      'Asia/Dushanbe',
      'Asia/Oral',
      'Asia/Qyzylorda',
      'Asia/Samarkand',
      'Asia/Tashkent',
      'Asia/Yekaterinburg',
      'Etc/GMT-5',
      'Indian/Kerguelen',
      'Indian/Maldives',
    ],
  },
  {
    value: 'UTC+05:30',
    abbr: '+0530',
    text: 'UTC+05:30',
    utc: ['Asia/Colombo'],
  },
  {
    value: 'UTC+05:45',
    abbr: '+0545',
    text: 'UTC+05:45',
    utc: ['Asia/Kathmandu', 'Asia/Katmandu'],
  },
  {
    value: 'UTC+06:00',
    abbr: '+06',
    text: 'UTC+06:00',
    utc: [
      'Antarctica/Vostok',
      'Asia/Almaty',
      'Asia/Bishkek',
      'Asia/Dacca',
      'Asia/Dhaka',
      'Asia/Kashgar',
      'Asia/Omsk',
      'Asia/Qostanay',
      'Asia/Thimbu',
      'Asia/Thimphu',
      'Asia/Urumqi',
      'Etc/GMT-6',
      'Indian/Chagos',
    ],
  },
  {
    value: 'UTC+06:30',
    abbr: '+0630',
    text: 'UTC+06:30',
    utc: ['Asia/Rangoon', 'Asia/Yangon', 'Indian/Cocos'],
  },
  {
    value: 'UTC+07:00',
    abbr: '+07',
    text: 'UTC+07:00',
    utc: [
      'Antarctica/Davis',
      'Asia/Bangkok',
      'Asia/Barnaul',
      'Asia/Ho_Chi_Minh',
      'Asia/Hovd',
      'Asia/Krasnoyarsk',
      'Asia/Novokuznetsk',
      'Asia/Novosibirsk',
      'Asia/Phnom_Penh',
      'Asia/Saigon',
      'Asia/Tomsk',
      'Asia/Vientiane',
      'Etc/GMT-7',
      'Indian/Christmas',
    ],
  },
  {
    value: 'UTC+08:00',
    abbr: '+08',
    text: 'UTC+08:00',
    utc: [
      'Asia/Brunei',
      'Asia/Choibalsan',
      'Asia/Irkutsk',
      'Asia/Kuala_Lumpur',
      'Asia/Kuching',
      'Asia/Singapore',
      'Asia/Ulaanbaatar',
      'Asia/Ulan_Bator',
      'Etc/GMT-8',
      'Singapore',
    ],
  },
  {
    value: 'UTC+08:45',
    abbr: '+0845',
    text: 'UTC+08:45',
    utc: ['Australia/Eucla'],
  },
  {
    value: 'UTC+09:00',
    abbr: '+09',
    text: 'UTC+09:00',
    utc: [
      'Asia/Chita',
      'Asia/Dili',
      'Asia/Khandyga',
      'Asia/Yakutsk',
      'Etc/GMT-9',
      'Pacific/Palau',
    ],
  },
  {
    value: 'UTC+10:00',
    abbr: '+10',
    text: 'UTC+10:00',
    utc: [
      'Antarctica/DumontDUrville',
      'Asia/Ust-Nera',
      'Asia/Vladivostok',
      'Etc/GMT-10',
      'Pacific/Chuuk',
      'Pacific/Port_Moresby',
      'Pacific/Truk',
      'Pacific/Yap',
    ],
  },
  {
    value: 'UTC+10:30',
    abbr: '+1030',
    text: 'UTC+10:30',
    utc: ['Australia/LHI', 'Australia/Lord_Howe'],
  },
  {
    value: 'UTC+11:00',
    abbr: '+11',
    text: 'UTC+11:00',
    utc: [
      'Antarctica/Casey',
      'Asia/Magadan',
      'Asia/Sakhalin',
      'Asia/Srednekolymsk',
      'Etc/GMT-11',
      'Pacific/Bougainville',
      'Pacific/Efate',
      'Pacific/Guadalcanal',
      'Pacific/Kosrae',
      'Pacific/Norfolk',
      'Pacific/Noumea',
      'Pacific/Pohnpei',
      'Pacific/Ponape',
    ],
  },
  {
    value: 'UTC+12:00',
    abbr: '+12',
    text: 'UTC+12:00',
    utc: [
      'Asia/Anadyr',
      'Asia/Kamchatka',
      'Etc/GMT-12',
      'Kwajalein',
      'Pacific/Fiji',
      'Pacific/Funafuti',
      'Pacific/Kwajalein',
      'Pacific/Majuro',
      'Pacific/Nauru',
      'Pacific/Tarawa',
      'Pacific/Wake',
      'Pacific/Wallis',
    ],
  },
  {
    value: 'UTC+12:45',
    abbr: '+1245',
    text: 'UTC+12:45',
    utc: ['NZ-CHAT', 'Pacific/Chatham'],
  },
  {
    value: 'UTC+13:00',
    abbr: '+13',
    text: 'UTC+13:00',
    utc: [
      'Etc/GMT-13',
      'Pacific/Apia',
      'Pacific/Enderbury',
      'Pacific/Fakaofo',
      'Pacific/Kanton',
      'Pacific/Tongatapu',
    ],
  },
  {
    value: 'UTC+14:00',
    abbr: '+14',
    text: 'UTC+14:00',
    utc: ['Etc/GMT-14', 'Pacific/Kiritimati'],
  },
  {
    value: 'UTC-01:00',
    abbr: '-01',
    text: 'UTC-01:00',
    utc: ['Atlantic/Cape_Verde', 'Etc/GMT+1'],
  },
  {
    value: 'UTC-02:00',
    abbr: '-02',
    text: 'UTC-02:00',
    utc: [
      'America/Godthab',
      'America/Miquelon',
      'America/Noronha',
      'America/Nuuk',
      'Atlantic/South_Georgia',
      'Brazil/DeNoronha',
      'Etc/GMT+2',
    ],
  },
  {
    value: 'UTC-03:00',
    abbr: '-03',
    text: 'UTC-03:00',
    utc: [
      'America/Araguaina',
      'America/Argentina/Buenos_Aires',
      'America/Argentina/Catamarca',
      'America/Argentina/ComodRivadavia',
      'America/Argentina/Cordoba',
      'America/Argentina/Jujuy',
      'America/Argentina/La_Rioja',
      'America/Argentina/Mendoza',
      'America/Argentina/Rio_Gallegos',
      'America/Argentina/Salta',
      'America/Argentina/San_Juan',
      'America/Argentina/San_Luis',
      'America/Argentina/Tucuman',
      'America/Argentina/Ushuaia',
      'America/Bahia',
      'America/Belem',
      'America/Buenos_Aires',
      'America/Catamarca',
      'America/Cayenne',
      'America/Cordoba',
      'America/Fortaleza',
      'America/Jujuy',
      'America/Maceio',
      'America/Mendoza',
      'America/Montevideo',
      'America/Paramaribo',
      'America/Punta_Arenas',
      'America/Recife',
      'America/Rosario',
      'America/Santarem',
      'America/Sao_Paulo',
      'Antarctica/Palmer',
      'Antarctica/Rothera',
      'America/Santiago',
      'Atlantic/Stanley',
      'Brazil/East',
      'Etc/GMT+3',
    ],
  },
  {
    value: 'UTC-04:00',
    abbr: '-04',
    text: 'UTC-04:00',
    utc: [
      'America/Asuncion',
      'America/Boa_Vista',
      'America/Campo_Grande',
      'America/Caracas',
      'America/Cuiaba',
      'America/Guyana',
      'America/La_Paz',
      'America/Manaus',
      'America/Porto_Velho',
      'America/Santiago',
      'Brazil/West',
      'Chile/Continental',
      'Etc/GMT+4',
    ],
  },
  {
    value: 'UTC-05:00',
    abbr: '-05',
    text: 'UTC-05:00',
    utc: [
      'America/Bogota',
      'America/Eirunepe',
      'America/Guayaquil',
      'America/Lima',
      'America/Porto_Acre',
      'America/Rio_Branco',
      'Brazil/Acre',
      'Etc/GMT+5',
    ],
  },
  {
    value: 'UTC-06:00',
    abbr: '-06',
    text: 'UTC-06:00',
    utc: [
      'Chile/EasterIsland',
      'Etc/GMT+6',
      'Pacific/Easter',
      'Pacific/Galapagos',
    ],
  },
  {
    value: 'UTC-07:00',
    abbr: '-07',
    text: 'UTC-07:00',
    utc: ['Etc/GMT+7'],
  },
  {
    value: 'UTC-08:00',
    abbr: '-08',
    text: 'UTC-08:00',
    utc: ['Etc/GMT+8', 'Pacific/Pitcairn'],
  },
  {
    value: 'UTC-09:00',
    abbr: '-09',
    text: 'UTC-09:00',
    utc: ['Etc/GMT+9', 'Pacific/Gambier'],
  },
  {
    value: 'UTC-09:30',
    abbr: '-0930',
    text: 'UTC-09:30',
    utc: ['Pacific/Marquesas'],
  },
  {
    value: 'UTC-10:00',
    abbr: '-10',
    text: 'UTC-10:00',
    utc: ['Etc/GMT+10', 'Pacific/Rarotonga', 'Pacific/Tahiti'],
  },
  {
    value: 'UTC-11:00',
    abbr: '-11',
    text: 'UTC-11:00',
    utc: ['Etc/GMT+11', 'Pacific/Niue'],
  },
  {
    value: 'UTC-12:00',
    abbr: '-12',
    text: 'UTC-12:00',
    utc: ['Etc/GMT+12'],
  },
  {
    value: 'Australian Central Standard Time',
    abbr: 'ACST',
    text: '(UTC+09:30) Australian Central Standard Time',
    utc: [
      'Australia/Adelaide',
      'Australia/Broken_Hill',
      'Australia/Darwin',
      'Australia/North',
      'Australia/South',
      'Australia/Yancowinna',
    ],
  },
  {
    value: 'Australian Central Daylight Time',
    abbr: 'ACDT',
    text: '(UTC+10:30) Australian Central Daylight Time',
    utc: [
      'Australia/Adelaide',
      'Australia/Broken_Hill',
      'Australia/Darwin',
      'Australia/North',
      'Australia/South',
      'Australia/Yancowinna',
    ],
  },
  {
    value: 'Eastern Standard Time',
    abbr: 'EST',
    text: '(UTC-05:00) Eastern Standard Time',
    utc: [
      'America/Atikokan',
      'America/Cancun',
      'America/Cayman',
      'America/Coral_Harbour',
      'America/Jamaica',
      'America/Panama',
      'EST',
      'Jamaica',
      'America/Detroit',
      'America/Fort_Wayne',
      'America/Grand_Turk',
      'America/Indiana/Indianapolis',
      'America/Indiana/Marengo',
      'America/Indiana/Petersburg',
      'America/Indiana/Vevay',
      'America/Indiana/Vincennes',
      'America/Indiana/Winamac',
      'America/Indianapolis',
      'America/Iqaluit',
      'America/Kentucky/Louisville',
      'America/Kentucky/Monticello',
      'America/Louisville',
      'America/Montreal',
      'America/Nassau',
      'America/New_York',
      'America/Nipigon',
      'America/Pangnirtung',
      'America/Port-au-Prince',
      'America/Thunder_Bay',
      'America/Toronto',
      'Canada/Eastern',
      'EST5EDT',
      'US/East-Indiana',
      'US/Eastern',
      'US/Michigan',
    ],
  },
  {
    value: 'Eastern Daylight Time',
    abbr: 'EDT',
    text: '(UTC-06:00) Eastern Daylight Time',
    utc: [
      'America/Detroit',
      'America/Fort_Wayne',
      'America/Grand_Turk',
      'America/Indiana/Indianapolis',
      'America/Indiana/Marengo',
      'America/Indiana/Petersburg',
      'America/Indiana/Vevay',
      'America/Indiana/Vincennes',
      'America/Indiana/Winamac',
      'America/Indianapolis',
      'America/Iqaluit',
      'America/Kentucky/Louisville',
      'America/Kentucky/Monticello',
      'America/Louisville',
      'America/Montreal',
      'America/Nassau',
      'America/New_York',
      'America/Nipigon',
      'America/Pangnirtung',
      'America/Port-au-Prince',
      'America/Thunder_Bay',
      'America/Toronto',
      'Canada/Eastern',
      'EST5EDT',
      'US/East-Indiana',
      'US/Eastern',
      'US/Michigan',
    ],
  },
  {
    value: 'Central Daylight Time',
    abbr: 'CDT',
    text: '(UTC-05:00) Central Daylight Time',
    utc: [
      'America/Chicago',
      'America/Havana',
      'America/Indiana/Knox',
      'America/Indiana/Tell_City',
      'America/Knox_IN',
      'America/Matamoros',
      'America/Menominee',
      'America/Merida',
      'America/Mexico_City',
      'America/Monterrey',
      'America/North_Dakota/Beulah',
      'America/North_Dakota/Center',
      'America/North_Dakota/New_Salem',
      'America/Rainy_River',
      'America/Rankin_Inlet',
      'America/Resolute',
      'America/Winnipeg',
      'CST6CDT',
      'Canada/Central',
      'Cuba',
      'Mexico/General',
      'US/Central',
      'US/Indiana-Starke',
    ],
  },
  {
    value: 'Central Standard Time',
    abbr: 'CST',
    text: '(UTC-06:00) Central Standard Time',
    utc: [
      'America/Bahia_Banderas',
      'America/Chicago',
      'America/Havana',
      'America/Indiana/Knox',
      'America/Indiana/Tell_City',
      'America/Knox_IN',
      'America/Matamoros',
      'America/Menominee',
      'America/Merida',
      'America/Mexico_City',
      'America/Monterrey',
      'America/North_Dakota/Beulah',
      'America/North_Dakota/Center',
      'America/North_Dakota/New_Salem',
      'America/Rainy_River',
      'America/Rankin_Inlet',
      'America/Resolute',
      'America/Winnipeg',
      'CST6CDT',
      'Canada/Central',
      'Cuba',
      'Mexico/General',
      'US/Central',
      'US/Indiana-Starke',
      'America/Belize',
      'America/Costa_Rica',
      'America/El_Salvador',
      'America/Guatemala',
      'America/Managua',
      'America/Regina',
      'America/Swift_Current',
      'America/Tegucigalpa',
      'Canada/Saskatchewan',
    ],
  },
  {
    value: 'Mountain Daylight Time',
    abbr: 'MDT',
    text: '(UTC-06:00) Mountain Daylight Time',
    utc: [
      'America/Boise',
      'America/Cambridge_Bay',
      'America/Chihuahua',
      'America/Denver',
      'America/Edmonton',
      'America/Inuvik',
      'America/Mazatlan',
      'America/Ojinaga',
      'America/Shiprock',
      'America/Yellowknife',
      'Canada/Mountain',
      'MST7MDT',
      'Mexico/BajaSur',
      'Navajo',
      'US/Mountain',
    ],
  },
  {
    value: 'Mountain Standard Time',
    abbr: 'MST',
    text: '(UTC-07:00) Mountain Standard Time',
    utc: [
      'America/Creston',
      'America/Dawson',
      'America/Dawson_Creek',
      'America/Fort_Nelson',
      'America/Hermosillo',
      'America/Phoenix',
      'America/Whitehorse',
      'Canada/Yukon',
      'MST',
      'US/Arizona',
      'America/Boise',
      'America/Cambridge_Bay',
      'America/Chihuahua',
      'America/Denver',
      'America/Edmonton',
      'America/Inuvik',
      'America/Mazatlan',
      'America/Ojinaga',
      'America/Shiprock',
      'America/Yellowknife',
      'Canada/Mountain',
      'MST7MDT',
      'Mexico/BajaSur',
      'Navajo',
      'US/Mountain',
    ],
  },
  {
    value: 'Pacific Daylight Time',
    abbr: 'PDT',
    text: '(UTC-07:00) Pacific Daylight Time',
    utc: [
      'America/Ensenada',
      'America/Los_Angeles',
      'America/Santa_Isabel',
      'America/Tijuana',
      'America/Vancouver',
      'Canada/Pacific',
      'Mexico/BajaNorte',
      'PST8PDT',
      'US/Pacific',
    ],
  },
  {
    value: 'Pacific Standard Time',
    abbr: 'PST',
    text: '(UTC-08:00) Pacific Standard Time',
    utc: [
      'America/Ensenada',
      'America/Los_Angeles',
      'America/Santa_Isabel',
      'America/Tijuana',
      'America/Vancouver',
      'Canada/Pacific',
      'Mexico/BajaNorte',
      'PST8PDT',
      'US/Pacific',
    ],
  },
  {
    value: 'China Standard Time',
    abbr: 'CST',
    text: '(UTC+08:00) China Standard Time',
    utc: [
      'Asia/Chongqing',
      'Asia/Chungking',
      'Asia/Harbin',
      'Asia/Macao',
      'Asia/Macau',
      'Asia/Shanghai',
      'Asia/Taipei',
      'PRC',
      'ROC',
    ],
  },
  {
    value: 'Chamorro Standard Time',
    abbr: 'ChST',
    text: '(UTC+10:00) Chamorro Standard Time',
    utc: ['Pacific/Guam', 'Pacific/Saipan'],
  },
  {
    value: 'Israel Daylight Time',
    abbr: 'IDT',
    text: '(UTC+03:00) Israel Daylight Time',
    utc: ['Asia/Jerusalem', 'Asia/Tel_Aviv', 'Israel'],
  },
  {
    value: 'Israel Standard Time',
    abbr: 'IST',
    text: '(UTC+02:00) Israel Standard Time',
    utc: ['Asia/Jerusalem', 'Asia/Tel_Aviv', 'Israel'],
  },
  {
    value: 'Irish Standard Time',
    abbr: 'IST',
    text: '(UTC+01:00) Irish Standard Time',
    utc: ['Eire', 'Europe/Dublin'],
  },
  {
    value: 'Indian Standard Time',
    abbr: 'IST',
    text: '(UTC+05:30) Indian Standard Time',
    utc: ['Asia/Calcutta', 'Asia/Kolkata'],
  },
  {
    value: 'Japan Standard Time',
    abbr: 'JST',
    text: '(UTC+09:00) Japan Standard Time',
    utc: ['Asia/Tokyo', 'Japan'],
  },
  {
    value: 'Korea Standard Time',
    abbr: 'KST',
    text: '(UTC+09:00) Korea Standard Time',
    utc: ['Asia/Pyongyang', 'Asia/Seoul', 'ROK'],
  },
  {
    value: 'Hong Kong Standard Time',
    abbr: 'HKT',
    text: '(UTC+08:00) Hong Kong Standard Time',
    utc: ['Asia/Hong_Kong'],
  },
  {
    value: 'Moscow Time',
    abbr: 'MSK',
    text: '(UTC+03:00) Moscow Time',
    utc: ['Europe/Moscow', 'Europe/Simferopol', 'W-SU'],
  },
  {
    value: 'Newfoundland Daylight Time',
    abbr: 'NDT',
    text: '(UTC-02:30) Newfoundland Daylight Time',
    utc: ['America/St_Johns', 'Canada/Newfoundland'],
  },
  {
    value: 'Newfoundland Standard Time',
    abbr: 'NST',
    text: '(UTC-03:30) Newfoundland Standard Time',
    utc: ['America/St_Johns', 'Canada/Newfoundland'],
  },
  {
    value: 'New Zealand Standard Time',
    abbr: 'NZST',
    text: '(UTC+12:00) New Zealand Standard Time',
    utc: [
      'Antarctica/McMurdo',
      'Antarctica/South_Pole',
      'NZ',
      'Pacific/Auckland',
    ],
  },
  {
    value: 'New Zealand Daylight Time',
    abbr: 'NZDT',
    text: '(UTC+13:00) New Zealand Daylight Time',
    utc: [
      'Antarctica/McMurdo',
      'Antarctica/South_Pole',
      'NZ',
      'Pacific/Auckland',
    ],
  },
  {
    value: 'Philippine Standard Time',
    abbr: 'PST',
    text: '(UTC+08:00) Philippine Standard Time',
    utc: ['Asia/Manila'],
  },
  {
    value: 'Pakistan Standard Time',
    abbr: 'PKT',
    text: '(UTC+05:00) Pakistan Standard Time',
    utc: ['Asia/Karachi'],
  },
  {
    value: 'Samoa Standard Time',
    abbr: 'SST',
    text: '(UTC-11:00) Samoa Standard Time',
    utc: ['Pacific/Midway', 'Pacific/Pago_Pago', 'Pacific/Samoa', 'US/Samoa'],
  },
  {
    value: 'Western European Summer Time',
    abbr: 'WEST',
    text: '(UTC+01:00) Western European Summer Time',
    utc: [
      'Atlantic/Canary',
      'Atlantic/Faeroe',
      'Atlantic/Faroe',
      'Atlantic/Madeira',
      'Europe/Lisbon',
      'Portugal',
      'WET',
    ],
  },
  {
    value: 'Western European Time',
    abbr: 'WET',
    text: '(UTC+00:00) Western European Time',
    utc: [
      'Atlantic/Canary',
      'Atlantic/Faeroe',
      'Atlantic/Faroe',
      'Atlantic/Madeira',
      'Europe/Lisbon',
      'Portugal',
      'WET',
    ],
  },
  {
    value: 'Western Indonesia Time',
    abbr: 'WIB',
    text: '(UTC+07:00) Western Indonesia Time',
    utc: ['Asia/Jakarta', 'Asia/Pontianak'],
  },
  {
    value: 'Central Indonesia Time',
    abbr: 'WITA',
    text: '(UTC+08:00) Central Indonesia Time',
    utc: ['Asia/Makassar', 'Asia/Ujung_Pandang'],
  },
  {
    value: 'Eastern Indonesia Time',
    abbr: 'WIT',
    text: '(UTC+09:00) Eastern Indonesia Time',
    utc: ['Asia/Jayapura'],
  },
];
