export const AttendeeMic = ({
  width = '19',
  height = '24',
  className = 'fill-[#8B33F7]',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00004 12.5C10.2092 12.5 12 10.7091 12 8.5V4.5C12 2.29086 10.2092 0.5 8.00004 0.5C5.7909 0.5 4.00004 2.29086 4.00004 4.5V8.5C4.00004 10.7091 5.7909 12.5 8.00004 12.5ZM6.00004 4.5C6.00004 3.39543 6.89547 2.5 8.00004 2.5C9.10461 2.5 10 3.39543 10 4.5V8.5C10 9.60457 9.10461 10.5 8.00004 10.5C6.89547 10.5 6.00004 9.60457 6.00004 8.5V4.5ZM14.86 11.7C14.9671 11.7463 15.0478 11.838 15.08 11.95C15.1067 12.0665 15.0771 12.1887 15 12.28V12.36C13.7649 14.5971 11.5353 16.1095 9.00004 16.43V18.5H10.5C10.7762 18.5 11 18.7239 11 19V20C11 20.2761 10.7762 20.5 10.5 20.5H5.50004C5.2239 20.5 5.00004 20.2761 5.00004 20V19C5.00004 18.7239 5.2239 18.5 5.50004 18.5H6.96004V16.48C4.42479 16.1595 2.19522 14.6471 0.960041 12.41C0.905572 12.2822 0.905572 12.1378 0.960041 12.01C0.999357 11.8772 1.09414 11.7678 1.22004 11.71L2.13004 11.29C2.3653 11.179 2.64626 11.2712 2.77004 11.5C3.84807 13.3939 5.86827 14.5544 8.04741 14.5316C10.2265 14.5087 12.222 13.3061 13.26 11.39C13.3662 11.1931 13.608 11.114 13.81 11.21L14.86 11.7Z"
      />
    </svg>
  );
};
