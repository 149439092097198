import styled, { css } from 'styled-components';
import { baseInputStyle, fieldLabel } from '../../theme/baseStyles';
import { scrollbarCss } from '../../helper/css';

export const SelectButton = styled.div`
  ${baseInputStyle}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      cursor: default;
      background-color: ${theme.palette.background.disabled};
      border-color: ${theme.palette.border.disabled};
      color: ${theme.palette.text.disabled};
    `}

  // space the input text and chevron to either side of the button
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  margin-bottom: ${({ theme }) => theme.spacing(0.5)}px;
`;

export const SelectItemsContainer = styled.div`
  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: 40px;
      right: 0;
      width: 200px;
      z-index: 3;

      max-height: 280px;
      overflow-y: auto;
      ${scrollbarCss(true, false)}
    `}

  ${(props) =>
    props.horizontal &&
    css`
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
    `}

  padding: 0;
  margin: 0;
  border: 1px solid #ebecf0; // TODO use theme colors
  box-shadow: 0 4px 8px 0 rgba(16, 24, 64, 0.08); // TODO use theme colors
`;

export const SelectItemContainer = styled.div`
  // Style in same manner as an input field label.
  ${fieldLabel};
  // override with paragraph theme color.
  color: ${({ theme }) => theme.palette.text.paragraph};

  // enable hover behavior to show the active hovered select item.
  background-color: ${({ active, theme }) =>
    active
      ? theme.palette.background.selectHover
      : theme.palette.background.default};
  :hover {
    background-color: ${({ theme }) => theme.palette.background.selectHover};
  }

  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing(1.5)}px;
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 8px;
`;
