import React from 'react';

export const LinkedInIconV2 = ({
  width = '20px',
  height = '20px',
  className = 'fill-purple',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.91699 4.16667C2.91699 3.24619 3.66318 2.5 4.58366 2.5C5.02569 2.5 5.44961 2.67559 5.76217 2.98816C6.07473 3.30072 6.25033 3.72464 6.25033 4.16667C6.25033 5.08714 5.50413 5.83333 4.58366 5.83333C3.66318 5.83333 2.91699 5.08714 2.91699 4.16667ZM17.5003 11.175C17.529 9.37075 16.2982 7.78968 14.542 7.375C13.3651 7.12159 12.1384 7.45389 11.2503 8.26667V7.91667C11.2503 7.68655 11.0638 7.5 10.8337 7.5H8.75032C8.52021 7.5 8.33366 7.68655 8.33366 7.91667V16.25C8.33366 16.4801 8.52021 16.6667 8.75032 16.6667H10.8337C11.0638 16.6667 11.2503 16.4801 11.2503 16.25V11.55C11.2297 10.7131 11.813 9.98223 12.6337 9.81667C13.1223 9.73232 13.6231 9.86992 14 10.1921C14.377 10.5143 14.5909 10.9875 14.5837 11.4833V16.25C14.5837 16.4801 14.7702 16.6667 15.0003 16.6667H17.0837C17.3138 16.6667 17.5003 16.4801 17.5003 16.25V11.175ZM6.25033 7.91667V16.25C6.25033 16.4801 6.06378 16.6667 5.83366 16.6667H3.75033C3.52021 16.6667 3.33366 16.4801 3.33366 16.25V7.91667C3.33366 7.68655 3.52021 7.5 3.75033 7.5H5.83366C6.06378 7.5 6.25033 7.68655 6.25033 7.91667Z"
    />
  </svg>
);
