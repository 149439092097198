import React, { useState } from 'react';
import {
  SoundBoardContainer,
  Item,
  ItemImg,
  ItemCaption,
} from './SoundBoard.style';
import { isUserMeetingController } from '../../helper/roles';

const SoundBoardItem = ({
  handleClick,
  soundTime,
  imageSrc,
  gifSrc,
  soundName,
  scale,
}) => {
  const [soundPlaying, setSoundPlaying] = useState(false);

  return (
    <Item
      onClick={() => {
        if (!soundPlaying) {
          setSoundPlaying(true);
          handleClick();
          setTimeout(() => {
            setSoundPlaying(false);
          }, soundTime);
        }
      }}
      hoverEffect={soundPlaying ? false : true}
    >
      <ItemImg src={soundPlaying ? gifSrc : imageSrc} scale={scale} />
      <ItemCaption>{soundName}</ItemCaption>
    </Item>
  );
};

export const SoundBoard = ({ position, playSound, soundsMap, user }) => {
  return (
    isUserMeetingController(user) && (
      <SoundBoardContainer position={position}>
        {Object.keys(soundsMap).map((sound) => (
          <SoundBoardItem
            handleClick={() => playSound(sound)}
            soundTime={soundsMap[sound].soundTime}
            imageSrc={soundsMap[sound].image}
            scale={soundsMap[sound].scale}
            gifSrc={soundsMap[sound].gif}
            soundName={soundsMap[sound].soundName}
            key={soundsMap[sound].soundName}
          />
        ))}
      </SoundBoardContainer>
    )
  );
};
