import React from 'react';

export const MailIcon = ({
  width = 20,
  height = 16,
  className = 'fill-purple',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 0H2C0.89543 0 0 0.89543 0 2V14C0 15.1046 0.89543 16 2 16H18C19.1046 16 20 15.1046 20 14V2C20 0.89543 19.1046 0 18 0ZM18 2V4.9L10.65 10.05C10.2591 10.3213 9.74087 10.3213 9.35 10.05L2 4.9V2H18ZM2 7V14H18V7L11.65 11.44C10.659 12.1327 9.34095 12.1327 8.35 11.44L2 7Z"
      />
    </svg>
  );
};
