import React from 'react';

export const TriangleAlertIcon = ({
  width = '12px',
  height = '12px',
  className = 'stroke-[#F8155C]',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M5.145 1.92668L0.909999 8.99668C0.822683 9.1479 0.776482 9.31933 0.775993 9.49394C0.775505 9.66855 0.820744 9.84025 0.907212 9.99195C0.99368 10.1436 1.11836 10.2701 1.26885 10.3586C1.41934 10.4472 1.5904 10.4948 1.765 10.4967H10.235C10.4096 10.4948 10.5807 10.4472 10.7311 10.3586C10.8816 10.2701 11.0063 10.1436 11.0928 9.99195C11.1793 9.84025 11.2245 9.66855 11.224 9.49394C11.2235 9.31933 11.1773 9.1479 11.09 8.99668L6.855 1.92668C6.76586 1.77974 6.64036 1.65824 6.4906 1.57393C6.34083 1.48961 6.17187 1.44531 6 1.44531C5.82813 1.44531 5.65916 1.48961 5.5094 1.57393C5.35964 1.65824 5.23413 1.77974 5.145 1.92668V1.92668Z"
      className={className}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 4.5V6.5"
      className={className}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 8.5H6.00667"
      className={className}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
