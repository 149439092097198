import mixpanel from 'mixpanel-browser';
import { useCallback, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getMeetingSeriesProps } from 'zync-common/tracking';
import { createNewSeriesApi } from '../helper/api';
import { useUserWorkspaces } from './useUserWorkspaces';
import { fromError, logerror } from '../helper/contextualLogger';

export const getWorkspaceIdFromUrl = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get('workspaceId');
};

export const getWorkspaceIdFromStorage = () => {
  try {
    return localStorage.getItem('defaultWorkspaceId');
  } catch (error) {
    return null;
  }
};

export const useMeetingCreationFromTemplate = () => {
  const history = useHistory();
  const { user = {} } = useSelector((st) => st.auth, shallowEqual);
  const { currentWorkspaceId } = useUserWorkspaces();

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateModalOpen, setTemplateModalOpen] = useState(false);
  const [showNewMeetingModal, setShowNewMeetingModal] = useState(false);

  const createNewMeeting = useCallback(
    async ({ visibility, title, scheduledEvent }) => {
      if (!title) {
        return;
      }

      const workspaceId = currentWorkspaceId || getWorkspaceIdFromUrl();

      try {
        const series = await createNewSeriesApi(
          workspaceId,
          user.emailAddress,
          title,
          '' /* empty description */,
          visibility,
          selectedTemplate,
          scheduledEvent
        );

        history.push(`/e/${series.meetingSeriesId}`);

        return mixpanel.track('Portal - Series Created', {
          ...getMeetingSeriesProps(series),
        });
      } catch (err) {
        logerror(fromError(err));
      }
    },
    [history, selectedTemplate, user.emailAddress, currentWorkspaceId]
  );

  return {
    createNewMeeting,
    selectedTemplate,
    setSelectedTemplate,
    showNewMeetingModal,
    setShowNewMeetingModal,
    templateModalOpen,
    setTemplateModalOpen,
  };
};
