import { useState, useCallback, useRef, useMemo } from 'react';
import mixpanel from 'mixpanel-browser';
import { useHistory } from 'react-router-dom';
import { getMeetingSeriesProps } from 'zync-common/tracking';
import { getSeries } from '../pages/Series/Series.jsx';
import { updateSeriesApi } from '../helper/api';
import { isMeetingController } from '../helper/roles';
import { useSelector } from 'react-redux';
import { PreviewInstructionsModal } from '../components/PreviewInstructionsModal';
import { endRunningMeeting } from '../helper/api';
import { fromError, logerror } from '../helper/contextualLogger';

export const useMeetingInformation = ({
  meetingTitle,
  description,
  series,
  meetingSeriesId,
  localDispatch,
}) => {
  /**
   * (Start / Join / Test) Meeting button logic
   */
  const {
    upcomingMeeting,
    attendees,
    previewMeetingCompleted,
    autoLaunchConfig,
  } = series;

  const [joinClicked, setJoinClicked] = useState(false);

  const { state } = upcomingMeeting || {};
  const { running } = state || {};
  const { user } = useSelector((_st) => _st.auth);
  const { userPreferences } = user || {};
  const [previewInstructionsModalOpen, setPreviewInstructionsModalOpen] =
    useState(false);
  const { showPreviewInstructions } = userPreferences || {};
  const { features = {} } = autoLaunchConfig;
  const { meetingPreviewButtonText = 'Preview Event' } = features;
  const history = useHistory();

  const isCurrentUserMeetingController = useMemo(() => {
    const currentUser = attendees.find(
      (attendee) => attendee.emailAddress === user.userId
    );
    if (currentUser) {
      return isMeetingController(currentUser);
    } else {
      return false;
    }
  }, [attendees, user.userId]);

  const handleClickPreview = useCallback(() => {
    mixpanel.track('Series - Home Preview Click', {
      ...getMeetingSeriesProps(series),
    });
    history.push(`/e/${meetingSeriesId}/live?preview`);
  }, [series, meetingSeriesId, history]);

  const updatePreviewMeetingCompleted = async (previewMeetingCompleted) => {
    const updatedSeries = Object.assign({}, series);
    updatedSeries.previewMeetingCompleted = previewMeetingCompleted;
    const result = await updateSeriesApi(meetingSeriesId, updatedSeries);
    localDispatch({ type: 'REFRESH_SERIES', series: result });
  };

  const goToMeetingButtonText =
    isCurrentUserMeetingController && !running ? 'Start Event' : 'Go to Event';

  const handleGoToMeeting = useCallback(() => {
    mixpanel.track('Series - Home Join Meeting Click', {
      ...getMeetingSeriesProps(series),
    });
    setJoinClicked(true);
  }, [series]);

  /**
   * API Post Request logic for Title & Description
   */
  const updateMeetingTitle = async (newTitle) => {
    try {
      await updateSeriesApi(meetingSeriesId, { title: newTitle });
      mixpanel.track('Series - Settings Title Updated', {
        ...getMeetingSeriesProps(series),
        newTitle,
      });
      getSeries(meetingSeriesId, localDispatch);
    } catch (e) {
      logerror(fromError(e));
    }
  };

  const updateMeetingDescription = async (newDescription) => {
    try {
      await updateSeriesApi(meetingSeriesId, {
        description: newDescription,
      });
      mixpanel.track('Series - Settings Description Updated', {
        ...getMeetingSeriesProps(series),
        description,
      });
      getSeries(meetingSeriesId, localDispatch);
    } catch (e) {
      logerror(fromError(e));
    }
  };

  /**
   * Local component logic
   */

  const [newTitle, setNewTitle] = useState(meetingTitle ?? '');
  const [newDescription, setNewDescription] = useState(description ?? '');
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const titleInputEl = useRef(null);
  const descriptionInputEl = useRef(null);

  const handleEditTitle = (e) => {
    e.preventDefault();
    if (!isEditingTitle) {
      setIsEditingTitle(true);
    } else {
      setIsEditingTitle(false);
    }
  };

  const handleEditDescription = (e) => {
    e.preventDefault();
    if (!isEditingDescription) {
      setIsEditingDescription(true);
    } else {
      setIsEditingDescription(false);
    }
  };

  /** Logic for meeting template privelage buttons */

  const { userId, privileges } = user || {};
  const allowForceTerminateMeeting =
    privileges?.allowForceTerminateMeeting || false;
  const { allowTemplateManagement = false } = privileges || {};
  const [showCreateMeetingTemplateModal, setShowCreateMeetingTemplateModal] =
    useState(false);
  const [showUpdateMeetingTemplateModal, setShowUpdateMeetingTemplateModal] =
    useState(false);

  const handleMeetingForceTermination = useCallback(async () => {
    await endRunningMeeting(meetingSeriesId, userId);
    getSeries(meetingSeriesId, localDispatch);
  }, [meetingSeriesId, userId, localDispatch]);

  return {
    handleClickPreview,
    updatePreviewMeetingCompleted,
    goToMeetingButtonText,
    previewMeetingCompleted,
    showPreviewInstructions,
    isCurrentUserMeetingController,
    previewInstructionsModalOpen,
    setPreviewInstructionsModalOpen,
    user,
    meetingPreviewButtonText,
    PreviewInstructionsModal,
    handleGoToMeeting,
    updateMeetingTitle,
    updateMeetingDescription,
    newTitle,
    newDescription,
    setIsEditingDescription,
    setNewTitle,
    setNewDescription,
    joinClicked,
    isEditingTitle,
    setIsEditingTitle,
    isEditingDescription,
    titleInputEl,
    descriptionInputEl,
    handleEditTitle,
    handleEditDescription,
    allowForceTerminateMeeting,
    allowTemplateManagement,
    handleMeetingForceTermination,
    showCreateMeetingTemplateModal,
    setShowCreateMeetingTemplateModal,
    showUpdateMeetingTemplateModal,
    setShowUpdateMeetingTemplateModal,
    userId,
    autoLaunchConfig,
  };
};
