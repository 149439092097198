import { useEffect, useCallback } from 'react';

/**
 * A hook that listens for the Escape key press
 * and executes the argument callback.
 */
export const useEscapeKeyPress = (cb) => {
  const handleEscapeKeyPress = useCallback(
    (e) => {
      if (e.key === 'Escape') {
        cb();
      }
    },
    [cb]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleEscapeKeyPress);
    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress);
    };
  }, [handleEscapeKeyPress]);
};
