import { shallowEqual, useSelector } from 'react-redux';
import { isEqual } from 'lodash/fp';

/** useMeeting hook for state selectors of Meeting.jsx component */
export const useMeeting = () => {
  const meetingId = useSelector((state) => state.meetingState.meetingId);
  const workspaceId = useSelector((state) => state.meetingState.workspaceId);
  const isDemo = useSelector((state) => state.meetingState.isDemo);
  const isPreview = useSelector((state) => state.meetingState.isPreview);
  const meetingConfig = useSelector(
    (state) => state.meetingState.meetingConfig,
    shallowEqual
  );
  const hasFakeUsers = useSelector(
    (state) => state.meetingState.state?.hasFakeUsers
  );

  const running = useSelector((state) => state.meetingState.state?.running);
  const completed = useSelector((state) => state.meetingState.state?.completed);
  const streaming = useSelector((state) => state.meetingState.state?.streaming);

  const recording = useSelector(
    (state) => state.meetingState.recording,
    shallowEqual
  );

  const isLive = useSelector((state) => state.meetingState.state?.live);
  const wentLive = useSelector((state) => state.meetingState.state?.wentLive);
  const wentLiveTimeStamp = useSelector(
    (state) => state.meetingState.state?.wentLiveTimeStamp
  );

  const users = useSelector((state) => {
    const users = state.meetingState.state?.users;
    const fakeUsers = Object.keys(users || []).filter((userId) =>
      userId.includes('BOT')
    );

    if (!fakeUsers?.length) {
      return users;
    }

    for (const fakeUserIndex in fakeUsers) {
      const fakeUserId = fakeUsers[fakeUserIndex];
      for (const userId in users) {
        const fakeUserMatchesUserId = `BOT_${userId}` === fakeUserId;

        if (fakeUserMatchesUserId) {
          delete users[fakeUserId];
        }
      }
    }

    return users;
  }, isEqual);

  const invisibleUsers = useSelector(
    (state) => state.meetingState.state?.invisibleUsers,
    isEqual
  );

  return {
    completed,
    running,
    isDemo,
    hasFakeUsers,
    streaming,
    recording,
    meetingConfig,
    meetingId,
    workspaceId,
    invisibleUsers,
    users,
    isPreview,
    isLive,
    wentLive,
    wentLiveTimeStamp,
  };
};
