export const CALL_STATE_UNLOADED = 'unloaded';
export const CALL_STATE_INITIALIZING = 'initializing';
export const CALL_STATE_READY = 'ready';
export const CALL_STATE_LOBBY = 'lobby';
export const CALL_STATE_JOINING = 'joining';
export const CALL_STATE_JOINED = 'joined';
export const CALL_STATE_ENDED = 'ended';
export const CALL_STATE_ERROR = 'error';
export const CALL_STATE_FULL = 'full';
export const CALL_STATE_EXPIRED = 'expired';
export const CALL_STATE_NOT_BEFORE = 'nbf';
export const CALL_STATE_REMOVED = 'removed-from-call';
export const CALL_STATE_REDIRECTING = 'redirecting';
export const CALL_STATE_NOT_FOUND = 'not-found';
export const CALL_STATE_NOT_ALLOWED = 'not-allowed';
export const CALL_STATE_AWAITING_ARGS = 'awaiting-args';
// Allow 'blocked' track states so that a user can still join a meeting without a camera / mic.
export const ALLOWED_TRACK_STATES_TO_JOIN_MEETING = [
  'playable',
  'off',
  'blocked',
];
// Background types
export const BACKGROUND_TYPE_NONE = 'none';
export const BACKGROUND_TYPE_BLUR = 'background-blur';
export const BACKGROUND_TYPE_IMAGE = 'background-image';
// Simulcast layers
export const simulcastLayers = {
  HIGH: 2,
  MED: 1,
  LOW: 0,
};
