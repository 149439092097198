import React from 'react';

export const CloudUploadIcon = ({
  width = '17px',
  height = '16px',
  className = 'fill-white',
  rectWidth = '16px',
  rectHeight = '16px',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 17 16"
    fill="none"
    className={className}
  >
    <g clipPath="url(#clip0_20722_33219)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.0439 4.38408C4.64672 2.89794 6.15664 1.49219 8.40918 1.49219C10.8327 1.49219 12.8982 3.14112 13.351 5.80952C13.9905 5.90524 14.66 6.15013 15.2112 6.55964C15.8964 7.0686 16.4092 7.84414 16.4092 8.87969C16.4092 9.88374 15.9865 10.6825 15.2982 11.218C14.6236 11.7428 13.7258 11.9922 12.7842 11.9922H10.4092C10.133 11.9922 9.90918 11.7683 9.90918 11.4922C9.90918 11.216 10.133 10.9922 10.4092 10.9922H12.7842C13.5613 10.9922 14.226 10.7852 14.6842 10.4287C15.1288 10.0828 15.4092 9.57533 15.4092 8.87969C15.4092 8.21554 15.0938 7.71811 14.6149 7.36239C14.1237 6.99752 13.4727 6.79571 12.8844 6.76657C12.6381 6.75437 12.4375 6.56442 12.4119 6.31916C12.16 3.90888 10.4276 2.49219 8.40918 2.49219C6.50319 2.49219 5.28458 3.74956 4.8853 4.99485C4.82424 5.18529 4.65557 5.32103 4.45647 5.33995C2.73348 5.50363 1.40918 6.56436 1.40918 8.16719C1.40918 9.77853 2.80153 10.9922 4.65918 10.9922H6.40918C6.68532 10.9922 6.90918 11.216 6.90918 11.4922C6.90918 11.7683 6.68532 11.9922 6.40918 11.9922H4.65918C2.39183 11.9922 0.40918 10.4633 0.40918 8.16719C0.40918 5.98344 2.13809 4.6788 4.0439 4.38408Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.05563 5.63863C8.25089 5.44337 8.56747 5.44337 8.76273 5.63863L10.7627 7.63863C10.958 7.8339 10.958 8.15048 10.7627 8.34574C10.5675 8.541 10.2509 8.541 10.0556 8.34574L8.40918 6.69929L6.76273 8.34574C6.56747 8.541 6.25089 8.541 6.05563 8.34574C5.86036 8.15048 5.86036 7.8339 6.05563 7.63863L8.05563 5.63863Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.40918 5.99219C8.68532 5.99219 8.90918 6.21605 8.90918 6.49219V14.0053C8.90918 14.2815 8.68532 14.5053 8.40918 14.5053C8.13304 14.5053 7.90918 14.2815 7.90918 14.0053V6.49219C7.90918 6.21605 8.13304 5.99219 8.40918 5.99219Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_20722_33219">
        <rect
          width={rectWidth}
          height={rectHeight}
          transform="translate(0.40918)"
        />
      </clipPath>
    </defs>
  </svg>
);
