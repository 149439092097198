class MeetingConfigStack {
  /* array of all snapshots saved in this object */
  snapshots;

  /* this is an index pointing at current snapshot. Used for redo and undo operations */
  pointer;

  /* determines the limit of how many snapshots can be saved at maximum */
  stackLengthLimit;

  constructor(stackLengthLimit = 10) {
    this.pointer = 0;
    this.snapshots = [];
    this.stackLengthLimit = stackLengthLimit;
  }

  getSnapshots() {
    return this.snapshots;
  }

  addSnapshot(snapshot) {
    if (this.pointer < this.snapshots.length - 1) {
      this.snapshots = this.snapshots.slice(0, this.pointer + 1);
    }

    this.snapshots.push(snapshot);

    if (this.snapshots.length > this.stackLengthLimit) {
      this.snapshots = this.snapshots.slice(
        this.snapshots.length - this.stackLengthLimit
      );
    }

    this.pointer = this.snapshots.length - 1;
  }

  getPointer() {
    return this.pointer;
  }

  decrementPointer() {
    this.pointer = this.pointer - 1;
  }

  incrementPointer() {
    this.pointer = this.pointer + 1;
  }

  getSnapshot() {
    return this.snapshots[this.pointer];
  }
}

/* This is a utility for saving, storing and returning snapshots of changes */
class MeetingConfigStackManager {
  /* a map of possible commands */
  static COMMANDS = {
    UNDO: 'undo',
    REDO: 'redo',
  };

  /* current stack used by MeetingConfigStackManager */
  stack;

  constructor() {
    this.stack = new MeetingConfigStack();
  }

  getStack() {
    return this.stack;
  }

  canUndo() {
    const stack = this.getStack();

    return stack.snapshots.length > 0 && stack.pointer > 0;
  }

  undo() {
    const stack = this.getStack();

    if (!this.canUndo()) {
      return this;
    }

    stack.decrementPointer();

    return this;
  }

  /* checks whether we can redo */
  canRedo() {
    const stack = this.getStack();

    return stack.pointer < stack.snapshots.length - 1;
  }

  /* perform redo operation */
  redo() {
    const stack = this.getStack();

    if (!this.canRedo()) {
      return this;
    }

    stack.incrementPointer();

    return this;
  }

  /* executed commands are saved as snapshots to the stack */
  execute(snapshot) {
    const stack = this.getStack();

    stack.addSnapshot(snapshot);

    return this;
  }

  /* returns current snapshot based on pointer */
  restore() {
    const stack = this.getStack();

    return stack.getSnapshot();
  }
}

export default MeetingConfigStackManager;
