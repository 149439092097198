import React from 'react';

import { Button } from '../../components/common/Button';
import { PencilIcon } from '../../components/icons/PencilIcon';
import { CopyIcon } from '../../components/icons/CopyIcon';
import { UrlLink } from '../../components/UrlLink';
import classNames from '../../helper/classNames';

export const MeetingBox = ({
  children,
  title,
  description,
  buttonText,
  onClick,
  isEditableIcon,
  url,
  urlText,
  showUrl = false,
  showHoverButton = false,
}) => (
  <section className="relative w-full flex rounded-lg flex-col bg-white border border-solid border-gray">
    <div className=" bg-purple min-w-fit rounded-t-lg flex flex-col md:flex-row pl-5 pr-16 min-h-[5.5rem] py-5 bg-opacity-[5%] space-y-3 md:space-y-0 md:items-center md:justify-between md:pr-5">
      <div className="flex flex-col md:flex-row md:items-center space-y-3 md:space-y-0 md:space-x-4">
        <div className="text-xl font-bold">{title}</div>
        <div className="text-sm text-blue-gray">{description}</div>
      </div>
      {!showUrl && buttonText && onClick ? (
        <div className="max-w-fit">
          <div className="md:ml-5 cursor-pointer">
            <Button color={Button.colors.PURPLE} onClick={onClick}>
              <div className="px-3 flex items-center space-x-3">
                {isEditableIcon ? <PencilIcon className="fill-white" /> : null}
                <div className="text-sm font-medium md:whitespace-nowrap">
                  {buttonText}
                </div>
              </div>
            </Button>
          </div>
        </div>
      ) : (
        <></>
      )}
      {showUrl && url && urlText ? (
        <div className="md:ml-5">
          <UrlWithCopyButton url={url} text={urlText} />
        </div>
      ) : (
        <></>
      )}
    </div>
    <div
      className={classNames(
        `min-w-fit rounded-b-lg ${children ? 'md:p-5' : ''} group`,
        children?.props?.className?.includes('hidden')
          ? 'p-0'
          : `${children ? 'p-5' : ''}`,
        onClick && 'cursor-pointer'
      )}
      onClick={onClick}
    >
      <div className="relative">
        <div className="hidden md:block">
          {!showUrl && buttonText && onClick && showHoverButton && (
            <div className="cursor-pointer opacity-0 absolute md:group-hover:opacity-100 left-1/2 top-3/4 -translate-x-1/2 md:group-hover:top-1/2 md:group-hover:-translate-y-1/2 md:group-hover:-translate-x-1/2 duration-200 z-10">
              <Button color={Button.colors.PURPLE}>
                <div className="px-3 flex items-center space-x-3">
                  {isEditableIcon ? (
                    <PencilIcon className="fill-white" />
                  ) : null}
                  <span className="text-sm font-medium">{buttonText}</span>
                </div>
              </Button>
            </div>
          )}
        </div>
        <div
          className={classNames(
            !showUrl &&
              buttonText &&
              onClick &&
              showHoverButton &&
              'md:group-hover:opacity-20 md:group-hover:duration-300'
          )}
        >
          {children}
        </div>
      </div>
    </div>
  </section>
);

const UrlWithCopyButton = ({ text, color = '#8B33F7', url }) => (
  <div className="flex space-x-2 items-center">
    <div className="font-bold text-lg opacity-90 min-w-fit">{text}</div>
    <UrlLink
      url={url}
      textStyle={{
        color,
        fontSize: '0.9rem',
        fontWeight: 'bold',
        minWidth: '100%',
        wordBreak: 'break-all',
      }}
    >
      <div className="ml-2">
        <CopyIcon width="18" height="18" />
      </div>
    </UrlLink>
  </div>
);
