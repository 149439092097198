import { ConfirmationModal, Modal } from './Modal';
import { Button } from './common/Button';
import React from 'react';

export const OtherDeviceErrorModal = ({ onDismiss }) => {
  return (
    <ConfirmationModal
      title="We experienced a problem with your microphone or camera"
      text={
        <div className="flex flex-col gap-5 -mt-4">
          <div className="flex flex-col gap-5 -mt-4">
            <span>
              We were unable to fetch your video and audio stream from your
              camera/mic. Please verify that your camera/mic are functioning
              properly. You can use this tool here at{' '}
              <a
                className="text-blue-light hover:underline"
                href="https://webcammictest.com/"
                target="_blank"
                rel="noreferrer"
              >
                Webcam test
              </a>{' '}
              to test your devices. You can try again by clicking "Reload" once
              you have addressed any issues with your devices.
            </span>
          </div>
        </div>
      }
      size={Modal.size.md}
      actionButtons={
        <div>
          <Button
            size={Button.sizes.FULL}
            color={Button.colors.PURPLE}
            onClick={onDismiss}
          >
            <span className="text-base font-bold">Dismiss</span>
          </Button>
        </div>
      }
      width={512}
      height={310}
      shrinkOnResize
    />
  );
};
