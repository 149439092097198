export const useWorkspaceFlags = (workspace = {}) => {
  const {
    featureFlags: {
      enablePublicContentKit = false,
      useSegments = true,
      useVectorStore = false,
      useDownloadLocalRecording = false,
      useImprovedVideoQuality = false,
      useMoreThemes = false,
    } = {},
  } = workspace || {};

  return {
    enablePublicContentKit,
    useSegments,
    useVectorStore,
    useDownloadLocalRecording,
    useImprovedVideoQuality,
    useMoreThemes,
  };
};
