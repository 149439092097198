import React from 'react';
import classNames from '../helper/classNames';
import { getStaticAssetUrl } from '../helper/getStaticAssetUrl';

const TopGradientOpacityVariants = {
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low',
};

const TopGradientOpacityClassNames = {
  [TopGradientOpacityVariants.HIGH]: 'from-purple/80',
  [TopGradientOpacityVariants.MEDIUM]: 'from-purple/50',
  [TopGradientOpacityVariants.LOW]: 'from-purple/20',
};

export const IsometricShapesDecoration = ({
  className = 'fixed -bottom-1/4 md:bottom-0 left-0 w-full h-2/4 object-cover',
}) => {
  return (
    <img
      className={className}
      src={getStaticAssetUrl('sign-on-decoration-lines.svg')}
      alt=""
    />
  );
};

export const FuturisticBackground = ({
  responsiveHideTopDecorations = false,
  topGradientOpacity = TopGradientOpacityVariants.HIGH,
  showDecorations = true,
  showGradient = true,
  showIsometricLinesDecoration = true,
}) => (
  <div className="isolate">
    {showGradient && (
      <div
        className={classNames(
          'absolute left-0 top-0 bg-gradient-to-b h-1/4 to-white w-full',
          TopGradientOpacityClassNames[topGradientOpacity]
        )}
      />
    )}
    {showDecorations && (
      <img
        className="fixed sm-landscape:absolute -bottom-[15%] md:bottom-0 left-0"
        src={getStaticAssetUrl('sign-on-decoration-green.svg')}
        alt=""
      />
    )}
    {showDecorations && (
      <img
        className={classNames(
          'fixed translate-x-[-85%] md:translate-x-0 left-0 top-1/3',
          responsiveHideTopDecorations && 'hidden lg:block'
        )}
        src={getStaticAssetUrl('sign-on-decoration-blue.svg')}
        alt=""
      />
    )}
    {showDecorations && (
      <img
        className="fixed sm-landscape:absolute translate-x-[65%] md:translate-x-0 translate-y-[55%] md:translate-y-0 md:translate-x-0 right-[5%] bottom-0"
        src={getStaticAssetUrl('sign-on-decoration-orange.svg')}
        alt=""
      />
    )}
    {showDecorations && (
      <img
        className={classNames(
          'fixed translate-x-[75%] md:translate-x-0 right-0 top-1/3 -z-10',
          responsiveHideTopDecorations && 'hidden lg:block'
        )}
        src={getStaticAssetUrl('sign-on-decoration-yellow.svg')}
        alt=""
      />
    )}
    {showIsometricLinesDecoration && <IsometricShapesDecoration />}
  </div>
);

FuturisticBackground.topGradientOpacity = TopGradientOpacityVariants;
