import { css } from 'styled-components';

export const containerPadding = css`
  padding-top: 1em;
`;

export const cssCenterColumn = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
