import React from 'react';
import { WelcomeSpeakerAvatarUploader } from './WelcomeSpeakerAvatarUploader';
import { debounce } from 'lodash/fp';
import { updateSpeakerApi } from '../../helper/api';

const updateSpeaker = debounce(500, async (speakerData) => {
  return updateSpeakerApi(speakerData.workspaceId, {
    _id: speakerData._id,
    presenterPictureUrl: speakerData.presenterPictureUrl,
    fullName: speakerData.fullName,
    jobTitle: speakerData.jobTitle,
  });
});

export const WelcomeSpeakerProfile = ({ speaker, setSpeaker }) => {
  return (
    <div className="flex w-full gap-6 items-center">
      <WelcomeSpeakerAvatarUploader
        avatarUrl={speaker.presenterPictureUrl}
        speakerId={speaker._id}
        handleAvatarChange={(avatarUrl) => {
          setSpeaker((prev) => {
            const newSpeaker = { ...prev, presenterPictureUrl: avatarUrl };
            updateSpeaker(newSpeaker);
            return newSpeaker;
          });
        }}
      />
      <div className="flex flex-col gap-6 w-full">
        <input
          type="text"
          value={speaker.fullName}
          onChange={(event) => {
            event.persist();
            setSpeaker((prev) => {
              const newSpeaker = { ...prev, fullName: event.target.value };
              updateSpeaker(newSpeaker);
              return newSpeaker;
            });
          }}
          className="w-full border border-[#EBEAED] px-3 py-2 md:text-base text-sm"
          maxLength={24}
        />
        <textarea
          rows={2}
          value={speaker.jobTitle}
          onChange={(event) => {
            event.persist();
            setSpeaker((prev) => {
              const newSpeaker = { ...prev, jobTitle: event.target.value };
              updateSpeaker(newSpeaker);
              return newSpeaker;
            });
          }}
          placeholder="Headline e.g. CEO Acme Inc"
          className="w-full border border-[#EBEAED] px-3 py-2 md:text-base text-sm"
          maxLength={50}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
            }
          }}
        />
      </div>
    </div>
  );
};
