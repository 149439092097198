import React, { useEffect, useRef } from 'react';
import Hls from 'hls.js';
import classNames from '../helper/classNames';
import { logwarn } from '../helper/contextualLogger';
import { notifyUser } from './authoring/hooks';

export default function HlsVideoPlayer({
  src,
  poster,
  className,
  onPlay,
  showControls = true,
  autoPlay = false,
  userId,
  meetingId,
}) {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    if (!video) return;

    video.controls = showControls;

    let hls;

    if (video.canPlayType('application/vnd.apple.mpegurl')) {
      // This will run in safari, where HLS is supported natively
      video.src = src;
    } else if (Hls.isSupported()) {
      if (src.includes('mp4')) {
        video.src = src;
        video.type = 'video/mp4';
      } else {
        hls = new Hls();
        hls.loadSource(src);
        hls.attachMedia(video);
      }
      // This will run in all other modern browsers
    } else {
      notifyUser(
        'We could not load your video because your browser does not support MSE. Please update or change your browser.'
      );
      logwarn({
        message: `This is an old browser that does not support MSE https://developer.mozilla.org/en-US/docs/Web/API/Media_Source_Extensions_API pageErrored: ${window.location.pathname} ${navigator.userAgent}`,
        meetingId,
        userId,
      });
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [src, showControls, videoRef, onPlay, meetingId, userId]);

  return (
    <>
      <video
        ref={videoRef}
        poster={poster}
        className={classNames('w-[800px] cursor-pointer max-w-full', className)}
        playsInline
        autoPlay={autoPlay}
      />
    </>
  );
}
