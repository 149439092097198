const initialUserActivityState = {
  lastUserActivity: {},
  activeUser: null,
};

/**
 * USER_ACTIVITY action structure:
 *  - type: string
 *  - users: Array<User>: All users within the meeting.
 *  - userId: string: User who was just the active speaker.
 *  - actvitityTime: Date: The time at which the user was active.
 */
const USER_ACTIVITY = 'USER_ACTIVITY';

const FOREVER_AGO = new Date(0);

const userActivity = (state = initialUserActivityState, action) => {
  switch (action.type) {
    case USER_ACTIVITY:
      const { users, userId, activityTime = new Date() } = action;

      const newLastUserActivity = {};
      for (const u of users) {
        newLastUserActivity[u] = state.lastUserActivity[u] || FOREVER_AGO;
        if (u === userId) {
          newLastUserActivity[userId] = activityTime;
        }
      }
      return {
        ...state,
        lastUserActivity: newLastUserActivity,
        activeUser: { userId, activityTime },
      };
    default:
      return state;
  }
};

// Returns the userId from userIds who was active the longest ago.
function getUserWithLeastActivity(userIds, lastUserActivity) {
  userIds.sort(
    (a, b) =>
      lastUserActivity[a] || FOREVER_AGO - lastUserActivity[b] || FOREVER_AGO
  );
  return userIds[0];
}

export { USER_ACTIVITY, userActivity, getUserWithLeastActivity };
