import React, { useCallback } from 'react';
import { Editor, RichUtils } from 'draft-js';

import { inlineStyleMap } from '../helpers';

/* Here we can define styles for custom blocks. Each case returns css className that will be applied to each block */
const getBlockStyle = (block) => {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    default:
      return null;
  }
};

const RichTextEditor = ({ editorState, onChange, onBlur, ...props }) => {
  const handleKeyCommand = useCallback(
    (command) => {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        onChange(newState);
        return true;
      }
      return false;
    },
    [editorState, onChange]
  );

  return (
    <Editor
      {...props}
      blockStyleFn={getBlockStyle}
      customStyleMap={inlineStyleMap}
      editorState={editorState}
      handleKeyCommand={handleKeyCommand}
      onChange={onChange}
      spellCheck={true}
      onBlur={onBlur}
    />
  );
};

export default React.memo(RichTextEditor);
