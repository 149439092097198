/** A list of font weights supported by default in authoring (text box block). */
export const defaultFontWeights = [100, 300, 400, 500, 700];

/**
 * A list of google fonts with weights. If weights is not specified, it will use a list of common
 * weights: 100, 300, 400, 500, and 700.
 */
export const googleFonts = [
  'Alfa',
  'Balsamiq Sans',
  'Bungee Spice',
  'Cairo',
  'Caveat',
  'Comfortaa',
  'Corinthia',
  'Cormorant Garamond',
  'Dela Gothic One',
  'Exo',
  'Fuzzy Bubbles',
  'Kumar One',
  'Libre Baskerville',
  'Luxurious Script',
  'Meow Script',
  'Merriweather',
  'Montserrat Alternates',
  'Open Sans',
  'Patua One',
  'Permanent Marker',
  'Playfair Display',
  'Poppins',
  'Raleway',
  'Righteous',
  'Roboto',
  'Roboto Mono',
  'Roboto Slab',
  'Rubik Moonrocks',
  'Sigmar',
  'Silkscreen',
  'Source Sans Pro',
  'Staatliches',
  'Ubuntu',
  'Vollkorn',
];

export const googleFontList = googleFonts.map((font) => ({
  font,
  weights: defaultFontWeights,
}));

/**
 * Generates a url to a CSS file to import the given fonts. Use in a CSS import or link tag. If no
 * parameters are present, generates a url with all default google fonts. @see googleFontList
 */
export function generateFontUrl(fonts = googleFontList) {
  const fontFamilySegment = fonts
    .flatMap(({ font, weights }) => [
      `family=${encodeURIComponent(font)}`, // google fonts returns the default font weight.
      `family=${encodeURIComponent(font)}:wght@${weights.join(';')}`, // request all given weights
    ])
    .join('&');
  const url = `https://fonts.googleapis.com/css2?${fontFamilySegment}&display=swap`;
  return url;
}
