import React from 'react';

export const ReelIcon = ({
  width = '20px',
  height = '20px',
  className = 'fill-purple/5 stroke-purple',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 37 37"
    fill="none"
    className={className}
  >
    <path
      d="M18.0653 13.9236C19.0258 13.9236 19.8044 13.1449 19.8044 12.1844C19.8044 11.2239 19.0258 10.4453 18.0653 10.4453C17.1048 10.4453 16.3262 11.2239 16.3262 12.1844C16.3262 13.1449 17.1048 13.9236 18.0653 13.9236Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.0653 24.3572C19.0258 24.3572 19.8044 23.5785 19.8044 22.618C19.8044 21.6575 19.0258 20.8789 18.0653 20.8789C17.1048 20.8789 16.3262 21.6575 16.3262 22.618C16.3262 23.5785 17.1048 24.3572 18.0653 24.3572Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.2831 19.1384C24.2436 19.1384 25.0222 18.3598 25.0222 17.3993C25.0222 16.4388 24.2436 15.6602 23.2831 15.6602C22.3226 15.6602 21.5439 16.4388 21.5439 17.3993C21.5439 18.3598 22.3226 19.1384 23.2831 19.1384Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.8475 19.1384C13.808 19.1384 14.5867 18.3598 14.5867 17.3993C14.5867 16.4388 13.808 15.6602 12.8475 15.6602C11.887 15.6602 11.1084 16.4388 11.1084 17.3993C11.1084 18.3598 11.887 19.1384 12.8475 19.1384Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.0652 26.9664C23.3479 26.9664 27.6304 22.6839 27.6304 17.4012C27.6304 12.1184 23.3479 7.83594 18.0652 7.83594C12.7825 7.83594 8.5 12.1184 8.5 17.4012C8.5 22.6839 12.7825 26.9664 18.0652 26.9664Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.0649 18.2704C18.5451 18.2704 18.9344 17.8811 18.9344 17.4008C18.9344 16.9206 18.5451 16.5312 18.0649 16.5312C17.5846 16.5312 17.1953 16.9206 17.1953 17.4008C17.1953 17.8811 17.5846 18.2704 18.0649 18.2704Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.0654 26.9648H28.5002"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
