import moment from 'moment';

export const isSubscriptionActive = (subscription) => {
  if (!subscription) {
    return false;
  }

  const isCancelled = !!subscription.canceled_at;

  return !isCancelled;
};

export const checkIsSubscriptionCanceled = (subscription) => {
  if (!subscription) {
    return false;
  }

  return !!subscription.canceled_at;
};

export const getDefaultPaymentExpirationTimeInMonths = (subscription) => {
  const card = subscription?.default_payment_method?.card;

  if (!card) {
    return null;
  }

  const { exp_month, exp_year } = card;

  const expirationDate = moment(`${exp_month}/${exp_year}`, 'M/YYYY').endOf(
    'month'
  );
  const currentMonthLastDay = moment().endOf('month');

  return currentMonthLastDay.diff(expirationDate, 'months');
};
