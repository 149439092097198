import React from 'react';
import { ModalBody, ModalTitle, ModalWindow } from './Modal';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from './common/Button';
import { updateUserConsentsApi } from '../helper/api';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { refreshUser } from '../auth';

export const PrivacyPolicyConsentModal = ({ setShowPrivacyPolicyModal }) => {
  const { user } = useSelector((_st) => _st.auth, shallowEqual);
  const { userId, consents } = user || {};
  const dispatch = useDispatch();

  return (
    <ModalWindow>
      <ModalTitle>
        <span className="flex w-full justify-center">
          AI Powered Content Enhancement
        </span>
      </ModalTitle>
      <ModalBody>
        <div className="flex flex-col gap-5 text-blue-gray">
          <span>
            At Zync AI, we employ advanced AI models to process recorded
            content, generating summaries, social media posts, and enriching
            materials to provide you with a comprehensive experience. By signing
            up, you acknowledge that your recordings may be used for this
            purpose.
          </span>
          <span>
            Rest assured, your data will be handled securely and confidentially.
            For more information, please refer to our{' '}
            <Link
              to="/privacy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-purple decoration-purple underline"
            >
              Privacy Policy
            </Link>
            .
          </span>
          <span>
            By clicking "<span className="font-bold">Agree</span>" below, you
            acknowledge and consent to this data processing for content
            generation aimed at enhancing your user experience.
          </span>
          <Button
            color={Button.colors.PURPLE}
            size={Button.sizes.FULL}
            onClick={async () => {
              await updateUserConsentsApi(userId, {
                ...consents,
                acceptedPrivacyPolicy: true,
              });
              await refreshUser(dispatch, userId);
              setShowPrivacyPolicyModal(false);
            }}
          >
            Agree
          </Button>
          <span>
            Questions? Contact us at our{' '}
            <span className="text-purple decoration-purple underline">
              support@zync.ai
            </span>{' '}
            and read our{' '}
            <Link
              to="/privacy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-purple decoration-purple underline"
            >
              Privacy Policy
            </Link>
          </span>
        </div>
      </ModalBody>
    </ModalWindow>
  );
};
