import React from 'react';

export const YoutubeIcon = ({ width = '16px', height = '17px' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_12190_59707)">
      <path
        d="M16 8.75C16 4.33172 12.4183 0.75 8 0.75C3.58172 0.75 0 4.33172 0 8.75C0 13.1683 3.58172 16.75 8 16.75C12.4183 16.75 16 13.1683 16 8.75Z"
        fill="#FF0000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9126 5.46417C12.341 5.57947 12.6787 5.9172 12.794 6.34553C13.0082 7.12807 13 8.75903 13 8.75903C13 8.75903 13 10.3818 12.794 11.1643C12.6787 11.5926 12.341 11.9304 11.9126 12.0457C11.1301 12.2516 7.99997 12.2516 7.99997 12.2516C7.99997 12.2516 4.87807 12.2516 4.0873 12.0374C3.65897 11.9221 3.32125 11.5844 3.20593 11.1561C3 10.3818 3 8.7508 3 8.7508C3 8.7508 3 7.12807 3.20593 6.34553C3.32125 5.9172 3.6672 5.57123 4.0873 5.4559C4.86983 5.25 7.99997 5.25 7.99997 5.25C7.99997 5.25 11.1301 5.25 11.9126 5.46417ZM9.60623 8.7508L7.00327 10.25V7.25163L9.60623 8.7508Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_12190_59707">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.75)"
        />
      </clipPath>
    </defs>
  </svg>
);
