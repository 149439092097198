import React, {
  useCallback,
  useState,
  createElement,
  useEffect,
  useRef,
} from 'react';
import { Provider, shallowEqual, useSelector } from 'react-redux';
import { uploadVideoToMux } from '../helper/localRecordingStream';
import { notifyUser } from '../components/authoring/hooks';
import { sendEvent } from '../helper/api';
import ReactDOM from 'react-dom';
import { Button } from '../components/common/Button';
import { getStaticAssetUrl } from '../helper/getStaticAssetUrl';
import { WarningTriangleIcon } from '../components/icons/WarningTriangleIcon';
import { CheckMarkIcon } from '../components/icons/CheckMarkIcon';
import { createStore } from 'redux';
import rootReducer from '../reducers';
import { ModalBody, ModalTitle, ModalWindow } from '../components/Modal';
import { MAIN_SLIDE_CONTAINER } from '../components/Slide';
import { isSafari } from '../helper';
import {
  fromError,
  logerror,
  loginfo,
  logwarn,
} from '../helper/contextualLogger';

const config = {
  audioBitsPerSecond: 48_000, // Sample rate 48khz is considered a standard for high quality productions,
  videoBitsPerSecond: 12_000_000, // ideal kbps per second for 4k falls between 35,000,000 to 45,000,000
  timeSlice: 3_000, // media stream chunks are saved using this interval
};

const LOCAL_RECORDING_PROGRESS_ID = 'local-recording-progress';
const LOCAL_RECORDING_DOWNLOAD_ID = 'local-recording-download';
const modalManager = (containerHtmlId, classNames = 'absolute') => ({
  openModal(modalClass, props) {
    // Create container for the modal to be rendered into
    const renderContainer = document.createElement('div');
    renderContainer.classList.add(classNames);
    renderContainer.id = containerHtmlId;
    renderContainer.style.zIndex = 9999;
    document.body.prepend(renderContainer);

    // Create & render component
    const modalInst = createElement(modalClass, { ...props, renderContainer });

    ReactDOM.render(modalInst, renderContainer);

    return () => this.unmountModal(renderContainer);
  },
  unmountModal(callback) {
    const renderContainer = document.getElementById(containerHtmlId);
    ReactDOM.unmountComponentAtNode(renderContainer);
    renderContainer.parentNode.removeChild(renderContainer);

    if (callback) {
      callback();
    }
  },
});

const PROGRESS_BAR_COUNTER_HTML_ID = 'progress-bar-counter';
const PROGRESS_BAR_INDICATOR_HTML_ID = 'progress-bar-indicator';
const PROGRESS_BAR_CONFIRM_BUTTON_HTML_ID = 'progress-bar-confirm-button';
const PROGRESS_BAR_IMAGE_HTML_ID = 'progress-bar-image';
const PROGRESS_BAR_TEXT_HTML_ID = 'progress-bar-text';
const PROGRESS_BAR_DO_NOT_CLOSE_TIP_HTML_ID = 'progress-bar-do-not-close-tip';
const PROGRESS_BAR_YOU_CAN_CLOSE_TIP_HTML_ID = 'progress-bar-you-can-close-tip';
const PROGRESS_BAR_FILE_SAVED_LOCALLY = 'progress-bar-file-saved-locally';
const updateCounter = (value) => {
  const counter = document.getElementById(PROGRESS_BAR_COUNTER_HTML_ID);
  const indicator = document.getElementById(PROGRESS_BAR_INDICATOR_HTML_ID);

  const stringifiedValue = `${value}%`;

  if (counter) {
    counter.textContent = stringifiedValue;
  }

  if (indicator) {
    indicator.style.width = stringifiedValue;
  }
};

const completeUpload = () => {
  const logo = document.getElementById(PROGRESS_BAR_IMAGE_HTML_ID);
  const confirmButton = document.getElementById(
    PROGRESS_BAR_CONFIRM_BUTTON_HTML_ID
  );
  const text = document.getElementById(PROGRESS_BAR_TEXT_HTML_ID);

  const doNotCloseTip = document.getElementById(
    PROGRESS_BAR_DO_NOT_CLOSE_TIP_HTML_ID
  );
  const youCanCloseTip = document.getElementById(
    PROGRESS_BAR_YOU_CAN_CLOSE_TIP_HTML_ID
  );

  if (confirmButton) {
    confirmButton.classList.remove('pointer-events-none');
    confirmButton.classList.remove('opacity-0');
  }

  if (text) {
    text.textContent = 'Upload Complete';
  }

  if (logo) {
    logo.classList.add('invisible');
  }

  if (doNotCloseTip) {
    doNotCloseTip.classList.remove('flex');
    doNotCloseTip.classList.add('hidden');
  }

  if (youCanCloseTip) {
    youCanCloseTip.classList.remove('hidden');
    youCanCloseTip.classList.add('flex');
  }
};

window.updateCounter = updateCounter;
window.completeUpload = completeUpload;

const UploadProgressBar = ({ handleClose }) => {
  return (
    <div className="fixed w-full bg-white z-[9999] top-0 px-6 py-2">
      <div className="flex gap-4 items-center h-[48px]">
        <div className="flex gap-1 items-center shrink-0">
          <img
            id={PROGRESS_BAR_IMAGE_HTML_ID}
            className="h-[48px]"
            src={getStaticAssetUrl('uploading.gif')}
            alt=""
          />
          <p
            id={PROGRESS_BAR_TEXT_HTML_ID}
            className="text-blue-dark font-bold text-sm text-center whitespace-nowrap w-[152px]"
          >
            Uploading Recording
          </p>
        </div>
        <div className="w-full h-[10px] relative top-0">
          <div className="w-full bg-black/5 absolute top-0 left-0 h-full rounded-full" />
          <div
            id={PROGRESS_BAR_INDICATOR_HTML_ID}
            className="bg-cyan absolute top-0 left-0 transition-all duration-300 h-full rounded-full"
            style={{
              width: '0%',
            }}
          />
        </div>
        <div id={PROGRESS_BAR_COUNTER_HTML_ID} className="w-[50px]">
          0%
        </div>
        <div
          id={PROGRESS_BAR_CONFIRM_BUTTON_HTML_ID}
          className="flex justify-end opacity-0 pointer-events-none w-[133px]"
        >
          <Button
            onClick={handleClose}
            color={Button.colors.PURPLE}
            padding={Button.padding.SMALL}
            size={Button.sizes.FULL}
          >
            <span className="text-sm font-medium">OK</span>
          </Button>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <p
          id={PROGRESS_BAR_DO_NOT_CLOSE_TIP_HTML_ID}
          className="flex gap-1 items-center text-sm font-medium text-blue-gray"
        >
          <WarningTriangleIcon /> Video Upload in Progress. Do not close this
          window.
        </p>
        <p
          id={PROGRESS_BAR_YOU_CAN_CLOSE_TIP_HTML_ID}
          className="hidden gap-1 items-center text-sm font-medium text-blue-gray "
        >
          <img src={getStaticAssetUrl('info-sign.svg')} alt="" />
          You are now free to close the window.
        </p>
        <p
          id={PROGRESS_BAR_FILE_SAVED_LOCALLY}
          className="flex gap-2 items-center invisible"
        >
          <span className="bg-cyan rounded-full w-[16px] h-[16px] grid place-content-center">
            <CheckMarkIcon className="fill-white" />
          </span>
          <span className="text-sm text-blue-gray font-medium">
            File saved locally
          </span>
        </p>
      </div>
    </div>
  );
};

const ConfirmationModal = ({ blob }) => {
  const [isLastWarning, setIsLastWarning] = useState(false);

  const store = createStore(rootReducer);
  const handleClose = () =>
    modalManager(LOCAL_RECORDING_DOWNLOAD_ID).unmountModal();

  const handleDownloadVideoFileLocally = () => {
    const url = URL.createObjectURL(blob);

    // Create a link element and programmatically click it to download the file
    const link = document.createElement('a');
    link.href = url;
    link.download = 'recorded-video.webm';
    link.click();

    const successText = document.getElementById(
      PROGRESS_BAR_FILE_SAVED_LOCALLY
    );

    if (successText) {
      successText.classList.remove('invisible');
    }

    handleClose();
  };

  return (
    <Provider store={store}>
      <ModalWindow size="md" onCancel={handleClose} showCancel={false}>
        <ModalTitle>
          <div className="flex gap-2 items-center mb-10">
            <WarningTriangleIcon /> Attention
          </div>
        </ModalTitle>
        <ModalBody>
          <p className="text-blue-gray text-sm -mt-6 mb-10">
            {isLastWarning
              ? 'You will lose a high definition recording of the episode. Are you sure you want to continue?'
              : 'We will record the file locally so you can upload it later. This is useful if the current uploading fails'}
          </p>
          <div className="flex gap-2">
            {!isLastWarning && (
              <Button
                onClick={() => setIsLastWarning(true)}
                color={Button.colors.WHITE}
                padding={Button.padding.MEDIUM}
                size={Button.sizes.FULL}
              >
                <span className="text-sm font-bold text-blue-dark font-jakarta">
                  No
                </span>
              </Button>
            )}
            {!isLastWarning && (
              <Button
                onClick={handleDownloadVideoFileLocally}
                color={Button.colors.PURPLE}
                padding={Button.padding.MEDIUM}
                size={Button.sizes.FULL}
              >
                <span className="text-base font-medium font-jakarta">Ok</span>
              </Button>
            )}
            {isLastWarning && (
              <Button
                onClick={handleClose}
                color={Button.colors.RED}
                padding={Button.padding.MEDIUM}
                size={Button.sizes.FULL}
              >
                <span className="text-base font-medium">Lose Recording</span>
              </Button>
            )}
            {isLastWarning && (
              <Button
                onClick={handleDownloadVideoFileLocally}
                color={Button.colors.WHITE}
                padding={Button.padding.MEDIUM}
                size={Button.sizes.FULL}
              >
                <span className="text-sm font-bold font-jakarta">
                  Save File Locally
                </span>
              </Button>
            )}
          </div>
        </ModalBody>
      </ModalWindow>
    </Provider>
  );
};
const ProgressBarPopup = () => {
  const handleClose = () =>
    modalManager(LOCAL_RECORDING_PROGRESS_ID).unmountModal(() => {
      const mainStyleContainer = document.getElementById(MAIN_SLIDE_CONTAINER);

      if (mainStyleContainer) {
        mainStyleContainer.style.top = 0;
      }
    });

  return <UploadProgressBar handleClose={handleClose} />;
};

const openProgressUploadPopup = () => {
  const mainSlideContainer = document.getElementById(MAIN_SLIDE_CONTAINER);

  if (mainSlideContainer) {
    mainSlideContainer.style.top = '84px';
  }

  modalManager(LOCAL_RECORDING_PROGRESS_ID, 'h-[84px]').openModal(
    ProgressBarPopup
  );
};

const openLocalDownloadPopup = (blob) => {
  modalManager(LOCAL_RECORDING_DOWNLOAD_ID).openModal(ConfirmationModal, {
    blob,
  });
};

window.modall = () => openProgressUploadPopup();
window.modall2 = (blob) => openLocalDownloadPopup(blob);

export const useLocalRecording = (isSoloEpisode) => {
  const userId = useSelector((_st) => _st.auth.user?.userId);
  const localTrackState = useSelector((state) => state.callState.tracks.local);
  const tracks = useSelector(
    (state) => state.callState.tracks.allUsers,
    shallowEqual
  );
  const wentLive = useSelector((state) => state.meetingState.state?.wentLive);
  const meetingId = useSelector((state) => state.meetingState.meetingId);

  const activeMeetingId = useRef(null);

  const [isLocalRecording, setIsLocalRecording] = useState(false);
  const [wasLocalRecording, setWasLocalRecording] = useState(false);
  const [isCompleting, setIsCompleting] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [audioDestination, setAudioDestination] = useState(null);
  const [localAudioContext, setLocalAudioContext] = useState(null);
  const [originalAudioSource, setOriginalAudioSource] = useState(null);

  const recordingVideoRef = useRef(undefined);

  const sendStartRecordingAction = useCallback(
    (timestamp) => {
      const startRecordingAction = {
        type: 'START_LOCAL_RECORDING',
        data: { timestamp },
      };

      return sendEvent(userId, meetingId, startRecordingAction);
    },
    [meetingId, userId]
  );

  const sendStopRecordingAction = useCallback(() => {
    const stopRecordingAction = {
      type: 'STOP_LOCAL_RECORDING',
    };

    return sendEvent(userId, meetingId, stopRecordingAction);
  }, [meetingId, userId]);

  const localVideoTrack = localTrackState?.videoTrackState?.track;
  const localAudioTrack = localTrackState?.audioTrackState?.track;

  useEffect(() => {
    if (wasLocalRecording && localVideoTrack && recordingVideoRef.current) {
      try {
        recordingVideoRef.current.srcObject = new MediaStream([
          localVideoTrack,
        ]);
        recordingVideoRef.current.play();
      } catch (error) {}
    }
  }, [wasLocalRecording, localVideoTrack]);

  const userDevicesPrecheck = useCallback(() => {
    if (!localVideoTrack || !localAudioTrack) {
      return false;
    }

    try {
      new MediaStream([localVideoTrack]);
      new MediaStream([localAudioTrack]);
    } catch (error) {
      return false;
    }

    for (const userId in tracks) {
      try {
        const audioTrack = tracks[userId].audioTrackState;
        const videoTrack = tracks[userId].videoTrackState;

        if (
          audioTrack.state !== 'playable' ||
          videoTrack.state !== 'playable'
        ) {
          return false;
        }

        new MediaStream([audioTrack.track]);
        new MediaStream([videoTrack.track]);
      } catch (e) {
        return false;
      }
    }

    return true;
  }, [localAudioTrack, localVideoTrack, tracks]);

  const startRecording = useCallback(async () => {
    // TODO: check if MediaRecorder is supported by client browser: https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder#browser_compatibility

    if (wasLocalRecording) {
      return;
    }

    activeMeetingId.current = meetingId;

    setWasLocalRecording(true);

    loginfo({ message: 'First local recording attempt', meetingId, userId });

    if (localTrackState) {
      // Create two video elements: one local video track and the other local screen share track
      // Also note that screen share needs to be started BEFORE starting recording in order for it to show up in the recording
      // Can be improved by listening to screen share state change and dynamically adding/removing tracks to MediaRecorder via addTrack() and removeTrack()

      const localVideoTrack = localTrackState.videoTrackState.track;
      //const localScreenVideoTrack = isSharingScreen
      //  ? localParticipant.tracks.screenVideo.track
      //  : null;

      const videoEl1 = document.createElement('video');
      try {
        videoEl1.srcObject = new MediaStream([localVideoTrack]);
      } catch (e) {
        notifyUser(
          'We could not start local recording - did you turn on your camera?',
          undefined,
          false
        );
        logwarn({
          message: `Local recording failed - user ${userId}'s camera was off`,
          meetingId,
          userId,
        });
        setWasLocalRecording(false);
        return 'error';
      }

      videoEl1.style.display = 'none';
      videoEl1.play();

      const videoEls = [videoEl1];

      recordingVideoRef.current = videoEl1;

      const localScreenVideoTrack = false;
      if (localScreenVideoTrack) {
        const videoEl2 = document.createElement('video');
        videoEl2.srcObject = new MediaStream([localScreenVideoTrack]);
        videoEl2.style.display = 'none';
        videoEl2.play();
        videoEls.push(videoEl2);
      }

      // Creating a canvas element for rendering the video tracks
      const canvas = document.getElementById('local-recording-container');
      const ctx = canvas.getContext('2d');
      ctx.canvas.hidden = true;

      // Get the video track settings for layout math
      const videoTrackSettings1 = localVideoTrack.getSettings();
      const videoTrackSettings2 = localScreenVideoTrack
        ? localScreenVideoTrack.getSettings()
        : null;

      // Set the canvas width to be the sum of the widths of the video tracks
      canvas.width =
        videoTrackSettings1.width +
        (videoTrackSettings2 ? videoTrackSettings2.width : 0);
      // Set the canvas height to be the maximum height of the video tracks
      canvas.height = Math.max(
        videoTrackSettings1.height,
        videoTrackSettings2 ? videoTrackSettings2.height : 0
      );

      // Draw each video onto a separate part of the canvas
      const drawVideos = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        const width1 = videoTrackSettings1.width;
        const height1 = videoTrackSettings1.height;
        ctx.drawImage(videoEls[0], 0, 0, width1, height1);

        if (videoTrackSettings2) {
          const width2 = videoTrackSettings2.width;
          const height2 = videoTrackSettings2.height;
          ctx.drawImage(videoEls[1], width1, 0, width2, height2);
        }

        requestAnimationFrame(drawVideos);
      };

      drawVideos();
      // Get local audio track also
      const localAudioTrack = localTrackState.audioTrackState.track;
      const audioContext = new AudioContext();
      const audioDestination = audioContext.createMediaStreamDestination();

      setAudioDestination(audioDestination);
      setLocalAudioContext(audioContext);

      let localAudioStream;
      try {
        localAudioStream = new MediaStream([localAudioTrack]);
      } catch (e) {
        notifyUser(
          'We could not start local recording - did you turn on your microphone?',
          undefined,
          false
        );
        logwarn({
          message: `Local recording failed - user ${userId}'s microphone was off`,
          meetingId,
          userId,
        });
        setWasLocalRecording(false);
        return 'error';
      }

      const audioSource =
        audioContext.createMediaStreamSource(localAudioStream);
      setOriginalAudioSource(audioSource);
      audioSource.connect(audioDestination);

      const stream = canvas.captureStream(30);
      stream.addTrack(audioDestination.stream.getAudioTracks()[0]);

      // Adjust as per needed. Note that VP9 support may not be available for all hardware and browsers, so VP8 might be a better default - so highly recommend placing checks to see if VP9 is supported
      const options = {
        audioBitsPerSecond: config.audioBitsPerSecond,
        videoBitsPerSecond: config.videoBitsPerSecond,
        mimeType: isSafari ? 'video/mp4' : 'video/webm;codecs="vp9,opus"',
      };

      const newMediaRecorder = new MediaRecorder(stream, options);
      setMediaRecorder(newMediaRecorder);
      newMediaRecorder.onstart = () => {
        const timestamp = Date.now();
        sendStartRecordingAction(timestamp);
      };

      // Start recording
      newMediaRecorder.start(config.timeSlice);

      loginfo({
        message:
          'Local recording after mic/camera initialization started successfully',
        meetingId,
        userId,
      });

      setIsLocalRecording(true);

      newMediaRecorder.onerror = (event) => {
        console.log('MediaRecorder error:', event.error.name);
        logerror({
          ...fromError(event.error),
          message: 'Local recording stopped due to error: ' + event.error.name,
          userId,
          meetingId,
        });
      };

      // Listen for dataavailable event to collect the recorded chunks
      newMediaRecorder.ondataavailable = (e) => {
        console.log('Data available:', e.data);
        setRecordedChunks((prev) => [...prev, e.data]);
      };
    } else {
      loginfo({
        message: 'Tried to local record but we could not find localVideoTrack',
        meetingId,
        userId,
      });
      setWasLocalRecording(false);
      return 'error';
    }
  }, [
    userId,
    meetingId,
    sendStartRecordingAction,
    localTrackState,
    wasLocalRecording,
  ]);

  const audioTrack = localTrackState.audioTrackState?.track;

  const resetLocalAudioTrack = useCallback(() => {
    if (localAudioContext && audioTrack && originalAudioSource) {
      const newAudioSource = localAudioContext.createMediaStreamSource(
        new MediaStream([localTrackState.audioTrackState.track])
      );

      newAudioSource.connect(audioDestination);

      originalAudioSource.disconnect();
      setOriginalAudioSource(newAudioSource);
    }
    // eslint-disable-next-line
  }, [audioTrack]);

  const stopRecording = useCallback(
    async ({ workspaceId, meetingSeriesId }) => {
      if (mediaRecorder && mediaRecorder.state === 'recording') {
        mediaRecorder.stop();

        setIsCompleting(true);
        setIsLocalRecording(false);

        const meetingId = activeMeetingId.current;

        loginfo({
          workspaceId,
          meetingSeriesId,
          meetingId,
          userId,
          message: `Stopped local recording normally`,
        });

        await sendStopRecordingAction();

        if (!wentLive) {
          activeMeetingId.current = null;
          setIsCompleting(false);
          setIsLocalRecording(false);
          setRecordedChunks([]);
          return;
        }

        // Create a Blob from the recorded chunks
        const blob = new Blob(recordedChunks, { type: 'video/webm' });

        loginfo({
          workspaceId,
          meetingSeriesId,
          meetingId,
          userId,
          message: `Local recording file created. Size: ${blob.size}`,
        });

        openProgressUploadPopup();
        openLocalDownloadPopup(blob);

        try {
          await uploadVideoToMux(
            blob,
            {
              userId,
              workspaceId,
              meetingSeriesId,
              meetingId,
              type: 'local-recording',
            },
            {
              onDone: () => {
                completeUpload();
              },
              onUploadProgress: function (progressEvent) {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );

                updateCounter(percentCompleted);

                if (percentCompleted === 100) {
                  completeUpload();
                }
              },
            }
          );
          setRecordedChunks([]);
        } catch (e) {
          logerror({
            ...fromError(e),
            message: `Tried to upload local recording but failed. ${e.message}`,
          });
        } finally {
          activeMeetingId.current = null;
          setIsCompleting(false);
        }
      }
    },
    [wentLive, sendStopRecordingAction, mediaRecorder, recordedChunks, userId]
  );

  if (!isSoloEpisode) {
    window.stopLocalRecording = stopRecording;
  }

  useEffect(() => {
    if (isSoloEpisode) return;
    resetLocalAudioTrack();
  }, [resetLocalAudioTrack, isSoloEpisode]);

  return {
    startRecording,
    stopRecording,
    isLocalRecording,
    isCompleting,
    userDevicesPrecheck,
  };
};
