import { MUTED_BY_MODERATOR } from '../helper/audio';
import { useState, useCallback, useEffect } from 'react';
import { useInvitePermissions } from '../components/MeetingControl/useInvitePermissions';
import { sendEvent } from '../helper/api';
import { v4 as uuidv4 } from 'uuid';
import { useProcessedActions } from '../helper/useProcessedActions';
import { useStartLoading } from './useStartLoading';

/**
 * @param Object Assigning user.
 * @param string The reason for muting.
 * @param string meetingId
 *
 * @returns A function that accepts an optional callback and mutes the user.
 */
export const useMuteMeetingUser = ({ user, reason, meetingId }) => {
  const { canMuteUser } = useInvitePermissions(user, user);
  const userId = user?.userId;
  const [mutedUser, setMutedUser] = useState(null);
  const [muteUserActionId, setMuteUserActionId] = useState(null);

  const { hasActionCompleted: hasMuteUserCompleted } =
    useProcessedActions(muteUserActionId);

  /**
   * Mutes a single user.
   */
  const muteUser = useCallback(
    async (targetUser) => {
      const actionId = uuidv4();
      setMuteUserActionId(actionId);
      sendEvent(userId, meetingId, {
        type: 'MUTE_USER',
        targetUserId: targetUser?.userId,
        reason: reason || MUTED_BY_MODERATOR,
        actionId,
      });
    },
    [meetingId, reason, userId]
  );

  const handleMuteMeetingUser = (targetUser, cb) => {
    onClickWithLoading(targetUser, cb);
  };

  /**
   * @param Object User to be muted.
   * @param Function An optional callback.
   */
  const handleMuteMeetingUserHelper = (targetUser, cb) => {
    if (canMuteUser) {
      cb?.();
      muteUser(targetUser);
      setMutedUser(targetUser);
    }
  };

  /**
   * Mute All Users Loading Logic
   */
  const {
    isLoading: isMuteUserLoading,
    onClickWithLoading,
    disableLoading,
  } = useStartLoading(
    handleMuteMeetingUserHelper,
    undefined,
    undefined,
    'Mute User'
  );

  useEffect(() => {
    if (hasMuteUserCompleted() && isMuteUserLoading) {
      disableLoading();
    }
  }, [
    hasMuteUserCompleted,
    muteUserActionId,
    disableLoading,
    isMuteUserLoading,
  ]);

  return {
    isMuteUserLoading,
    handleMuteMeetingUser,
    mutedUser,
  };
};
