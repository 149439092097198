import { useCallback, useState } from 'react';
import { debounce } from 'lodash/function';
import { updateSeriesApi } from '../../../helper/api';
import { fromError, logerror } from '../../../helper/contextualLogger';

export const useChangeJoinWithoutMedia = (series, localDispatch) => {
  const [isJoinWithoutMediaEnabled, setisJoinWithoutMediaEnabled] = useState(
    series.settings.allowJoinWithoutMedia
  );

  const meetingSeriesId = series?.meetingSeriesId;

  const onChangeJoinWithoutMedia = useCallback(
    async (newValue) => {
      try {
        const result = await updateSeriesApi(meetingSeriesId, {
          settings: { allowJoinWithoutMedia: newValue },
        });
        if (!result);
        setisJoinWithoutMediaEnabled(result.settings.allowJoinWithoutMedia);
        localDispatch({ type: 'REFRESH_SERIES', series: result });
      } catch (error) {
        logerror(fromError(error));
      }
    },
    [localDispatch, meetingSeriesId]
  );

  const handleChangeJoinWithoutMedia = debounce(
    onChangeJoinWithoutMedia,
    1_000
  );

  return {
    handleChangeJoinWithoutMedia,
    isJoinWithoutMediaEnabled,
  };
};
