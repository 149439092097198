import { EditorState, Modifier, RichUtils } from 'draft-js';
import { findStylesByPrefix } from '../helpers';

/* Applies inline style override - useful when free typing when new style (color, fontFamily etc.) needs to be changed */
export const applyInlineStyleOverride = (
  editorState,
  currentStyle,
  prefix,
  styleApplied
) => {
  const newStyle = currentStyle.filter((_value, key) => !key.includes(prefix));

  return EditorState.setInlineStyleOverride(
    editorState,
    currentStyle.has(styleApplied)
      ? newStyle.remove(styleApplied)
      : newStyle.add(styleApplied)
  );
};

/* Returns content state without styles applied. Use this when selecting colors or fontFamilies as we don't want to stack them*/
export const removeStylesFromContentState = (editorState, prefix) => {
  const stylesToRemove = findStylesByPrefix(editorState, prefix);
  const selectionState = editorState.getSelection();

  return stylesToRemove.reduce(
    (newContentState, style) =>
      Modifier.removeInlineStyle(newContentState, selectionState, style),
    editorState.getCurrentContent()
  );
};

/* Overrides current state with current content state and style that we want to apply */
export const selectInlineStyle = (editorState, contentState, styleApplied) => {
  return RichUtils.toggleInlineStyle(
    EditorState.push(editorState, contentState, 'change-inline-style'),
    styleApplied
  );
};
