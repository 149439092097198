import React, { useRef, useState } from 'react';
import { useClickOutside } from '../helper/useClickOutside';
import { ClockIcon } from './icons/ClockIcon';
import { DropdownSelect } from './common/Dropdown';

const timeOptions = [
  '12:00 AM',
  '12:15 AM',
  '12:30 AM',
  '12:45 AM',
  '1:00 AM',
  '1:15 AM',
  '1:30 AM',
  '1:45 AM',
  '2:00 AM',
  '2:15 AM',
  '2:30 AM',
  '2:45 AM',
  '3:00 AM',
  '3:15 AM',
  '3:30 AM',
  '3:45 AM',
  '4:00 AM',
  '4:15 AM',
  '4:30 AM',
  '4:45 AM',
  '5:00 AM',
  '5:15 AM',
  '5:30 AM',
  '5:45 AM',
  '6:00 AM',
  '6:15 AM',
  '6:30 AM',
  '6:45 AM',
  '7:00 AM',
  '7:15 AM',
  '7:30 AM',
  '7:45 AM',
  '8:00 AM',
  '8:15 AM',
  '8:30 AM',
  '8:45 AM',
  '9:00 AM',
  '9:15 AM',
  '9:30 AM',
  '9:45 AM',
  '10:00 AM',
  '10:15 AM',
  '10:30 AM',
  '10:45 AM',
  '11:00 AM',
  '11:15 AM',
  '11:30 AM',
  '11:45 AM',
  '12:00 PM',
  '12:15 PM',
  '12:30 PM',
  '12:45 PM',
  '1:00 PM',
  '1:15 PM',
  '1:30 PM',
  '1:45 PM',
  '2:00 PM',
  '2:15 PM',
  '2:30 PM',
  '2:45 PM',
  '3:00 PM',
  '3:15 PM',
  '3:30 PM',
  '3:45 PM',
  '4:00 PM',
  '4:15 PM',
  '4:30 PM',
  '4:45 PM',
  '5:00 PM',
  '5:15 PM',
  '5:30 PM',
  '5:45 PM',
  '6:00 PM',
  '6:15 PM',
  '6:30 PM',
  '6:45 PM',
  '7:00 PM',
  '7:15 PM',
  '7:30 PM',
  '7:45 PM',
  '8:00 PM',
  '8:15 PM',
  '8:30 PM',
  '8:45 PM',
  '9:00 PM',
  '9:15 PM',
  '9:30 PM',
  '9:45 PM',
  '10:00 PM',
  '10:15 PM',
  '10:30 PM',
  '10:45 PM',
  '11:00 PM',
  '11:15 PM',
  '11:30 PM',
  '11:45 PM',
].map((time) => ({
  key: time,
  title: time,
}));
export const TimePicker = ({ time, label = '', setTime, disabled }) => {
  const [timeSelectionOpen, setTimeSelectionOpen] = useState(false);
  const timeSelectionRef = useRef(null);

  const handleClickOutside = () => {
    setTimeSelectionOpen(false);
  };

  useClickOutside(timeSelectionRef, handleClickOutside);

  return (
    <div className="flex flex-col relative w-full">
      <div className="text-blue-dark text-sm font-medium">{label}</div>

      <div className="relative">
        <DropdownSelect
          options={timeOptions}
          selectedKey={time}
          onSelect={setTime}
          borderColor={DropdownSelect.borderColor.GRAY}
          borderSize={DropdownSelect.borderSize.ONE}
          backgroundColor={DropdownSelect.backgroundColor.WHITE}
          fontSize={DropdownSelect.fontSize.SMALL}
          fontColor={DropdownSelect.fontColor.BLUE_GRAY}
          disabled={disabled}
        />
        <div className="absolute top-1/2 -translate-y-1/2 right-2 ">
          <ClockIcon color={timeSelectionOpen ? '#8B33F7' : '#696F8C'} />
        </div>
      </div>
    </div>
  );
};
