import React from 'react';
import styled from 'styled-components';
import classNames from '../../helper/classNames';

/** Styled textarea DOM element. */
export const TextAreaElement = styled.textarea`
  font-weight: ${({ theme }) => theme.typography.fontWeight.light};
  font-family: ${({ theme }) => theme.typography.fontFamily.regular};
  font-size: ${({ theme }) => theme.typography.fontSize.small}px;
  line-height: ${({ theme }) => theme.typography.lineHeight.small}px;

  color: ${(props) => props.theme.palette.text.paragraph};
  border-radius: ${({ theme }) => theme.borderRadius.field}px;
  outline: none;
  border: 1px solid ${(props) => props.theme.palette.border.field} !important;
  &:hover {
    border-color: ${(props) => props.theme.palette.border.neutral};
  }
  &:active,
  &:focus {
    border-color: ${(props) => props.theme.palette.border.hover};
  }
  padding: 10px;
  display: block;
  width: 100%;
  height: 90px;
  box-sizing: border-box;
  border: none;
  resize: none;
`;

/** A styled text area element. */
export const TextArea = React.forwardRef(
  (
    {
      /** The current (controlled) value of the textarea. */
      value,
      /** Handles when the textarea content changes. Passes the content string as the only parameter. */
      onChange,
      /** Whether the textarea is editable. */
      disabled = false,
      /** The maximum length of the content of the textarea. */
      maxLength,
      onKeyPress,
      placeholder,
      hideScrollbar = false,
      className,
      onBlur,
      label,
      rows,
    },
    ref
  ) => (
    <>
      {label && (
        <div className="text-blue-dark font-medium text-sm mb-2">{label}</div>
      )}
      <textarea
        ref={ref}
        className={classNames(
          'font-normal text-sm border border-solid border-gray text-blue-gray rounded-md outline-none hover:border-purple active:border-purple focus:border-purple p-2 block w-full box-border resize-none duration-200',
          hideScrollbar ? 'overflow-hidden' : '',
          className?.includes('h-') ? '' : 'h-24',
          className
        )}
        rows={rows}
        value={value}
        disabled={disabled}
        onChange={(event) => !disabled && onChange(event.target.value)}
        maxLength={maxLength}
        placeholder={placeholder}
        onKeyPress={onKeyPress}
        onBlur={onBlur}
      />
    </>
  )
);
