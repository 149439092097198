import { useClickOutside } from '../../../../helper/useClickOutside';
import { TextArrayInput, TextInput } from '../../../../components/Input';
import React from 'react';

export const OpinionPollUpdate = ({
  posterSlideTemplate,
  setPosterSlideTemplate,
  setUpdate,
  onConfirm,
  onCancel,
  updateOnClose,
  hostNotesComponent,
}) => {
  const opinionPollBlock = posterSlideTemplate.blocks.find(
    (block) => block.blockId === 'opinionpoll'
  );

  const handleConfirm = (shouldOpenNewlyCreated) =>
    onConfirm(
      posterSlideTemplate,
      `Opinion Poll - ${opinionPollBlock.settings.pollQuestion}`,
      shouldOpenNewlyCreated
    );

  useClickOutside({ current: document.querySelector('.modal') }, () => {
    updateOnClose && handleConfirm(false);
    onCancel();
  });

  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <h4 className="text-sm font-medium text-blue-dark mb-4">
          What topic or question would you like to discuss?
        </h4>
        <TextInput
          value={opinionPollBlock.settings.pollQuestion}
          onBlurNoValidation={true}
          onChange={(_event, value) => {
            setPosterSlideTemplate((template) => {
              template.slideName = `Opinion Poll - ${opinionPollBlock.settings.pollQuestion}`;
              opinionPollBlock.settings.pollQuestion = value;
              return template;
            });

            setUpdate((x) => x + 1);
          }}
          onBlur={handleConfirm}
        />
        <div className="-ml-2 mb-4">
          <TextArrayInput
            defaultValue={opinionPollBlock.settings.pollChoices}
            onChange={(newPollChoices, i, action) => {
              if (action !== 'change') {
                return;
              }

              setPosterSlideTemplate((template) => {
                opinionPollBlock.settings.pollChoices = newPollChoices;
                return template;
              });

              setUpdate((x) => x + 1);
            }}
            onBlurNoValidation={true}
            addLabel="Add Option"
            onBlur={() => {
              onConfirm(
                posterSlideTemplate,
                `Opinion Poll - ${opinionPollBlock.settings.pollQuestion}`
              );
            }}
          />
        </div>
      </div>
      <div>{hostNotesComponent}</div>
    </div>
  );
};
