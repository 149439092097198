import React, { useState } from 'react';
import { Button } from '../../components/common/Button';
import { TextInput } from '../../components/Input';
import classNames from '../../helper/classNames';

import {
  faMinusSquare,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalBody, ModalWindow } from '../../components/Modal';
import { RolesInput } from '../../components/RolesInput';
import { getStaticAssetUrl } from '../../helper/getStaticAssetUrl';
import { Roles } from '../../helper/roles';
import { RemoveUserIcon, RoleInformationIcon } from './Attendees.styled';
import { usePermissions } from '../../hooks/usePermissions';
import { SeriesVisibilitySelector } from '../../components/SeriesVisibilitySelector';
import { ConfirmationModal } from '../../components/Modal';
import { SeriesVisibility } from '../../helper/visibility';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { rsvpFormFields } from '../../helper/constants';
import {
  convertUTCToTimezone,
  getTimezoneAbbreviation,
} from 'zync-common/helper/timezone';

export const getUsersCSV = (users, visibility, localTimezone) => {
  const data = [['Email', 'Full name', 'First name', 'Last name', 'Roles']];

  if (visibility === SeriesVisibility.RSVP) {
    data[0].push(
      'Registration time (timestamp)',
      'Registration time',
      'Job Title',
      'Organization',
      'Phone number',
      'UTM Campaign',
      'UTM Source',
      'UTM Medium',
      'UTM Content',
      'UTM Term',
      'RSVP Url Params'
    );
  }

  Object.values(users).forEach((user) => {
    const { emailAddress, userName, roles, registrationTime } = user;
    const [firstName, lastName] = (userName || ' ').split(' ');
    const rowData = [
      emailAddress,
      userName ?? 'N/A',
      firstName ?? 'N/A',
      lastName ?? 'N/A',
      roles.join(', '),
    ];
    if (visibility === SeriesVisibility.RSVP) {
      const {
        userTitle,
        organization,
        phone,
        utmCampaign,
        utmSource,
        utmMedium,
        utmContent,
        utmTerm,
        urlParams,
      } = user;
      rowData.push(
        registrationTime ? new Date(registrationTime).getTime() : 'N/A',
        registrationTime
          ? `${convertUTCToTimezone(
              registrationTime,
              localTimezone,
              'time'
            )} ${getTimezoneAbbreviation(localTimezone)}`
          : 'N/A',
        userTitle ?? 'N/A',
        organization ?? 'N/A',
        phone ?? 'N/A',
        utmCampaign ?? 'N/A',
        utmSource ?? 'N/A',
        utmMedium ?? 'N/A',
        utmContent ?? 'N/A',
        utmTerm ?? 'N/A',
        urlParams ?? 'N/A'
      );
    }
    data.push(rowData);
  });

  return data;
};

const DownloadAttendeesButton = ({
  attendees,
  meetingSeriesId,
  visibility,
}) => (
  <Button color={Button.colors.PURPLE} padding={Button.padding.NONE}>
    <CSVLink
      data={getUsersCSV(attendees, visibility)}
      filename={`Registered users list - ${meetingSeriesId}`}
      target="_blank"
    >
      <div className="text-sm text-white font-medium flex gap-3 px-2 py-1">
        <FontAwesomeIcon icon={['fas', 'external-link-alt']} size="lg" />
        <div>Export CSV</div>
      </div>
    </CSVLink>
  </Button>
);

export const Permissions = ({
  inviteInputTitle = 'Invite',
  inviteInputPlaceholder = 'Email',
  inviteButtonText = 'Invite',
  localDispatch,
  series,
  mode,
  workspaceId,
  roleSuggestions,
  owner,
  attendees,
  meetingSeriesId,
  visibility,
  Roles,
  allowInvite = true,
}) => {
  const usePermissionArgs = {
    localDispatch,
    series,
    mode,
    workspaceId,
    attendees,
    meetingSeriesId,
  };

  const {
    newEmailAddress,
    isEmailValid,
    showMeetingRoles,
    setShowMeetingRoles,
    emailToAddToWorkspace,
    setEmailToAddToWorkspace,
    lastError,
    isLoading,
    lockedRoles,
    filteredAttendees,
    addUserRole,
    removeUserRole,
    handleChangeEmailAddress,
    deleteAttendee,
    onConfirmAddToWorkspace,
    onInviteUserSubmitted,
    changeSeriesVisibility,
    RoleInformationModal,
    showMoreGuests,
    setShowMoreGuests,
    showMoreModerators,
    setShowMoreModerators,
    scheduledEvent,
    setScheduledEvent,
    updateSeriesScheduledEvent,
    convertedCurrentScheduledEvent,
    emailAddress,
    enabledFieldsRsvp = [],
  } = usePermissions(usePermissionArgs);

  const isOrganizationFieldEnabled = enabledFieldsRsvp.includes(
    rsvpFormFields.ORGANIZATION
  );

  return (
    <div className="flex flex-col items-start w-full">
      <div className="flex flex-col gap-5 md:gap-3 min-w-full">
        <div className="order-2 md:order-1">
          {mode === Roles.GUEST && (
            <SeriesVisibilitySelector
              borderColor="#8B33F7"
              defaultValue={visibility}
              onChangeVisibility={(value) => changeSeriesVisibility(value)}
              onUpdateSeriesScheduledEvent={(value) =>
                updateSeriesScheduledEvent(value)
              }
              isUpdating={true}
              scheduledEvent={scheduledEvent}
              setScheduledEvent={setScheduledEvent}
              convertedCurrentScheduledEvent={convertedCurrentScheduledEvent}
            />
          )}
        </div>
        {/* Invite Section */}
        {allowInvite && (
          <form
            className="flex items-end order-1 md:order-2 "
            onSubmit={onInviteUserSubmitted}
          >
            <div className="w-2/3 md:w-1/3">
              <TextInput
                label={inviteInputTitle}
                placeholder={inviteInputPlaceholder}
                style={{ width: '100%', height: '2.5rem' }}
                value={newEmailAddress}
                onChange={handleChangeEmailAddress}
              />
            </div>
            <div className="w-1/3 mb-[0.73rem] ml-2 md:max-w-fit">
              <Button
                type="submit"
                state={
                  isLoading
                    ? Button.states.LOADING
                    : !newEmailAddress || !isEmailValid
                    ? Button.states.DISABLED
                    : Button.states.DEFAULT
                }
                size={Button.sizes.FULL}
                color={Button.colors.PURPLE}
                padding={Button.padding.NONE}
              >
                <span className="text-xs font-semibold py-[11.5px] px-7">
                  {inviteButtonText}
                </span>
              </Button>
            </div>
          </form>
        )}
        {lastError && <p className="text-red">{lastError}</p>}
        {emailToAddToWorkspace && (
          <ModalWindow showCancel={false} size={Modal.size.md}>
            <div className="bg-purple bg-opacity-[5%] rounded-sm flex text-blue-gray items-center gap-3 py-5 px-4">
              <div className="w-12">
                <img src={getStaticAssetUrl('briefcase.png')} alt="" />
              </div>
              <h2 className="text-black font-medium text-lg ">Add User</h2>
            </div>
            <ModalBody>
              <div className="py-0 mt-8 mb-7 text-sm ">
                Only users in your workspace can be moderators. Do you want to
                add {emailToAddToWorkspace} to your workspace?
              </div>
              <div className="flex justify-end gap-4">
                <Button onClick={() => setEmailToAddToWorkspace(null)}>
                  <span className="text-xs font-medium">Cancel</span>
                </Button>
                <Button
                  color={Button.colors.PURPLE}
                  onClick={onConfirmAddToWorkspace}
                >
                  <span className="text-xs font-medium">Add User</span>
                </Button>
              </div>
            </ModalBody>
          </ModalWindow>
        )}
        {/* Information Section */}
        {visibility === SeriesVisibility.RSVP &&
          mode === 'guest' &&
          attendees.length > 0 && (
            <div className="flex flex-row-reverse justify-between order-3">
              <div className="text-blue-dark font-semibold">RSVP'd guests</div>
              <DownloadAttendeesButton
                attendees={attendees}
                meetingSeriesId={meetingSeriesId}
                visibility={visibility}
              />
            </div>
          )}

        <div
          className={classNames(
            'order-4 mx-1',
            !filteredAttendees?.length && 'hidden'
          )}
        >
          <div className="flex w-full">
            <div className=" min-w-[25%]">
              <span className="text-sm font-bold">Name</span>
            </div>
            <div className="hidden md:block min-w-[25%]">
              <span className="text-sm font-bold">Email</span>
            </div>
            <div className="hidden md:flex grow min-w-[25%] space-x-2 items-center">
              <span className="text-sm font-bold">Roles</span>

              <RoleInformationIcon
                style={{ color: 'gray' }}
                icon={faQuestionCircle}
                onClick={() =>
                  setShowMeetingRoles((wereRolesShown) => !wereRolesShown)
                }
              />
              {showMeetingRoles && (
                <div className="relative min-w-[70%]">
                  <RoleInformationModal
                    onClickOutside={() => setShowMeetingRoles(false)}
                  />
                </div>
              )}
            </div>
            {visibility === SeriesVisibility.RSVP &&
              isOrganizationFieldEnabled && (
                <div className="hidden md:block min-w-[25%]">
                  <span className="text-sm font-bold">Organization</span>
                </div>
              )}
          </div>
          <div className="w-full bg-gray h-[1px]" />
          {/* Data */}
          <div className="mt-4">
            <TableData
              deleteAttendee={deleteAttendee}
              roleSuggestions={roleSuggestions}
              lockedRoles={lockedRoles}
              addUserRole={addUserRole}
              removeUserRole={removeUserRole}
              attendees={attendees}
              owner={owner}
              filteredAttendees={filteredAttendees}
              setShowMoreModerators={setShowMoreModerators}
              setShowMoreGuests={setShowMoreGuests}
              showMoreModerators={showMoreModerators}
              showMoreGuests={showMoreGuests}
              mode={mode}
              emailAddress={emailAddress}
              visibility={visibility}
              isOrganizationFieldEnabled={isOrganizationFieldEnabled}
            />
          </div>
          {visibility !== SeriesVisibility.RSVP && (
            <DownloadAttendeesButton
              attendees={attendees}
              meetingSeriesId={meetingSeriesId}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const TableData = ({
  deleteAttendee,
  roleSuggestions,
  lockedRoles,
  addUserRole,
  removeUserRole,
  attendees,
  owner,
  filteredAttendees,
  showMoreModerators,
  showMoreGuests,
  setShowMoreModerators,
  setShowMoreGuests,
  mode,
  emailAddress,
  visibility,
  isOrganizationFieldEnabled,
}) => {
  const showAllModerators = mode === Roles.MODERATOR && showMoreModerators;
  const showAllGuests = mode === Roles.GUEST && showMoreGuests;
  const initialAmountToShow = 5;
  const remainingAmount = filteredAttendees?.length - initialAmountToShow;
  const [
    showDeleteSelfAsModeratorConfirmation,
    setShowDeleteSelfAsModeratorConfirmation,
  ] = useState(false);

  const updatedAttendees =
    showAllModerators || showAllGuests
      ? filteredAttendees
      : filteredAttendees?.slice(0, 5);

  const handleShowMore = () => {
    if (!showAllModerators) {
      setShowMoreModerators(true);
    }
    if (!showAllGuests) {
      setShowMoreGuests(true);
    }
  };

  const handleDeleteAttendee = (attendee) => {
    if (mode === Roles.MODERATOR && attendee.emailAddress === emailAddress) {
      setShowDeleteSelfAsModeratorConfirmation(attendee.emailAddress);
    } else {
      deleteAttendee(attendee.emailAddress);
    }
  };

  return (
    <>
      {showDeleteSelfAsModeratorConfirmation && (
        <ConfirmationModal
          onCancel={() => setShowDeleteSelfAsModeratorConfirmation(false)}
          onConfirm={() =>
            deleteAttendee(showDeleteSelfAsModeratorConfirmation)
          }
          title="Delete Moderator"
          text="Are you sure you want to delete yourself as a moderator of this meeting?"
          activateKeyboardShortcuts
        />
      )}
      {updatedAttendees &&
        updatedAttendees.map((attendee, idx) => (
          <div key={attendee?.emailAddress} className="w-full">
            <div className="flex w-full space-x-3 md:space-x-0">
              <div className="min-w-[40%] md:min-w-[25%] flex">
                <div
                  className={classNames(
                    'flex items-center h-fit min-w-fit mr-3 font-medium',
                    owner ? 'md:space-x-0' : 'md:space-x-3'
                  )}
                >
                  <div
                    className={classNames(
                      owner === attendee.emailAddress
                        ? 'hidden -ml-6'
                        : 'block',
                      'hidden md:block'
                    )}
                  >
                    <RemoveUserIcon
                      style={{ color: '#F8155C' }}
                      disabled={
                        owner ===
                        attendee.emailAddress /* This is actually the userId */
                      }
                      icon={faMinusSquare}
                      onClick={() => handleDeleteAttendee(attendee)}
                    />
                  </div>
                  <div className="text-sm break-all pr-3 ">
                    {attendee?.userName || attendee?.emailAddress}
                  </div>
                </div>
              </div>
              <div className="hidden md:flex md:min-w-[25%]  break-all pr-3 font-medium">
                <span className="text-sm">{attendee?.emailAddress}</span>
              </div>
              <div className="relative w-full md:min-w-[25%] mt-[-4px]">
                <RolesInput
                  user={attendee}
                  roleSuggestions={roleSuggestions}
                  canEdit={true}
                  lockedRoles={lockedRoles}
                  handleAddUserRole={addUserRole(
                    attendee.roles,
                    attendee.emailAddress
                  )}
                  onRoleRemove={removeUserRole(
                    attendee.roles,
                    attendee.emailAddress
                  )}
                />
              </div>
              {visibility === SeriesVisibility.RSVP &&
                isOrganizationFieldEnabled && (
                  <div className="hidden md:flex md:min-w-[25%]  break-all pr-3 font-medium">
                    <span className="text-sm">
                      {attendee?.organization ?? '-'}
                    </span>
                  </div>
                )}
            </div>
            <div
              className={classNames(
                idx !== attendees.length - 1
                  ? 'w-full bg-gray h-[1px] my-3'
                  : ''
              )}
            />
          </div>
        ))}
      {!showAllModerators &&
        !showAllGuests &&
        filteredAttendees?.length > initialAmountToShow && (
          <div className="flex justify-center items-center mt-5">
            <Button
              color={Button.colors.PURPLE_LITE}
              padding={Button.padding.NONE}
              onClick={handleShowMore}
            >
              <span className="text-sm font-medium px-2 py-2">
                See {remainingAmount} more
              </span>
            </Button>
          </div>
        )}
    </>
  );
};
