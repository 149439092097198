import { useState, useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useProcessedActions } from '../helper/useProcessedActions';
import { useBulkAddMeetingRoles } from '../components/BulkAddMeetingRolesDropdown';
import { useInvitePermissions } from '../components/MeetingControl/useInvitePermissions';

export const useAddUserMeetingRoles = ({ user, meetingId }) => {
  const { canEditRole } = useInvitePermissions(user);
  const [roleAddActionId, setRoleAddActionId] = useState(null);
  const [isAddingUserMeetingRolesLoading, setIsAddingUserMeetingRolesLoading] =
    useState(false);
  const { hasActionCompleted: hasRoleAddActionCompleted } =
    useProcessedActions(roleAddActionId);
  const { bulkRolesAddAction } = useBulkAddMeetingRoles(meetingId, user);

  /**
   * Adds roles to a single user.
   */
  const handleAddUserMeetingRoles = useCallback(
    (users, roles) => {
      if (canEditRole) {
        const actionId = uuidv4();
        setRoleAddActionId(actionId);
        bulkRolesAddAction(users, roles, actionId);
      }
    },
    [bulkRolesAddAction, canEditRole]
  );

  useEffect(() => {
    if (hasRoleAddActionCompleted(roleAddActionId)) {
      setIsAddingUserMeetingRolesLoading(false);
    }
  }, [
    isAddingUserMeetingRolesLoading,
    setIsAddingUserMeetingRolesLoading,
    roleAddActionId,
    hasRoleAddActionCompleted,
  ]);

  return {
    handleAddUserMeetingRoles,
    isAddingUserMeetingRolesLoading,
    setIsAddingUserMeetingRolesLoading,
  };
};
