import React, { useState } from 'react';

import { PlanTitleCard } from './PlanTitleCard';
import { PlanScheduleCard } from './PlanScheduleCard';
import { PlanSpeakerCard } from './PlanSpeakerCard';
import { PlanInviteSpeakerCard } from './PlanInviteSpeakerCard';
import { usePosterSlide } from '../../../components/series/settings/usePosterSlide';
import { ScenePreview } from '../../../components/authoring/Menu/ScenePreview';
import { PlanSetHostCard } from './PlanSetHostCard';
import { getSlideTemplateKey } from '../../Portal/CreateNewEventModal';
import { presenterTypes } from '../../../components/series/settings/EventPresenters';
import {
  PurpleNotification,
  Notifications,
} from '../../../components/Notifications';
import { WarningTriangleIcon } from '../../../components/icons/WarningTriangleIcon';
import classNames from '../../../helper/classNames';
import { episodeTypes } from 'zync-common/types';

export const TaskCardContainer = ({
  children,
  boxShadow = false,
  hide = false,
}) =>
  hide ? null : (
    <div
      className={`flex flex-col w-full ${boxShadow && 'shadow-elevation-one'}`}
    >
      {children}
    </div>
  );

export const TaskCardHeader = ({ children, className }) => (
  <h4
    className={classNames('font-semibold text-base text-blue-dark', className)}
  >
    {children}
  </h4>
);

export const Plan = ({ series, localDispatch, disableEventPage }) => {
  const { workspace, settings, scheduledEvent } = series || {};
  const { startDate, endDate, timezone, registeredAttendees } = scheduledEvent;
  const isDateSet = !!startDate && !!endDate && !!timezone;
  const episodeType = series?.settings?.episodeType;
  const isSoloEpisode = episodeType === episodeTypes.solo;

  const { eventPresenters = [] } = settings || {};
  const speakers = eventPresenters.filter(
    (ep) => ep.type === presenterTypes.SPEAKER
  );
  const [title, setTitle] = useState(series.title);
  const { brandKit } = workspace || {};

  const eventHost = eventPresenters.find(
    (ep) => ep.type === presenterTypes.HOST
  );

  const [temporaryEventPresenter, setTemporaryEventPresenter] = useState({
    emailAddress: '',
    fullName: '',
    jobTitle: '',
    linkedInUrl: '',
    presenterPictureUrl: '',
    type: 'speaker',
  });

  const isTemporaryEventPresenterVisible = Boolean(
    temporaryEventPresenter.fullName || temporaryEventPresenter.jobTitle
  );

  const presenters = isTemporaryEventPresenterVisible
    ? [eventHost, temporaryEventPresenter, ...speakers]
    : [eventHost, ...speakers];

  const areSomeSpeakerInvitesUnsent = speakers.some(
    (presenter) => !presenter.invited
  );

  const areAnySpeakerInvitesSentOrAttendeesRegistered = Boolean(
    speakers.some((presenter) => presenter.invited) ||
      registeredAttendees?.length
  );

  const { posterSlide } = usePosterSlide(
    {
      ...series,
      title,
      settings: {
        ...series.settings,
        eventPresenters: presenters,
      },
    },
    localDispatch,
    false,
    getSlideTemplateKey(
      speakers.length + (isTemporaryEventPresenterVisible ? 1 : 0)
    ),
    true
  );

  const previewComponent = posterSlide ? (
    <div className="flex-shrink-0">
      <ScenePreview
        scene={posterSlide}
        brandKit={brandKit}
        width={434}
        height={(434 / 16) * 9}
      />
    </div>
  ) : null;

  return (
    <div className="flex gap-2 items-start md:w-[1040px] mx-auto mt-16">
      <div className="flex flex-[1_0_0%] flex-col gap-6 items-center pb-10 w-full">
        <TaskCardContainer>
          <PlanTitleCard
            series={series}
            localDispatch={localDispatch}
            title={title}
            setTitle={setTitle}
            disableEventPage={disableEventPage}
          />
        </TaskCardContainer>
        <TaskCardContainer hide={isSoloEpisode}>
          <PlanScheduleCard
            series={series}
            localDispatch={localDispatch}
            disableEventPage={disableEventPage}
            areAnySpeakerInvitesSentOrAttendeesRegistered={
              areAnySpeakerInvitesSentOrAttendeesRegistered
            }
          />
        </TaskCardContainer>
        <TaskCardContainer hide={isSoloEpisode}>
          <PlanSetHostCard
            series={series}
            localDispatch={localDispatch}
            disableEventPage={disableEventPage}
          />
        </TaskCardContainer>
        <TaskCardContainer hide={isSoloEpisode}>
          <PlanSpeakerCard
            series={series}
            localDispatch={localDispatch}
            disableEventPage={disableEventPage}
            setTemporaryEventPresenter={setTemporaryEventPresenter}
            temporaryEventPresenter={temporaryEventPresenter}
          />
        </TaskCardContainer>
        {(isSoloEpisode || isDateSet) && (
          <TaskCardContainer>
            <PlanInviteSpeakerCard
              series={series}
              localDispatch={localDispatch}
              disableEventPage={disableEventPage}
            />
          </TaskCardContainer>
        )}
      </div>
      <div
        className={classNames(
          isSoloEpisode ? 'invisible' : '',
          'flex flex-col gap-y-2 items-center flex-[1_0_0%] sticky top-[calc(64px+121px+56px)] p-8 border border-gray/50 rounded-xl'
        )}
      >
        <Notifications
          render={[
            {
              component: (
                <PurpleNotification
                  title={
                    <p className="flex gap-2">
                      <WarningTriangleIcon /> Speaker Invites not sent
                    </p>
                  }
                  message="Speakers will need unique links to attend the event."
                />
              ),
              when: isDateSet && areSomeSpeakerInvitesUnsent,
            },
            {
              component: (
                <PurpleNotification
                  title={
                    <p className="flex gap-2">
                      <WarningTriangleIcon /> Episode Time Not Set
                    </p>
                  }
                  message="You can’t enter the recording studio without a set episode time"
                />
              ),
              when: !isDateSet,
            },
          ]}
        />
        <h2 className="text-xl font-semibold text-center">Preview</h2>
        {previewComponent}
      </div>
    </div>
  );
};
