import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { USER_ACTIVITY } from '../reducers/userActivity';
import { createTrackStateFromParticipant } from '../reducers/callState';

const isInstanceOfElement = (object) => {
  return object instanceof Element;
};
/* Accepts two objects of the same type and returns key changed values in the same properties.
   If property.value is null, it means there was no change.
   Objects are traversed recursively to avoid shallow comparison
*/
const analyzeChanges = (firstObj, secondObj) => {
  let result = {};
  for (let key in firstObj) {
    const firstObjectValue = firstObj?.[key];
    const secondObjectValue = secondObj?.[key];

    if (
      isInstanceOfElement(firstObjectValue) ||
      isInstanceOfElement(secondObjectValue)
    ) {
      return null;
    }

    if (
      !Array.isArray(firstObjectValue) &&
      typeof firstObjectValue === 'object' &&
      typeof secondObjectValue === 'object'
    ) {
      analyzeChanges(firstObjectValue, secondObjectValue);
    }

    result[key] =
      firstObjectValue === secondObjectValue ? null : secondObjectValue;
  }

  return result;
};

/* We want to find previous values of current trackState (changedUserTrackState). This helper will iterate usersTrackStates to find matching sessionId  */
const getBeforeAndAfterSnapshot = (usersTrackStates, changedUserTrackState) => {
  const sessionId = changedUserTrackState.sessionId; // this is a unique track id, but we use sessionId because this is how daily names it

  for (const userName in usersTrackStates) {
    if (usersTrackStates[userName]?.sessionId === sessionId) {
      return {
        /* Objects returned here have the same interface - "before" is just a track state from the list and "after" is a track state coming from event */
        before: usersTrackStates[userName],
        after: changedUserTrackState,
      };
    }
  }
};

/* useDailyOnParticipantsChange is a hook for processing "participant-updated" event and creating side-effects like user activity and such based on outcome */
export const useDailyOnParticipantsChange = (users) => {
  const dispatch = useDispatch();

  /* Main function that produces side-effects based on deltas outcome. Here we can provide additional conditions and expected results */
  const processChanges = useCallback(
    (event, { cameraOn }) => {
      switch (true) {
        /* User switched cameraOn === true, and we want to notify all participants of this event by dispatching USER_ACTIVITY */
        case cameraOn: {
          return dispatch({
            type: USER_ACTIVITY,
            userId: event.participant.user_name,
            users: Object.keys(users || {}),
          });
        }
        default: {
          // making ESLint happy - no need to do anything
        }
      }
    },
    [dispatch, users]
  );

  const onParticipantsChange = useCallback(
    (trackStates, event) => {
      if (event.action !== 'participant-updated') {
        return;
      }

      const changes = getBeforeAndAfterSnapshot(
        trackStates,
        createTrackStateFromParticipant(event.participant)
      );

      if (changes) {
        const deltas = analyzeChanges(changes.before, changes.after);

        processChanges(event, deltas);
      }
    },
    [processChanges]
  );

  return {
    onParticipantsChange,
  };
};
