import React from 'react';

export const DownloadIcon = ({
  className = 'fill-purple',
  width = '20px',
  height = '20px',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M19 12C18.4 12 18 12.4 18 13V17C18 17.6 17.6 18 17 18H3C2.4 18 2 17.6 2 17V13C2 12.4 1.6 12 1 12C0.4 12 0 12.4 0 13V17C0 18.7 1.3 20 3 20H17C18.7 20 20 18.7 20 17V13C20 12.4 19.6 12 19 12ZM9.3 13.7C9.7 14.1 10.3 14.1 10.7 13.7L14.7 9.7C15.1 9.3 15.1 8.7 14.7 8.3C14.3 7.9 13.7 7.9 13.3 8.3L11 10.6V1C11 0.4 10.6 0 10 0C9.4 0 9 0.4 9 1V10.6L6.7 8.3C6.3 7.9 5.7 7.9 5.3 8.3C4.9 8.7 4.9 9.3 5.3 9.7L9.3 13.7Z" />
    </svg>
  );
};
