import React from 'react';
import { Switch } from '../common/Switch';
import { InputExplanation, FieldContainer, InputLabel } from '../SettingsInput';

/**
 * A checkbox input providing editing for a checked/unchecked (true/false) input value.
 */
export const BooleanInput = ({
  /** A label for the input. */
  label,
  /** An explanation for the input. */
  explanation,
  /** Handles changes to the input. Passes one boolean argument. */
  onChange,
  /** The default value, if uncontrolled. */
  defaultValue,
  /** The value, if controlled. */
  value,
  /** Whether the input is disabled. */
  disabled = false,
  /** Customizable text for the switch.  */
  booleanText = ['ON', 'OFF'],
}) => (
  <FieldContainer>
    <InputLabel>{label}</InputLabel>
    <Switch
      defaultChecked={defaultValue}
      checked={value}
      onChangeValue={onChange}
      disabled={disabled}
      booleanText={booleanText}
    />
    {explanation && <InputExplanation>{explanation}</InputExplanation>}
  </FieldContainer>
);
