import React, { useEffect, useState } from 'react';
import classNames from '../../helper/classNames';
import { usePrevious } from '../../hooks/usePrevious';
import { hasChanged, InputExplanation } from '../SettingsInput';

const isEmpty = (string) => string === '';

/**
 * An input field for all manner of text-based inputs including email, number, password, and text.
 * This element replaces the previous <TextInput /> and provides the same attributes.
 */
export const TextInput = React.forwardRef(
  (
    {
      /** The label value to briefly describe the input. */
      label = '',

      /** A longer explanation of the input's purpose, if needed. */
      explanation = '',

      /** The input type. One of text, email, number, or password. */
      type = 'text',

      /** Whether the input is disabled. false by default. */
      disabled = false,

      /** The default Value of the input, if uncontrolled. */
      defaultValue,

      /** The value of the input, if controlled. */
      value,

      /** A handler for when the input changes. Passes (event, value), where value is a string or number. */
      onChange,

      /** A handler for when the enter key is pressed. Passes (event, value). */
      onEnter,

      /** A handler for when the input loses focus. Passes (event, value). */
      onBlur,

      /** For type "number", the minimum value. */
      min = 0,
      /** For type "number", the maximum value. */
      max = undefined,
      /**
       * For type "number", the step size (how much to increase or decrease the value when up or down is
       * pressed). Defaults to 1.
       */
      step = 1,

      /** A short string to display at the end of the input, e.g. "%" or "px" */
      unit = undefined,

      /** Whether the text input should be showed in a compact style. */
      compact = false,

      /** An optional error message. */
      error,

      /** Sets input field outline to red. */
      showErrorStyle = false,

      /** An upper limit for the number of characters. */
      textLength,

      /** Run onBlur without value validation */
      onBlurNoValidation = false,

      size,

      /** Any other props to be passed directly to the native input. */
      ...props
    },
    ref
  ) => {
    const [internalValue, setInternalValue] = useState(defaultValue);

    const previousValue = usePrevious(defaultValue);

    useEffect(() => {
      setInternalValue(defaultValue);
    }, [defaultValue]);

    return (
      <label className={`relative flex flex-col items-stretch my-1.5`}>
        {label && (
          <div className="text-blue-dark font-medium text-sm mb-2 font-inter">
            {label}
          </div>
        )}
        <div className="relative flex flex-col items-stretch">
          {unit && (
            <div className="absolute right-8 top-3 text-light-gray">{unit}</div>
          )}
          <input
            className={classNames(
              'bg-white border-solid border rounded text-blue-gray font-normal text-base md:text-sm w-full box-border font-inter',
              'disabled:bg-gray/20',
              'placeholder:text-light-gray',
              disabled ? '' : 'hover:border-purple',
              compact ? 'p-1' : 'p-2',
              size ? size : '',
              showErrorStyle
                ? 'outline-red focus:border-red hover:border-red border-red focus:outline-none'
                : 'border-gray focus:outline-none outline-purple focus:border-purple hover:border-purple'
            )}
            ref={ref}
            type={type}
            disabled={disabled ?? false}
            onChange={(event) =>
              onChange
                ? onChange(event, event.currentTarget.value)
                : setInternalValue(event.currentTarget.value)
            }
            onBlur={(event) => {
              (onBlurNoValidation || hasChanged(event, previousValue)) &&
                onBlur &&
                onBlur(event, event.currentTarget.value);
            }}
            onKeyPress={
              onEnter
                ? (event) =>
                    event.key === 'Enter' &&
                    onEnter(event, event.currentTarget.value)
                : undefined
            }
            defaultValue={value ? defaultValue : undefined}
            value={isEmpty(value) || value ? value : internalValue}
            min={type === 'number' ? min : undefined}
            max={type === 'number' ? max : undefined}
            step={type === 'number' ? step : undefined}
            maxLength={textLength ? textLength : ''}
            {...props}
          />
        </div>
        {error && (
          <div className="font-light mt-1 ml-4 text-red text-right">
            {error}
          </div>
        )}
        {explanation && <InputExplanation>{explanation}</InputExplanation>}
      </label>
    );
  }
);
