import { useState, useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { BoomerangUpArrow } from './icons/BoomerangUpArrow';
import { BoomerangDownArrow } from './icons/BoomerangDownArrow';
import { Button } from './common/Button';
import { sendEvent } from '../helper/api';
import { useProcessedActions } from '../helper/useProcessedActions';
import { AddRolesPopupWindow } from './MeetingControl/PeopleControl/AddRolesPopupWindow';

/**
 * A hook for the BulkAddMeetingRolesDropdown component:
 *
 * Returns an object containing:
 *
 * bulkAddRolesAction: Sends the action 'ADD_ROLES_TO_USERS' via sendEvent api call.
 * dropdownClicked: True if the dropdown was clicked.
 * setDropdownClicked: State setter for dropdownClicked.
 * isBulkAssignLoading: True if the action 'ADD_ROLES_TO_USERS' is not yet complete.
 * setIsBulkAssignLoading: State setter for isBulkAssignLoading.
 *
 */
export const useBulkAddMeetingRoles = (meetingId, rolesAssigningUser) => {
  const [dropdownClicked, setDropdownClicked] = useState(false);
  const [bulkRolesAddActionId, setbulkRolesAddActionId] = useState(null);
  const [isBulkAssignLoading, setIsBulkAssignLoading] = useState(false);

  /**
   * Adds checked roles to all users via sendEvent.
   */
  const bulkRolesAddAction = useCallback(
    (users, roles, argActionId) => {
      const actionId = argActionId || uuidv4();
      if (!argActionId) {
        setbulkRolesAddActionId(actionId);
      }
      sendEvent(rolesAssigningUser?.userId, meetingId, {
        type: 'ADD_ROLES_TO_USERS',
        rolesAssigningUser: rolesAssigningUser?.userId,
        usersToBeAssigned: users,
        roles,
        actionId,
      });
    },
    [meetingId, rolesAssigningUser?.userId]
  );

  /**
   * Checks if the action is completed.
   */
  const { hasActionCompleted: hasBulkRoleAddActionCompleted } =
    useProcessedActions(bulkRolesAddActionId, rolesAssigningUser);

  /**
   * Special Loading case:
   *
   * This component cannot make use of the useStartLoading hook,
   * since the loading state of this dropdown depends on the 'Bulk Assign' button in
   * the AddRolesPopupWindow(its child component) being clicked.
   *
   * The useStartLoading hook accepts an onClick handler, that can only be passed in by
   * AddRolesPopupWindow component (since that's where the 'Bulk Assign' button that needs to be clicked exists)
   *
   * State is therefore needed inside of this component in order to know when the 'Bulk Assign' button
   * in the AddRolesPopupWindow is clicked, defeating the purpose of the useStartLoading hook.
   *
   */

  useEffect(() => {
    if (hasBulkRoleAddActionCompleted(bulkRolesAddActionId)) {
      setIsBulkAssignLoading(false);
    }
  }, [
    setIsBulkAssignLoading,
    bulkRolesAddActionId,
    hasBulkRoleAddActionCompleted,
  ]);

  return {
    bulkRolesAddAction,
    dropdownClicked,
    setDropdownClicked,
    isBulkAssignLoading,
    setIsBulkAssignLoading,
  };
};

/**
 * A dropdown component for bulk adding user roles.
 *
 * selectedUsersObjectsList: A list of user objects. Each user object should have a 'roles' property.
 * currentUser: The user issuing the bulk add roles action.
 * absolutePositioningForPopup: absolute positioning values for the popup window.
 * meetingId
 */
export const BulkAddMeetingRolesDropdown = ({
  selectedUserObjectsList = [],
  currentUser,
  meetingId,
  absolutePositioningForPopup,
  roleSuggestions,
}) => {
  const {
    bulkRolesAddAction,
    isBulkAssignLoading,
    setIsBulkAssignLoading,
    dropdownClicked,
    setDropdownClicked,
  } = useBulkAddMeetingRoles(meetingId, currentUser);

  const { top, left, bottom, right } = absolutePositioningForPopup;

  return (
    <div>
      <Button
        onClick={() =>
          setDropdownClicked((dropdownClicked) => !dropdownClicked)
        }
        state={isBulkAssignLoading && Button.states.LOADING}
      >
        <span className="text-sm px-2 relative">
          <p>Bulk Assign</p>
          <div className="absolute -right-3 top-1/2 -translate-y-1/2">
            {dropdownClicked ? <BoomerangUpArrow /> : <BoomerangDownArrow />}
          </div>
        </span>
      </Button>
      {dropdownClicked && (
        <div
          className="shadow-lg absolute"
          style={{ top, left, right, bottom }}
        >
          <AddRolesPopupWindow
            setIsBulkAssignLoading={setIsBulkAssignLoading}
            users={selectedUserObjectsList}
            onAddUsersRoles={bulkRolesAddAction}
            roleSuggestions={roleSuggestions}
            onClose={() => {
              setDropdownClicked(false);
            }}
            showBulkAssign={true}
          />
        </div>
      )}
    </div>
  );
};
