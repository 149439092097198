import { useState } from 'react';
import classNames from '../../../helper/classNames';
import { Checkbox } from '../../Checkbox/Checkbox';
import { LoadingSpinner } from '../../LoadingSpinner';
import { AddRolesPopupWindow } from './AddRolesPopupWindow';
import { AttendeeMic } from '../../icons/AttendeeMic';
import { AttendeeMicMuted } from '../../icons/AttendeeMicMuted';
import { isUserMeetingController } from '../../../helper/roles';
import { ExitIcon } from '../../icons/ExitIcon';
import { useMuteMeetingUser } from '../../../hooks/useMuteMeetingUser';
import { useRemoveUserMeetingRole } from '../../../hooks/useRemoveUserMeetingRole';
import { useAddUserMeetingRoles } from '../../../hooks/useAddUserMeetingRoles';

/**
 * A single row in the MeetingAttendees table.
 */
export const MeetingAttendeeRow = ({
  user,
  setSelectedUser,
  selectedUser,
  updateSelectedUser,
  editingUser,
  selectedUsers,
  meetingId,
  roleSuggestions,
  isEjectingUserLoading,
  ejectedUser,
  showEjectUserPopup,
}) => {
  const { roles, trackStates } = user || {};
  const { micOn } = trackStates || {};
  const isUserSelected = !!selectedUsers[user?.userId];
  const [showRolesPopup, setShowRolesPopup] = useState(false);

  /**
   * Hook for muting a user in a meeting.
   */
  const { handleMuteMeetingUser, mutedUser, isMuteUserLoading } =
    useMuteMeetingUser({
      user: editingUser,
      meetingId,
    });

  /**
   * Hook for removing a role from a user in meeting.
   */
  const {
    handleRemoveUserMeetingRole,
    isRemovingUserMeetingRoleLoading,
    roleToRemove,
  } = useRemoveUserMeetingRole({ user: editingUser, meetingId });

  /**
   * Hook for adding a role to a single user.
   */
  const {
    isAddingUserMeetingRolesLoading,
    handleAddUserMeetingRoles,
    setIsAddingUserMeetingRolesLoading,
  } = useAddUserMeetingRoles({ user: editingUser, meetingId });

  // show mute spinner if:
  // The userId matches the userId of the user to be muted and isMuteUserLoading is true.
  const showMuteSpinner =
    user?.userId === mutedUser?.userId && isMuteUserLoading;

  // show eject spinner if:
  // The userId matches the userId of the user to be ejected and isEjectUserLoading is true.
  const showEjectSpinner =
    user?.userId === ejectedUser?.userId && isEjectingUserLoading;

  // show Add Role spinner if:
  // The userId matches the userId of the user to add roles to and isAddingUserMeetingRolesLoading is true.
  const showAddRoleSpinner =
    user?.userId === selectedUser?.userId && isAddingUserMeetingRolesLoading;

  // Show Remove Role spinner if:
  // The userId matches the userId of the user to remove the selected role from.
  // The role arg to matches roleToRemove.
  // isRemovingUserMeetingRoleLoading is set to true.
  const showRemoveRoleSpinner = (role) =>
    user?.userId === selectedUser?.userId &&
    role === roleToRemove &&
    isRemovingUserMeetingRoleLoading;

  const handleClosePopup = () => setTimeout(() => setShowRolesPopup(false), 0);

  return (
    <tr
      className={classNames(
        'flex px-7 py-1 border-b border-gray',
        isUserSelected && 'bg-purple bg-opacity-10'
      )}
    >
      <td className="w-1/2 pr-4 flex flex-col justify-center">
        <div className="flex items-center">
          <Checkbox
            checked={isUserSelected}
            handleChange={() => updateSelectedUser(user)}
          />
          <div className="flex flex-col items-start ml-2">
            <div className="font-normal text-ellipsis whitespace-nowrap overflow-hidden">
              {user?.userName}
            </div>
            <div className="text-xs font-light text-black text-opacity-60 text-ellipsis whitespace-nowrap overflow-hidden">
              {user?.emailAddress}
            </div>
          </div>
        </div>
      </td>
      <td className="flex items-center w-1/2">
        {
          <div className="flex gap-x-2 flex-wrap gap-y-2 mr-5 w-11/12">
            {roles?.map((role) => (
              <div
                key={role}
                className="flex items-center px-2 py-1 rounded-md bg-purple bg-opacity-10 text-purple space-x-1"
              >
                <div className="lowercase text-sm font-medium">{role}</div>
                {/* 'X' button next to each role. */}

                <button
                  onClick={() =>
                    handleRemoveUserMeetingRole(role, user, () =>
                      setSelectedUser(user)
                    )
                  }
                  className="text-xxs text-blue-gray font-bold cursor-pointer"
                >
                  {showRemoveRoleSpinner(role) ? (
                    <LoadingSpinner width="20px" />
                  ) : (
                    <span>&#10005;</span>
                  )}
                </button>
              </div>
            ))}
            {/**
             * Adding a Role to a User.
             */}
            {showAddRoleSpinner ? (
              <LoadingSpinner width="20px" />
            ) : (
              <>
                {/* Plus button at the end of the roles list. */}
                <button
                  onClick={() => {
                    setSelectedUser(user);
                    setShowRolesPopup(true);
                  }}
                  className="relative cursor-pointer text-blue-gray hover:text-purple"
                >
                  <div className="text-lg">&#43;</div>
                  <div className="absolute border-2 border-solid border-blue-gray p-2 rounded-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 hover:border-purple"></div>
                </button>
                {showRolesPopup && selectedUser && (
                  <div className="absolute right-0 top-44 bottom-0 left-0 flex items-center justify-center">
                    <AddRolesPopupWindow
                      users={[selectedUser]}
                      onAddUsersRoles={handleAddUserMeetingRoles}
                      roleSuggestions={roleSuggestions}
                      onClose={handleClosePopup}
                      setIsAddSingleUserRolesLoading={
                        setIsAddingUserMeetingRolesLoading
                      }
                    />
                  </div>
                )}
              </>
            )}
          </div>
        }
      </td>
      {/* Muting a user */}
      <td className="flex items-center w-1/12">
        {micOn ? (
          <button
            onClick={() =>
              handleMuteMeetingUser(user, () => setSelectedUser(user))
            }
            className="cursor-pointer w-10 h-10 flex justify-start items-center"
          >
            {showMuteSpinner ? (
              <LoadingSpinner width="20px" />
            ) : (
              <AttendeeMic />
            )}
          </button>
        ) : (
          <button className="cursor-not-allowed w-10 h-10 flex justify-start items-center">
            <AttendeeMicMuted />
          </button>
        )}
        {/* Ejecting a user */}
        {!isUserMeetingController(user) &&
          (showEjectSpinner ? (
            <LoadingSpinner width="20px" />
          ) : (
            <button
              onClick={() => {
                setSelectedUser(user);
                showEjectUserPopup();
              }}
              className="cursor-pointer"
            >
              <ExitIcon />
            </button>
          ))}
      </td>
    </tr>
  );
};
