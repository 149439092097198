import {
  SLIDE_CONTAINER_ID,
  TOTAL_MARGIN_LIVE_MEETING_NAVIGATION_ARROWS,
} from '../helper/constants';
import { useWindowSize } from '../helper/windowSizeChangeHandler';
import { useSlideSize } from './useSlideSize';
import { useEffect, useState } from 'react';

const getTransformedLeftSlideMargin = (
  windowWidth,
  slideWidth,
  transformScale
) => (windowWidth - slideWidth * transformScale) / 2;

export const useSidebarSceneSplit = (
  initialScale,
  isNavigationArrowShowing,
  liveTabWidth,
  isLiveTabActive
) => {
  const { width: windowWidth } = useWindowSize();

  const { element } = useSlideSize(SLIDE_CONTAINER_ID);

  const slideWidth = element
    ? parseFloat(initialScale) * element.clientWidth
    : windowWidth;

  const slideMargin = windowWidth - slideWidth - liveTabWidth;

  const [, setTransformScale] = useState(1);

  let transformScale = 1;
  let xOffset = 0;

  const isMarginTooSmall =
    slideMargin < TOTAL_MARGIN_LIVE_MEETING_NAVIGATION_ARROWS;

  if (isNavigationArrowShowing) {
    transformScale = isMarginTooSmall
      ? (windowWidth -
          TOTAL_MARGIN_LIVE_MEETING_NAVIGATION_ARROWS -
          liveTabWidth) /
        slideWidth
      : 1;
    xOffset = isLiveTabActive
      ? isMarginTooSmall
        ? (-getTransformedLeftSlideMargin(
            windowWidth,
            slideWidth,
            transformScale
          ) +
            liveTabWidth +
            TOTAL_MARGIN_LIVE_MEETING_NAVIGATION_ARROWS / 2) /
          transformScale
        : -(windowWidth - slideWidth) / 2 + liveTabWidth + slideMargin / 2
      : 0;
  } else {
    transformScale =
      isLiveTabActive && liveTabWidth + slideWidth > windowWidth
        ? (windowWidth - liveTabWidth) / slideWidth
        : 1;
    xOffset = isLiveTabActive
      ? Math.max(
          (-getTransformedLeftSlideMargin(
            windowWidth,
            slideWidth,
            transformScale
          ) +
            liveTabWidth) /
            transformScale,
          0
        )
      : 0;
  }

  const leftSlideMargin =
    getTransformedLeftSlideMargin(windowWidth, slideWidth, transformScale) -
    liveTabWidth / 2;

  const newSlideHeight = element
    ? element.clientHeight * transformScale * parseFloat(initialScale)
    : windowWidth * transformScale;
  const newSlideWidth = slideWidth * transformScale;

  useEffect(() => {
    setTransformScale(transformScale);
  }, [transformScale]);

  return {
    leftSlideMargin,
    transformScale,
    xOffset,
    newSlideHeight,
    newSlideWidth,
  };
};
