import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const useGrantMediaPermissions = (
  updateLocalParticipantToPublisher,
  audioPersistentTrack,
  videoPersistentTrack,
  setCameraStarted
) => {
  const modalContext = useSelector((state) => state.clientDetails.modalContext);

  useEffect(() => {
    if (modalContext === 'PERMISSIONS') {
      if (audioPersistentTrack === null && videoPersistentTrack === null) {
        updateLocalParticipantToPublisher();
      }
      if (videoPersistentTrack !== null) {
        setCameraStarted(true);
      }
    }
  }, [
    modalContext,
    audioPersistentTrack,
    updateLocalParticipantToPublisher,
    videoPersistentTrack,
    setCameraStarted,
  ]);
};
