import { useCallback, useState } from 'react';
import { debounce } from 'lodash/function';
import { updateSeriesApi } from '../../../helper/api';
import { fromError, logerror } from '../../../helper/contextualLogger';

export const useChangeGuestsMutedOnStart = (series, localDispatch) => {
  const [areGuestsMutedOnStart, setAreGuestsMutedOnStart] = useState(
    series.settings.guestsStartWithAudioOff
  );

  const meetingSeriesId = series?.meetingSeriesId;

  const onChangeGuestsMutedOnStart = useCallback(
    async (newValue) => {
      try {
        const result = await updateSeriesApi(meetingSeriesId, {
          settings: {
            guestsStartWithAudioOff: newValue,
          },
        });
        if (!result);
        setAreGuestsMutedOnStart(result.settings.guestsStartWithAudioOff);
        localDispatch({ type: 'REFRESH_SERIES', series: result });
      } catch (error) {
        logerror(fromError(error));
      }
    },
    [localDispatch, meetingSeriesId]
  );

  const handleChangeGuestsMutedOnStart = debounce(
    onChangeGuestsMutedOnStart,
    1_000
  );

  return {
    handleChangeGuestsMutedOnStart,
    areGuestsMutedOnStart,
  };
};
