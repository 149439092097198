import { useFeedPage } from './useFeedPage';
import classNames from '../../helper/classNames';
import { Link } from 'react-router-dom';
import React from 'react';
import { getStaticAssetUrl } from '../../helper/getStaticAssetUrl';

const logoImageUrlBlack = getStaticAssetUrl('logo_text.png');

export const PageHeaderWhite = () => {
  const { selectedHighlight } = useFeedPage();
  const isSelectedHighlightHidden = selectedHighlight?.hidden;

  return (
    <header
      className={classNames(
        'flex items-center h-[72px] px-5 shadow-400',
        isSelectedHighlightHidden ? 'mb-0' : 'mb-12'
      )}
    >
      <Link to="/" className="block w-full max-w-[1385px] mx-auto">
        <img
          style={{
            height: '88px',
          }}
          src={logoImageUrlBlack}
          alt="icon"
        />
      </Link>
    </header>
  );
};
