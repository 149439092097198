import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
  useReducer,
} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { isMobile } from '../helper';
import {
  fetchBlocksApi,
  fetchSeriesApi,
  getMeetingListApi,
  getMeetingChatTranscript,
  fetchMeetingVoiceTranscript,
  getMeetingEventsApi,
} from '../helper/api';
import { hasSummary, SummaryRenderer } from '../blocks';
import { getStaticAssetUrl } from '../helper/getStaticAssetUrl';
import HlsVideoPlayer from './HlsVideoPlayer';
import { SecondaryText, H3Text } from './common/Text';
import GradientBanner from './series/GradientBanner';
import {
  FloatingWindow,
  FloatingWindowBorder,
  FloatingWindowContainer,
  HeaderWrapper,
} from './series/shared.styled';
import mixpanel from 'mixpanel-browser';
import { getMeetingProps, getMeetingSeriesProps } from 'zync-common/tracking';
import { GradientBackground } from './GradientBackground';
import { CopyIcon } from './icons/CopyIcon';
import { urlLinkStyle } from './series/MeetingWindow';
import { UrlLink } from './UrlLink';
import {
  Link,
  useParams,
  NavLink,
  useLocation,
  matchPath,
  useHistory,
} from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import classNames from '../helper/classNames';
import { DefaultNavbar } from '../pages/Navbar/DefaultNavbar';
import { pageTitles } from '../pages/Navbar/NavbarComponents';
import { Page404 } from '../pages/page404';
import { Page403 } from '../pages/page403';
import { isUserMeetingController } from '../helper/roles';
import { isRolesAGuest } from '../helper/roles';
import { SeriesVisibility } from '../helper/visibility';
import { logerror, withCatchAllAndLogging } from '../helper/contextualLogger';
import { ZyncErrorModal } from './ZyncErrorModal';
import { ZyncLoadingModal } from './ZyncLoadingModal';
import { convertUTCToTimezone, getLocalTimezoneName } from '../helper/rsvp';
import { Button } from './common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSVLink } from 'react-csv';
import {
  Highlight,
  marketingOptions,
  doesMediaHaveTargetSettingsAndAspectRatio,
  DownloadLink,
  ClipRecording,
  DownloadAssets,
} from './Highlights';
import { useRichTextEditor } from '../hooks/useRichTextEditor';
import { ToastOutlet } from './ToastOutlet';
import { getUsersCSV } from '../pages/Series/Permissions';
import { GearIcon } from './icons/GearIcon';
import { highlightTypes } from 'zync-common/types';
import { ProcessingContentKitView } from '../pages/EventLanding/Publish/ProcessingContentKitView';
import { isContentKitCompleted } from 'zync-common/helper/contentKit';
import { PLANS } from 'zync-common/zyncCustomerPlans';
import { useWorkspacePlan } from '../hooks/useWorkspacePlan';

const errorMobileUnsupported = getStaticAssetUrl(
  'error-mobile-unsupported.svg'
);

export const Border = styled.div`
  background: #ffffff;
  border: 1px solid #f4f4f4;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(102, 102, 102, 0.25);
  border-radius: 8px;
  padding: 10px;
  margin: 16px 0px;
  width: 710px;
`;
export const PastMeetingsContainer = styled.div`
  width: 254px;
  background: #fff;

  color: #3d3d46;
  padding: 37px 0 24px;
  overflow: hidden;

  .meetings-list {
    padding-left: 42px;
  }

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 34px;
    text-align: left;
    margin-bottom: 18px;
    position: absolute;
    top: 37px;
    z-index: 100;
    background-color: #fff;
  }

  .item {
    width: 156px;
    height: 66px;
    background: #fafbfb;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 12px;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 16px;
    position: relative;
    text-decoration: none;
    color: #3d3d46;

    &.active {
      background: rgba(0, 163, 165, 0.2);
    }
  }

  .item .day {
    font-weight: 600;
  }
`;

export const TimeDisplay = ({ startTime, endTime }) => {
  return (
    <SecondaryText
      style={{
        // color: '#828282',
        // fontSize: '15px',
        // lineHeight: '22px',
        // fontWeight: '600',
        margin: '8px 0',
      }}
    >
      {startTime && <span>{moment(startTime).format('LT')}</span>}
      {endTime && <span> - {moment(endTime).format('LT')}</span>}
    </SecondaryText>
  );
};

export const Recording = ({ recording, meetingId, userId }) => {
  const { recordingStart, durationSeconds, files = [], playbackId } = recording;
  const startTime = new Date(recordingStart);
  const endTime = new Date(startTime.getTime() + durationSeconds * 1000);
  const getFileDisplayName = (file) => file.name.split('.')[0];

  const sortedFiles = useMemo(() => {
    const result = [...files];
    const getRank = (name) => {
      switch (name) {
        case 'high.mp4':
          return 1;
        case 'medium.mp4':
          return 2;
        case 'low.mp4':
          return 3;
        default:
          return 4;
      }
    };
    result.sort((f1, f2) => getRank(f1.name) - getRank(f2.name));
    return result;
  }, [files]);

  return (
    <div>
      {recordingStart && (
        <TimeDisplay startTime={startTime} endTime={endTime}></TimeDisplay>
      )}
      <HlsVideoPlayer
        className="pt-1 px-0 pb-3"
        src={`https://stream.mux.com/${playbackId}.m3u8`}
        poster={`https://image.mux.com/${playbackId}/thumbnail.jpg?&width=800&height=450`}
        meetingId={meetingId}
        userId={userId}
      ></HlsVideoPlayer>
      {files.length ? (
        <>
          Download:{' '}
          {sortedFiles.map((file) => (
            <span key={file.name}>
              <a
                style={{ textTransform: 'capitalize' }}
                key={file.name}
                href={`https://stream.mux.com/${playbackId}/${file.name}?download=Zync%20Meeting`}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                {getFileDisplayName(file)}
              </a>{' '}
            </span>
          ))}
        </>
      ) : (
        <>
          Recordings are currently processing and will be available for download
          soon.
        </>
      )}
    </div>
  );
};

export const highlightsReducer = (state, action) => {
  switch (action.type) {
    case 'INITIALIZE_HIGHLIGHTS': {
      return {
        ...state,
        ...action.highlights,
      };
    }
    case 'UPDATE_SOCIAL_MEDIA_POST_TEXT': {
      const { meetingId, highlightId, socialMediaType, value, property } =
        action;

      const newHighlights = state[meetingId].map((highlight) => {
        if (highlight.highlightId === highlightId) {
          highlight['socialMediaPosts'][socialMediaType][property] = value;
        }
        return highlight;
      });

      return {
        ...state,
        [state[meetingId]]: newHighlights,
      };
    }
    case 'GENERATE_SOCIAL_MEDIA_ASSET': {
      const { meetingId, highlightId, mediaType, renderData, settings } =
        action;

      const newHighlights = state[meetingId].map((highlight) => {
        if (highlight.highlightId === highlightId) {
          highlight.media.push({
            aspectRatio: mediaType,
            renderData,
            settings,
          });
        }
        return highlight;
      });

      return {
        ...state,
        [state[meetingId]]: newHighlights,
      };
    }
    case 'REFRESH_MEDIA': {
      const { meetingId, highlightId, newMedia, aspectRatio, settings } =
        action;

      const newHighlights = state[meetingId].map((highlight) => {
        if (highlight.highlightId === highlightId) {
          highlight.media = highlight.media.filter((m) => {
            if (
              doesMediaHaveTargetSettingsAndAspectRatio(
                m,
                aspectRatio,
                settings
              )
            ) {
              return false;
            } else {
              return true;
            }
          });

          highlight.media.push(newMedia);
        }
        return highlight;
      });

      return {
        ...state,
        [state[meetingId]]: newHighlights,
      };
    }
    case 'DELETE_HIGHLIGHT': {
      const { meetingId, highlightId } = action;

      const newHighlights = state[meetingId].map((highlight) => {
        if (highlight.highlightId === highlightId) {
          highlight.hidden = true;
          return highlight;
        } else {
          return highlight;
        }
      });

      return {
        ...state,
        [state[meetingId]]: newHighlights,
      };
    }
    case 'DELETE_MARKETING_ASSET': {
      const { meetingId, highlightId, assetId } = action;
      const newHighlights = state[meetingId].map((highlight) => {
        if (highlight.highlightId === highlightId) {
          highlight.media = highlight.media.filter(
            (m) => m.renderData.assetId !== assetId
          );
        }
        return highlight;
      });

      return {
        ...state,
        [state[meetingId]]: newHighlights,
      };
    }
    default:
      return state;
  }
};

const highlightsOrder = {
  [highlightTypes.INTERVIEW]: 1,
  [highlightTypes.QA]: 2,
  [highlightTypes.RAISE_HAND]: 3,
  [highlightTypes.WORD_CLOUD]: 4,
  [highlightTypes.WELCOME_GAME]: 5,
  [highlightTypes.COMMS_GAME]: 6,
  [highlightTypes.SCENE]: 7,
};

const SummaryPage = () => {
  const history = useHistory();
  const [meetingSeries, setMeetingSeries] = useState(null);
  const [meetingList, setMeetingList] = useState(null);
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [highlightsState, highlightsDispatch] = useReducer(
    highlightsReducer,
    {}
  );

  const { user, authenticated } = useSelector((_st) => _st.auth, shallowEqual);
  let { userId, emailAddress } = user || {};
  const location = useLocation();
  const currentUrl = location?.pathname;

  const getMeetingSeries = useCallback(
    async (meetingSeriesId) => {
      try {
        const result = await fetchSeriesApi(meetingSeriesId);
        if (!result) {
          logerror({
            userId,
            meetingSeriesId,
            message:
              'Error attempting to call fetchSeriesApi inside of SummaryPage.jsx - No result from api call.',
          });
          return;
        }
        return result;
      } catch (err) {
        logerror({
          userId,
          meetingSeriesId,
          message:
            'Error attempting to call fetchSeriesApi inside of SummaryPage.jsx, ' +
            err.message,
          stacktrace: err.stack || err,
        });
      }
    },
    [userId]
  );
  const getMeetingList = useCallback(
    async (meetingSeriesId) => {
      try {
        const result = await getMeetingListApi(meetingSeriesId);
        if (!result) {
          logerror({
            userId,
            meetingSeriesId,
            message:
              'Error attempting to call getMeetingListApi inside of SummaryPage.jsx - No result from api call.',
          });
          return [];
        }

        if (result.length > 0) {
          result.sort(
            (a, b) => (b.state || {}).endTime - (a.state || {}).endTime
          );

          const highlights = result.reduce((prevHighlights, meeting) => {
            if (meeting.highlights.length > 0) {
              return {
                ...prevHighlights,
                [meeting.meetingId]: meeting.highlights
                  .map((highlight) => {
                    Object.keys(marketingOptions).forEach((option) => {
                      if (!highlight['socialMediaPosts'][option]) {
                        highlight['socialMediaPosts'][option] = {
                          postTitle: '',
                        };
                      }
                    });
                    return highlight;
                  })
                  .sort(
                    (a, b) => highlightsOrder[a.type] - highlightsOrder[b.type]
                  ),
              };
            } else {
              return prevHighlights;
            }
          }, {});

          highlightsDispatch({ type: 'INITIALIZE_HIGHLIGHTS', highlights });
        }
        return result;
      } catch (err) {
        logerror({
          userId,
          meetingSeriesId,
          message:
            'Error attempting to call getMeetingListApi inside of SummaryPage.jsx, ' +
            err.message,
          stacktrace: err.stack || err,
        });
        return [];
      }
    },
    [userId]
  );

  const baseHighlightsUrl = matchPath(currentUrl, {
    path: '/e/:seriesId/highlights',
    exact: true,
  });

  const { meetingSeriesId, meetingId } = useParams();

  useEffect(() => {
    const updateMeetingInfo = async () => {
      const ms = await getMeetingSeries(meetingSeriesId);
      const ml = await getMeetingList(meetingSeriesId);
      if (meetingId) {
        setIsLoadingPage(false);
      }
      setMeetingSeries(ms);
      setMeetingList(ml);
    };
    updateMeetingInfo();
  }, [meetingSeriesId, meetingId, getMeetingList, getMeetingSeries]);

  const completedMeetingWithSummaries = useMemo(
    () =>
      (meetingList || [])
        .filter(
          (m) =>
            !m.hideFromSummaryList && (m?.state?.completed || m?.state?.running)
        )
        .sort((a, b) => {
          if (!a?.state?.running && !b?.state?.running) {
            return new Date(a.creationTime) < new Date(b.creationTime);
          } else if (a?.state?.running) {
            return -1;
          } else {
            return 1;
          }
        }),
    [meetingList]
  );

  const { meetingSeriesUrl } = meetingSeries || {};
  let selectedMeetingState = meetingId
    ? (completedMeetingWithSummaries || []).find(
        (m) => m.meetingId === meetingId
      )
    : completedMeetingWithSummaries[0];

  useEffect(() => {
    if (!meetingSeries) return;
    mixpanel.track('Series - Highlights Page View', {
      ...withCatchAllAndLogging(getMeetingSeriesProps)(meetingSeries),
    });
  }, [meetingSeries]);

  useEffect(() => {
    if (!completedMeetingWithSummaries) return;
    mixpanel.track('Series - Highlights View Meeting', {
      ...withCatchAllAndLogging(getMeetingSeriesProps)(meetingSeries),
      ...withCatchAllAndLogging(getMeetingProps)(
        completedMeetingWithSummaries,
        userId
      ),
    });
  }, [meetingSeries, completedMeetingWithSummaries, userId]);

  const isHighlightSelected = !!meetingId;
  const firstMeetingSummaryId = completedMeetingWithSummaries[0]?.meetingId;

  useEffect(() => {
    if (!isHighlightSelected && firstMeetingSummaryId) {
      history.replace(
        `/e/${meetingSeriesId}/highlights/${firstMeetingSummaryId}`
      );
    }
  }, [history, isHighlightSelected, firstMeetingSummaryId, meetingSeriesId]);

  /**
   * Show loading logo while the meeting series and meeting list is being fetched.
   */

  const { Editor } = useRichTextEditor({
    initialValue: meetingSeries?.title,
  });

  if (isLoadingPage) {
    return <ZyncLoadingModal message={'Powering up'} />;
  }

  /**
   * Handles Highlight Permission logic.
   */
  const currentAttendee = meetingSeries?.attendees?.find(
    (attendee) => attendee?.emailAddress === emailAddress
  );

  const hasModeratorPrivileges = isUserMeetingController(currentAttendee);

  const hidePastMeetings = !authenticated || !hasModeratorPrivileges;

  const isUserGuestAttendee = (currentAttendee) => {
    if (!currentAttendee) {
      return false;
    }
    return isRolesAGuest(currentAttendee?.roles ?? []);
  };

  /**
   *  Reasons to show 404 page are as follows:
   *
   *  If there is no meetingId in the meeting list matching meetingId from the url param.
   *
   */

  const shouldShowPageNotFound = () => {
    if (!selectedMeetingState) {
      return true;
    }

    return false;
  };

  /**
   *  Reasons to show 403 page are as follows:
   *
   *  The base url is hit, but the user is not a moderator.
   *  A user is accessing a non-public meeting and they are not a guest.
   */
  const shouldShowAccessDeniedPage = () => {
    if (baseHighlightsUrl && !hasModeratorPrivileges) {
      return true;
    }

    if (
      !hasModeratorPrivileges &&
      meetingSeries?.visibility !== SeriesVisibility.PUBLIC &&
      !isUserGuestAttendee(currentAttendee)
    ) {
      return true;
    }
    return false;
  };

  if (shouldShowPageNotFound()) {
    return <Page404 />;
  } else if (shouldShowAccessDeniedPage()) {
    return <Page403 />;
  }

  if (isMobile) {
    return (
      <ZyncErrorModal
        title="Mobile device unsupported"
        message="The page you are accessing requires you to be on a desktop browser. Please try again after switching your device."
        img={<img src={errorMobileUnsupported} alt="" />}
      />
    );
  }

  return (
    <div className="min-h-min">
      <ToastOutlet isMobile={+isMobile} />
      <DefaultNavbar
        currentPage={pageTitles.highlights}
        previousRoute={`/e/${meetingSeriesId}`}
      />
      <div className="flex">
        <div
          className={classNames(
            'min-w-fit max-h-screen fixed bg-white',
            hidePastMeetings && 'hidden'
          )}
        >
          <PastMeetingsContainer className="past-meetings">
            <div className="mt-10 h-[80vh] overflow-scroll">
              {completedMeetingWithSummaries.map((meeting, i) => {
                const { meetingId, state: summaryState, isPreview } = meeting;
                const { startTime: summaryStartTime } = summaryState || {};

                return (
                  <div className="meetings-list" key={i}>
                    {i === 0 && <div className="title">Past Meetings</div>}
                    <NavLink
                      key={meetingId}
                      className="item"
                      activeClassName="active"
                      to={`/e/${meetingSeriesId}/highlights/${meetingId}`}
                    >
                      <div className="flex justify-between items-baseline">
                        <div>
                          <div className="day">
                            {moment(summaryStartTime).format('MMM D')}
                          </div>
                          {moment(summaryStartTime).format('LT')}
                        </div>
                        {isPreview ? (
                          <span className="uppercase text-purple text-sm font-medium">
                            Preview
                          </span>
                        ) : null}
                      </div>
                    </NavLink>
                  </div>
                );
              })}
            </div>
          </PastMeetingsContainer>
        </div>
        <div
          className={classNames(
            'flex flex-col w-full',
            hidePastMeetings ? 'ml-0' : 'ml-60'
          )}
        >
          <div>
            <div className="flex flex-col">
              <GradientBanner
                style={{
                  position: 'relative',
                  top: '-90px',
                }}
              />
              <FloatingWindowContainer>
                <FloatingWindow>
                  <FloatingWindowBorder>
                    <GradientBackground />
                    <HeaderWrapper>
                      <div className="flex space-x-4 items-center">
                        <h1 className="font-bold text-[30px] text-blue-dark">
                          {Editor}
                        </h1>
                        {/* TODO - implement DELETE when it is necessary. Leaving uncommented code so that it won't dissapear */}
                        {/*{selectedMeetingSummary?.isPreview ? (*/}
                        {/*  <Button*/}
                        {/*    color={Button.colors.PURPLE_LITE}*/}
                        {/*    padding={Button.padding.NONE}*/}
                        {/*  >*/}
                        {/*    <span className="uppercase px-2 py-1 text-sm font-medium">*/}
                        {/*      Delete*/}
                        {/*    </span>*/}
                        {/*  </Button>*/}
                        {/*) : null}*/}
                      </div>
                      <div className="flex flex-1">
                        <div className="flex-1">
                          <UrlLink
                            url={meetingSeriesUrl}
                            textStyle={urlLinkStyle}
                          >
                            <CopyIcon />
                          </UrlLink>
                        </div>
                        <Link to={`/e/${meetingSeriesId}`}>
                          Back to Meeting Home
                        </Link>
                      </div>
                      {selectedMeetingState?.isPreview ? (
                        <p className="uppercase text-red text-sm font-medium">
                          These are highlights of a preview event. They are
                          temporary and will dissapear after some time.
                        </p>
                      ) : null}
                    </HeaderWrapper>
                  </FloatingWindowBorder>
                </FloatingWindow>
              </FloatingWindowContainer>
            </div>
          </div>
          <div
            className="meeting-summary"
            style={{
              height: '100%',
              marginLeft: '56px',
            }}
          >
            <h2 className="text-black">Event Highlights</h2>
            <div>
              <Summary
                meetingState={selectedMeetingState}
                meetingSeries={meetingSeries}
                meetingId={meetingId}
                hasModeratorPrivileges={hasModeratorPrivileges}
                highlights={highlightsState[meetingId]}
                highlightsDispatch={highlightsDispatch}
                userId={userId}
                contentKitCompleted={isContentKitCompleted(
                  highlightsState[meetingId]
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const getCSVDataFromChatTranscript = (chatTranscript) => {
  const data = [['Time', 'Username', 'Message']];
  chatTranscript.forEach((messageObject) => {
    const { timestamp, userName, message } = messageObject;
    data.push([timestamp, userName, message]);
  });
  return data;
};

const userDataCSVEventTargets = {
  Wordcloud: {
    actionTypes: ['SUBMIT_WORD'],
  },
  'Opinion Poll': {
    actionTypes: ['SUBMIT_POLL'],
  },
  'Card List': {
    actionTypes: ['ADD_CARD', 'LIKE_CARD'],
  },
  Chat: {
    actionTypes: ['CHAT_MESSAGE'],
  },
  Reaction: {
    actionTypes: ['USER_REACTION'],
  },
};

const getCSVDataFromUsers = (users, meetingEvents) => {
  const eventTargets = Object.keys(userDataCSVEventTargets);
  const targetActionTypes = [];
  Object.values(userDataCSVEventTargets).forEach((value) => {
    const { actionTypes } = value;
    targetActionTypes.push(...actionTypes);
  });
  const userIdToTargetEvents = {};

  meetingEvents.forEach((e) => {
    const { sendingUserId, actionType } = e;
    if (!targetActionTypes.includes(actionType)) return;
    if (userIdToTargetEvents[sendingUserId]) {
      userIdToTargetEvents[sendingUserId].push(e);
    } else {
      userIdToTargetEvents[sendingUserId] = [e];
    }
  });

  const data = [
    ['Email', 'Username', 'Roles', 'Join Time', 'Leave Time', ...eventTargets],
  ];

  Object.values(users).forEach((user) => {
    const {
      emailAddress = null,
      userName,
      roles,
      joinTime = null,
      leaveTime = null,
      userId,
    } = user;

    const userData = [
      emailAddress || 'N/A',
      userName,
      roles.join(', '),
      joinTime ? joinTime.toString() : 'N/A',
      leaveTime ? leaveTime.toString() : 'N/A',
    ];

    if (!userIdToTargetEvents?.[userId]) {
      userData.push(...Array(eventTargets.length).fill(0));
    } else {
      const userEvents = userIdToTargetEvents[userId];
      eventTargets.forEach((e) => {
        const targetActionTypes = userDataCSVEventTargets[e].actionTypes;
        const count = userEvents.filter((e) =>
          targetActionTypes.includes(e.actionType)
        ).length;
        count > 0 ? userData.push(1) : userData.push(0);
      });
    }

    data.push(userData);
  });

  return data;
};

export const isUnableToGenerateContentKit = (recordings, transcript) => {
  if (recordings.length > 0) {
    if (recordings[0].durationSeconds < 120) {
      if (recordings[0].files.length === 0) return false;
      return 'Recording must be at least 2 minutes.';
    }
    if (!transcript || transcript?.length === 0) {
      return 'Recording must have a transcript.';
    }
    return false;
  }

  return 'There is no recording available.';
};

const Section = ({ children }) => (
  <div className="md:w-[953px] md:w-max-[953px] w-full p-5 relative bg-white rounded-lg border-[#EBEAED] border">
    {children}
  </div>
);

const SectionTitle = ({ title }) => (
  <div className="font-medium text-xs text-blue-dark">{title}</div>
);

const sadRobotUrl = getStaticAssetUrl('sad-robot.png');

export const Summary = ({
  compact = false,
  meetingState,
  meetingSeries,
  meetingId,
  hasModeratorPrivileges,
  highlights,
  highlightsDispatch,
  userId,
  hasGenerateHighlightsPermissions,
  contentKitCompleted,
}) => {
  const { state, blocks, recording, trimmedRecording } = meetingState || {};
  const { streaming, startTime, endTime, users, completed } = state || {};
  const { attendees, visibility, scheduledEvent, meetingSeriesId, workspace } =
    meetingSeries || {};
  const { brandKit } = workspace || {};
  const { registeredAttendees } = scheduledEvent || {};
  const guestAttendees =
    visibility === SeriesVisibility.RSVP ? registeredAttendees : attendees;
  const hasRecordings = !!recording?.liveStreamId;
  const hasHighlights = highlights?.length > 0;
  const hasTrimmedRecordings =
    Object.entries(trimmedRecording || {}).length > 0;
  const recordings = Object.values(streaming?.assets || {});

  const { isTrial, plan } = useWorkspacePlan();

  const showWatermark = plan === PLANS.free.id && !isTrial;

  recordings
    .sort((a, b) => new Date(a.recordingStart) - new Date(b.recordingStart))
    .forEach((recording, index) => {
      if (hasTrimmedRecordings && trimmedRecording[recording.assetId]) {
        recordings[index] = {
          ...recording,
          ...trimmedRecording[recording.assetId],
        };
      }
    });

  const [blocksMap, setBlocksMap] = useState();
  const [showSummaries, setShowSummaries] = useState();
  const [chatTranscript, setChatTranscript] = useState();
  const [transcript, setTranscript] = useState([]);
  const [meetingEvents, setMeetingEvents] = useState([]);
  const CSVLinkRef = useRef(null);
  const localTimezone = getLocalTimezoneName();
  const unableToGenerateContentKit = isUnableToGenerateContentKit(
    recordings,
    transcript
  );
  const isContentKitProcessing =
    completed && !unableToGenerateContentKit && !contentKitCompleted;

  const queryParams = new URLSearchParams(useLocation().search);
  const queryHighlightId = queryParams.get('highlightId');
  const querySocialMedia = queryParams.get('socialMedia');

  useEffect(() => {
    if (blocksMap && blocks) {
      const blockData = {};
      const updateSummaryStatus = async (blocks) => {
        for (const block in blocks) {
          const generatedBlock = blocks[block];
          const blockId = generatedBlock['blockId'];
          if (!blocksMap[blockId]) continue;
          const clientSide = blocksMap[blockId]['code']['clientSide'];
          const containsSummary = await hasSummary(clientSide, generatedBlock);
          if (containsSummary) {
            blockData[generatedBlock?.blockInstanceId] = containsSummary;
          }
        }
        setShowSummaries(blockData);
      };
      updateSummaryStatus(blocks);
    }
  }, [blocks, blocksMap]);

  useEffect(() => {
    const fetchBlocks = async () => {
      const bList = await fetchBlocksApi();
      const bMap = {};
      bList.forEach((b) => {
        bMap[b.blockId] = b;
      });
      setBlocksMap(bMap);
    };
    fetchBlocks();
  }, []);

  useEffect(() => {
    const getChatTranscript = async () => {
      const chatTranscript = await getMeetingChatTranscript(meetingId);
      const processedChatTranscript = chatTranscript.map((messageObject) => {
        return {
          ...messageObject,
          timestamp: convertUTCToTimezone(
            messageObject.timestamp,
            localTimezone,
            'time'
          ),
        };
      });
      setChatTranscript(processedChatTranscript);
    };
    getChatTranscript();
  }, [localTimezone, meetingId]);

  useEffect(() => {
    if (queryHighlightId) {
      setTimeout(() => {
        const element = document.getElementById(queryHighlightId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 500);
    }
  }, [queryHighlightId]);

  useEffect(() => {
    const getVoiceTranscript = async () => {
      const { error, messages } = await fetchMeetingVoiceTranscript(meetingId);
      if (error || messages.length === 0) return;
      setTranscript(messages);
    };
    const getMeetingEvents = async () => {
      const { error, meetingEvents } = await getMeetingEventsApi(meetingId);
      if (error || meetingEvents.length === 0) return;
      setMeetingEvents(meetingEvents);
    };
    getVoiceTranscript();
    getMeetingEvents();
  }, [meetingId]);

  return (
    <div className="flex flex-col md:gap-12 gap-5 pb-12 md:w-[953px] w-full md:px-0 px-[15px]">
      {contentKitCompleted && (
        <Section>
          <div className="w-full flex flex-col gap-1">
            <div className="w-full flex justify-between">
              <div className="flex flex-col justify-between gap-[5px]">
                <SectionTitle title="Attendees" />
                <div className="font-medium text-sm text-blue-gray">
                  {startTime && moment(startTime).format('LT')} -
                  {endTime && moment(endTime).format('LT')}
                </div>
              </div>
              <div className="flex md:gap-4 gap-[15px] items-start md:flex-row flex-col">
                <CSVLink
                  data={getCSVDataFromUsers(users, meetingEvents)}
                  filename={`Attendees list - ${meetingId}`}
                  target="_blank"
                >
                  <DownloadLink
                    text={`Attendance Report (${
                      Object.values(users || {}).length
                    })`}
                  />
                </CSVLink>
                <CSVLink
                  data={getUsersCSV(guestAttendees, visibility, localTimezone)}
                  filename={`Registered users list - ${meetingSeries.meetingSeriesId}`}
                  target="_blank"
                >
                  <DownloadLink
                    text={`Registration Report (${guestAttendees.length})`}
                  />
                </CSVLink>
              </div>
            </div>
          </div>
        </Section>
      )}

      {hasRecordings &&
        (hasGenerateHighlightsPermissions || contentKitCompleted) &&
        recordings.map((recording, index) => (
          <Section key={recording.assetId}>
            <div className="w-full flex flex-col gap-1">
              <SectionTitle title="Event Recap" />
              <div className="flex md:gap-5 gap-[30px] md:flex-row flex-col">
                <div className="md:w-[440px] md:h-[224px] w-full">
                  <ClipRecording
                    playbackId={recording.playbackId}
                    className="h-full w-full rounded-lg"
                    userId={userId}
                    meetingId={meetingId}
                  />
                </div>
                <div className="flex flex-col gap-[30px]">
                  <DownloadAssets
                    clipText="Event Recording"
                    clipFileName={`Event Recording - ${meetingId} - #${index}`}
                    playbackId={recording.playbackId}
                    clipFiles={recording.files}
                    transcript={transcript}
                    localTimezone={localTimezone}
                  />
                  <div className="flex flex-col gap-[15px]">
                    <div className="font-medium text-blue-dark text-xs">
                      Create Marketing Assets
                    </div>
                    <Link
                      to={`/e/${meetingSeriesId}/ondemand/${meetingId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="flex gap-1 decoration-blue-gray underline">
                        <GearIcon />
                        <div className="text-blue-gray font-medium text-xs">
                          On Demand Page
                        </div>
                      </div>
                    </Link>
                    {/*hasGenerateHighlightsPermissions &&
                      recording?.assetId &&
                      recording?.playbackId &&
                      recording?.files?.length > 0 && (
                        <Button
                          color={Button.colors.PURPLE}
                          onClick={() =>
                            startPostRecordingActionsApi(
                              meetingSeriesId,
                              meetingId
                            )
                          }
                        >
                          <div className="text-xs">GENERATE HIGHLIGHTS</div>
                        </Button>
                        )*/}
                  </div>
                </div>
              </div>
            </div>
          </Section>
        ))}

      {hasHighlights && contentKitCompleted && (
        <div className="flex flex-col md:gap-12 gap-5 md:w-[710px] w-full">
          {highlights.map((highlight, index) => (
            <Highlight
              highlight={highlight}
              key={highlight.highlightId}
              index={index}
              recordings={recordings}
              meetingId={meetingId}
              hasModeratorPrivileges={hasModeratorPrivileges}
              highlightsDispatch={highlightsDispatch}
              querySocialMedia={querySocialMedia}
              queryHighlightId={queryHighlightId}
              transcript={transcript}
              localTimezone={localTimezone}
              brandKit={brandKit}
              userId={userId}
              showWatermark={showWatermark}
            />
          ))}
        </div>
      )}

      {blocksMap &&
        contentKitCompleted &&
        Object.values(blocks || {}).map((block, i) => {
          const { start_time } = block || {};
          const blockMetadata = blocksMap[block.blockId];
          const { code, details } = blockMetadata || {};
          const { title } = details || {};

          return (
            showSummaries &&
            showSummaries[block?.blockInstanceId] && (
              <Border style={{ width: compact ? '100%' : '710px' }} key={i}>
                <H3Text>{title}</H3Text>
                <TimeDisplay startTime={start_time} />
                <SummaryRenderer
                  code={code}
                  block={block}
                  compact={compact}
                  liveMeetingSummary={!meetingSeries}
                />
              </Border>
            )
          );
        })}

      {chatTranscript?.length > 0 && contentKitCompleted && (
        <Border style={{ width: compact ? '100%' : '710px' }}>
          <div className="flex justify-between items-center">
            <H3Text>Chat Transcript</H3Text>
            <Button
              color={Button.colors.PURPLE}
              onClick={() => CSVLinkRef.current.download}
              padding={Button.padding.NONE}
            >
              <CSVLink
                data={getCSVDataFromChatTranscript(chatTranscript)}
                filename={`Chat Transcript - ${meetingId}`}
                target="_blank"
                ref={CSVLinkRef}
              >
                <div className="text-sm text-white font-medium flex gap-3 px-2 py-1">
                  <FontAwesomeIcon
                    icon={['fas', 'external-link-alt']}
                    size="lg"
                  />
                  <div>Download Chat Transcript</div>
                </div>
              </CSVLink>
            </Button>
          </div>
          <div className="flex flex-col gap-2.5 mt-6 mb-2.5 max-h-96 overflow-auto purple-scrollbar">
            {chatTranscript.map((messageObject, index) => {
              const { timestamp, userName, message } = messageObject;
              return (
                <div className="flex flex-col gap-2.5" key={timestamp + index}>
                  <div className="flex items-baseline gap-2">
                    <div className="font-medium text-sm text-blue-dark">
                      {userName}
                    </div>
                    <div className="font-light text-xxs text-blue-gray">
                      {timestamp}
                    </div>
                  </div>
                  <div className="font-light text-xs text-blue-gray">
                    {message}
                  </div>
                </div>
              );
            })}
          </div>
        </Border>
      )}
      {isContentKitProcessing && <ProcessingContentKitView />}

      {unableToGenerateContentKit && (
        <div className="flex flex-col items-center">
          <div className="font-semibold text-blue-dark">
            Unable to generate content kit. {unableToGenerateContentKit}
          </div>
          <img
            src={sadRobotUrl}
            alt="unable to generate content kit"
            className="object-contain w-128 h-128"
          />
        </div>
      )}
    </div>
  );
};

export default SummaryPage;
