import React, { useState } from 'react';
import { Roles } from '../helper/roles';
import { AddRolePopupWindow } from './meetingTabs/Invite/AddRolePopupWindow';
import { RoleTag } from './meetingTabs/Invite/RoleTag';
import {
  AddRoleButton,
  RoleTagGroupDiv,
} from './meetingTabs/Invite/InviteTab.styled';

const roleBackgroundColors = [
  `bg-blue`,
  'bg-red',
  'bg-blue-light',
  'bg-orange',
  'bg-orange-light',
];

const roleBackgroundOpacity = 'bg-opacity-20';

export const RolesInput = ({
  canEdit,
  user,
  roleSuggestions,
  lockedRoles = [Roles.MODERATOR],
  handleAddUserRole,
  onRoleRemove,
  darkTheme = false,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const isRoleLocked = (role) => lockedRoles.includes(role);

  return (
    <>
      <RoleTagGroupDiv darkTheme={darkTheme}>
        {user.roles.map((roleName, idx) => (
          <RoleTag
            key={roleName}
            roleName={roleName}
            onTagRemoveClick={() => onRoleRemove(roleName)}
            canEditRole={canEdit && !isRoleLocked(roleName)}
            roleBackgroundColor={
              roleBackgroundColors[idx % roleBackgroundColors.length]
            }
            roleBackgroundOpacity={roleBackgroundOpacity}
          />
        ))}
        {canEdit && (
          <div className="hidden md:block">
            <AddRoleButton onClick={() => setModalOpen(true)}>+</AddRoleButton>
          </div>
        )}
      </RoleTagGroupDiv>
      <AddRolePopupWindow
        user={user}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        lockedRoles={lockedRoles}
        roleSuggestions={roleSuggestions}
        onAddUserRole={handleAddUserRole}
      />
    </>
  );
};
