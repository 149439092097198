import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchShowDataApi } from '../../helper/api';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export const useShowPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { workspaceId } = useParams();
  const [showPageData, setShowPageData] = useState(null);
  const queryParams = new URLSearchParams(useLocation().search);
  const queryParamWorkspaceId = queryParams.get('workspaceId');

  useEffect(() => {
    if (!workspaceId && !queryParamWorkspaceId) return;
    const fetchShowPageData = async () => {
      const result = await fetchShowDataApi(
        workspaceId || queryParamWorkspaceId
      );
      if (!result) {
        setIsLoading(false);
        return;
      }
      const { showPage } = result;
      setShowPageData(showPage);
      setIsLoading(false);
    };
    fetchShowPageData();
  }, [queryParamWorkspaceId, workspaceId]);

  const refreshShowPageData = useCallback(async () => {
    const result = await fetchShowDataApi(workspaceId || queryParamWorkspaceId);
    if (!result) {
      return;
    }
    const { showPage } = result;
    setShowPageData(showPage);
  }, [workspaceId, queryParamWorkspaceId]);

  return {
    showPageData,
    isLoading,
    workspaceId,
    refreshShowPageData,
  };
};
