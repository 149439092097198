const swap = (list = [], currentIndex, nextIndex) => {
  if (nextIndex < 0 || nextIndex > list.length - 1) {
    return;
  }

  const currentItem = list[currentIndex];
  const nextItem = list[nextIndex];

  list[currentIndex] = nextItem;
  list[nextIndex] = currentItem;
};

const moveToBottom = (list, index) => {
  const item = list[index];

  list.splice(index, 1);
  list.unshift(item);
};

const moveToTop = (list, index) => {
  const item = list[index];

  list.splice(index, 1);
  list.push(item);
};

const slideBlocksSwap = ({ blockInstanceId, slideBlocks, direction }) => {
  const currentBlockIndex = slideBlocks.findIndex(
    (sb) => sb?.blockInstanceId === blockInstanceId
  );

  if (currentBlockIndex === -1) {
    return;
  }

  if (direction === 'top') {
    moveToTop(slideBlocks, currentBlockIndex);
  }

  if (direction === 'forwards') {
    swap(slideBlocks, currentBlockIndex, currentBlockIndex + 1);
  }

  if (direction === 'backwards') {
    swap(slideBlocks, currentBlockIndex, currentBlockIndex - 1);
  }

  if (direction === 'bottom') {
    moveToBottom(slideBlocks, currentBlockIndex);
  }
};

module.exports = {
  slideBlocksSwap,
};
