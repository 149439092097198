import React, { useEffect, useMemo, useState } from 'react';
import { CheckInCircleIcon } from '../../../components/icons/CheckInCircleIcon';
import { DatePicker } from '../../../components/DatePicker';
import { DropdownSelect } from '../../../components/common/Dropdown';
import { TimePicker } from '../../../components/TimePicker';
import {
  convertUTCToTimezone,
  getDatesInUTC,
  getLocalTimezoneName,
} from '../../../helper/rsvp';
import moment from 'moment';
import classNames from '../../../helper/classNames';
import { Button } from '../../../components/common/Button';
import { updateSeriesApi } from '../../../helper/api';
import { TaskCardHeader } from './Plan';
import { ModalBody, ModalTitle, ModalWindow } from '../../../components/Modal';
import { WarningTriangleIcon } from '../../../components/icons/WarningTriangleIcon';
import { timezonesOptions } from '../../../helper/timezone-formatter';

export const CompletedButtonLabel = ({ text }) => (
  <div className="h-full w-full bg-[#531f95] flex items-center justify-center animate-[fadeInAndBack_2000ms_both]">
    <CheckInCircleIcon width={20} color="#fff" checkColor="#531f95" />
    <div className="animate-[stretchAndBack_2000ms] will-change-contents" />
    <span className="text-base">{text}</span>
  </div>
);

export const PlanScheduleCard = ({
  series,
  localDispatch,
  disableEventPage,
  areAnySpeakerInvitesSentOrAttendeesRegistered,
}) => {
  const { scheduledEvent: currentScheduledEvent } = series || {};
  const { startDate, endDate, timezone } = currentScheduledEvent || {};

  const [isConfirmComplete, setIsConfirmComplete] = useState(false);
  const [error, setError] = useState('');
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const handleChangeScheduledEvent = (key, value) => {
    setScheduledEvent((prevScheduledEvent) => ({
      ...prevScheduledEvent,
      [key]: value,
    }));
  };

  const convertedCurrentScheduledEvent = useMemo(() => {
    return {
      startTime: startDate
        ? convertUTCToTimezone(startDate, timezone, 'time', false, false)
        : '2:00 PM',
      daySelected: startDate
        ? moment(
            convertUTCToTimezone(startDate, timezone, 'date', false, false)
          )
        : moment(),
      endTime: endDate
        ? convertUTCToTimezone(endDate, timezone, 'time', false, false)
        : '3:00 PM',
      timezoneSelected: timezone || getLocalTimezoneName(),
    };
  }, [endDate, startDate, timezone]);

  const [scheduledEvent, setScheduledEvent] = useState(
    convertedCurrentScheduledEvent
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let timeout;

    if (isConfirmComplete) {
      timeout = setTimeout(() => {
        setIsConfirmComplete(false);
      }, 2_000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isConfirmComplete]);

  const handleShowConfirmationModal = () => {
    setIsConfirmationModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleConfirmDateChange = async () => {
    if (isLoading || isConfirmComplete) {
      return;
    }

    setIsLoading(true);
    setError('');

    const { startDate, endDate } = getDatesInUTC(
      scheduledEvent.daySelected,
      scheduledEvent.startTime,
      scheduledEvent.endTime,
      scheduledEvent.timezoneSelected,
      false
    );

    const result = await updateSeriesApi(series.meetingSeriesId, {
      scheduledEvent: {
        startDate,
        endDate,
        timezone: scheduledEvent.timezoneSelected,
      },
    });

    setIsConfirmComplete(true);

    setIsLoading(false);
    localDispatch({ type: 'REFRESH_SERIES', series: result });
  };

  return (
    <>
      <div className="justify-center items-center px-6">
        <TaskCardHeader className="mb-6">Schedule Event</TaskCardHeader>
        <div
          className={classNames('flex flex-col w-full gap-8')}
          style={{
            pointerEvents: disableEventPage ? 'none' : undefined,
          }}
        >
          <div className="flex gap-8">
            <div className="flex-[1_0_0%]">
              <DatePicker
                daySelected={scheduledEvent.daySelected}
                setDaySelected={(daySelected) =>
                  handleChangeScheduledEvent('daySelected', daySelected)
                }
                label="Date"
              />
            </div>
            <div className="flex flex-col gap-2 flex-[1_0_0%] w-1/2 min-w-0">
              <div className="text-blue-dark text-sm font-medium">
                Time Zone
              </div>
              <DropdownSelect
                options={timezonesOptions(scheduledEvent.daySelected)}
                selectedKey={scheduledEvent.timezoneSelected}
                onSelect={(selectKey) => {
                  handleChangeScheduledEvent('timezoneSelected', selectKey);
                }}
                fontSize={DropdownSelect.fontSize.SMALL}
                backgroundColor={DropdownSelect.backgroundColor.WHITE}
                fontColor={DropdownSelect.fontColor.BLUE_GRAY}
                borderSize={DropdownSelect.borderSize.ONE}
                borderColor={DropdownSelect.borderColor.GRAY}
                angleColor="fill-blue-gray"
                optionWidth={DropdownSelect.optionWidth.SMALL}
                menuWidth={370}
              />
            </div>
          </div>
          <div className="flex gap-8">
            <div className="flex-1">
              <TimePicker
                time={scheduledEvent.startTime}
                setTime={(time) =>
                  handleChangeScheduledEvent('startTime', time)
                }
                label="Start Time"
              />
            </div>
            <div className="flex-1">
              <TimePicker
                time={scheduledEvent.endTime}
                setTime={(time) => handleChangeScheduledEvent('endTime', time)}
                label="End Time"
              />
            </div>
          </div>

          {error && <span className="text-red font-medium">{error}</span>}

          <Button
            color={Button.colors.PURPLE}
            padding={Button.padding.SMALL}
            state={
              isLoading && !isConfirmationModalOpen
                ? Button.states.LOADING
                : isConfirmComplete &&
                  !isConfirmationModalOpen &&
                  Button.states.CONFIRM
            }
            confirmLabel={<CompletedButtonLabel text="Updated" />}
            onClick={
              areAnySpeakerInvitesSentOrAttendeesRegistered
                ? handleShowConfirmationModal
                : handleConfirmDateChange
            }
          >
            <span className="text-base p-2">Confirm</span>
          </Button>
        </div>
      </div>
      {isConfirmationModalOpen && (
        <ModalWindow width="538px" onCancel={handleCloseConfirmationModal}>
          <ModalTitle>
            <div className="flex gap-2 items-center">
              <WarningTriangleIcon /> Attention
            </div>
          </ModalTitle>
          <ModalBody>
            <p className="text-sm text-blue-gray -mt-4 mb-4">
              You're adjusting the episode time. Attendees or speakers may have
              old time on their calendars. Please inform them of this change.
            </p>
            <p className="text-sm text-blue-gray font-medium">
              The unique link in their email remains valid.
            </p>
            <div className="mt-8">
              <Button
                color={Button.colors.PURPLE}
                padding={Button.padding.SMALL}
                state={
                  isLoading ? Button.states.LOADING : Button.states.DEFAULT
                }
                onClick={async () => {
                  await handleConfirmDateChange();
                  handleCloseConfirmationModal();
                }}
                size={Button.sizes.FULL}
              >
                <span className="text-base font-medium p-2">OK</span>
              </Button>
            </div>
          </ModalBody>
        </ModalWindow>
      )}
    </>
  );
};
