import React, { useCallback, useEffect } from 'react';
import { Prompt } from 'react-router-dom';

/* Component for detecting a navigation or page refresh. Useful for preventing accidental user actions causing page change */
export const NavigationConfirmation = ({ confirmationMessage }) => {
  const confirmLeaving = useCallback(
    (e) => {
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    },
    [confirmationMessage]
  );

  useEffect(() => {
    window.addEventListener('beforeunload', confirmLeaving);
    return () => window.removeEventListener('beforeunload', confirmLeaving);
  }, [confirmLeaving]);

  return (
    <Prompt
      message={(_location, action) => {
        if (action !== 'POP') {
          return true;
        }
        return confirmationMessage;
      }}
    />
  );
};
