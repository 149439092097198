import { SearchBar } from '../../SearchBar';
import { BulkAddMeetingRolesDropdown as BulkRolesDropdown } from '../../BulkAddMeetingRolesDropdown';
import { EjectMeetingUsersButton } from '../../EjectMeetingUsersButton';
import { MuteMeetingUsersButton } from '../../MuteMeetingUsersButton';

/**
 * Contains filtering and modification tools.
 * Composed of SearchBar, Button, BulkRolesDropdown components.
 */
export const MeetingToolBox = ({
  searchValue,
  setSearchValue,
  selectedUsers,
  user,
  meetingId,
  selectedUserObjectsList,
  isEjectAllUsersLoading,
  showEjectAllUsersPopup,
  roleSuggestions,
}) => {
  return (
    <div className="w-full flex items-center gap-x-7 max-w-4xl left-72">
      <div className="w-2/5">
        <SearchBar searchValue={searchValue} setSearchValue={setSearchValue} />
      </div>

      {Object.entries(selectedUsers)?.length >= 2 && (
        <div className="w-5/6 flex h-12 gap-x-7 max-w-fit">
          <MuteMeetingUsersButton
            users={selectedUserObjectsList}
            user={user}
            meetingId={meetingId}
          />
          <EjectMeetingUsersButton
            isEjectAllUsersLoading={isEjectAllUsersLoading}
            showEjectAllUsersPopup={showEjectAllUsersPopup}
          />
          <BulkRolesDropdown
            selectedUserObjectsList={selectedUserObjectsList}
            meetingId={meetingId}
            currentUser={user}
            absolutePositioningForPopup={{ top: '7rem', right: '22rem' }}
            roleSuggestions={roleSuggestions}
          />
        </div>
      )}
    </div>
  );
};
