import React from 'react';
import styled, { css } from 'styled-components';
import { isMobile } from '../../helper';
import { getStaticAssetUrl } from '../../helper/getStaticAssetUrl';

const gradient = getStaticAssetUrl('gradient-banner-background.png');

const getBackgroundSrc = (tab) => {
  switch (tab) {
    // placeholder for more background images in future (Figma already has more!)
    default: {
      return gradient;
    }
  }
};
const GradientBanner = ({ tab, style }) => {
  return <Container backgroundUrl={getBackgroundSrc(tab)} style={style} />;
};

const Container = styled.div`
  height: 100%;
  min-height: 240px;
  background-image: url(${({ backgroundUrl }) => backgroundUrl});
  background-size: cover;
  background-position: right;
  margin-left: -16px;
  z-index: -1;

  ${isMobile &&
  css`
    min-height: 180px;
    height: initial;
  `}
`;

export default GradientBanner;
