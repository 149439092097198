// onboarding reducer manages data used during user onboarding

const initialState = {
  // when user successfully integrates with linkedin, we store necessary data here
  linkedin: {},
  linkedinDone: false,
};

export const onboardingReducerActionNames = {
  SAVE_LINKEDIN_DATA: 'SAVE_LINKEDIN_DATA',
  RESET_LINKEDIN_DATA: 'RESET_LINKEDIN_DATA',
  PROCEED_LINKEDIN_STEP: 'PROCEED_LINKEDIN_STEP',
};

const onboarding = (state = initialState, action) => {
  switch (action.type) {
    case onboardingReducerActionNames.SAVE_LINKEDIN_DATA:
      return {
        ...state,
        linkedin: {
          auth: action.payload.auth,
          authenticatedUserId: action.payload.authenticatedUserId,
          profile: action.payload.profile,
        },
      };
    case onboardingReducerActionNames.RESET_LINKEDIN_DATA: {
      return {
        ...state,
        linkedin: {},
      };
    }
    case onboardingReducerActionNames.PROCEED_LINKEDIN_STEP: {
      return {
        ...state,
        linkedinDone: true,
      };
    }
    default: {
      return state;
    }
  }
};

export default onboarding;
