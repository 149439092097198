import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';
import { Button } from './common/Button';
import { FieldContainer } from './Input';

const noWhitespacesAndSpecialChars = (string) =>
  string
    .replace(/\s/g, '')
    // .replace(/[^A-Za-z.]/g, '')
    .toLowerCase();

export const readContents = (file) => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onload = (e) => res(e.target.result);
    reader.onerror = (e) => rej(e);
    reader.readAsArrayBuffer(file);
  });
};

const HiddenInput = styled.input`
  display: none;
`;

const VideoUploadContainerForm = styled(FieldContainer)`
  margin-top: ${({ theme }) => theme.spacing(0)}px;
  margin-bottom: ${({ theme }) => theme.spacing(0)}px;
`;

const MAX_FILE_SIZE_IN_BYTES = 5_000_000; // 5 MB

export const getImageDimensions = async (file, url = null) => {
  let img = new Image();
  img.src = file ? URL.createObjectURL(file) : url;
  await img.decode();
  let width = img.width;
  let height = img.height;
  return {
    width,
    height,
  };
};

function hiddenInputHandler(event) {
  event.target.value = null;
}
/**
 * A component with an Image Upload button that allows for uploading a file.
 * `onImageSubmit` is called after the user chooses a file to upload, with a
 * file buffer, file type, and filename.
 */
export const VideoUploader = ({
  onImageSubmit,
  label = 'Video upload',
  disabled = false,
  loading = false,
  multiple = false,
  onFileSizeExceeded,
  onImageDimensionsExceeded,
  enforcedAspectRatio,
  appendTimeStampToFile = true,
  buttonColor,
  buttonPadding,
  buttonSize,
  buttonState,
  accept = 'video/*',
}) => {
  const inputRef = useRef(null);

  const handleClickUpload = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    if (inputRef.current) {
      inputRef.current.click();
    }
    return true;
  }, []);

  const handleChange = useCallback(
    async (event) => {
      if (!event.target.files || event.target.files.length === 0) {
        return;
      }
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const { name, type, size } = file;

        const video = document.createElement('video');

        video.src = URL.createObjectURL(file);

        video.addEventListener('loadedmetadata', async (event) => {
          if (size > MAX_FILE_SIZE_IN_BYTES && onFileSizeExceeded) {
            return onFileSizeExceeded();
          }

          if (enforcedAspectRatio) {
            const difference = Math.abs(
              video.videoWidth / video.videoHeight - enforcedAspectRatio
            );

            // Small tolerance if exact aspect ratio matching is not required.
            const tolerance = 0.01;

            if (difference > tolerance) {
              return (
                onImageDimensionsExceeded &&
                onImageDimensionsExceeded({
                  width: video.videoWidth,
                  height: video.videoHeight,
                })
              );
            }
          }

          const fileNameWithTimestamp = appendTimeStampToFile
            ? Date.now() + '-' + noWhitespacesAndSpecialChars(name)
            : noWhitespacesAndSpecialChars(name);

          const buffer = await readContents(file);

          await onImageSubmit(buffer, type, fileNameWithTimestamp);
        });
      }
    },
    [
      onFileSizeExceeded,
      onImageSubmit,
      appendTimeStampToFile,
      enforcedAspectRatio,
      onImageDimensionsExceeded,
    ]
  );

  return (
    <VideoUploadContainerForm>
      <Button
        color={buttonColor ? Button.colors[buttonColor] : Button.colors.BLUE}
        padding={buttonPadding ? Button.padding[buttonPadding] : undefined}
        onClick={handleClickUpload}
        state={
          loading
            ? Button.states.LOADING
            : disabled
            ? Button.states.DISABLED
            : Button.states.DEFAULT
        }
        size={buttonSize || Button.sizes.FIT}
      >
        <span className="text-xs font-medium">{label}</span>
      </Button>
      <HiddenInput
        ref={inputRef}
        accept={accept}
        type="file"
        multiple={multiple ?? false}
        onChange={handleChange}
        onClick={hiddenInputHandler}
      />
    </VideoUploadContainerForm>
  );
};
