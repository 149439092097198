import {
  Modal,
  ModalWindow,
  ModalTitle,
  ModalBody,
  ModalButtonsGroup,
  ModalButton,
} from '../../components/Modal';

export const SignUpModal = ({
  title,
  body,
  buttonText = 'Sign Up',
  handleSubmit,
  handleCancel,
}) => {
  return (
    <ModalWindow
      zyncLogo={true}
      size={Modal.size.md}
      showCancel={true}
      onCancel={handleCancel}
    >
      <div className="text-center">
        <ModalTitle fontColor="">{title}</ModalTitle>
      </div>
      <ModalBody>{body}</ModalBody>
      <ModalButtonsGroup>
        <ModalButton buttonText={buttonText} handleOnClick={handleSubmit} />
      </ModalButtonsGroup>
    </ModalWindow>
  );
};
