import { useState, useCallback } from 'react';
import { useInvitePermissions } from '../components/MeetingControl/useInvitePermissions';
import { sendEvent } from '../helper/api';
import { v4 as uuidv4 } from 'uuid';
import { useStartLoading } from './useStartLoading';
import { useProcessedActions } from '../helper/useProcessedActions';

/**
 *
 * @param Object user: The user issuing the eject action.
 * @param string meetingId
 * @returns
 */
export const useEjectMeetingUser = ({ user, meetingId }) => {
  const { canEjectUser } = useInvitePermissions(user);
  const [ejectUserActionId, setEjectUserActionId] = useState(null);
  const [ejectedUser, setEjectedUser] = useState(null);
  const { hasActionCompleted: hasEjectUserActionCompleted } =
    useProcessedActions(ejectUserActionId);

  /**
   * @param Object User to be ejected.
   * @param Function An optional callback.
   */
  const handleEjectMeetingUserHelper = (targetUser, cb) => {
    if (canEjectUser) {
      cb?.();
      ejectUser(targetUser, !!targetUser.isFake);
      setEjectedUser(targetUser);
    }
  };

  const handleEjectMeetingUser = (targetUser, cb) => {
    onClickWithLoading(targetUser, cb);
  };

  /**
   * Loading Logic for ejecting a user.
   */
  const {
    onClickWithLoading,
    isLoading: isEjectingUserLoading,
    disableLoading: disableEjectUserLoading,
  } = useStartLoading(
    handleEjectMeetingUserHelper,
    undefined,
    undefined,
    'Eject User'
  );

  if (isEjectingUserLoading && hasEjectUserActionCompleted(ejectUserActionId)) {
    disableEjectUserLoading();
  }

  /**
   * Ejects a single user.
   */
  const ejectUser = useCallback(
    (targetUser) => {
      const actionId = uuidv4();
      setEjectUserActionId(actionId);
      const userId = user?.userId;
      sendEvent(userId, meetingId, {
        type: 'EJECT_USER',
        targetUserId: targetUser?.userId,
        actionId,
      });
    },
    [meetingId, user?.userId]
  );

  return {
    handleEjectMeetingUser,
    isEjectingUserLoading,
    ejectedUser,
  };
};
