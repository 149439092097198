import React from 'react';

export const DisabledMicIcon = ({ width = 35, height = 35 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6L8 10C8 12.2091 9.79086 14 12 14C14.2091 14 16 12.2091 16 10V6C16 3.79086 14.2091 2 12 2C9.79086 2 8 3.79086 8 6ZM18.86 13.2C18.967 13.2463 19.0477 13.338 19.08 13.45C19.1067 13.5665 19.0771 13.6887 19 13.78V13.86C17.7648 16.0971 15.5353 17.6095 13 17.93L13 20H14.5C14.7761 20 15 20.2239 15 20.5V21.5C15 21.7761 14.7761 22 14.5 22H9.5C9.22386 22 9 21.7761 9 21.5V20.5C9 20.2239 9.22386 20 9.5 20H10.96V17.98C8.42476 17.6595 6.19518 16.1471 4.96 13.91C4.90553 13.7822 4.90553 13.6378 4.96 13.51C4.99932 13.3772 5.09411 13.2678 5.22 13.21L6.13 12.79C6.36526 12.679 6.64622 12.7712 6.77 13C7.84804 14.8939 9.86823 16.0544 12.0474 16.0316C14.2265 16.0087 16.2219 14.8061 17.26 12.89C17.3662 12.6931 17.608 12.614 17.81 12.71L18.86 13.2Z"
        fill="#696F8C"
      />
    </svg>
  );
};
