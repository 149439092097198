import mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';

const LinkText = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.005em;
  word-break: break-word;
`;

const CopyButton = styled.button`
  background: none;
  border: none;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;
  color: #00a3a5;

  &:active {
    transform: translateY(2px);
  }
`;

const DEFAULT_LABEL = 'COPY';

export const UrlLink = ({ children, url, showUrl = true, textStyle }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!copied) return;
    mixpanel.track('URL Copied', { url });
  }, [copied, url]);

  return (
    <div>
      {showUrl && <LinkText style={textStyle}>{url}</LinkText>}
      <CopyToClipboard text={url}>
        <CopyButton onClick={() => setCopied(true)}>
          {children || DEFAULT_LABEL}
        </CopyButton>
      </CopyToClipboard>
    </div>
  );
};
