import React from 'react';

export const WarningTriangleIcon = ({
  width = '16px',
  height = '15px',
  className = 'fill-yellow',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.846 13L15.856 12.99L8.86032 0.99L8.85033 1C8.68044 0.7 8.37063 0.5 8.00086 0.5C7.63109 0.5 7.33128 0.7 7.15139 1L7.1414 0.99L0.145772 12.99L0.155766 13C0.0658219 13.15 0.00585938 13.31 0.00585938 13.5C0.00585938 14.05 0.455578 14.5 1.00523 14.5H14.9965C15.5461 14.5 15.9959 14.05 15.9959 13.5C15.9959 13.31 15.9359 13.15 15.846 13ZM9.00024 12.49H7.00149V10.49H9.00024V12.49ZM7.00149 9.49H9.00024V4.49H7.00149V9.49Z"
      />
    </svg>
  );
};
