import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { TextInput } from '../../components/Input';
import { typography } from '../../theme/baseStyles';

export const TabContainer = styled.div`
  position: relative;
`;

export const AttendeeTable = styled.table`
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;

  th {
    padding: ${({ theme }) => theme.spacing(2)}px 0;
  }

  & tr:not(:last-of-type),
  & tr:first-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  }
`;

export const RemoveUserIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
  margin-right: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: -2px;
  visibility: ${({ disabled }) => (disabled ? 'hidden' : 'visible')};

  & .options-wrapper {
    margin: 0;
  }
`;

export const InviteHeader = styled.h3`
  margin-top: 36px;
  ${typography.bodyHeavy};
`;

export const InviteForm = styled.form`
  align-items: center;
  display: flex;
  gap: 12px;
  margin: 12px 0;
`;

export const StyledSeriesInput = styled(TextInput)`
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid #e0e0e0;
  width: 484px;
  outline-color: #f8155c;

  &:hover {
    border-color: #f8155c;
  }
`;

export const AttendeeTableHeader = styled.th`
  ${typography.titleHeavy};
  text-align: left;
  position: relative;
`;

export const EmailTd = styled.td`
  ${typography.bodyHeavy};
`;

export const UserNameTd = styled.td`
  ${typography.bodyLight};
`;

export const RolesTd = styled.td`
  position: relative;
  left: ${({ theme }) => -theme.spacing(1.5)}px;
  padding-top: ${({ theme }) => theme.spacing(1)}px;
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const InformationModalWrapper = styled.div`
  position: absolute;
  top: 50px;
  right: 0;
  background-color: #fff;
  border: 1px solid #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(102, 102, 102, 0.25);
  z-index: ${({ theme }) => theme.zIndex.modal};
`;

export const RoleInformationIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: #f8155c;
  cursor: pointer;
`;

export const RoleInformationModalHeader = styled.h3`
  ${typography.bodyHeavy};
`;
