import { WarningCircleIcon } from '../../../components/icons/WarningCircleIcon';

export const ContentKitErrorView = ({ isSoloEpisode }) => {
  return (
    <div className="flex flex-col justify-center items-center py-[60px]">
      <div className="text-blue-dark text-2xl font-semibold text-center mb-4">
        No content kit created
      </div>
      <div className="text-blue-gray text-sm font-medium text-center mb-10">
        Problem with content kit production
      </div>
      <div className="text-red rounded-lg border border-red bg-red/5 text-sm font-medium px-3 py-4 flex gap-2.5 items-center w-[440px] text-center flex-wrap justify-center">
        <WarningCircleIcon />
        <span>We couldn’t find enough video to produce a content kit</span>
        {isSoloEpisode && (
          <a
            className="text-blue-light text-sm font-medium underline decoration-blue-light"
            href="http://support.zync.ai/article/why-is-my-content-kit-empty"
            target="_blank"
            rel="noreferrer"
          >
            Why is my content kit empty?
          </a>
        )}
      </div>
    </div>
  );
};

export const MonthlyClipLimitReachedView = ({ isSoloEpisode }) => (
  <div className="flex flex-col justify-center items-center py-[60px]">
    <div className="text-blue-dark text-2xl font-semibold text-center mb-4">
      No content kit created
    </div>
    <div className="text-red rounded-lg border border-red bg-red/5 text-sm font-medium px-3 py-4 flex gap-2.5 items-center w-[442px] text-center flex-wrap justify-center">
      <WarningCircleIcon />
      <span>Reached Limit on Clips Per Month. Upgrade to Continue</span>
      {isSoloEpisode && (
        <a
          className="text-blue-light text-sm font-medium underline decoration-blue-light"
          href="http://support.zync.ai/article/why-is-my-content-kit-empty"
          target="_blank"
          rel="noreferrer"
        >
          Why is my content kit empty?
        </a>
      )}
    </div>
  </div>
);
