import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MediaButton from './MediaButton';
import Lottie from 'react-lottie';
import scenesAnimation from '../Live meeting icons/Scenes.json';
import { animationOptions } from '../meetingControlAnimationOptions';

const MediaScenePickerButton = ({ timelineOn }) => {
  const dispatch = useDispatch();
  const [isScenePickAnimationStopped, setIsScenePickAnimationStopped] =
    useState(true);

  const liveTab = useSelector((st) => st.clientDetails.liveTab);

  const isScenesTabActive = liveTab === 'scenes';
  const isTimelineTabActive = liveTab === 'timeline';

  const handleToggleScenes = useCallback(() => {
    if (timelineOn) {
      dispatch({
        type: 'SELECT_LIVE_TAB',
        tab: isTimelineTabActive ? null : 'timeline',
      });
    } else {
      dispatch({
        type: 'SELECT_LIVE_TAB',
        tab: isScenesTabActive ? null : 'scenes',
      });
    }
  }, [dispatch, timelineOn, isScenesTabActive, isTimelineTabActive]);

  return (
    <MediaButton
      disabled={false}
      className="cursor-pointer"
      onClick={handleToggleScenes}
      isSelected={isScenesTabActive || isTimelineTabActive}
      onMouseEnter={() => setIsScenePickAnimationStopped(false)}
      onMouseLeave={() => setIsScenePickAnimationStopped(true)}
    >
      <div className=" flex flex-col justify-center items-center">
        <div className="w-8 h-8 text-purple">
          <Lottie
            options={{ ...animationOptions, animationData: scenesAnimation }}
            isStopped={isScenePickAnimationStopped}
          />
        </div>
        <span className="text-purple text-xxs font-medium">
          {timelineOn ? 'Timeline' : 'Scenes'}
        </span>
      </div>
    </MediaButton>
  );
};

export default MediaScenePickerButton;
