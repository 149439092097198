import React from 'react';

export const HostNotes = ({ onChange, defaultValue, isLoading }) => {
  return (
    <div
      className={`bg-purple/10 rounded-xl px-4 py-5 ${
        isLoading && 'pointer-events-none'
      }`}
    >
      <label className="flex flex-col gap-3">
        <span className="text-xs font-medium text-blue-dark">Host notes</span>
        <textarea
          onBlur={onChange}
          defaultValue={defaultValue}
          disabled={isLoading}
          maxLength={1000}
          className="p-2 border border-gray rounded-md"
          placeholder="Type notes that you want to refer
during the event."
        />
      </label>
    </div>
  );
};
