import React, { useCallback, useRef } from 'react';
import { useRichTextEditor } from '../../hooks/useRichTextEditor';

const RichTextMeetingTitle = ({
  meetingTitle = '',
  isEditingTitle,
  setIsEditingTitle,
  updateMeetingTitle,
  trigger,
}) => {
  const containerRef = useRef(null);

  const handleUpdate = useCallback(
    (value) => {
      setIsEditingTitle(false);
      if (value !== meetingTitle) {
        updateMeetingTitle(value);
      }
    },
    [meetingTitle, setIsEditingTitle, updateMeetingTitle]
  );

  const { Editor } = useRichTextEditor({
    initialValue: meetingTitle,
    isActive: isEditingTitle,
    handleUpdate,
    maxLength: 120,
  });

  return (
    <div ref={containerRef} className="flex items-center text-2xl md:text-3xl">
      {isEditingTitle ? (
        <div className="font-bold border border-purple p-2 -m-2 rounded-lg opacity-70">
          {Editor}
        </div>
      ) : (
        <div className="font-bold border border-purple/0 ">{Editor}</div>
      )}
      {trigger}
    </div>
  );
};

export default RichTextMeetingTitle;
