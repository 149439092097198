export const FileIcon = ({
  width = '8px',
  height = '8px',
  className = 'fill-white',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 8 8"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.74666 0.667969H5.99999C6.36818 0.667969 6.66666 0.966446 6.66666 1.33464V6.66797C6.66666 7.03616 6.36818 7.33464 5.99999 7.33464H1.99999C1.63181 7.33464 1.33333 7.03616 1.33333 6.66797V3.0813C1.33356 2.81617 1.43907 2.56199 1.62666 2.37464L3.03999 0.961302C3.22735 0.773712 3.48153 0.668202 3.74666 0.667969ZM1.99999 3.33464V6.66797H6V1.33464H3.99999V3.0013C3.99999 3.1854 3.85076 3.33464 3.66666 3.33464H1.99999ZM2.66666 4.16797V4.5013C2.66666 4.59335 2.74128 4.66797 2.83333 4.66797H5.16666C5.25871 4.66797 5.33333 4.59335 5.33333 4.5013V4.16797C5.33333 4.07592 5.25871 4.0013 5.16666 4.0013H2.83333C2.74128 4.0013 2.66666 4.07592 2.66666 4.16797ZM2.83333 5.33464H4.16666C4.25871 5.33464 4.33333 5.40926 4.33333 5.5013V5.83464C4.33333 5.92668 4.25871 6.0013 4.16666 6.0013H2.83333C2.74128 6.0013 2.66666 5.92668 2.66666 5.83464V5.5013C2.66666 5.40926 2.74128 5.33464 2.83333 5.33464Z"
    />
  </svg>
);
