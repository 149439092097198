const { createFromText } = require('draft-js/lib/ContentState');
const { convertFromRaw } = require('draft-js');

const fromRawContentState = (serializedState) => {
  try {
    const json = JSON.parse(serializedState);
    return convertFromRaw(json);
  } catch (e) {
    try {
      return createFromText(serializedState);
    } catch (e) {
      return createFromText(
        'Click on the Text Box block and start typing to edit content.'
      );
    }
  }
};
const getTextFromRichText = (text) => {
  try {
    const richTextJson = JSON.parse(text);
    return richTextJson.blocks[0].text;
  } catch (error) {
    return text;
  }
};

const getMultiTextFromRichText = (text) => {
  try {
    const richTextJson = JSON.parse(text);
    return richTextJson.blocks.map((block) => block.text);
  } catch (error) {
    return text;
  }
};

const isStringifiedJSON = (str) => {
  if (/^\s*(\{|\[)/.test(str)) {
    return false;
  }
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
};

module.exports = {
  getTextFromRichText,
  getMultiTextFromRichText,
  isStringifiedJSON,
  fromRawContentState,
};
