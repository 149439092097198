import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import classNames from '../../helper/classNames';

import { outerContainerBoxShadow, outerContainerTheme } from './Container';
import { H3Text } from './Text';
import {
  sizeToNumber,
  sizeToString,
  absolutePosition,
  scrollbarCss,
} from '../../helper/css';
import { CircularMenuButton } from './CircularMenuButton';
import { CheckBox } from './CheckBox';

export const defaultActionMenuWidth = 180;
export const defaultActionMenuPadding = 16;

export const ActionMenuHoverContainer = styled.div`
  z-index: 3;
  ${(props) => absolutePosition(props.position)}
`;

export const ActionMenuContainer = styled.ul`
  ${outerContainerBoxShadow};
  ${outerContainerTheme};
  ${scrollbarCss(true, false)};
  border-radius: 8px;
  padding: 10px 0;
  box-sizing: border-box;
  margin: 0;
  overflow: hidden;
  color: #222;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${(props) =>
    props.width &&
    css`
      width: ${sizeToString(props.width)};
    `}
  ${(props) =>
    props.maxHeight &&
    css`
      max-height: ${sizeToString(props.maxHeight)};
      overflow-y: auto;
    `}
  ${() => H3Text} {
    padding: 0 22px;
  }
`;

const Icon = styled.img`
  height: 12px;
`;

export const Actions = ({ actionItems, multiSelect, isDisabled = false }) => {
  return (actionItems || []).map(({ icon, text, action, checked }) => (
    <li
      key={text}
      onClick={() => {
        action && action();
      }}
      className={classNames(
        'list-none px-5 py-4 flex gap-1 items-center whitespace-nowrap',
        !isDisabled &&
          'hover:bg-purple hover:bg-opacity-5 hover:text-purple cursor-pointer border-l-2 border-white hover:border-purple'
      )}
    >
      {icon && typeof icon === 'string' ? <Icon src={icon} /> : null}
      {icon && typeof icon !== 'string' ? icon : null}
      <span className="flex-grow-1">{text}</span>
      {multiSelect && (
        <CheckBox value={checked} onChange={() => action(!checked)} />
      )}
    </li>
  ));
};

export const ActionMenu = React.forwardRef(
  ({ title = null, actionItems, maxHeight }, ref) => {
    return (
      <ActionMenuContainer ref={ref} maxHeight={maxHeight}>
        {title && <H3Text>{title}</H3Text>}
        <Actions actionItems={actionItems} />
      </ActionMenuContainer>
    );
  }
);

export const blockActionMenuHoverStyles = css`
  ${() => ActionMenuHoverContainer} {
    display: none;
  }

  &:hover ${() => ActionMenuHoverContainer} {
    display: block;
  }
`;

export const ActionMenuBlock = styled(ActionMenuContainer)`
  position: absolute;
  z-index: 500;
  top: 16px;
  ${(props) => absolutePosition(props.position)}
`;

/** An action menu that automatically positions itself for use as the menu for a block. */
export const BlockActionMenu = React.forwardRef(
  (
    {
      title = null,
      actionItems,
      blockPosition,
      /** Whether to display the block menu from an ellipsis circular menu. */
      menuButton = true,
      width: menuWidth = defaultActionMenuWidth,
    },
    ref
  ) => {
    const [showMenu, setShowMenu] = useState(false);
    const blockWidth = sizeToNumber(blockPosition.width);
    const blockHeight = sizeToNumber(blockPosition.height);
    const padding = defaultActionMenuPadding;

    // position to the top right of the block
    const containerPosition = {
      left: 0,
      top: 0,
      width: blockWidth,
      height: blockHeight,
    };
    const menuPosition = {
      right: padding,
      top: padding,
    };

    const modifiedActionItems = useMemo(
      () =>
        actionItems.map((item) => ({
          ...item,
          action: () => {
            item.action();
            setShowMenu(false);
          },
        })),
      [actionItems]
    );

    return (
      <ActionMenuHoverContainer position={containerPosition}>
        {menuButton && !showMenu && (
          <CircularMenuButton
            onClick={() => setShowMenu(true)}
            position={menuPosition}
            shadow
          />
        )}
        {(!menuButton || showMenu) && (
          <ActionMenuBlock
            ref={ref}
            position={menuPosition}
            onMouseLeave={() => setShowMenu(false)}
          >
            <Actions actionItems={modifiedActionItems} title={title} />
          </ActionMenuBlock>
        )}
      </ActionMenuHoverContainer>
    );
  }
);
