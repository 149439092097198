import React, { useEffect, useState } from 'react';
import { presenterTypes } from '../../../components/series/settings/EventPresenters';
import {
  fetchWorkspaceApi,
  fetchUserApi,
  updateSeriesApi,
} from '../../../helper/api';
import { logerror } from '../../../helper/contextualLogger';
import { DropdownSelect } from '../../../components/common/Dropdown';
import { TaskCardHeader } from './Plan';

export const PlanSetHostCard = ({
  series,
  localDispatch,
  disableEventPage,
}) => {
  const { settings, meetingSeriesId, workspace } = series || {};
  const { workspaceId } = workspace || {};
  const { eventPresenters = [] } = settings || {};
  const host = eventPresenters.find((ep) => ep.type === presenterTypes.HOST);
  const [workspaceMembers, setWorkspaceMembers] = useState([]);

  const handleSelectHost = async (emailAddress) => {
    if (emailAddress === host.emailAddress && host?.user) return;
    const member = workspaceMembers.find(
      (m) => m.emailAddress === emailAddress
    );
    if (!member) return;
    const user = await fetchUserApi(member.userId);
    if (!user) return;
    const newEventPresenters = eventPresenters.slice();
    const newHost = newEventPresenters.find(
      (ep) => ep.type === presenterTypes.HOST
    );
    newHost.linkedInUrl = user?.linkedInUrl || '';
    newHost.fullName = user?.userName || '';
    newHost.jobTitle = user?.bio || '';
    newHost.presenterPictureUrl = user?.avatarUrl || null;
    newHost.emailAddress = user.emailAddress;
    newHost.invited = false;
    newHost.type = presenterTypes.HOST;
    const result = await updateSeriesApi(meetingSeriesId, {
      eventPresentersUpdate: {
        type: 'SET_HOST',
        eventPresenters: newEventPresenters,
      },
    });
    if (result?.error) return;
    localDispatch({ type: 'REFRESH_SERIES', series: result });
  };

  useEffect(() => {
    if (!workspaceId) return;
    const getWorkspaceMembers = async () => {
      const json = await fetchWorkspaceApi(workspaceId);
      const { result, error } = json;
      if (error) {
        logerror({
          message: `Error getting workspace with id ${workspaceId} in set host card`,
        });
      }
      setWorkspaceMembers(
        result?.members?.filter((m) => !m.hidden && m?.userName) || []
      );
    };
    getWorkspaceMembers();
  }, [workspaceId]);

  return (
    <div>
      <div className="px-5">
        <TaskCardHeader className="mb-6">Set Host</TaskCardHeader>
      </div>
      <div className="flex gap-[49px] px-5">
        <div className="flex flex-col w-full gap-5 h-[42px] z-10">
          <DropdownSelect
            options={workspaceMembers.map((member) => ({
              key: member.emailAddress,
              title: member.userName,
            }))}
            onSelect={(selectKey) => {
              handleSelectHost(selectKey);
            }}
            backgroundColor={DropdownSelect.backgroundColor.WHITE}
            selectedKey={host?.emailAddress}
            fontColor={DropdownSelect.fontColor.BLUE_GRAY}
            borderSize={DropdownSelect.borderSize.ONE}
            borderColor={DropdownSelect.borderColor.GRAY}
            angleColor="fill-[#97A0AF]"
            autoScrollToSelection={false}
            disabled={disableEventPage}
            className="h-full"
          />
        </div>
      </div>
    </div>
  );
};
