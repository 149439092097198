import { Button } from './common/Button';
import { useMuteMeetingUsers } from '../hooks/useMuteMeetingUsers';

export const MuteMeetingUsersButton = ({ users, meetingId, user }) => {
  const { handleMuteAllUsers, isMuteAllUsersLoading } = useMuteMeetingUsers({
    users,
    meetingId,
    user,
  });
  return (
    <Button
      state={
        isMuteAllUsersLoading ? Button.states.LOADING : Button.states.DEFAULT
      }
      onClick={handleMuteAllUsers}
    >
      <span className="text-sm">Mute All</span>
    </Button>
  );
};
