import { Modal, ModalBody } from '../../../components/Modal';
import classNames from '../../../helper/classNames';
import React from 'react';

export const SignOnContainer = ({ children, pageMode, setPageMode }) => {
  return (
    <div className="z-[2] mt-2 w-96 flex items-stretch mb-4 md:mb-0">
      <Modal size={Modal.size.sm} boxShadow={Modal.boxShadow['3xl']}>
        {(pageMode === 'register' || pageMode === 'signin') && (
          <div className="flex flex-row pt-6 px-6 md:pt-8 md:px-8 w-full gap-6">
            <button
              className={classNames(
                'font-semibold text-xl cursor-pointer bg-transparent border-0 p-0',
                pageMode === 'register'
                  ? 'underline decoration-purple decoration-solid decoration-2 text-purple'
                  : 'text-light-gray'
              )}
              onClick={() => setPageMode('register')}
            >
              Sign up
            </button>
            <button
              className={classNames(
                'font-semibold text-xl cursor-pointer bg-transparent border-0 p-0',
                pageMode === 'signin'
                  ? 'underline decoration-purple decoration-solid decoration-2 text-purple'
                  : 'text-light-gray'
              )}
              onClick={() => setPageMode('signin')}
            >
              Sign in
            </button>
          </div>
        )}
        <ModalBody>{children}</ModalBody>
        {(pageMode === 'register' || pageMode === 'signup') && (
          <p className="text-blue-gray text-sm px-6 pb-6 font-normal m-0">
            By registering, you agree to our{' '}
            <a
              href="/tos"
              className="underline decoration-purple decoration-solid decoration-1 text-purple text-sm"
            >
              Terms of Service
            </a>{' '}
            and{' '}
            <a
              href="/privacy"
              className="underline decoration-purple decoration-solid decoration-1 text-purple text-sm"
            >
              Privacy Policy
            </a>
          </p>
        )}
        {pageMode === 'signin' && (
          <p className="text-blue-gray text-sm px-6 pb-6 font-normal m-0">
            Not registered?
            <button
              onClick={() => setPageMode('register')}
              className="underline decoration-purple decoration-solid decoration-1 text-purple text-sm pl-1 inline-block"
            >
              Register
            </button>
          </p>
        )}
      </Modal>
    </div>
  );
};
