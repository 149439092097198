export const SignOutIcon = ({
  color = '#696F8C',
  width = '12',
  height = '12',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33333 10.6667H5C5.18409 10.6667 5.33333 10.8159 5.33333 11V11.6667C5.33333 11.8508 5.18409 12 5 12H1.33333C0.596954 12 0 11.403 0 10.6667V1.33333C0 0.596954 0.596954 0 1.33333 0H5C5.18409 0 5.33333 0.149238 5.33333 0.333333V1C5.33333 1.18409 5.18409 1.33333 5 1.33333H1.33333V10.6667ZM8.42667 2.1L11.8533 5.52C11.9459 5.61448 11.9985 5.74106 12 5.87333V6.12667C11.9999 6.25923 11.9471 6.38632 11.8533 6.48L8.42667 9.9C8.36408 9.9631 8.27888 9.9986 8.19 9.9986C8.10112 9.9986 8.01592 9.9631 7.95333 9.9L7.48 9.43333C7.4169 9.37074 7.3814 9.28555 7.3814 9.19667C7.3814 9.10779 7.4169 9.02259 7.48 8.96L9.78 6.66667H3C2.81591 6.66667 2.66667 6.51743 2.66667 6.33333V5.66667C2.66667 5.48257 2.81591 5.33333 3 5.33333H9.78L7.48 3.04C7.41729 2.97856 7.38195 2.89446 7.38195 2.80667C7.38195 2.71887 7.41729 2.63478 7.48 2.57333L7.95333 2.1C8.01592 2.0369 8.10112 2.0014 8.19 2.0014C8.27888 2.0014 8.36408 2.0369 8.42667 2.1Z"
        fill={color}
      />
    </svg>
  );
};
