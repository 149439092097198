import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyIcon } from '../../../components/icons/CopyIcon';

export const RSSFeed = ({ rssFeedUrl }) => {
  return (
    <div className="flex gap-1">
      <div className="text-sm text-blue-dark">{rssFeedUrl}</div>
      <CopyToClipboard text={rssFeedUrl}>
        <button>
          <CopyIcon />
        </button>
      </CopyToClipboard>
    </div>
  );
};
