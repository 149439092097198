import { getUserById } from '../../helper';

// Special chatroom key for 'Everyone'. Ie, not a direct message.
export const EVERYONE = '___EVERYONE___';

export const formatChatMessage = (
  message,
  userId,
  users,
  isMessageFromCurrentUser
) => {
  if (isMessageFromCurrentUser) {
    return message;
  }
  const userName = (getUserById(users, userId) || {}).userName;
  const userNameAndMessage = `**${
    userName || userId || 'Anonymous'
  }:** ${message}`;
  return userNameAndMessage;
};

export const buildChatroomList = (
  users,
  userId,
  chatRoomHasUnreadMessages,
  currentChatroomKey
) => {
  // Get all other users than the current logged in one.
  const allOtherUsers = users.filter((u) => u.userId !== userId);

  const hasUnread = (key) =>
    chatRoomHasUnreadMessages[key] && currentChatroomKey !== key;

  // Sort users with unread messages at the top.
  allOtherUsers.sort((a, _b) => (chatRoomHasUnreadMessages[a.userId] ? -1 : 1));
  return [
    {
      title: 'Everyone',
      key: EVERYONE,
      unread: hasUnread(EVERYONE),
    },
    ...allOtherUsers.map((u) => {
      return {
        title: u.userName,
        key: u.userId,
        unread: hasUnread(u.userId),
      };
    }),
  ];
};

export const getChatroomKeyFromReceivedMessage = (sender, recipient) => {
  // If the message originally had a recipient, it's a direct message to the current user.
  // In that event, we make the chatroom key the sender. Otherwise, it's to everyone in the room.
  return recipient ? sender : EVERYONE;
};
