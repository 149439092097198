import React from 'react';
import styled, { css } from 'styled-components';
import { absolutePosition } from '../../helper/css';
import { LoadingSpinner } from '../LoadingSpinner';
import { outerContainerBoxShadow } from './Container';

const SpinnerWrapper = styled.div`
  position: absolute;
`;

export const buttonBoxShadow = css`
  box-shadow: 0 2px 4px -2px rgba(24, 39, 75, 0.12),
    0 4px 4px -2px rgba(24, 39, 75, 0.08);
`;

export const buttonBoxShadowBehavior = css`
  transition: box-shadow 70ms ease-out, transform 70ms ease-out;
  &:hover {
    ${buttonBoxShadow}
  }
  &:active {
    ${buttonBoxShadow};
    transform: translateY(2px);
  }
`;

export const largeBoxShadowBehavior = css`
  transition: box-shadow 70ms ease-out, transform 70ms ease-out;
  ${outerContainerBoxShadow}
  &:active {
    ${outerContainerBoxShadow}
    transform: translateY(2px);
  }
`;

export const ActionButtonContainer = styled.div`
  cursor: pointer;

  ${(props) => absolutePosition(props.position)}

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;

  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  border: 1px solid rgba(255, 255, 255, 0);

  ${(props) =>
    props.primary &&
    css`
      background: ${props.theme.palette.primary.main};
      color: ${props.theme.palette.primary.contrastText};
    `}
  ${(props) =>
    props.secondary &&
    css`
      color: ${props.theme.palette.primary.contrastText};
      background: ${props.theme.palette.secondary.main};
    `}
  ${(props) =>
    props.tertiary &&
    css`
      color: ${props.theme.palette.text.disabled};
      background: ${props.theme.palette.background.default};
      border: 1px solid ${props.theme.palette.border.field};
    `}
  ${(props) =>
    props.decorative &&
    css`
      color: ${props.theme.palette.text.paragraph};
      background: ${props.theme.palette.background.default};
      border: 1px solid ${props.theme.palette.border.field};
      border-radius: 14px !important;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    `}
  ${(props) =>
    props.compact
      ? css`
          padding: 4px 8px;
        `
      : css`
          padding: 11px 24px;
        `}
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
  ${(props) =>
    (props.disabled || props.isLoading) &&
    css`
      opacity: 0.7;
      cursor: default;
    `}

  box-sizing: border-box;
  border-radius: 4px;
  visibility: ${(props) => (props.isLoading ? 'hidden' : 'visible')};

  ${(props) => (props.margin ? 'margin: 10px;' : '')}

  transition: all 120ms ease-in-out;

  ${(props) =>
    props.shadow ? largeBoxShadowBehavior : buttonBoxShadowBehavior}

  ${SpinnerWrapper} {
    visibility: ${(props) => (props.isLoading ? 'visible' : 'hidden')};
  }
`;

/** A standardized button. */
export const ActionButton = React.forwardRef(
  (
    {
      /** Specifies tag which will be used on the root component */
      as = 'div',
      /** The button label (text or JSX). */
      label = '',
      /** Alternative to label: children to include as the children of the button container. */
      children,
      /** The action to be taken on click. */
      onClick,
      /** Primary style button. */
      primary,
      /** Secondary style button. */
      secondary,
      /** Tertiary style button. */
      tertiary,
      /** Decorative style button (particularly rounded with a white-and-gray theme). */
      decorative,
      /** Whether the button has a larger drop shadow. */
      shadow = false,
      /** Whether to show a minimal version of the button with less padding. */
      compact = false,
      /** Whether the button will fill its container's width. */
      fullWidth = false,
      /** Whether the button can be interacted with. */
      disabled = false,
      /** Additional style attributes. Use position for static absolute positioning. */
      style,
      /**
       * Absolute positioning information (top, left, bottom, and/or right). If provided,
       * the button will be positioned absolute.
       */
      position,
      /** HTML button type */
      type = 'button',
      /** Whether we want to show loading spinner representing async action being performed */
      showLoading,
      /** Add a margin around the button. */
      margin = false,
      className,
    },
    ref
  ) => {
    if (primary === undefined) {
      primary = !secondary && !tertiary;
    }
    return (
      <ActionButtonContainer
        ref={ref}
        onClick={
          disabled || showLoading
            ? undefined
            : (event) => {
                onClick && onClick(event);
              }
        }
        primary={primary}
        secondary={secondary}
        tertiary={tertiary}
        decorative={decorative}
        disabled={disabled}
        shadow={shadow}
        compact={compact}
        fullWidth={fullWidth}
        margin={margin}
        style={style ?? undefined}
        position={position}
        type={type}
        isLoading={showLoading}
        className={className}
        as={showLoading ? 'div' : as}
      >
        {label}
        {children}

        {showLoading && (
          <SpinnerWrapper>
            <LoadingSpinner width={style?.height || '36px'} />
          </SpinnerWrapper>
        )}
      </ActionButtonContainer>
    );
  }
);
