import { useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { SUBSCRIBE_AUDIO, UNSUBSCRIBE_AUDIO } from '../reducers/callState';

export const useDailyAudio = (trackStates, outputDeviceId) => {
  const dispatch = useDispatch();
  const audioElement = useRef(null);

  const isLocal = trackStates?.local;
  const sessionId = trackStates?.sessionId;
  const audioTrackState = trackStates?.audioTrackState?.state;

  useEffect(() => {
    if (isLocal || !sessionId) return;
    dispatch({ type: SUBSCRIBE_AUDIO, sessionId: sessionId });
    return () => {
      dispatch({
        type: UNSUBSCRIBE_AUDIO,
        sessionId: sessionId,
      });
    };
  }, [sessionId, isLocal, dispatch]);

  const audioTrack = useMemo(() => {
    return trackStates && audioTrackState === 'playable'
      ? trackStates.audioTrackState.track
      : null;
  }, [trackStates, audioTrackState]);

  useEffect(() => {
    const setAudioElement = async () => {
      if (!audioElement.current) return;
      audioElement.current.srcObject = new MediaStream([audioTrack]);
      if (outputDeviceId !== null && audioElement.current.setSinkId) {
        await audioElement.current.setSinkId(outputDeviceId);
      }
    };
    setAudioElement();
  }, [audioTrack, audioElement, outputDeviceId]);

  return { audioElement, audioTrack };
};
