import React, { useState, useMemo, useCallback } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { MeetingAttendeesTable } from './MeetingAttendeesTable';
import { MeetingToolBox } from './MeetingToolbox';
import { useEscapeKeyPress } from '../../../hooks/useEscapeKeyPress';
import { EjectUsersPopup } from './EjectUsersPopup';
import { useEjectMeetingUsers } from '../../../hooks/useEjectMeetingUsers';
import { useEjectMeetingUser } from '../../../hooks/useEjectMeetingUser';
import { Button } from '../../common/Button';
import { sendEvent } from '../../../helper/api';
import { demoUsers } from '../../../helper/constants';
import { PARTICIPANTS_CHANGE } from '../../../reducers/callState';

const Title = ({ children }) => {
  return <h1 className="text-2xl font-bold">{children}</h1>;
};

/**
 * Main component that is rendered when the 'People/Attendees' button is clicked in a live meeting.
 * This component renders the <MeetingToolBox /> which contains <SearchBar />, <MuteMeetingUsersButton /> , <EjectMeetingUsersButton />
 * and the <MeetingAttendeesTable /> which contains <MeetingAttendeeRow />
 */
export const AttendeesSettingsModal = ({
  setSelectedMeetingControlButton,
  joinedUsers,
  user,
  roleSuggestions,
}) => {
  const meetingState = useSelector((state) => state.meetingState, shallowEqual);
  const { state, meetingId } = meetingState || {};
  const { processedActions } = state || {};
  const [searchValue, setSearchValue] = useState('');
  const [showEjectConfirmation, setShowEjectConfirmation] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);
  const [isEjectAllClicked, setIsEjectAllClicked] = useState(false);
  const [isEjectUserClicked, setIsEjectUserClicked] = useState(false);
  const [enableFakeUsersButton, setEnableFakeUsersButton] = useState(true);
  useEscapeKeyPress(() => setSelectedMeetingControlButton(''));
  const daily = useSelector((state) => state.callState.daily);
  const dispatch = useDispatch();
  const robotController = useSelector(
    (state) => state.auth.user?.privileges?.robotController
  );

  // Creates an array from all users maintaining their selected state.
  const selectedUserObjectsList = useMemo(
    () =>
      joinedUsers.filter(
        (currentUser) => !!selectedUsers[[currentUser?.userId]]
      ),
    [joinedUsers, selectedUsers]
  );

  /**
   * Hook for ejecting a user in a meeting.
   */
  const { handleEjectMeetingUser, isEjectingUserLoading, ejectedUser } =
    useEjectMeetingUser({
      user,
      meetingId,
    });

  /**
   * Hook for ejecting all users in a meeting.
   */
  const { isEjectAllUsersLoading, handleEjectAll } = useEjectMeetingUsers({
    meetingId,
    users: selectedUserObjectsList,
    user,
  });

  const removeEjectPopup = () => {
    setShowEjectConfirmation(false);
    setIsEjectAllClicked(false);
    setIsEjectUserClicked(false);
  };

  const showEjectAllUsersPopup = () => {
    setShowEjectConfirmation(true);
    setIsEjectAllClicked(true);
  };

  const showEjectUserPopup = () => {
    setShowEjectConfirmation(true);
    setIsEjectUserClicked(true);
  };

  const addFakeUsers = useCallback(() => {
    // Send JOIN_MEETING event for each fake user.
    const fakeUsers = Object.values(demoUsers);
    fakeUsers.forEach(async (fakeUser) => {
      await sendEvent(fakeUser.userId, meetingId, {
        type: 'JOIN_MEETING',
        userName: fakeUser.userName,
        emailAddress: fakeUser.userId,
        roles: fakeUser.roles,
        mobileUser: false,
        isFake: true,
        fakeVideoStream: fakeUser.fakeVideoStream,
      });
    });

    // Create a participant object for each fake user
    // and update the callStart participant's object with fakeParticipants.
    const fakeParticipants = {};
    for (const fakeUser of fakeUsers) {
      const sessionId = `${fakeUser.userId}-session`;
      fakeParticipants[sessionId] = {
        user_name: fakeUser.userId,
        session_id: sessionId,
        tracks: {
          video: {
            state: 'playable',
            track: fakeUser.fakeVideoStream,
          },
          screenVideo: {},
          screenAudio: {},
        },
        video: true,
        audio: false,
        local: false,
        joined_at: new Date(),
        isFake: true,
      };
    }

    // Update the callState with the newly added fake participants.
    dispatch({
      type: PARTICIPANTS_CHANGE,
      participants: { ...daily.participants(), ...fakeParticipants },
    });
  }, [meetingId, dispatch, daily]);

  return (
    <div className="z-[200] shadow-xl flex flex-col absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-md bg-white w-3/4 max-w-5xl min-h-[65%]">
      {showEjectConfirmation && (
        <EjectUsersPopup
          handleSubmit={() => {
            if (isEjectAllClicked) {
              handleEjectAll(selectedUserObjectsList);
            } else if (isEjectUserClicked) {
              handleEjectMeetingUser(selectedUser, () => setSelectedUser(user));
            }
            removeEjectPopup();
          }}
          handleCancel={() => setShowEjectConfirmation(false)}
          isMultipleUsers={selectedUserObjectsList.length > 1}
        />
      )}
      <div className="relative mx-10">
        {/* X button to close popup */}
        <button
          onClick={() => setSelectedMeetingControlButton('')}
          className="absolute top-5 right-5 cursor-pointer font-bold text-blue-gray"
        >
          &#10005;
        </button>

        <div className="flex items-center h-10 space-x-2">
          <div className="mt-4">
            <Title>Attendees</Title>
          </div>

          {robotController && (
            <div className="mt-2">
              <Button
                color={Button.colors.PURPLE}
                padding={Button.padding.SMALL}
                onClick={() => {
                  addFakeUsers();
                  setEnableFakeUsersButton(false);
                }}
                state={!enableFakeUsersButton && Button.states.DISABLED}
              >
                <span className="text-sm">Add Bots</span>
              </Button>
            </div>
          )}
        </div>

        {joinedUsers.length >= 2 && (
          <MeetingToolBox
            meetingId={meetingId}
            processedActions={processedActions}
            roleSuggestions={roleSuggestions}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            selectedUserObjectsList={selectedUserObjectsList}
            isEjectAllUsersLoading={isEjectAllUsersLoading}
            user={user}
            showEjectAllUsersPopup={showEjectAllUsersPopup}
          />
        )}
      </div>

      <MeetingAttendeesTable
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        joinedUsers={joinedUsers}
        roleSuggestions={roleSuggestions}
        meetingId={meetingId}
        searchValue={searchValue}
        editingUser={user}
        handleEjectMeetingUser={handleEjectMeetingUser}
        isEjectingUserLoading={isEjectingUserLoading}
        ejectedUser={ejectedUser}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        showEjectUserPopup={showEjectUserPopup}
      />
    </div>
  );
};
