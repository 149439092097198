import React from 'react';
import styled from 'styled-components';
import { absolutePosition, sizeToString } from '../helper/css';

export const defaultCloseButtonSize = 36;

/**
 * A styled close button that adjusts to light or dark backgrounds.
 * See tooltip pane for a usage example. Does not include any absolute
 * positioning code, however the CloseButton can be extended.
 *
 * @example
 * import styled from "styled-components";
 *
 * export const PositionedCloseButton = styled(CloseButton)`
 *   position: absolute;
 *   top: 10px;
 *   right: 10px;
 * `;
 *
 * @example
 * export const StylePositionedCloseButton = () => (
 *   <CloseButton style={{ position: "absolute", top: 10, right: 10 }} />
 * );
 */
export const CloseButton = React.forwardRef(
  (
    {
      /** Handles when the button is clicked. */
      onClose,
      /** Whether the button will respond to interaction. */
      isDisabled,
      /** Change the button display based on whether the background is light or dark. */
      isOnLightBackground = true,
      /** The diameter or size of the button. */
      size = defaultCloseButtonSize,

      ...rest
    },
    ref
  ) => (
    <CloseButtonContainer
      {...rest}
      ref={ref}
      light={isOnLightBackground}
      onClick={isDisabled ? undefined : onClose}
      size={size}
    >
      &times;
    </CloseButtonContainer>
  )
);

/**
 * The X (close) button. Stays fixed in the top
 * right corner of the tooltip pane.
 */
export const CloseButtonContainer = styled.div`
  // size px diameter circle.
  ${({ position }) => absolutePosition(position)}
  width: ${(props) => sizeToString(props.size)};
  height: ${(props) => sizeToString(props.size)};
  border-radius: 100%;

  // with a light theme (dark text, light background), it should be slightly darker. Otherwise lighter.
  background: ${({ light }) =>
    light ? `rgba(255, 255, 255, 0.2)` : `rgba(0, 0, 0, 0.2)`};
  color: ${({ light }) => (light ? `#000000` : `#ffffff`)};

  cursor: pointer;
  user-select: none;

  // center the X
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  padding-top: 3px;
  padding-left: 1px;
  box-sizing: border-box;

  ${({ shadow }) => (shadow ? 'box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);' : null)}
`;
