import React from 'react';

import classNames from '../../../helper/classNames';

const MediaButton = ({
  children,
  disabled,
  onClick,
  isSelected,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <button
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      disabled={disabled}
      className={classNames(
        'w-14 h-14 active:translate-y-1 duration-100 cursor-pointer rounded-xl hover:bg-opacity-10 hover:bg-purple',
        isSelected && 'border-2 border-purple bg-opacity-10 bg-purple'
      )}
    >
      {children}
    </button>
  );
};

export default MediaButton;
