import styled from 'styled-components';
import { typography } from '../../theme/baseStyles';

export const WarningWrapper = styled.div`
  background: rgba(238, 69, 64, 0.1);
  border-radius: 4px;
  padding: 30px;
  max-width: max-content;
`;

export const WarningTitle = styled.h4`
  ${typography.h4};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
  line-height: 150%;
`;

export const WarningMessage = styled.p`
  ${typography.paragraphRegular};
  color: #696f8c;
  margin-bottom: 20px;
`;
