import React, { lazy as reactLazy } from 'react';
import { logerror } from './contextualLogger';
import { toast } from 'react-toastify';
import { Button } from '../components/common/Button';

const pageReloadFlagKey = 'page-reload-flag';

const setFlag = (value) =>
  window.localStorage.setItem(pageReloadFlagKey, JSON.stringify(value));
const getFlag = () =>
  JSON.parse(window.localStorage.getItem(pageReloadFlagKey) ?? 'false');

const dispatchReloadAppToast = () => {
  toast(
    <div className="flex gap-x-4 items-center justify-around">
      <p className="whitespace-nowrap text-sm">
        A newer version of the app is available, please reload to update
      </p>
      <Button
        padding={Button.padding.SMALL}
        onClick={() => {
          setFlag(true);
          window.onbeforeunload = null;
          window.location.reload();
        }}
      >
        <span className="text-sm">Reload</span>
      </Button>
    </div>,
    {
      position: 'top-left',
      className: '!fixed !top-4 !left-4 !m-0',
      draggable: false,
      autoClose: false,
      closeOnClick: false,
      closeButton: () => null,
    }
  );
};

window.testt = dispatchReloadAppToast;
/**
 * Lazy load a component with a fail fallback. The page will be reloaded if there is
 * an error loading the chunk. Only one reload will be attempted, after which
 * the error is logged.
 */
export const lazy = (componentImport) => {
  return reactLazy(async () => {
    const hasPageReloaded = getFlag();
    try {
      const component = await componentImport();

      setFlag(false);

      return component;
    } catch (error) {
      if (!hasPageReloaded) {
        // Infinite reload loop prevention: unless the force refresh has already taken place,
        // set the reload flag and reload the page.
        return dispatchReloadAppToast();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      logerror({
        message: `Error: Force page reload did not address chunk load failure: ${error.message}`,
        stacktrace: error.stack,
      });
      throw error;
    }
  });
};
