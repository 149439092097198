import React from 'react';
import styled, { css } from 'styled-components';
import { isMobile } from '../../helper';
import { typography } from '../../theme/baseStyles';
import Editable from '../common/Editable';

const SeriesTitle = ({ title, handleEdit, isEditable }) => {
  return (
    <Editable
      TextComponent={() => <Title>{title}</Title>}
      handleEdit={handleEdit}
      isEditable={isEditable}
    />
  );
};

const Title = styled.h1`
  ${typography.h1};
  color: ${({ theme }) => theme.palette.text.primaryHeadline};
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 30px;
  line-height: 1.2;

  ${isMobile &&
  css`
    font-size: 20px;
  `};
`;

export default SeriesTitle;
