import React, { memo } from 'react';

import { ZyncErrorModal } from '../components/ZyncErrorModal';
import { getStaticAssetUrl } from '../helper/getStaticAssetUrl';

const error403BlowWhistle = getStaticAssetUrl(
  'blow-whistle-and-raise-hand-forbidden.svg'
);

export const Page403 = memo(() => {
  return (
    <ZyncErrorModal
      title="You do not have access to this page"
      message="Please contact your admin for access"
      imgSrc={error403BlowWhistle}
    />
  );
});
