import React from 'react';

export const CircledArrowIcon = ({
  width = '22px',
  height = '22px',
  className = 'fill-purple',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M11.0134 4.08928C10.7723 4.08928 10.5714 4.16963 10.4107 4.33035L9.19197 5.5491C9.03126 5.70981 8.9509 5.91071 8.9509 6.15178C8.9509 6.39285 9.03126 6.59374 9.19197 6.75446L11.7232 9.28571L5.00001 9.28571C4.76787 9.28571 4.56697 9.37053 4.39733 9.54017C4.22769 9.70981 4.14287 9.91071 4.14287 10.1428L4.14287 11.8571C4.14287 12.0893 4.22769 12.2902 4.39733 12.4598C4.56697 12.6295 4.76787 12.7143 5.00001 12.7143L11.7232 12.7143L9.19197 15.2455C9.02233 15.4152 8.93751 15.6161 8.93751 15.8482C8.93751 16.0803 9.02233 16.2812 9.19197 16.4509L10.4107 17.6696C10.5714 17.8303 10.7723 17.9107 11.0134 17.9107C11.2545 17.9107 11.4554 17.8303 11.6161 17.6696L16.4643 12.8214L17.683 11.6027C17.8438 11.442 17.9241 11.2411 17.9241 11C17.9241 10.7589 17.8438 10.558 17.683 10.3973L16.4643 9.17856L11.6161 4.33035C11.4554 4.16963 11.2545 4.08928 11.0134 4.08928ZM11 0.714277C12.8661 0.714277 14.5871 1.1741 16.163 2.09374C17.7388 3.01338 18.9866 4.26115 19.9063 5.83704C20.8259 7.41294 21.2857 9.13392 21.2857 11C21.2857 12.8661 20.8259 14.587 19.9063 16.1629C18.9866 17.7388 17.7388 18.9866 16.163 19.9062C14.5871 20.8259 12.8661 21.2857 11 21.2857C9.13394 21.2857 7.41296 20.8259 5.83706 19.9062C4.26117 18.9866 3.0134 17.7388 2.09376 16.1629C1.17412 14.587 0.714295 12.8661 0.714295 11C0.714295 9.13392 1.17412 7.41294 2.09376 5.83704C3.0134 4.26115 4.26117 3.01338 5.83706 2.09374C7.41296 1.1741 9.13394 0.714277 11 0.714277Z" />
  </svg>
);
