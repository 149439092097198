import React from 'react';

export const RefreshIcon = ({ color = '#696F8C', width = 18, height = 19 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 7H12.5C12.2239 7 12 6.77614 12 6.5V6.20999C11.998 6.07441 12.0523 5.94407 12.15 5.85L13.93 4.06999C12.6297 2.74828 10.8541 2.00272 9 1.99999C5.19715 2.00858 2.09678 5.0517 2.01733 8.85373C1.93787 12.6558 4.90841 15.8257 8.70758 15.9932C12.5068 16.1606 15.7447 13.2643 16 9.46999C16.0159 9.20563 16.2352 8.99952 16.5 9H17.5C17.6363 9.00405 17.7656 9.06151 17.86 9.16C17.9519 9.26048 17.9989 9.3941 17.99 9.52999C17.7416 13.767 14.5669 17.2546 10.3718 17.899C6.17675 18.5434 2.10165 16.1694 0.593101 12.2022C-0.915448 8.23508 0.552615 3.7532 4.11591 1.44737C7.6792 -0.858458 12.3691 -0.361455 15.37 2.64L16.86 1.14999C16.9517 1.05485 17.0779 1.00076 17.21 0.999995H17.5C17.7761 0.999995 18 1.22385 18 1.49999V6.5C18 6.77614 17.7761 7 17.5 7Z"
        fill={color}
      />
    </svg>
  );
};
