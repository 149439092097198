import styled from 'styled-components';
import { typography } from '../../theme/baseStyles';

export const TabHeader = styled.div`
  ${typography.h1};
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const TabHeaderSub = styled.div`
  ${typography.bodyLight};
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

export const PanelContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(2)}px;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0 8px 20px rgba(156, 168, 186, 0.5);
  border-radius: 8px;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  box-sizing: border-box;
`;

export const PanelHeader = styled.div`
  margin: ${({ theme }) => -theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: ${({ theme }) => theme.palette.background.field};
`;

export const SeriesLabel = styled.p`
  ${typography.titleHeavy};
`;
