import { useCallback, useEffect, useRef, useState } from 'react';
import {
  fromRawContentState,
  getCustomStylesFromState,
  toRawContentState,
} from '../components/richText/helpers';
import { EditorState } from 'draft-js';
import { debounce } from 'lodash';
import { strategyDecorator } from '../components/richText/editor/helper';
import { RichTextEditor, RichTextToolbar } from '../components/richText';
import { positions } from '../components/richText/toolbar/ToolbarAutoPosition';

export const useEpisodeSummary = ({
  series,
  disabled,
  episodeSummary,
  updateSettings,
}) => {
  const isInitialized = useRef(false);

  const episodeSummarySettings = series?.settings?.episodeSummary;

  const textEditorInitialValues = {
    color: '#000000',
    fontSize: '24px',
    fontFamily: 'Poppins',
  };

  const [editorState, setEditorState] = useState(() => {
    const state = fromRawContentState(episodeSummarySettings ?? episodeSummary);

    return EditorState.moveFocusToEnd(EditorState.createWithContent(state));
  });

  const [lastEditValue, setLastEditValue] = useState(() =>
    toRawContentState(editorState.getCurrentContent())
  );

  useEffect(() => {
    if (isInitialized.current) {
      return;
    }

    isInitialized.current = true;

    const newEditorState = EditorState.createWithContent(
      fromRawContentState(episodeSummarySettings ?? episodeSummary),
      strategyDecorator
    );

    getCustomStylesFromState(newEditorState, 'color');

    setEditorState(newEditorState);
  }, [episodeSummary, episodeSummarySettings]);

  const debouncedHandleChange = debounce(
    (editorState) => {
      const content = editorState.getCurrentContent();
      const text = toRawContentState(content);
      if (lastEditValue !== text) {
        setLastEditValue(text);
        updateSettings('episodeSummary', text);
      }
    },
    600,
    { maxWait: 3000 }
  );

  const handleChange = useCallback(
    (editorState) => {
      setEditorState(editorState);
      debouncedHandleChange(editorState);
    },
    [debouncedHandleChange]
  );

  return {
    RichTextToolbar: (
      <RichTextToolbar
        editorState={editorState}
        onChange={handleChange}
        initialValues={textEditorInitialValues}
        bestPosition={positions.TOP}
        enableHyperlinks
        disabled={disabled}
      />
    ),
    RichTextEditor: (
      <RichTextEditor editorState={editorState} onChange={handleChange} />
    ),
  };
};
