const { sceneTemplateKeyMap } = require('../types');
const { convertUTCToTimezone } = require('./timezone');
const moment = require('moment');

const socialMediaOptions = {
  twitter: {
    modalTitle: 'Twitter Post',
  },
  facebook: {
    modalTitle: 'Facebook Post',
  },
  linkedIn: {
    modalTitle: 'LinkedIn Post',
  },
};

const getBeginningOfPrompt = (socialMediaType) => {
  switch (socialMediaType) {
    case 'facebook':
    case 'linkedIn':
    case 'twitter':
      return `Write a ${socialMediaOptions[socialMediaType].modalTitle} inviting people for an expert interview I'm hosting`;
    case 'email':
      return `Write a 100 word email inviting people for an expert interview I'm hosting`;
    case 'messaging':
      return `Write a personal message in 60 words inviting people for an expert interview I'm hosting`;
    default:
      return ``;
  }
};

const getPromoteSocialMediaPrompt = (series, socialMediaType, domain) => {
  const speaker = (series?.settings?.eventPresenters || []).find(
    (ep) => ep.type === 'speaker'
  );
  const title = series?.title;
  const timezone = series?.scheduledEvent?.timezone;
  const startDate = timezone
    ? convertUTCToTimezone(
        series?.scheduledEvent?.startDate,
        timezone,
        'date-time'
      )
    : null;
  const eventRegistrationLink = domain
    ? `${domain}/e/${series.meetingSeriesId}/register`
    : `localhost:3000/e/${series.meetingSeriesId}/register`;
  const eventPosterImage = series?.settings?.eventPosterImage;

  const day = moment(new Date(series?.scheduledEvent?.startDate)).format(
    'Do MMMM yyyy'
  );

  let prompt = getBeginningOfPrompt(socialMediaType);
  prompt +=
    speaker?.fullName && speaker?.jobTitle
      ? `with ${speaker.fullName} who is ${speaker.jobTitle}. `
      : '. ';
  prompt += title && `We are going to be talking on the topic of ${title}. `;
  prompt +=
    startDate &&
    timezone &&
    `Event to be held at ${day} ${startDate} ${timezone}. `;
  prompt += `Please register using ${eventRegistrationLink}. `;
  //prompt += eventPosterImage && `Include this poster ${eventPosterImage}. `;

  switch (socialMediaType) {
    case 'linkedIn': {
      prompt +=
        title &&
        `Make the first paragraph of the post be about why the topic: ${title} is very relevant now and limit that to 50 words. Include a catchy first line.`;
      break;
    }
    case 'twitter': {
      prompt += 'Include hashtags.';
      break;
    }
    case 'email': {
      prompt += 'Write it as a template with placeholder for First Name.';
      break;
    }
    case 'messaging': {
      prompt +=
        'Write it as a template with placeholder for First Name. Make it a bit casual and personal.';
      break;
    }
    default:
      break;
  }
  return prompt;
};

const getEventDescriptionPrompt = (series) => {
  const {
    settings: { eventPresenters },
    autoLaunchConfig,
    title,
  } = series;
  const speaker = eventPresenters.find(
    (eventPresenter) => eventPresenter.type === 'speaker'
  );
  const host = eventPresenters.find(
    (eventPresenter) => eventPresenter.type === 'host'
  );
  const { user: hostUser } = host || {};
  let prompt = `Give me a 150 word welcome description for a Show that i am hosting with the title: ${title}. Let the narration be from me.`;

  if (host) {
    if (hostUser && hostUser?.userName && hostUser?.bio) {
      prompt += `I, The host will be ${hostUser.userName}. My bio is ${hostUser.bio}.`;
    } else if (host?.fullName && host?.jobTitle) {
      prompt += `I, The host will be ${host.fullName}, ${host.jobTitle}.`;
    }
  }
  if (speaker && speaker?.fullName && speaker?.jobTitle) {
    prompt += `The speaker will be ${speaker.fullName} who is a ${speaker.jobTitle}.`;
  }

  const scenes = autoLaunchConfig?.slides;
  const sceneInterviewScenes = (scenes || []).filter(
    (scene) => scene.sceneTemplateKey === sceneTemplateKeyMap.interview
  );

  if (sceneInterviewScenes) {
    let topics = '';
    sceneInterviewScenes.forEach((scene) => {
      topics += `${scene.slideName}, `;
    });
    prompt += `Include in bullet points the topics mentioned as Discuss:${topics}.`;
    prompt +=
      'End with call to action to request the reader to attend the show. Do not include place holder for date,time,venue or registration link.';
  }

  return prompt;
};

const getDescriptionForIcsFile = (title, mode, inviteUrl) => {
  switch (mode) {
    case 'speaker': {
      return `You are the Speaker at this Event: ${title}\n\nPlease join with your unique link here: ${inviteUrl}`;
    }
    case 'host': {
      return `You are the Host of the Event: ${title}\n\nPlease join with your unique link here: ${inviteUrl}`;
    }
    default:
      return `You are registered to attend the Event: ${title}\n\nPlease join with your unique link here: ${inviteUrl}`;
  }
};

const getEventTopicPrompt = (series) => {
  let prompt =
    'What would be a discussion point for the event in the json below? Your answer should be a short phrase that would go in the run of show. It should not be more than 150 characters. There should be no hashtags. Make sure that the new discussion point is unique and unrelated to the discussion_points specified in the json.';

  const host = (series?.settings?.eventPresenters || []).find(
    (ep) => ep.type === 'host'
  );
  const speakers = (series?.settings?.eventPresenters || []).filter(
    (ep) => ep.type === 'speaker'
  );
  const json = {
    event_title: series.title,
  };

  if (host) {
    json.host_name = host.fullName;
    json.host_bio = host.jobTitle;
    json.host_linkedin_url = host.linkedInUrl;
  }

  if (speakers) {
    json.speakers = speakers;
  }

  const discussion_topics = (series?.autoLaunchConfig?.slides || [])
    .filter(
      (s) =>
        s.sceneTemplateKey === sceneTemplateKeyMap.interview ||
        s.sceneTemplateKey === sceneTemplateKeyMap.panel_interview ||
        s.sceneTemplateKey ===
          sceneTemplateKeyMap.scene_discussion_point_no_speaker
    )
    .map((s) => s.slideName.replace('Discuss: ', ''))
    .map((t) => t.replace('Topic: ', ''))
    .map((t) => t.replace('Discussion Point: ', ''));

  json.discussion_topics = discussion_topics;

  prompt += `\n ${JSON.stringify(json)}`;
  return prompt;
};

module.exports = {
  getDescriptionForIcsFile,
  getPromoteSocialMediaPrompt,
  getEventDescriptionPrompt,
  getEventTopicPrompt,
};
