import React, { useContext, useRef, useState } from 'react';
import { BlockSettings, SettingsInput } from '../blockHelpers';
import { Button } from '../common/Button';
import { Divider } from '../meetingTabs/Invite/InviteTab.styled';
import { RightPanelDiv } from './AuthoringTool.styled';
import { useAuthoringTool } from './hooks';
import { useShowUnviewedItems } from '../../hooks/useViewedBlocks';
import {
  RightPanelHeader,
  RightPanelHeaderBlockIcon,
} from './RightPanel.styled';
import { PositionedCloseButton } from '../common/CloseButton';
import { DeleteBlockConfirmation } from './Confirmation';
import { VideoToolTip } from '../common/ToolTip';
import { InfoIcon } from '../common/Icon';
import { UserSettingsContext } from './userSettingsContext';
import { DeleteSceneConfirmation } from '../Modal';
import classNames from '../../helper/classNames';

export const SlideSettingsPane = () => {
  const { isDeleteSceneHidden } = useContext(UserSettingsContext);

  const {
    selectedSlide,
    updateTemplateSlide,
    deleteTemplateScene,
    selectedSlideBackgroundAutoColor,
  } = useAuthoringTool();

  const [showDelete, setShowDelete] = useState(false);

  if (!selectedSlide) {
    return null;
  }

  const key = selectedSlide?.sceneId;

  return (
    <>
      <RightPanelHeader>{selectedSlide.friendlyName} Settings</RightPanelHeader>

      <Divider />

      <SettingsInput
        uniqueKey={key}
        settings={selectedSlide}
        updateSettings={updateTemplateSlide}
        field="slideName"
        label="Scene Title"
        updateOnBlur
      />

      <SettingsInput
        uniqueKey={key}
        settings={selectedSlide}
        updateSettings={updateTemplateSlide}
        field="sceneDescription"
        label="Scene Description"
        type="textarea"
        updateOnBlur
      />

      <SettingsInput
        uniqueKey={key + selectedSlideBackgroundAutoColor}
        settings={selectedSlide}
        defaultValue={selectedSlideBackgroundAutoColor}
        updateSettings={updateTemplateSlide}
        field="slideBackgroundColor"
        label="Scene Background Color"
        type="color"
        updateOnBlur
        custom={true}
      />

      {showDelete && (
        <DeleteSceneConfirmation
          activateKeyboardShortcuts={showDelete}
          onCancel={() => setShowDelete(false)}
        />
      )}

      <div className="px-2 mt-2.5">
        <Button
          color={Button.colors.RED}
          size={Button.sizes.FULL}
          onClick={
            isDeleteSceneHidden
              ? () => deleteTemplateScene()
              : () => setShowDelete(true)
          }
        >
          <span className="text-xs font-medium">Delete Scene</span>
        </Button>
      </div>
    </>
  );
};

export const BlockSettingsPane = () => {
  const { isDeleteBlockHidden } = useContext(UserSettingsContext);

  const [showDelete, setShowDelete] = useState(false);
  const {
    getBlockById,
    selectedSlideBlock,
    selectSlideBlock,
    removeSelectedSlideBlock,
  } = useAuthoringTool();

  const currentBlockId = selectedSlideBlock?.blockId ?? null;

  const [unviewedTips, resetTip, showTip] =
    useShowUnviewedItems(currentBlockId);

  const block = getBlockById(currentBlockId);
  const blockTitle = block?.details?.title ?? 'Block';
  const blockDescription = block?.details?.description;
  const hasBlockSettings = Object.keys(block?.settings || {}).length !== 0;

  const iconRef = useRef(null);

  return (
    <>
      <RightPanelHeader>
        {block?.details?.iconUrl && (
          <RightPanelHeaderBlockIcon
            src={block.details.iconUrl}
            alt={blockTitle}
          />
        )}
        {blockTitle}
        <InfoIcon iconRef={iconRef} onClick={() => showTip(currentBlockId)} />
        {blockDescription && (
          <VideoToolTip
            referenceElement={iconRef.current}
            text={blockDescription}
            videoUrl={block.details?.videoUrl ?? null}
            videoDescription={`See ${
              block?.details?.title ?? 'this block'
            } in action`}
            open={unviewedTips[currentBlockId]}
            onClose={() => resetTip(currentBlockId)}
          />
        )}
      </RightPanelHeader>
      <PositionedCloseButton onClose={() => selectSlideBlock(null)} />
      <BlockSettings />
      <DeleteBlockConfirmation
        open={showDelete}
        onClose={() => setShowDelete(false)}
      />
      <div className={classNames('px-2', !hasBlockSettings && 'mt-2.5')}>
        <Button
          color={Button.colors.RED}
          size={Button.sizes.FULL}
          onClick={
            isDeleteBlockHidden
              ? () => removeSelectedSlideBlock()
              : () => setShowDelete(true)
          }
        >
          <span className="text-xs font-medium">Delete Block</span>
        </Button>
      </div>
    </>
  );
};

export const RightPanel = () => {
  const { selectedSlideBlock, rightPanelOpen, slideOptionsOpen } =
    useAuthoringTool();

  return (
    <RightPanelDiv tabIndex={1}>
      {rightPanelOpen && selectedSlideBlock?.blockId && <BlockSettingsPane />}
      {slideOptionsOpen && !selectedSlideBlock && <SlideSettingsPane />}
    </RightPanelDiv>
  );
};
