export const SearchIcon = ({
  width = '12',
  height = '13',
  className = 'fill-[#8F95B2]',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.66537 8.56886L11.6666 10.5776C11.869 10.78 11.9964 11.0648 11.9964 11.3721C11.9964 11.9942 11.4942 12.4964 10.8721 12.4964C10.5648 12.4964 10.28 12.369 10.0776 12.1666L8.06886 10.1654C7.25937 10.6825 6.29248 10.9973 5.25063 10.9973C2.34994 10.9973 0.00390625 8.65131 0.00390625 5.75063C0.00390625 2.84994 2.34994 0.503906 5.25063 0.503906C8.15131 0.503906 10.4973 2.84994 10.4973 5.75063C10.4973 6.78498 10.19 7.75188 9.66537 8.56886ZM1.50297 5.75063C1.50297 7.81933 3.18192 9.49829 5.25063 9.49829C7.31933 9.49829 8.99829 7.81933 8.99829 5.75063C8.99829 3.68192 7.31933 2.00297 5.25063 2.00297C3.18192 2.00297 1.50297 3.68192 1.50297 5.75063Z"
      />
    </svg>
  );
};
