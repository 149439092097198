import { useClickOutside } from '../../../../helper/useClickOutside';
import React, { useCallback, useEffect } from 'react';
import { DropdownSelect } from '../../../../components/common/Dropdown';
import { TextInput } from '../../../../components/Input';

export const ThisOrThatUpdate = ({
  posterSlideTemplate,
  setPosterSlideTemplate,
  setUpdate,
  onConfirm,
  onCancel,
  updateOnClose,
  series,
  hostNotesComponent,
}) => {
  const thisOrThatBlock = posterSlideTemplate.blocks?.findLast(
    (block) => block.blockId === 'this-or-that'
  );

  useEffect(() => {
    if (
      posterSlideTemplate.slideName === 'This Or That' ||
      posterSlideTemplate.slideName === 'This or That - No Speaker'
    ) {
      setPosterSlideTemplate((prevPoster) => ({
        ...prevPoster,
        slideName:
          'This or that: For your social media posts which would you choose?',
      }));
    }
  }, [posterSlideTemplate, setPosterSlideTemplate]);

  const handleConfirm = (shouldOpenNewlyCreated) =>
    onConfirm(
      posterSlideTemplate,
      `This or that: ${thisOrThatBlock?.settings?.questionText}`,
      shouldOpenNewlyCreated
    );

  useClickOutside({ current: document.querySelector('.modal') }, () => {
    updateOnClose && handleConfirm(false);
    onCancel();
  });

  const handleMainSpeakerBlockChange = (settingsKey, value) => {
    setPosterSlideTemplate((template) => {
      if (thisOrThatBlock) {
        thisOrThatBlock.settings[settingsKey] = value;
      }

      return template;
    });

    setUpdate((x) => x + 1);
  };

  const handleVideoBlockChange = useCallback(
    (settingsKey, value) => {
      setPosterSlideTemplate((template) => {
        if (thisOrThatBlock) {
          thisOrThatBlock.settings[settingsKey] = value;
        }

        return template;
      });

      setUpdate((x) => x + 1);
    },
    [setPosterSlideTemplate, setUpdate, thisOrThatBlock]
  );

  const eventPresenters = series.settings.eventPresenters;

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="flex flex-col gap-3">
        <div>
          <h4 className="text-sm font-medium text-blue-dark mb-1">
            Choose a Presenter
          </h4>
          <DropdownSelect
            selectedKey={thisOrThatBlock?.settings.intervieweeVideoUserRole}
            options={eventPresenters
              .slice()
              .sort((a, b) =>
                a.type === b.type ? 0 : a.type > b.type ? -1 : 1
              )
              .map((ep) => ({
                key: ep.emailAddress,
                title: ep.fullName,
              }))}
            onSelect={(value) => {
              handleMainSpeakerBlockChange('intervieweeVideoUserRole', value);
              handleConfirm();
            }}
          />
        </div>

        <div>
          <h4 className="text-sm font-medium text-blue-dark mb-1">
            Question to the speaker
          </h4>
          <TextInput
            value={thisOrThatBlock.settings.questionText}
            onChange={(event) => {
              posterSlideTemplate.slideName = `This or that: ${event.nativeEvent.target.value}`;
              handleVideoBlockChange(
                'questionText',
                event.nativeEvent.target.value
              );
            }}
            className="w-full p-2 border border-gray rounded"
            placeholder="What are the top 3 concerns for you right now?"
            onBlur={handleConfirm}
            textLength={75}
          />
          <h4 className="text-sm font-medium text-blue-dark mb-1">
            The first answer
          </h4>
          <TextInput
            value={thisOrThatBlock.settings.thisText}
            onChange={(event) =>
              handleVideoBlockChange('thisText', event.nativeEvent.target.value)
            }
            className="w-full p-2 border border-gray rounded"
            placeholder="What are the top 3 concerns for you right now?"
            onBlur={handleConfirm}
            textLength={50}
          />
          <h4 className="text-sm font-medium text-blue-dark mb-1">
            The second answer
          </h4>
          <TextInput
            value={thisOrThatBlock.settings.thatText}
            onChange={(event) =>
              handleVideoBlockChange('thatText', event.nativeEvent.target.value)
            }
            className="w-full p-2 border border-gray rounded"
            placeholder="What are the top 3 concerns for you right now?"
            onBlur={handleConfirm}
            textLength={50}
          />
        </div>
      </div>
      <div>{hostNotesComponent}</div>
    </div>
  );
};
