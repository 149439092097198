import { useEffect, useState } from 'react';
import { checkpointNames, MEETING_TOPIC } from './constants';
import { useUserWorkspaces } from '../../hooks/useUserWorkspaces';
import { validateTimes } from '../../helper/rsvp';
import { fetchWorkspaceApi } from '../../helper/api';
import { buttonFunctionalityClasses } from '../../components/onboarding/constants';

export const checkpoints = {
  [checkpointNames.MEETING_TOPIC]: MEETING_TOPIC,
};

export const useCreateNewSoloInterview = ({
  createNewEvent,
  scheduledEvent,
  title,
  eventSpeakersCount,
}) => {
  const [checkpointsData] = useState(checkpoints);
  const [workspace, setWorkspace] = useState(null);
  const { brandKit } = workspace || {};
  const [MEETING_TOPIC] = [checkpointsData[checkpointNames.MEETING_TOPIC]];

  const [checkpoint] = useState(MEETING_TOPIC);

  const [nextButtonStyling, setNextButtonStyling] = useState(
    buttonFunctionalityClasses.ENABLE
  );

  const [previousButtonStyling, setPreviousButtonStyling] = useState(
    buttonFunctionalityClasses.DISABLE
  );

  const [isLoading, setIsLoading] = useState(false);

  const { currentWorkspaceId } = useUserWorkspaces();
  useEffect(() => {
    const getWorkspace = async () => {
      const { result } = await fetchWorkspaceApi(currentWorkspaceId);
      if (!result) return;
      setWorkspace(result);
    };
    getWorkspace();
  }, [currentWorkspaceId]);

  const styleToDisablePreviousButtonAndEnableNextButton = () => {
    setPreviousButtonStyling(buttonFunctionalityClasses.DISABLE);
    setNextButtonStyling(buttonFunctionalityClasses.ENABLE);
  };

  const styleToEnablePreviousButtonAndNextButton = () => {
    setPreviousButtonStyling(buttonFunctionalityClasses.ENABLE);
    setNextButtonStyling(buttonFunctionalityClasses.ENABLE);
  };

  const styleToDisablePreviousButtonAndDisableNextButton = () => {
    setPreviousButtonStyling(buttonFunctionalityClasses.DISABLE);
    setNextButtonStyling(buttonFunctionalityClasses.DISABLE);
  };

  /** Previous and Next button styling. */
  useEffect(() => {
    switch (checkpoint) {
      case MEETING_TOPIC:
        if (!title || (title && title.trim().length === 0)) {
          styleToDisablePreviousButtonAndDisableNextButton();
        } else {
          styleToDisablePreviousButtonAndEnableNextButton();
        }
        break;
      default:
        styleToEnablePreviousButtonAndNextButton();
        break;
    }
  }, [checkpoint, MEETING_TOPIC, title]);

  const setPreviousCheckpoint = (currentCheckpoint = checkpoint) => {
    switch (currentCheckpoint) {
      default:
    }
  };

  const setNextCheckpoint = (currentCheckpoint = checkpoint) => {
    switch (currentCheckpoint) {
      default:
    }
  };

  const handlePreviousClick = () => {
    // If the DISABLE class is applied, do not allow previous click.
    if (previousButtonStyling === buttonFunctionalityClasses.DISABLE) {
      return;
    }
    setPreviousCheckpoint();
  };

  const handleNextClick = () => {
    // If the DISABLE class is applied, do not allow next click.
    if (nextButtonStyling === buttonFunctionalityClasses.DISABLE) {
      return;
    }
    if (checkpoint === MEETING_TOPIC) {
      // last action
      setIsLoading(true);
      createNewEvent({ topic: title });
    } else {
      setNextCheckpoint();
    }
  };

  /** Returns true/false depending on if the checkpoint in state comes before the argument, checkpoint. */
  const isBeforeCheckpoint = (currentCheckpointName) => {
    const currentCheckpoint = checkpointsData[currentCheckpointName];
    const stateCheckpoint = checkpoint;
    if (currentCheckpoint === stateCheckpoint) {
      return false;
    }
    for (let checkpoint in checkpointsData) {
      // found the state checkpoint first
      if (stateCheckpoint === checkpointsData[checkpoint]) {
        return true;
      }
      // found the current checkpoint first
      if (currentCheckpoint === checkpointsData[checkpoint]) {
        return false;
      }
    }
  };

  /** Returns true/false depending on if the checkpoint in state is at the argument, checkpoint. */
  const isAtCheckpoint = (currentCheckpointName) => {
    const currentCheckpoint = checkpointsData[currentCheckpointName];
    return currentCheckpoint === checkpoint;
  };

  const ProgressStates = {
    BEFORE: 'BEFORE',
    CURRENT: 'CURRENT',
    AFTER: 'AFTER',
  };

  /** Every checkpoint is looped over to determine its relationship to the checkpoint in state.
   *  This is used to determine the color & attributes that the currentCheckpoint should contain.
   *  If the checkpoint in state comes before currentCheckpoint, then its state is BEFORE
   *  If the checkpoint in state is at currentCheckpoint, then its state is CURRENT
   *  If the checkpoint in state is after currentCheckpoint, then its state is AFTER
   */
  const getProgressState = (currentCheckpoint) => {
    const isBefore = isBeforeCheckpoint(currentCheckpoint);
    const isAt = isAtCheckpoint(currentCheckpoint);
    if (isBefore) {
      return ProgressStates.BEFORE;
    }
    if (isAt) {
      return ProgressStates.CURRENT;
    }
    return ProgressStates.AFTER;
  };

  const areDatesValid = validateTimes(
    scheduledEvent.currentDate,
    scheduledEvent.startDate,
    scheduledEvent.endDate
  );

  return {
    brandKit,
    checkpoint,
    checkpointsData,
    isLoading,

    getProgressState,

    handlePreviousClick,
    handleNextClick,

    previousButtonStyling,
    nextButtonStyling,

    styleToDisablePreviousButtonAndDisableNextButton,
    styleToEnablePreviousButtonAndNextButton,

    areDatesValid,
  };
};
