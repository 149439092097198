import React from 'react';

export const ThisOrThatIcon = ({
  width = 18,
  height = 21,
  className = 'fill-[#8B33F7]',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_18889_17898)">
      <path d="M8.355 4.96094C7.335 4.96094 6.515 5.79094 6.515 6.80094V15.9009L5.505 14.8909C4.755 14.1409 3.545 14.1509 2.805 14.8909C2.065 15.6309 2.065 16.8409 2.805 17.5909L5.805 20.5909C6.005 20.7909 6.315 20.7909 6.515 20.5909C6.715 20.3909 6.715 20.0809 6.515 19.8809L3.515 16.8809C3.165 16.5309 3.165 15.9509 3.515 15.6009C3.865 15.2509 4.445 15.2509 4.805 15.6009L6.665 17.4609C6.805 17.6009 7.025 17.6509 7.205 17.5709C7.395 17.4909 7.515 17.3109 7.515 17.1109V6.81094C7.515 6.35094 7.895 5.97094 8.355 5.97094C8.815 5.97094 9.195 6.35094 9.195 6.81094V12.6409C9.195 12.8809 9.365 13.0909 9.605 13.1309L13.625 13.8609C14.575 14.0309 15.255 14.8609 15.255 15.8209C15.255 16.0209 15.225 16.2209 15.165 16.4109L14.015 20.1009C13.935 20.3609 14.085 20.6409 14.345 20.7309C14.395 20.7509 14.445 20.7509 14.495 20.7509C14.705 20.7509 14.905 20.6109 14.975 20.4009L16.125 16.7109C16.215 16.4209 16.255 16.1209 16.255 15.8209C16.255 14.3709 15.225 13.1409 13.795 12.8809L10.185 12.2209V6.80094C10.185 5.78094 9.355 4.96094 8.345 4.96094H8.355Z" />
      <path d="M4.63535 0.689063L2.16535 3.15906L1.09535 2.08906C0.895352 1.88906 0.585352 1.88906 0.385352 2.08906C0.185352 2.28906 0.185352 2.59906 0.385352 2.79906L1.80535 4.21906C1.89535 4.30906 2.02535 4.36906 2.15535 4.36906C2.28535 4.36906 2.41535 4.31906 2.50535 4.21906L5.32535 1.39906C5.52535 1.19906 5.52535 0.889063 5.32535 0.689063C5.12535 0.489063 4.81535 0.489063 4.61535 0.689063H4.63535Z" />
      <path d="M13.9054 4.09656C14.0054 4.19656 14.1354 4.24656 14.2554 4.24656C14.3754 4.24656 14.5154 4.19656 14.6054 4.09656L15.7554 2.94656L16.9054 4.09656C17.0054 4.19656 17.1354 4.24656 17.2554 4.24656C17.3754 4.24656 17.5154 4.19656 17.6054 4.09656C17.8054 3.89656 17.8054 3.58656 17.6054 3.38656L16.4554 2.23656L17.6054 1.08656C17.8054 0.886562 17.8054 0.576563 17.6054 0.376563C17.4054 0.176563 17.0954 0.176563 16.8954 0.376563L15.7454 1.52656L14.5954 0.376563C14.3954 0.176563 14.0854 0.176563 13.8854 0.376563C13.6854 0.576563 13.6854 0.886562 13.8854 1.08656L15.0354 2.23656L13.8854 3.38656C13.6854 3.58656 13.6854 3.89656 13.8854 4.09656H13.9054Z" />
    </g>
    <defs>
      <clipPath id="clip0_18889_17898">
        <rect
          width="17.51"
          height="20.5"
          fill="white"
          transform="translate(0.245117 0.25)"
        />
      </clipPath>
    </defs>
  </svg>
);
