import { episodeBuilder } from '../../helper/episodeBuilder';
import { episodeTypes } from 'zync-common/types';
import {
  createNewPrompterSlide,
  createNewVideoScriptEditorSlide,
} from './useMeetingCreator';

export const createSoloInterviewEpisodeSegments = async ({
  title,
  eventSpeakers,
  questions,
  episodeType,
  doNotCountTowardsMonthlyClips = false,
}) => {
  const { init, build } = episodeBuilder({
    title,
    slides: [
      {
        sceneTemplateKey: 'scene_lobby',
      },
      {
        sceneTemplateKey: 'solo_studio_welcome',
      },
      ...questions.map((questionSlide) => ({
        sceneTemplateKey: 'solo_video_script_editor',
        mapper: (scene) =>
          createNewVideoScriptEditorSlide(scene, questionSlide),
      })),
      {
        sceneTemplateKey: 'solo_studio_adjust_camera',
      },
      ...questions.map((questionSlide) => ({
        sceneTemplateKey: 'solo_studio_zync_prompter',
        mapper: (scene) => createNewPrompterSlide(scene, questionSlide),
      })),
      {
        sceneTemplateKey: 'solo_studio_done',
      },
    ],
    templateKey: 'meeting_speaker_interview_1x1',
    options: {
      eventPresenters: [...eventSpeakers],
      episodeType: episodeType || episodeTypes.solo,
      doNotCountTowardsMonthlyClips,
    },
  });

  await init();

  return build();
};
