import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChatNotificationDot } from '../../chat/ChatNotification';
import chatAnimation from '../Live meeting icons/chat.json';
import MediaButton from './MediaButton';
import { animationOptions } from '../meetingControlAnimationOptions';
import Lottie from 'react-lottie';

const MediaChatButton = () => {
  const [isChatAnimationStopped, setIsChatAnimationStopped] = useState(true);
  const dispatch = useDispatch();

  const liveTab = useSelector((st) => st.clientDetails.liveTab);

  const isChatTabActive = liveTab === 'chat';

  const handleToggleChat = useCallback(() => {
    dispatch({
      type: 'SELECT_LIVE_TAB',
      tab: isChatTabActive ? null : 'chat',
    });
  }, [dispatch, isChatTabActive]);

  const unread = useSelector((state) =>
    Object.values(state.chatDetails.hasUnreadMessages).find((item) => item)
  );

  const unreadMessagesNotification = unread && !isChatTabActive && (
    <div className="absolute top-0 left-9 scale-75">
      <ChatNotificationDot />
    </div>
  );

  return (
    <MediaButton
      disabled={false}
      className="cursor-pointer relative"
      onClick={handleToggleChat}
      isSelected={isChatTabActive}
      onMouseEnter={() => setIsChatAnimationStopped(false)}
      onMouseLeave={() => setIsChatAnimationStopped(true)}
    >
      <div className="flex flex-col justify-center items-center">
        <div className="w-8 h-8 text-purple">
          <Lottie
            options={{ ...animationOptions, animationData: chatAnimation }}
            isStopped={isChatAnimationStopped}
          />
        </div>
        <span className="text-purple text-xxs font-medium">Chat</span>
      </div>
      {unreadMessagesNotification}
    </MediaButton>
  );
};

export default MediaChatButton;
