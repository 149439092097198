const platform =
  window.navigator?.userAgentData?.platform || window.navigator?.platform;

const isMac = platform.toLowerCase().includes('mac');

const EntitiesActions = {
  COPY: 'COPY',
  PASTE: 'PASTE',
  DUPLICATE: 'DUPLICATE',
  REMOVE: 'REMOVE',
  HANDLE_INTERESTING_MOMENT: 'HANDLE_INTERESTING_MOMENT',
};

const systemControlKey = isMac ? '⌘' : 'Ctrl';
const controlKey = isMac ? '^' : 'Ctrl';

const createShortcut = (...keys) => {
  return keys.join('+');
};

const keyboardShortcutsOS = {
  [EntitiesActions.COPY]: createShortcut(systemControlKey, 'C'),
  [EntitiesActions.PASTE]: createShortcut(systemControlKey, 'V'),
  [EntitiesActions.DUPLICATE]: createShortcut(systemControlKey, 'D'),
  [EntitiesActions.REMOVE]: createShortcut('⌫'),
  [EntitiesActions.HANDLE_INTERESTING_MOMENT]: createShortcut(
    controlKey,
    'Space'
  ),
};

export default keyboardShortcutsOS;
