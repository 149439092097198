import React, { useEffect, useMemo, useRef, useState } from 'react';
import { getBackgroundColor, getInitials } from '../../helper';
import { useDailyVideo } from '../../hooks/useDailyVideo';
import {
  AudioIcon,
  PositionedVideoNameLabel,
  RectangularVideoIcons,
  VideoAvatar,
  VideoDimensionsContainer,
  VideoElement,
  VideoBackground,
  VideoIcon,
} from './UserVideo.style';
import { sumSizes, sizeToString, sizeToNumber } from '../../helper/css';
import { videoQualities } from '../../helper/constants';
import classNames from '../../helper/classNames';
import { CameraIcon } from '../icons/CameraIcon';
import { PersonIcon } from '../icons/PersonIcon';
import { getContrastColor } from 'zync-common/helper/color';

/** The default aspect ratio. */
const defaultAspectRatio = 9 / 16;

const minWidthVideoQualities = {
  HIGH: 320,
  MED: 160,
  LOW: 80,
};

const MAX_INTIALS_FONT_SIZE = 23;

/** Get background color given user ID. */
export const useBackgroundColor = (userId) =>
  useMemo(() => getBackgroundColor(userId), [userId]);

/** Gets the video element given the trackStates. */
export const useVideoElement = (trackStates, videoQuality) => {
  const { isActiveSpeaker, videoElement, videoTrack } = useDailyVideo(
    trackStates,
    videoQuality
  );
  const video = useMemo(() => {
    const isLocalStream = trackStates?.local ?? false;

    return videoTrack ? (
      <VideoElement
        ref={videoElement}
        isLocal={isLocalStream}
        muted
        playsInline
        autoPlay
      />
    ) : null;
  }, [trackStates, videoTrack, videoElement]);

  return { video, isActiveSpeaker };
};

const getVideoQualityBasedOnSize = (width) => {
  if (width >= minWidthVideoQualities.HIGH) {
    return videoQualities.HIGH;
  } else if (width >= minWidthVideoQualities.MED) {
    return videoQualities.MED;
  } else {
    return videoQualities.LOW;
  }
};

// the circle's diameter should be the minimum of width and height,
// and should be centered within the bounding area.
const getCircleProps = (width, height, size, position) => {
  const widthValue = sizeToNumber(size || width, null);
  const heightValue = sizeToNumber(size || height, null);
  const circleSize =
    widthValue && heightValue
      ? Math.min(widthValue, heightValue)
      : size || width || height || undefined;
  const circlePosition =
    position && typeof circleSize === 'number'
      ? {
          left: sizeToString(
            sizeToNumber(position.left) + (widthValue - circleSize) / 2
          ),
          top: sizeToString(
            sizeToNumber(position.top) + (heightValue - circleSize) / 2
          ),
        }
      : undefined;
  return {
    isCircle: true,
    size: circleSize,
    width: circleSize,
    height: circleSize,
    position: circlePosition,
  };
};

/**
 * A component to display a user video stream. `ref`
 * can be used to directly change styles or other attributes
 * on the container div element.
 */
export const UserVideo = React.forwardRef(
  (
    {
      avatarUrl,
      avatarSize,

      /** The width of the video in pixels. If not specified, defaults to 100% of it container element. */
      width,

      /** The height of the video in pixels. If not specified, defaults to the width multiplied by the aspect ratio. */
      height,

      /** The user trackStates from which to obtain video stream and element. */
      trackStates,

      /** The user ID. */
      userId,

      /** The user name for display. */
      userName,

      /** Whether to show the username label. */
      showUserName = true,

      /** The opacity of the component, including background and video element. */
      opacity,

      /** If specified, positions the element absolutely at the given coordinates, `{ left, top }`. */
      position,

      /** If circle is true, passes parameters directly to the CircleVideo component, using the minimum of `width`, `height` as the `size`. */
      circle = false,

      /** Optional. The circle diameter. If not specified, centers the circle within the (width, height) rectangle. */
      size,

      /**
       * The sizing mode ("contain" or "cover"). Contain centers the video with a 16:9 aspect ratio in the bounding
       * box of the block. Cover fills the entire block bounds with the video, cutting off any excess from the video element.
       * Does not apply if `circle` is true.
       */
      sizingMode = 'contain',

      /**
       * Minimal mode optimizes the video block for smaller use cases. Circle videos now all use minimal mode,
       * with the name tag placed below the circle.
       */
      minimal = false,

      /** True if a border should be displayed around the video. Used to highlight the active speaker. */
      highlightActiveSpeaker = false,

      /** custom implemenation for user video label (such as username) */
      videoNameLabel = undefined,

      /** custom css position for video icons */
      videoIconsPosition = undefined,

      /** React children property */
      children,
      videoQuality = videoQualities.HIGH,
      isVideoQualityBasedOnSize = false,
      showTrackStatus = true,
      accentColor,
      disableUserNameLabel,
    },
    forwardedRef
  ) => {
    if (circle) {
      sizingMode = 'contain';
      minimal = true;
    }
    const circleProps = circle
      ? getCircleProps(width, height, size, position)
      : undefined;

    const displayAudio = trackStates?.micOn;

    // calculate the initials from the username.
    const initials = useMemo(() => {
      return getInitials({ userName });
    }, [userName]);
    const [initialsFontSize, setInitialsFontSize] = useState(15);

    // calculate the background color seeded by the userId.
    const backgroundColor = useBackgroundColor(userId);

    // get x/y offset and width/height for the video given the sizing mode.
    const dimensions = useSizingModeDimensions(width, height, sizingMode);

    const videoQualityMemo = useMemo(() => {
      if (isVideoQualityBasedOnSize) {
        return circle && circleProps
          ? getVideoQualityBasedOnSize(circleProps.width)
          : videoQualities.HIGH;
      }

      return videoQuality;
    }, [isVideoQualityBasedOnSize, videoQuality, circle, circleProps]);

    // create the video element from local or network stream.
    // if no videoTrack is available, the element is omitted entirely.
    const { video, isActiveSpeaker } = useVideoElement(
      trackStates,
      videoQualityMemo
    );

    const videoAvatarRef = useRef(null);

    useEffect(() => {
      if (!videoAvatarRef.current) return;
      if (circle) {
        setInitialsFontSize(
          Math.min(
            Math.floor(circleProps.width / initials.length),
            MAX_INTIALS_FONT_SIZE
          )
        );
      } else {
        setInitialsFontSize(
          Math.min(
            Math.floor(videoAvatarRef.current.clientWidth / initials.length),
            MAX_INTIALS_FONT_SIZE
          )
        );
      }
    }, [circle, circleProps, initials.length, minimal]);

    const userImageDimension = useMemo(() => {
      if (typeof height === 'number' && typeof width === 'number') {
        return Math.min(height, width) * 0.8;
      }

      if (
        typeof height === 'string' &&
        height.includes('px') &&
        typeof width === 'string' &&
        width.includes('px')
      ) {
        const widthParsed = Number(width.split('px')[0]);
        const heightParsed = Number(height.split('px')[0]);

        return Math.min(widthParsed, heightParsed) * 0.8;
      }
    }, [width, height]);

    return (
      <VideoDimensionsContainer
        ref={forwardedRef}
        width={dimensions.width}
        height={dimensions.height}
        highlight={highlightActiveSpeaker && isActiveSpeaker}
        position={
          position
            ? {
                left: sumSizes(position.left, dimensions.left),
                top: sumSizes(position.top, dimensions.top),
              }
            : undefined
        }
        opacity={Number.isNaN(opacity) ? 1 : opacity}
        aspectRatio={defaultAspectRatio}
        isMinimal={minimal}
        {...circleProps}
      >
        {!avatarUrl && (
          <VideoBackground
            color={backgroundColor}
            isMinimal={minimal}
            isCircle={circle}
          />
        )}

        {avatarUrl && !video && (
          <VideoBackground isCircle={circle} color="black" />
        )}

        {!video && avatarUrl && (
          <div
            className={classNames(
              'flex justify-center relative w-full h-full',
              !circle && 'p-10'
            )}
          >
            <div className="w-full max-h-full flex justify-center items-center">
              <div className="relative flex justify-center items-center">
                <img
                  src={avatarUrl}
                  alt={`${userName} avatar`}
                  className="rounded-full border-8 object-cover aspect-square w-[250px]"
                  style={{
                    borderColor:
                      circle && avatarUrl
                        ? 'none'
                        : accentColor || backgroundColor,
                    width: avatarSize || userImageDimension,
                    height: avatarSize || userImageDimension,
                    maxWidth: userImageDimension ? 'unset' : undefined,
                  }}
                />
                {showTrackStatus && (
                  <div className="absolute bottom-3 w-12 h-12 flex justify-center items-center rounded-full bg-purple/10">
                    <PersonIcon
                      width="24px"
                      className={classNames('fill-purple')}
                    />
                    <div className="absolute top-0 -right-1">
                      <CameraIcon
                        width="20px"
                        className={classNames('fill-purple')}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {video}

        {!video && !avatarUrl && (
          <VideoAvatar
            isMinimal={minimal}
            size={
              circleProps
                ? circleProps.size
                : dimensions.width && dimensions.height
                ? Math.min(dimensions.width, dimensions.height)
                : 60
            }
            center
            isCircle={circle}
            ref={videoAvatarRef}
            style={{ fontSize: initialsFontSize }}
          >
            {initials}
          </VideoAvatar>
        )}

        {!avatarUrl && (
          <RectangularVideoIcons
            isMinimal={minimal}
            isCircle={circle}
            videoIconsPosition={videoIconsPosition}
          >
            {!video && <VideoIcon />}
            {!displayAudio && <AudioIcon />}
          </RectangularVideoIcons>
        )}

        {!disableUserNameLabel &&
          !avatarUrl &&
          (videoNameLabel || (
            <PositionedVideoNameLabel
              show={showUserName}
              isMinimal={minimal}
              isCircle={circle}
            >
              <div
                className={classNames(
                  'bg-white text-blue-dark px-3 py-1.5 uppercase text-base',
                  'animate-[slideToRight_1500ms_1000ms_both]'
                )}
                style={{
                  backgroundColor: accentColor,
                  color: accentColor
                    ? getContrastColor(accentColor)
                    : '#101840',
                  animationTimingFunction:
                    'cubic-bezier(0.565, 0.050, 0.065, 1.010)',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {userName}
              </div>
            </PositionedVideoNameLabel>
          ))}

        {children}
      </VideoDimensionsContainer>
    );
  }
);

/**
 * Calculates dimensions for the video element based on the block width/height,
 * and sizing mode "cover" or "contain".
 */
export const useSizingModeDimensions = (width, height, sizingMode) =>
  useMemo(() => {
    const aspectRatio = 16 / 9;

    const widthValue = sizeToNumber(width, null);
    const heightValue = height
      ? sizeToNumber(height, null)
      : widthValue
      ? widthValue / aspectRatio
      : null;

    if (!widthValue || !heightValue) {
      return { width, height, left: 0, top: 0, blockRatio: null, aspectRatio };
    }

    const blockRatio = widthValue / heightValue;
    const blockRatioIsGreater = blockRatio > aspectRatio;

    const containerDimensions =
      sizingMode === 'contain'
        ? blockRatioIsGreater
          ? {
              // with contain, if the block ratio is greater than the aspect ratio,
              // the width will be calculated based on height and the aspect ratio,
              // and the height will be the block height.
              width: heightValue * aspectRatio,
              height: heightValue,
            }
          : {
              // with contain, if the block ratio is less than the aspect ratio, the full width of the
              // block will be used, and the height will be calculated based on the width
              // and the aspect ratio.
              width: widthValue,
              height: widthValue * (1 / aspectRatio),
            }
        : {
            // with cover,the full width/height of the block is filled with the video element,
            // and any excess height/width is cut off evenly between top/bottom or left/right.
            width: widthValue,
            height: heightValue,
          };

    const containerOffset =
      sizingMode === 'cover'
        ? { left: 0, top: 0 }
        : {
            // if the mode is contain and the block ratio is greater than the aspect ratio,
            // the offset is the difference between the calculated width and the block width,
            // all divided by two.
            left: blockRatioIsGreater
              ? (widthValue - containerDimensions.width) / 2
              : 0,
            // if the block ratio is less than the aspect ratio, the same calculations as `left`
            // apply for `top`.
            top: blockRatioIsGreater
              ? 0
              : (heightValue - containerDimensions.height) / 2,
          };

    return {
      ...containerOffset,
      ...containerDimensions,
      blockRatio,
      aspectRatio,
    };
  }, [sizingMode, width, height]);

export const AuthoringUserVideo = React.forwardRef(
  (
    {
      width,
      height,
      userId,
      opacity,
      position,
      circle = false,
      size,
      sizingMode = 'contain',
      minimal = false,
      children,
      role,
      isInGroupVideo = false,
      image,
      showTrackStatus,
    },
    forwardedRef
  ) => {
    if (circle) {
      sizingMode = 'contain';
      minimal = true;
    }

    const circleProps = circle
      ? getCircleProps(width, height, size, position)
      : undefined;

    const dimensions = useSizingModeDimensions(width, height, sizingMode);

    return (
      <VideoDimensionsContainer
        ref={forwardedRef}
        width={dimensions.width}
        height={dimensions.height}
        position={
          position
            ? {
                left: sumSizes(position.left, dimensions.left),
                top: sumSizes(position.top, dimensions.top),
              }
            : undefined
        }
        opacity={Number.isNaN(opacity) ? 1 : opacity}
        aspectRatio={defaultAspectRatio}
        isMinimal={minimal}
        {...circleProps}
      >
        <div
          className={classNames(
            'absolute top-[1px] left-[1px] m-0 box-border flex flex-col justify-center items-center gap-1 bg-white w-[calc(100%-2px)] h-[calc(100%-2px)] bg-clip-content',
            circle ? 'rounded-full' : 'rounded',
            role || userId ? ' border-purple' : ' border-red',
            !isInGroupVideo && 'border-2 border-dashed'
          )}
        >
          <img
            src={image}
            alt=""
            className={classNames(
              'absolute object-cover opacity-50 w-full h-full',
              circle ? 'rounded-full' : 'rounded'
            )}
          />
          <div
            className={classNames(
              'w-12 h-12 flex justify-center relative items-center rounded-full',
              role || userId ? 'bg-purple/10' : 'bg-red/10'
            )}
          >
            <PersonIcon
              width="24px"
              className={classNames(
                role || userId ? 'fill-purple' : 'fill-red'
              )}
            />
            <div className="absolute top-0 -right-1">
              <CameraIcon
                width="20px"
                className={classNames(
                  role || userId ? 'fill-purple' : 'fill-red'
                )}
              />
            </div>
          </div>
          {isInGroupVideo ? null : (
            <div className="flex flex-col relative items-center gap-1">
              {userId && (
                <div className="bg-purple text-white text-xs p-1 rounded w-fit">
                  {userId}
                </div>
              )}
              {role && (
                <div className="bg-purple text-white text-xs p-1 rounded w-fit">
                  #{role}
                </div>
              )}
              {!userId && !role && (
                <div className="bg-red text-white text-xs p-1 rounded w-fit">
                  Unassigned
                </div>
              )}
            </div>
          )}
          {showTrackStatus && (
            <div className="absolute bottom-3">
              <CameraIcon width={30} height={30} className="fill-red" />
            </div>
          )}
        </div>
        {children}
      </VideoDimensionsContainer>
    );
  }
);
