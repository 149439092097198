import React from 'react';

import classNames from '../../../../../helper/classNames';
import { Button } from '../../../../common/Button';
import { CameraIcon } from '../../../../icons/CameraIcon';
import { CircleIcon } from '../../../../icons/CircleIcon';
import { ClockIcon } from '../../../../icons/ClockIcon';
import { PersonProfileIcon } from '../../../../icons/PersonProfileIcon';

const ListItem = ({ children }) => (
  <li className="flex flex-1 basis-3/6 items-center gap-2 pb-2 text-blue-dark text-xs font-medium">
    {children}
  </li>
);

const CardColorClassNames = {
  PURPLE: 'bg-purple text-purple bg-opacity-10',
  ORANGE: 'bg-orange-light text-orange-light bg-opacity-5',
};

const CardIconClassNames = {
  PURPLE: 'fill-purple',
  ORANGE: 'fill-orange-light',
};

/** A Card component representing a billing plan option */
const Card = ({ color, plan, upgradePlan, targetCustomerPlanName }) => {
  const { name, cost, maxAttendees, maxMinutes, recording, streaming, id } =
    plan;

  const iconProps = {
    width: 20,
    height: 20,
    className: CardIconClassNames[color],
  };

  return (
    <article
      className={classNames(
        'fill-purple px-4 pt-3 pb-0 rounded-lg',
        CardColorClassNames[color]
      )}
    >
      <header className="flex justify-between items-center space-x-6">
        <div>
          <p className="text-sm font-medium">{name} Plan -</p>{' '}
          <p className="font-semibold">${cost}/month</p>
        </div>
        <Button
          color={Button.colors[color]}
          padding={Button.padding.NONE}
          onClick={() => upgradePlan(id)}
          state={
            // we want to inform user that, current plan is in progress of updating to targetCustomerPlanName. By setting LOADING or DISABLED state, we show either loading spinner or prevent other buttons from being clicked
            targetCustomerPlanName === id
              ? Button.states.LOADING
              : targetCustomerPlanName
              ? Button.states.DISABLED
              : Button.states.DEFAULT
          }
        >
          <span className="text-sm font-medium px-8 py-2">Upgrade</span>
        </Button>
      </header>
      <ul className="flex flex-wrap mt-3">
        {streaming && (
          <ListItem>
            <CircleIcon {...iconProps} /> Live Streaming
          </ListItem>
        )}
        {recording && (
          <ListItem>
            <CameraIcon {...iconProps} />
            Record Meetings
          </ListItem>
        )}
        <ListItem>
          <PersonProfileIcon {...iconProps} /> Up to {maxAttendees} attendees
        </ListItem>
        <ListItem>
          <ClockIcon {...iconProps} /> Up to {maxMinutes} mins
        </ListItem>
      </ul>
    </article>
  );
};

export default Card;
