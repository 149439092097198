import { cloneDeep } from 'lodash/fp';
import { fromRawContentState } from '../../components/richText/helpers';
import { isStringifiedJSON } from 'zync-common/helper/richText';

const replaceDynamicWithStaticValue = (setting, dynamicValue, staticValue) => {
  return setting.replace(dynamicValue, () => staticValue);
};

export const replaceRichTextStyleRangeLength = (setting, newStyleLength) => {
  try {
    const parsedContentState = JSON.parse(setting);

    parsedContentState.blocks = parsedContentState.blocks.map((styleBlock) => ({
      ...styleBlock,
      inlineStyleRanges: styleBlock.inlineStyleRanges.map((styleRange) => ({
        ...styleRange,
        length: newStyleLength,
      })),
    }));

    return JSON.stringify(parsedContentState);
  } catch (error) {
    return setting;
  }
};
/** slide template wrapper providing utilities when working with dynamic variables embedded in block settings */

export class DynamicBlocks {
  blocks = {};

  constructor(blocks = []) {
    this.blocks = cloneDeep(blocks);
  }

  /** replaceWith(dynamicVariable: string, staticValue: string)
   * Looks into blocks collection and makes text replacement - use it when working with blocks with dynamic variables embedded in their settings
   * */
  replaceWith(dynamicVariable, staticValue) {
    this.blocks.forEach((block) => {
      const blockSettings = block.settings;
      for (const setting in blockSettings) {
        try {
          const dynamicValue =
            blockSettings[setting].match(dynamicVariable)?.[0] ||
            blockSettings[setting].match(`{${dynamicVariable}}`)?.[0];

          if (!dynamicValue) {
            return;
          }

          let parsedValue;
          if (isStringifiedJSON(staticValue)) {
            try {
              parsedValue = fromRawContentState(staticValue).getPlainText();
            } catch (error) {
              parsedValue = staticValue;
            }

            if (dynamicValue && staticValue !== undefined) {
              blockSettings[setting] = replaceDynamicWithStaticValue(
                blockSettings[setting],
                dynamicValue,
                parsedValue
              );

              blockSettings[setting] = replaceRichTextStyleRangeLength(
                blockSettings[setting],
                parsedValue.length
              );
            }
          } else {
            if (dynamicValue && staticValue !== undefined) {
              blockSettings.useRichText = false;
              blockSettings[setting] = replaceDynamicWithStaticValue(
                blockSettings[setting],
                dynamicValue,
                staticValue.replace(/\n/g, ' ')
              );
            }
          }
        } catch (error) {
          // the value was not a string, therefore there is nothing to match and replace against
        }
      }
    });

    return this;
  }

  /** getAll()
   * Returns all blocks with all replacement changes
   * */
  getAll() {
    return Object.values(this.blocks);
  }
}
