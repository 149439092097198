import { useClickOutside } from '../../../../helper/useClickOutside';
import React, { useCallback } from 'react';
import { DropdownSelect } from '../../../../components/common/Dropdown';

export const SpeakerIntroUpdate = ({
  posterSlideTemplate,
  setPosterSlideTemplate,
  setUpdate,
  onConfirm,
  onCancel,
  updateOnClose,
  series,
  hostNotesComponent,
}) => {
  const eventPresenters = series.settings.eventPresenters;

  const presenters = eventPresenters
    .slice()
    .filter((ep) => ep.type === 'speaker')
    .sort((a, b) => (a.type === b.type ? 0 : a.type > b.type ? -1 : 1));

  const speakerVideo = posterSlideTemplate.blocks?.findLast(
    (block) =>
      block.blockId === 'singlevideo' && block.settings.role === 'speaker'
  );

  const speakerBioAndTitle = posterSlideTemplate.blocks.find(
    (block) => block.blockId === 'textbox'
  );

  const handleConfirm = useCallback(
    (shouldOpenNewlyCreated) => {
      const fullName = presenters.find(
        (p) => p.emailAddress === speakerVideo?.settings?.userId
      )?.fullName;

      posterSlideTemplate.slideName = `Speaker Intro: ${fullName}`;

      onConfirm(
        posterSlideTemplate,
        `Speaker Intro: ${fullName}`,
        shouldOpenNewlyCreated
      );
    },
    [onConfirm, posterSlideTemplate, presenters, speakerVideo?.settings?.userId]
  );

  const handleMainSpeakerBlockChange = (userId) => {
    setPosterSlideTemplate((template) => {
      if (speakerVideo) {
        speakerVideo.settings.userId = userId;
      }

      const speaker = eventPresenters.find(
        (ep) => ep?.speaker?.emailAddress === userId
      );

      if (speakerBioAndTitle && speaker) {
        speakerBioAndTitle.settings.text = `{${speaker._id}_name}\n${
          speaker.jobTitle ? `{${speaker._id}_bio}` : ''
        }`;
      }

      return template;
    });

    setUpdate((x) => x + 1);
  };

  useClickOutside({ current: document.querySelector('.modal') }, () => {
    updateOnClose && handleConfirm(false);
    onCancel();
  });

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="flex flex-col gap-3">
        <div>
          <h4 className="text-sm font-medium text-blue-dark mb-1">
            Choose a Presenter
          </h4>
          <DropdownSelect
            selectedKey={speakerVideo?.settings.userId}
            options={presenters.map((ep) => ({
              key: ep.emailAddress,
              title: ep.fullName,
            }))}
            onSelect={(value) => {
              handleMainSpeakerBlockChange(value);
              handleConfirm();
            }}
          />
        </div>
      </div>
      <div>{hostNotesComponent}</div>
    </div>
  );
};
