import React, { useCallback } from 'react';
import { EditorState, Modifier, RichUtils } from 'draft-js';
import { InlineStyleControls } from './InlineStyleControls';
import { BlockStyleControls } from './BlockStyleControls';
import { CircleIcon } from '../../icons/CircleIcon';
import {
  applyInlineStyleOverride,
  removeStylesFromContentState,
  selectInlineStyle,
} from './api';
import { getSelectionEntity, strategyDecorator } from '../editor/helper';

/* This is main component rendering rich text options toolbar, that appears in editable mode of TextBlock */
const RichTextToolbar = ({
  onChange,
  editorState,
  initialValues,
  bestPosition,
  enableHyperlinks = false,
  children,
  containerClassName = 'flex shrink-0 gap-4 px-6 py-4 flex-wrap justify-center items-center',
  disabled,
}) => {
  const toggleBlockType = useCallback(
    (blockType) => {
      onChange(RichUtils.toggleBlockType(editorState, blockType));
    },
    [onChange, editorState]
  );

  /* Applies a new inline style */
  const toggleInlineStyle = useCallback(
    (inlineStyle) => {
      onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    },
    [onChange, editorState]
  );

  /* Applies a new inline style and makes sure other styles are stripped away */
  const overrideInlineStyle = useCallback(
    (inlineStyle, prefix = undefined) => {
      const selectionState = editorState.getSelection();
      const currentStyle = editorState.getCurrentInlineStyle();

      if (selectionState.isCollapsed()) {
        return onChange(
          applyInlineStyleOverride(
            editorState,
            currentStyle,
            prefix,
            inlineStyle
          )
        );
      }

      const contentWithoutStyles = removeStylesFromContentState(
        editorState,
        prefix
      );

      return onChange(
        selectInlineStyle(
          editorState,
          contentWithoutStyles,
          currentStyle.has(inlineStyle) ? null : inlineStyle
        )
      );
    },
    [onChange, editorState]
  );

  const hasLink = getSelectionEntity(editorState);

  const addLink = (e) => {
    e.preventDefault();

    const contentState = editorState.getCurrentContent();
    const url = hasLink ? null : window.prompt('Add link below', 'https://');

    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      { url }
    );

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const contentStateWithLink = Modifier.applyEntity(
      contentStateWithEntity,
      editorState.getSelection(),
      hasLink || !url ? null : entityKey
    );

    const styledContentStateWithLink = Modifier.applyInlineStyle(
      contentStateWithLink,
      editorState.getSelection(),
      'HYPERLINK'
    );

    const newState = EditorState.set(editorState, {
      currentContent: styledContentStateWithLink,
      decorator: strategyDecorator,
    });

    onChange(newState);
  };

  return (
    <div
      className={`${containerClassName} ${disabled && 'pointer-events-none'}`}
    >
      <InlineStyleControls
        editorState={editorState}
        onToggle={toggleInlineStyle}
        onSelect={overrideInlineStyle}
        initialValues={initialValues}
        bestPosition={bestPosition}
      />

      {enableHyperlinks && (
        <button
          className={`${
            hasLink
              ? 'bg-purple text-white'
              : 'hover:bg-purple/5 hover:text-black'
          } py-0.5 px-1 rounded `}
          onClick={addLink}
        >
          Link
        </button>
      )}

      <div className="-mx-2">
        <CircleIcon className="fill-gray" width="20px" height="6px" />
      </div>

      <BlockStyleControls
        editorState={editorState}
        onToggle={toggleBlockType}
      />

      {children}
    </div>
  );
};

export default React.memo(RichTextToolbar);
