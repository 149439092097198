import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getStaticAssetUrl } from '../helper/getStaticAssetUrl';

const logoImage = getStaticAssetUrl('logo.png');

const FooterContainer = styled.footer`
  margin-top: 30px;
  padding: 8px;
  display: grid;
  grid-area: footer;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
  align-items: center;
  background-color: rgba(0, 163, 165, 0.3);
  font-size: 14px;

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const Span = styled.span`
  font-weight: 800;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <div>
        <a rel="noopener noreferrer" href="https://www.zync.ai" target="_blank">
          <Span>
            <img alt="logo" width="20" height="20" src={logoImage}></img>
            Zync AI Inc
          </Span>
        </a>
      </div>
      <div style={{ margin: '0', justifyItems: 'center' }}>
        <Link to="/tos">Terms of Service</Link>
        <br />
        <Link to="/privacy">Privacy Policy</Link>
      </div>
      <div>
        <div>Bellevue, WA</div>
        <p style={{ margin: '0px' }}>
          <a href="mailto:info@zync.ai">info@zync.ai</a>
        </p>
      </div>
    </FooterContainer>
  );
};

export default Footer;
