import React from 'react';

export const PresentImageIcon = ({
  width = 18,
  height = 19,
  className = 'fill-[#8B33F7]',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_18425_77058)">
      <path d="M1.05375 14.2137H0.5475V1.0475H13.7175V1.55375C13.7175 1.70375 13.8413 1.8275 13.9913 1.8275C14.1413 1.8275 14.265 1.70375 14.265 1.55375V0.77375C14.265 0.62375 14.1413 0.5 13.9913 0.5H0.27375C0.12375 0.5 0 0.62375 0 0.77375V14.4875C0 14.6375 0.12375 14.7612 0.27375 14.7612H1.05375C1.20375 14.7612 1.3275 14.6375 1.3275 14.4875C1.3275 14.3375 1.20375 14.2137 1.05375 14.2137Z" />
      <path d="M2.92094 16.0847H2.41469V2.91469H15.5847V3.42094C15.5847 3.57094 15.7084 3.69469 15.8584 3.69469C16.0084 3.69469 16.1322 3.57094 16.1322 3.42094V2.64094C16.1322 2.49094 16.0084 2.36719 15.8584 2.36719H2.14094C1.99094 2.36719 1.86719 2.49094 1.86719 2.64094V16.3547C1.86719 16.5047 1.99094 16.6284 2.14094 16.6284H2.92094C3.07094 16.6284 3.19469 16.5047 3.19469 16.3547C3.19469 16.2047 3.07094 16.0809 2.92094 16.0809V16.0847Z" />
      <path d="M17.7258 4.24594H4.01203C3.86203 4.24594 3.73828 4.36969 3.73828 4.51969V18.2334C3.73828 18.2672 3.74578 18.3047 3.76078 18.3384C3.78703 18.4059 3.84328 18.4584 3.90703 18.4847C3.94078 18.4997 3.97453 18.5072 4.01203 18.5072H17.7258C17.8758 18.5072 17.9995 18.3834 17.9995 18.2334V4.51594C17.9995 4.36594 17.8758 4.24219 17.7258 4.24219V4.24594ZM12.8545 17.9597L15.9558 14.8584C16.387 14.9897 16.7995 15.1397 17.1895 15.3084L14.5345 17.9634H12.8508L12.8545 17.9597ZM10.1283 17.9597L13.7283 14.3597C14.287 14.4422 14.8345 14.5509 15.3595 14.6859L12.0858 17.9597H10.1283ZM8.21953 17.9597L11.9958 14.1834C12.3483 14.2022 12.697 14.2322 13.0458 14.2734L9.35953 17.9597H8.22328H8.21953ZM4.67203 17.9597L8.31328 14.3184C9.27328 14.1909 10.2633 14.1384 11.2495 14.1572L7.44703 17.9597H4.67203ZM4.28578 15.4209C5.20828 14.9972 6.26578 14.6784 7.39453 14.4684L4.28578 17.5772V15.4209ZM17.4558 4.78969V14.8247C16.3383 14.3409 15.0483 13.9997 13.6833 13.8047C13.6645 13.8009 13.642 13.7972 13.6233 13.7972C13.0758 13.7222 12.517 13.6697 11.9545 13.6397C11.9245 13.6322 11.8945 13.6284 11.8645 13.6322C10.642 13.5722 9.40078 13.6247 8.21203 13.7859C8.18203 13.7859 8.15203 13.7859 8.12203 13.7972C6.73453 13.9884 5.42578 14.3334 4.29328 14.8247V4.78969H17.4633H17.4558ZM15.3108 17.9597L17.4558 15.8147V17.9597H15.3108Z" />
      <path d="M12.1726 8.68313C12.9264 8.68313 13.5376 8.07188 13.5376 7.31813C13.5376 6.56438 12.9264 5.95312 12.1726 5.95312C11.4189 5.95312 10.8076 6.56438 10.8076 7.31813C10.8076 8.07188 11.4189 8.68313 12.1726 8.68313ZM12.1726 6.50063C12.6226 6.50063 12.9901 6.86813 12.9901 7.31813C12.9901 7.76813 12.6226 8.13563 12.1726 8.13563C11.7226 8.13563 11.3551 7.76813 11.3551 7.31813C11.3551 6.86813 11.7226 6.50063 12.1726 6.50063Z" />
      <path d="M12.6866 10.8097C12.6866 10.8359 12.6866 10.8622 12.6903 10.8847C12.4466 11.0347 12.2891 11.3009 12.2891 11.6009C12.2891 12.0622 12.6641 12.4409 13.1291 12.4409H15.6191C16.1703 12.4409 16.6203 11.9909 16.6203 11.4397C16.6203 10.9334 16.2416 10.5134 15.7503 10.4459C15.6903 9.87594 15.2066 9.42969 14.6178 9.42969C14.1716 9.42969 13.7778 9.69219 13.5941 10.0747C13.5453 10.0634 13.4928 10.0597 13.4403 10.0597C13.0241 10.0597 12.6866 10.3972 12.6866 10.8134V10.8097ZM13.8566 10.7122C13.9503 10.6784 14.0216 10.5922 14.0328 10.4909C14.0703 10.1984 14.3253 9.97719 14.6178 9.97719C14.9441 9.97719 15.2103 10.2434 15.2103 10.5697C15.2103 10.6072 15.2066 10.6447 15.1991 10.6784C15.1841 10.7684 15.2103 10.8584 15.2778 10.9222C15.3416 10.9859 15.4353 11.0122 15.5253 10.9934C15.8216 10.9297 16.0728 11.1584 16.0728 11.4397C16.0728 11.6909 15.8666 11.8972 15.6153 11.8972H13.1253C12.9641 11.8972 12.8328 11.7659 12.8328 11.6047C12.8328 11.4659 12.9341 11.3422 13.0691 11.3159C13.1553 11.3009 13.2266 11.2409 13.2641 11.1622C13.3016 11.0834 13.2978 10.9897 13.2528 10.9147C13.2341 10.8847 13.2266 10.8509 13.2266 10.8134C13.2266 10.7009 13.3203 10.6072 13.4328 10.6072C13.4966 10.6072 13.5453 10.6372 13.5716 10.6597C13.6466 10.7272 13.7553 10.7497 13.8491 10.7122H13.8566Z" />
      <path d="M6.29215 10.5347H8.78215C9.3334 10.5347 9.7834 10.0847 9.7834 9.53344C9.7834 9.02719 9.40465 8.60344 8.9134 8.53969C8.8534 7.96969 8.36965 7.52344 7.7809 7.52344C7.33465 7.52344 6.9409 7.78219 6.75715 8.16844C6.7084 8.15719 6.6559 8.15344 6.6034 8.15344C6.18715 8.15344 5.84965 8.49094 5.84965 8.90719C5.84965 8.93344 5.84965 8.95969 5.8534 8.98594C5.60965 9.13594 5.45215 9.40219 5.45215 9.70219C5.45215 10.1634 5.82715 10.5422 6.29215 10.5422V10.5347ZM6.2359 9.40969C6.32215 9.39469 6.3934 9.33469 6.4309 9.25594C6.4684 9.17719 6.46465 9.08344 6.41965 9.00844C6.4009 8.97844 6.3934 8.94094 6.3934 8.90719C6.3934 8.79469 6.48715 8.70094 6.59965 8.70094C6.6634 8.70094 6.71215 8.73094 6.7384 8.75344C6.8134 8.82094 6.92215 8.84344 7.0159 8.80594C7.10965 8.77219 7.1809 8.68594 7.19215 8.58469C7.22965 8.29219 7.4809 8.07094 7.77715 8.07094C8.1034 8.07094 8.36965 8.33719 8.36965 8.66344C8.36965 8.70094 8.3659 8.73844 8.3584 8.77219C8.3434 8.86219 8.36965 8.95219 8.43715 9.01594C8.5009 9.07969 8.59465 9.10594 8.68465 9.08719C8.98465 9.02344 9.2359 9.25219 9.2359 9.53344C9.2359 9.78469 9.02965 9.99094 8.7784 9.99094H6.2884C6.12715 9.99094 5.9959 9.85969 5.9959 9.69844C5.9959 9.55969 6.09715 9.43594 6.23215 9.40969H6.2359Z" />
    </g>
    <defs>
      <clipPath id="clip0_18425_77058">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
