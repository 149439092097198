import classNames from '../../../helper/classNames';

export const PostTextAreaInput = ({
  resizable = true,
  onChange,
  value,
  rows,
  className = '',
}) => {
  return (
    <textarea
      className={classNames(
        'rounded-md border border-solid border-gray px-2 pt-1 text-sm text-[#696F8C] outline-none focus:border-purple w-full',
        !resizable && 'resize-none',
        className
      )}
      onChange={(event) => onChange(event, event.currentTarget.value)}
      value={value}
      rows={rows}
    ></textarea>
  );
};
