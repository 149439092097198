export const ArrowRightIcon = ({
  width = 16,
  height = 12,
  className = 'fill=[#8B33F7]',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M15.5304 6.53033C15.8233 6.23744 15.8233 5.76256 15.5304 5.46967L10.7574 0.6967C10.4645 0.403807 9.98966 0.403807 9.69676 0.6967C9.40387 0.989593 9.40387 1.46447 9.69676 1.75736L13.9394 6L9.69676 10.2426C9.40387 10.5355 9.40387 11.0104 9.69676 11.3033C9.98965 11.5962 10.4645 11.5962 10.7574 11.3033L15.5304 6.53033ZM6.09696e-05 6.75L15.0001 6.75L15.0001 5.25L6.11007e-05 5.25L6.09696e-05 6.75Z" />
  </svg>
);
