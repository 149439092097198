import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../../common/Button';

import useConfirmationModal from './useConfirmationModal';

export const UpgradeButton = () => {
  const history = useHistory();

  const handleNavigateToWorkspaceMembersPage = useCallback(() => {
    history.push('/workspace/members');
  }, [history]);

  const { Modal, handleOpen } = useConfirmationModal({
    modalTitle: (
      <h4 className="font-semibold text-xl -ml-2 leading-0">
        You are about to leave the meeting
      </h4>
    ),
    modalText: (
      <p className="-mt-2 mb-3">
        Setting up a new subscription requires you to navigate to Workspace
        Settings. Do you wish to proceed?
      </p>
    ),
    onConfirm: handleNavigateToWorkspaceMembersPage,
  });

  return (
    <>
      <Button
        color={Button.colors.PURPLE}
        padding={Button.padding.NONE}
        onClick={handleOpen}
      >
        <span className="text-sm font-medium px-8 py-2">Upgrade</span>
      </Button>
      {Modal}
    </>
  );
};

export default UpgradeButton;
