export function setWorkspaceUrlParams(workspaceId) {
  const queryParams = new URLSearchParams(window.location.search);
  queryParams.set('workspaceId', workspaceId);
  let newUrl =
    window.location.protocol +
    '//' +
    window.location.host +
    window.location.pathname +
    '?' +
    queryParams.toString();
  window.history.replaceState({ path: newUrl }, '', newUrl);
}
