import React, { useCallback } from 'react';

import { ReactionsBar } from './Reactions';
import { sendEvent } from '../helper/api';
import { useSelector } from 'react-redux';

export const UserReactions = ({ vertical = false }) => {
  const userId = useSelector((state) => state.auth?.user?.userId);
  const meetingId = useSelector((state) => state.meetingState?.meetingId);

  const sendReaction = useCallback(
    (reaction) => {
      sendEvent(userId, meetingId, {
        type: 'BROADCAST_ONLY',
        data: {
          key: 'reaction',
          reaction,
        },
      });
    },
    [userId, meetingId]
  );

  return (
    <div className="absolute flex items-center">
      <ReactionsBar onClick={sendReaction} vertical={vertical} />
    </div>
  );
};
