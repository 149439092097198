import React from 'react';

import MessagePartials from './MessagePartials';

const MeetingFullModerator = ({ billingAdminEmail, plan }) => {
  const { maxAttendees, name } = plan;

  return (
    <div>
      <MessagePartials.UpgradeFreePlan
        maxAttendees={maxAttendees}
        planName={name}
      />
      <MessagePartials.ContactYourAdmin billingAdminEmail={billingAdminEmail} />
    </div>
  );
};

export default MeetingFullModerator;
