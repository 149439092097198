import React from 'react';

import MessagePartials from './MessagePartials';

const StreamingUnsupportedModerator = ({ billingAdminEmail, plan }) => {
  const { name } = plan;

  return (
    <div className="px-5">
      <MessagePartials.FeatureNotSupported
        planName={name}
        featureName="streaming"
      />
      <MessagePartials.ContactYourAdmin billingAdminEmail={billingAdminEmail} />
    </div>
  );
};

export default StreamingUnsupportedModerator;
