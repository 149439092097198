import { ProgressStates } from '../../pages/Portal/constants';
import classNames from '../../helper/classNames';

const getWidthClassName = (elementsCount) => {
  const classNames = ['w-full', 'w-1/2', 'w-1/3', 'w-1/4'];

  return classNames[elementsCount];
};

export const ProgressBar = ({ checkpointsData, getProgressState }) => {
  return (
    <div className="flex space-x-[2px] -md:mx-0 rounded-bl-lg rounded-br-lg overflow-hidden">
      {Object.keys(checkpointsData).map((checkpoint, currentIdx) => {
        return getProgressState(checkpoint) === ProgressStates.AFTER ? (
          <div
            key={currentIdx}
            className={classNames(
              'h-2.5 bg-purple duration-500',
              getWidthClassName(Object.values(checkpointsData).length - 1)
            )}
          />
        ) : (
          <div
            key={currentIdx}
            className={classNames(
              'h-2.5 bg-gray/50 duration-500',
              getWidthClassName(Object.values(checkpointsData).length - 1)
            )}
          />
        );
      })}
    </div>
  );
};
