import { useEffect, useMemo, useState } from 'react';

export const isNotBeforeTime = (msTime, msBefore) =>
  msTime - Date.now() > msBefore;

export const useIsMinutesBefore = ({ date, msBefore }) => {
  const msTime = useMemo(() => new Date(date).getTime(), [date]);
  const [isBefore, setIsBefore] = useState(
    date ? isNotBeforeTime(msTime, msBefore) : true
  );

  useEffect(() => {
    let timeout;

    if (isNotBeforeTime(msTime, msBefore)) {
      const timeoutToShowLaunch = msTime - Date.now() - msBefore;

      // timeout will be called immediately for values higher than 2_147_483_647
      if (timeoutToShowLaunch >= 2_147_483_647) {
        return;
      }

      setTimeout(() => {
        setIsBefore(false);
      }, timeoutToShowLaunch);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [msBefore, msTime]);

  return { isBefore };
};
