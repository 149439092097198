import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from '../../../helper/classNames';
import {
  Modal,
  ModalBody,
  ModalTitle,
  ModalWindow,
} from '../../../components/Modal';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyIcon } from '../../../components/icons/CopyIcon';
import { DownloadIcon } from '../../../components/icons/DownloadIcon';
import { downloadGCSFile } from './PublishMenus';
import ReactPlayer from 'react-player';
import { formatDuration, getHighestQualityDownloadUrl } from './YoutubePosts';
import { usePosterSlide } from '../../../components/series/settings/usePosterSlide';
import { ScenePreview } from '../../../components/authoring/Menu/ScenePreview';
import { Button } from '../../../components/common/Button';
import { PostTextAreaInput } from './PostTextAreaInput';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { CloudUploadIcon } from '../../../components/icons/CloudUploadIcon';
import { CheckMarkIcon } from '../../../components/icons/CheckMarkIcon';
import mixpanel from 'mixpanel-browser';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { PROCESSOR_BASE_URL } from '../../../helper/constants';
import { useTrackActivity } from '../../../hooks/track';
import { LinkedInReauthModal } from '../../../hooks/useLinkedInIntegration';

export const LinkedinModal = ({
  title,
  description = '',
  setShowPublishModal,
  thumbnailUrl,
  playbackUrl,
  alternateVideoUrl,
  aspectRatio,
  videoGCSUrl,
  disableDescription,
  previewComponent,
  handleDownloadPosterImage,
  highlightId,
  saveHighlightSocialMediaPost,
  saveShortsSocialMediaPost,
  shared,
  workspace,
  handlePublish,
  createdByProcessorId,
  uploadedToLinkedIn,
  linkedinAccountOwnerUserId,
  isSpeakerKit,
  refreshContentKit,
  isSoloEpisode,
  series,
}) => {
  const { title: episodeTitle } = series || {};
  const { meetingSeriesId } = useParams();
  const [editedDescription, setEditedDescription] = useState(description || '');
  const [isSavingPostTitleLoading, setIsSavingPostTitleLoading] =
    useState(false);
  const isPortrait = aspectRatio === 'portrait';
  const isDescriptionEdited = editedDescription.trim() !== description.trim();
  const [isPublishLoading, setIsPublishLoading] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [errorPublishing, setErrorPublishing] = useState(null);
  const hasLinkedinProfileLoaded = Boolean(
    workspace?.integrations?.linkedin?.profile
  );
  const intervalRef = useRef(null);
  const [isDownloadVideoLoading, setIsDownloadVideoLoading] = useState(false);
  const [errorDownloading, setErrorDownloading] = useState(null);
  const [showReauthModal, setShowReauthModal] = useState(false);

  const fetchPostToLinkedIn = useCallback(async () => {
    const result = await fetch(
      `${PROCESSOR_BASE_URL}/asset?meetingSeriesId=${meetingSeriesId}&groupId=${highlightId}&workspaceId=${workspace.workspaceId}&processorId=POST_TO_LINKEDIN`
    );
    return result.json();
  }, [highlightId, meetingSeriesId, workspace.workspaceId]);

  const track = useTrackActivity();

  useEffect(() => {
    track('Content Kit: LinkedIn Post Opened', { groupId: highlightId });
  }, [track, highlightId]);

  const refreshPostToLinkedIn = useCallback(async () => {
    const { result } = await fetchPostToLinkedIn();
    const { data } = result || {};
    const { status, message = '' } = data || {};

    if (status === 'COMPLETE') {
      await refreshContentKit();
      setIsPublishLoading(false);
      clearInterval(intervalRef.current);
    }

    if (status === 'ERROR') {
      await refreshContentKit();
      setIsPublishLoading(false);
      setErrorPublishing(
        message.length > 0
          ? message
          : 'There was an error publishing, please try again later.'
      );
      clearInterval(intervalRef.current);
    }
  }, [fetchPostToLinkedIn, refreshContentKit]);

  useEffect(() => {
    if (isSpeakerKit) return;
    const loadModal = async () => {
      setIsModalLoading(true);
      const { result } = await fetchPostToLinkedIn();
      if (!result) {
        setIsModalLoading(false);
        return;
      }
      const { data } = result || {};
      const { status, message = '' } = data || {};
      if (status === 'ERROR') {
        setErrorPublishing(
          message.length > 0
            ? message
            : 'There was an error publishing, please try again later.'
        );
      }
      if (status === 'LOADING') {
        setIsPublishLoading(true);
        intervalRef.current = setInterval(() => {
          refreshPostToLinkedIn();
        }, [5_000]);
      }
      setIsModalLoading(false);
    };
    loadModal();
  }, [fetchPostToLinkedIn, isSpeakerKit, refreshPostToLinkedIn]);

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  if (isModalLoading) return null;

  return (
    <ModalWindow
      size={
        isSpeakerKit && aspectRatio === 'portrait'
          ? Modal.size.sm
          : Modal.size.xl
      }
      showCancel
      onCancel={() => {
        setShowPublishModal(false);
      }}
    >
      {showReauthModal && (
        <LinkedInReauthModal
          setShowReauthModal={setShowReauthModal}
          workspaceId={workspace?.workspaceId}
        />
      )}
      <ModalTitle>{title}</ModalTitle>
      <ModalBody>
        <div className="flex gap-[25px]">
          {disableDescription ? null : (
            <div
              className={classNames(
                'flex flex-col gap-5 shrink-0',
                aspectRatio === 'portrait' || aspectRatio === 'square'
                  ? 'flex-1'
                  : 'w-1/4'
              )}
            >
              <div className="flex flex-col gap-2.5 h-full">
                <div className="flex justify-between">
                  <span className="text-blue-dark text-sm font-medium">
                    LinkedIn Post
                  </span>
                  <div className="flex gap-1">
                    {!shared && isDescriptionEdited && (
                      <Button
                        padding={Button.padding.NONE}
                        color={Button.colors.PURPLE}
                        border={Button.border.ROUNDED}
                        onClick={async () => {
                          track('Content Kit: LinkedIn Description Updated');
                          saveHighlightSocialMediaPost
                            ? await saveHighlightSocialMediaPost(
                                setIsSavingPostTitleLoading,
                                editedDescription,
                                highlightId,
                                'linkedIn'
                              )
                            : await saveShortsSocialMediaPost(
                                setIsSavingPostTitleLoading,
                                editedDescription,
                                highlightId,
                                'linkedinPost'
                              );
                        }}
                        state={
                          isSavingPostTitleLoading
                            ? Button.states.LOADING
                            : Button.states.DEFAULT
                        }
                      >
                        <div className="text-xxs h-5 flex justify-center items-center px-2.5">
                          Save
                        </div>
                      </Button>
                    )}
                    <CopyToClipboard text={editedDescription}>
                      <button>
                        <CopyIcon />
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>
                <PostTextAreaInput
                  resizable={false}
                  onChange={(event, value) => {
                    setEditedDescription(value);
                  }}
                  value={editedDescription}
                  rows={6}
                  className="h-full"
                ></PostTextAreaInput>
              </div>
            </div>
          )}
          <div
            className={classNames(
              'flex flex-col gap-[15px]',
              !disableDescription && isPortrait ? 'w-1/4' : 'flex-1'
            )}
          >
            <div className="flex gap-[5px] justify-end">
              {videoGCSUrl && (
                <Button
                  padding={Button.padding.NONE}
                  color={Button.colors.PURPLE}
                  onClick={() => {
                    track('Content Kit: LinkedIn Video Download Clicked');
                    downloadGCSFile(
                      videoGCSUrl,
                      isSoloEpisode
                        ? `${episodeTitle}-${aspectRatio}.mp4`
                        : `${title}.mp4`,
                      setIsDownloadVideoLoading,
                      setErrorDownloading
                    );
                  }}
                  state={
                    isDownloadVideoLoading
                      ? Button.states.LOADING
                      : Button.states.DEFAULT
                  }
                >
                  <div className="bg-purple text-white text-xxs font-medium flex gap-[5px] rounded items-center h-min  px-2">
                    <DownloadIcon height={12} className="fill-white" />
                    Video
                  </div>
                </Button>
              )}
              {handleDownloadPosterImage && !isSoloEpisode ? (
                <button
                  type="button"
                  className="bg-purple text-white text-xxs font-medium p-2 flex gap-[5px] rounded"
                  onClick={handleDownloadPosterImage}
                >
                  <DownloadIcon height={12} className="fill-white" />
                  Poster
                </button>
              ) : null}
              {thumbnailUrl && (
                <button
                  type="button"
                  className="bg-purple text-white text-xxs font-medium p-2 flex gap-[5px] rounded"
                  onClick={() => {
                    track('Content Kit: LinkedIn Thumbnail Download Clicked');
                    downloadGCSFile(thumbnailUrl, `${title}.png`);
                  }}
                >
                  <DownloadIcon height={12} className="fill-white" />
                  Thumbnail
                </button>
              )}
              {isPublishLoading ? (
                <LoadingSpinner width="20px" />
              ) : (
                <>
                  {!shared && (
                    <div className="relative group">
                      <p
                        className={classNames(
                          'hidden rounded-lg bg-blue-dark text-white text-xs font-light absolute right-0 bottom-9 w-[280px] p-2 text-center',
                          (!linkedinAccountOwnerUserId ||
                            !hasLinkedinProfileLoaded) &&
                            'group-hover:block'
                        )}
                      >
                        Connect to LinkedIn in Settings &#62; Integration
                      </p>
                      <button
                        className={classNames(
                          'bg-[#0A66C2] text-white text-xxs font-medium p-2 flex gap-[5px] rounded',
                          (uploadedToLinkedIn ||
                            !linkedinAccountOwnerUserId ||
                            !hasLinkedinProfileLoaded) &&
                            'opacity-50'
                        )}
                        onClick={async () => {
                          setIsPublishLoading(true);
                          setErrorPublishing(null);
                          track('Content Kit: LinkedIn Publish Clicked');
                          const isPublishing = await handlePublish(
                            highlightId,
                            createdByProcessorId,
                            editedDescription,
                            aspectRatio,
                            setShowReauthModal
                          );
                          if (isPublishing) {
                            intervalRef.current = setInterval(() => {
                              refreshPostToLinkedIn();
                            }, [5_000]);
                          } else {
                            setIsPublishLoading(false);
                          }
                        }}
                        disabled={
                          uploadedToLinkedIn ||
                          !linkedinAccountOwnerUserId ||
                          !hasLinkedinProfileLoaded
                        }
                      >
                        <CloudUploadIcon />
                        Publish
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
            {errorPublishing && !shared && (
              <div className="text-red text-xs font-light text-right">
                {errorPublishing}
              </div>
            )}
            {errorDownloading && (
              <div className="text-red text-xs font-light text-right">
                {errorDownloading}
              </div>
            )}
            {aspectRatio === 'portrait' ? (
              <style>{`.react-player > video { aspect-ratio: 9/16}`}</style>
            ) : null}
            <ReactPlayer
              className={classNames(
                'react-player',
                aspectRatio === 'square' ? 'aspect-square' : 'aspect-video'
              )}
              onPlay={() => {
                track('Content Kit: LinkedIn Video Played');
              }}
              width={'100%'}
              height="auto"
              url={
                alternateVideoUrl.trim().length > 0
                  ? alternateVideoUrl
                  : playbackUrl
              }
              playing={!!thumbnailUrl}
              controls={true}
              light={thumbnailUrl}
              loop={false}
              volume={1}
              muted={false}
              pip={false}
            />
          </div>
          <div className="absolute -left-[9999px] -top-[9999px]">
            {previewComponent}
          </div>
        </div>
      </ModalBody>
    </ModalWindow>
  );
};

export const LinkedinPost = ({
  playbackUrl,
  title,
  thumbnailUrl,
  postTitle,
  files,
  handlePublish,
  highlightId,
  uploadedToLinkedIn,
  saveHighlightSocialMediaPost,
  saveShortsSocialMediaPost,
  aspectRatio = 'landscape',
  createdByProcessorId,
  alternateVideoUrl = '',
  posterUrl,
  brandKit,
  downloadUrl,
  series,
  videoUrlOverride,
  disableDescription = false,
  usePoster,
  presenterPictureUrl,
  shared,
  workspace,
  linkedinAccountOwnerUserId,
  isSpeakerKit = false,
  refreshContentKit,
  isSoloEpisode,
}) => {
  const { primaryColor } = brandKit || {};
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [videoDuration, setVideoDuration] = useState(null);
  const videoDownloadUrl = getHighestQualityDownloadUrl(files);
  const videoGCSUrl = downloadUrl || getHighestQualityDownloadUrl(files);
  const [showVideo, setShowVideo] = useState(true);
  const videoRef = useRef(null);

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      setVideoDuration(videoRef.current.duration);
      setShowVideo(false);
    }
  };

  const speakerAvatarUrl =
    presenterPictureUrl ||
    series?.settings?.eventPresenters.find((ep) => ep.type === 'speaker')
      ?.presenterPictureUrl;

  const { posterSlide, handleDownloadPosterImage } = usePosterSlide(
    series,
    undefined,
    false,
    'thumbnail_shorts_1',
    false,
    [
      ['{speaker_image_no_bg}', speakerAvatarUrl],
      ['{thumbnail_text}', title],
    ]
  );

  const previewComponent = posterSlide ? (
    <div className="flex-shrink-0">
      <ScenePreview
        scene={posterSlide}
        brandKit={brandKit}
        width={320}
        height={(320 / 16) * 9}
      />
    </div>
  ) : null;

  const previewComponentToGeneratePngFrom = posterSlide ? (
    <div className="flex-shrink-0">
      <ScenePreview
        scene={posterSlide}
        brandKit={brandKit}
        width={1600}
        height={(1600 / 16) * 9}
        htmlId="poster-id"
      />
    </div>
  ) : null;

  return (
    <>
      {showVideo && (
        <video
          ref={videoRef}
          src={alternateVideoUrl || videoDownloadUrl}
          className="hidden"
          onLoadedMetadata={handleLoadedMetadata}
          onError={() => {
            setShowVideo(false);
          }}
        />
      )}
      {showPublishModal && (
        <LinkedinModal
          highlightId={highlightId}
          title={title}
          description={postTitle}
          createdByProcessorId={createdByProcessorId}
          handlePublish={handlePublish}
          setShowPublishModal={setShowPublishModal}
          thumbnailUrl={thumbnailUrl}
          uploadedToLinkedIn={uploadedToLinkedIn}
          playbackUrl={playbackUrl}
          alternateVideoUrl={alternateVideoUrl}
          aspectRatio={aspectRatio}
          posterUrl={posterUrl}
          saveHighlightSocialMediaPost={saveHighlightSocialMediaPost}
          saveShortsSocialMediaPost={saveShortsSocialMediaPost}
          videoDownloadUrl={videoDownloadUrl}
          videoGCSUrl={videoGCSUrl}
          disableDescription={disableDescription}
          previewComponent={previewComponentToGeneratePngFrom}
          handleDownloadPosterImage={handleDownloadPosterImage}
          shared={shared}
          workspace={workspace}
          linkedinAccountOwnerUserId={linkedinAccountOwnerUserId}
          isSpeakerKit={isSpeakerKit}
          refreshContentKit={refreshContentKit}
          isSoloEpisode={isSoloEpisode}
          series={series}
        />
      )}
      <div
        className={classNames(
          'relative',
          aspectRatio === 'portrait' ? 'w-[225px]' : 'w-[320px]'
        )}
      >
        <button
          className={classNames(
            'flex flex-col gap-3',
            !videoUrlOverride
              ? aspectRatio === 'portrait'
                ? 'max-w-[225px]'
                : 'max-w-[320px]'
              : 'w-full'
          )}
          onClick={() => {
            mixpanel.track('Content Kit: LinkedIn: Modal Opened', {
              highlightId,
              aspectRatio,
            });
            setShowPublishModal(true);
          }}
        >
          <div
            className={classNames(
              'w-full relative flex items-center',
              aspectRatio === 'portrait' ? 'h-[400px] rounded-lg' : 'bg-white',
              aspectRatio === 'square' ? 'h-[320px] rounded-lg' : 'bg-white'
            )}
            style={{
              backgroundColor: videoUrlOverride ? 'transparent' : primaryColor,
            }}
          >
            {uploadedToLinkedIn && (
              <div className="flex min-w-full min-h-full absolute top-0 left-0 bg-black/50 z-10"></div>
            )}
            {usePoster ? <img src={thumbnailUrl} alt={title} /> : null}
            {videoUrlOverride || usePoster ? null : previewComponent}
            <div className="flex gap-1 absolute bottom-1.5 left-1.5 text-white text-xs font-medium">
              {videoDuration && (
                <div className="flex px-1.5 bg-purple rounded items-center justify-center">
                  {formatDuration(videoDuration)}
                </div>
              )}
              {uploadedToLinkedIn && (
                <div className="flex px-1.5 bg-purple rounded items-center justify-center gap-1">
                  <div className="flex justify-center items-center w-2 h-2 rounded border-white border">
                    <CheckMarkIcon className="fill-white" />
                  </div>
                  <span>PUBLISHED</span>
                </div>
              )}
            </div>
          </div>
          <div className="text-blue-dark font-semibold text-left">{title}</div>
        </button>
        {videoUrlOverride ? (
          <video
            className={classNames(
              'absolute inset-0 -z-10',
              aspectRatio === 'portrait' && 'w-[225px]',
              aspectRatio === 'square' && 'w-[320px]'
            )}
            width={320}
            src={videoUrlOverride}
          />
        ) : null}
      </div>
    </>
  );
};
