import { useState, useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { sendEvent } from '../helper/api';
import { useStartLoading } from './useStartLoading';
import { useProcessedActions } from '../helper/useProcessedActions';

/**
 * A hook for ejecting meeting users.
 */
export const useEjectMeetingUsers = ({ meetingId, users, user }) => {
  const [ejectAllActionId, setEjectAllActionId] = useState(null);

  const { hasActionCompleted: hasEjectAllActionCompleted } =
    useProcessedActions(ejectAllActionId);

  /**
   * Ejects all selected users.
   */
  const ejectAllUsers = useCallback(() => {
    const actionId = uuidv4();
    setEjectAllActionId(actionId);
    const otherUsers = users.filter(
      (currentUser) => user?.userId !== currentUser?.userId
    );
    sendEvent(user?.userId, meetingId, {
      type: 'EJECT_ALL_USERS',
      users: otherUsers,
      actionId,
    });
  }, [meetingId, user?.userId, users]);

  /**
   * Eject All Users Loading Logic
   */
  const {
    isLoading: isEjectAllUsersLoading,
    onClickWithLoading: handleEjectAll,
    disableLoading,
  } = useStartLoading(ejectAllUsers, undefined, undefined, 'Eject All Users');

  useEffect(() => {
    if (hasEjectAllActionCompleted() && isEjectAllUsersLoading) {
      disableLoading();
    }
  }, [
    hasEjectAllActionCompleted,
    ejectAllActionId,
    disableLoading,
    isEjectAllUsersLoading,
  ]);

  return {
    isEjectAllUsersLoading,
    handleEjectAll,
  };
};
