import React from 'react';

export const PlayIcon = ({ color = '#696F8C', width = 15, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 7.825V8.175C14.499 8.5082 14.3235 8.81651 14.0375 8.9875L2.85 15.5C2.175 15.9 1.825 15.9 1.525 15.725L1.2125 15.55C0.933994 15.3837 0.759605 15.0867 0.75 14.7625V1.2375C0.751011 0.904297 0.926513 0.595982 1.2125 0.424998L1.525 0.249998C1.825 0.0749977 2.175 0.0749977 3.05 0.587498L14.0375 7.0125C14.3235 7.18348 14.499 7.4918 14.5 7.825Z"
        fill={color}
      />
    </svg>
  );
};
