import { ContentState, convertFromRaw, convertToRaw } from 'draft-js';
import { googleFonts } from '../../helper/font';

export const customStylesPrefixes = {
  COLOR: 'color_',
  FONT_FAMILY: 'fontFamily_',
  FONT_SIZE: 'fontSize_',
};

const fontSizes = [8, 10, 12, 14, 16, 18, 20, 24, 28, 32, 38, 46, 54, 62, 72];

const createInlineStyles = (prefix, simpleArray, itemMapper) => {
  return simpleArray.reduce((newInlineStylesMap, item) => {
    newInlineStylesMap[`${prefix}${item}`] = itemMapper(item);
    return newInlineStylesMap;
  }, {});
};

/* Used for defining custom styles - both static and dynamic (created while runtime). Here we can provide default styles such as available fonts, sizes and other inline styles */
export const inlineStyleMap = {
  ...createInlineStyles(
    customStylesPrefixes.FONT_FAMILY,
    googleFonts,
    (fontFamily) => ({
      fontFamily,
    })
  ),
  ...createInlineStyles(
    customStylesPrefixes.FONT_SIZE,
    fontSizes,
    (fontSize) => ({
      fontSize,
    })
  ),
  STRIKETHROUGH: {
    textDecoration: 'line-through',
  },
};

export const setInlineStyleMap = (callback) => {
  callback(inlineStyleMap);
};

export const addPrefix = (cssProperty) => `${cssProperty}_`;
export const stripPrefix = (styleName) => styleName.replace(/^(.+?)_/, '');

/* Simple filtering function that returns styles by their prefix */
const getInlineStylesByPrefix = (prefix, mapperFn) => {
  let result = {};

  for (const key in inlineStyleMap) {
    if (key.includes(prefix)) {
      if (mapperFn) {
        result[key] = mapperFn(key, inlineStyleMap[key]);
      } else {
        result[key] = inlineStyleMap[key];
      }
    }
  }

  return result;
};

export const findStylesByPrefix = (editorState, prefix) => [
  ...new Set(
    editorState
      .getCurrentContent()
      .getBlocksAsArray()
      .flatMap((block) =>
        block
          .getCharacterList()
          .toArray()
          .flatMap((character) => character.getStyle().toArray())
      )
      .filter((style) => style?.startsWith(prefix))
  ),
];

export const getCustomStylesFromState = (editorState, cssProperty) => {
  const prefix = addPrefix(cssProperty);

  const stylesToApply = findStylesByPrefix(editorState, prefix);

  stylesToApply.forEach((style) => {
    const value = stripPrefix(style);
    setInlineStyleMap((inlineStyleMap) => {
      inlineStyleMap[style] = {
        [cssProperty]: value,
      };
    });
  });
};

export const getCustomFontsFromState = (editorState) => {
  if (!editorState) {
    return [];
  }
  return findStylesByPrefix(editorState, customStylesPrefixes.FONT_FAMILY).map(
    stripPrefix
  );
};

export const fontSizesOptions = Object.values(
  getInlineStylesByPrefix(customStylesPrefixes.FONT_SIZE, (key, item) => ({
    value: key,
    label: item.fontSize,
  }))
);

export const fontFamiliesOptions = Object.values(
  getInlineStylesByPrefix(
    customStylesPrefixes.FONT_FAMILY,
    (key, item) => ({
      value: key,
      label: item.fontFamily,
    }),
    inlineStyleMap
  )
);

export const getColorsOptions = () =>
  Object.values(
    getInlineStylesByPrefix(
      customStylesPrefixes.COLOR,
      (key, item) => ({
        value: key,
        label: item.color,
      }),
      inlineStyleMap
    )
  );

export const fromRawContentState = (serializedState) => {
  try {
    const json = JSON.parse(serializedState);
    return convertFromRaw(json);
  } catch (e) {
    try {
      return ContentState.createFromText(serializedState);
    } catch (e) {
      return ContentState.createFromText(
        'Click on the Text Box block and start typing to edit content.'
      );
    }
  }
};

export const getMeetingTitle = (title) => {
  try {
    return fromRawContentState(title).getPlainText();
  } catch (e) {
    // it was not RichText content state, just string
    return title;
  }
};

export const toRawContentState = (editorContent) => {
  return JSON.stringify(convertToRaw(editorContent));
};
