import React from 'react';
import { getStaticAssetUrl } from '../helper/getStaticAssetUrl';
import { Modal, ModalWindow } from './Modal';
import { FuturisticBackground } from './FuturisticBackground';

const logo = getStaticAssetUrl('logo_text.png');

export const ZyncErrorModal = ({ children, title, message, img, imgSrc }) => {
  return (
    <ModalWindow showCancel={false} size={Modal.size.fullscreen}>
      <FuturisticBackground responsiveHideTopDecorations />
      <div className="flex justify-center items-center h-full w-full flex-col gap-3 z-[1001] relative sm-landscape:py-[10%]">
        <div className="h-11 flex justify-center items-center">
          <img src={logo} alt="zync logo" className="w-28 object-cover" />
        </div>
        <div className="text-center flex flex-col gap-2 justify-center items-center lg:px-24 px-8 overflow-auto">
          <div className="text-blue-dark font-bold text-2xl sm:text-4xl">
            {title}
          </div>
          <div className="text-blue-gray font-medium text-base sm:text-2xl">
            {message}
          </div>
          {children}
          {img || <img src={imgSrc} alt="" className="w-96" />}
        </div>
      </div>
    </ModalWindow>
  );
};
