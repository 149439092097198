import { useCallback, useRef, useState } from 'react';

export const useDelayedFnCallWithCancel = (delayInSeconds) => {
  const [countdown, setCountdown] = useState(delayInSeconds);

  const timeoutRef = useRef(null);
  const intervalRef = useRef(null);

  const resetCountdown = useCallback(() => {
    clearTimeout(timeoutRef.current);
    clearInterval(intervalRef.current);
    setCountdown(delayInSeconds);
  }, [delayInSeconds]);

  const call = useCallback(
    (handler, onFinish) => {
      if (intervalRef.current || timeoutRef.current) {
        return;
      }

      intervalRef.current = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1_000);

      timeoutRef.current = setTimeout(async () => {
        await handler();
        onFinish();
        resetCountdown();
      }, delayInSeconds * 1_000);
    },
    [delayInSeconds, resetCountdown]
  );

  return {
    countdown,
    call,
    cancel: resetCountdown,
  };
};
