import React from 'react';
import styled, { css } from 'styled-components';

const baseBubbleCss = css`
  position: absolute;
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: -1;
`;

const TopRightEllipse = styled.div`
  ${baseBubbleCss};
  width: 878px;
  height: 878px;
  right: 0;
  top: 0;
  transform: translate(50%, -20%);
  background: radial-gradient(
    45.65% 45.65% at 50% 50%,
    rgba(190, 238, 152, 0.26) 0%,
    rgba(190, 238, 152, 0) 100%
  );
  filter: blur(41.12px);
`;

const BottomRightEllipse = styled.div`
  ${baseBubbleCss};
  width: 1075px;
  height: 1075px;
  background: radial-gradient(
    45.65% 45.65% at 50% 50%,
    rgba(23, 194, 192, 0.32) 0%,
    rgba(23, 194, 192, 0) 100%
  );
  filter: blur(41.12px);
  transform: translate(50%, 50%);
  bottom: 0;
  right: 0;
`;

const MiddleLeftEllipse = styled.div`
  ${baseBubbleCss};
  width: 743px;
  height: 743px;
  background: radial-gradient(
    45.65% 45.65% at 50% 50%,
    rgba(23, 194, 192, 0.2) 0%,
    rgba(23, 194, 192, 0) 100%
  );
  transform: translate(-50%, -50%);
  filter: blur(41.12px);
  bottom: 20%;
  left: 0;
`;

const BottomLeftEllipse = styled.div`
  ${baseBubbleCss};
  background: radial-gradient(
    45.65% 45.65% at 50% 50%,
    rgba(190, 238, 152, 0.52) 0%,
    rgba(190, 238, 152, 0) 100%
  );
  filter: blur(41.12px);
  width: 338px;
  height: 338px;
  transform: translateY(50%);
  bottom: 0;
  left: 10%;
`;

export const GradientBackground = () => {
  return (
    <Container>
      <TopRightEllipse />
      <BottomRightEllipse />
      <MiddleLeftEllipse />
      <BottomLeftEllipse />
    </Container>
  );
};
