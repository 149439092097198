import styled, { css } from 'styled-components/macro';
import {
  faMicrophoneSlash,
  faVideoSlash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sizeToString, sizeToNumber } from '../../helper/css';
import { blockActionMenuHoverStyles } from '../common/ActionMenu';

/**
 * A video element that horizontally flips video sourced
 * from a local stream.
 */
export const VideoElement = styled.video`
  transform: ${({ isLocal }) => (isLocal ? 'scale(-1, 1)' : 'none')};
  width: ${({ width }) => sizeToString(width, '100%')};
  height: ${({ height }) => sizeToString(height, '100%')};
  flex-shrink: 0;
  object-fit: cover;
  z-index: 1;
  border-radius: ${({ theme }) => theme.spacing(1)}px;
`;

/**
 * An overall container defining positioning information
 * affecting the StyledVideoContainer and its child,
 * the video element.
 */
export const VideoDimensionsContainer = styled.div`
  position: relative;
  ${({ position, width, height, opacity, aspectRatio }) => css`
    ${position &&
    css`
      position: absolute;
      top: ${sizeToString(position.top, 0)};
      left: ${sizeToString(position.left, 0)};
    `}
    width: ${sizeToString(width)};
    height: ${!height
      ? `${sizeToString(width * aspectRatio)}`
      : sizeToString(height)};
    opacity: ${opacity >= 0 ? (Number.isNaN(opacity) ? 1 : opacity) : 1};
  `}
  isolation: isolate;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  color: white;
  isolation: isolate;
  border-radius: ${({ isCircle, theme }) =>
    isCircle ? '50%' : `${theme.spacing(1)}px`};

  ${({ highlight }) =>
    highlight &&
    css`
      box-shadow: inset 0 0 0 3px ${({ theme }) => theme.palette.border.hover},
        0 0 2px 3px ${({ theme }) => theme.palette.border.hover};
    `};
  ${({ isCircle, isMinimal }) =>
    isCircle &&
    css`
      border-radius: 50%;

      ${isMinimal &&
      css`
        overflow: visible;
      `}
      video {
        border-radius: 50%;
        /* below lines fix Chrome / IOS bug with border-radius breaking when element is rotated, manages overflow and has border-radius set */
        will-change: transform;
        -webkit-mask-image: -webkit-radial-gradient(white, black);
      }
    `}

  ${blockActionMenuHoverStyles}
`;

/**
 * A background for a rectangular or circular video
 * which will not bleed out the edges of the video element.
 */
export const VideoBackground = styled.div`
  position: absolute;
  top: 1px;
  left: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background: ${({ color }) => color ?? 'black'};
  color: white;
  border-radius: ${({ isCircle, theme }) =>
    isCircle ? '50%' : `${theme.spacing(1)}px`};
  margin: 0;
  box-shadow: 0px 0px 16px rgba(101, 101, 101, 0.25);
  ${({ isCircle, isMinimal }) =>
    isCircle &&
    isMinimal &&
    css`
      border: 2px solid white;
    `}
  box-sizing: border-box;
`;

/** Help VideoAvatar choose between a small and large value given size. */
const getInitialsSizeValue = (
  size,
  valueIfSmall,
  valueIfLarge,
  minimal = false
) => {
  valueIfSmall = sizeToString(valueIfSmall);
  valueIfLarge = sizeToString(valueIfLarge);

  if (!size) {
    return minimal ? valueIfSmall : valueIfLarge;
  } else if (typeof size === 'string') {
    return size;
  } else {
    const threshold = 80;
    const sizeNumber = sizeToNumber(size);
    return sizeNumber > threshold ? valueIfLarge : valueIfSmall;
  }
};

/**
 * A circular avatar containing the user's initials,
 * shown when the video track is not available.
 *
 * @param{object} props
 * @param{boolean} fill If true, fills the parent/container's height/width.
 * @param{number | string} props.size If defined, and fill is false, determines the width/height of the avatar circle.
 */
export const VideoAvatar = styled.div`
  // container contents centered vertically/horizontally
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  ${({ center }) =>
    center &&
    css`
      margin: 0 auto;
      align-self: center;
    `} // expand to height/width of parent container if fill is specified
          // otherwise, use size.
  width: ${({ size, fill, isMinimal }) =>
    fill ? '100%' : getInitialsSizeValue(size, 40, 60, isMinimal)};
  height: ${({ size, fill, isMinimal }) =>
    fill ? '100%' : getInitialsSizeValue(size, 40, 60, isMinimal)};

  // colors and styles for circle and initials.
  cursor: pointer;
  background-color: ${({ color }) => color ?? 'black'};
  border: 2px solid #fff;
  border-radius: 50%;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  color: #fff;
  text-transform: uppercase;

  ${({ isMinimal }) =>
    isMinimal &&
    css`
      background-color: rgba(0, 0, 0, 0);
      border: 2px solid rgba(0, 0, 0, 0);
      box-shadow: none;
      color: #fff;
    `}
`;

/** A name label/nametag. */
export const NameLabel = styled.div`
  overflow: hidden;
  user-select: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 20px);

  font-size: 12px;
  line-height: 18px;

  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  z-index: 2;
`;

/** Container for the video's full name tag. */
export const PositionedVideoNameLabel = styled(NameLabel)`
  position: absolute;
  z-index: 100;
  ${({ isCircle }) =>
    isCircle
      ? css`
          text-align: center;
          bottom: ${({ isMinimal }) => (isMinimal ? '-24px' : '32px')};
          overflow: hidden;
        `
      : css`
          left: 8px;
          bottom: 8px;
        `}
`;

/** A video icon with slash, shown to indicate video off. */
export const VideoIcon = styled(FontAwesomeIcon).attrs((props) => ({
  icon: faVideoSlash,
}))`
  width: 20px;
  height: 16px;

  color: rgba(255, 255, 255, 0.8);
`;

/** A microphone icon with slash, shown to indicate microphone off. */
export const AudioIcon = styled(FontAwesomeIcon).attrs((props) => ({
  icon: faMicrophoneSlash,
}))`
  ${(props) =>
    props.size === 'small' &&
    css`
      width: 15px;
      height: 12px;
    `}
  ${(props) =>
    (!props.size || props.size === 'medium') &&
    css`
      width: 20px;
      height: 16px;
    `}

  color: rgba(255, 255, 255, 0.8);
`;

export const RectangularVideoIcons = styled.div`
  text-align: center;
  left: ${({ isCircle }) => (isCircle ? 0 : 'unset')};
  right: ${({ isCircle }) => (isCircle ? 0 : '10px')};
  bottom: ${({ isMinimal, isCircle, videoIconsPosition }) =>
    videoIconsPosition
      ? videoIconsPosition.bottom
      : isMinimal
      ? '6px'
      : isCircle
      ? '2px'
      : '10px'};
  justify-content: ${({ isCircle }) => (isCircle ? 'center' : 'flex-start')};
  align-items: ${({ isCircle }) => (isCircle ? 'center' : 'flex-start')};
  flex-direction: ${({ isCircle }) => (isCircle ? 'row' : 'row-reverse')};
  position: absolute;
  display: flex;
  z-index: 2;

  > * {
    margin: ${({ isMinimal }) => (isMinimal ? '0' : '0 4px')};
    height: ${({ isMinimal }) => (isMinimal ? '17px' : '21px')} !important;
    width: ${({ isMinimal }) => (isMinimal ? '17px' : '21px')} !important;
    object-fit: cover;
  }
`;
