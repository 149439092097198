import { ConfirmationModal, Modal } from './Modal';
import { Button } from './common/Button';
import React from 'react';

export const DevicesNotFoundModal = ({ onDismiss }) => {
  return (
    <ConfirmationModal
      title="Camera and microphone could not be found"
      text={
        <div className="flex flex-col gap-5 -mt-4">
          <span>
            We were unable to detect any camera/mic attached to your device. We
            recommend that you use Zync with a camera and a microphone for an
            optimal meeting experience. You can use this tool{' '}
            <a
              className="text-blue-light hover:underline"
              href="https://webcammictest.com/"
              target="_blank"
              rel="noreferrer"
            >
              Webcam test
            </a>{' '}
            to test your devices. You can try again by clicking "Reload" once
            you have addressed any issues with your devices.
          </span>
        </div>
      }
      size={Modal.size.md}
      actionButtons={
        <div>
          <Button
            size={Button.sizes.FULL}
            color={Button.colors.PURPLE}
            onClick={onDismiss}
          >
            <span className="text-base font-bold">Dismiss</span>
          </Button>
        </div>
      }
      width={512}
      height={350}
      shrinkOnResize
    />
  );
};
