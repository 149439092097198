import React from 'react';

import MessagePartials from './MessagePartials';

const MeetingEndingShortlyAdmin = ({
  billingAdminEmail,
  plan,
  isSubscriptionSetup,
  meetingCountdownProperties,
  isSubscriptionActive,
  workspaceId,
}) => {
  const { timeToEnd, showDynamicCountdown } = meetingCountdownProperties;
  const { name, maxMinutes } = plan;

  return (
    <div className="flex justify-between items-center">
      <div className="flex-grow w-full px-5">
        <MessagePartials.YourMeetingIsEnding
          planName={name}
          timeToEnd={timeToEnd}
          maxMinutes={maxMinutes}
          showDynamicCountdown={showDynamicCountdown}
          workspaceId={workspaceId}
        />
      </div>
    </div>
  );
};

export default MeetingEndingShortlyAdmin;
