import React from 'react';
import styled from 'styled-components';
import { ToastContainer, Zoom } from 'react-toastify';

// Adds room for the bottom control bars on desktop devices.
const DesktopToastContainerWrapper = styled.div`
  .Toastify__toast-container--bottom-center,
  .Toastify__toast-container--bottom-right,
  .Toastify__toast-container--bottom-left {
    bottom: 70px;
  }
`;

export const ToastOutlet = ({ isMobile }) => {
  const toastContainer = (
    <ToastContainer
      transition={Zoom}
      autoClose={4000}
      pauseOnFocusLoss
      hideProgressBar={true}
      theme="dark"
    />
  );
  return isMobile ? (
    toastContainer
  ) : (
    <DesktopToastContainerWrapper>
      {toastContainer}
    </DesktopToastContainerWrapper>
  );
};
