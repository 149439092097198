import { SummaryRenderer } from '../../../blocks';
import { Border, TimeDisplay } from '../../../components/Summary';
import { H3Text } from '../../../components/common/Text';

export const OtherContent = ({ blocksData }) => {
  if (!blocksData || blocksData.length === 0) return null;

  return blocksData.map((blockData) => {
    const { code, block } = blockData || {};
    const { details } = code || {};
    const { start_time, blockInstanceId } = block || {};
    const { title } = details || {};

    return (
      <Border key={blockInstanceId}>
        <H3Text>{title}</H3Text>
        <TimeDisplay startTime={start_time} />
        <SummaryRenderer code={code} block={block} liveMeetingSummary={false} />
      </Border>
    );
  });
};
