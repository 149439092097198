import { useEffect, useMemo } from 'react';
import { scrollToElement } from './YoutubePosts';
import { Link, useParams } from 'react-router-dom';
import { TwitterPost } from './TwitterPost';
import { TwitterIcon } from '../../../components/icons/TwitterIcon';
import { useTrackActivity } from '../../../hooks/track';

export const TwitterPosts = ({
  highlights,
  saveHighlightSocialMediaPost,
  saveShortsSocialMediaPost,
  shorts,
  posterUrl,
  workspace,
  series,
  shared,
  meetingSeriesId,
  isSoloEpisode,
}) => {
  const { section } = useParams();

  const { brandKit } = workspace || {};

  const track = useTrackActivity();

  useEffect(() => {
    track('Content Kit: Twitter Section Loaded');
  }, [track]);

  const twitterHighlights = useMemo(
    () =>
      highlights
        .filter((h) =>
          isSoloEpisode ? false : h.autoGenerateSocialMediaVideo === 'twitter'
        )
        .map((h) => ({
          highlightId: h.highlightId,
          title: h.title,
          thumbnailUrl: h.HIGHLIGHT_THUMBNAIL?.imageUrl,
          playbackUrl:
            h?.HIGHLIGHT_EDITED_LANDSCAPE?.playbackUrl ||
            h?.HIGHLIGHT_RAW_LANDSCAPE?.playbackUrl,
          postTitle:
            h.HIGHLIGHT_SOCIAL_MEDIA_POSTS?.socialMediaPosts.twitter.text,
          files:
            h?.HIGHLIGHT_EDITED_LANDSCAPE?.files ||
            h?.HIGHLIGHT_RAW_LANDSCAPE?.files ||
            [],
        })),
    [highlights, isSoloEpisode]
  );

  const twitterSoloHighlights = useMemo(
    () =>
      highlights
        .filter((h) => h?.HIGHLIGHT_EDITED_LANDSCAPE)
        .map((h) => ({
          highlightId: h.highlightId,
          title: h.title,
          thumbnailUrl: h.HIGHLIGHT_THUMBNAIL?.imageUrl,
          playbackUrl: h.HIGHLIGHT_EDITED_LANDSCAPE.playbackUrl,
          postTitle:
            h.HIGHLIGHT_SOCIAL_MEDIA_POSTS.socialMediaPosts.twitter.text,
          aspectRatio: 'landscape',
          downloadUrl: h?.HIGHLIGHT_EDITED_LANDSCAPE?.playbackUrl,
        })),
    [highlights]
  );

  useEffect(() => {
    if (!section) return;
    scrollToElement(section);
  }, [section]);

  const twitterPosts = useMemo(
    () =>
      shorts
        .filter(
          (s) =>
            s.INTERESTING_MOMENTS_SHORT_MIXED_EDITED_LANDSCAPE?.aspectRatio ===
            'landscape'
        )
        .map((s, index) => ({
          highlightId: s.highlightId,
          title: s.shortTextData?.thumbnailTitle || `Shorts ${index + 1}`,
          postTitle: s.shortTextData?.twitterPost || '',
          thumbnailUrl: s.thumbnailUrl,
          playbackUrl:
            s.INTERESTING_MOMENTS_SHORT_MIXED_EDITED_LANDSCAPE.playbackUrl,
          downloadUrl:
            s.INTERESTING_MOMENTS_SHORT_MIXED_EDITED_LANDSCAPE.playbackUrl,
          aspectRatio:
            s.INTERESTING_MOMENTS_SHORT_MIXED_EDITED_LANDSCAPE.aspectRatio,
          presenterPictureUrl: s.shortTextData?.presenterPictureUrl || '',
        })),
    [shorts]
  );

  return (
    <>
      <Link
        to={
          shared
            ? `/e/${meetingSeriesId}/contentkit`
            : `/e/${meetingSeriesId}/details/publish`
        }
        className="self-start pl-64"
      >
        <div className="font-medium text-sm">
          <span className="text-[#97A0AF]">
            Content Kit /{'  '}
            <span className="text-blue-dark">Twitter Highlights</span>
          </span>
        </div>
      </Link>
      <div className="flex flex-col gap-5 justify-center items-center w-full">
        <div className="flex flex-col gap-5 justify-center w-full mx-auto pl-64 pr-52 relative pb-8">
          <div className="flex flex-col gap-5">
            <div className="pt-5">
              <TwitterIcon width="40px" height="40px" />
            </div>
            {twitterPosts?.length ? (
              <>
                <div
                  className="text-blue-dark font-semibold text-xl"
                  id="video-clips"
                >
                  Twitter Short Video Clips
                </div>
                <div className="flex gap-[30px] flex-wrap">
                  {twitterPosts.length > 0 &&
                    twitterPosts.map((s) => (
                      <TwitterPost
                        playbackUrl={s.playbackUrl}
                        thumbnailUrl={s.thumbnailUrl}
                        title={s.title}
                        postTitle={s.postTitle}
                        posterUrl={posterUrl}
                        aspectRatio={s.aspectRatio}
                        brandKit={brandKit}
                        key={s.highlightId}
                        files={[]}
                        downloadUrl={s.downloadUrl}
                        saveShortsSocialMediaPost={saveShortsSocialMediaPost}
                        series={series}
                        presenterPictureUrl={s.presenterPictureUrl}
                        shared={shared}
                        highlightId={s.highlightId}
                        isSoloEpisode={isSoloEpisode}
                      />
                    ))}
                </div>
              </>
            ) : null}
          </div>
          <div className="absolute inset-0 bg-[#3EEDE80D] -z-[9999]" />
          <h2 className="text-blue-dark font-semibold text-xl mt-4" id="tweets">
            Twitter Posts
          </h2>
          <div className="flex flex-wrap gap-[30px]">
            {!isSoloEpisode &&
              twitterHighlights.map((s) => (
                <TwitterPost
                  playbackUrl={s.playbackUrl}
                  thumbnailUrl={s.thumbnailUrl}
                  title={s.title}
                  postTitle={s.postTitle}
                  posterUrl={posterUrl}
                  aspectRatio={s.aspectRatio}
                  brandKit={brandKit}
                  key={s.highlightId}
                  files={s.files}
                  downloadUrl={s.downloadUrl}
                  saveHighlightSocialMediaPost={saveHighlightSocialMediaPost}
                  series={series}
                  usePoster={true}
                  shared={shared}
                  highlightId={s.highlightId}
                  isSoloEpisode={isSoloEpisode}
                />
              ))}
            {isSoloEpisode
              ? twitterSoloHighlights.map((s) => (
                  <TwitterPost
                    playbackUrl={s.playbackUrl}
                    thumbnailUrl={s.thumbnailUrl}
                    title={s.title}
                    postTitle={s.postTitle}
                    posterUrl={posterUrl}
                    aspectRatio={s.aspectRatio}
                    brandKit={brandKit}
                    key={s.highlightId}
                    files={[]}
                    downloadUrl={s.downloadUrl}
                    saveHighlightSocialMediaPost={saveHighlightSocialMediaPost}
                    series={series}
                    usePoster={true}
                    shared={shared}
                    highlightId={s.highlightId}
                    isSoloEpisode={isSoloEpisode}
                  />
                ))
              : null}
          </div>
        </div>
      </div>
    </>
  );
};
