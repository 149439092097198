import React from 'react';

export const PlayerIcon = ({
  width = '21px',
  height = '21px',
  className = 'fill-purple',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 21 21"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8337 4.3776C18.8337 3.45713 18.0875 2.71094 17.167 2.71094H3.83366C2.91318 2.71094 2.16699 3.45713 2.16699 4.3776V14.3776C2.16699 15.2981 2.91318 16.0443 3.83366 16.0443H8.07533C8.29394 16.0452 8.50344 16.132 8.65866 16.2859L9.90033 17.5276C10.0174 17.6448 10.1763 17.7108 10.342 17.7109H10.6587C10.8244 17.7108 10.9832 17.6448 11.1003 17.5276L12.342 16.2859C12.4972 16.132 12.7067 16.0452 12.9253 16.0443H17.167C18.0875 16.0443 18.8337 15.2981 18.8337 14.3776V4.3776ZM17.167 14.3776H12.9253C12.2625 14.3782 11.627 14.642 11.1587 15.1109L10.5003 15.7693L9.84199 15.1109C9.3736 14.642 8.73814 14.3782 8.07533 14.3776H3.83366V4.3776H17.167V14.3776ZM9.16601 12.0954C8.96189 11.9872 8.83408 11.7753 8.83366 11.5443V7.21094C8.83408 6.97993 8.96189 6.76799 9.16601 6.65983C9.37013 6.55166 9.61727 6.56491 9.80866 6.69427L13.0503 8.86094C13.225 8.97475 13.3303 9.16912 13.3303 9.3776C13.3303 9.58609 13.225 9.78046 13.0503 9.89427L9.80866 12.0609C9.61727 12.1903 9.37013 12.2035 9.16601 12.0954Z"
    />
  </svg>
);
