import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AuthoringBlockRenderer } from '../../blocks';
import classNames from '../../helper/classNames';
import { LoadingSpinnerAbsoluteFullCentered } from '../LoadingSpinner';
import { LogoSpinnerFullCentered } from '../LogoSpinner';
import { useAuthoringModals, useAuthoringTool } from './hooks';
import { RightPanel } from './RightPanel';
import { SlideBlockPreview } from './SlideBlockIcon';
import { SlideTray } from './Menu/SlideTray';
import {
  AuthoringBlocksContainer,
  EditingControlsContainer,
  MainContentPresentationWrapper,
  MainContentSlideImage,
  SceneContentContainer,
  RightPanelContainer,
} from './AuthoringTool.styled';
import { SLIDE_CONTAINER_ID } from '../../helper/constants';
import { SlideContainerRefContext } from '../Slide';
import { useElementSize } from '../../hooks/useElementSize';
import { standardScreenSize } from '../../helper/css';
import { enrichBlockListForAuthoring, calculateBlockPosition } from './util';
import { DeleteBlockConfirmation } from './Confirmation';
import { ToastOutlet } from '../ToastOutlet';
import { isMobile } from '../../helper';
import { INVISIBLE_IMAGE_SRC } from '../../helper/image';
import { overrideDynamicValue } from 'zync-common/helper/overrideDynamicValue';
import { useDeepCompareMemo } from 'use-deep-compare';
import { presenterTypes } from '../series/settings/EventPresenters';
import { SeriesInfoContext } from '../LiveMeeting';

/* initially, our blocks start with all editPosition values at 0 */
export const checkIsBlockInitialized = (block) => {
  return Boolean(
    block.editPosition.top ||
      block.editPosition.left ||
      block.editPosition.width ||
      block.editPosition.height
  );
};

export const getFakeSpeakers = (speakers) => {
  return speakers.map((speaker, i) => ({
    avatarUrl: speaker.presenterPictureUrl,
    userId: `${speaker.emailAddress || speaker.fullName}`,
    roles: ['guest', 'presenter', 'speaker', 'speaker' + (i + 1)],
    userName: speaker.fullName,
    fullName: speaker.fullName,
    jobTitle: speaker.jobTitle,
    _id: speaker._id,
  }));
};

export const getFakeHost = (host) => {
  if (!host) {
    return undefined;
  }

  const userId = `BOT_${host.emailAddress || host.fullName}`;

  return {
    avatarUrl: host.user?.avatarUrl || host.presenterPictureUrl,
    userId,
    roles: ['host', 'moderator'],
    userName: host.fullName,
    jobTitle: host.jobTitle,
    _id: host._id || userId,
  };
};
const MainSlideImage = ({
  slideUrl,
  handleOnLoad,
  brandKit,
  backgroundColor,
}) => {
  const color = overrideDynamicValue(backgroundColor, brandKit);

  return (
    <MainContentSlideImage
      src={color ? INVISIBLE_IMAGE_SRC : slideUrl}
      draggable={false}
      id="scene-background"
      style={{
        backgroundColor: color,
      }}
      onLoad={() => handleOnLoad(true)}
      onError={(e) => {
        e.target.src =
          'https://via.placeholder.com/800x450.png?text=No+Image+Found';
      }}
    />
  );
};

export const MainContent = ({
  setEditableMode,
  setShowModal,
  showModal,
  editableMode,
}) => {
  const {
    blocks: blockTemplates,
    selectedSlide,
    updateSlideBlock,
    openSlideSettings,
    selectSlideBlock,
    selectedSlideBlock,
    currentSeries,
    status,
    initializeState,
    setSceneLibraryOpen,
  } = useAuthoringTool();

  const {
    deleteSceneConfirmationModalOpen,
    hideDeleteSceneConfirmationModal,
    showDeleteSceneConfirmationModal,
  } = useAuthoringModals();

  const selectedBlockInstanceId = selectedSlideBlock?.blockInstanceId ?? null;
  useEffect(() => {
    setEditableMode(false);
  }, [selectedBlockInstanceId, setEditableMode]);

  const handleDoubleSelect = useCallback(() => {
    setEditableMode(true);
  }, [setEditableMode]);

  // track if resizing is occurring to prevent scene click events during resize.
  const [transitoryBlockCoordinates, setTransitoryBlockCoordinates] =
    useState(null);
  const isResizingBlock = transitoryBlockCoordinates !== null;

  const handleSceneClick = useCallback(() => {
    if (!selectedSlide) {
      return;
    }
    if (!isResizingBlock) {
      selectSlideBlock(null);
      openSlideSettings();
    }
  }, [isResizingBlock, openSlideSettings, selectSlideBlock, selectedSlide]);

  const handleBlockResize = useCallback(
    (resizing, coordinates) => {
      if (resizing) {
        setTransitoryBlockCoordinates(coordinates);
      } else {
        setTimeout(() => setTransitoryBlockCoordinates(null), 10);
      }
    },
    [setTransitoryBlockCoordinates]
  );

  const [isLoaded, setIsLoaded] = useState(false);

  const handleSlideImageLoaded = () => setIsLoaded(true);

  useEffect(() => {
    initializeState();
  }, [initializeState]);

  const slideContainerRef = useRef(null);

  // maintain slide content to a 16 / 9 ratio for accurate block positioning.
  const imageContainerSize = useElementSize();
  const imageContainerRatio = imageContainerSize.height
    ? imageContainerSize.width / imageContainerSize.height
    : undefined;
  const imageSize = useMemo(() => {
    if (!imageContainerRatio) {
      return {
        width: 0,
        height: 0,
      };
    }

    return imageContainerRatio >= 16 / 9
      ? {
          // the container is wider than the image. Base dimensions on the container height
          width: (imageContainerSize.height * 16) / 9,
          height: imageContainerSize.height,
        }
      : {
          // the container is narrower than the image. Base dimensions on the container width.
          width: imageContainerSize.width,
          height: (imageContainerSize.width * 9) / 16,
        };
  }, [
    imageContainerRatio,
    imageContainerSize.height,
    imageContainerSize.width,
  ]);

  const scale = imageSize
    ? imageSize.width / standardScreenSize.width
    : undefined;

  const sceneObjectBlocks = selectedSlide?.slideConfig?.slideBlocks;

  const brandKit = currentSeries?.workspace?.brandKit;

  const { blocks, authoringBlocks } = useMemo(() => {
    const blocks = enrichBlockListForAuthoring(
      sceneObjectBlocks,
      standardScreenSize,
      imageSize,
      blockTemplates,
      brandKit
    );
    const authoringBlocks = blocks.map((block) => {
      const blockPosition =
        block.blockInstanceId === transitoryBlockCoordinates?.blockInstanceId
          ? calculateBlockPosition(
              { ...block.settings, ...transitoryBlockCoordinates },
              standardScreenSize
            )
          : block.position;

      return {
        ...block,
        position: blockPosition,
      };
    });
    return { blocks, authoringBlocks };
  }, [
    sceneObjectBlocks,
    blockTemplates,
    imageSize,
    transitoryBlockCoordinates,
    brandKit,
  ]);

  const styles = useMemo(
    () => ({
      ...standardScreenSize,
      transform: scale ? `scale(${scale})` : undefined,
    }),
    [scale]
  );

  const host =
    currentSeries?.settings?.eventPresenters?.find(
      (eventPresenter) => eventPresenter.type === presenterTypes.HOST
    ) || [];

  const speakers =
    currentSeries?.settings?.eventPresenters?.filter(
      (eventPresenter) => eventPresenter.type === presenterTypes.SPEAKER
    ) || [];

  const fakeUsers = useDeepCompareMemo(() => {
    return [getFakeHost(host), ...getFakeSpeakers(speakers)];
  }, [speakers]);

  const logo = currentSeries?.workspace?.brandKit?.logoUrl;
  const seriesTitle = currentSeries?.title;
  const showName = currentSeries?.workspace?.show?.name;
  const primaryContrast = currentSeries?.workspace?.brandKit?.primaryContrast;
  const primaryColor = currentSeries?.workspace?.brandKit?.primaryColor;
  const accentColor = currentSeries?.workspace?.brandKit?.accentColor;
  const accentContrast = currentSeries?.workspace?.brandKit?.accentContrast;
  const speakersCount = (currentSeries?.settings?.eventPresenters || []).filter(
    (ep) => ep.type === 'speaker'
  ).length;
  const episodeType = currentSeries?.settings?.episodeType;

  const seriesInfoContextValue = useMemo(
    () => ({
      logo,
      seriesTitle,
      showName,
      primaryContrast,
      primaryColor,
      accentColor,
      accentContrast,
      speakersCount,
      episodeType,
    }),
    [
      logo,
      seriesTitle,
      showName,
      primaryContrast,
      primaryColor,
      accentColor,
      accentContrast,
      speakersCount,
      episodeType,
    ]
  );

  if (status.selectedTemplateLoading) {
    return (
      <SceneContentContainer>
        <LogoSpinnerFullCentered />
      </SceneContentContainer>
    );
  }

  return (
    <SlideContainerRefContext.Provider value={slideContainerRef}>
      <SeriesInfoContext.Provider value={seriesInfoContextValue}>
        <ToastOutlet isMobile={+isMobile} />
        <DeleteBlockConfirmation
          open={showModal === 'block'}
          onClose={() => setShowModal(null)}
        />

        <RightPanelContainer>
          <RightPanel />
        </RightPanelContainer>
        <SceneContentContainer
          id={'xxx'}
          ref={imageContainerSize.ref}
          tabIndex={1}
          onClick={handleSceneClick}
          className={classNames(
            status.isUpdatingState && 'opacity-50 pointer-events-none'
          )}
        >
          {status.isUpdatingState && <LoadingSpinnerAbsoluteFullCentered />}
          <MainContentPresentationWrapper
            id={SLIDE_CONTAINER_ID}
            ref={slideContainerRef}
            style={imageSize}
          >
            {selectedSlide && (
              <MainSlideImage
                handleOnLoad={handleSlideImageLoaded}
                slideUrl={selectedSlide.slideUrl}
                backgroundColor={selectedSlide.slideBackgroundColor}
                brandKit={currentSeries?.workspace?.brandKit}
              />
            )}
            <AuthoringBlocksContainer style={styles}>
              {/** Authoring Blocks. */}
              {isLoaded &&
                authoringBlocks
                  .filter(
                    (block) =>
                      !block.details.inlineEditable ||
                      (block.details.inlineEditable && !editableMode) ||
                      block.blockInstanceId !== selectedBlockInstanceId
                  )
                  .map((block) => (
                    <AuthoringBlockRenderer
                      key={`${block.blockInstanceId}-authoringblock`}
                      block={block}
                      users={fakeUsers}
                      accentColor={brandKit?.accentColor}
                      brandKit={brandKit}
                    />
                  ))}
            </AuthoringBlocksContainer>

            <EditingControlsContainer style={imageSize}>
              {/** Editing controls overlaying the authoring blocks. */}
              {blocks.filter(checkIsBlockInitialized).map((block) => (
                <SlideBlockPreview
                  key={`${block.blockInstanceId}-controls`}
                  block={block}
                  onResizing={handleBlockResize}
                  onDoubleSelect={handleDoubleSelect}
                />
              ))}
            </EditingControlsContainer>
            <AuthoringBlocksContainer editable style={styles}>
              {authoringBlocks
                .filter(checkIsBlockInitialized)
                .filter(
                  (block) =>
                    block.details.inlineEditable &&
                    editableMode &&
                    block.blockInstanceId === selectedBlockInstanceId
                )
                .map((block) => (
                  <AuthoringBlockRenderer
                    key={`${block.blockInstanceId}-inlineauthoringblock`}
                    block={block}
                    inlineEditable
                    onUpdate={updateSlideBlock}
                    users={fakeUsers}
                    accentColor={brandKit?.accentColor}
                    brandKit={brandKit}
                  />
                ))}
            </AuthoringBlocksContainer>
          </MainContentPresentationWrapper>
        </SceneContentContainer>

        <SlideTray
          slides={currentSeries.autoLaunchConfig.slides}
          onAddScene={() => setSceneLibraryOpen('end')}
          deleteSceneConfirmationModalOpen={deleteSceneConfirmationModalOpen}
          hideDeleteSceneConfirmationModal={hideDeleteSceneConfirmationModal}
          showDeleteSceneConfirmationModal={showDeleteSceneConfirmationModal}
          brandKit={currentSeries?.workspace?.brandKit}
          users={fakeUsers}
        />
      </SeriesInfoContext.Provider>
    </SlideContainerRefContext.Provider>
  );
};
