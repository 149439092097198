import React from 'react';
import classNames from '../../../helper/classNames';

/* Reusable component displaying toolbar button with icon*/
const ToolbarButton = ({ active, onToggle, style, label, Icon }) => {
  const handleToggle = (e) => {
    e.preventDefault();
    onToggle(style);
  };

  return (
    <button
      className={classNames(
        'bg-white px-2 py-1 rounded-md',
        'hover:bg-opacity-10 hover:bg-purple',
        active && '!bg-opacity-100 bg-purple'
      )}
      onMouseDown={handleToggle}
    >
      <Icon className={active ? 'fill-white text-white' : 'fill-black'} />
      <span className="sr-only">{label}</span>
    </button>
  );
};

export default React.memo(ToolbarButton);
