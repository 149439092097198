import { useCallback, useEffect, useRef, useState } from 'react';
import { CaretIcon } from '../../../components/icons/CaretIcon';
import { RichTextEditor, RichTextToolbar } from '../../../components/richText';
import {
  fromRawContentState,
  getCustomStylesFromState,
  toRawContentState,
} from '../../../components/richText/helpers';
import { EditorState } from 'draft-js';
import { strategyDecorator } from '../../../components/richText/editor/helper';
import { debounce } from 'lodash';
import { positions } from '../../../components/richText/toolbar/ToolbarAutoPosition';

const Email = ({ speakerKit, startCollapsed = false }) => {
  const isInitialized = useRef(false);
  const [isCollapsed, setIsCollapsed] = useState(startCollapsed);
  const { header, emailAddress, subject, emailText } = speakerKit || {};

  const textEditorInitialValues = {
    color: '#000000',
    fontSize: '24px',
    fontFamily: 'Poppins',
  };

  const [editorState, setEditorState] = useState(() => {
    const state = fromRawContentState(emailText);

    return EditorState.moveFocusToEnd(EditorState.createWithContent(state));
  });

  const [lastEditValue, setLastEditValue] = useState(() =>
    toRawContentState(editorState.getCurrentContent())
  );

  useEffect(() => {
    if (isInitialized.current) {
      return;
    }

    isInitialized.current = true;

    const newEditorState = EditorState.createWithContent(
      fromRawContentState(emailText),
      strategyDecorator
    );

    getCustomStylesFromState(newEditorState, 'color');

    setEditorState(newEditorState);
  }, [emailText]);

  const debouncedHandleChange = debounce(
    (editorState) => {
      const content = editorState.getCurrentContent();
      const text = toRawContentState(content);
      if (lastEditValue !== text) {
        setLastEditValue(text);
      }
    },
    600,
    { maxWait: 3000 }
  );

  const handleChange = useCallback(
    (editorState) => {
      setEditorState(editorState);
      debouncedHandleChange(editorState);
    },
    [debouncedHandleChange]
  );

  return (
    <div className="w-[953px] flex flex-col shadow-elevation-one rounded-lg border-[#EBEAED] border">
      <div className="flex flex-row justify-between items-center py-3 px-5 h-12 bg-[#F9F5FF]">
        <div className="text-blue-dark text-sm font-medium truncate">
          {header}
        </div>
        <div className="flex gap-5">
          <button onClick={() => setIsCollapsed((prevState) => !prevState)}>
            {isCollapsed ? (
              <CaretIcon width="15px" height="11px" />
            ) : (
              <CaretIcon
                width="15px"
                height="11px"
                className="rotate-180 fill-blue-gray"
              />
            )}
          </button>
        </div>
      </div>
      {!isCollapsed && (
        <div className="p-5">
          <div className="bg-[#F2FBFF] rounded-2xl py-7 px-9 flex flex-col gap-5">
            <div className="flex flex-col gap-2.5">
              <div className="flex gap-5 items-center">
                <div className="text-blue-dark font-medium text-sm">To</div>
                <div className="w-fit min-w-[300px] py-2 px-3 bg-white text-sm text-blue-gray border border-[#D8DAE5] rounded">
                  {emailAddress}
                </div>
              </div>
              <div className="flex gap-5 items-center">
                <div className="text-blue-dark font-medium text-sm">Sub</div>
                <div className="w-fit min-w-[300px] py-2 px-3 bg-white text-sm text-blue-gray border border-[#D8DAE5] rounded">
                  {subject}
                </div>
              </div>
            </div>
            <div className="flex flex-col bg-white rounded-[10px]">
              <RichTextToolbar
                editorState={editorState}
                onChange={handleChange}
                initialValues={textEditorInitialValues}
                bestPosition={positions.TOP}
                enableHyperlinks
                disabled={true}
              />
              <div className="w-full h-[1px] bg-[#EBEAED]"></div>
              <div className="p-5">
                <RichTextEditor
                  editorState={editorState}
                  onChange={handleChange}
                  readOnly={true}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const SpeakerEmails = ({ speakerKits }) => {
  if (!speakerKits || speakerKits.length === 0) return null;
  return speakerKits.map((sk, index) => (
    <Email
      speakerKit={sk}
      startCollapsed={index === 0 ? false : true}
      key={sk.emailAddress}
    />
  ));
};
