import { useFeedPage } from './useFeedPage';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import React from 'react';

const TranscriptMessage = ({ message }) => {
  const { userName, text } = message || {};

  return (
    <div className="text-black font-inter text-lg">
      {userName && <span className="font-bold">{userName}:</span>} {text}
    </div>
  );
};

export const MainVideo = () => {
  const { selectedHighlight, workspace } = useFeedPage();

  return (
    <div className="flex-[915_0_0%] h-full max-w-[915px]">
      {selectedHighlight && workspace && (
        <div key={selectedHighlight.highlightId}>
          <div className="rounded-[20px] overflow-hidden">
            <ReactPlayer
              className="react-player aspect-video"
              width="100%"
              height="auto"
              url={
                selectedHighlight.HIGHLIGHT_EDITED_LANDSCAPE
                  ? selectedHighlight.HIGHLIGHT_EDITED_LANDSCAPE.playbackUrl
                  : selectedHighlight.HIGHLIGHT_RAW_LANDSCAPE.playbackUrl
              }
              playing={true}
              controls={true}
              light={selectedHighlight.HIGHLIGHT_THUMBNAIL.imageUrl}
              loop={false}
              volume={1}
              muted={false}
              pip={false}
            />
          </div>
          <div className="pl-2">
            <h1 className="text-xl md:text-[24px] font-semibold mt-4 mb-0 text-[#101840]">
              {selectedHighlight.title}
            </h1>

            <Link to={`/${selectedHighlight.meetingSeriesId}/episode`}>
              <h2 className="text-base font-medium text-[#101840]">
                {workspace?.name}
                {selectedHighlight.episodeTitle
                  ? ` | ${selectedHighlight.episodeTitle}`
                  : ''}
              </h2>
            </Link>
          </div>
          <div className="flex flex-col gap-4 pl-2 mt-[34px]">
            {(selectedHighlight?.transcript || []).map((m, index) => (
              <TranscriptMessage
                message={m}
                key={`transcript-message-${index}`}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
