import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { inviteToSeries } from '../auth';
import { fetchWorkspaceApi, updateSeriesApi } from '../helper/api';
import { fromError, logerror } from '../helper/contextualLogger';
import { isUserMeetingController, Roles } from '../helper/roles';
import { validateEmail } from '../helper/validations';
import { getMeetingSeriesProps } from 'zync-common/tracking';
import mixpanel from 'mixpanel-browser';
import { useClickOutside } from '../helper/useClickOutside';
import { InformationModalWrapper } from '../pages/Series/Attendees.styled';
import { Modal, ModalBody, ModalTitle } from '../components/Modal';
import moment from 'moment';
import { getLocalTimezoneName, convertUTCToTimezone } from '../helper/rsvp';
import { SeriesVisibility } from '../helper/visibility';

export const usePermissions = ({
  localDispatch,
  series,
  mode,
  workspaceId,
  attendees,
  meetingSeriesId,
}) => {
  const { user: authenticatedUser } = useSelector((_st) => _st.auth);
  const { userName, emailAddress } = authenticatedUser;
  const {
    scheduledEvent: currentScheduledEvent,
    visibility,
    settings: { enabledFieldsRsvp },
  } = series || {};
  const { startDate, endDate, timezone, registeredAttendees } =
    currentScheduledEvent || {};
  const [newEmailAddress, setNewEmailAddress] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [showMeetingRoles, setShowMeetingRoles] = useState(false);
  const [workspace, setWorkspace] = useState(null);
  const [emailToAddToWorkspace, setEmailToAddToWorkspace] = useState(null);
  const [lastError, setLastError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const isEditingModerators = mode === 'moderator';
  const [showMoreGuests, setShowMoreGuests] = useState(false);
  const [showMoreModerators, setShowMoreModerators] = useState(false);

  const convertedCurrentScheduledEvent = useMemo(() => {
    return {
      startTime: startDate
        ? convertUTCToTimezone(startDate, timezone, 'time')
        : '02:00 PM',
      daySelected: startDate
        ? moment(convertUTCToTimezone(startDate, timezone, 'date'))
        : moment(),
      endTime: endDate
        ? convertUTCToTimezone(endDate, timezone, 'time')
        : '03:00 PM',
      timezoneSelected: timezone || getLocalTimezoneName(),
    };
  }, [endDate, startDate, timezone]);

  const [scheduledEvent, setScheduledEvent] = useState(
    convertedCurrentScheduledEvent
  );

  const lockedRoles = isEditingModerators
    ? [Roles.MODERATOR]
    : Object.values(Roles);

  const filteredAttendees = attendees.filter((u) => {
    const isModerator = isUserMeetingController(u);
    return isEditingModerators ? isModerator : !isModerator;
  });

  const changeRoles = async (emailAddress, newRoles) => {
    const updateSeriesParams = {
      attendeesUpdate: {
        type: 'CHANGE_ROLES',
        userId: emailAddress,
        roles: newRoles,
      },
    };

    const result = await updateSeriesApi(meetingSeriesId, updateSeriesParams);

    localDispatch({ type: 'REFRESH_SERIES', series: result });
  };

  const RoleInformationModal = ({ onClickOutside }) => {
    const containerRef = useRef(null);

    useClickOutside(containerRef, onClickOutside);

    return (
      <InformationModalWrapper ref={containerRef}>
        <Modal>
          <ModalTitle>Custom Roles</ModalTitle>
          <ModalBody>
            <p className="text-blue-gray text-sm font-normal">
              Add Custom Roles to organize your video layout in the meeting.
              e.g. People with “chiefguest” role show up big and center. Those
              with the “guest” role are displayed as small bubbles in the
              bottom.
            </p>
          </ModalBody>
        </Modal>
      </InformationModalWrapper>
    );
  };

  const addUserRole = (roles, targetUserId) => (_, newRole) => {
    mixpanel.track('Series - Role Added', {
      ...getMeetingSeriesProps(series),
      attendee: targetUserId,
      role: newRole,
    });
    changeRoles(targetUserId, roles.concat(newRole));
  };

  const removeUserRole = (roles, targetUserId) => (removedRole) => {
    mixpanel.track('Series - Role Removed', {
      ...getMeetingSeriesProps(series),
      attendee: targetUserId,
      role: removedRole,
    });
    changeRoles(
      targetUserId,
      roles.filter((role) => role !== removedRole)
    );
  };

  const handleChangeEmailAddress = (e) => {
    const mail = e.target.value;
    setIsEmailValid(validateEmail(mail));
    setNewEmailAddress(mail.trim().toLowerCase());
  };

  const refreshWorkspace = useCallback(async () => {
    setWorkspace((await fetchWorkspaceApi(workspaceId)).result);
  }, [workspaceId]);

  const deleteAttendee = useCallback(
    async (emailAddress, attendeeType) => {
      const updateSeriesParams = {
        attendeesUpdate: {
          type: 'REMOVE',
          userId: emailAddress,
        },
      };

      if (attendeeType) {
        updateSeriesParams.attendeesUpdate.attendeeType = attendeeType;
      } else {
        updateSeriesParams.attendeesUpdate.attendeeType =
          visibility === SeriesVisibility.RSVP ? 'RSVP' : 'REGULAR';
      }

      try {
        const result = await updateSeriesApi(
          meetingSeriesId,
          updateSeriesParams
        );
        localDispatch({ type: 'REFRESH_SERIES', series: result });
      } catch (error) {
        logerror({
          meetingSeriesId,
          ...fromError(error),
        });
      }
    },
    [localDispatch, meetingSeriesId, visibility]
  );

  const inviteUser = useCallback(async () => {
    // Check if the user is an existing attendee.

    const existingAttendee = attendees.find(
      (a) => a.emailAddress === newEmailAddress
    );
    const isExistingModerator =
      existingAttendee && isUserMeetingController(existingAttendee);

    if (existingAttendee && isExistingModerator) {
      setLastError('This user is already a moderator.');
      return;
    }

    if (existingAttendee && !isExistingModerator && !isEditingModerators) {
      setLastError('This user is already a guest.');
      return;
    }

    setIsLoading(true);
    if (existingAttendee && !isExistingModerator && isEditingModerators) {
      // Delete the attendee- they will be re-added as a moderator, potentially added
      // to the workspace, and sent an email.
      await deleteAttendee(newEmailAddress, 'RSVP');
      await deleteAttendee(newEmailAddress, 'REGULAR');
    }

    try {
      const roleToAdd = isEditingModerators ? Roles.MODERATOR : Roles.GUEST;
      const { series, error, status } = await inviteToSeries({
        emailAddress: newEmailAddress,
        meetingSeriesId,
        inviterUserName: userName,
        initialRoles: [roleToAdd],
        workspaceId: isEditingModerators ? workspaceId : null,
      });
      if (status === 200) {
        mixpanel.track('Series - Attendee Added', {
          ...getMeetingSeriesProps(series),
          attendee: newEmailAddress,
          role: roleToAdd,
        });
        localDispatch({ type: 'REFRESH_SERIES', series });
        setNewEmailAddress('');
      } else if (status === 500) {
        logerror({
          userId: newEmailAddress,
          meetingSeriesId,
          message: 'Error attempting  to invite attendee: ' + error,
        });
      }
      if (error) {
        setLastError(error);
      }
    } catch (error) {
      logerror({
        userId: newEmailAddress,
        meetingSeriesId,
        message:
          'Caught exception attempting to register user: ' + error.message,
        stacktrace: error.stack || error,
      });
      setLastError('Internal server error');
    }
    setIsLoading(false);
  }, [
    meetingSeriesId,
    userName,
    workspaceId,
    localDispatch,
    newEmailAddress,
    isEditingModerators,
    attendees,
    deleteAttendee,
  ]);

  const onConfirmAddToWorkspace = useCallback(async () => {
    setEmailToAddToWorkspace(null);
    await inviteUser();
    refreshWorkspace();
  }, [inviteUser, refreshWorkspace]);

  const onInviteUserSubmitted = useCallback(
    async (event) => {
      event.preventDefault();
      setLastError('');
      if (!newEmailAddress || !isEmailValid) return;
      if (!workspace && isEditingModerators) {
        logerror({
          meetingSeriesId,
          message: 'Cannot add moderator, workspace is not yet loaded.',
        });
        return;
      }

      if (
        isEditingModerators &&
        !workspace.members.find((w) => w.emailAddress === newEmailAddress)
      ) {
        setEmailToAddToWorkspace(newEmailAddress);
      } else {
        await inviteUser();
      }
    },
    [
      inviteUser,
      isEmailValid,
      newEmailAddress,
      isEditingModerators,
      workspace,
      meetingSeriesId,
    ]
  );
  const changeSeriesVisibility = async (visibility) => {
    const updatedSeries = Object.assign({}, series);
    updatedSeries.visibility = visibility;
    const result = await updateSeriesApi(meetingSeriesId, updatedSeries);
    localDispatch({ type: 'REFRESH_SERIES', series: result });
  };

  const updateSeriesScheduledEvent = async (scheduledEvent) => {
    const updatedSeries = Object.assign({}, series);
    updatedSeries.scheduledEvent = { ...scheduledEvent, registeredAttendees };
    updatedSeries.visibility = SeriesVisibility.RSVP;
    const result = await updateSeriesApi(meetingSeriesId, updatedSeries);
    localDispatch({ type: 'REFRESH_SERIES', series: result });
  };

  useEffect(() => {
    if (isEditingModerators) refreshWorkspace();
  }, [isEditingModerators, refreshWorkspace]);

  return {
    newEmailAddress,
    isEmailValid,
    showMeetingRoles,
    setShowMeetingRoles,
    emailToAddToWorkspace,
    setEmailToAddToWorkspace,
    lastError,
    isLoading,
    lockedRoles,
    filteredAttendees,
    addUserRole,
    removeUserRole,
    handleChangeEmailAddress,
    deleteAttendee,
    onConfirmAddToWorkspace,
    onInviteUserSubmitted,
    changeSeriesVisibility,
    RoleInformationModal,
    showMoreGuests,
    setShowMoreGuests,
    showMoreModerators,
    setShowMoreModerators,
    scheduledEvent,
    setScheduledEvent,
    updateSeriesScheduledEvent,
    convertedCurrentScheduledEvent,
    emailAddress,
    enabledFieldsRsvp,
  };
};
