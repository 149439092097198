import React from 'react';
import { ThemeProvider as StyledCompsThemeProvider } from 'styled-components';
import { lightTheme } from './lightTheme';

export const ThemeProvider = ({ children }) => {
  return (
    <StyledCompsThemeProvider theme={lightTheme}>
      {children}
    </StyledCompsThemeProvider>
  );
};
