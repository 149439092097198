import React from 'react';

export const PageIcon = ({
  width = '20px',
  height = '20px',
  className = 'fill-purple',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.667 3.33203H3.33366C2.41318 3.33203 1.66699 4.07822 1.66699 4.9987V14.9987C1.66699 15.9192 2.41318 16.6654 3.33366 16.6654H16.667C17.5875 16.6654 18.3337 15.9192 18.3337 14.9987V4.9987C18.3337 4.07822 17.5875 3.33203 16.667 3.33203ZM16.667 14.9987H3.33366V6.66536H16.667V14.9987ZM8.75033 13.332H5.41699C5.18687 13.332 5.00033 13.1455 5.00033 12.9154V8.7487C5.00033 8.51858 5.18687 8.33203 5.41699 8.33203H8.75033C8.98044 8.33203 9.16699 8.51858 9.16699 8.7487V12.9154C9.16699 13.1455 8.98044 13.332 8.75033 13.332ZM11.2503 9.9987H14.5837C14.8138 9.9987 15.0003 9.81215 15.0003 9.58203V8.7487C15.0003 8.51858 14.8138 8.33203 14.5837 8.33203H11.2503C11.0202 8.33203 10.8337 8.51858 10.8337 8.7487V9.58203C10.8337 9.81215 11.0202 9.9987 11.2503 9.9987ZM12.917 13.332H11.2503C11.0202 13.332 10.8337 13.1455 10.8337 12.9154V12.082C10.8337 11.8519 11.0202 11.6654 11.2503 11.6654H12.917C13.1471 11.6654 13.3337 11.8519 13.3337 12.082V12.9154C13.3337 13.1455 13.1471 13.332 12.917 13.332Z"
    />
  </svg>
);
