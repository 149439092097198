import React from 'react';

import {
  PreviousPage,
  pageTitles,
  Navbar,
  NavbarPlanInformation,
} from './NavbarComponents';
import { NavbarUserDropdownMenu } from './NavbarUserDropdownMenu';
import { usePortal } from '../../hooks/usePortal';
import { useWorkspacePlan } from '../../hooks/useWorkspacePlan';
import { Link } from 'react-router-dom';

export const MeetingNavbar = ({
  isAuthenticated,
  workspaceId,
  isTrial,
  plan,
}) => {
  const { workspacePlanValidUntil, currentSubscriptionRenewalTime } =
    usePortal();

  const { isAppSumo, isLoading, workspacePlanLabel } = useWorkspacePlan();

  return (
    <Navbar>
      <div className="md:min-w-[1000px] flex justify-between items-center">
        <PreviousPage currentPage={pageTitles.meeting} />
        <ul className="flex gap-5">
          {isLoading ? null : isAppSumo ? (
            <Link
              to={`/workspace/billing?workspaceId=${workspaceId}`}
              className="text-blue-dark font-medium flex justify-center items-center text-xs cursor-pointer underline decoration-blue-dark"
            >
              {workspacePlanLabel} - Lifetime access
            </Link>
          ) : (
            <NavbarPlanInformation
              workspaceId={workspaceId}
              isTrial={isTrial}
              plan={plan}
              trialUntil={workspacePlanValidUntil}
              workspacePlanValidUntil={workspacePlanValidUntil}
              currentSubscriptionRenewalTime={currentSubscriptionRenewalTime}
            />
          )}
          {isAuthenticated && (
            <li className="hidden md:block">
              <NavbarUserDropdownMenu pageTitle={pageTitles.meeting} />
            </li>
          )}
        </ul>
      </div>
    </Navbar>
  );
};
