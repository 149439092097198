const getMeetingSeriesProps = (meetingSeries) => {
  const { meetingSeriesId, visibility, workspace, autoLaunchConfig } =
    meetingSeries || {};
  const workspaceId = workspace?.workspaceId;
  const templateId = autoLaunchConfig?.templateId;
  return {
    meetingSeriesId,
    visibility,
    workspaceId,
    templateId,
  };
};

const getMeetingProps = (meeting, userId) => {
  let meetingStartTime;
  if (meeting?.state?.startTime) {
    meetingStartTime = new Date(meeting.state.startTime).toUTCString();
  }

  let meetingEndTime;
  if (meeting?.state?.endTime) {
    meetingEndTime = new Date(meeting.state.endTime).toUTCString();
  }

  let meetingDuration;
  if (meetingStartTime && meetingEndTime) {
    meetingDuration =
      (new Date(meeting.state.endTime).getTime() -
        new Date(meeting.state.startTime)) /
      1000;
  }

  let isModerator;
  if (userId && meeting?.state?.users) {
    const currentUser = meeting?.state?.users[userId];
    if (currentUser) isModerator = currentUser.roles.includes('moderator');
  }

  const users = Object.values(meeting?.state?.users || {});
  const currentUserCount = users.filter((u) => u.joined).length;
  const totalUserCount = users.length;

  return {
    meetingId: meeting?.meetingId,
    meetingSeriesId: meeting?.meetingSeriesId,
    isDemo: meeting && !!meeting?.isDemo,
    meetingStartTime,
    meetingEndTime,
    meetingDuration,
    isModerator,
    currentUserCount,
    totalUserCount,
  };
};

// Keep in sync with getMeetingProps.
const meetingPropNames = [
  'meetingId',
  'meetingSeriesId',
  'isDemo',
  'meetingStartTime',
  'meetingEndTime',
  'meetingDuration',
  'isModerator',
  'currentUserCount',
  'totalUserCount',
];

module.exports = {
  getMeetingSeriesProps,
  getMeetingProps,
  meetingPropNames,
};
