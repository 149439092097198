import React, { useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobile } from '../../helper';
import { isMeetingController } from '../../helper/roles';
import { updateSeriesApi } from '../../helper/api';
import { Button } from '../common/Button';

import SeriesTitle from './SeriesTitle';
import { PreviewInstructionsModal } from '../PreviewInstructionsModal';

const MeetingWindow = ({
  series,
  user,
  handleGoToMeeting,
  handleGoToSettingsTab,
  descriptionComponent,
  informationComponent,
  handleClickPreview,
  localDispatch,
  meetingSeriesId,
}) => {
  const {
    title,
    attendees,
    previewMeetingCompleted,
    autoLaunchConfig,
    upcomingMeeting,
  } = series;
  const { features = {} } = autoLaunchConfig;
  const { meetingPreviewButtonText = 'Test Meeting' } = features;
  const reviewButtonRef = useRef(null);
  const [previewInstructionsModalOpen, setPreviewInstructionsModalOpen] =
    useState(false);
  const { userPreferences } = user || {};
  const { showPreviewInstructions } = userPreferences || {};
  const { state } = upcomingMeeting || {};
  const { running } = state || {};

  const updatePreviewMeetingCompleted = async (previewMeetingCompleted) => {
    const updatedSeries = Object.assign({}, series);
    updatedSeries.previewMeetingCompleted = previewMeetingCompleted;
    const result = await updateSeriesApi(meetingSeriesId, updatedSeries);
    localDispatch({ type: 'REFRESH_SERIES', series: result });
  };

  const isCurrentUserMeetingController = useMemo(() => {
    const currentUser = attendees.find(
      (attendee) => attendee.emailAddress === user.userId
    );

    if (currentUser) {
      return isMeetingController(currentUser);
    } else {
      return false;
    }
  }, [attendees, user.userId]);

  const goToMeetingButtonText =
    isCurrentUserMeetingController && !running ? 'Start Event' : 'Go to Event';

  const buttonComponent = (
    <ButtonContainer>
      <Button
        onClick={handleGoToMeeting}
        color={
          !isCurrentUserMeetingController
            ? Button.colors.PURPLE_LITE
            : previewMeetingCompleted
            ? Button.colors.PURPLE
            : Button.colors.PURPLE_LITE
        }
      >
        <FontAwesomeIcon icon={['fas', 'external-link-alt']} size="lg" />{' '}
        {goToMeetingButtonText}
      </Button>
      {isCurrentUserMeetingController && (
        <>
          <Button
            ref={reviewButtonRef}
            onClick={() => {
              if (!previewMeetingCompleted) {
                updatePreviewMeetingCompleted(true);
              }
              if (showPreviewInstructions) {
                setPreviewInstructionsModalOpen(true);
              } else {
                handleClickPreview();
              }
            }}
            color={
              previewMeetingCompleted
                ? Button.colors.PURPLE_LITE
                : Button.colors.PURPLE
            }
          >
            <FontAwesomeIcon icon="play-circle" size="lg" />{' '}
            {meetingPreviewButtonText}
          </Button>
        </>
      )}
    </ButtonContainer>
  );

  return (
    <>
      <Container>
        {previewInstructionsModalOpen && (
          <PreviewInstructionsModal
            onCancel={() => setPreviewInstructionsModalOpen(false)}
            user={user}
            handleClickPreview={handleClickPreview}
            meetingPreviewButtonText={meetingPreviewButtonText}
          />
        )}
        <ContentContainer>
          <Column grow={85}>
            <SeriesTitle
              title={title}
              handleEdit={handleGoToSettingsTab}
              isEditable={isCurrentUserMeetingController}
            />
            {!isMobile && descriptionComponent}
            {!isMobile && buttonComponent}
          </Column>
          {!isMobile && <Column grow={15}>{informationComponent}</Column>}
        </ContentContainer>
        {isMobile && buttonComponent}
      </Container>
    </>
  );
};

const Container = styled.div`
  padding: ${({ theme }) => (isMobile ? theme.spacing(2) : theme.spacing(3))}px;
  padding-top: ${({ theme }) =>
    isMobile ? theme.spacing(2.5) : theme.spacing(3)}px;
  border-left: ${isMobile ? 5 : 15}px solid #8b33f7;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: ${({ theme }) => theme.spacing(4)}px;
  row-gap: ${({ theme }) => theme.spacing(2.5)}px; ;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing(isMobile ? 0.5 : 2.5)}px;
  flex: ${({ grow }) => grow || 1} 0 400px;
  width: min(400px, 100%);

  ${isMobile &&
  css`
    flex: ${({ grow }) => grow || 1} 0 100%;
    width: 100%;
  `};
`;

const ButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(isMobile ? 2.5 : 1)}px;

  ${isMobile &&
  css`
    > button {
      width: 100%;
    }
  `}

  ${!isMobile &&
  css`
    display: flex;
    gap: ${({ theme }) => theme.spacing(1.5)}px;

    > button {
      flex: 1 0 0;
      max-width: 50%;
    }
  `}
`;

export const urlLinkStyle = {
  color: '#8B33F7',
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: 1.5,
};

export default MeetingWindow;
