const ORIGIN = 'https://storage.googleapis.com';
const BUCKET_NAME = 'zync-media';
const MAIN_FOLDER = 'assets';
const SUB_FOLDER = 'static';

/** The purpose of this helper is to generate an GCP Storage link to our static resource bucket. The required parameter is a filename with extension, for example "emoji.svg".  */
export const getStaticAssetUrl = (assetNameWithExtension) =>
  `${[
    ORIGIN,
    BUCKET_NAME,
    MAIN_FOLDER,
    SUB_FOLDER,
    assetNameWithExtension,
  ].join('/')}`;
