import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';
import { absolutePosition, standardScreenSize } from '../../../helper/css';
import { LoadingSpinner } from '../../LoadingSpinner';
import { SlideContainerRefContext } from '../../Slide';
import { checkIsBlockInitialized } from '../MainContent';
import { enrichBlockListForAuthoring } from '../util';
import { AuthoringBlockRenderer } from '../../../blocks';
import { useBlockTemplates } from '../../../hooks/useBlockTemplates';
import { INVISIBLE_IMAGE_SRC } from '../../../helper/image';
import { overrideDynamicValue } from 'zync-common/helper/overrideDynamicValue';

/** Default dimensions for ScenePreview. */
export const defaultPreviewDimensions = {
  width: (80 * 16) / 9,
  height: 80,
};

/** Overall container for the scene preview. */
export const SlideImageContainer = styled.div`
  ${({ dimensions }) => absolutePosition(dimensions)}
  position: relative;
  overflow: hidden;
  box-sizing: content-box;
  box-shadow: 0 8px 28px -6px rgba(24, 39, 75, 0.12);
  border: 1px solid rgba(24, 39, 75, 0.08);
  border-radius: 6px;

  > * {
    pointer-events: none;
  }
`;

export const SlideImage = styled.img`
  display: block;
  height: 100%;
  width: 100%;

  object-fit: cover;
  box-sizing: border-box;
  margin: 0;
`;

/** A container for the authoring blocks, which will be sized and scaled. */
export const ScenePreviewBlocksContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
`;

/** A self-contained scene preview with custom dimensions. */
export const ScenePreview = ({
  /** The scene object or scene template object. */
  scene,

  /** Optional. The width of the preview area. Defaults to @see defaultPreviewDimensions */
  width = defaultPreviewDimensions.width,

  /** Optional. The height of the preview area. Defaults to @see defaultPreviewDimensions */
  height = defaultPreviewDimensions.height,

  /** Optional. The screen resolution. Defaults to @see standardScreenSize */
  screenSize = standardScreenSize,

  /** Style overrides. */
  style,

  /** determines whether authoring blocks, that are included in this scene, are rendered */
  hasBlocksVisible = true,

  brandKit,

  htmlId,

  users = [],
}) => {
  const previewDimensions = useMemo(() => {
    return { width, height };
  }, [width, height]); // assumes that width / height === 16 / 9

  const blockTemplates = useBlockTemplates();

  const sceneBlocks = scene.blocks || scene.slideConfig?.slideBlocks;

  const blocks = useMemo(
    () =>
      enrichBlockListForAuthoring(
        sceneBlocks,
        screenSize,
        previewDimensions,
        blockTemplates,
        brandKit
      ),
    [sceneBlocks, blockTemplates, previewDimensions, screenSize, brandKit]
  );

  const scale = previewDimensions.width / screenSize.width;

  const slideContainerRef = useRef(null);

  const backgroundColor = overrideDynamicValue(
    scene.slideBackgroundColor,
    brandKit
  );

  return (
    <SlideContainerRefContext.Provider value={slideContainerRef}>
      <SlideImageContainer
        dimensions={previewDimensions}
        style={style}
        id={htmlId}
      >
        {scene.slideBackgroundColor ? (
          <div style={{ backgroundColor, height: '100%' }} />
        ) : (
          <SlideImage
            draggable={false}
            src={
              scene.slideBackgroundColor
                ? INVISIBLE_IMAGE_SRC
                : scene.slideUrl || scene.settings?.slideUrl
            }
            onError={(e) => {
              e.target.src = `https://via.placeholder.com/${previewDimensions.width}x${previewDimensions.width}.png?text=No+Image`;
            }}
            style={{
              backgroundColor,
            }}
          />
        )}
        <ScenePreviewBlocksContainer
          ref={slideContainerRef}
          style={{
            ...screenSize,
            transform: `scale(${scale})`,
          }}
        >
          {/** Authoring Blocks. */}
          {hasBlocksVisible ? (
            blocks
              .filter(checkIsBlockInitialized)
              .map((block) => (
                <AuthoringBlockRenderer
                  key={`${block.blockInstanceId}-authoringblock`}
                  code={block.spec.code}
                  block={block}
                  users={users}
                  accentColor={brandKit?.accentColor}
                  brandKit={brandKit}
                />
              ))
          ) : (
            <div className="inset-0 absolute w-full h-full flex items-center justify-center kozak">
              <LoadingSpinner width="500px" thickness="30px" />
            </div>
          )}
        </ScenePreviewBlocksContainer>
      </SlideImageContainer>
    </SlideContainerRefContext.Provider>
  );
};
