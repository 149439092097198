import { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addUserViewedBlockApi } from '../helper/api';

/** Use a list of viewed blocks, handling updating the viewed block list on the user as necessary. */
export const useViewedBlocks = (blockId = null) => {
  const { viewedBlocks, user } = useSelector((st) => st.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user && blockId && !viewedBlocks.includes(blockId)) {
      // add the block to viewed blocks and update the user document in db.
      dispatch({ type: 'ADD_VIEWED_BLOCK', viewedBlock: blockId });
      addUserViewedBlockApi(user.userId, blockId);
    }
  }, [viewedBlocks, dispatch, blockId, user]);

  return viewedBlocks;
};

export const useShowUnviewedItems = (...itemIds) => {
  const { viewedBlocks, user } = useSelector((st) => st.auth);
  const dispatch = useDispatch();

  const [showItems, setShowItems] = useState(null);

  const handleReset = useCallback(
    (itemId, show = false) => {
      const itemIds = Array.isArray(itemId) ? itemId : [itemId];

      if (!show) {
        // remove the item from the map of unviewed items.
        let update = { ...showItems };
        itemIds.forEach((itemId) => {
          delete update[itemId];
        });
        setShowItems(update);
      } else if (show) {
        // add item to unviewed items.
        let update = { ...showItems };
        itemIds.forEach((itemId) => {
          update[itemId] = true;
        });
        setShowItems(update);
      }
    },
    [showItems, setShowItems]
  );

  const handleShow = useCallback(
    (itemId) => handleReset(itemId, true),
    [handleReset]
  );

  useEffect(() => {
    if (user && !showItems) {
      const unviewed = {};
      itemIds.forEach((itemId) => {
        if (!viewedBlocks.includes(itemId)) {
          // add the block to viewed blocks and update the user document in db.
          dispatch({ type: 'ADD_VIEWED_BLOCK', viewedBlock: itemId });
          addUserViewedBlockApi(user.userId, itemId);
          unviewed[itemId] = true;
        }
      });
      setShowItems(unviewed);
    }
  }, [user, viewedBlocks, setShowItems, dispatch, itemIds, showItems]);

  return [showItems ?? {}, handleReset, handleShow];
};
