import React, { useEffect, useReducer, useMemo, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';

import { isMobile } from '../../helper';
import { getStaticAssetUrl } from '../../helper/getStaticAssetUrl';
import {
  isRolesMeetingController,
  isUserMeetingController,
  Roles,
} from '../../helper/roles';
import { isAuthorized, SeriesVisibility } from '../../helper/visibility';
import { ModalBody, ModalWindow } from '../../components/Modal';
import { getMeetingListApi } from '../../helper/api';
import { AuthModal } from '../../components/SignOn';
import { Page404 } from '../page404';
import { useUserWorkspaces } from '../../hooks/useUserWorkspaces';
import { logwarn } from '../../helper/contextualLogger';
import { ZyncErrorModal } from '../../components/ZyncErrorModal';
import { MeetingInformation } from './MeetingInformation';
import { UpdateTabOnScroll } from './UpdateTabs';
import { HighlightsInformation } from './HighlightsInformation';
import { Scenes } from './Scenes';
import { MeetingAttendees } from './MeetingAttendees';
import { DeleteMeeting } from './DeleteMeeting';
import { RawConfig } from './RawConfig';
import { FuturisticBackground } from '../../components/FuturisticBackground';
import { MeetingNavbar } from '../Navbar/MeetingNavbar';
import { MeetingBox } from './MeetingBox';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { PencilIcon } from '../../components/icons/PencilIcon';
import { MeetingSettingsModal } from '../../components/series/MeetingSettingsModal';

const errorMeetingPermissionSrc = getStaticAssetUrl(
  'error-meeting-permission.svg'
);

const localReducer = (state, action) => {
  switch (action.type) {
    case 'REFRESH_SERIES':
      return {
        ...state,
        loading: false,
        series: action.series,
      };
    case 'REFRESH_MEETING':
      return {
        ...state,
        meeting: action.meeting,
      };
    case 'REFRESH_MEETING_LIST':
      return {
        ...state,
        meetingList: action.meetingList,
      };
    case 'MEETING_SERIES_DELETED':
      return {
        ...state,
        seriesDeleted: true,
      };
    case 'AUTH_MODE':
      return {
        ...state,
        authMode: action.authMode,
      };
    default:
      return {
        ...state,
      };
  }
};

export const getSeries = async (seriesId, localDispatch) => {
  const response = await fetch(`/api/meetingseries/${seriesId}`);
  const { result } = await response.json();
  localDispatch({ type: 'REFRESH_SERIES', series: result });
  if (!result) return;
  if (!result.upcomingMeeting) return;
  localDispatch({ type: 'REFRESH_MEETING', meeting: result.upcomingMeeting });
};

const Series = () => {
  const dispatch = useDispatch();
  const { meetingSeriesId: seriesId } = useParams();

  const [localState, localDispatch] = useReducer(localReducer, {
    loading: true,
  });

  const { series, meeting, meetingList, seriesDeleted, loading } = localState;

  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  const nonPreviewMeetingList = useMemo(
    () =>
      meetingList?.filter(
        (meeting) =>
          !meeting?.isPreview &&
          (meeting?.state?.completed || meeting?.state?.running)
      ),
    [meetingList]
  );

  const { switchWorkspace } = useUserWorkspaces();
  const { user, authenticated } = useSelector((st) => st.auth);
  const { userId, emailAddress } = user || {};
  const {
    meetingSeriesId,
    attendees,
    workspace,
    title: meetingTitle,
    description,
    meetingSeriesUrl,
    visibility,
    scheduledEvent,
  } = series || {};
  const { workspaceId } = workspace || {};
  const { meetingId, state } = meeting || {};
  const { users } = state || {};
  const { registeredAttendees } = scheduledEvent || {};

  const currentUser = (users || {})[userId];
  const userWorkspaces = useMemo(() => user?.workspaces || [], [user]);
  const { joined } = currentUser || {};
  const attendeeUser = (attendees || []).find(
    (u) => userId && u.emailAddress && u.emailAddress === userId
  );

  const guestAttendees = useMemo(
    () =>
      visibility === SeriesVisibility.RSVP ? registeredAttendees : attendees,
    [attendees, registeredAttendees, visibility]
  );

  const allowInviteGuests = visibility !== SeriesVisibility.RSVP;

  const roles = useMemo(() => {
    if (!attendeeUser) return [Roles.GUEST];
    if (attendeeUser.roles && attendeeUser.roles.length > 0)
      return attendeeUser.roles;
    if (attendeeUser.role) return [attendeeUser.role];
    return [Roles.GUEST];
  }, [attendeeUser]);

  const authorized = isAuthorized(user, series);
  const isMeetingController = isRolesMeetingController(roles);
  const isNotWorkspaceMemberOfSeries =
    workspaceId && !userWorkspaces.find((w) => w.workspaceId === workspaceId);

  const meetingInformationRef = useRef(null);
  const meetingBox1Ref = useRef(null);
  const meetingBox2Ref = useRef(null);
  const meetingBox3Ref = useRef(null);
  const meetingBox4Ref = useRef(null);
  const [boxToShow, setBoxToShow] = useState(0);
  const [isHighlightsLoading, setIsHightlightsLoading] = useState(true);

  useEffect(() => {
    if (!seriesId) return;
    // Wipe out any existing meeting state and start from a clean slate
    // This is applicable if the user just completed a meeting (either ended it or left it)
    dispatch({ type: 'CLEAR_MEETING_STATE', userId });
    getSeries(seriesId, localDispatch);
  }, [userId, seriesId, localDispatch, dispatch]);

  useEffect(() => {
    if (!meetingSeriesId) return;
    if (!authorized) return;
    const getMeetingList = async () => {
      const result = await getMeetingListApi(meetingSeriesId);
      if ((result || []).length > 0) {
        result.sort(
          (a, b) => (b.state || {}).endTime - (a.state || {}).endTime
        );
      }
      localDispatch({ type: 'REFRESH_MEETING_LIST', meetingList: result });
    };

    getMeetingList();
  }, [meetingSeriesId, joined, authorized]);

  /* Switch to the workspace of the current series. */
  useEffect(() => {
    if (workspaceId) {
      switchWorkspace(workspaceId, userWorkspaces, true);
    }
  }, [workspaceId, userWorkspaces, switchWorkspace]);

  /* Log a warning whenever a moderator has been removed from a series' workspace. */
  useEffect(() => {
    if (isMeetingController && isNotWorkspaceMemberOfSeries) {
      logwarn({
        userId,
        meetingSeriesId: seriesId,
        message: `User is a moderator of the series, but was removed from its workspace: ${workspaceId}`,
      });
    }
  }, [
    isNotWorkspaceMemberOfSeries,
    userId,
    isMeetingController,
    seriesId,
    workspaceId,
  ]);

  useEffect(() => {
    if (meetingList) {
      setIsHightlightsLoading(false);
    }
  }, [meetingList]);

  if (!meetingId || !meetingSeriesId) {
    return loading ? null : <Page404 />;
  }
  if (seriesDeleted) {
    return <Redirect to="/" />;
  }

  if (!authorized) {
    if (!authenticated) {
      return (
        <AuthModal authMode="signin" redirectToPortalPageAfterLogIn={false} />
      );
    }

    return (
      <RequestAccessModal
        emailAddress={emailAddress}
        meetingTitle={workspace?.name}
      />
    );
  }

  if (isMeetingController && isNotWorkspaceMemberOfSeries) {
    return <RequestWorkspaceAccessModal></RequestWorkspaceAccessModal>;
  }

  const currentAttendee = attendees.find(
    (attendee) => attendee.emailAddress === emailAddress
  );
  const hasModeratorPrivileges = isUserMeetingController(currentAttendee);

  const boxRefs = [
    meetingBox1Ref,
    meetingBox2Ref,
    meetingBox3Ref,
    meetingBox4Ref,
  ];

  const hasHighlights = meetingList?.length && meetingList[0]?.state?.startTime;

  if (isMobile || !user || !hasModeratorPrivileges) {
    return <Redirect to={`/e/${meetingSeriesId}/join`} />;
  }

  return (
    <>
      <div className="min-h-min">
        {user?.registered && <MeetingNavbar isAuthenticated={authenticated} />}
        <FuturisticBackground />
        <UpdateTabOnScroll
          mainBoxRef={meetingInformationRef}
          boxToShow={boxToShow}
          setBoxToShow={setBoxToShow}
          boxRefs={boxRefs}
        />
        <div className="flex justify-center w-full">
          <div className="w-11/12 max-w-7xl h-full flex flex-col justify-center items-center gap-y-16 mb-14 ">
            {/* MeetingInformation Component */}
            <MeetingInformation
              ref={meetingInformationRef}
              meetingTitle={meetingTitle}
              description={description}
              meetingSeriesUrl={meetingSeriesUrl}
              visibility={visibility}
              series={series}
              meetingSeriesId={meetingSeriesId}
              localDispatch={localDispatch}
              boxToShow={boxToShow}
              setBoxToShow={setBoxToShow}
              boxRefs={boxRefs}
              meetingList={meetingList}
              workspaceId={workspaceId}
              mainBoxRef={meetingInformationRef}
              hasHighlights={hasHighlights}
              showModeratorData={hasModeratorPrivileges}
            />
            {hasModeratorPrivileges && (
              <>
                {/* Highlights Component */}
                {isHighlightsLoading ? (
                  <MeetingBox
                    title="Highlights"
                    description="Analyze the key information documented from your past events"
                    buttonText="See Highlights"
                  >
                    <div className="h-32 flex justify-center">
                      <LoadingSpinner />
                    </div>
                  </MeetingBox>
                ) : (
                  hasHighlights && (
                    <div ref={meetingBox1Ref} className="w-full">
                      <HighlightsInformation
                        totalMeetings={nonPreviewMeetingList?.length}
                        seriesCreationDate={series?.creationTime}
                        lastMeetingDate={
                          meetingList?.length
                            ? meetingList[0].state.endTime
                            : ''
                        }
                        meetingSeriesId={meetingSeriesId}
                        meetingList={meetingList}
                      />
                    </div>
                  )
                )}

                {/* Scenes Component */}
                <div className="w-full hidden md:block" ref={meetingBox2Ref}>
                  <Scenes
                    series={series}
                    meetingSeriesId={meetingSeriesId}
                    isMeetingController={isMeetingController}
                  />
                </div>
                {/* Moderators Component */}
                <div className="w-full" ref={meetingBox3Ref}>
                  <MeetingAttendees
                    attendees={attendees}
                    localDispatch={localDispatch}
                    series={series}
                    meetingSeriesId={meetingSeriesId}
                    workspaceId={workspaceId}
                  />
                </div>
                {/* Guests Component */}
                <div className="w-full" ref={meetingBox4Ref}>
                  <MeetingAttendees
                    title="Guests"
                    description="Choose who can attend your event"
                    attendees={guestAttendees}
                    localDispatch={localDispatch}
                    series={series}
                    meetingSeriesId={meetingSeriesId}
                    workspaceId={workspaceId}
                    mode="guest"
                    allowInvite={allowInviteGuests}
                  />
                </div>
                <MeetingBox
                  title="Settings"
                  description="Click here to edit settings of the event"
                  buttonText={
                    <span className="flex gap-3 items-center justify-center text-base px-8">
                      <PencilIcon className="fill-white" /> Edit
                    </span>
                  }
                  onClick={() => setIsSettingsModalOpen(true)}
                />
                {/* Delete Event Component */}
                <DeleteMeeting
                  meetingSeriesId={meetingSeriesId}
                  series={series}
                  localDispatch={localDispatch}
                />
                <RawConfig
                  series={series}
                  localDispatch={localDispatch}
                  user={user}
                />
              </>
            )}
          </div>
        </div>
      </div>
      {isSettingsModalOpen && (
        <MeetingSettingsModal
          handleClose={() => setIsSettingsModalOpen(false)}
          series={series}
          localDispatch={localDispatch}
          areEventPosterSettingsEnabled={true}
          areEventPageSettingsEnabled={true}
          areEventTopicsSettingsEnabled={true}
          areRSVPSettingsEnabled={true}
          areOtherSettingsEnabled={true}
        />
      )}
    </>
  );
};

export const RequestAccessModal = ({ meetingTitle }) => (
  <ZyncErrorModal
    title=""
    message={`You do not have adequate permission to access ${meetingTitle}`}
    imgSrc={errorMeetingPermissionSrc}
  />
);

const RequestWorkspaceAccessModal = () => {
  return (
    <ModalWindow showCancel={false}>
      <ModalBody>
        You're not a member of the workspace so you cannot be a moderator and
        collaborate on this meeting. Please ask the workspace admin to add you
        to the workspace.
      </ModalBody>
    </ModalWindow>
  );
};

export default Series;
