import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CALL_STATE_CHANGE,
  CLEANUP,
  DAILY_CALL_OBJECT_DESTROYED,
} from '../reducers/callState';
import {
  CALL_STATE_ERROR,
  CALL_STATE_JOINED,
  CALL_STATE_JOINING,
  BACKGROUND_TYPE_NONE,
  BACKGROUND_TYPE_BLUR,
} from './dailyConstants';
import { loginfo, logwarn } from '../helper/contextualLogger';

const JOINED_MEETING_STATES = ['joining-meeting', 'joined-meeting'];

let localMicUpdatingInterval = null;

export const useDailyControls = ({
  subscribeToTracksAutomatically = false,
} = {}) => {
  const {
    daily,
    state,
    tracks,
    inputSettings: currentInputSettings,
  } = useSelector((state) => state.callState);
  const screenShareActive = !!tracks.mainScreenShare;
  const isLocalUserScreenSharing = !!tracks.mainScreenShare?.local;
  const backgroundType =
    currentInputSettings?.video?.processor?.type || BACKGROUND_TYPE_NONE;
  const error = useSelector(
    (state) => state.callState.fatalError || state.callState.camOrMicError
  );
  const localTrackState = useSelector((state) => state.callState.tracks.local);
  const { cameraOn: localCameraOn = false, micOn: localMicOn = false } =
    localTrackState || {};

  const localMicAvailable =
    localTrackState?.audioTrackState?.state !== 'blocked';
  const localCameraAvailable =
    localTrackState?.videoTrackState?.state !== 'blocked';

  const dispatch = useDispatch();

  const [isLocalCameraUpdating, setIsLocalCameraUpdating] = useState(false);
  const [isLocalMicUpdating, setIsLocalMicUpdating] = useState(false);

  useEffect(() => {
    /* we want to finish the local camera toggle action, and we know that when localCameraOn changes */
    setIsLocalCameraUpdating(false);
  }, [localCameraOn]);

  useEffect(() => {
    /* we want to finish the local microphone toggle action, and we know that when localMicOn changes */
    setIsLocalMicUpdating(false);
  }, [localMicOn]);

  useEffect(() => {
    if (isLocalMicUpdating) {
      localMicUpdatingInterval = setInterval(() => {
        setIsLocalMicUpdating(false);
        localMicUpdatingInterval = null;
      }, 5_000);
    } else {
      clearInterval(localMicUpdatingInterval);
      localMicUpdatingInterval = null;
    }

    return () => {
      if (localMicUpdatingInterval) {
        clearInterval(localMicUpdatingInterval);
        localMicUpdatingInterval = null;
      }
    };
  }, [isLocalMicUpdating]);

  // --- Callbacks ---
  /**
   * Joins call (with the token, if applicable)
   */
  const join = useCallback(
    async (userId) => {
      if (!daily || daily.isDestroyed()) {
        return;
      }

      if (JOINED_MEETING_STATES.includes(daily.meetingState())) return;
      dispatch({ type: CALL_STATE_CHANGE, newState: CALL_STATE_JOINING });
      loginfo({
        message: `Joining daily meeting with userName: ${userId}`,
        userId,
      });
      await daily.join({
        subscribeToTracksAutomatically,
        userName: userId,
      });
      dispatch({ type: CALL_STATE_CHANGE, newState: CALL_STATE_JOINED });
    },
    [subscribeToTracksAutomatically, daily, dispatch]
  );

  const userId = useSelector((_st) => _st.auth?.user?.userId);

  /**
   * Leave call
   */
  const leave = useCallback(async () => {
    if (!daily || daily.isDestroyed()) {
      return;
    }

    // If we're in the error state, we've already "left", so just clean up
    if (state === CALL_STATE_ERROR) {
      // Never call daily.destroy() directly without ensuring that it is removed from redux.

      if (!daily.isDestroyed()) {
        await daily.destroy();
      }

      dispatch({ type: DAILY_CALL_OBJECT_DESTROYED, userId });
    } else {
      if (!daily.isDestroyed()) {
        await daily.leave();
      }
    }
  }, [daily, dispatch, state, userId]);

  /**
   * Destroy the daily call object
   *
   * It is important that this method is always called instead of daily.destroy directly.
   * Otherwise, a new daily call object will not be created if the user joins another meeting.
   */
  const destroy = useCallback(async () => {
    if (!daily || daily.isDestroyed()) {
      logwarn({
        message:
          'Cannot destroy call object- no reference to daily or daily is destroyed.',
      });
      return;
    }

    await leave();

    if (daily && !daily.isDestroyed()) {
      await daily.destroy();
    }

    dispatch({ type: DAILY_CALL_OBJECT_DESTROYED, userId });
    dispatch({
      type: CLEANUP,
    });
  }, [daily, leave, dispatch, userId]);

  /**
   * Toggle mic
   */
  const toggleMic = useCallback(
    async (newValue) => {
      if (!daily || daily.isDestroyed()) {
        return;
      }

      if (!localMicOn) {
        setIsLocalMicUpdating(true);
      }

      if (newValue !== false && newValue !== true) {
        newValue = !daily.localAudio();
      }

      daily.setLocalAudio(newValue);
    },
    [daily, localMicOn]
  );

  /**
   * Toggle camera
   */
  const toggleCamera = useCallback(() => {
    if (!daily || daily.isDestroyed()) {
      return;
    }

    if (!localCameraOn) {
      setIsLocalCameraUpdating(true);
    }
    daily.setLocalVideo(!daily.localVideo());
  }, [localCameraOn, daily]);

  /**
   * Turn on background blur
   */
  const setBackgroundBlur = useCallback(() => {
    if (!daily || daily.isDestroyed()) {
      return;
    }

    const inputSettings = {
      video: {
        processor: {
          type: BACKGROUND_TYPE_BLUR,
          config: { strength: 0.9 },
        },
      },
    };
    dispatch({ type: 'UPDATE_INPUT_SETTINGS', inputSettings });
    daily.updateInputSettings(inputSettings);
  }, [daily, dispatch]);

  /**
   * Turn off background options
   */
  const setBackgroundNone = useCallback(() => {
    if (!daily || daily.isDestroyed()) {
      return;
    }

    const inputSettings = {
      video: {
        processor: {
          type: BACKGROUND_TYPE_NONE,
        },
      },
    };
    dispatch({ type: 'UPDATE_INPUT_SETTINGS', inputSettings });
    daily.updateInputSettings(inputSettings);
  }, [daily, dispatch]);

  /**
   * Start screen share
   */
  const startScreenShare = useCallback(() => {
    if (!daily || daily.isDestroyed()) {
      return;
    }

    daily.startScreenShare({
      screenVideoSendSettings: 'detail-optimized',
    });
  }, [daily]);

  /**
   * Stop screen share
   */
  const stopScreenShare = useCallback(() => {
    if (!daily || daily.isDestroyed()) {
      return;
    }

    daily.stopScreenShare();
  }, [daily]);

  const startVoiceTranscription = useCallback(async () => {
    if (!daily || daily.isDestroyed()) {
      return;
    }

    await daily.startTranscription();
  }, [daily]);

  const updateLocalParticipantToPublisher = useCallback(() => {
    if (!daily || daily.isDestroyed()) {
      return;
    }

    daily.setLocalVideo(true);
    daily.setLocalAudio(true);
  }, [daily]);

  return {
    state,
    daily,
    destroy,
    join,
    leave,
    toggleMic,
    toggleCamera,
    setBackgroundBlur,
    setBackgroundNone,
    startScreenShare,
    stopScreenShare,
    startVoiceTranscription,
    updateLocalParticipantToPublisher,
    backgroundType,
    localCameraOn,
    localMicOn,
    localCameraAvailable,
    localMicAvailable,
    screenShareActive,
    isLocalUserScreenSharing,
    isLocalCameraUpdating,
    isLocalMicUpdating,
    error,
    localTrackState,
  };
};
