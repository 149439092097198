import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { PLANS } from 'zync-common/zyncCustomerPlans';
import { getStaticAssetUrl } from '../../../../../helper/getStaticAssetUrl';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const UnlockPotential = React.memo(() => (
  <p className="text-blue-gray font-normal">
    Unlock the true potential of Zync by upgrading to one of our premium plans.
  </p>
));

const ContactYourAdmin = React.memo(({ billingAdminEmail }) => (
  <p className="text-blue-gray font-normal">
    Contact <span className="text-purple">{billingAdminEmail}</span> to upgrade.
  </p>
));

const UpgradeFreePlan = React.memo(({ planName, maxAttendees }) => (
  <p className="text-blue-dark font-semibold mb-0.5">
    Your Zync <span className="text-purple">{planName} plan</span> only supports{' '}
    {maxAttendees} users. One or more users are unable to join the meeting.
  </p>
));

const FeatureNotSupported = React.memo(({ planName, featureName }) => (
  <p className="text-blue-dark font-semibold mb-0.5">
    Your current <span className="text-purple">{planName} plan</span> does not
    support {featureName}
  </p>
));

const MeetingEndsIn = React.memo(
  ({
    /* Time to end to be displayed. Provide in format mm:ss */
    timeToEnd,

    /* Whether to run time to end countdown ticking each second */
    showDynamicCountdown,
  }) => {
    const [timeToEndDisplayed, setTimeToEndDisplayed] = useState(timeToEnd);

    useEffect(() => {
      const runCountdown = () => {
        const interval = setInterval(() => {
          setTimeToEndDisplayed((prevTimeToEndDisplayed) => {
            const timeParsed = moment(prevTimeToEndDisplayed, 'mm:ss');
            const seconds = timeParsed.seconds();
            const minutes = timeParsed.minutes();

            /* if there are minutes or seconds left, we count till 00:00 */
            const shouldCount = Boolean(seconds || minutes);

            if (shouldCount) {
              return timeParsed.subtract(1, 'seconds').format('mm:ss');
            }

            clearInterval(interval);

            return prevTimeToEndDisplayed;
          });
        }, 1000);

        return () => {
          clearInterval(interval);
        };
      };

      if (showDynamicCountdown) {
        runCountdown();
      }
    }, [showDynamicCountdown]);

    return (
      <span className="flex text-blue-dark font-semibold mb-0.5 justify-center items-center gap-2.5">
        <img
          width={24}
          height={24}
          src={getStaticAssetUrl('alert-triangle.svg')}
          alt=""
        />{' '}
        Your studio session will end in {timeToEndDisplayed}.
      </span>
    );
  }
);

const YourMeetingIsEnding = React.memo(
  ({ planName, maxMinutes, timeToEnd, showDynamicCountdown, workspaceId }) => (
    <div className="flex justify-between mb-0.5 w-full">
      <MeetingEndsIn
        timeToEnd={timeToEnd}
        showDynamicCountdown={showDynamicCountdown}
      />
      {planName === PLANS.free.name && (
        <Link
          to={`/workspace/billing?workspaceId=${workspaceId}`}
          target="_blank"
          rel="noreferrer"
        >
          <div className="py-2 px-4 bg-[#FF8B28] rounded flex justify-center items-center">
            <div className="flex gap-1.5 items-center">
              <img
                width={24}
                height={24}
                src={getStaticAssetUrl('chargingIcon.png')}
                alt=""
              />
              <span className="text-sm">Upgrade</span>
            </div>
          </div>
        </Link>
      )}
    </div>
  )
);

const MessagePartials = {
  UnlockPotential,
  ContactYourAdmin,
  UpgradeFreePlan,
  FeatureNotSupported,
  MeetingEndsIn,
  YourMeetingIsEnding,
};

export default MessagePartials;
