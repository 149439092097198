import { useEffect, useRef, useState } from 'react';
import { fromError, logerror, loginfo } from '../helper/contextualLogger';
import { useSelector } from 'react-redux';
import { useAreLocalTracksReady } from './useAreLocalTracksReady';

export const useUserMediaPermissions = (
  setHasAnsweredPermissionsPrompt,
  subscriberOnly = false
) => {
  const userId = useSelector((_st) => _st.auth?.user?.userId);
  const meetingId = useSelector((state) => state.meetingState?.meetingId);

  const isCheckingPermissionsTimer = useRef(null);
  const [isCheckingPermissions, setIsCheckingPermissions] = useState(true);
  const [areDevicePermissionsDenied, setAreDevicePermissionsDenied] =
    useState(false);
  const [areDevicesNotFound, setAreDevicesNotFound] = useState(false);
  const [areDevicesUsedElsewhere, setAreDevicesUsedElsewhere] = useState(false);
  const [didOtherErrorHappen, setDidOtherErrorHappen] = useState(false);

  const {
    isLocalAudioTrackReady,
    isLocalVideoTrackReady,
    allLocalTracksReady,
  } = useAreLocalTracksReady();

  const [allLocalTracksReadyErrored, setAllLocalTracksReadyErrored] =
    useState(false);

  useEffect(() => {
    isCheckingPermissionsTimer.current = setTimeout(() => {
      setIsCheckingPermissions(false);
    }, 3000);

    return () => clearTimeout(isCheckingPermissionsTimer.current);
  }, []);

  useEffect(() => {
    const checkDevicePermissions = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
        if (setHasAnsweredPermissionsPrompt) {
          setHasAnsweredPermissionsPrompt(true);
        }
      } catch (error) {
        if (setHasAnsweredPermissionsPrompt) {
          setHasAnsweredPermissionsPrompt(true);
        }
        if (
          error.name === 'NotFoundError' ||
          error.name === 'DevicesNotFoundError'
        ) {
          //required track is missing
          logerror({
            ...fromError(error),
            message: `Either camera or microphone of ${userId} was not found`,
            userId,
            meetingId,
          });

          setAreDevicesNotFound(true);
        } else if (
          error.name === 'NotReadableError' ||
          error.name === 'TrackStartError'
        ) {
          //webcam or mic are already in use
          logerror({
            ...fromError(error),
            message: `Webcam or mic are already in use for ${userId}`,
            userId,
            meetingId,
          });

          setAreDevicesUsedElsewhere(true);
        } else if (
          error.name === 'NotAllowedError' ||
          error.name === 'PermissionDeniedError'
        ) {
          //permission denied in browser
          loginfo({
            message: `User ${userId} did not grant device (media) permissions.`,
            userId,
            meetingId,
          });

          setAreDevicePermissionsDenied(true);
        } else {
          logerror({
            ...fromError(error),
            userId,
            meetingId,
          });

          setDidOtherErrorHappen(true);
        }
      }
    };
    if (subscriberOnly) return;
    checkDevicePermissions();
  }, [meetingId, setHasAnsweredPermissionsPrompt, subscriberOnly, userId]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAllLocalTracksReadyErrored(true);
    }, 10_000);

    if (isLocalAudioTrackReady || isLocalVideoTrackReady) {
      clearTimeout(timeout);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isLocalAudioTrackReady, isLocalVideoTrackReady]);

  useEffect(() => {
    if (allLocalTracksReady) {
      setAreDevicesUsedElsewhere(false);
    }
  }, [allLocalTracksReady]);

  return {
    areDevicePermissionsDenied,
    areDevicesUsedElsewhere:
      allLocalTracksReadyErrored && areDevicesUsedElsewhere,
    areDevicesNotFound,
    didOtherErrorHappen,
    isCheckingPermissions,
  };
};
