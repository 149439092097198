import { ConfirmationModal, Modal } from './Modal';
import { Button } from './common/Button';
import React from 'react';

const MissingMicrophoneText = () => (
  <div className="flex flex-col gap-5 -mt-4">
    <span>
      Unfortunately, we can’t access your microphone stream. We highly recommend
      using a microphone to ensure the best Zync event experience. Please verify
      that your microphone is connected and functioning properly. You can use
      this tool here at{' '}
      <a
        className="text-blue-light hover:underline"
        href="https://webcammictest.com/"
        target="_blank"
        rel="noreferrer"
      >
        Webcam test
      </a>{' '}
      to test your device. You can try again by clicking "Reload" once you have
      addressed any issues with your devices.
    </span>
  </div>
);

const MissingCameraText = () => (
  <div className="flex flex-col gap-5 -mt-4">
    <span>
      Unfortunately, we can’t access your camera stream. We highly recommend
      using a camera to ensure the best Zync event experience. Please verify
      that your camera is connected and functioning properly. You can use this
      tool here at{' '}
      <a
        className="text-blue-light hover:underline"
        href="https://webcammictest.com/"
        target="_blank"
        rel="noreferrer"
      >
        Webcam test
      </a>{' '}
      to test your device. You can try again by clicking "Reload" once you have
      addressed any issues with your device.
    </span>
  </div>
);

const MissingAllText = () => (
  <div className="flex flex-col gap-5 -mt-4">
    <span>
      We are unable to access to your camera and mic stream. Please verify that
      your camera and mic are connected and functioning properly. You can use
      this tool here at{' '}
      <a
        className="text-blue-light hover:underline"
        href="https://webcammictest.com/"
        target="_blank"
        rel="noreferrer"
      >
        Webcam test
      </a>{' '}
      to test your devices. You can try again by clicking "Reload" once you have
      addressed any issues with your devices.
    </span>
  </div>
);

const TextDisplayed = ({ isCameraReady, isMicReady }) => {
  switch (true) {
    case isCameraReady && !isMicReady: {
      return <MissingMicrophoneText />;
    }
    case isMicReady && !isCameraReady: {
      return <MissingCameraText />;
    }
    default: {
      return <MissingAllText />;
    }
  }
};

const UseSettingsText = () => {
  return (
    <p className="mb-8 -mt-4">
      You can switch your device by clicking the Settings button on this page.
    </p>
  );
};

const TitleDisplayed = ({ isCameraReady, isMicReady }) => {
  switch (true) {
    case isCameraReady && !isMicReady: {
      return 'We are unable to access your microphone stream';
    }
    case isMicReady && !isCameraReady: {
      return 'We are unable to access your camera stream';
    }
    default: {
      return 'We are unable to access your camera and microphone stream';
    }
  }
};

export const UnableToUseDevicesModal = ({
  onDismiss,
  isCameraReady,
  isMicReady,
  isLiveMeeting,
}) => {
  return (
    <ConfirmationModal
      title={
        <TitleDisplayed isCameraReady={isCameraReady} isMicReady={isMicReady} />
      }
      text={
        <>
          {isLiveMeeting && <UseSettingsText />}
          <TextDisplayed
            isCameraReady={isCameraReady}
            isMicReady={isMicReady}
          />
        </>
      }
      size={Modal.size.md}
      actionButtons={
        <div>
          <Button
            size={Button.sizes.FULL}
            color={Button.colors.PURPLE}
            onClick={onDismiss}
          >
            <span className="text-base font-bold">Dismiss</span>
          </Button>
        </div>
      }
      width={512}
      height={330}
      shrinkOnResize
    />
  );
};
