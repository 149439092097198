import React from 'react';

export const GiftPresentIcon = ({
  width = '19px',
  height = '19px',
  className = 'fill-purple ',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6125 5.5H16.0725C16.4251 4.89232 16.6113 4.20252 16.6125 3.5C16.6125 1.84315 15.2694 0.5 13.6125 0.5C11.9898 0.698628 10.5318 1.58803 9.61255 2.94C8.69334 1.58803 7.2353 0.698628 5.61255 0.5C3.95569 0.5 2.61255 1.84315 2.61255 3.5C2.6138 4.20252 2.80004 4.89232 3.15255 5.5H2.61255C1.50798 5.5 0.612549 6.39543 0.612549 7.5V10.5C0.612549 11.0523 1.06026 11.5 1.61255 11.5H2.61255V16.5C2.61255 17.6046 3.50798 18.5 4.61255 18.5H14.6125C15.7171 18.5 16.6125 17.6046 16.6125 16.5V11.5H17.6125C18.1648 11.5 18.6125 11.0523 18.6125 10.5V7.5C18.6125 6.39543 17.7171 5.5 16.6125 5.5ZM13.6125 2.5C14.1648 2.5 14.6125 2.94772 14.6125 3.5C14.6136 4.16525 14.2837 4.78746 13.7325 5.16C13.4015 5.38249 13.0114 5.50089 12.6125 5.5H10.6125C10.6125 4.41 12.5225 2.5 13.6125 2.5ZM5.61255 2.5C5.06026 2.5 4.61255 2.94772 4.61255 3.5C4.61255 4.60457 5.50798 5.5 6.61255 5.5H8.61255C8.61255 4.41 6.70255 2.5 5.61255 2.5ZM8.61255 7.5V9.5H2.61255V7.5H8.61255ZM8.61255 16.5V11.5H4.61255V16.5H8.61255ZM10.6125 16.5V11.5H14.6125V16.5H10.6125ZM10.6125 7.5V9.5H16.6125V7.5H10.6125Z"
      />
    </svg>
  );
};
