import { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createDailyMeetingRoom,
  createMeetingToken,
  sendEvent,
} from '../helper/api';
import DailyIframe from '@daily-co/daily-js';
import {
  ACTIVE_SPEAKER_CHANGE,
  CALL_STATE_CHANGE,
  CAM_OR_MIC_ERROR,
  CLEANUP,
  CLICK_ALLOW_TIMEOUT,
  DAILY_CALL_OBJECT_CREATED,
  FATAL_ERROR,
  NONFATAL_ERROR,
  PARTICIPANTS_CHANGE,
  SUBSCRIPTIONS_UPDATED,
} from '../reducers/callState';
import {
  CALL_STATE_ENDED,
  CALL_STATE_ERROR,
  CALL_STATE_EXPIRED,
  CALL_STATE_FULL,
  CALL_STATE_INITIALIZING,
  CALL_STATE_JOINED,
  CALL_STATE_JOINING,
  CALL_STATE_LOBBY,
  CALL_STATE_NOT_ALLOWED,
  CALL_STATE_NOT_BEFORE,
  CALL_STATE_NOT_FOUND,
  CALL_STATE_READY,
  CALL_STATE_REDIRECTING,
  CALL_STATE_REMOVED,
  CALL_STATE_UNLOADED,
  simulcastLayers,
} from './dailyConstants';
import { useDailyControls } from './useDailyControls';
import {
  logerror,
  fromError,
  loginfo,
  logwarn,
} from '../helper/contextualLogger';
import { useMuteUser } from './useMuteUser';
import { fromZyncBottomsup } from '../helper/constants';
import {
  onLocalRecordingData,
  startLocalRecording,
} from '../helper/localRecordingStream';
import { isEqual } from 'lodash/fp';
import { isBrave, isChrome } from '../helper';

const DEBUG_SUBSCRIPTIONS = false;

const logParticipantUpdated = (event) => {
  const {
    participant: { audio, video, user_name, tracks },
  } = event;

  loginfo({
    message: `Participant ${user_name} changed state. Audio ${
      tracks.audio.track?.label
    }: ${audio} - ${tracks.audio.state || ''} Video ${
      tracks.video.track?.label || ''
    }: ${video} - ${tracks.video.state}`,
  });
};

export const useDailyMeeting = ({
  userId,
  meetingId,
  onActiveSpeakerChange,
  onParticipantsChange,
  onScreenShareError,
  fakeUsers = [],
  subscriberOnly = false,
  isMeetingController,
  meetingLeaderUserId,
  retryPrepareDaily,
  retryPrepareDailyIntervalRef,
  startAudioOffOverride,
  useImprovedVideoQuality,
} = {}) => {
  const {
    daily,
    state,
    pendingMute,
    pendingSubscriptions,
    audioSubscriptions,
    videoSubscriptions,
    pendingUnmute,
    tracks,
    tracks: { allUsers: userIdToTrackStates },
  } = useSelector((state) => state.callState);

  const localVideoTrack = tracks?.local?.videoTrackState?.track;
  const localVideoTrackLabel = tracks?.local?.videoTrackState?.track?.label;

  useEffect(() => {
    const applyConstraints = async () => {
      if (!useImprovedVideoQuality) {
        return;
      }

      if (!isChrome && !isBrave) {
        return;
      }

      if (localVideoTrack) {
        const { height, width } = localVideoTrack.getCapabilities();

        const { width: currentWidth, height: currentHeight } =
          localVideoTrack.getSettings();

        const maxWidth = Math.min(1920, width.max);
        const maxHeight = Math.min(1080, height.max);

        if (currentWidth === maxWidth || currentHeight === maxHeight) {
          return;
        }

        await localVideoTrack.applyConstraints({
          width: maxWidth,
          height: maxHeight,
          advanced: [{ aspectRatio: 16 / 9 }, { aspectRatio: 16 / 10 }],
          frameRate: { ideal: 30, max: 60 },
        });

        const { height: updatedHeight, width: updateWidth } =
          localVideoTrack.getSettings();

        if (
          (updateWidth !== maxWidth && updatedHeight !== maxHeight) ||
          isEqual(
            { height: currentHeight, width: currentWidth },
            { height: updatedHeight, width: updateWidth }
          )
        ) {
          setTimeout(applyConstraints, 5_000);
        }
      }
    };

    applyConstraints();
  }, [localVideoTrack, localVideoTrackLabel, useImprovedVideoQuality]);

  const { startAudioOff, startVideoOff } = useSelector(
    (state) => state.clientDetails
  );

  const [redirectOnLeave, setRedirectOnLeave] = useState(false);
  const dispatch = useDispatch();

  const {
    toggleMic,
    destroy,
    leave,
    localCameraOn,
    localMicOn,
    startVoiceTranscription,
  } = useDailyControls();
  const { handleMuteUser } = useMuteUser();

  // Create fake participants based on the data from fake users.
  const fakeParticipants = useMemo(() => {
    const fp = {};
    for (const fakeUser of fakeUsers) {
      const sessionId = `${fakeUser.userId}-session`;
      fp[sessionId] = {
        user_name: fakeUser.userId,
        session_id: sessionId,
        tracks: {
          video: {
            state: 'playable',
            track: fakeUser.fakeVideoStream,
          },
          screenVideo: {},
          screenAudio: {},
        },
        video: true,
        audio: false,
        local: false,
        joined_at: new Date(),
        isFake: true,
      };
    }
    return fp;
  }, [fakeUsers]);

  // --- Effects ---

  /**
   * Remember user's settings in case of network disconnect since daily object gets destroyed.
   */
  useEffect(() => {
    if (!daily || (state !== CALL_STATE_READY && state !== CALL_STATE_JOINED))
      return;
    if (daily.isDestroyed()) return;
    dispatch({ type: 'SET_START_VIDEO', startVideoOff: !localCameraOn });
  }, [daily, localCameraOn, dispatch, state]);

  useEffect(() => {
    if (!daily || (state !== CALL_STATE_READY && state !== CALL_STATE_JOINED))
      return;
    if (daily.isDestroyed()) return;
    dispatch({
      type: 'SET_START_AUDIO',
      startAudioOff: !localMicOn,
    });
  }, [daily, localMicOn, dispatch, state]);

  /**
   * Instantiate the call object
   */
  useEffect(() => {
    if (
      daily ||
      state !== CALL_STATE_UNLOADED ||
      startAudioOffOverride === undefined
    )
      return;
    const createDailyCallObject = async () => {
      const properties = {
        dailyConfig: {
          experimentalChromeVideoMuteLightOff: true,
          useDevicePreferenceCookies: true,
        },
        startAudioOff: !!startAudioOff,
        startVideoOff: !!startVideoOff,
      };

      if (subscriberOnly) {
        properties.audioSource = false;
        properties.videoSource = false;
      }

      let co = null;
      try {
        co = DailyIframe.createCallObject(properties);
      } catch (error) {
        logerror({
          ...fromError(error),
          userId,
        });
        return;
      }
      dispatch({ type: DAILY_CALL_OBJECT_CREATED, callObject: co, userId });
    };
    createDailyCallObject();
  }, [
    daily,
    state,
    dispatch,
    subscriberOnly,
    userId,
    startAudioOff,
    startVideoOff,
    startAudioOffOverride,
  ]);

  /**
   * Prepare the room
   */
  useEffect(() => {
    if (!daily || state !== CALL_STATE_INITIALIZING || !meetingId) return;
    if (daily.isDestroyed()) return;
    const prepareDailyRoom = async () => {
      const url = `https://zync.daily.co/${meetingId}`;
      try {
        await createDailyMeetingRoom(meetingId);
        if (fromZyncBottomsup || subscriberOnly) {
          const properties = {
            start_audio_off: true,
            start_video_off: true,
            permissions: { hasPresence: false, canSend: false },
          };
          const token = await createMeetingToken(meetingId, properties);
          await daily.preAuth({ url, token });
        } else if (isMeetingController) {
          const properties = { is_owner: true };
          const token = await createMeetingToken(meetingId, properties);
          await daily.preAuth({ url, token });
        } else {
          await daily.preAuth({ url });
        }
        clearInterval(retryPrepareDailyIntervalRef.current);
      } catch (error) {
        logerror({
          ...fromError(error),
          meetingId,
          userId,
        });
        return;
      }
      dispatch({ type: CALL_STATE_CHANGE, newState: CALL_STATE_READY });
    };
    prepareDailyRoom();
  }, [
    daily,
    state,
    meetingId,
    userId,
    dispatch,
    isMeetingController,
    retryPrepareDaily,
    retryPrepareDailyIntervalRef,
    subscriberOnly,
  ]);

  // Note: retryPrepareDaily is added as a dependency to some hooks where we add event listeners because they do not get triggered in the case of a network issue and the
  // Daily object is recreated.

  /**
   * Start listening for participant changes, when the callObject is set.
   */
  useEffect(() => {
    if (!daily || !fakeParticipants) return;
    if (daily.isDestroyed()) return;
    const events = [
      'joined-meeting',
      'participant-joined',
      'participant-updated',
      'participant-left',
    ];

    function handleNewParticipantsState(event) {
      onParticipantsChange(userIdToTrackStates, event);

      if (event.action === 'participant-updated') {
        logParticipantUpdated(event);
      }

      dispatch({
        type: PARTICIPANTS_CHANGE,
        originalEvent: event,
        participants: { ...daily.participants(), ...fakeParticipants },
      });
    }

    // Listen for changes in state
    for (const event of events) {
      daily.on(event, handleNewParticipantsState);
    }

    // Stop listening for changes in state
    return function cleanup() {
      if (daily.isDestroyed()) return;
      for (const event of events) {
        daily.off(event, handleNewParticipantsState);
      }
    };
  }, [
    userIdToTrackStates,
    onParticipantsChange,
    daily,
    dispatch,
    fakeParticipants,
    retryPrepareDaily,
  ]);

  useEffect(() => {
    if (!daily) {
      return;
    }
    if (daily.isDestroyed()) return;
    const interval = setInterval(async () => {
      try {
        const networkStats = await daily.getNetworkStats();

        switch (networkStats.threshold) {
          case 'low': {
            logwarn({
              userId,
              message: `User is experiencing ${networkStats.threshold} network conditions.`,
              stats: networkStats,
            });
            break;
          }

          case 'very-low': {
            logerror({
              userId,
              message: `User is experiencing ${networkStats.threshold} network conditions.`,
              stats: networkStats,
            });
            break;
          }

          default: {
            return;
          }
        }
      } catch (error) {
        logerror(fromError(error));
      }
    }, 60_000);

    return function cleanup() {
      clearInterval(interval);
    };
  }, [userId, daily]);

  /**
   * Start listening for call errors, when the callObject is set.
   */
  useEffect(() => {
    if (!daily) return;
    if (daily.isDestroyed()) return;
    function handleCameraErrorEvent(event) {
      dispatch({
        type: CAM_OR_MIC_ERROR,
        message:
          (event && event.errorMsg && event.errorMsg.errorMsg) || 'Unknown',
      });
    }

    // We're making an assumption here: there is no camera error when callObject
    // is first assigned.

    daily.on('camera-error', handleCameraErrorEvent);

    return function cleanup() {
      if (daily.isDestroyed()) return;
      daily.off('camera-error', handleCameraErrorEvent);
    };
  }, [daily, dispatch, retryPrepareDaily]);

  /**
   * Start listening for fatal errors, when the callObject is set.
   */
  useEffect(() => {
    if (!daily) return;
    if (daily.isDestroyed()) return;
    function handleErrorEvent(e) {
      dispatch({
        type: FATAL_ERROR,
        message: (e && e.errorMsg) || 'Unknown',
      });
    }

    // We're making an assumption here: there is no error when callObject is
    // first assigned.

    daily.on('error', handleErrorEvent);

    return function cleanup() {
      if (daily.isDestroyed()) return;
      daily.off('error', handleErrorEvent);
    };
  }, [daily, dispatch, retryPrepareDaily]);

  /**
   * Start listening for fatal errors, when the callObject is set.
   */
  useEffect(() => {
    if (!daily) return;
    if (daily.isDestroyed()) return;
    function handleNonfatalErrorEvent(e) {
      // Specially handle screenshare errors.
      if (e.type === 'screen-share-error' && onScreenShareError) {
        onScreenShareError();
      }

      dispatch({
        type: NONFATAL_ERROR,
        message: (e && e.errorMsg) || 'Unknown',
      });
    }

    // We're making an assumption here: there is no error when callObject is
    // first assigned.

    daily.on('nonfatal-error', handleNonfatalErrorEvent);

    return function cleanup() {
      if (daily.isDestroyed()) return;
      daily.off('nonfatal-error', handleNonfatalErrorEvent);
    };
  }, [daily, dispatch, onScreenShareError, retryPrepareDaily]);

  /**
   * Start a timer to show the "click allow" message, when the component mounts.
   */
  useEffect(() => {
    const t = setTimeout(() => {
      dispatch({ type: CLICK_ALLOW_TIMEOUT });
    }, 2500);

    return function cleanup() {
      clearTimeout(t);
    };
  }, [dispatch]);

  /**
   * Listen for and manage call state
   */
  useEffect(() => {
    if (!daily) return;
    if (daily.isDestroyed()) return;
    const events = [
      'joined-meeting',
      'joining-meeting',
      'left-meeting',
      'load-attempt-failed',
      'error',
    ];

    const setState = (newState) =>
      dispatch({ type: CALL_STATE_CHANGE, newState });

    const logDailyError = (message) => {
      logerror({
        userId,
        meetingId,
        message: 'Failure while loading the daily call object: ' + message,
      });
    };

    const handleMeetingState = async (ev) => {
      switch (ev.action) {
        /**
         * Don't transition to 'joining' or 'joined' UI as long as access is not 'full'.
         * This means a request to join a private room is not granted, yet.
         * Technically in requesting for access, the participant is already known
         * to the room, but not joined, yet.
         */
        case 'joining-meeting':
          setState(CALL_STATE_JOINING);
          break;
        case 'joined-meeting':
          setState(CALL_STATE_JOINED);
          break;
        case 'left-meeting':
          destroy();
          // Cleanup call state after leaving the meeting.
          dispatch({
            type: CLEANUP,
          });
          setState(
            !redirectOnLeave ? CALL_STATE_ENDED : CALL_STATE_REDIRECTING
          );
          break;
        case 'load-attempt-failed': {
          setState(CALL_STATE_ERROR);
          logDailyError('[Failed Load Attempt] ' + ev?.errorMsg ?? ev?.message);
          break;
        }
        case 'error':
          switch (ev?.error?.type) {
            case 'nbf-room':
            case 'nbf-token':
              destroy();
              setState(CALL_STATE_NOT_BEFORE);
              logDailyError('[nbf] ' + ev?.errorMsg ?? ev?.message);
              break;
            case 'exp-room':
            case 'exp-token':
              destroy();
              setState(CALL_STATE_EXPIRED);
              logDailyError('[exp] ' + ev?.errorMsg ?? ev?.message);
              break;
            case 'ejected':
              destroy();
              setState(CALL_STATE_REMOVED);
              break;
            default:
              switch (ev?.errorMsg) {
                case 'Join request rejected':
                  // Join request to a private room was denied. We can end here.
                  setState(CALL_STATE_LOBBY);
                  leave();
                  break;
                case 'Meeting has ended':
                  // Meeting has ended or participant was removed by an owner.
                  destroy();
                  setState(CALL_STATE_ENDED);
                  break;
                case 'Meeting is full':
                  destroy();
                  setState(CALL_STATE_FULL);
                  break;
                case "The meeting you're trying to join does not exist.":
                  destroy();
                  setState(CALL_STATE_NOT_FOUND);
                  logDailyError(
                    '[Invalid room] ' + ev?.errorMsg ?? ev?.message
                  );
                  break;
                case 'You are not allowed to join this meeting':
                  destroy();
                  setState(CALL_STATE_NOT_ALLOWED);
                  break;
                default:
                  logDailyError(
                    '[Unknown error] ' + ev?.errorMsg ?? ev?.message
                  );
                  setState(CALL_STATE_ERROR);
                  break;
              }
              break;
          }
          break;
        default:
          break;
      }
    };

    // Listen for changes in state
    events.forEach((event) => daily.on(event, handleMeetingState));

    // Stop listening for changes in state
    return () => {
      if (daily.isDestroyed()) return;
      events.forEach((event) => daily.off(event, handleMeetingState));
    };
  }, [destroy, leave, daily, redirectOnLeave, dispatch, userId, meetingId]);

  /*
   * Listen for voice transcription events
   */
  useEffect(() => {
    if (!daily) return;
    if (daily.isDestroyed()) return;
    const handleTranscriptionEvent = (ev) => {
      switch (ev.action) {
        case 'app-message': {
          const data = ev.data;
          // Vincent: we can drop is_final check, but we will have a much more noise. I noticed however, that is_final skips some words, too
          if (ev?.fromId === 'transcription' && data?.is_final) {
            const participants = daily.participants();
            const isLocalUser =
              participants.local.session_id === data.session_id;

            const user = isLocalUser
              ? participants.local
              : participants[data.session_id];

            const speakerUserId = user?.user_name;

            if (userId === meetingLeaderUserId) {
              sendEvent(speakerUserId, meetingId, {
                type: 'STORE_VOICE_TRANSCRIPTION',
                userId: speakerUserId,
                timestamp: new Date(data.timestamp).getTime(),
                message: data.text,
              });
            }
          }
          break;
        }
        case 'transcription-error': {
          if (userId === meetingLeaderUserId) {
            startVoiceTranscription();
          }
          loginfo({
            message:
              'Starting voice transcription again, transcription-error received',
            meetingId,
          });
          break;
        }

        default: {
          loginfo({
            message: ` Unsupported action ${ev?.action}`,
            meetingId,
          });
        }
      }
    };
    const events = [
      'transcription-started',
      'app-message',
      'transcription-stopped',
      'error',
      'transcription-error',
    ];

    events.forEach((event) => daily.on(event, handleTranscriptionEvent));

    return () => {
      if (daily.isDestroyed()) return;
      events.forEach((event) => daily.off(event, handleTranscriptionEvent));
    };
  }, [
    daily,
    meetingId,
    meetingLeaderUserId,
    userId,
    retryPrepareDaily,
    startVoiceTranscription,
  ]);

  useEffect(() => {
    if (!daily) return;
    if (daily.isDestroyed()) return;

    daily
      .on('recording-started', startLocalRecording)
      .on('recording-data', onLocalRecordingData);

    return () => {
      if (daily.isDestroyed()) return;
      daily.off('recording-started', startLocalRecording);
      daily.off('recording-data', onLocalRecordingData);
    };
  }, [daily]);

  /**
   * Listen for pending user mutes & unmutes from the server.
   *
   * Once the local audio is muted by daily (via handleMuteUser),
   * dispatch the SET_MUTE action to reset the mute state.
   */
  useEffect(() => {
    const { mute: shouldMute, reason } = pendingMute || {};
    if (!shouldMute) return;
    handleMuteUser(reason);

    // Mark the mute action complete.
    dispatch({ type: 'SET_MUTE', mute: false });
  }, [pendingMute, dispatch, handleMuteUser]);

  useEffect(() => {
    if (!pendingUnmute) return;
    toggleMic(true);

    // Mark the unmute complete.
    dispatch({ type: 'SET_UNMUTE', unmute: false });
  }, [pendingUnmute, dispatch, toggleMic]);

  const getUserIdAndTrackStatesBySessionId = useCallback(
    (sessionId) => {
      const [userId, trackStates] =
        Object.entries(userIdToTrackStates).find(
          ([_userId, trackStates]) => trackStates.sessionId === sessionId
        ) || [];
      return { userId, trackStates };
    },
    [userIdToTrackStates]
  );

  /* Handle track subscriptions / unsubscriptions */
  useEffect(() => {
    if (!daily || state !== CALL_STATE_JOINED) return;
    if (daily.isDestroyed()) return;
    const subscriptionsToProcess = Object.keys(pendingSubscriptions);
    if (!subscriptionsToProcess.length) return;
    for (const sessionId of subscriptionsToProcess) {
      const subscribeToVideo = !!videoSubscriptions[sessionId];
      const subscribeToAudio = !!audioSubscriptions[sessionId];

      // Update the partipant's video quality if video is enabled.
      if (subscribeToVideo) {
        const videoQualitiesInVideoSubscriptions = [];
        let newReceiveSettings = 0;
        for (const [videoQuality, referenceCount] of Object.entries(
          videoSubscriptions[sessionId]
        )) {
          if (referenceCount > 0) {
            videoQualitiesInVideoSubscriptions.push(videoQuality);
          }
        }
        for (const videoQuality of videoQualitiesInVideoSubscriptions) {
          newReceiveSettings = Math.max(
            newReceiveSettings,
            simulcastLayers[videoQuality]
          );
        }
        daily.updateReceiveSettings({
          [sessionId]: { video: { layer: newReceiveSettings } },
        });
      }
      // updateParticipant must be called after updateReceiveSettings.
      // Otherwise, there is a brief deadzone where media elements are created with
      // the existing receive settings and never upgraded.
      daily.updateParticipant(sessionId, {
        setSubscribedTracks: {
          video: subscribeToVideo,
          screenVideo: subscribeToVideo,
          audio: subscribeToAudio,
          screenAudio: subscribeToAudio,
        },
      });

      if (DEBUG_SUBSCRIPTIONS) {
        const { userId } = getUserIdAndTrackStatesBySessionId(sessionId);
        loginfo({
          message: `Updating subscription for user ${userId} with session/peer id ${sessionId}. Video: ${subscribeToVideo} Audio: ${subscribeToAudio}`,
        });
      }
      dispatch({ type: SUBSCRIPTIONS_UPDATED, sessionId: sessionId });
    }
    if (DEBUG_SUBSCRIPTIONS) {
      loginfo({
        message: 'User track states',
        userIdToTrackStates,
      });
      loginfo({
        message: `Video stream references. ${JSON.parse(videoSubscriptions)}`,
      });
      loginfo({
        message: `Audio stream references. ${JSON.parse(audioSubscriptions)}`,
      });
    }
  }, [
    daily,
    pendingSubscriptions,
    videoSubscriptions,
    audioSubscriptions,
    state,
    dispatch,
    userIdToTrackStates,
    getUserIdAndTrackStatesBySessionId,
  ]);

  /* Handle unload - force destruction of the call object. */
  // Always keep a reference to the last seen daily destroy reference so that we can
  // clean up when the Meeting Component unloads unexpectedly (such as from the back button).
  // If there is a better way to do this, by all means...
  const lastDestroyRef = useRef(null);
  useEffect(() => {
    if (destroy) {
      lastDestroyRef.current = destroy;
    }
  }, [destroy]);
  useEffect(() => {
    return () => {
      lastDestroyRef.current && lastDestroyRef.current();
    };
  }, []);

  /**
   * Listen for active speaker change events.
   */
  useEffect(() => {
    if (!daily) return;
    if (daily.isDestroyed()) return;
    function handleActiveSpeakerChangeEvent(event) {
      // In daily, peerId and sessionId are equivalent.
      const { peerId: sessionId } = event?.activeSpeaker;
      if (!sessionId) {
        logerror({
          message: 'No sessionId was returned from `active-speaker-change`.',
        });
        return;
      }

      // Find the track states associated with the event's sessionId.
      // If this is slow, we could also store a map of sessionId -> trackStates in callState.
      const { userId, trackStates } =
        getUserIdAndTrackStatesBySessionId(sessionId);
      if (!userId || !trackStates) {
        logwarn({
          message: 'Could not find track states for session id: ' + sessionId,
        });
        return;
      }

      const activeSpeakerInfo = {
        userId,
        sessionId,
        local: trackStates.local,
      };
      if (onActiveSpeakerChange) {
        onActiveSpeakerChange(activeSpeakerInfo);
      }
      dispatch({
        type: ACTIVE_SPEAKER_CHANGE,
        ...activeSpeakerInfo,
      });
    }

    daily.on('active-speaker-change', handleActiveSpeakerChangeEvent);
    return function cleanup() {
      if (daily.isDestroyed()) return;
      daily.off('active-speaker-change', handleActiveSpeakerChangeEvent);
    };
  }, [
    daily,
    dispatch,
    onActiveSpeakerChange,
    getUserIdAndTrackStatesBySessionId,
  ]);

  return { setRedirectOnLeave };
};
