import React, { useCallback, useEffect, useState } from 'react';
import { LogoSpinnerFullCentered } from '../../components/LogoSpinner';
import { AdminProtectedRoute } from '../AdminProtectedRoute';
import { Page404 } from '../page404';
import { ModalBody, ModalTitle, ModalWindow } from '../../components/Modal';
import { FuturisticBackground } from '../../components/FuturisticBackground';
import { useSeries } from '../../hooks/useSeries';
import { useParams } from 'react-router-dom';
import { getEpisodeAssets } from '../../helper/api';
import { Button } from '../../components/common/Button';
import axios from 'axios';
import { PROCESSOR_BASE_URL } from '../../helper/constants';
import { uniq } from 'lodash';
import { RefreshIcon } from '../../components/icons/RefreshIcon';

const managedProcessors = [
  'RENDER_VIDEO_PORTRAIT',
  'RENDER_VIDEO_LANDSCAPE',
  'RENDER_VIDEO_SQUARE',
  'SOLO_HIGHLIGHTS_AGGREGATOR',
  'FEED_PAGE',
  'CONTENT_KIT_PAGE',
];

const filterByManagedProcessors = (assets = []) => {
  return assets.filter((asset) =>
    managedProcessors.includes(asset.processorId)
  );
};

const useAssets = (meetingSeriesId) => {
  const [assets, setAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getAssets = useCallback(async () => {
    setIsLoading(true);
    const { result } = await getEpisodeAssets(meetingSeriesId);
    setAssets(result);
    setIsLoading(false);
  }, [meetingSeriesId]);

  useEffect(() => {
    getAssets();
  }, [getAssets]);

  return {
    assets,
    getAssets,
    isLoading,
  };
};

const STATUS_TYPES = {
  COMPLETE: 'COMPLETE',
  PROCESSING: 'PROCESSING',
  WAITING_FOR_CALLBACK: 'WAITING_FOR_CALLBACK',
  ERROR: 'ERROR',
  WAITING_FOR_INPUT: 'WAITING FOR INPUT',
};

const getStatusText = (statusType) => {
  switch (statusType) {
    case STATUS_TYPES.COMPLETE:
      return '🟢';
    case STATUS_TYPES.ERROR:
      return '🔴';
    case STATUS_TYPES.WAITING_FOR_INPUT:
      return '⏰';
    case STATUS_TYPES.PROCESSING:
      return '🟠';
    case STATUS_TYPES.WAITING_FOR_CALLBACK:
      return '🟠';
    default: {
      return '';
    }
  }
};

const getGroupIds = (assets = []) => {
  return uniq(
    assets
      .filter((asset) => asset.groupId && asset.groupId !== 'fake')
      .map((asset) => asset.groupId)
  );
};

const buildTaskName = (asset) => {
  let result = `${getStatusText(asset.status?.type)} ${asset.processorId}`;

  switch (true) {
    case Boolean(asset.groupId): {
      result += ` - ${asset.groupId}`;
    }
    // eslint-disable-next-line
    default: {
      return result;
    }
  }
};

const Page = ({ assets, getAssets, meetingSeries, isLoading }) => {
  const handleRerun = async (processorId, groupId = undefined) => {
    const taskDetails = {
      processorId,
      meetingSeriesId: meetingSeries.meetingSeriesId,
      workspaceId: meetingSeries.workspace.workspaceId,
    };

    if (groupId) {
      taskDetails.groupId = groupId;
    }

    try {
      await axios.post(`${PROCESSOR_BASE_URL}/task`, {
        taskDetails,
        forceRun: true,
      });
    } finally {
      getAssets();
    }
  };

  const groupIds = getGroupIds(assets);
  console.log(groupIds);

  const handleDelete = (workspaceId, meetingSeriesId, groupId) => {
    const taskDetails = {
      workspaceId,
      meetingSeriesId,
      groupId,
    };

    return axios.delete(`${PROCESSOR_BASE_URL}/delete-single-asset`, {
      taskDetails,
      forceRun: true,
    });
  };

  return (
    <>
      <ModalWindow size="lg" zyncLogo={true} bg="transparent" boxShadow="3xl">
        <ModalTitle>
          <p className="text-2xl font-medium mb-3 text-blue-dark">
            {meetingSeries.title} ({meetingSeries.meetingSeriesId}) - Episode
            Admin Page
          </p>
        </ModalTitle>
        <ModalBody>
          <section>
            <a
              href={`https://contentkit.zync.ai/api/processor/statustext?meetingSeriesId=${meetingSeries.meetingSeriesId}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-light font-medium text-sm"
            >
              View Content Kit Status
            </a>
          </section>
          <section>
            <h2 className="text-xl my-3 font-semibold text-blue-dark">
              Tasks Management
            </h2>
            <Button
              color={Button.colors.PURPLE_LITE}
              onClick={getAssets}
              state={isLoading ? Button.states.LOADING : Button.states.DEFAULT}
            >
              <div className="flex items-center gap-2 text-sm">
                <RefreshIcon color="#8B33F7" /> Refresh
              </div>
            </Button>
            <div className="space-y-1 my-3">
              {filterByManagedProcessors(assets).map((asset) => (
                <div className="flex gap-2">
                  <p className="w-1/2">{buildTaskName(asset)}</p>
                  <div className="flex gap-1">
                    <Button
                      state={
                        isLoading
                          ? Button.states.DISABLED
                          : Button.states.DEFAULT
                      }
                      color={Button.colors.PURPLE}
                      padding={Button.padding.NONE}
                      onClick={() =>
                        handleRerun(asset.processorId, asset.groupId)
                      }
                    >
                      <span className="text-xs p-1">Force re-run</span>
                    </Button>
                    <Button
                      state={Button.states.DISABLED}
                      color={Button.colors.RED}
                      padding={Button.padding.NONE}
                      onClick={() => {
                        handleDelete(
                          meetingSeries.workspace.workspaceId,
                          meetingSeries.meetingSeriesId,
                          asset.groupId
                        );
                      }}
                    >
                      <span className="text-xs p-1">Delete</span>
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </ModalBody>
      </ModalWindow>
      <FuturisticBackground
        topGradientOpacity={FuturisticBackground.topGradientOpacity.LOW}
      />
    </>
  );
};

export const EpisodeAdminPage = () => {
  const { meetingSeriesId } = useParams();
  const { series, isLoading: isMeetingSeriesLoading } =
    useSeries(meetingSeriesId);
  const {
    assets,
    isLoading: areAssetsLoading,
    getAssets,
  } = useAssets(meetingSeriesId);

  if (isMeetingSeriesLoading) {
    return (
      <div className="h-screen w-screen place-content-center ">
        <LogoSpinnerFullCentered />
      </div>
    );
  }

  if (!series) {
    return <Page404 />;
  }

  return (
    <AdminProtectedRoute>
      <Page
        meetingSeries={series}
        assets={assets}
        getAssets={getAssets}
        isLoading={areAssetsLoading}
      />
    </AdminProtectedRoute>
  );
};
