import React, { useEffect, useState } from 'react';
import { ScenePreview } from './authoring/Menu/ScenePreview';
import { fetchSceneTemplateApi } from '../helper/api';
import { DynamicBlocks } from '../blocks/helper/dynamicBlockVariables';

export const PreviewSlidev2 = ({
  sceneTemplateId,
  brandKit,
  width,
  height,
  label,
  variablesToStaticMap = [],
  posterSlideTemplate: posterSlideTemplateProps,
  users,
}) => {
  const [posterSlideTemplate, setPosterSlideTemplate] = useState(
    posterSlideTemplateProps
  );

  useEffect(() => {
    if (!sceneTemplateId) {
      return;
    }
    fetchSceneTemplateApi(sceneTemplateId).then((result) => {
      setPosterSlideTemplate(result);
    });
  }, [sceneTemplateId]);

  const dynamicBlocks = new DynamicBlocks(posterSlideTemplate?.blocks || []);

  variablesToStaticMap.forEach(([dynamicVariable, staticValue]) => {
    dynamicBlocks.replaceWith(dynamicVariable, staticValue);
  });

  return posterSlideTemplate ? (
    <div>
      {label ? (
        <span className="text-sm text-blue-dark font-medium mb-1">{label}</span>
      ) : null}
      <div className="flex-shrink-0 rounded-md overflow-hidden">
        <ScenePreview
          scene={{
            ...posterSlideTemplate,
            blocks: dynamicBlocks.getAll(),
          }}
          width={width}
          height={height}
          brandKit={brandKit}
          users={users}
        />
      </div>
    </div>
  ) : null;
};
