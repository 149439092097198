import { isArray } from 'lodash';
import mixpanel from 'mixpanel-browser';

function getRandomFromList(listName) {
  return listName[Math.floor(Math.random() * Math.floor(listName.length))];
}

const nameGenerator = () => {
  return getRandomFromList(screenNames);
};

const idGenerator = () => {
  return Math.random().toString(36).substr(2, 9);
};

const meetingNameGenerator = () => {
  return (
    getRandomFromList(placeAdjectives) +
    ' ' +
    getRandomFromList(cityNames)
  )
    .toLowerCase()
    .replace(' ', '_');
};

const getRecordingBotUserId = () => {
  return process.env.REACT_APP_RECORDING_USER_ID;
};

const isProductionServer = () => {
  const domain = process.env.REACT_APP_DOMAIN;
  if (!domain) return false;
  return (
    domain === 'https://app.zync.ai' || domain === 'https://www.app.zync.ai'
  );
};

const getHomeUrl = () => {
  if (isProductionServer()) return 'https://zync.ai';
  return '/';
};

const getInitials = (user) => {
  if (!user || !user.userName) return 'GU';
  const initialsMatch = user.userName.match(/\b(\w)/g);
  return initialsMatch ? initialsMatch.join('') : user.userName[0];
};

const getUserById = (users, userId) => {
  return users.find((u) => u.userId === userId) || null;
};

const allUsersExcept = (users, userIdsToExclude) => {
  if (!users) {
    return;
  }

  if (!isArray(userIdsToExclude)) {
    userIdsToExclude = [userIdsToExclude];
  }

  return users.filter((user) => !userIdsToExclude.includes(user.userId));
};

const getUserId = () => {
  let userId = localStorage.getItem('userId');
  if (userId) {
    mixpanel.identify(userId);
  } else {
    userId = Math.random().toString(36).substr(2);
    localStorage.setItem('userId', userId);
    mixpanel.alias(userId);
  }
  return userId;
};

const isDev = () => {
  return process.env.NODE_ENV === 'development';
};

const isMobile = (() => {
  /* source https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser */
  let result = false;
  (function (a) {
    if (
      // eslint-disable-next-line
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      // eslint-disable-next-line
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      result = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return result;
})();

const userAgent = navigator.userAgent.toLowerCase();
export const isFirefox = userAgent.indexOf('firefox') > -1;
const isChrome = userAgent.indexOf('chrome') > -1;
export const isBrave = Boolean(navigator?.brave);
export const isSafari = userAgent.indexOf('safari') > -1 && !isChrome;
const isOpera = userAgent.indexOf('opr') > -1;

const isBrowserSupported = () => {
  return (isFirefox || isChrome || isSafari) && !isOpera;
};

const colorArray = [
  '#da095a',
  '#ec417a',
  '#db2929',
  '#e64b3b',
  '#fbb02d',
  '#ffc147',
  '#2d8a56',
  '#62c370',
  '#007F7F',
  '#00a3a5',
  '#135fa9',
  '#3376da',
  '#bf6ee0',
  '#8e44ad',
];
export const getBackgroundColor = (userId) => {
  return colorArray[(basicHash(userId) || 0) % colorArray.length];
};

/** Make a random ID in identical fashion to the server. */
export const makeRandomId = (length) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const basicHash = (str) => {
  if (!str) return Math.round(Math.random() * 100000);
  var hash = 0;
  if (str.length === 0) {
    return hash;
  }
  for (var i = 0; i < str.length; i++) {
    var char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return Math.abs(hash);
};

const screenNames = [
  'Axwayer',
  'Billibu',
  'Broodub',
  'Burntorge',
  'Buxbann',
  'Crazygy',
  'Delphotro',
  'Gamega',
  'Hamburgun',
  'HellTin',
  'Mjsp',
  'Neatar',
  'Novusea',
  'PacMajere',
  'PreciseSubject',
  'ReggaeLabs',
  'RiderFortune',
  'Scannemaki',
  'Scutivat',
  'Stunnaug',
  'WomanRing',
];
const cityNames = [
  'Tokyo',
  'Delhi',
  'Shanghai',
  'São Paulo',
  'Mexico City',
  'Cairo',
  'Mumbai',
  'Beijing',
  'Dhaka',
  'Osaka',
  'New York City',
  'Karachi',
  'Buenos Aires',
  'Chongqing',
  'Istanbul',
  'Kolkata',
  'Manila',
  'Lagos',
  'Rio de Janeiro',
  'Tianjin',
  'Kinshasa',
  'Guangzhou',
  'Los Angeles',
  'Moscow',
  'Shenzhen',
  'Lahore',
  'Bangalore',
  'Paris',
  'Bogotá',
  'Jakarta',
  'Chennai',
  'Lima',
  'Bangkok',
  'Seoul',
  'Nagoya',
  'Hyderabad',
  'London',
  'Tehran',
  'Chicago',
  'Chengdu',
  'Nanjing',
  'Wuhan',
  'Ho Chi Minh City',
  'Luanda',
  'Ahmedabad',
  'Kuala Lumpur',
  "Xi'an",
  'Hong Kong',
  'Dongguan',
  'Hangzhou',
  'Foshan',
  'Shenyang',
  'Riyadh',
  'Baghdad',
  'Santiago',
  'Surat',
  'Madrid',
  'Suzhou',
  'Pune',
  'Harbin',
  'Houston',
  'Dallas',
  'Toronto',
  'Dar es Salaam',
  'Miami',
  'Belo Horizonte',
  'Singapore',
  'Philadelphia',
  'Atlanta',
  'Fukuoka',
  'Khartoum',
  'Barcelona',
  'Johannesburg',
  'Saint Petersburg',
  'Qingdao',
  'Dalian',
  'Washington',
  'D.C.',
  'Yangon',
  'Alexandria',
  'Jinan',
  'Guadalajara',
];
const placeAdjectives = [
  'ancient',
  'beautiful',
  'boring',
  'bustling',
  'charming',
  'contemporary',
  'compact',
  'cosmopolitan',
  'crowded',
  'exciting',
  'expensive',
  'famous',
  'fantastic',
  'fascinating',
  'huge',
  'lively',
  'inexpensive',
  'popular',
  'picturesque',
  'polluted',
  'touristy',
];
export {
  nameGenerator,
  idGenerator,
  getUserId,
  allUsersExcept,
  isDev,
  isMobile,
  isBrowserSupported,
  meetingNameGenerator,
  getInitials,
  getUserById,
  isProductionServer,
  getHomeUrl,
  getRecordingBotUserId,
  basicHash,
  isChrome,
};
