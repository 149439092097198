import React from 'react';

export const TimelineIcon = ({
  width = '18px',
  height = '22px',
  className = 'fill-purple',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M15.72 0.920013H2.27999C1.22399 0.920013 0.359985 1.78401 0.359985 2.84001V19.16C0.359985 20.216 1.22399 21.08 2.27999 21.08H15.72C16.776 21.08 17.64 20.216 17.64 19.16V2.84001C17.64 1.78401 16.776 0.920013 15.72 0.920013ZM13.8 13.88H16.68V15.32H13.8V13.88ZM13.8 12.92V11.48H16.68V12.92H13.8ZM13.8 10.52V9.08001H16.68V10.52H13.8ZM13.8 8.12001V6.68001H16.68V8.12001H13.8ZM13.8 5.72001V4.28001H16.68V5.72001H13.8ZM13.8 16.28H16.68V17.72H13.8V16.28ZM16.68 2.84001V3.32001H13.8V1.88001H15.72C16.248 1.88001 16.68 2.31201 16.68 2.84001ZM12.84 1.88001V10.52H5.15999V1.88001H12.84ZM4.19999 15.32H1.31999V13.88H4.19999V15.32ZM4.19999 12.92H1.31999V11.48H4.19999V12.92ZM4.19999 8.12001H1.31999V6.68001H4.19999V8.12001ZM4.19999 9.08001V10.52H1.31999V9.08001H4.19999ZM4.19999 5.72001H1.31999V4.28001H4.19999V5.72001ZM1.31999 16.28H4.19999V17.72H1.31999V16.28ZM2.27999 1.88001H4.19999V3.32001H1.31999V2.84001C1.31999 2.31201 1.75199 1.88001 2.27999 1.88001ZM1.31999 19.16V18.68H4.19999V20.12H2.27999C1.75199 20.12 1.31999 19.688 1.31999 19.16ZM5.15999 20.12V11.48H12.84V20.12H5.15999ZM15.72 20.12H13.8V18.68H16.68V19.16C16.68 19.688 16.248 20.12 15.72 20.12Z" />
  </svg>
);
