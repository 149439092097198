import React from 'react';

import {
  Navbar,
  NavbarPlanInformation,
  PreviousPage,
} from './NavbarComponents';
import { NavbarUserDropdownMenu } from './NavbarUserDropdownMenu';
import { Link } from 'react-router-dom';
import { useWorkspacePlan } from '../../hooks/useWorkspacePlan';

export const DefaultNavbar = ({
  currentPage,
  previousRoute,
  isAuthenticated,
}) => {
  const {
    workspace,
    isAppSumo,
    isLoading,
    plan,
    isTrial,
    workspacePlanLabel,
    currentPeriodEnd,
  } = useWorkspacePlan();

  const workspaceId = workspace?.workspaceId;
  const workspacePlanValidUntil = workspace?.workspacePlanValidUntil;

  return (
    <Navbar>
      <div className="w-full flex justify-between items-center">
        <PreviousPage
          currentPage={currentPage}
          previousRoute={previousRoute ?? '/'}
        />
        <div className="flex gap-5 items-center">
          {isLoading ? null : isAppSumo ? (
            <Link
              to={`/workspace/billing?workspaceId=${workspaceId}`}
              className="text-blue-dark font-medium flex justify-center items-center text-xs cursor-pointer underline decoration-blue-dark"
            >
              {workspacePlanLabel} - Lifetime access
            </Link>
          ) : (
            <NavbarPlanInformation
              showUpgradeButtom={false}
              workspaceId={workspaceId}
              isTrial={isTrial}
              plan={plan}
              trialUntil={workspacePlanValidUntil}
              workspacePlanValidUntil={workspacePlanValidUntil}
              currentSubscriptionRenewalTime={currentPeriodEnd}
            />
          )}

          <ul>
            {isAuthenticated && (
              <li className="hidden md:block">
                <NavbarUserDropdownMenu />
              </li>
            )}
          </ul>
        </div>
      </div>
    </Navbar>
  );
};
