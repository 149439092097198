import React from 'react';

export const FacebookIcon = ({ width = '16px', height = '17px' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_12190_59722)">
      <path
        d="M16 8.75C16 4.33172 12.4183 0.75 8 0.75C3.58172 0.75 0 4.33172 0 8.75C0 12.743 2.92548 16.0527 6.75 16.6528V11.0625H4.71875V8.75H6.75V6.9875C6.75 4.9825 7.94435 3.875 9.77171 3.875C10.647 3.875 11.5625 4.03125 11.5625 4.03125V6H10.5537C9.55992 6 9.25 6.61667 9.25 7.24933V8.75H11.4688L11.1141 11.0625H9.25V16.6528C13.0745 16.0527 16 12.743 16 8.75Z"
        fill="#1877F2"
      />
      <path
        d="M11.1141 11.0625L11.4688 8.75H9.25V7.24933C9.25 6.61667 9.55992 6 10.5537 6H11.5625V4.03125C11.5625 4.03125 10.647 3.875 9.77171 3.875C7.94435 3.875 6.75 4.9825 6.75 6.9875V8.75H4.71875V11.0625H6.75V16.6528C7.57829 16.7824 8.42171 16.7824 9.25 16.6528V11.0625H11.1141Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_12190_59722">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.75)"
        />
      </clipPath>
    </defs>
  </svg>
);
