import { useEffect, useState } from 'react';
import { fetchSeriesApi } from '../helper/api';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

export const useSeries = (meetingSeriesId) => {
  const [series, setSeries] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useSelector((st) => st.auth, shallowEqual);
  const { userId } = user || {};
  const dispatch = useDispatch();

  useEffect(() => {
    if (!meetingSeriesId) return;
    const getSeries = async () => {
      dispatch({ type: 'CLEAR_MEETING_STATE', userId });
      setIsLoading(true);
      const result = await fetchSeriesApi(meetingSeriesId);
      setSeries(result);
      setIsLoading(false);
    };
    getSeries();
  }, [dispatch, meetingSeriesId, userId]);

  return {
    series,
    isLoading,
  };
};
