export const PersonInSquareIcon = ({ color = '#DFE1E6' }) => {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.31936 0.662109C0.623417 0.662109 0.0585938 1.22211 0.0585938 1.91211V8.16211C0.0585938 8.85211 0.623417 9.41211 1.31936 9.41211C2.0153 9.41211 2.58012 8.85211 2.58012 8.16211V3.16211H7.62319C8.31913 3.16211 8.88395 2.60211 8.88395 1.91211C8.88395 1.22211 8.31913 0.662109 7.62319 0.662109H1.31936ZM15.1878 0.662109C14.4918 0.662109 13.927 1.22211 13.927 1.91211C13.927 2.60211 14.4918 3.16211 15.1878 3.16211H20.2308V8.16211C20.2308 8.85211 20.7957 9.41211 21.4916 9.41211C22.1876 9.41211 22.7524 8.85211 22.7524 8.16211V1.91211C22.7524 1.22211 22.1876 0.662109 21.4916 0.662109H15.1878ZM11.4055 4.41211C9.3164 4.41211 7.62319 6.09086 7.62319 8.16211C7.62319 10.2334 9.3164 11.9121 11.4055 11.9121C13.4946 11.9121 15.1878 10.2334 15.1878 8.16211C15.1878 6.09086 13.4946 4.41211 11.4055 4.41211ZM10.1447 13.1621C7.96612 13.1621 5.76483 14.5596 5.14075 16.5221C4.88481 17.3271 5.51014 18.1621 6.36242 18.1621C7.6421 18.1621 15.8106 18.1621 16.4485 18.1621C17.3122 18.1621 17.9085 17.3321 17.6311 16.5221C16.9743 14.6058 14.7944 13.1621 12.6662 13.1621H10.1447ZM1.31936 14.4121C0.623417 14.4121 0.0585938 14.9721 0.0585938 15.6621V21.9121C0.0585938 22.6021 0.623417 23.1621 1.31936 23.1621H7.62319C8.31913 23.1621 8.88395 22.6021 8.88395 21.9121C8.88395 21.2221 8.31913 20.6621 7.62319 20.6621H2.58012V15.6621C2.58012 14.9721 2.0153 14.4121 1.31936 14.4121ZM21.4916 14.4121C20.7957 14.4121 20.2308 14.9721 20.2308 15.6621V20.6621H15.1878C14.4918 20.6621 13.927 21.2221 13.927 21.9121C13.927 22.6021 14.4918 23.1621 15.1878 23.1621H21.4916C22.1876 23.1621 22.7524 22.6021 22.7524 21.9121V15.6621C22.7524 14.9721 22.1876 14.4121 21.4916 14.4121Z"
        fill={color}
      />
    </svg>
  );
};
