import React from 'react';

export const RadioButton = ({
  value,
  name,
  checked,
  onChange,
  disabled,
  label,
}) => (
  <label className="flex items-center gap-2 text-sm">
    <input
      value={value}
      name={name}
      type="radio"
      checked={checked}
      onChange={disabled ? undefined : (event) => onChange(event.target.value)}
      className="form-radio text-purple focus:ring-0 focus:ring-white h-5 w-5"
    />
    <div>{label}</div>
  </label>
);
