import { useEffect, useState } from 'react';
import { fetchBlocksApi } from '../helper/api';

let blockTemplates = null;

/**
 * Use an array of block templates from blockList.js.
 * Initially may return a null value if the templates have not been cached.
 */
export const useBlockTemplates = () => {
  const [templates, setTemplates] = useState(blockTemplates);

  // fetch the block templates, cache, and update local templates state value.
  useEffect(() => {
    if (!templates) {
      fetchBlocksApi().then((blocks) => {
        setTemplates(blocks);
        blockTemplates = blocks; // cache the value for future uses of the hook.
      });
    }
  }, [templates, setTemplates]);

  return templates;
};
