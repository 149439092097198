import { useMemo } from 'react';

import { shuffle } from 'lodash';

export const EXAMPLE_USERS_TO_GENERATE = 16;

export const exampleUserImages = [
  'https://storage.googleapis.com/zync-media/assets/fake-user-images/authoring-user-1.jpg',
  'https://storage.googleapis.com/zync-media/assets/fake-user-images/authoring-user-2.jpg',
  'https://storage.googleapis.com/zync-media/assets/fake-user-images/authoring-user-3.jpg',
  'https://storage.googleapis.com/zync-media/assets/fake-user-images/authoring-user-4.jpg',
  'https://storage.googleapis.com/zync-media/assets/fake-user-images/authoring-user-5.jpg',
  'https://storage.googleapis.com/zync-media/assets/fake-user-images/authoring-user-6.jpg',
];

export const useExampleUsers = (count = 4, key = '') => {
  const users = useMemo(
    () =>
      getExampleUsers(count, key).map((user) => ({
        ...user,
        trackStates: exampleTrackStates[user.userId],
      })),
    [count, key]
  );
  return users;
};

const exampleUsersRaw = [
  {
    userId: 'albert.flores@zync.ai',
    userName: 'Albert Flores',
  },
  {
    userId: 'courtney.henry@zync.ai',
    userName: 'Courtney Henry',
  },
  {
    userId: 'jenny.wilson@zync.ai',
    userName: 'Jenny Wilson',
  },
  {
    userId: 'guy.hawkins@zync.ai',
    userName: 'Guy Hawkins',
  },
  {
    userId: 'kristin.watson@zync.ai',
    userName: 'Kristin Watson',
  },
  {
    userId: 'mitah.soto@zync.ai',
    userName: 'Mitah Soto',
  },
];

const orderedUsers = new Array(EXAMPLE_USERS_TO_GENERATE)
  .fill(null)
  .map((_, index) => {
    const randomExampleUserIndex = Math.floor(
      Math.random() * exampleUsersRaw.length
    );

    const user = exampleUsersRaw[randomExampleUserIndex];

    return { ...user, userId: user.userId + index };
  });

// cache generated user lists by key so that they do not change per block.
let userListByKey = {};

export const getExampleUsers = (count = 4, key = '') => {
  const cached = userListByKey[`${key}-${count}`];
  if (cached) {
    return cached;
  } else {
    const users = shuffle(orderedUsers).slice(0, count);
    userListByKey[`${key}-${count}`] = users;
    return users;
  }
};

export const exampleUsers = getExampleUsers(EXAMPLE_USERS_TO_GENERATE);

export const exampleTrackStates = orderedUsers.reduce(
  (memo, user, index) => ({
    ...memo,
    [user.userId]: {
      isFake: true,
      image: exampleUserImages[index % exampleUserImages.length],
      micOn: true,
      videoTrackState: {
        state: 'playable',
        track: {},
      },
    },
  }),
  {}
);

export const createExampleColorGrid = () => {
  const grid = new Array(15)
    .fill(null)
    .map(() => new Array(40).fill('#FFFFFF'));
  return grid;
};

export const createExampleQAData = () => {
  const users = getExampleUsers(2);
  const exampleHost = users[0];
  const exampleGuest = users[1];

  return {
    exampleHost,
    exampleGuest,
    activeId: 0,
    questions: [
      {
        id: 0,
        answered: false,
        ...exampleGuest,
        timestamp: 1634834399334,
        type: 'question',
        text: 'Example question from a member of the audience.',
        anonymous: false,
        votes: {
          [exampleHost.userId]: 1,
        },
        score: 28,
      },
      {
        id: 1,
        answered: true,
        ...exampleGuest,
        timestamp: 1634834407401,
        type: 'onstage',
      },
      {
        id: 2,
        answered: false,
        ...exampleGuest,
        timestamp: 1634834425789,
        type: 'question',
        text: 'An anonymous question for those who do not wish to display their username.',
        anonymous: true,
        votes: {},
        score: 5,
      },
      {
        id: 3,
        answered: true,
        ...exampleGuest,
        timestamp: 1634834425789,
        type: 'question',
        text: 'An anonymous question for those who do not wish to display their username.',
        anonymous: true,
        votes: {},
        score: 10,
      },
      {
        id: 4,
        answered: false,
        ...exampleGuest,
        timestamp: 1634834425789,
        type: 'question',
        text: 'An anonymous question for those who do not wish to display their username.',
        anonymous: true,
        votes: {},
        score: 10,
      },
    ],
  };
};
