import React from 'react';

import { MeetingBox } from './MeetingBox';
import { Permissions } from './Permissions';
import { flatten, uniq } from 'lodash';
import { isRolesMeetingController, Roles } from '../../helper/roles';

export const getUserRoles = (users) => {
  return uniq([...flatten(users.map((user) => user.roles))]).filter(
    (role) => !isRolesMeetingController([role])
  );
};

export const MeetingAttendees = ({
  attendees,
  series,
  localDispatch,
  meetingSeriesId,
  workspaceId,
  mode,
  title,
  description,
  children,
  allowInvite,
}) => {
  const { owner, visibility, meetingSeriesUrl } = series;
  const roleSuggestions = getUserRoles(attendees);

  return (
    <MeetingBox
      title={title || 'Moderators'}
      description={
        description ||
        'These are the people who have full permissions to control the event flow, and can make changes to the event template'
      }
      showUrl={mode === Roles.GUEST}
      urlText={mode === Roles.GUEST && 'INVITE'}
      url={mode === Roles.GUEST && meetingSeriesUrl}
    >
      <Permissions
        children={children}
        localDispatch={localDispatch}
        series={series}
        mode={mode || Roles.MODERATOR}
        workspaceId={workspaceId}
        roleSuggestions={roleSuggestions}
        owner={owner}
        attendees={attendees}
        meetingSeriesId={meetingSeriesId}
        visibility={visibility}
        inviteInputTitle={
          (mode === Roles.GUEST && 'Invite Selected Participants') || 'Invite'
        }
        Roles={Roles}
        allowInvite={allowInvite}
      />
    </MeetingBox>
  );
};
