import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { isMobile } from '../helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { useDailyVideo } from '../hooks/useDailyVideo';
import { videoQualities } from '../helper/constants';
import { UserReactions } from './UserReactions';
import { UserVideo } from './UserVideo';
import { useWindowSize } from '../helper/windowSizeChangeHandler';
import { useSelector } from 'react-redux';

const REACTIONS_WIDTH = 52;
const BOTTOM_BAR_HEIGHT = 70;
const TOP_MARGIN = 0;

const Scene = styled.section`
  height: calc(
    ${isMobile ? '100%' : `100vh - ${BOTTOM_BAR_HEIGHT + TOP_MARGIN}px`}
  );
  margin-top: ${TOP_MARGIN}px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const screenShareVideoHeight = isMobile
  ? '100%'
  : `calc(
    100vh - ${BOTTOM_BAR_HEIGHT + TOP_MARGIN}px
  ) `;

const ScreenShareVideo = styled.video`
  box-sizing: border-box;
  height: ${screenShareVideoHeight};
`;

const FullScreen = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  padding: 0 8px;
  position: absolute;
  top: 34px;
  cursor: pointer;
  z-index: 100;
`;

const ScreenSharingUserVideo = React.memo(({ userId, userName }) => {
  const userIdToTrackStates =
    useSelector((_st) => _st.callState.tracks.allUsers) || {};

  return (
    <UserVideo
      circle
      height={isMobile ? 100 : 125}
      showUserName
      userName={userName}
      trackStates={userIdToTrackStates[userId]}
      userId={userId}
      sizingMode="cover"
      videoQuality={videoQualities.HIGH}
    />
  );
});
export const ScreenShareScene = ({
  screenShareTrackStates,
  mobileOptionsMenu,
}) => {
  useWindowSize();

  const fullScreen = () => {
    const d = document.getElementById('screensharevideo');
    d.requestFullscreen();
  };

  const { videoTrack, videoElement: videoElementRef } = useDailyVideo(
    screenShareTrackStates,
    videoQualities.LOW
  );

  const [reactionsPosition, setReactionsPosition] = useState(-REACTIONS_WIDTH);

  const adjustReactionsPosition = useCallback(() => {
    const videoElement = videoElementRef.current;

    if (videoElement && videoElement.clientWidth) {
      setTimeout(() => {
        setReactionsPosition(
          Math.min(
            Math.max(
              (videoElement.clientWidth - window.innerWidth) / 2,
              -REACTIONS_WIDTH
            ),
            0
          )
        );
      }, 300);
    }
  }, [videoElementRef]);

  useEffect(() => {
    adjustReactionsPosition();
  });

  const isHorizontal = window.innerWidth > window.innerHeight;

  return (
    <Scene className="sm-landscape:items-end">
      {isMobile ? null : (
        <FullScreen onClick={fullScreen}>
          Full Screen <FontAwesomeIcon icon={faExpandArrowsAlt} />
        </FullScreen>
      )}

      {videoTrack && (
        <div className="relative h-full sm-landscape:h-full md:h-auto">
          <ScreenShareVideo
            id="screensharevideo"
            muted
            playsInline
            autoPlay
            ref={videoElementRef}
          />
          <div
            style={{
              left: isHorizontal ? reactionsPosition : undefined,
            }}
            className="absolute bottom-[175px] left-2 md:top-1/2 sm-landscape:top-1/2 md:left-2 sm-landscape:left-2 md:bottom-0 sm-landscape:bottom-0"
          >
            <UserReactions vertical={window.innerWidth > window.innerHeight} />
          </div>
          <div className="absolute bottom-8 left-2 opacity-80">
            <ScreenSharingUserVideo
              userName={screenShareTrackStates?.user?.userName}
              userId={screenShareTrackStates?.user?.userId}
            />
          </div>
        </div>
      )}
      {mobileOptionsMenu}
    </Scene>
  );
};
