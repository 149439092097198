import React, { useCallback, useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import {
  checkIsSubscriptionPastDue,
  PLANS,
} from 'zync-common/zyncCustomerPlans';
import { GiftPresentIcon } from '../components/icons/GiftPresentIcon';
import { WarningTriangleIcon } from '../components/icons/WarningTriangleIcon';

import { dispatchToast } from '../components/useWorkspacePlanEnforcementMessages';
import {
  fetchSeriesApi,
  fetchWorkspaceApi,
  upsertWorkspaceMemberApi,
} from '../helper/api';
import { fromError, logerror } from '../helper/contextualLogger';
import { useChangeCustomerPlan } from './useChangeCustomerPlan';
import { useStripeConfig } from './useStripeConfig';

const dispatchSuccessMessage = (newPlan) => {
  return dispatchToast(
    <div className="flex space-x-4 items-center">
      <Confetti />
      <div className="bg-purple bg-opacity-10 w-[44px] h-[44px] grid place-content-center">
        <GiftPresentIcon />
      </div>
      <div>
        <p className="text-blue-dark font-semibold mb-0.5">
          Meeting upgraded to{' '}
          <span className="text-purple capitalize">{newPlan}</span> Plan.
        </p>
        <p className="text-blue-gray font-normal">
          This meeting has been upgraded by the host and now supports upto{' '}
          {PLANS[newPlan]?.maxAttendees} attendees and{' '}
          {PLANS[newPlan]?.maxMinutes} minutes.{' '}
        </p>
      </div>
    </div>
  );
};

const dispatchErrorMessage = (newPlan) => {
  return dispatchToast(
    <div className="flex space-x-4 items-center">
      <div className="bg-red bg-opacity-10 w-[44px] h-[44px] grid place-content-center">
        <WarningTriangleIcon className="fill-red" />
      </div>
      <div>
        <p className="text-blue-dark font-semibold mb-0.5">
          We had a problem with your last payment
        </p>
        <p className="text-blue-gray font-normal">
          We had a problem processing your last payment for{' '}
          <span className="capitalize text-purple">{newPlan}</span> Plan. Please
          go to Workspace Settings in order to verify your payment method.
        </p>
      </div>
    </div>
  );
};

export const useChangePlanDuringMeeting = ({
  series,
  setSeries,
  subscription,
  refreshSubscription,
}) => {
  const [currentWorkspace, setCurrentWorkspace] = useState(null);
  const [targetCustomerPlanName, setTargetCustomerPlanName] = useState(false);

  const { stripeConfig } = useStripeConfig();

  const seriesWorkspaceId = series?.workspace?.workspaceId;
  const meetingSeriesId = series?.meetingSeriesId;

  const onComplete = useCallback(
    async ({ updatedMember, subscription: pendingSubscription }) => {
      if (seriesWorkspaceId) {
        const { error } = await upsertWorkspaceMemberApi(
          seriesWorkspaceId,
          updatedMember.userId,
          updatedMember.plan,
          updatedMember.isAdmin,
          updatedMember.isProducer
        );

        const newPlan = updatedMember.plan;

        if (!error) {
          checkIsSubscriptionPastDue(pendingSubscription)
            ? dispatchErrorMessage(newPlan)
            : dispatchSuccessMessage(newPlan);

          await refreshSubscription();

          setTargetCustomerPlanName(null);

          const result = await fetchSeriesApi(meetingSeriesId);

          return setSeries((prevSeries) => ({
            ...prevSeries,
            upcomingMeeting: {
              ...prevSeries.upcomingMeeting,
              plan: result.upcomingMeeting.plan,
            },
          }));
        }
      }
    },
    [refreshSubscription, seriesWorkspaceId, meetingSeriesId, setSeries]
  );

  const meetingOwnerId = series?.owner;

  const selectedMember =
    meetingOwnerId && currentWorkspace
      ? currentWorkspace.members.find(
          (member) => member.userId === meetingOwnerId
        )
      : null;

  const { changePlan } = useChangeCustomerPlan({
    existingSubscription: subscription,
    stripeConfig,
    selectedMember,
    workspace: currentWorkspace,
    onComplete,
  });

  const handleChangePlan = useCallback(
    async (newPlan) => {
      setTargetCustomerPlanName(newPlan);

      try {
        await changePlan(newPlan);
      } catch (error) {
        logerror(fromError(error));
      }
    },
    [changePlan]
  );

  useEffect(() => {
    const fetchWorkspace = async () => {
      if (seriesWorkspaceId) {
        const response = await fetchWorkspaceApi(seriesWorkspaceId);
        setCurrentWorkspace(response.result);
      }
    };

    fetchWorkspace();
  }, [seriesWorkspaceId]);

  return { changePlan: handleChangePlan, targetCustomerPlanName };
};
