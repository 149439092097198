import React, {
  useCallback,
  useContext,
  useState,
  useRef,
  useMemo,
} from 'react';
import Draggable from 'react-draggable';
import { ResizableBox } from 'react-resizable';
import { SLIDE_CONTAINER_ID } from '../../helper/constants';
import { useClickOutside } from '../../helper/useClickOutside';
import {
  SlideBlockLayoutClickable,
  SlideBlockPositionDiv,
} from './AuthoringTool.styled';
import { DEFAULT_BLOCK_SIZE_PX } from './constants';
import { getFromClipboard, notifyUser, useAuthoringTool } from './hooks';
import { useSlideSize } from '../../hooks/useSlideSize';
import { SlideContainerRefContext } from '../Slide';
import { sizeToNumber } from '../../helper/css';
import { throttle } from 'lodash';
import {
  AuthoringContextMenuBlockActions,
  AuthoringContextMenuStandardActions,
  ContextMenu,
} from '../ContextMenu';
import { Divider } from '../meetingTabs/Invite/InviteTab.styled';
import { MATA } from './localStateAndContext';
import { slideBlocksSwap } from 'zync-common/helper/slideBlocksSwap';

const draggableHandleClassName = 'slideBlockIcon';
const initialBlockPosition = { x: 0, y: 0 };
const resizeHandles = ['s', 'w', 'e', 'n', 'sw', 'nw', 'se', 'ne'];
const EMPTY_LIST = [];
const MIN_DISTANCE_TO_DRAG_WITHOUT_SELECT = 3;

const cleanupNodePosition = (node) => {
  node.style.left = null;
  node.style.top = null;
};

const ensureUniqueValue = (value) => value + Math.random() / 1000000;

const shapeBlockIds = {
  rectangle: 'rectangle',
  circle: 'circle',
  triangle: 'triangle',
  star: 'star',
};

const isShapeBlock = (blockId) => !!shapeBlockIds[blockId];

export const splitValueAndUnit = (cssProperty) => {
  if (typeof cssProperty === 'number') {
    return [null, cssProperty, 'px'];
  }
  return cssProperty && cssProperty.match(/(-?[\d.]+)([a-z%]*)/);
};

const getPercentageFromCssProp = (cssProperty, containerDimension) => {
  if (!cssProperty) {
    cssProperty = '0%';
  }

  const [, value, unit] = splitValueAndUnit(cssProperty);

  let result = 0;

  switch (unit) {
    case 'px': {
      result = parseFloat(value) / containerDimension;
      break;
    }

    case '%': {
      result = parseFloat(value) / 100;
      break;
    }

    default: {
      result = value / 100;
      break;
    }
  }

  return ensureUniqueValue(result);
};

const convertFloatToCssPercent = (float) => {
  return `${(float * 100).toFixed(2)}%`;
};

const convertPercentageToPx = (cssPercent, parentDimensions) => {
  const percent = getPercentageFromCssProp(cssPercent);

  return {
    width: percent * parentDimensions.width,
    height: percent * parentDimensions.height,
  };
};

export const calculateBlockPosition = (slideBlock, parentDimensions) => {
  const blockHeightPx =
    convertPercentageToPx(slideBlock.settings.height, parentDimensions)
      .height || DEFAULT_BLOCK_SIZE_PX;

  const blockWidthPx =
    convertPercentageToPx(slideBlock.settings.width, parentDimensions).width ||
    DEFAULT_BLOCK_SIZE_PX;

  return {
    height: blockHeightPx,
    width: blockWidthPx,
    top:
      convertPercentageToPx(slideBlock.settings.top, parentDimensions).height ??
      0,
    left:
      convertPercentageToPx(slideBlock.settings.left, parentDimensions).width ??
      0,
  };
};

function getDragLocation(dragData, containerElement, blockSettings) {
  if (!containerElement) {
    return null;
  }
  const { x, y } = dragData;

  const parentDimensions = containerElement.getBoundingClientRect();

  const newX = x / parentDimensions.width;
  const newY = y / parentDimensions.height;
  const top = convertFloatToCssPercent(
    getPercentageFromCssProp(blockSettings.top, parentDimensions.height) + newY
  );
  const left = convertFloatToCssPercent(
    getPercentageFromCssProp(blockSettings.left, parentDimensions.width) + newX
  );

  return { left, top };
}

const checkConstraint = (value, max) => {
  return value > max ? max : value;
};

const getConstraint = (constraint, sceneDimension) => {
  const [, value, unit] = splitValueAndUnit(constraint);
  const parsedValue = parseFloat(value);
  switch (unit) {
    case '%':
      const valuePx = (parsedValue * sceneDimension) / 100;
      return checkConstraint(valuePx, sceneDimension);
    default:
      return checkConstraint(parsedValue, sceneDimension);
  }
};

// TODO: Increase minimum size and put minimum sizes in blockList
const getFinalConstraints = (constraints, sceneDimensions) => {
  const finalConstraints = {
    minWidth: 10,
    minHeight: 10,
    maxHeight: sceneDimensions.height,
    maxWidth: sceneDimensions.width,
  };

  if (Object.keys(constraints).length === 0) return finalConstraints;

  const maxHeightConstraint = getConstraint(
    constraints['maxHeight'] || sceneDimensions.height,
    sceneDimensions.height
  );
  finalConstraints['maxHeight'] = maxHeightConstraint;

  const minHeightConstraint = getConstraint(
    constraints['minHeight'] || 10,
    sceneDimensions.height
  );
  finalConstraints['minHeight'] = minHeightConstraint;

  const maxWidthConstraint = getConstraint(
    constraints['maxWidth'] || sceneDimensions.width,
    sceneDimensions.width
  );
  finalConstraints['maxWidth'] = maxWidthConstraint;

  const minWidthConstraint = getConstraint(
    constraints['minWidth'] || 10,
    sceneDimensions.width
  );
  finalConstraints['minWidth'] = minWidthConstraint;

  return finalConstraints;
};

/*const getBlockOrderZ = (slideBlock, slideBlocks) => {
  return (
    slideBlock.settings.orderZ ||
    slideBlocks.findIndex(
      (sb) => sb.blockInstanceId === slideBlock.blockInstanceId
    )
  );
};*/

export const SlideBlockPreview = ({
  block: propBlock,
  onResizing,
  onDoubleSelect,
}) => {
  const block = useMemo(
    () => ({ ...propBlock, position: propBlock.editPosition }),
    [propBlock]
  );

  const { constraints = {} } = block.details;

  const draggableNodeRef = useRef(null);

  const {
    selectSlideBlock,
    selectedSlideBlock,
    updateSlideBlockDimensions,
    updateSlideBlock,
    handleCopy,
    removeSelectedSlideBlock,
    pasteBlock,
    selectedSlide: {
      slideConfig: { slideBlocks },
    },
    dispatchTd,
    isAdvancedAuthoringModeEnabled,
    currentSeries,
  } = useAuthoringTool();

  const sceneDimensions = useSlideSize('slide-container');

  const finalConstraints = getFinalConstraints(constraints, sceneDimensions);

  const slideContainerRef = useContext(SlideContainerRefContext);

  const isEditableBlock = Boolean(block.details.inlineEditable);

  const isSelected =
    block.blockInstanceId === selectedSlideBlock?.blockInstanceId;
  const [isDragging, setIsDragging] = useState(false);

  // TODO: Remove this when we debug it.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onResizingThrottled = useCallback(throttle(onResizing, 30), [
    onResizing,
  ]);

  const [startDragPosition, setStartDragPosition] = useState(null);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

  const handleClickBlock = useCallback(
    (event) => {
      event.stopPropagation();
      if (isSelected) {
        if (onDoubleSelect) {
          onDoubleSelect();
        }
      } else {
        selectSlideBlock(block.blockInstanceId);
      }
    },
    [selectSlideBlock, block, isSelected, onDoubleSelect]
  );

  const handleStartDrag = useCallback(
    (event, data) => {
      const location = getDragLocation(
        data,
        slideContainerRef.current,
        block.settings
      );

      if (!isDragging) setStartDragPosition(location);

      setIsDragging(true);

      onResizingThrottled(true, {
        ...location,
        blockInstanceId: block.blockInstanceId,
      });
    },
    [
      slideContainerRef,
      block.settings,
      block.blockInstanceId,
      isDragging,
      onResizingThrottled,
    ]
  );

  const handleStopDrag = useCallback(
    (event) => {
      if (isDragging) {
        event.stopPropagation();

        setIsDragging(false);
        onResizing(false, null);
      }
    },
    [isDragging, setIsDragging, onResizing]
  );

  useClickOutside(draggableNodeRef, handleStopDrag);

  /**
   * Get the x/y coordinate by passing in the top/left css property and the scene dimension's
   * width/height. Since top/left is a percentage in a string, slice it to leave out the '%'
   * and calculate the coordinate.
   */
  const getCoordinateFromPercentage = (location, sceneDimension) => {
    const percentage = location.slice(0, location.length - 1);
    return (parseFloat(percentage) / 100) * sceneDimension;
  };

  const checkIfShouldSelect = useCallback(
    (finalLocation) => {
      if (!startDragPosition) return 0;
      const finalTop = getCoordinateFromPercentage(
        finalLocation.top,
        sceneDimensions.height
      );
      const finalLeft = getCoordinateFromPercentage(
        finalLocation.left,
        sceneDimensions.width
      );
      const startTop = getCoordinateFromPercentage(
        startDragPosition.top,
        sceneDimensions.height
      );
      const startLeft = getCoordinateFromPercentage(
        startDragPosition.left,
        sceneDimensions.width
      );

      const distance = Math.floor(
        Math.sqrt(
          Math.pow(finalTop - startTop, 2) + Math.pow(finalLeft - startLeft, 2)
        )
      );

      if (distance < MIN_DISTANCE_TO_DRAG_WITHOUT_SELECT) {
        selectSlideBlock(block.blockInstanceId);
      }
    },
    [
      sceneDimensions,
      startDragPosition,
      selectSlideBlock,
      block.blockInstanceId,
    ]
  );

  const handleFinishDrag = useCallback(
    (event, data) => {
      event.stopPropagation();
      if (isDragging) {
        cleanupNodePosition(data.node);

        const location = getDragLocation(
          data,
          slideContainerRef.current,
          block.settings
        );

        checkIfShouldSelect(location);
        setStartDragPosition(null);
        if (location) {
          updateSlideBlockDimensions({
            blockInstanceId: block.blockInstanceId,
            ...location,
          });
        }
      }
    },
    [
      isDragging,
      block.blockInstanceId,
      block.settings,
      updateSlideBlockDimensions,
      slideContainerRef,
      checkIfShouldSelect,
    ]
  );

  const handleOpenContextMenu = () => {
    setIsContextMenuOpen(true);
  };
  const handleCloseContextMenu = () => {
    setIsContextMenuOpen(false);
  };

  /**
   * Snap the block to the edge of the scene if user tries to resize the block
   * beyond the scene. However, only do this if the max width / height constraint
   * of the block is outside the scene. If this check is not there, then the block
   * will always snap to the edge of the scene when stretched to the max constraint even
   * if the mouse is not outside the scene.
   */
  const checkResizeOutOfBounds = (
    event,
    parentDimensions,
    nodeDimensions,
    newOptions,
    blockNode,
    maxPositionLeft,
    maxPositionTop,
    resizeCallbackData
  ) => {
    if (
      // Only check if the northwest or southwest handler is being used.
      resizeCallbackData.handle.includes('w') &&
      getPercentageFromCssProp(maxPositionLeft) <= 0.0 &&
      event.x < parentDimensions.left
    ) {
      blockNode.style.left = convertFloatToCssPercent(0.0);
      newOptions.left = convertFloatToCssPercent(0.0);
      newOptions.width = convertFloatToCssPercent(
        (nodeDimensions.right - parentDimensions.left) / parentDimensions.width
      );
    }

    // Only check if the northwest or northeast handler is being used.
    if (
      resizeCallbackData.handle.includes('n') &&
      getPercentageFromCssProp(maxPositionTop) <= 0.0 &&
      event.y < parentDimensions.top
    ) {
      blockNode.style.top = convertFloatToCssPercent(0.0);
      newOptions.top = convertFloatToCssPercent(0.0);
      newOptions.height = convertFloatToCssPercent(
        (nodeDimensions.bottom - parentDimensions.top) / parentDimensions.height
      );
    }
  };

  /**
   * Calculate the blocks' min/max constraint position. If the yAxis object is passed in,
   * it will calculate the blocks' min/max top position. If the xAxis object is passed in,
   * it will calculate the blocks' min/max left position.
   */
  const calculateBlockConstraintPosition = ({ yAxis, xAxis }) => {
    const {
      blockPosition,
      blockDimension,
      constraintDimension,
      parentDimension,
    } = yAxis || xAxis;
    const oppositePosition = blockPosition + blockDimension;
    const blockConstraintPosition = oppositePosition - constraintDimension;
    const blockConstraintPositionPercent = convertFloatToCssPercent(
      blockConstraintPosition / parentDimension
    );
    if (blockConstraintPositionPercent < 0.0) return 0.0;
    return blockConstraintPositionPercent;
  };

  const handleFinishResize = useCallback(
    (event, resizeCallbackData) => {
      const isResizing = !event.type.includes('up');

      const { size, node } = resizeCallbackData;

      const nodeDimensions =
        node.parentElement.parentElement.getBoundingClientRect();
      const parentDimensions =
        slideContainerRef.current.getBoundingClientRect();

      const maxWidth =
        1 -
        (nodeDimensions.left - parentDimensions.left) / parentDimensions.width;
      const maxHeight =
        1 -
        (nodeDimensions.top - parentDimensions.top) / parentDimensions.height;

      const newWidth = Math.min(maxWidth, size.width / parentDimensions.width);
      const newHeight = Math.min(
        maxHeight,
        size.height / parentDimensions.height
      );

      const containerBounds = slideContainerRef.current.getBoundingClientRect();
      const blockNode = resizeCallbackData.node.parentElement.parentElement;

      const blockTop = convertFloatToCssPercent(
        (event.pageY - containerBounds.top) / containerBounds.height
      );
      const blockLeft = convertFloatToCssPercent(
        (event.pageX - containerBounds.left) / containerBounds.width
      );

      const newOptions = {
        blockInstanceId: block.blockInstanceId,
        width: convertFloatToCssPercent(newWidth),
        height: convertFloatToCssPercent(newHeight),
      };

      const minPositionLeft = calculateBlockConstraintPosition({
        xAxis: {
          blockPosition: block.position.left,
          blockDimension: block.position.width,
          parentDimension: parentDimensions.width,
          constraintDimension: finalConstraints.minWidth,
        },
      });

      const minPositionTop = calculateBlockConstraintPosition({
        yAxis: {
          blockPosition: block.position.top,
          blockDimension: block.position.height,
          parentDimension: parentDimensions.height,
          constraintDimension: finalConstraints.minHeight,
        },
      });

      const maxPositionLeft = calculateBlockConstraintPosition({
        xAxis: {
          blockPosition: block.position.left,
          blockDimension: block.position.width,
          parentDimension: parentDimensions.width,
          constraintDimension: finalConstraints.maxWidth,
        },
      });

      const maxPositionTop = calculateBlockConstraintPosition({
        yAxis: {
          blockPosition: block.position.top,
          blockDimension: block.position.height,
          parentDimension: parentDimensions.height,
          constraintDimension: finalConstraints.maxHeight,
        },
      });

      /**
       * Each case refers to which corner the user is pulling from and checks if the block has reached
       * the max / min width / height.
       * nw = northwest, ne = northeast, sw = southwest
       */
      switch (resizeCallbackData.handle) {
        // Check the width and height for this case since top and left are changing
        case 'nw': {
          if (size.width <= finalConstraints.minWidth) {
            newOptions.left = minPositionLeft;
            blockNode.style.left = minPositionLeft;
          } else if (size.width >= finalConstraints.maxWidth) {
            newOptions.left = maxPositionLeft;
            blockNode.style.left = maxPositionLeft;
          } else {
            newOptions.left = blockLeft;
            blockNode.style.left = blockLeft;
          }

          if (size.height <= finalConstraints.minHeight) {
            newOptions.top = minPositionTop;
            blockNode.style.top = minPositionTop;
          } else if (size.height >= finalConstraints.maxHeight) {
            newOptions.top = maxPositionTop;
            blockNode.style.top = maxPositionTop;
          } else {
            newOptions.top = blockTop;
            blockNode.style.top = blockTop;
          }
          break;
        }

        // Check only width for this case because only left is changing
        case 'w':
        case 'sw': {
          if (size.width <= finalConstraints.minWidth) {
            newOptions.left = minPositionLeft;
            blockNode.style.left = minPositionLeft;
          } else if (size.width >= finalConstraints.maxWidth) {
            newOptions.left = maxPositionLeft;
            blockNode.style.left = maxPositionLeft;
          } else {
            newOptions.left = blockLeft;
            blockNode.style.left = blockLeft;
          }
          break;
        }

        // Check only height for this case because only top is changing
        case 'ne':
        case 'n': {
          if (size.height <= finalConstraints.minHeight) {
            newOptions.top = minPositionTop;
            blockNode.style.top = minPositionTop;
          } else if (size.height >= finalConstraints.maxHeight) {
            newOptions.top = maxPositionTop;
            blockNode.style.top = maxPositionTop;
          } else {
            newOptions.top = blockTop;
            blockNode.style.top = blockTop;
          }
          break;
        }
        default: {
          break;
        }
      }

      if (isResizing) {
        onResizingThrottled(true, newOptions);
      } else {
        isShapeBlock(block.blockId) ||
          checkResizeOutOfBounds(
            event,
            containerBounds,
            nodeDimensions,
            newOptions,
            blockNode,
            maxPositionLeft,
            maxPositionTop,
            resizeCallbackData
          );

        updateSlideBlockDimensions(newOptions);

        onResizing(false, null);
      }
    },
    [
      finalConstraints,
      block,
      updateSlideBlockDimensions,
      slideContainerRef,
      onResizingThrottled,
      onResizing,
    ]
  );

  const minConstraints = useMemo(
    () => [finalConstraints.minWidth, finalConstraints.minHeight],
    [finalConstraints.minWidth, finalConstraints.minHeight]
  );

  const maxConstraints = useMemo(
    () => [finalConstraints.maxWidth, finalConstraints.maxHeight],
    [finalConstraints.maxWidth, finalConstraints.maxHeight]
  );

  if (!block.blockId) {
    return null;
  }

  const isDraggingEnabled =
    block.details?.defaultPosition?.allowReposition &&
    isAdvancedAuthoringModeEnabled;
  const isResizingEnabled =
    block.details?.defaultSize?.allowResize && isAdvancedAuthoringModeEnabled;

  const isActive =
    block.blockInstanceId === selectedSlideBlock?.blockInstanceId;

  const handleCopyBlock = async () => {
    await handleCopy();
    handleCloseContextMenu();
  };
  const handlePasteBlock = async ({ notify = true } = {}) => {
    const clipboard = await getFromClipboard();
    if (clipboard?.blockId) {
      await pasteBlock(clipboard);
      notify && notifyUser(`${block.details.title} block pasted.`);
    }
    handleCloseContextMenu();
  };
  const handleDuplicateBlock = async () => {
    await handleCopy({ notify: false });
    await handlePasteBlock({ notify: false });

    notifyUser(`${block.details.title} block duplicated.`);

    handleCloseContextMenu();
  };
  const handleRemoveBlock = async () => {
    await removeSelectedSlideBlock();
    handleCloseContextMenu();
  };

  const handleMove = async (orderZ) => {
    handleCloseContextMenu();

    await updateSlideBlock(
      { orderZ },
      undefined,
      undefined,
      undefined,
      true,
      () => {
        const slideBlocksCopy = [...slideBlocks];

        slideBlocksSwap({
          blockInstanceId: selectedSlideBlock.blockInstanceId,
          slideBlocks: slideBlocksCopy,
          direction: orderZ,
        });

        dispatchTd({
          type: MATA.UPDATE_SCENE_BLOCKS,
          payload: slideBlocksCopy,
        });
      }
    );
  };

  return (
    <Draggable
      bounds={isShapeBlock(block.blockId) || '#' + SLIDE_CONTAINER_ID}
      nodeRef={draggableNodeRef}
      onDrag={handleStartDrag}
      onStop={handleFinishDrag}
      position={initialBlockPosition}
      handle={`.${draggableHandleClassName}`}
      defaultClassName={
        isActive ? 'react-draggable' : 'react-draggable-inactive'
      }
      disabled={!isDraggingEnabled}
    >
      <SlideBlockPositionDiv
        ref={draggableNodeRef}
        active={isActive}
        top={block.position.top}
        left={block.position.left}
        resizable={isResizingEnabled}
        onContextMenu={(event) => {
          if (!currentSeries.settings.allowAdvancedAuthoring) {
            return;
          }
          event.preventDefault();
          selectSlideBlock(block.blockInstanceId);
          handleOpenContextMenu();
        }}
      >
        <ResizableBox
          height={sizeToNumber(block.position.height) ?? 0}
          width={sizeToNumber(block.position.width) ?? 0}
          resizeHandles={isActive ? resizeHandles : EMPTY_LIST}
          onResizeStop={handleFinishResize}
          onResize={handleFinishResize}
          maxConstraints={maxConstraints}
          minConstraints={minConstraints}
          style={resizableBoxStyles}
          axis={isResizingEnabled ? 'both' : 'none'}
        >
          <SlideBlockLayoutClickable
            isDraggable={isDraggingEnabled}
            onClick={isDragging ? handleStopDrag : handleClickBlock}
            active={isActive}
            editable={isEditableBlock}
            className={`${draggableHandleClassName} slide-block-draggable-container`}
            blockId={block.blockId}
          />
          {isContextMenuOpen && (
            <ContextMenu onClose={handleCloseContextMenu} className="w-auto">
              <AuthoringContextMenuStandardActions
                handleCopy={handleCopyBlock}
                handlePaste={handlePasteBlock}
                handleDuplicate={handleDuplicateBlock}
                handleRemove={handleRemoveBlock}
              />
              <Divider />
              <AuthoringContextMenuBlockActions
                handleMoveToTop={() => handleMove('top')}
                handleMoveForward={() => handleMove('forwards')}
                handleMoveBackward={() => handleMove('backwards')}
                handleMoveToBottom={() => handleMove('bottom')}
              />
            </ContextMenu>
          )}
        </ResizableBox>
      </SlideBlockPositionDiv>
    </Draggable>
  );
};

const resizableBoxStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
