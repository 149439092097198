import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SUBSCRIBE_VIDEO, UNSUBSCRIBE_VIDEO } from '../reducers/callState';

export const useDailyVideo = (trackStates, videoQuality) => {
  const dispatch = useDispatch();
  const videoElement = useRef(null);
  const { activeSpeaker } = useSelector((state) => state.callState);

  const isLocal = trackStates?.local;
  const sessionId = trackStates?.sessionId;
  const isFake = trackStates?.isFake;
  const isActiveSpeaker =
    activeSpeaker && activeSpeaker.sessionId === sessionId;
  const staticImage = trackStates?.image;
  const videoTrackState = trackStates?.videoTrackState?.state;

  useEffect(() => {
    if (isFake || isLocal || !sessionId) return;
    dispatch({
      type: SUBSCRIBE_VIDEO,
      sessionId: sessionId,
      videoQuality: videoQuality,
    });
    return () => {
      dispatch({
        type: UNSUBSCRIBE_VIDEO,
        sessionId: sessionId,
        videoQuality: videoQuality,
      });
    };
  }, [isFake, isLocal, sessionId, dispatch, videoQuality]);

  const videoTrack = useMemo(() => {
    return trackStates && videoTrackState === 'playable'
      ? trackStates.videoTrackState.track
      : null;
  }, [trackStates, videoTrackState]);

  useEffect(() => {
    if (!videoElement.current) return;
    const element = videoElement.current;
    element.controls = false;
    element.autoplay = false;
    element.poster = null;

    if (isFake) {
      element.srcObject = null;
      if (staticImage) {
        element.poster = staticImage;
      } else {
        element.src = videoTrack;
        element.loop = true;
        element.muted = true;
        element.autoplay = true;
      }
    } else {
      element.src = null;
      element.autoplay = true;
      element.srcObject = new MediaStream([videoTrack]);
    }
  }, [videoTrack, videoElement, isFake, staticImage]);

  return { videoElement, videoTrack, isActiveSpeaker };
};
