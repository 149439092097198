import { makeRandomId } from '../../helper';
import { applyFunctionToMap } from '../../helper/css';
import { overrideDynamicValue } from 'zync-common/helper/overrideDynamicValue';

/** A helper function that converts block position settings to decimals (e.g. 0.5 from 50%). */
export const calculateBlockPositionFractions = (settings, sceneDimensions) => {
  const convertedFractions = applyFunctionToMap(
    settings,
    (value, key) => {
      const str = `${value}`;
      if (str.includes('%')) {
        return parseFloat(value) / 100;
      } else if (str.includes('px')) {
        return (
          parseFloat(str) /
          (key === 'top' ? sceneDimensions.height : sceneDimensions.width)
        );
      } else {
        return parseFloat(str) / 100 ?? 0;
      }
    },
    ['top', 'left', 'height', 'width']
  );

  // defaults in the case that one or more properties are omitted.
  const fractions = {
    top: 0,
    left: 0,
    height: 0.1,
    width: 0.1,
    ...convertedFractions,
  };

  return fractions;
};

/**
 * Convert block settings (percentages) to pixel coordinates.
 */
export const calculateBlockPosition = (
  blockSettings,
  sceneDimensions,
  precalculatedFractions = null
) => {
  // calculate the block position within the scene in pixels.
  const fractions =
    precalculatedFractions ??
    calculateBlockPositionFractions(blockSettings, sceneDimensions);

  const position = {
    top: fractions.top * sceneDimensions.height,
    height: fractions.height * sceneDimensions.height,
    left: fractions.left * sceneDimensions.width,
    width: fractions.width * sceneDimensions.width,
  };

  return position;
};

/** Processes a bulk operation on @see enrichBlockPropertiesForAuthoring */
export const enrichBlockListForAuthoring = (
  blocks,
  sceneDimensions,
  containerDimensions,
  blockTemplates,
  brandKit
) => {
  return (blocks ?? [])
    .map((block) => {
      const template = blockTemplates
        ? Array.isArray(blockTemplates)
          ? blockTemplates.find(
              (blockSpec) => blockSpec.blockId === block.blockId
            )
          : blockTemplates[block.blockId]
        : null;

      if (!template) {
        return null;
      }

      const enriched = enrichBlockPropertiesForAuthoring(
        block,
        sceneDimensions,
        containerDimensions,
        template,
        brandKit
      );
      return enriched;
    })
    .filter((block) => block);
};

/**
 * Adds properties to the basic block instance object from the database.
 * Does not mutate the original object.
 */
export const enrichBlockPropertiesForAuthoring = (
  /** The block instance object from the meeting series. */
  block,
  /** The dimensions of the scene (usually @see standardScreenSize ). */
  sceneDimensions,
  /** The measured dimensions of the scene container element. */
  containerDimensions,
  /** The block specification from blockList.js */
  blockSpec,
  brandKit
) => {
  if (!blockSpec) {
    return null;
  }

  let brandKitSettings = {};

  if (brandKit) {
    for (const key in block.settings) {
      brandKitSettings[key] = overrideDynamicValue(
        block.settings[key],
        brandKit
      );
    }
  }
  // convert block settings to fractions.
  const fractions = calculateBlockPositionFractions(
    block.settings,
    sceneDimensions
  );

  // calculate the block position within the scene in pixels.
  const position = calculateBlockPosition(null, sceneDimensions, fractions);

  // calculate the block position within the non-scaled container,
  // to position the editing controls.
  const editPosition = containerDimensions
    ? calculateBlockPosition(null, containerDimensions, fractions)
    : block.settings;

  // return the block and relevant sections of the block spec from blockList.js
  return {
    ...block,
    blockInstanceId:
      block.blockInstanceId ?? `${block.blockId}-${makeRandomId(6)}`,
    position,
    editPosition,
    spec: blockSpec,
    details: blockSpec.details,
    code: blockSpec.code,
    settings: brandKit ? brandKitSettings : block.settings,
  };
};

export const checkIsPreview = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return urlSearchParams.has('preview');
};

/* A helper for fetching sceneId from the URL */
export const getSceneIdFromUrl = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const sceneId = urlSearchParams.get('sceneId');

  return sceneId;
};

/* A helper for updating sceneId in URL without reloading page */
export const updateSceneIdForUrl = (newValue) => {
  const params = new URLSearchParams(window.location.search);

  params.set('sceneId', newValue);

  window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
};
