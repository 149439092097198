import { useDailyControls } from './useDailyControls';
import { toast } from 'react-toastify';
import { displayMuteReason, micRelatedToastProps } from '../helper/audio';

export const useMuteUser = () => {
  const { localMicOn, toggleMic } = useDailyControls();

  const handleMuteUser = (muteReason) => {
    // Do nothing if the user is already muted.
    if (!localMicOn) return;

    // Mute the user.
    toggleMic(false);

    // If there an associated reason for the mute, display it in a toast.
    const message = displayMuteReason(muteReason);
    if (message) {
      toast(message, {
        ...micRelatedToastProps,
        toastId: muteReason,
      });
    }
  };

  return {
    handleMuteUser,
  };
};
