import React, { useRef, useState } from 'react';
import { useClickOutside } from '../../../helper/useClickOutside';
import { FaChevronDown } from 'react-icons/fa';
import classNames from '../../../helper/classNames';
import { positions } from './ToolbarAutoPosition';

const menuPositionClassNames = {
  [positions.TOP]: 'top-full translate-y-8',
  [positions.CENTER]: 'top-full translate-y-8',
  [positions.RIGHT]: 'translate-y-4 left-1/2 -translate-x-1/2',
  [positions.LEFT]: 'translate-y-4 left-1/2 -translate-x-1/2',
  [positions.BOTTOM]: 'bottom-full -translate-y-8 ',
};

const DropdownTrigger = ({ children, value, initialValue, menuPosition }) => {
  const containerRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = (event) => {
    event.preventDefault();
    setIsOpen((wasOpen) => !wasOpen);
  };

  useClickOutside(containerRef, () => setIsOpen(false));

  const isPlacedHorizontally =
    menuPosition === positions.RIGHT || menuPosition === positions.LEFT;

  const chevronRotationClassName = isOpen ? 'rotate-180' : 'rotate-0';
  const chevronColor = value ? '#fff' : '#696F8C';

  return (
    <div ref={containerRef} className="relative max-w-full">
      <button
        onMouseDown={toggleOpen}
        className={classNames(
          'flex gap-2 items-center justify-center py-1 px-2 rounded-md w-full',
          value ? 'bg-purple text-white' : 'text-blue-gray'
        )}
      >
        <span className="block text-ellipsis whitespace-nowrap overflow-hidden">
          {value || initialValue}
        </span>

        {isPlacedHorizontally ? null : (
          <div
            className={classNames(
              'transition-transform',
              chevronRotationClassName
            )}
          >
            <FaChevronDown size={14} color={chevronColor} />
          </div>
        )}
      </button>
      {isOpen && (
        <div
          className={classNames(
            'absolute bg-white flex flex-col max-h-44 overflow-y-auto animate-[fadeIn_300ms] shadow-700 rounded-lg z-10',
            menuPositionClassNames[menuPosition]
          )}
        >
          {children}
        </div>
      )}
    </div>
  );
};

const getOptionColor = (selected, option) =>
  selected.value === option.value ? 'bg-purple text-white' : 'text-blue-gray';

/* Reusable component rendering a dropdown in RichTextToolbar. Here we divide the component by trigger (a button opening options list) and options list that is hidden by default */
const ToolbarDropdown = ({
  options,
  onSelect,
  selected = {},
  initialSelected,
  menuPosition,
  prefix,
}) => {
  const handleClick = (style) => (event) => {
    event.preventDefault();
    onSelect(style, prefix);
  };

  return (
    <DropdownTrigger
      value={selected.label}
      initialValue={initialSelected.label}
      menuPosition={menuPosition}
    >
      {options.map((option) => (
        <button
          key={option.value}
          onMouseDown={handleClick(option.value)}
          className={classNames(
            'block text-left font-medium px-4 py-2',
            getOptionColor(selected, option)
          )}
          type="button"
        >
          {option.label}
        </button>
      ))}
    </DropdownTrigger>
  );
};

export default ToolbarDropdown;
