import React, { useEffect, useState } from 'react';
import { ScenePreview } from './authoring/Menu/ScenePreview';
import { fetchSceneTemplateApi } from '../helper/api';
import { DynamicBlocks } from '../blocks/helper/dynamicBlockVariables';

export const PreviewSlide = ({
  sceneTemplateId,
  brandKit,
  width,
  height,
  label,
  show,
  avatarUrl,
  hostedBy,
  hostName,
  hostBio,
}) => {
  const [posterSlideTemplate, setPosterSlideTemplate] = useState(null);

  useEffect(() => {
    if (!sceneTemplateId) {
      return;
    }
    fetchSceneTemplateApi(sceneTemplateId).then((result) => {
      setPosterSlideTemplate(result);
    });
  }, [sceneTemplateId]);

  const dynamicBlocks = new DynamicBlocks(posterSlideTemplate?.blocks || []);

  dynamicBlocks.replaceWith('{cta_text}', brandKit?.ctaText);
  dynamicBlocks.replaceWith('{{backdrop_image}}', brandKit?.backdrop_image);
  dynamicBlocks.replaceWith('{show_name}', show?.name);
  dynamicBlocks.replaceWith('{hosted_by_text}', hostedBy);
  dynamicBlocks.replaceWith('{hosted_by_avatar}', avatarUrl);
  dynamicBlocks.replaceWith('{host_name}', hostName);
  dynamicBlocks.replaceWith('{host_bio}', hostBio);

  return posterSlideTemplate ? (
    <div>
      {label ? (
        <span className="text-sm text-blue-dark font-medium mb-1">{label}</span>
      ) : null}
      <div className="flex-shrink-0 rounded-md overflow-hidden">
        <ScenePreview
          scene={{
            ...posterSlideTemplate,
            blocks: dynamicBlocks.getAll(),
          }}
          width={width}
          height={height}
          brandKit={{ ...brandKit }}
        />
      </div>
    </div>
  ) : null;
};
