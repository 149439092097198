import React from 'react';
import { LoadingSpinner } from '../../../LoadingSpinner';

import { Recording } from '../../../Summary';

import useMeetingRecordings from './useMeetingRecordings';

const CurrentMeetingRecordings = ({ meetingId, userId }) => {
  const { recordings, hasRecordings } = useMeetingRecordings();

  const recentRecording = recordings[recordings.length - 1];

  return hasRecordings ? (
    <div className="my-2 rounded-md">
      {recentRecording && (
        <Recording
          recording={recentRecording}
          meetingId={meetingId}
          userId={userId}
        />
      )}
      {!recentRecording && (
        <div className="grid place-content-center">
          <LoadingSpinner />
        </div>
      )}
    </div>
  ) : null;
};

export default CurrentMeetingRecordings;
