import classNames from '../helper/classNames';
import { SearchIcon } from './icons/SearchIcon';
import { TextInput } from './Input';

/**
 * A search bar with an 'X' button to clear the search value.
 *
 * Accepts the search state and a setter to update that search state.
 */
const searchInputStyle = { paddingLeft: '2.2rem', paddingRight: '2rem' };

export const SearchBar = ({ searchValue, setSearchValue, placeholder }) => {
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };
  return (
    <div className="relative w-full h-min">
      <TextInput
        style={searchInputStyle}
        value={searchValue}
        placeholder={placeholder || 'Search'}
        onChange={handleSearchChange}
      />
      <div className="absolute top-1/2 left-4 -translate-y-1/2">
        <SearchIcon />
      </div>

      {/* 'X' button to clear the search bar */}
      <button
        onClick={() => setSearchValue('')}
        className={classNames(
          'absolute top-1/2 -translate-y-1/2 right-2 cursor-pointer text-xs font-bold text-blue-gray px-2 py-1 hover:bg-gray hover:bg-opacity-40 rounded-full duration-100',
          searchValue ? 'block' : 'hidden'
        )}
      >
        &#10005;
      </button>
    </div>
  );
};
