import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { logerror } from '../helper/contextualLogger';

const TIME_BEFORE_CANCEL = 8_000;

const notifyUser = (message) =>
  toast(message, {
    position: toast.POSITION.BOTTOM_CENTER,
    autoClose: 5_000,
    type: toast.TYPE.ERROR,
  });
/* A simple hook for enriching onClick handlers with isLoading management - good for immediate action pending on various events  */
export const useStartLoading = (
  onClick,
  shouldResetLoading,
  waitUntil = TIME_BEFORE_CANCEL,
  actionName
) => {
  const userId = useSelector((_st) => _st.auth.user?.userId);
  const [isLoading, setIsLoading] = useState(false);

  /* If component is not unmount after onClick, we can reset loading state by passing shouldResetLoading prop */
  useEffect(() => {
    setIsLoading(false);
  }, [shouldResetLoading]);

  /* If a request or any async action takes too long, we want to cancel the loading state and display an error notification to the user. */
  useEffect(() => {
    const timeout = isLoading
      ? setTimeout(() => {
          if (isLoading) {
            logerror({
              message: `${userId} encountered problem with the last event action ${
                actionName ? `action: ${actionName}` : null
              }`,
            });
            setIsLoading(false);
            notifyUser(
              'We encountered a problem with your last action. Please try again.'
            );
          }
        }, waitUntil)
      : null;

    if (!isLoading) {
      clearTimeout(timeout);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [actionName, isLoading, waitUntil, userId]);

  const disableLoading = useCallback(() => {
    setIsLoading(false);
  }, [setIsLoading]);

  const startLoading = useCallback(() => {
    setIsLoading(true);
  }, [setIsLoading]);

  const onClickWithLoading = useCallback(
    (...args) => {
      setIsLoading(true);
      onClick(...args);
    },
    [onClick]
  );

  return { isLoading, onClickWithLoading, disableLoading, startLoading };
};
