import styled from 'styled-components';
import { isMobile } from '../../helper';

export const FloatingWindow = styled.div`
  box-shadow: ${({ theme }) => theme.shadows['800']};
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.spacing(isMobile ? 0.5 : 2.5)}px;
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;

  * {
    box-sizing: border-box;
  }
`;

export const FloatingWindowContainer = styled.div`
  --top-offset: ${({ theme }) => theme.spacing(11)}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: -224px;
  padding-left: ${({ theme }) => theme.spacing(7)}px;
  padding-right: ${({ theme }) => theme.spacing(7)}px;
  min-height: calc(100% - var(--top-offset));
`;

export const FloatingWindowBorder = styled.div`
  padding: ${({ theme }) => theme.spacing(6)}px;
  border-left: 15px solid #8b33f7;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing(2.5)}px;
`;
