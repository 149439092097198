import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UserAudio } from './UserAudio';

export const AudioOutput = () => {
  const callState = useSelector((_st) => _st.callState);
  const {
    tracks: {
      allUsers: userIdToTrackStates,
      mainScreenShare: screenShareTrackStates,
    },
    userIdsAudioFilter,
    outputDeviceId,
  } = callState;

  const openAudioStreams = useMemo(
    () =>
      Object.entries(userIdToTrackStates ?? {})
        .filter(([userId, trackStates]) => trackStates)
        .map(([userId, trackStates]) => ({
          userId,
          trackStates,
          muted:
            userIdsAudioFilter !== null && !userIdsAudioFilter.includes(userId),
        }))
        .filter((entry) => !entry.muted),
    [userIdToTrackStates, userIdsAudioFilter]
  );

  return (
    <>
      {openAudioStreams.map(({ userId, trackStates }) => (
        <UserAudio
          key={userId}
          trackStates={trackStates}
          outputDeviceId={outputDeviceId}
        />
      ))}
      {screenShareTrackStates && (
        <UserAudio
          trackStates={screenShareTrackStates}
          outputDeviceId={outputDeviceId}
        />
      )}
    </>
  );
};
