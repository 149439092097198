import React from 'react';
import styled, { css } from 'styled-components';
import { absolutePosition } from '../../helper/css';
import { H5Text, SecondaryText } from './Text';
import { sizeToString } from '../../helper/css';

export const outerContainerBoxShadow = css`
  box-shadow: 0 8px 28px -6px rgba(24, 39, 75, 0.12),
    0 18px 88px -4px rgba(24, 39, 75, 0.14);
`;

export const outerContainerTheme = css`
  padding: 24px;
  box-sizing: border-box;
  border-radius: 16px;
  background: ${({ theme }) => theme.palette.background.default};
`;

/**
 * An absolutely positioned white outer container with
 * rounded corners, padding, and a drop shadow. Adds
 * a standard margin between all elements stacked vertically.
 */
export const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;

  ${(props) => absolutePosition(props.position)}

  ${outerContainerBoxShadow}
  ${outerContainerTheme}
`;

/** Overall container for the ThumbnailGrid component. */
export const ThumbnailGridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 20px;
`;

/** Item container for the ThumbnailGrid component. */
export const ThumbnailGridItemContainer = styled.div`
  width: ${(props) => sizeToString(props.width ?? 100)};

  cursor: pointer;

  display: flex;
  flex-direction: column;

  > ${() => H5Text} {
  }

  > ${() => SecondaryText} {
    margin-top: 4px;
  }
`;

/**
 * An organizational container which displays a grid of `items` where each
 * item has either `content` or `image` and an optional `title`. A `onClick` action can be specified for
 * each item. A `width` and `height` prop can be specified optionally. The `height` corresponds to the height
 * of the thumbnail image, not of the overall thumbnail.
 *
 * @example
 * <ThumbnailGrid
 *   items={[
 *     { id: 'unique-id-1', title: 'Theme 1', image: image1, onClick: () => {...} },
 *     { id: 'unique-id-2', title: 'Theme 2', image: image2, onClick: () => {...} },
 *     { id: 'unique-id-3', title: 'Theme 3', image: image3, onClick: () => {...} },
 *     { id: 'unique-id-4', title: 'Theme 4', content: <p>Orange-Red Gradient</p>, onClick: () => {...} },
 *     { id: 'unique-id-5', title: 'Theme 5', content: "A string.", onClick: () => {...} },
 *   ]}
 * />
 */
export const ThumbnailGrid = ({
  /** An array of items, each with { id, title, content | image | explanation, onClick } */
  items,
  /** The width of the thumbnail image and overall item container. */
  width = 100,
  /** The height of the thumbnail image. */
  height = 80,
  /**
   * Whether to expand the image to completely fill the width/height specification.
   * If false, fits entire image within the bounds. If true, fills the entire bounds, maintaining image aspect ratio.
   */
  fill = true,

  /** The container component to extend the grid for other purposes. */
  GridContainerComponent = ThumbnailGridContainer,
  HeaderComponent = H5Text,
}) => (
  <GridContainerComponent>
    {items.map(
      ({
        id,
        title,
        subTitle,
        image,
        content,
        imageContent,
        explanation,
        onClick,
      }) => (
        <ThumbnailGridItemContainer
          width={width}
          onClick={onClick}
          key={id || title || image}
        >
          {title && <HeaderComponent>{title}</HeaderComponent>}
          {image && (
            <img
              width={width}
              height={height}
              src={image}
              className="object-cover rounded"
              alt=""
            />
          )}
          {imageContent}
          <div className="bottom-area">
            {subTitle && <HeaderComponent>{subTitle}</HeaderComponent>}
            {content}
            {explanation && <SecondaryText>{explanation}</SecondaryText>}
          </div>
        </ThumbnailGridItemContainer>
      )
    )}
  </GridContainerComponent>
);
