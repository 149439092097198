import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { animated } from 'react-spring';
import styled from 'styled-components';
import { baseButtonStyles } from '../../theme/baseStyles';
import { OpenHamburgerIcon } from '../icons/OpenHamburgerIcon';
import { CloseHamburgerIcon } from '../icons/CloseHamburgerIcon';

const Container = styled(animated.div)`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 2rem;
  right: ${({ showChat }) => (showChat ? 'calc(25% + 1rem)' : '1rem')};
  border-radius: 50px;
  z-index: 100;
`;

const IconWrapper = styled.div`
  display: flex;
`;

const CircleButton = styled(animated.button)`
  ${baseButtonStyles};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size || 50}px;
  height: ${({ size }) => size || 50}px;
  background-color: ${({ backgroundColor = 'transparent' }) => backgroundColor};
  font-size: 1.25rem;
  opacity: ${({ isOff }) => (isOff ? 0.5 : 1)};
  position: relative;

  svg {
    z-index: 1;
  }
`;

const OptionsWrapper = styled(animated.div)`
  display: flex;
  align-items: center;
  overflow-x: hidden;
`;

export const MobilePlayerOptionsMenu = ({ buttonsConfig, showChat }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen((wasOpen) => !wasOpen);
  };

  return (
    <Container className="bg-purple" showChat={showChat}>
      <OptionsWrapper>
        <IconWrapper>
          <CircleButton
            backgroundColor={isOpen ? '#a155ff' : '#8B33F7'}
            onTouchStart={!isOpen || !showChat ? handleToggle : () => {}}
            size={60}
            style={{
              borderRadius: isOpen ? '50px 0 0 50px' : '50px',
            }}
          >
            {isOpen ? <CloseHamburgerIcon /> : <OpenHamburgerIcon />}
          </CircleButton>
        </IconWrapper>
        {isOpen
          ? buttonsConfig.map(
              (
                {
                  onTouchHandler,
                  iconName,
                  icon,
                  isOff,
                  backgroundColor,
                  disabled,
                  label,
                  ...props
                },
                i
              ) => (
                <CircleButton
                  key={i}
                  onTouchStart={onTouchHandler}
                  backgroundColor={backgroundColor}
                  disabled={!!disabled}
                  size={60}
                  style={{ width: '60px', height: '60px' }}
                >
                  <div className="flex flex-col items-center justify-center w-[60px] h-[60px]">
                    {icon || (
                      <FontAwesomeIcon size={20} color="#fff" icon={iconName} />
                    )}
                    <p className="text-white text-xxs font-medium">{label}</p>
                  </div>
                </CircleButton>
              )
            )
          : null}
      </OptionsWrapper>
    </Container>
  );
};
