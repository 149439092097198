import { useCallback, useState } from 'react';
import { debounce } from 'lodash/function';
import { updateSeriesApi } from '../../../helper/api';
import { fromError, logerror } from '../../../helper/contextualLogger';

export const useChangeModeratorCanScreenshare = (series, localDispatch) => {
  const [moderatorsCanScreenShareOnly, setModeratorsCanScreenShareOnly] =
    useState(series.settings.moderatorsCanScreenShareOnly);

  const meetingSeriesId = series?.meetingSeriesId;

  const onChangeModeratorCanScreenshareOnly = useCallback(
    async (newValue) => {
      try {
        const result = await updateSeriesApi(meetingSeriesId, {
          settings: {
            moderatorsCanScreenShareOnly: newValue,
          },
        });
        if (!result) return;

        setModeratorsCanScreenShareOnly(
          result.settings.moderatorsCanScreenShareOnly
        );
        localDispatch({ type: 'REFRESH_SERIES', series: result });
      } catch (error) {
        logerror(fromError(error));
      }
    },
    [localDispatch, meetingSeriesId]
  );

  const handleChangeModeratorCanScreenshareOnly = debounce(
    onChangeModeratorCanScreenshareOnly,
    1_000
  );

  return {
    handleChangeModeratorCanScreenshareOnly,
    moderatorsCanScreenShareOnly,
  };
};
