import styled from 'styled-components';

const Button = styled.button`
  background-color: rgba(0, 163, 165, 1);
  margin-top: 16px;
  padding: 10px 14px;
  font-size: 14px;
  font-weight: 600;
  color: white;
  border-radius: 3px;
  border: 0;
  margin: 10px;
  cursor: pointer;

  &:active {
    transform: translateY(2px);
  }
`;

const Input = styled.input`
  margin-bottom: 10px;
  padding: 10px 20px;
  font-size: 16px;
  font-family: Avenir;
  font-weight: 300;
  height: 30px;
`;

const TextArea = styled.textarea`
  margin-bottom: 10px;
  padding: 10px 20px;
  font-size: 16px;
  font-family: Avenir;
  font-weight: 300;
  width: 400px;
`;

const Page = styled.div`
  float: none !important;
  display: grid;
  height: 100%;
  position: relative;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'body'
    'footer';
`;

const Card = styled.div`
  background: #ffffff;
  border: 1px solid #f4f4f4;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(102, 102, 102, 0.25);
  border-radius: 8px;
  padding: 12px;
  margin: 24px 0px;
`;

const Tier = styled.div`
  display: inline-block;
  border: 1px solid #00a3a5;
  text-transform: uppercase;
  font-size: 13px;
  padding: 2px;
  line-height: 14px;
  color: #00a3a5;
  font-weight: 600;
`;
export const AvatarIcon = styled.div`
  color: #fff;
  border-radius: 50%;
  background-color: #00a3a5;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  font-size: 14px;
  overflow: hidden;
`;

export { Button, Input, TextArea, Page, Tier, Card };
