import React from 'react';

export const PauseIcon = ({ color = '#696F8C', width = 14, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 14.5625V1.4375C4.49327 0.922547 4.07745 0.506732 3.5625 0.5H1.6875C1.17255 0.506732 0.756732 0.922547 0.75 1.4375V14.5625C0.756732 15.0775 1.17255 15.4933 1.6875 15.5H3.5625C4.07745 15.4933 4.49327 15.0775 4.5 14.5625ZM10.4375 0.5H12.3125C12.8275 0.506732 13.2433 0.922547 13.25 1.4375V14.5625C13.2433 15.0775 12.8275 15.4933 12.3125 15.5H10.4375C9.92255 15.4933 9.50673 15.0775 9.5 14.5625V1.4375C9.50673 0.922547 9.92255 0.506732 10.4375 0.5Z"
        fill={color}
      />
    </svg>
  );
};
