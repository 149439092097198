import React from 'react';
import { getStaticAssetUrl as url } from '../../helper/getStaticAssetUrl';

export const appendBgToAssetName = (bg, assetName) =>
  bg + '-' + assetName + '.svg';

export const Icon = ({ alt, iconRef, assetName, ...props }) => (
  <img
    style={{ color: 'white' }}
    alt={alt}
    src={url(assetName)}
    ref={iconRef}
    {...props}
  />
);
