import React from 'react';

export const JoystickIcon = ({
  width = '19px',
  height = '21px',
  className = 'fill-purple',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M9.49835 0.453125C7.28925 0.453125 5.49835 2.24403 5.49835 4.45313C5.49835 5.40043 5.88485 6.35003 6.48805 7.05853L6.49655 7.84683L1.02225 10.5791C0.735655 10.7611 0.498352 11.0921 0.498352 11.4531V15.4531C0.498352 15.8321 0.722052 16.1901 1.06085 16.3591L9.06035 20.3591C9.34235 20.5001 9.65436 20.5001 9.93636 20.3591L17.9364 16.3591C18.2744 16.1901 18.4984 15.8321 18.4984 15.4531V11.4531C18.4984 11.0921 18.2614 10.7211 17.9314 10.5581L12.4994 7.84103L12.4934 7.05753C13.1504 6.31003 13.4984 5.40043 13.4984 4.45313C13.4984 2.24403 11.7074 0.453125 9.49835 0.453125ZM9.49835 2.45313C10.6034 2.45313 11.4984 3.34853 11.4984 4.45313C11.4984 5.00063 11.2443 5.49513 10.8103 5.89063C10.6033 6.08013 10.4984 6.35933 10.4984 6.64063V9.45313C10.4984 10.0051 10.0504 10.4531 9.49835 10.4531C8.94635 10.4531 8.49835 10.0051 8.49835 9.45313V6.64063C8.49835 6.35933 8.39336 6.08013 8.18636 5.89063C7.75236 5.49513 7.49835 5.00063 7.49835 4.45313C7.49835 3.34853 8.39335 2.45313 9.49835 2.45313ZM6.56615 10.0461C6.84595 11.4131 8.04835 12.4531 9.49835 12.4531C10.9484 12.4531 12.1673 11.4311 12.4464 10.0641L15.2734 11.4601L9.48535 14.3401L3.72095 11.4641L6.56615 10.0461ZM2.49835 13.0781L9.06035 16.3591C9.34235 16.5001 9.65436 16.5001 9.93636 16.3591L16.4984 13.0781V14.8281L9.49835 18.3281L2.49835 14.8281V13.0781Z" />
    </svg>
  );
};
