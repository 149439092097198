import { isZyncStaff } from '../helper/isZyncStaff';
import { Page404 } from './page404';
import { useSelector } from 'react-redux';

export const AdminProtectedRoute = ({ children }) => {
  const userId = useSelector((st) => st.auth?.user?.userId);

  if (!isZyncStaff(userId)) {
    return <Page404 />;
  }

  return <>{children}</>;
};
