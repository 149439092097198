import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  fromRawContentState,
  getCustomStylesFromState,
  toRawContentState,
} from '../../../../components/richText/helpers';
import { EditorState } from 'draft-js';
import { debounce } from 'lodash';
import { useClickOutside } from '../../../../helper/useClickOutside';
import { DropdownSelect } from '../../../../components/common/Dropdown';
import {
  RichTextEditor,
  RichTextToolbar,
} from '../../../../components/richText';

export const TextSceneUpdate = ({
  posterSlideTemplate,
  setPosterSlideTemplate,
  setUpdate,
  onConfirm,
  onCancel,
  updateOnClose,
  series,
  hostNotesComponent,
}) => {
  const mainSpeakerBlock = posterSlideTemplate.blocks?.findLast(
    (block) => block.blockId === 'singlevideo'
  );

  const textBlock = posterSlideTemplate.blocks?.findLast(
    (block) => block.blockId === 'textbox'
  );
  const [editorState, setEditorState] = useState(() => {
    const contentState = fromRawContentState(textBlock.settings.text);

    const state = EditorState.moveFocusToEnd(
      EditorState.createWithContent(contentState)
    );
    getCustomStylesFromState(state, 'color');
    return state;
  });

  const handleTextBlockChange = useCallback(
    (settingsKey, value) => {
      setPosterSlideTemplate((template) => {
        const block = template.slideConfig.slideblocks?.findLast(
          (block) => block.blockId === 'textbox'
        );

        if (block) {
          block.settings[settingsKey] = value;
        }

        return template;
      });

      setUpdate((x) => x + 1);
    },
    [setPosterSlideTemplate, setUpdate]
  );

  const lastEditValueRef = useRef(
    toRawContentState(editorState.getCurrentContent())
  );

  const handleConfirm = useCallback(
    (shouldOpenNewlyCreated) =>
      onConfirm(posterSlideTemplate, undefined, shouldOpenNewlyCreated),
    [posterSlideTemplate, onConfirm]
  );

  const debouncedConfirm = debounce(handleConfirm, 1000);

  /* eslint-disable-next-line */
  const debouncedHandleChange = useCallback(
    debounce((editorState) => {
      const content = editorState.getCurrentContent();
      const text = toRawContentState(content);

      if (lastEditValueRef.current !== text) {
        lastEditValueRef.current = text;
        handleTextBlockChange('text', text);
        debouncedConfirm();
      }
    }, 1000),
    [handleTextBlockChange]
  );

  const handleChange = useCallback(
    (editorState) => {
      setEditorState(editorState);
      debouncedHandleChange(editorState);
    },
    [debouncedHandleChange]
  );

  useClickOutside({ current: document.querySelector('.modal') }, () => {
    updateOnClose && handleConfirm(false);
    onCancel();
  });

  const handleMainSpeakerBlockChange = (settingsKey, value) => {
    setPosterSlideTemplate((template) => {
      if (mainSpeakerBlock) {
        mainSpeakerBlock.settings[settingsKey] = value;
      }

      return template;
    });

    setUpdate((x) => x + 1);
  };

  const eventPresenters = series.settings.eventPresenters;

  const font =
    series?.workspace?.brandKit?.font || textBlock.settings.fontFamily;
  const textEditorInitialValues = useMemo(
    () => ({
      fontFamily: font,
      fontSize: parseInt(textBlock.settings.fontSize),
      color: textBlock.settings.color,
    }),
    [textBlock.settings.color, font, textBlock.settings.fontSize]
  );

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="flex flex-col gap-3">
        <div>
          <h4 className="text-sm font-medium text-blue-dark mb-1">
            Choose a Presenter
          </h4>
          <DropdownSelect
            selectedKey={
              mainSpeakerBlock.settings.userId ||
              eventPresenters.find((ep) => ep.type === 'host')?.emailAddress
            }
            options={eventPresenters.map((ep) => ({
              key: ep.emailAddress,
              title: ep.fullName,
            }))}
            onSelect={(value) => {
              handleMainSpeakerBlockChange('userId', value);
              handleConfirm();
            }}
          />
        </div>
        <div className="relative">
          <h4 className="text-sm font-medium text-blue-dark mb-1">Text</h4>
          <div className="rounded-md bg-white border border-gray/50">
            <RichTextToolbar
              editorState={editorState}
              onChange={handleChange}
              initialValues={textEditorInitialValues}
              bestPosition={'top'}
              containerClassName={
                'flex gap-1 px-1 py-1 flex-wrap items-center rounded-full'
              }
            />
          </div>
          <div className="border border-gray/50 rounded-md mt-2 py-2">
            <RichTextEditor
              editorState={editorState}
              onChange={handleChange}
              spellCheck
            />
          </div>
        </div>
      </div>
      <div>{hostNotesComponent}</div>
    </div>
  );
};
