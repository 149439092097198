import { ConfirmationModal, Modal } from './Modal';
import { getStaticAssetUrl } from '../helper/getStaticAssetUrl';
import { Button } from './common/Button';
import React from 'react';

export const PermissionsDeniedModal = ({ onDismiss }) => {
  return (
    <ConfirmationModal
      title="Camera and microphone are blocked"
      text={
        <div className="flex flex-col gap-5 -mt-4">
          <span>
            We have not received permission from you to access your microphone
            and camera. Until you do so, other attendees will not be able to see
            or hear you. Here is a short clip on how you could grant access.
          </span>
          <img
            alt="Instructions to set camera and microphone devices"
            src={getStaticAssetUrl('camera-permissions-instructions.gif')}
          />
          <span>
            Once you follow these steps and reload page, you will be able to
            join the meeting with your devices.
          </span>
          <div className="flex flex-col gap-2">
            <span className="font-medium">For more help</span>
            <a
              className="text-blue-light hover:underline"
              href="https://www.zync.ai/troubleshooting-guide"
              target="_blank"
              rel="noreferrer"
            >
              Click here for more detailed troubleshooting instructions
            </a>
          </div>
        </div>
      }
      size={Modal.size.md}
      actionButtons={
        <div>
          <Button
            size={Button.sizes.FULL}
            color={Button.colors.PURPLE}
            onClick={onDismiss}
          >
            <span className="text-base font-bold">Dismiss</span>
          </Button>
        </div>
      }
      height={780}
      width={512}
      shrinkOnResize
    />
  );
};
