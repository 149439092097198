import React, { useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { createPortal } from 'react-dom';
import { CheckBox } from './CheckBox';
import { OuterContainer } from './Container';
import { CloseButton } from './CloseButton';
import { scrollbarCss } from '../../helper/css';
import { LoadingSpinnerAbsoluteFullCentered } from '../LoadingSpinner';
import { ActionMenu } from './ActionMenu';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { useSlideSize } from '../../hooks/useSlideSize';
import * as Icons from './Icon';
import { useKeyboardShortcuts } from 'use-keyboard-shortcuts';
import { Button } from '../common/Button';

import {
  TOP_BAR_HEIGHT_PX,
  BOTTOM_BAR_HEIGHT_PX,
} from '../authoring/constants';

/** ID of the DOM element used for modal dialogs. */
export const MODAL_ELEMENT_ID = 'modal-root';

/** A full screen transparent backdrop. */
export const ModalBackdrop = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  background: ${({ authoring }) =>
    authoring ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.2)'};
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`;

/** Positions the modal. */
export const ModalPosition = styled.div`
  position: relative;
  box-sizing: border-box;
  ${(props) =>
    props.wide
      ? css`
          width: calc(100vw - ${46 * 2 + 90 + 20}px);
          height: calc(100vh - ${134 * 2 + 20}px);
        `
      : css`
          max-width: 600px;
          width: ${props.width};
          height: 70vh;
          max-height: 400px;
        `}
  ${(props) =>
    props.autoHeight &&
    css`
      height: auto;
      max-height: unset;
    `}
  ${(props) =>
    props.authoring &&
    css`
      position: absolute;
      height: calc(100vh - ${BOTTOM_BAR_HEIGHT_PX}px - ${TOP_BAR_HEIGHT_PX}px);
      width: 100vw;
      top: ${TOP_BAR_HEIGHT_PX}px;
      left: 0;
      max-height: unset;
      max-width: unset;
    `}
`;

/** The modal container. */
export const ModalContainer = styled(OuterContainer)`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 0;
  overflow: hidden;
  overflow-y: auto;
  ${scrollbarCss(true, false)}

  ${(props) =>
    props.isLoading &&
    css`
      > * {
        opacity: 0.8;
      }
    `}
`;

const PositionedCloseButton = styled(CloseButton)`
  position: absolute;
  right: ${({ authoring }) => (authoring ? '14px' : '-46px')};
  top: ${({ authoring }) => (authoring ? '8px' : '-18px')};
  background: white;
`;

/**
 * A styled modal dialog with close button and full screen overlay.
 */
export const Modal = ({
  /** Handler for when the user clicks the close button. */
  onClose,
  /** Whether the modal is open or closed. */
  open,
  /** Children to be rendered. */
  children,
  /** Whether the modal is in a loading state. */
  loading,
  /** Whether the modal is a wide modal (near full screen) or not (limited height/width). */
  wide = false,
  /** Whether the height should fit the content. Defaults to false. */
  width = '70vw',
  autoHeight = false,
  /** Whether it should be positioned for authoring. */
  authoring = false,
  /** Whether to show close button. Defaults to true */
  showCloseButton = true,
  /** Custom style passed to modal position component */
  positionStyle,
  /** Custom style passed to modal container */
  containerStyle,
}) => {
  return createPortal(
    open && (
      <ModalBackdrop onClick={onClose}>
        <ModalPosition
          wide={wide}
          width={width}
          autoHeight={autoHeight}
          authoring={authoring}
          onClick={(event) => event.stopPropagation()}
          style={positionStyle}
        >
          {showCloseButton && (
            <PositionedCloseButton onClose={onClose} authoring={authoring} />
          )}
          {loading && <LoadingSpinnerAbsoluteFullCentered />}
          <ModalContainer isLoading={loading} style={containerStyle}>
            {children}
          </ModalContainer>
        </ModalPosition>
      </ModalBackdrop>
    ),
    document.getElementById(MODAL_ELEMENT_ID)
  );
};
/**
 * A styled modal dialog with close button and full screen overlay.
 */
export const ActionMenuModal = ({
  /** Handler for when the backdrop is clicked (a cancel action). */
  onClose,
  /** Whether the modal is open or closed. */
  open,
  /** Action menu title. */
  title,
  /** Action items in menu. An array of { action, text, icon }, as in ActionMenu. */
  actionItems,
}) => {
  const menuRef = useRef(null);
  useOnClickOutside(menuRef, open, onClose);

  const { height: slideHeight } = useSlideSize();

  return createPortal(
    <>
      {open && (
        <>
          <ModalBackdrop>
            <ActionMenu
              ref={menuRef}
              title={title}
              maxHeight={slideHeight - 50}
              actionItems={actionItems.map(({ action, text, icon }) => ({
                action: () => {
                  action && action();
                  onClose();
                },
                text,
                icon,
              }))}
            />
          </ModalBackdrop>
        </>
      )}
    </>,
    document.getElementById(MODAL_ELEMENT_ID)
  );
};

/** Confirmation modal with icon, title, descriptive text, and actions. */
export const ConfirmationModal = ({
  /** Handler for when the user clicks the close button. */
  onClose,
  /** Whether the modal is open or closed. */
  open,
  /** Validation handler. A synchronous function  */
  validationFunction,
  /** Title of modal in header. */
  title,
  /** Additional style for title - uses standard style interface */
  titleStyle,
  /** Optional. Icon for title in header. */
  icon,
  /** Text describing the action to be confirmed. */
  text,
  /** Additional style for text - uses standard style interface */
  textStyle,
  /** Handler for if the action is confirmed, returns a promise. */
  onConfirm,
  /** Optional label for the confirm button. */
  confirmLabel,
  /** Color theme for the icon and confirm button. */
  color,
  /** This is required to show "Do not show again" checkbox and when provided, it runs the provided callback */
  doNotShowAgainHandler,
  /** ReactNode. Custom submit button implementation. If provided, it replaces default submit button implementation. */
  modalActions,
  /** modal container width - accepts all css width values */
  width,
  /** whether to show close button. Defaults to true */
  showCloseButton = true,
  /** all other content */
  children,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isHiddenNextTime, setIsHiddenNextTime] = useState(false);

  const Icon = typeof icon === 'string' ? Icons[icon] : icon;
  const iconElement = icon ? <Icon /> : null;

  const handleConfirm = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const validationResult = validationFunction
        ? await validationFunction()
        : undefined;
      if (validationResult) {
        setError(validationResult);
      }

      if (doNotShowAgainHandler) {
        doNotShowAgainHandler(isHiddenNextTime);
      }

      await onConfirm();
    } finally {
      setLoading(false);
      onClose();
    }
  }, [
    onConfirm,
    setLoading,
    onClose,
    validationFunction,
    doNotShowAgainHandler,
    isHiddenNextTime,
  ]);

  useKeyboardShortcuts(
    [
      { keys: ['Enter'], onEvent: handleConfirm },
      { keys: ['Escape'], onEvent: onClose },
    ],
    open,
    [open, handleConfirm]
  );

  return (
    <Modal
      onClose={onClose}
      open={open}
      loading={loading}
      autoHeight
      width={width}
      showCloseButton={showCloseButton}
    >
      <div
        style={{ color: color }}
        className={`flex flex-col items-stretch gap-y-5`}
      >
        {title && (
          <div style={titleStyle} className={`flex space-x-2 items-center`}>
            <div>{iconElement}</div>
            <div>{title}</div>
          </div>
        )}
        {text && <div className={`text-sm text-blue-gray`}>{text}</div>}
        {doNotShowAgainHandler && (
          <CheckBox
            label="Don't show this again"
            onChange={(value) => setIsHiddenNextTime(value)}
            value={isHiddenNextTime}
          />
        )}
        {children}
        {error && (
          <div className={`text-red text-xs font-light mb-2`}>{error}</div>
        )}
        {modalActions || (
          <div className={`flex flex-row justify-end gap-4`}>
            <Button color={Button.colors.GRAY} onClick={onClose}>
              <span className="text-xs">Cancel</span>
            </Button>
            <Button onClick={handleConfirm} color={Button.colors.GRAY}>
              <span className="text-xs">{confirmLabel ?? 'Confirm'}</span>
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};
