import { useClickOutside } from '../../../../helper/useClickOutside';
import React, { useCallback } from 'react';
import { DropdownSelect } from '../../../../components/common/Dropdown';
import { BooleanInput, TextInput } from '../../../../components/Input';

export const VideoSceneUpdate = ({
  posterSlideTemplate,
  setPosterSlideTemplate,
  setUpdate,
  onConfirm,
  onCancel,
  updateOnClose,
  series,
  hostNotesComponent,
}) => {
  const mainSpeakerBlock = posterSlideTemplate.blocks?.findLast(
    (block) => block.blockId === 'singlevideo'
  );
  const videoBlock = posterSlideTemplate.blocks?.findLast(
    (block) => block.blockId === 'videoplayer'
  );

  const handleConfirm = (shouldOpenNewlyCreated) =>
    onConfirm(posterSlideTemplate, undefined, shouldOpenNewlyCreated);

  useClickOutside({ current: document.querySelector('.modal') }, () => {
    updateOnClose && handleConfirm(false);
    onCancel();
  });

  const handleMainSpeakerBlockChange = (settingsKey, value) => {
    setPosterSlideTemplate((template) => {
      if (mainSpeakerBlock) {
        mainSpeakerBlock.settings[settingsKey] = value;
      }

      return template;
    });

    setUpdate((x) => x + 1);
  };

  const handleVideoBlockChange = useCallback(
    (settingsKey, value) => {
      setPosterSlideTemplate((template) => {
        const block = template.blocks?.findLast(
          (block) => block.blockId === 'videoplayer'
        );

        if (block) {
          block.settings[settingsKey] = value;
        }

        return template;
      });

      setUpdate((x) => x + 1);
    },
    [setPosterSlideTemplate, setUpdate]
  );

  const eventPresenters = series.settings.eventPresenters;

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="flex flex-col gap-3">
        <div>
          <h4 className="text-sm font-medium text-blue-dark mb-1">
            Choose a Presenter
          </h4>
          <DropdownSelect
            selectedKey={
              mainSpeakerBlock.settings.userId ||
              eventPresenters.find((ep) => ep.type === 'host')?.emailAddress
            }
            options={eventPresenters.map((ep) => ({
              key: ep.emailAddress,
              title: ep.fullName,
            }))}
            onSelect={(value) => {
              handleMainSpeakerBlockChange('userId', value);
              handleConfirm();
            }}
          />
        </div>

        <div>
          <h4 className="text-sm font-medium text-blue-dark mb-1">Video URL</h4>
          <TextInput
            value={videoBlock.settings.videoUrl}
            onChange={(event, value) => {
              handleVideoBlockChange('videoUrl', value);
            }}
            className="w-full p-2 border border-gray rounded"
            placeholder="Enter Youtube Video URL here"
            onBlur={handleConfirm}
          />
        </div>
        <div className="flex gap-x-6 gap-y-2 flex-wrap">
          <div>
            <h4 className="text-sm font-medium text-blue-dark mb-1">
              Autoplay
            </h4>
            <BooleanInput
              defaultValue={videoBlock.settings.autoPlay}
              onChange={(value) => {
                handleVideoBlockChange('autoPlay', value);
                handleConfirm();
              }}
            />
          </div>
          <div>
            <h4 className="text-sm font-medium text-blue-dark mb-1">Muted</h4>
            <BooleanInput
              defaultValue={videoBlock.settings.muted}
              onChange={(value) => {
                handleVideoBlockChange('muted', value);
                handleConfirm();
              }}
            />
          </div>
          <div>
            <h4 className="text-sm font-medium text-blue-dark mb-1">
              Show Controls
            </h4>
            <BooleanInput
              defaultValue={videoBlock.settings.showControls}
              onChange={(value) => {
                handleVideoBlockChange('showControls', value);
                handleConfirm();
              }}
            />
          </div>
        </div>
      </div>
      <div>{hostNotesComponent}</div>
    </div>
  );
};
