const zyncRegistrationSources = {
  /** Zync Sign Up (Standard Zync Registration) */
  STANDARD_SIGN_UP: 'STANDARD_SIGN_UP',

  /** Meeting Attendee (Guest Join Flow) */
  MEETING_GUEST_ATTENDEE: 'MEETING_GUEST_ATTENDEE',

  /** Email For Highlights Prompt (Highlights Prompt at the end of the meeting) */
  EMAIL_FOR_HIGHLIGHT_PROMPT: 'EMAIL_FOR_HIGHLIGHT_PROMPT',

  /** Added As Moderator to a meeting (Someone added person as Moderator) */
  ADDED_AS_MODERATOR: 'ADDED_AS_MODERATOR',

  /** Added As Attendee to a meeting (Someone added person as an Attendee) */
  ADDED_AS_ATTENDEE: 'ADDED_AS_ATTENDEE',

  /** Added To Workspace (Someone added person to their Workspace) */
  ADDED_TO_WORKSPACE: 'ADDED_TO_WORKSPACE',

  /** EventRSVP (They RSVP-ed for an event) */
  EVENT_RSVP: 'EVENT_RSVP',

  /** Quick Start */
  QUICK_START: 'QUICK_START',
};

module.exports = zyncRegistrationSources;
