import React from 'react';

export const PersonProfileIcon = ({
  color = '#696F8C',
  width = '16',
  height = '16',
  className,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2283 14.4187C14.7953 13.4305 12.7059 12.7716 11.313 12.1599C9.92004 11.5481 10.1083 11.1716 10.0612 10.6634C10.0518 10.5975 10.0518 10.5222 10.0518 10.4469C10.5318 10.0234 10.9177 9.43988 11.1718 8.77164C11.1718 8.77164 11.1812 8.73399 11.1906 8.72458C11.2471 8.58341 11.2942 8.42341 11.3318 8.27282C11.6518 8.20693 11.84 7.8587 11.9059 7.5387C11.9812 7.40693 12.1224 7.08693 12.0942 6.71988C12.0471 6.24929 11.8589 6.03282 11.6518 5.94811C11.6518 5.91988 11.6518 5.89164 11.6518 5.86341C11.6518 5.27046 11.5953 4.40458 11.4918 3.83988C11.473 3.67988 11.4353 3.52929 11.3883 3.36929C11.1812 2.68223 10.7577 2.05164 10.1836 1.6187C9.58122 1.17635 8.74357 0.931641 8.00004 0.931641C7.25651 0.931641 6.41886 1.16693 5.82592 1.6187C5.25181 2.06105 4.82828 2.68223 4.62122 3.36929C4.57416 3.52929 4.53651 3.67988 4.51769 3.83988C4.40475 4.40458 4.35769 5.26105 4.35769 5.86341C4.35769 5.89164 4.35769 5.91046 4.35769 5.9387C4.13181 6.02341 3.93416 6.23988 3.8871 6.71988C3.85886 7.07752 4.00945 7.39752 4.07533 7.52929C4.15063 7.8587 4.33886 8.20693 4.66828 8.26341C4.70592 8.42341 4.75298 8.57399 4.80945 8.72458C4.80945 8.73399 4.81886 8.74341 4.81886 8.75282L4.82828 8.76223C5.08239 9.43988 5.4871 10.0328 5.97651 10.4563C5.97651 10.5222 5.9671 10.5881 5.9671 10.654C5.92004 11.1622 6.06122 11.5387 4.67769 12.1505C3.28475 12.7622 1.19533 13.4211 0.762393 14.4093C0.329452 15.3975 0.508276 15.9811 0.508276 15.9811H15.4918C15.4824 15.9905 15.6612 15.4069 15.2283 14.4187Z"
        fill={className ? undefined : color}
      />
    </svg>
  );
};
