import React from 'react';

export const RichTextStrikeThroughIcon = ({
  width = 24,
  height = 24,
  className = 'fill-black',
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3 12.2029H21V13.7029H16.6342C16.8711 14.2002 16.9906 14.7451 16.9836 15.2958C16.9977 15.8183 16.8855 16.3365 16.6567 16.8064C16.428 17.2764 16.0893 17.6843 15.6694 17.9955C14.6579 18.7072 13.4378 19.0607 12.2025 19C11.3 19.0061 10.4062 18.8224 9.57916 18.461C8.82387 18.1477 8.16917 17.6329 7.68652 16.9728C7.24212 16.3426 7.00762 15.5884 7.01636 14.8174V14.704H7.30359V14.7029H7.64508V14.704H9.02V14.8173C9.00433 15.1687 9.07382 15.5187 9.22253 15.8374C9.37125 16.1562 9.59479 16.4343 9.87413 16.648C10.5515 17.1303 11.372 17.3697 12.2025 17.3273C12.9356 17.3752 13.6645 17.1835 14.2792 16.7811C14.507 16.6126 14.6901 16.3908 14.8124 16.1351C14.9346 15.8794 14.9923 15.5977 14.9804 15.3145C14.9964 15.041 14.9459 14.7676 14.8332 14.5178C14.7205 14.268 14.5491 14.0493 14.3335 13.8801C14.2451 13.8161 14.1533 13.7569 14.0585 13.7029H3V12.2029ZM16.3447 7.0598C15.9232 6.40481 15.3271 5.88058 14.6236 5.54619C13.8394 5.1726 12.9793 4.98565 12.1107 4.99999C10.8996 4.94801 9.70883 5.32403 8.74719 6.06212C8.33435 6.38097 8.00134 6.79147 7.77448 7.26119C7.54762 7.73091 7.43313 8.24695 7.44006 8.76854C7.43656 9.26283 7.54659 9.75133 7.76166 10.1964H10.3583C10.275 10.1427 10.173 10.0906 10.1056 10.0358C9.90219 9.88567 9.73702 9.68968 9.62358 9.46372C9.51014 9.23777 9.45164 8.98823 9.45282 8.7354C9.43773 8.44679 9.49242 8.15879 9.61226 7.8958C9.73209 7.63282 9.91354 7.40258 10.1412 7.2246C10.7173 6.82506 11.4109 6.63053 12.1107 6.67217C12.8717 6.62379 13.6231 6.86239 14.2167 7.34087C14.465 7.57466 14.6594 7.85967 14.7865 8.17612C14.9136 8.49257 14.9703 8.83288 14.9527 9.17345V9.28686H16.9559V9.17345C16.9592 8.42485 16.7471 7.69109 16.3447 7.0598Z" />
  </svg>
);
