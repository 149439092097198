import styled, { css } from 'styled-components';
import { baseButtonStyles, typography } from '../../theme/baseStyles';
import { PanelContainer, SeriesLabel } from './shared.styled';

export const SettingsRoot = styled.div``;

export const ContentGroup = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  ${SeriesLabel} {
    margin-top: 0;
  }
`;

export const ModalTitle = styled.p`
  ${typography.bodyHeavy};
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;

  & svg {
    margin-right: ${({ theme }) => theme.spacing(1)}px;
  }
`;

export const base = css`
  ${baseButtonStyles};
  width: 150px;
`;

export const SettingsPanelContainer = styled(PanelContainer)`
  margin-right: ${({ theme }) => theme.spacing(7)}px;
  margin-left: ${({ theme }) => theme.spacing(7)}px;
`;

export const Clickable = styled.div`
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
`;

export const Textarea = styled.textarea`
  width: calc(100% - 34px);
  outline: none;
  font-size: 16px;
  color: #3d3d46;
  padding: 13px 16px;
  margin: 0 0 24px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  resize: none;
  &::placeholder {
    font-family: Poppins;
    font-size: 15px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: rgba(61, 61, 70, 0.4);
  }
`;
