import { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash/function';
import { updateSeriesApi } from '../../../helper/api';
import { fromError, logerror } from '../../../helper/contextualLogger';
const updateRsvpSettings = async (
  meetingSeriesId,
  enabledFieldsRsvp,
  onUpdateStart,
  onUpdateEnd
) => {
  try {
    onUpdateStart();

    const result = await updateSeriesApi(meetingSeriesId, {
      settings: {
        enabledFieldsRsvp,
      },
    });

    onUpdateEnd(result);
  } catch (error) {
    logerror(fromError(error));
  }
};

const handleUpdateRsvpSettings = debounce(updateRsvpSettings, 1_000);
export const useChangeRsvpSettings = (series, localDispatch) => {
  const isInitialized = useRef(false);

  const [isUpdating, setIsUpdating] = useState(false);

  const [enabledFieldsRsvp, setEnabledFieldsRsvp] = useState(
    series.settings.enabledFieldsRsvp
  );

  const meetingSeriesId = series?.meetingSeriesId;

  const handleChangeRsvpField = useCallback(
    (fieldName) => (newValue) =>
      setEnabledFieldsRsvp((prevFields) =>
        newValue
          ? [...prevFields, fieldName]
          : prevFields.filter((field) => field !== fieldName)
      ),
    []
  );

  useEffect(() => {
    if (!isInitialized.current) {
      isInitialized.current = true;
      return;
    }

    const handleStartUpdate = () => {
      setIsUpdating(true);
    };

    const handleEndUpdate = (result) => {
      localDispatch({ type: 'REFRESH_SERIES', series: result });
      setIsUpdating(false);
    };

    handleUpdateRsvpSettings(
      meetingSeriesId,
      enabledFieldsRsvp,
      handleStartUpdate,
      handleEndUpdate
    );
  }, [localDispatch, meetingSeriesId, enabledFieldsRsvp]);

  return {
    handleChangeRsvpField,
    enabledFieldsRsvp,
    isUpdating,
  };
};
