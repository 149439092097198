import React from 'react';

export const PersonIcon = ({
  width = '21px',
  height = '20px',
  className = 'fill-purple',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5054 5C15.5054 7.76142 13.2662 10 10.5039 10C7.74165 10 5.50239 7.76142 5.50239 5C5.50239 2.23858 7.74165 0 10.5039 0C13.2662 0 15.5054 2.23858 15.5054 5ZM13.5048 5C13.5048 3.34315 12.1613 2 10.5039 2C8.84655 2 7.503 3.34315 7.503 5C7.503 6.65685 8.84655 8 10.5039 8C12.1613 8 13.5048 6.65685 13.5048 5ZM20.3969 18.55L18.5063 14.76C17.6584 13.0683 15.9278 12.0001 14.035 12H6.97284C5.08005 12.0001 3.34945 13.0683 2.50149 14.76L0.610913 18.55C0.454897 18.8595 0.470326 19.2277 0.651689 19.5231C0.833052 19.8185 1.15448 19.999 1.50118 20H19.5066C19.8533 19.999 20.1748 19.8185 20.3561 19.5231C20.5375 19.2277 20.5529 18.8595 20.3969 18.55ZM4.29203 15.66L3.12167 18H17.8861L16.7158 15.66C16.2084 14.6441 15.1708 14.0016 14.035 14H6.97284C5.837 14.0016 4.79937 14.6441 4.29203 15.66Z"
      />
    </svg>
  );
};
