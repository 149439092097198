import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';

import { useSlideSize } from '../../../hooks/useSlideSize';
import classNames from '../../../helper/classNames';

export const positions = {
  SHARED: 'SHARED',
  TOP: 'TOP',
  BOTTOM: 'BOTTOM',
  RIGHT: 'RIGHT',
  LEFT: 'LEFT',
  CENTER: 'CENTER',
};

const classes = {
  [positions.SHARED]: 'absolute w-max z-10 cursor-grab',
  [positions.TOP]: 'top-0 left-1/2',
  [positions.BOTTOM]: 'bottom-0 left-1/2',
  [positions.RIGHT]: 'right-0 top-1/2',
  [positions.LEFT]: 'left-0 top-1/2',
  [positions.CENTER]: 'left-2/4',
};

const getX = (initialOffset, edgeOffset) =>
  `calc(${initialOffset} + ${edgeOffset}px)`;

const draggableOffsets = (offset) => ({
  [positions.TOP]: {
    x: getX('-50%', offset),
    y: '-125%',
  },
  [positions.BOTTOM]: {
    x: getX('-50%', offset),
    y: '125%',
  },
  [positions.RIGHT]: {
    x: '125%',
    y: '-50%',
  },
  [positions.LEFT]: {
    x: '-125%',
    y: '-50%',
  },
});

const TOOLBAR_PADDING = 24;

const calculateOffset = (parent, child) => {
  if (!parent || !child) {
    return {
      leftEdgeOffset: 0,
      rightEdgeOffset: 0,
    };
  }
  const parentBounds = parent.getBoundingClientRect();
  const childBounds = child.getBoundingClientRect();

  const leftEdgeOffset = Math.max(0, parentBounds.left - childBounds.left);
  const rightEdgeOffset =
    Math.max(0, childBounds.right - parentBounds.right) * -1;

  const scaleX = childBounds.width / child.offsetWidth;

  return (leftEdgeOffset + rightEdgeOffset) / scaleX;
};

/* This component is responsible for auto positioning toolbar, depending on block position and toolbar heignt. This is purely for UX purposes, to ensure the toolbar looks and works well no matter the block positions */
const ToolbarAutoPosition = ({ children, blockPosition, toolbarHeight }) => {
  const [offset, setOffset] = useState(0);

  const toolbarRef = useRef(null);

  const {
    height: containerHeight,
    width: containerWidth,
    element: slideRef,
  } = useSlideSize();

  useEffect(() => {
    setOffset(calculateOffset(slideRef, toolbarRef.current));
  }, [slideRef, setOffset]);

  const { top, left, height: blockHeight, width: blockWidth } = blockPosition;

  const fits = {
    onTop: top > toolbarHeight,
    onBottom: containerHeight - (top + blockHeight) > toolbarHeight,
    onRight:
      containerWidth - (left + blockWidth + TOOLBAR_PADDING) > toolbarHeight,
    onLeft: left - TOOLBAR_PADDING > toolbarHeight,
  };

  const bestPosition = (() => {
    switch (true) {
      case fits.onTop: {
        return positions.TOP;
      }
      case fits.onBottom: {
        return positions.BOTTOM;
      }
      case fits.onRight: {
        return positions.RIGHT;
      }
      case fits.onLeft: {
        return positions.LEFT;
      }
      default: {
        return positions.CENTER;
      }
    }
  })();

  const fitsVertically = fits.onTop || fits.onBottom;
  const fitsHorizontally = fits.onRight || fits.onLeft;

  return (
    <Draggable positionOffset={draggableOffsets(offset)[bestPosition]}>
      <div
        ref={toolbarRef}
        className={classNames(classes.SHARED, classes[bestPosition])}
        style={{
          width:
            !fitsVertically && fitsHorizontally ? toolbarHeight : undefined,
        }}
      >
        {children(bestPosition)}
      </div>
    </Draggable>
  );
};

export default React.memo(ToolbarAutoPosition);
