import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { SeriesVisibility } from '../helper/visibility';
import { TwoPersonIcon as PublicVisibilityIcon } from './icons/TwoPersonIcon';
import { PersonInCircleIcon as RestrictedVisibilityIcon } from './icons/PersonInCircleIcon';
import { PersonInSquareIcon as PrivateVisibilityIcon } from './icons/PersonInSquareIcon';
import { CheckInCircleIcon } from './icons/CheckInCircleIcon';
import { MailIcon } from './icons/MailIcon';
import { PencilIcon } from './icons/PencilIcon';
import classNames from '../helper/classNames';
import {
  RSVPSettings,
  UpdateRSVPSettingsModal,
} from '../pages/Series/RSVP/RSVPSettings';
import { isEqual } from 'lodash';
import { getTimezoneAbbreviation } from '../helper/rsvp';
import { Button } from './common/Button';

export const options = [
  {
    value: SeriesVisibility.RESTRICTED,
    title: 'Workspace Members',
    Icon: RestrictedVisibilityIcon,
    text: 'Anyone in your workspace with a event link can attend.',
  },
  {
    value: SeriesVisibility.PUBLIC,
    title: 'Anyone',
    Icon: PublicVisibilityIcon,
    text: 'Anyone in the internet who has an event link can attend.',
  },
  {
    value: SeriesVisibility.PRIVATE,
    title: 'Invite Only',
    Icon: PrivateVisibilityIcon,
    text: 'Only people invited below can attend.',
  },
  {
    value: SeriesVisibility.RSVP,
    title: 'RSVP + Anyone',
    Icon: MailIcon,
    text: 'A public event that allows your attendees to RSVP.',
  },
];

export const SeriesVisibilityOption = ({ visibility, iconColor }) => {
  const [VisibilityData, setVisibilityData] = useState(null);

  useEffect(() => {
    options.forEach((option) => {
      if (option.value.toLowerCase() === visibility.toLowerCase()) {
        setVisibilityData({ ...option });
      }
    });
  }, [visibility]);

  return (
    <div>
      {VisibilityData && (
        <div className="flex flex-col space-y-3">
          <div className="flex space-x-2 ">
            <VisibilityData.Icon
              color={iconColor ? iconColor : '#8B33F7'}
            ></VisibilityData.Icon>
            <div className="text-md font-bold">{VisibilityData.title}</div>
          </div>
          <div className="text-sm text-blue-gray">{VisibilityData.text}</div>
        </div>
      )}
    </div>
  );
};
export const SeriesVisibilitySelector = ({
  defaultValue,
  onChangeVisibility,
  onUpdateSeriesScheduledEvent,
  scheduledEvent,
  setScheduledEvent,
  borderColor,
  isUpdating = false,
  convertedCurrentScheduledEvent,
  ...props
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const { startTime, endTime, daySelected, timezoneSelected } =
    scheduledEvent || {};

  const timezoneAbbreviation = useMemo(
    () => getTimezoneAbbreviation(timezoneSelected || 'Pacific Standard Time'),
    [timezoneSelected]
  );

  const [showUpdateRSVPSettingsModal, setShowUpdateRSVPSettingsModal] =
    useState(false);

  const optionClick = useCallback(
    (newValue) => {
      if (selectedValue !== newValue) {
        if (isUpdating && newValue === SeriesVisibility.RSVP) {
          setShowUpdateRSVPSettingsModal(true);
        } else {
          setSelectedValue(newValue);
          onChangeVisibility(newValue);
        }
      }
    },
    [onChangeVisibility, selectedValue, isUpdating]
  );

  return (
    <>
      {showUpdateRSVPSettingsModal && (
        <UpdateRSVPSettingsModal
          scheduledEvent={scheduledEvent}
          setScheduledEvent={setScheduledEvent}
          onCancel={() => {
            if (!isEqual(convertedCurrentScheduledEvent, scheduledEvent)) {
              setScheduledEvent(convertedCurrentScheduledEvent);
            }
          }}
          onSave={async (newValue) => {
            await onUpdateSeriesScheduledEvent(newValue);
          }}
          setSelectedValue={setSelectedValue}
          setShowUpdateRSVPSettingsModal={setShowUpdateRSVPSettingsModal}
        />
      )}
      <div
        className="flex flex-col md:flex-row w-full gap-4 justify-center"
        {...props}
      >
        {options.map((O) => (
          <div
            key={O.value}
            className={classNames(
              O.value === selectedValue ? 'border-purple' : 'border-gray',
              ' bg-white border border-solid p-2 rounded-lg box-border text-sm cursor-pointer h-32 flex-1'
            )}
            onClick={() => optionClick(O.value)}
          >
            <div className="flex justify-between h-12">
              <div className="flex space-x-3">
                <O.Icon
                  color={O.value === selectedValue ? borderColor : 'lightgray'}
                  width={25}
                  height={25}
                />
                <h3 className="lg:text-base text-sm font-semibold">
                  {O.title}
                </h3>
              </div>
              <CheckInCircleIcon
                width="25px"
                height="25px"
                color={O.value === selectedValue ? borderColor : 'lightgray'}
              />
            </div>
            <p className="text-blue-gray lg:text-sm text-xs">{O.text}</p>
          </div>
        ))}
      </div>
      {isUpdating && selectedValue === SeriesVisibility.RSVP && (
        <div className="flex w-full p-5 bg-purple/5 rounded-lg justify-between mt-5">
          <div className="flex items-center gap-2">
            <div className="font-semibold text-blue-dark">Date &amp; Time:</div>
            <div className="font-medium text-sm text-blue-gray">
              {daySelected.format('Do MMM, YYYY')} from {startTime} to {endTime}{' '}
              {timezoneAbbreviation}.
            </div>
          </div>
          <Button
            color={Button.colors.PURPLE}
            onClick={() => setShowUpdateRSVPSettingsModal(true)}
          >
            <div className="flex items-center gap-3">
              <PencilIcon className="fill-white" />
              <div className="text-base font-medium">Edit date &amp; time</div>
            </div>
          </Button>
        </div>
      )}
      {!isUpdating && selectedValue === SeriesVisibility.RSVP && (
        <RSVPSettings
          setScheduledEvent={setScheduledEvent}
          scheduledEvent={scheduledEvent}
        />
      )}
    </>
  );
};
