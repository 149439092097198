import React from 'react';
import styled from 'styled-components';

import BadgeEditable from './BadgeEditable';

const Editable = ({ TextComponent, handleEdit, style, isEditable }) => {
  return (
    <Container style={style}>
      <TextComponent />
      <BadgeEditable onClick={handleEdit} isEditable={isEditable} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

export default Editable;
