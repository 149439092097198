const clientDetails = (state = {}, action) => {
  switch (action.type) {
    case 'SELECT_LIVE_TAB':
      return {
        ...state,
        liveTab: action.tab,
      };
    case 'END_MEETING':
    case 'LEAVE_MEETING':
      return {
        ...state,
        leftMeeting: true,
        startAudioOff: false,
        startVideoOff: false,
      };
    case 'CLEAR_LIVE_MEETING_STATE':
    case 'CLEAR_MEETING_STATE':
      return {
        ...state,
        leftMeeting: false,
        liveTab: undefined,
      };
    case 'CLOSE_MODAL_WINDOW':
      return {
        ...state,
        activeModal: undefined,
        modalContext: undefined,
      };
    case 'LAUNCH_MODAL':
      return {
        ...state,
        activeModal: action.modal,
        signOnEmailAddress: action.emailAddress,
        modalContext: action.modalContext,
      };
    case 'SHOW_REMINDER_BAR':
      return {
        ...state,
        showReminderBar: true,
        warningDisplayed: true,
      };
    case 'CLOSE_REMINDER_BAR':
      return {
        ...state,
        showReminderBar: false,
      };
    case 'SET_START_AUDIO':
      return {
        ...state,
        startAudioOff: action.startAudioOff,
      };
    case 'SET_START_VIDEO':
      return {
        ...state,
        startVideoOff: action.startVideoOff,
      };
    default:
      return state;
  }
};

export default clientDetails;
