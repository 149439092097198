import { standardScreenSize } from '../helper/css';
import { SLIDE_CONTAINER_ID } from '../helper/constants';

const getDefaultValues = () => ({
  ...standardScreenSize,
  element: document.getElementById(SLIDE_CONTAINER_ID),
});

/**
 * Use the slide container width and height.
 *
 * @example
 * const { width: screenWidth, height: screenHeight } = useSlideSize();
 */

export const useSlideSize = (slideElementId) => {
  if (!slideElementId) {
    return getDefaultValues();
  }

  const slideContainer = document.getElementById(slideElementId);

  if (!slideContainer) {
    return getDefaultValues();
  }

  const { width, height } = slideContainer.getBoundingClientRect();

  return {
    width,
    height,
    element: slideContainer,
  };
};
