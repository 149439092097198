export const HighlightIcon = ({
  color = '#696F8C',
  width = '16',
  height = '20',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.24 0H14C15.1046 0 16 0.89543 16 2V18C16 19.1046 15.1046 20 14 20H2C0.89543 20 0 19.1046 0 18V7.24C0.000700208 6.44462 0.31723 5.68207 0.88 5.12L5.12 0.88C5.68207 0.31723 6.44462 0.000700208 7.24 0ZM2 8V18H14V2H8V7C8 7.55228 7.55228 8 7 8H2ZM4 10.5V11.5C4 11.7761 4.22386 12 4.5 12H11.5C11.7761 12 12 11.7761 12 11.5V10.5C12 10.2239 11.7761 10 11.5 10H4.5C4.22386 10 4 10.2239 4 10.5ZM4.5 14H8.5C8.77614 14 9 14.2239 9 14.5V15.5C9 15.7761 8.77614 16 8.5 16H4.5C4.22386 16 4 15.7761 4 15.5V14.5C4 14.2239 4.22386 14 4.5 14Z"
        fill={color}
      />
    </svg>
  );
};
