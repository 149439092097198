import { useCallback, useState } from 'react';
import { debounce } from 'lodash/function';
import { updateSeriesApi } from '../../../helper/api';
import { fromError, logerror } from '../../../helper/contextualLogger';

export const useChangeEmailMandatory = (series, localDispatch) => {
  const [isEmailMandatory, setisEmailMandatory] = useState(
    series.settings.isEmailMandatory
  );

  const meetingSeriesId = series?.meetingSeriesId;

  const onChangeisEmailMandatory = useCallback(
    async (newValue) => {
      try {
        const result = await updateSeriesApi(meetingSeriesId, {
          settings: { isEmailMandatory: newValue },
        });
        if (!result) return;
        setisEmailMandatory(result.settings.isEmailMandatory);
        localDispatch({ type: 'REFRESH_SERIES', series: result });
      } catch (error) {
        logerror(fromError(error));
      }
    },
    [localDispatch, meetingSeriesId]
  );

  const handleChangeIsEmailMandatory = debounce(
    onChangeisEmailMandatory,
    1_000
  );

  return {
    handleChangeIsEmailMandatory,
    isEmailMandatory,
  };
};
