const initialState = {
  isRecording: false,
};
const uiState = (state = initialState, action) => {
  switch (action.type) {
    case 'UI_RECORDING_STARTED':
      return {
        ...state,
        isRecording: true,
      };
    case 'UI_RECORDING_STOPPED':
      return {
        ...state,
        isRecording: false,
      };
    case 'END_MEETING':
    case 'LEAVE_MEETING':
    case 'CLEAR_MEETING_STATE': {
      return {
        ...state,
        isRecording: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default uiState;
