import React from 'react';

import MessagePartials from './MessagePartials';

const MeetingEndingShortlyGuest = ({ meetingCountdownProperties }) => {
  const { timeToEnd, showDynamicCountdown } = meetingCountdownProperties;

  return (
    <div>
      <MessagePartials.MeetingEndsIn
        timeToEnd={timeToEnd}
        showDynamicCountdown={showDynamicCountdown}
      />
    </div>
  );
};

export default MeetingEndingShortlyGuest;
