import {
  EVERYONE,
  getChatroomKeyFromReceivedMessage,
} from '../components/chat/chatHelpers';

const initialState = {
  messageHistory: {},
  incomingMessages: [],
  hasUnreadMessages: {},
};

const chatDetails = (state = initialState, action) => {
  // Deep-copies the existing message state, and then appends the new message to the correct chat.
  const appendMessage = (chatroomKey, sender, message) => {
    const existingChatroomMessages = state.messageHistory[chatroomKey] || [];
    return {
      ...state.messageHistory,
      [chatroomKey]: [...existingChatroomMessages, { sender, message }],
    };
  };

  switch (action.type) {
    case 'INCOMING_CHAT_MESSAGE': {
      // Handle incoming messages from the server.
      const { message, sender, recipient } = action;
      return {
        ...state,
        incomingMessages: [
          ...state.incomingMessages,
          { message, sender, recipient },
        ],
        hasUnreadMessages: {
          ...state.hasUnreadMessages,
          [getChatroomKeyFromReceivedMessage(sender, recipient)]: true,
        },
      };
    }
    case 'INCOMING_MESSAGES_PROCESSED':
      return {
        ...state,
        incomingMessages: [],
      };
    case 'STORE_RECEIVED_MESSAGE': {
      const { sender, recipient, message } = action;
      return {
        ...state,
        messageHistory: appendMessage(
          getChatroomKeyFromReceivedMessage(sender, recipient),
          sender,
          message
        ),
      };
    }
    case 'STORE_SENT_MESSAGE': {
      const { sender, recipient, message } = action;
      return {
        ...state,
        messageHistory: appendMessage(recipient || EVERYONE, sender, message),
      };
    }
    case 'MESSAGES_READ':
      return {
        ...state,
        hasUnreadMessages: {
          ...state.hasUnreadMessages,
          [action.chatroomKey]: false,
        },
      };
    case 'END_MEETING':
    case 'LEAVE_MEETING':
      return {
        ...state,
        messages: {},
        messageHistory: {},
        incomingMessages: [],
        hasUnreadMessages: {},
      };
    default:
      return {
        ...state,
      };
  }
};

export default chatDetails;
