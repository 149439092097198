import { useFeedPage } from './useFeedPage';
import { PageHeaderWhite } from './PageHeaderWhite';
import { LoadingVideo } from './FeedPage';
import React from 'react';
import { NoHighlightsContainer } from './NoHighlightsContainer';

const exampleReelLink = 'https://app.zync.ai/UP03JbW2/feed/bbuIea';

export const NoDataScreen = () => {
  const { allHighlights, workspace, isLoading } = useFeedPage();

  if (!isLoading && !workspace) {
    return (
      <div className="fixed top-[70px] right-0 bottom-0 left-0 grid place-content-center text-orange-light text-xl">
        <p>This workspace was not found or not loaded correctly.</p>
        <p>Please refresh the page and try again.</p>
      </div>
    );
  }

  if (!isLoading && !allHighlights?.length) {
    return (
      <div className="absolute inset-0 min-h-full bg-white scroll-auto">
        <PageHeaderWhite />
        <NoHighlightsContainer>
          <div className="flex-[980_0_0%]">
            <h2 className="text-blue-dark text-xl font-semibold text-center mb-3">
              You do not have any highlights generated.
            </h2>
            <h3 className="text-blue-dark text-sm text-center mb-6">
              Once you run your first episode you’ll see bite-sized video clips
              here
            </h3>
          </div>
          <div className="flex-[380_0_0%] flex flex-col gap-10" />
        </NoHighlightsContainer>

        <NoHighlightsContainer>
          <div className="flex-[980_0_0%]">
            <div className="relative group mb-4">
              <div className="group absolute inset-0 hover:backdrop-blur-sm grid place-content-center transition-all">
                <a
                  className="md:opacity-0 group-hover:opacity-100 bg-purple px-8 py-4 text-white text-sm rounded-md font-medium transition-opacity"
                  href={exampleReelLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  See sample highlight reel
                </a>
              </div>
              <LoadingVideo />
            </div>
          </div>
          <div className="flex-[380_0_0%] hidden md:flex flex-col gap-4 md:gap-10">
            <LoadingVideo />
            <LoadingVideo />
            <LoadingVideo />
            <LoadingVideo />
            <LoadingVideo />
          </div>
        </NoHighlightsContainer>
      </div>
    );
  }

  return null;
};
