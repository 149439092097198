import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { ScenePreview } from './authoring/Menu/ScenePreview';
import { getRemainingTime } from '../helper/getRemainingTime';
import { useWindowSize } from '../helper/windowSizeChangeHandler';
import { usePosterSlide } from './series/settings/usePosterSlide';
import { getSlideTemplateKey } from '../pages/Portal/CreateNewEventModal';
import useImageColor from 'use-image-color';
import { presenterTypes } from './series/settings/EventPresenters';
import { getContrastColor } from 'zync-common/helper/color';
import clickingClockAnimation from './assets/lottie/clickingClock.json';
import Lottie from 'react-lottie';
import { isNotDefaultPoster } from '../pages/Portal/EventCard';

const clickingClockAnimationOptions = {
  loop: true,
  autoplay: true,
  animationData: clickingClockAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const MeetingWaitingScreen = ({ series }) => {
  const { settings } = series || {};
  const { eventPresenters, eventPosterImage, posterImageUrl } = settings || {};

  const { width } = useWindowSize();

  const speakers = eventPresenters.filter(
    (ep) => ep.type === presenterTypes.SPEAKER
  );

  const { posterSlide } = usePosterSlide(
    series,
    () => {},
    false,
    getSlideTemplateKey(speakers.length)
  );

  const [, setTimeRemaining] = useState([]);

  const posterImageUrlToUse =
    posterImageUrl && isNotDefaultPoster(posterImageUrl)
      ? posterImageUrl
      : eventPosterImage;

  const { colors } = useImageColor(posterImageUrlToUse, {
    cors: true,
    colors: 2,
  });
  const previewWidth = width * 0.55;
  const previewHeight = (previewWidth / 16) * 9;

  const mainColor = colors?.[0];

  const backgroundColor = posterImageUrlToUse
    ? mainColor
    : series?.workspace.brandKit.primaryColor;

  const eventStartDate = new Date(series?.scheduledEvent.startDate).getTime();

  useEffect(() => {
    let interval = setInterval(() => {
      const now = moment.now();
      const difference = eventStartDate - now;
      setTimeRemaining(getRemainingTime(difference));
    }, 999);

    return () => {
      clearInterval(interval);
    };
  }, [eventStartDate]);

  return (
    <div
      className="h-screen flex flex-col items-center"
      style={{
        backgroundColor,
      }}
    >
      <div className="">
        {posterImageUrlToUse ? (
          <img
            src={posterImageUrlToUse}
            width={previewWidth}
            height={previewHeight}
            className="max-w-full object-contain aspect-video"
            alt=""
          />
        ) : (
          <ScenePreview
            scene={posterSlide}
            width={previewWidth}
            height={previewHeight}
            brandKit={series?.workspace.brandKit}
          />
        )}
      </div>
      <div
        className="flex-shrink-0"
        style={{
          color: getContrastColor(backgroundColor),
        }}
      >
        <h1 className="text-center text-[30px] font-semibold mt-4">
          Waiting for the show to start
        </h1>
        <div className="w-[169px] mx-auto">
          <Lottie options={clickingClockAnimationOptions} />
        </div>
      </div>
    </div>
  );
};
