import { fromError, logerror, loginfo } from '../../helper/contextualLogger';
import { notifyUser } from '../../components/authoring/hooks';

/**
 * https://github.com/wle8300/uifx/blob/master/src/index.js
 * Copied from link above and used here to add the ability to play sound from audio output device of user's choice.
 */
export default class UIfx {
  constructor(file, config) {
    const namespace = 'uifx';
    const throttle = (fn, delay) => {
      let lastCall = 0;
      return function (...args) {
        const now = new Date().getTime();
        if (now - lastCall < delay) {
          return;
        }
        lastCall = now;
        return fn(...args);
      };
    };
    const validateURI = (file) => {
      if (!file) {
        throw Error('Requires valid URI path for "file"');
      } else return file;
    };
    const validateVolume = (volume) => {
      const message = '"Volume" must be an number between 0.0 and 1.0';

      if (volume && typeof volume !== 'number') throw Error(message);
      if (volume < 0 || volume > 1) throw Error(message);

      return volume ? volume : 1.0;
    };
    const validateThrottleMs = (throttleMs) => {
      const message = '"throttleMs" must be a number greater than zero';

      if (throttleMs && typeof throttleMs !== 'number') throw Error(message);
      if (throttleMs < 0) throw Error(message);

      return throttleMs ? throttleMs : 0;
    };
    const volume = validateVolume(config && config.volume);
    const throttleMs = validateThrottleMs(config && config.throttleMs);
    const appendAudioElement = (file) => {
      // hack to force browser
      // to preload audio file

      // hash function: https://stackoverflow.com/a/8831937/11330825
      const hash = (str) => {
        var hash = 0;
        if (str.length === 0) {
          return hash;
        }
        for (var i = 0; i < str.length; i++) {
          var char = str.charCodeAt(i);
          hash = (hash << 5) - hash + char;
          hash = hash & hash; // Convert to 32bit integer
        }
        return Math.abs(hash);
      };
      const id = `${namespace}-${hash(file)}`;
      let audioElement = document.createElement('audio');

      audioElement.id = id;
      audioElement.src = file;
      audioElement.preload = 'auto';

      document.body.appendChild(audioElement);
      return file;
    };

    this.config = config;
    this.file = appendAudioElement(validateURI(file));
    this.volume = volume;
    this.throttleMs = throttleMs;
    this.play = throttleMs > 0 ? throttle(this.play, throttleMs) : this.play;
    this.validateVolume = validateVolume;
    this.fileUrl = file;
  }

  play = async ({ volume, outputDeviceId, currentSoundTime }) => {
    this.validateVolume(volume);
    if (this.audioElement) {
      const audioElementPromised = this.audioElement.play();

      audioElementPromised
        .then(() => {
          // autoplay started, everything is ok
        })
        .catch((error) => {
          loginfo({
            ...fromError(error),
            message: `UIfx says: "had a problem playing file: ${this.file}"`,
          });
        });
    } else {
      this.audioElement = new Audio(this.file);

      if (outputDeviceId !== null && this.audioElement.setSinkId) {
        try {
          await this.audioElement.setSinkId(outputDeviceId);
        } catch (err) {
          notifyUser(
            'We are having problems playing sounds from your audio device. Please select a different audio device.'
          );
          logerror({
            ...fromError(err, `Error setting sinkId in UIFx.js`),
            context: { outputDeviceId, fileUrl: this.fileUrl },
          });
        }
      }

      this.audioElement.playsInline = true;

      this.audioElement.load();
      this.audioElement.addEventListener('loadeddata', () => {
        this.audioElement.volume =
          volume >= 0 && volume <= 1 ? volume : this.volume;

        if (currentSoundTime) {
          this.audioElement.currentTime = currentSoundTime;
        }

        const audioElementPromised = this.audioElement.play();

        audioElementPromised
          .then(() => {
            // autoplay started, everything is ok
          })
          .catch((error) => {
            loginfo({
              ...fromError(error),
              message: `UIfx says: "had a problem playing file: ${this.file}"`,
            });
          });
      });

      if (this.config.onEnd) {
        this.audioElement.addEventListener('ended', (event) => {
          this.config.onEnd(event);
        });
      }
    }

    return this;
  };

  stop = () => {
    if (!this.audioElement) {
      return;
    }
    this.audioElement.pause();
    this.audioElement.currentTime = 0;
  };

  pause = () => {
    if (!this.audioElement) {
      return;
    }
    this.audioElement.pause();
  };

  cleanup = () => {
    if (!this.audioElement) {
      return;
    }

    this.audioElement.pause();
    this.audioElement.currentTime = 0;
    this.audioElement = null;
  };

  setVolume = (volume) => {
    this.validateVolume(volume);

    this.volume = volume;

    return this;
  };
}
