import { useContext, useEffect, useMemo, useState } from 'react';
import {
  fetchSceneTemplateApi,
  updateSeriesApi,
  uploadPosterImage,
} from '../../../helper/api';
import domtoimage from 'dom-to-image';
import { getTimezoneAbbreviation } from '../../../helper/rsvp';
import { fromError, logerror } from '../../../helper/contextualLogger';
import { useSelector } from 'react-redux';
import { DynamicBlocks } from '../../../blocks/helper/dynamicBlockVariables';
import { convertUTCToTimezone } from 'zync-common/helper/timezone';
import { isDev } from '../../../helper';
import { isNotDefaultPoster } from '../../../pages/Portal/EventCard';
import { WorkspaceContext } from '../../../App';
export const getStartDateFormatted = (series) =>
  series
    ? '🗓 ' +
      convertUTCToTimezone(
        series?.scheduledEvent?.startDate,
        series?.scheduledEvent?.timezone,
        'day-month-year',
        true,
        false
      )
    : undefined;
export const getStartAndEndTimeFormatted = (series) =>
  series
    ? '⏰ ' +
      convertUTCToTimezone(
        series?.scheduledEvent?.startDate,
        series?.scheduledEvent?.timezone,
        'time',
        false,
        false
      ) +
      ' - ' +
      convertUTCToTimezone(
        series?.scheduledEvent?.endDate,
        series?.scheduledEvent?.timezone,
        'time',
        false,
        false
      ) +
      ' ' +
      getTimezoneAbbreviation(
        series?.scheduledEvent?.timezone ||
          series?.scheduledEvent?.timezoneSelected
      )
    : undefined;

const dataURItoArrayBuffer = (dataURI) => {
  const binary_string = window.atob(dataURI.split(',')[1]);
  const len = binary_string.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }

  return bytes.buffer;
};

export const usePosterSlide = (
  series,
  localDispatch,
  useUserAsPlaceholder = true,
  posterSlideTemplateKey = 'scene_poster_interview_1x1',
  memoizePosterInterviewSlides = false,
  variablesToStaticMap,
  displayedUser
) => {
  const { allTemplates } = useContext(WorkspaceContext);

  const userName = useSelector((state) => state?.auth?.user?.userName);
  const userBio = useSelector((state) => state?.auth?.user?.bio);
  const userAvatar = useSelector((state) => state?.auth?.user?.avatarUrl);

  const [posterSlideTemplate, setPosterSlideTemplate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [error, setError] = useState('');

  const hasPosterGenerated =
    series?.settings?.eventPosterImage ||
    isNotDefaultPoster(series?.settings?.posterImageUrl);

  useEffect(() => {
    let timeout;

    timeout = setTimeout(() => {
      if (!isDev()) {
        setError(
          'We could not load poster image on time. Please refresh the page'
        );
        setIsLoading(false);
      }
    }, 60_000);

    if (hasPosterGenerated) {
      setIsLoading(false);
      clearTimeout(timeout);
    }

    return () => clearTimeout(timeout);
  }, [hasPosterGenerated]);

  useEffect(() => {
    if (allTemplates.has(posterSlideTemplateKey)) {
      return setPosterSlideTemplate(allTemplates.get(posterSlideTemplateKey));
    }
    if (!memoizePosterInterviewSlides) {
      fetchSceneTemplateApi(posterSlideTemplateKey).then((result) => {
        setPosterSlideTemplate(result);
        allTemplates.set(posterSlideTemplateKey, result);
      });
    }
  }, [allTemplates, posterSlideTemplateKey, memoizePosterInterviewSlides]);

  const handleDownloadPosterImage = () => {
    domtoimage
      .toPng(document.getElementById('poster-id'), { width: 1600, height: 900 })
      .then(function (dataUrl) {
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = `poster.png`;

        document.body.appendChild(link);

        link.click();
        link.remove();
      });
  };

  const posterBlocks = posterSlideTemplate?.blocks;

  const dynamicBlocks = new DynamicBlocks(posterBlocks);
  const dynamicBlocksWithPlaceholderValues = new DynamicBlocks(posterBlocks);

  const host = series?.settings?.eventPresenters.find(
    (p) => p?.type === 'host'
  );
  const { user: hostUser } = host || {};

  const speakers = useMemo(
    () =>
      series?.settings?.eventPresenters.filter((p) => p?.type === 'speaker') ||
      [],
    [series?.settings?.eventPresenters]
  );

  dynamicBlocks
    .replaceWith('{speaker_1_name}', speakers[0]?.fullName)
    .replaceWith('{speaker_1_bio}', speakers[0]?.jobTitle)
    .replaceWith('{speaker_1_image}', speakers[0]?.presenterPictureUrl);

  dynamicBlocks
    .replaceWith('{speaker_2_name}', speakers[1]?.fullName)
    .replaceWith('{speaker_2_bio}', speakers[1]?.jobTitle)
    .replaceWith('{speaker_2_image}', speakers[1]?.presenterPictureUrl);

  dynamicBlocks
    .replaceWith('{speaker_3_name}', speakers[2]?.fullName)
    .replaceWith('{speaker_3_bio}', speakers[2]?.jobTitle)
    .replaceWith('{speaker_3_image}', speakers[2]?.presenterPictureUrl);

  dynamicBlocks
    .replaceWith('{speaker_4_name}', speakers[3]?.fullName)
    .replaceWith('{speaker_4_bio}', speakers[3]?.jobTitle)
    .replaceWith('{speaker_4_image}', speakers[3]?.presenterPictureUrl);

  dynamicBlocks
    .replaceWith('{speaker_5_name}', speakers[4]?.fullName)
    .replaceWith('{speaker_5_bio}', speakers[4]?.jobTitle)
    .replaceWith('{speaker_5_image}', speakers[4]?.presenterPictureUrl);

  dynamicBlocks
    .replaceWith('{speaker_6_name}', speakers[5]?.fullName)
    .replaceWith('{speaker_6_bio}', speakers[5]?.jobTitle)
    .replaceWith('{speaker_6_image}', speakers[5]?.presenterPictureUrl);

  if (variablesToStaticMap) {
    variablesToStaticMap.forEach(([dynamicVariable, staticValue]) => {
      dynamicBlocks.replaceWith(dynamicVariable, staticValue);
    });
  }

  const dateStart = getStartDateFormatted(series);
  const timeStartAndEnd = getStartAndEndTimeFormatted(series);

  dynamicBlocks
    .replaceWith('{event_title}', series?.title)
    .replaceWith(
      '{event_date}',
      dateStart === '🗓 Invalid date - Invalid date'
        ? '🗓 Event time not set'
        : dateStart
    )
    .replaceWith(
      '{event_time}',
      !timeStartAndEnd ||
        timeStartAndEnd.includes('Invalid date - Invalid date PST')
        ? ''
        : timeStartAndEnd
    )
    .replaceWith('{{font}}', series?.workspace?.brandKit?.font);

  dynamicBlocksWithPlaceholderValues
    .replaceWith(
      '{event_title}',
      series?.title || 'Exploring the world of Generative AI - with Sam Altman'
    )
    .replaceWith('{event_date}', dateStart || '2:00 PM')
    .replaceWith('{event_time}', timeStartAndEnd || '3:00 PM PST')
    .replaceWith('{speaker_1_name}', speakers[0]?.fullName || 'Robert Brink')
    .replaceWith(
      '{speaker_1_bio}',
      speakers[0]?.jobTitle || 'Software Engineer'
    )
    .replaceWith(
      '{speaker_1_image}',
      speakers[0]?.presenterPictureUrl ||
        'https://storage.googleapis.com/zync-media/assets/static/fake-user1.PNG'
    )
    .replaceWith('{{font}}', series?.workspace?.brandKit?.font);

  if (useUserAsPlaceholder) {
    dynamicBlocks
      .replaceWith(
        '{host_name}',
        hostUser?.userName || host?.fullName || userName
      )
      .replaceWith(
        '{host_bio}',
        hostUser?.bio || host?.jobTitle || userBio || ''
      )
      .replaceWith(
        '{host_image}',
        hostUser?.avatarUrl || host?.presenterPictureUrl || userAvatar
      );
    dynamicBlocksWithPlaceholderValues
      .replaceWith(
        '{host_name}',
        hostUser?.userName || host?.fullName || userName || ''
      )
      .replaceWith(
        '{host_bio}',
        hostUser?.bio || host?.jobTitle || userBio || ''
      )
      .replaceWith(
        '{host_image}',
        hostUser?.avatarUrl || host?.presenterPictureUrl || userAvatar
      );
  } else if (displayedUser) {
    dynamicBlocks
      .replaceWith('{host_name}', displayedUser.userName)
      .replaceWith('{host_bio}', displayedUser.userBio)
      .replaceWith('{host_image}', displayedUser.userAvatar);
    dynamicBlocksWithPlaceholderValues
      .replaceWith('{host_name}', displayedUser.userName)
      .replaceWith('{host_bio}', displayedUser.userBio)
      .replaceWith('{host_image}', displayedUser.userAvatar);
  } else {
    dynamicBlocks
      .replaceWith('{host_name}', hostUser?.userName || host?.fullName)
      .replaceWith('{host_bio}', hostUser?.bio || host?.jobTitle || '')
      .replaceWith(
        '{host_image}',
        hostUser?.avatarUrl || host?.presenterPictureUrl
      );
    dynamicBlocksWithPlaceholderValues
      .replaceWith('{host_name}', hostUser?.userName || host?.fullName || '')
      .replaceWith('{host_bio}', hostUser?.bio || host?.jobTitle || '')
      .replaceWith(
        '{host_image}',
        hostUser?.avatarUrl || host?.presenterPictureUrl
      );
  }

  const updateSeriesPoster = async (eventPosterImage) => {
    try {
      const result = await updateSeriesApi(series.meetingSeriesId, {
        settings: { eventPosterImage },
      });

      localDispatch &&
        localDispatch({ type: 'REFRESH_SERIES', series: result });
    } catch (error) {
      logerror(fromError(error));
    }
  };

  const handleUploadPoster = () => {
    setIsLoading(true);
    setError('');
    setTimeout(() => {
      domtoimage
        .toPng(document.getElementById('poster-id'), {
          width: 1600,
          height: 900,
        })
        .then((dataUrl) => {
          uploadPosterImage(
            series.meetingSeriesId + `/scene_${Date.now()}.png`,
            dataURItoArrayBuffer(dataUrl),
            'image/png'
          )
            .then((response) => {
              updateSeriesPoster(
                'https://storage.googleapis.com/zync-media/' +
                  response.result.metadata.name
              );
            })
            .catch(() => {
              setError('Poster upload error');
            })
            .finally(() => {
              setIsLoading(false);
            });
        });
    }, 100);
  };

  return {
    posterSlide: {
      ...posterSlideTemplate,
      blocks: dynamicBlocks.getAll(),
    },
    posterSlideWithPlaceholderValues: {
      ...posterSlideTemplate,
      blocks: dynamicBlocksWithPlaceholderValues.getAll(),
    },
    handleUploadPoster,
    handleDownloadPosterImage,
    isLoading,
    setIsLoading,
    isUploadLoading,
    setIsUploadLoading,
    error,
    hasPosterGenerated,
  };
};
