import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { ImageUploader } from '../ImageUploader';
import { uploadBlockImage } from '../../helper/api';
import { FieldContainer, InputLabel } from './Input.style';
import { TextInput } from '.';
import { InputExplanation } from '../SettingsInput';

const ImageFileInputFieldContainer = styled(FieldContainer)`
  > ${() => FieldContainer} {
    margin-top: 0 !important;
  }
`;

/**
 * A control for uploading an image, or specifying the URL of an image.
 */
export const ImageFileInput = ({
  /** The url of the file. */
  value,
  /** Handler for when a new file is uploaded. Passes the url. */
  onChange,
  /**
   * Handler for when an upload is being requested after the file has been selected from the system
   * dialog. Defaults to using `uploadBlockImage`.
   */
  onUpload,
  /** A folder name in which to save the file. */
  folderName = '',
  /** An explanation for the input. */
  explanation,
  /** A label for the input. */
  label,
  /** Whether to allow uploading multiple files. */
  multiple = false,

  showInput = true,
}) => {
  const [url, setUrl] = useState(value ?? null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSetUrl = useCallback(
    (url) => {
      setUrl(url);
      onChange(url);
    },
    [setUrl, onChange]
  );

  const handleUpload = useCallback(
    async (fileName, buffer, type) => {
      if (onUpload) {
        return await onUpload(fileName, buffer, type);
      } else {
        return await uploadBlockImage(fileName, buffer, type);
      }
    },
    [onUpload]
  );

  return (
    <ImageFileInputFieldContainer>
      {label && <InputLabel>{label}</InputLabel>}

      {showInput && (
        <TextInput
          type="text"
          value={url}
          onChange={(event, value) => handleSetUrl(value)}
        />
      )}

      <ImageUploader
        disabled={loading}
        multiple={multiple}
        onImageSubmit={async (buffer, type, name) => {
          setError('');
          setLoading(true);

          const uploadFileName =
            (folderName ? folderName + '/' : '') + name.toLowerCase();

          try {
            const result = await handleUpload(uploadFileName, buffer, type);

            handleSetUrl(result);
          } finally {
            setLoading(false);
          }
        }}
        onFileSizeExceeded={() => {
          setError('Please use file smaller than 10 MB');
        }}
      />
      {error && <p className="text-red text-xs">{error}</p>}
      {explanation && <InputExplanation> {explanation}</InputExplanation>}
    </ImageFileInputFieldContainer>
  );
};
