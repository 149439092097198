import React from 'react';

export const WarningCircleIcon = ({
  width = '16px',
  height = '17px',
  className = 'fill-red',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0.505005C3.58276 0.505005 0.00499725 4.08277 0.00499725 8.50001C0.00499725 12.9172 3.58276 16.495 8 16.495C12.4172 16.495 15.995 12.9172 15.995 8.50001C15.995 4.08277 12.4172 0.505005 8 0.505005ZM7.00063 13.4969V11.4981H8.99938V13.4969H7.00063ZM7.00063 3.50313V10.4988H8.99938V3.50313H7.00063Z"
      />
    </svg>
  );
};
