export const getSynchronizedTimestamp = (user) => {
  if (!user || !user?.timestampOffset) {
    return Date.now();
  }

  return Date.now() + user.timestampOffset;
};

export const getCurrentTimerInSeconds = (user, duration, startTime) => {
  const timePassed = (getSynchronizedTimestamp(user) - startTime) / 1000;
  const currentTimer = duration - timePassed;
  if (isNaN(currentTimer)) return duration;
  return currentTimer > duration ? duration : currentTimer;
};
