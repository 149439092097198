import React from 'react';
import styled, { css } from 'styled-components';
import { CaretIcon } from '../icons/CaretIcon';

/** Primary styled text. */
export const PrimaryText = styled.div`
  font-size: ${({ theme, size = 'regular' }) =>
    theme.typography.fontSize[size]}px;
  line-height: ${({ theme, size = 'regular' }) =>
    theme.typography.lineHeight[size]}px;
  font-weight: 500;
  text-align: ${(props) => (props.centered ? 'center' : 'left')};
  color: ${({ theme, palette = 'primary', type = 'main', textColor }) =>
    textColor ?? theme.palette[palette][type]};
`;

/** Secondary styled text. */
export const SecondaryText = styled.div`
  font-size: ${({ theme, size = 'small' }) =>
    theme.typography.fontSize[size]}px;
  line-height: ${({ theme, size = 'small' }) =>
    theme.typography.lineHeight[size]}px;
  font-weight: 300;
  text-align: ${(props) => (props.centered ? 'center' : 'left')};
  color: ${({ theme, palette = 'text', type = 'paragraph', textColor }) =>
    textColor ?? theme.palette[palette][type]};
`;

/** H3 */
export const H3Text = styled.h3`
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  color: ${({ theme, textPalette }) =>
    textPalette ? theme.palette.text[textPalette] : '#44444f'};
  text-align: left;
  margin: 10px 0;
  padding: 0;
`;

/** H4 */
export const H4Text = styled.h4`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme, textPalette }) =>
    textPalette ? theme.palette.text[textPalette] : '#101840'};
  margin: 10px 0;
  text-align: left;

  ${(props) =>
    props.secondary &&
    css`
      color: #696f8c;
      font-weight: 500;
      line-height: 21px;
    `};
`;

/** H5 */
export const H5Text = styled.h5`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #696f8c;
  padding: 0;
  margin: 10px 0;
`;

/** Caret Text Container for styled-components use. */
export const CaretTextContainer = styled.div`
  transform: rotate(180deg);
    path {
      ${({ textColor }) => textColor !== undefined && `fill: ${textColor};`}
    }
  }
`;

/**
 * Unstyled caret text, taking a prop `direction` which can be `down`, `up`, `right`, or `left`. Defaults to `down`.
 */
export const CaretText = React.forwardRef(({ color }, ref) => (
  <CaretTextContainer ref={ref} textColor={color}>
    <CaretIcon />
  </CaretTextContainer>
));
