import React, { useCallback } from 'react';
import { useExampleUsers } from '../components/authoring/authoringBlockData';
import { SoundBoard } from '../components/SoundBoard/SoundBoard';
import { getStaticAssetUrl } from '../helper/getStaticAssetUrl';
import { BlockFunctionConstants } from './constants';
import { SOUNDS } from './sounds';

const trumpetImg = getStaticAssetUrl('trumpet.png');
const trumpetGif = getStaticAssetUrl('trumpet.gif');
const trumpImg = getStaticAssetUrl('trump.png');
const trumpGif = getStaticAssetUrl('trump.gif');
const drumsImg = getStaticAssetUrl('drums.png');
const drumsGif = getStaticAssetUrl('drums.gif');

export const BlockBuilderFunctions = {
  [BlockFunctionConstants.hasSummaryContent]: () => false,
};

export const soundsMap = {
  rimshot: {
    effect: SOUNDS.rimshot,
    soundName: 'Rimshot',
    image: drumsImg,
    gif: drumsGif,
    soundTime: 1000,
    scale: 0,
  },
  fakenews: {
    effect: SOUNDS.fakenews,
    soundName: 'Fake news',
    image: trumpImg,
    gif: trumpGif,
    soundTime: 1000,
    scale: 0,
  },
  airhorn: {
    effect: SOUNDS.airhorn,
    soundName: 'Airhorn',
    image: trumpetImg,
    gif: trumpetGif,
    soundTime: 3000,
    scale: 2.5,
  },
};

export const Block = ({ block, user, eventDispatch }) => {
  const { position } = block;

  const playSound = useCallback(
    (sound) => {
      eventDispatch(
        {
          type: 'BROADCAST_ONLY',
          data: {
            key: 'soundEffect',
            sound,
          },
        },
        /* we do not want to send block info, because we want to treat this event as platform event */
        false
      );
    },
    [eventDispatch]
  );

  return (
    <SoundBoard
      user={user}
      position={position}
      playSound={playSound}
      soundsMap={soundsMap}
    />
  );
};

export const AuthoringBlock = ({ block }) => {
  const { position } = block;
  const exampleUsers = useExampleUsers(1, block.blockInstanceId);
  const exampleHostUser = {
    ...exampleUsers[0],
    userId: exampleUsers[0].userId + '.two',
    roles: ['moderator'],
  };

  return (
    <SoundBoard
      user={exampleHostUser}
      position={position}
      playSound={() => {}}
      soundsMap={soundsMap}
    />
  );
};
