import React from 'react';
import { logerror, logwarn } from './contextualLogger';
import { ZyncErrorModal } from '../components/ZyncErrorModal';
import { connect } from 'react-redux';
import { sendEvent } from '../helper/api';
import { getStaticAssetUrl } from './getStaticAssetUrl';

const demonAttackSrc = getStaticAssetUrl('demon_attack.svg');
const devopsHolidaySrc = getStaticAssetUrl('devops_holiday.svg');
const detectiveAtWorkSrc = getStaticAssetUrl('detective_at_work.svg');

const errorViews = [
  {
    imageSrc: demonAttackSrc,
    title: 'We are currently thwarting an unexpected demon attack',
    subtitle:
      'We apologize for the disruption, please try reloading your browser to resume.',
  },
  {
    imageSrc: devopsHolidaySrc,
    title: "Our Devops engineer hasn't come back from vacation yet",
    subtitle:
      'We apologize for the disruption, please try reloading your browser to resume.',
  },
  {
    imageSrc: detectiveAtWorkSrc,
    title: 'We are looking into this',
    subtitle:
      'We apologize for the disruption, please try reloading your browser to resume.',
  },
];

const getRandomErrorView = () => {
  const randomIndex = Math.floor(Math.random() * errorViews.length);
  return errorViews[randomIndex];
};
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    const { daily, meetingId, meetingSeriesId, userId } = this.props;
    this.setState({ hasError: true });
    console.error(
      'Caught unhandled exception',
      error.message,
      error.stack,
      meetingId,
      meetingSeriesId,
      userId
    );
    // Report error log
    logerror({
      userId,
      meetingSeriesId,
      meetingId,
      message: error.message + `. Error happened on ${window.location.href}`,
      stacktrace: error.stack,
    });
    // Destroy the daily call object.
    if (daily) {
      logwarn({
        message: 'Destroying the daily call object.',
        meetingId,
        meetingSeriesId,
        userId,
      });
      if (!daily.isDestroyed()) {
        daily.destroy();
      }
    }

    if (userId && meetingId) {
      logwarn({
        message: 'Leavinge zync meeting.',
        userId,
        meetingId,
        meetingSeriesId,
      });
      sendEvent(userId, meetingId, {
        type: 'LEAVE_MEETING',
        userId,
      });
    }
  }

  render() {
    if (this.state.hasError) {
      const { imageSrc, title, subtitle } = getRandomErrorView();

      return (
        <ZyncErrorModal
          title={title}
          message={subtitle}
          imgSrc={imageSrc}
        ></ZyncErrorModal>
      );
    }
    return this.props.children;
  }
}

const mapStateToProps = (state) => {
  // For some reason, pulling state.callState.state out of redux and returning it as a prop
  // breaks useEffects in meetings for zync recording bot (and likely other invisible users).
  // It is unclear why this occurs. If adding any additional properties here, test that recording
  // stays functional.
  const { callState: { daily } = {}, meetingState: { meetingId } = {} } = state;
  return { daily, meetingId };
};

export default connect(mapStateToProps)(ErrorBoundary);
