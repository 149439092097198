import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import classNames from '../../helper/classNames';

const SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 50px;
  height: 20px;
  background: #8b33f7;
  border-radius: 12px;
  position: relative;
  transition: background-color 0.2s;

  ${({ checked }) => !checked && 'background-color: #333'};
  ${({ checked }) => !checked && 'opacity: 0.5'};

  ::before {
    content: ${({ checked, isCheckedText, isNotCheckedText }) =>
      checked ? `'${isCheckedText}'` : `'${isNotCheckedText}'`};
    position: absolute;
    font-size: ${({ theme }) => theme.typography.fontSize.small}px;
    ${({ checked }) => (checked ? 'left: 7.5px;' : 'right: 7.5px')};
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }

  &:active ${() => SwitchButton} {
    width: 25px;
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
    `}
  ${() => SwitchCheckboxInput}:checked + div ${() => SwitchLabel} ${() =>
    SwitchButton} {
    left: calc(100% - 3px);
    transform: translateX(-100%);
  }
`;

const SwitchButton = styled.div`
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  transition: 0.2s;
  background: #fff;
`;

const SwitchCheckboxInput = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;

/** A boolean input control. */
export const Switch = ({
  /** Optional. A unique name for the field. */
  type,
  /** The checked value, if controlled. */
  checked,
  /** The default checked value, if uncontrolled. */
  defaultChecked,
  /** Handle onChange. Passes the event as a parameter. */
  onChange,
  /** Handle onChange. Passes the checked value (true/false) as a parameter. */
  onChangeValue,
  /** Whether the input is disabled. */
  disabled = false,
  /** Customizable text for the switch.  */
  booleanText = ['ON', 'OFF'],
}) => {
  // use a random field name if `type` is not provided.
  const [fieldName] = useState(type ?? `${Math.random()}`);

  // keep track of the checked status if this is an uncontrolled component.
  const [value, setValue] = useState(defaultChecked);

  // given controlled/uncontrolled use, determines if the switch is checked.
  const isChecked = checked !== undefined ? checked : value;

  return (
    <SwitchContainer disabled={disabled}>
      <SwitchCheckboxInput
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={(event) => {
          setValue(event.target.checked);
          if (onChange) {
            onChange(event);
          } else if (onChangeValue) {
            onChangeValue(event.target.checked);
          }
        }}
        id={`switch-${fieldName}`}
        name={fieldName}
        type="checkbox"
      />
      <div
        className={classNames(
          'rounded-xl border border-solid border-transparent',
          !isChecked && 'hover:border-purple'
        )}
      >
        <SwitchLabel
          htmlFor={`switch-${fieldName}`}
          checked={isChecked}
          isCheckedText={booleanText[0]}
          isNotCheckedText={booleanText[1]}
        >
          <SwitchButton />
        </SwitchLabel>
      </div>
    </SwitchContainer>
  );
};
