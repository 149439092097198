const getContrastColor = (hexColor) => {
  if (!hexColor) {
    return hexColor;
  }
  // Convert hex color value to RGB values
  const red = parseInt(hexColor.substring(1, 3), 16);
  const green = parseInt(hexColor.substring(3, 5), 16);
  const blue = parseInt(hexColor.substring(5, 7), 16);

  // Calculate relative luminance of the color
  const luminance = (0.2126 * red + 0.7152 * green + 0.0722 * blue) / 255;

  // Choose white or black font color based on luminance
  return luminance > 0.5 ? '#000000' : '#FFFFFF';
};

function hexToHSL(hex) {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, '');

  // Parse r, g, b values
  let r = parseInt(hex.substring(0, 2), 16) / 255;
  let g = parseInt(hex.substring(2, 4), 16) / 255;
  let b = parseInt(hex.substring(4, 6), 16) / 255;

  // Find min and max values to get lightness
  let max = Math.max(r, g, b);
  let min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // Achromatic
  } else {
    let delta = max - min;
    s = l > 0.5 ? delta / (2 - max - min) : delta / (max + min);

    switch (max) {
      case r:
        h = (g - b) / delta + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / delta + 2;
        break;
      case b:
        h = (r - g) / delta + 4;
        break;
    }
    h *= 60;
  }

  // Convert H, S, L values to integers
  h = Math.round(h); // Round the hue value
  s = Math.round(s * 100); // Convert saturation to percentage and round
  l = Math.round(l * 100); // Convert lightness to percentage and round

  return `hsl(${h}, ${s}%, ${l}%)`;
}

function changeHSL(hslString, newSaturation = null, newLightness = null) {
  // Extract the hue, saturation, and lightness using regex
  const hslRegex = /hsl\((\d+),\s*([\d.]+)%,\s*([\d.]+)%\)/;
  const result = hslString.match(hslRegex);

  if (!result) {
    return `hsl(0, 100%, 100%)`;
  }

  let h = result[1]; // Hue
  let s = result[2]; // Saturation
  let l = result[3]; // Lightness

  // If newSaturation is provided, replace saturation with the new value
  if (newSaturation !== null) {
    s = Math.min(100, Math.max(0, newSaturation));
  }

  // If newLightness is provided, replace lightness with the new value
  if (newLightness !== null) {
    l = Math.min(100, Math.max(0, newLightness));
  }

  return `hsl(${h}, ${s}%, ${l}%)`;
}

module.exports = {
  getContrastColor,
  hexToHSL,
  changeHSL,
};
