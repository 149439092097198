import React from 'react';
import { Cards } from '../Cards';

import MessagePartials from './MessagePartials';

const RecordingUnsupportedAdmin = ({
  billingAdminEmail,
  plan,
  isSubscriptionSetup,
  isSubscriptionActive,
}) => {
  const { name } = plan;

  return (
    <div className="flex justify-between items-center">
      <div className="flex-grow w-full px-5">
        <MessagePartials.FeatureNotSupported
          planName={name}
          featureName="recording"
        />
        {isSubscriptionSetup ? (
          <MessagePartials.UnlockPotential />
        ) : (
          <MessagePartials.ContactYourAdmin
            billingAdminEmail={billingAdminEmail}
          />
        )}
      </div>
      <Cards plan={plan} isSubscriptionActive={isSubscriptionActive} />
    </div>
  );
};

export default RecordingUnsupportedAdmin;
