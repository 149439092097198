import React from 'react';
import { getStaticAssetUrl } from '../../../helper/getStaticAssetUrl';

const zyncLogo = getStaticAssetUrl('logo_text.png');
const appsumoLogo = getStaticAssetUrl('appsumo_logo.svg');

export const SignOnHeader = () => {
  return (
    <div className="flex flex-col z-[2] items-center gap-2 pt-[88px] md:pt-0">
      <div className="flex gap-2 items-center justify-center">
        <div className="h-11 flex justify-center items-center">
          <img
            className="object-cover w-[100px] md:w-[120px]"
            src={zyncLogo}
            alt="zync logo"
          />
        </div>
        <p className="font-medium text-blue-dark mr-1">with</p>
        <div className="h-11 flex justify-center items-center">
          <img
            className="object-cover w-[100px] md:w-[120px]"
            src={appsumoLogo}
            alt="zync logo"
          />
        </div>
      </div>
    </div>
  );
};
