import { useState, useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { sendEvent } from '../helper/api';
import { useProcessedActions } from '../helper/useProcessedActions';
import { useStartLoading } from './useStartLoading';
import { MUTED_BY_MODERATOR } from '../helper/audio';

/**
 * NB: Update with loading logic + useProcessedActions when muting users is
 * added to the meeting state.
 */
export const useMuteMeetingUsers = ({ users, user, meetingId, reason }) => {
  const [muteAllUsersActionId, setMuteAllUsersActionId] = useState(null);

  const { hasActionCompleted: hasMuteAllUsersCompleted } =
    useProcessedActions(muteAllUsersActionId);

  /**
   * Mutes all selected users.
   */
  const muteMeetingUsers = useCallback(async () => {
    const actionId = uuidv4();
    setMuteAllUsersActionId(actionId);
    const otherUsers = users.filter(
      (currentUser) => user?.userId !== currentUser?.userId
    );
    sendEvent(user?.userId, meetingId, {
      type: 'MUTE_ALL_USERS',
      users: otherUsers,
      actionId,
      reason: reason || MUTED_BY_MODERATOR,
    });
  }, [meetingId, user?.userId, users, reason]);

  /**
   * Mute All Users Loading Logic
   */
  const {
    isLoading: isMuteAllUsersLoading,
    onClickWithLoading: handleMuteAllUsers,
    disableLoading,
  } = useStartLoading(muteMeetingUsers, undefined, undefined, 'Mute Users');

  useEffect(() => {
    if (hasMuteAllUsersCompleted() && isMuteAllUsersLoading) {
      disableLoading();
    }
  }, [
    hasMuteAllUsersCompleted,
    muteAllUsersActionId,
    disableLoading,
    isMuteAllUsersLoading,
  ]);

  return {
    handleMuteAllUsers,
    isMuteAllUsersLoading,
  };
};
