import { useCallback, useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { PLANS } from 'zync-common/zyncCustomerPlans';
import {
  createStripeSubscriptionApi,
  updateStripeSubscriptionApi,
} from '../helper/api';
import { logerror, loginfo } from '../helper/contextualLogger';

function countMembersWithPlan(members, plan) {
  return members?.filter((m) => m.plan === plan)?.length ?? 0;
}

function findExistingSubscriptionItemByPrice(subscription, price) {
  return subscription.items.data.find((si) => si.price.id === price.id);
}

function getTeamPlanPrice(prices) {
  return prices?.find((p) => p.lookup_key === PLANS.team.id);
}

function getEventPlanPrice(prices) {
  return prices?.find((p) => p.lookup_key === PLANS.event.id);
}

export const useChangeCustomerPlan = ({
  workspace,
  selectedMember,
  onComplete,
  stripeConfig,
  existingSubscription,
}) => {
  const { planPrices, publishableKey } = stripeConfig || {};

  const teamPlanPrice = getTeamPlanPrice(planPrices);
  const eventPlanPrice = getEventPlanPrice(planPrices);

  const updatedMember = useRef(selectedMember);

  const [paymentRequired, setPaymentRequired] = useState(false);
  const [pendingSubscription, setPendingSubscription] = useState(null);
  const [lastSubscriptionError, setLastSubscriptionError] = useState(null);
  const [showNotBillingOwnerMessage, setShowNotBillingOwnerMessage] =
    useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const { user } = useSelector((st) => st.auth, shallowEqual);
  const { userId } = user;

  const { plan: currentPlan, userId: memberToChangeUserId } =
    selectedMember || {};

  const {
    workspaceId,
    name: workspaceName,
    members: workspaceMembers,
  } = workspace || {};

  const isWorkspaceBillingOwnerSet = Boolean(workspace?.billingOwnerUserId);
  const isWorkspaceBillingOwner = userId === workspace?.billingOwnerUserId;

  const teamPlanPriceId = teamPlanPrice?.id;
  const eventPlanPriceId = eventPlanPrice?.id;

  const createSubscription = useCallback(
    async (teamPlanCount, eventPlanCount) => {
      const subscriptionItems = [];
      if (teamPlanCount > 0) {
        subscriptionItems.push({
          quantity: teamPlanCount,
          price: teamPlanPriceId,
        });
      }
      if (eventPlanCount > 0) {
        subscriptionItems.push({
          quantity: eventPlanCount,
          price: eventPlanPriceId,
        });
      }
      setLastSubscriptionError(null);
      loginfo({
        userId,
        message: `Creating stripe subscription for workspace ${workspaceId} : ${workspaceName} with items ${JSON.stringify(
          subscriptionItems
        )}`,
      });
      const { result, error } = await createStripeSubscriptionApi(
        userId,
        subscriptionItems,
        {
          workspaceId: workspaceId,
          workspaceName: workspaceName,
          userId,
        }
      );
      if (error) {
        setLastSubscriptionError(error);
      }
      if (result) {
        const { subscription } = result;
        return subscription;
      }
    },
    [teamPlanPriceId, eventPlanPriceId, userId, workspaceId, workspaceName]
  );

  const updateSubscription = useCallback(
    async (teamPlanCount, eventPlanCount) => {
      const subscriptionItems = [];

      const existingTeamItem = findExistingSubscriptionItemByPrice(
        existingSubscription,
        teamPlanPrice
      );
      if (existingTeamItem) {
        subscriptionItems.push({
          id: existingTeamItem.id,
          quantity: teamPlanCount,
        });
      } else {
        subscriptionItems.push({
          quantity: teamPlanCount,
          price: teamPlanPrice.id,
        });
      }

      const existingEventItem = findExistingSubscriptionItemByPrice(
        existingSubscription,
        eventPlanPrice
      );
      if (existingEventItem) {
        subscriptionItems.push({
          id: existingEventItem.id,
          quantity: eventPlanCount,
        });
      } else {
        subscriptionItems.push({
          quantity: eventPlanCount,
          price: eventPlanPrice.id,
        });
      }
      setLastSubscriptionError(null);
      loginfo({
        userId,
        message: `Updating stripe subscription ${
          existingSubscription.id
        } for workspace ${workspaceId} : ${workspaceName} with items ${JSON.stringify(
          subscriptionItems
        )}`,
      });
      const { result, error } = await updateStripeSubscriptionApi(
        existingSubscription.id,
        subscriptionItems
      );
      if (error) {
        setLastSubscriptionError(error);
      }
      if (result) {
        const { updatedSubscription } = result;
        return updatedSubscription;
      }
    },
    [
      teamPlanPrice,
      eventPlanPrice,
      existingSubscription,
      userId,
      workspaceId,
      workspaceName,
    ]
  );

  /** called after payment was competed successfully */
  const onPaymentSuccess = useCallback(
    async (subscription) => {
      return onComplete({
        subscription: pendingSubscription || subscription,
        updatedMember: updatedMember.current,
      });
    },
    [onComplete, pendingSubscription]
  );

  const changePlan = useCallback(
    async (newPlan) => {
      if (
        !existingSubscription &&
        workspace &&
        workspace.stripeSubscriptionId
      ) {
        logerror({
          userId: user.userId,
          message:
            'Stripe subscription has not yet been loaded from the server.',
        });
        return;
      }

      setSelectedPlan(newPlan);
      updatedMember.current = { ...updatedMember.current, plan: newPlan };

      // Get updated plan counts.
      const allOtherMembers = workspaceMembers.filter(
        (m) => m.userId !== memberToChangeUserId
      );
      let teamPlanCount = countMembersWithPlan(allOtherMembers, PLANS.team.id);
      if (newPlan === PLANS.team.id) teamPlanCount++;
      let eventPlanCount = countMembersWithPlan(
        allOtherMembers,
        PLANS.event.id
      );
      if (newPlan === PLANS.event.id) eventPlanCount++;

      if (existingSubscription) {
        const subscription = await updateSubscription(
          teamPlanCount,
          eventPlanCount
        );
        if (subscription) {
          setPendingSubscription(subscription);
          onPaymentSuccess(subscription);
        }
      } else if (!isWorkspaceBillingOwnerSet || isWorkspaceBillingOwner) {
        const subscription = await createSubscription(
          teamPlanCount,
          eventPlanCount
        );

        if (subscription) {
          setPendingSubscription(subscription);
          const clientSecret =
            subscription.latest_invoice?.payment_intent?.client_secret;
          if (!clientSecret) {
            // No payment is required, likely due to the user having a balance on Stripe.
            onPaymentSuccess(subscription);
            return;
          }
          setPaymentRequired(true);
        }
      } else {
        setShowNotBillingOwnerMessage(true);
      }
      setSelectedPlan(null);
    },

    [
      createSubscription,
      workspaceMembers,
      memberToChangeUserId,
      isWorkspaceBillingOwner,
      isWorkspaceBillingOwnerSet,
      onPaymentSuccess,
      existingSubscription,
      updateSubscription,
      workspace,
      user.userId,
    ]
  );

  useEffect(() => {
    updatedMember.current = selectedMember;
  }, [selectedMember]);

  return {
    currentPlan,
    selectedPlan,
    paymentRequired,
    changePlan,
    pendingSubscription,
    user,
    onPaymentSuccess,
    showNotBillingOwnerMessage,
    lastSubscriptionError,
    teamPlanPrice,
    eventPlanPrice,
    publishableKey,
  };
};
