import { BlockFunctionConstants } from './constants';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { AbsolutePositioned } from './helper/Layout';
import classNames from '../helper/classNames';
import { sendEvent } from '../helper/api';
import mixpanel from 'mixpanel-browser';

export const MAX_SEGMENTS = 40;
export const MAX_CHARACTERS = 4000;

export const BlockBuilderFunctions = {
  [BlockFunctionConstants.hasSummaryContent]: () => false,
};

const Block = ({ block, user, eventDispatch, isAuthoring }) => {
  const { position, state, settings } = block || {};
  const { script: blockStateScript } = state || {};
  const { script = '' } = settings || {};
  const [isEditableMode, setIsEditableMode] = useState(false);
  const [localText, setLocalText] = useState(blockStateScript);
  const userId = useSelector((st) => st.auth?.user?.userId);
  const authoringSegments = script.split('\n\n').filter((s) => s.length !== 0);
  const meetingId = useSelector((state) => state.meetingState.meetingId);
  const meetingStateScript = useSelector(
    (state) => state.meetingState.state?.script
  );
  const segments = (isAuthoring ? script : meetingStateScript || localText)
    .split('\n\n')
    .filter((s) => s.length !== 0);
  const isInitialized = useRef(false);
  const wentLive = useSelector((state) => state.meetingState.state?.wentLive);

  const handleUpdateVideoScript = useCallback(async () => {
    mixpanel.track('VideoScriptEditorBlock: Video Script Updated', {
      distinct_id: userId,
    });

    const updateVideoScriptAction = {
      type: 'UPDATE_VIDEO_SCRIPT',
      userId,
      data: { newScript: localText },
    };

    await sendEvent(userId, meetingId, updateVideoScriptAction);
    eventDispatch(updateVideoScriptAction);
  }, [eventDispatch, localText, meetingId, userId]);

  useEffect(() => {
    if (isAuthoring) return;
    if (isInitialized.current) return;
    if (meetingStateScript === blockStateScript) return;
    isInitialized.current = true;
    const setVideoScriptInMeetingState = async () => {
      handleUpdateVideoScript();
    };

    setVideoScriptInMeetingState();
  }, [
    blockStateScript,
    handleUpdateVideoScript,
    isAuthoring,
    meetingStateScript,
  ]);

  const lines = (localText || '').split(/\n\n/);

  return (
    <AbsolutePositioned position={position} className="flex flex-col gap-9">
      <div className="flex gap-[5px] justify-end h-[40px]">
        {isEditableMode ? (
          <>
            <button
              className="w-[86px] h-[40px] font-montserrat bg-[#EA4748] rounded-xl font-bold text-white"
              onClick={() => setIsEditableMode(false)}
            >
              Cancel
            </button>
            <button
              className="w-[86px] h-[40px] font-montserrat bg-[#0C89FF] rounded-xl font-bold text-white disabled:opacity-50"
              onClick={async () => {
                await handleUpdateVideoScript();
                setIsEditableMode(false);
              }}
              disabled={
                localText?.length > MAX_CHARACTERS ||
                lines.length > MAX_SEGMENTS
              }
            >
              Save
            </button>
          </>
        ) : (
          <button
            className={classNames(
              'w-[92px] h-[40px] font-montserrat  rounded-xl text-white font-bold ',
              wentLive ? 'bg-[#525252] opacity-25' : 'bg-[#0C89FF]'
            )}
            onClick={() => setIsEditableMode(true)}
            disabled={wentLive}
          >
            Edit
          </button>
        )}
      </div>
      <div
        className={classNames(
          'flex rounded-[25px] py-[26px] w-full',
          !isEditableMode &&
            'border border-[#FFFFFF40] bg-[#363636] h-[calc(100%-50px)]',
          isEditableMode && 'bg-white h-full'
        )}
      >
        {isEditableMode ? (
          <div className="w-full">
            <textarea
              className="bg-white resize-none outline-none w-full h-full px-[26px] video-script-scrollbar font-roboto"
              maxLength={MAX_CHARACTERS}
              defaultValue={blockStateScript}
              onChange={(e) => {
                setLocalText(e.target.value);
              }}
            />
          </div>
        ) : (
          <div className="max-w-full max-h-full flex flex-col gap-2 overflow-y-auto video-script-scrollbar px-[26px] w-full">
            {isAuthoring
              ? authoringSegments.map((s, index) => (
                  <div
                    className="py-4 px-[25px] border border-[#FFFFFF40] rounded-[10px] font-roboto text-[#D0D0D0] w-full"
                    key={`video-script-editor-segment-${index}`}
                  >
                    {s}
                  </div>
                ))
              : segments.map((s, index) => (
                  <div
                    className="py-4 px-[25px] border border-[#FFFFFF40] rounded-[10px] font-roboto text-[#D0D0D0] w-full"
                    key={`video-script-editor-segment-${index}`}
                  >
                    {s}
                  </div>
                ))}
          </div>
        )}
      </div>
    </AbsolutePositioned>
  );
};

const AuthoringBlock = (props) => {
  return <Block {...props} isAuthoring />;
};

export { Block, AuthoringBlock };
