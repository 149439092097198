import React, { memo } from 'react';

import { ZyncErrorModal } from '../components/ZyncErrorModal';
import { getStaticAssetUrl } from '../helper/getStaticAssetUrl';

const error404CatSrc = getStaticAssetUrl('.404_cat.svg');

export const Page404 = memo(() => {
  return (
    <ZyncErrorModal
      title="Oops! you weren't supposed to see this."
      message="The page you are looking for does not exist."
      imgSrc={error404CatSrc}
    >
      <a
        href="/portal"
        className="font-medium text-purple decoration-purple decoration-1 underline text-base sm:text-2xl"
      >
        Please return to Portal
      </a>
    </ZyncErrorModal>
  );
});
