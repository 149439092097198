import { Button } from './common/Button';

export const EjectMeetingUsersButton = ({
  isEjectAllUsersLoading,
  showEjectAllUsersPopup,
}) => {
  return (
    <Button
      onClick={() => {
        showEjectAllUsersPopup();
      }}
      state={
        isEjectAllUsersLoading ? Button.states.LOADING : Button.states.DEFAULT
      }
    >
      <span className="text-sm">Eject All</span>
    </Button>
  );
};
