import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const DEFAULT_SIDEBAR_WIDTH = 330;
const SCENE_SEEKER_WIDTH = 178;
const QUESTIONS_WIDTH = 345;

export const useLiveTab = () => {
  const liveTab = useSelector((st) => st.clientDetails.liveTab);

  const isLiveTabActive = Boolean(liveTab);

  const [liveTabWidth, setLiveTabWidth] = useState(0);

  useEffect(() => {
    const queryAndCalculateSidebarWidth = () => {
      if (isLiveTabActive) {
        switch (liveTab) {
          case 'scenes': {
            setLiveTabWidth(SCENE_SEEKER_WIDTH);
            break;
          }
          case 'questions': {
            setLiveTabWidth(QUESTIONS_WIDTH);
            break;
          }
          case 'timeline':
          case 'chat':
          default:
            setLiveTabWidth(DEFAULT_SIDEBAR_WIDTH);
        }
      } else {
        setLiveTabWidth(0);
      }
    };

    queryAndCalculateSidebarWidth();
  }, [liveTab, isLiveTabActive]);

  return {
    liveTab,
    liveTabWidth,
    isLiveTabActive,
  };
};
