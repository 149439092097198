import React, { useRef } from 'react';
import { useClickOutside } from '../helper/useClickOutside';
import ReactDOM from 'react-dom';

import keyboardShortcutsOS from '../helper/keyboardShortcutsOS';

const ActionText = ({ children }) => {
  return <span className="text-sm">{children}</span>;
};

const KeyboardShortcutText = ({ children }) => {
  return <span className="text-light-gray text-xs">{children}</span>;
};
export const AuthoringContextMenuBlockActions = ({
  handleMoveBackward,
  handleMoveForward,
  handleMoveToTop,
  handleMoveToBottom,
}) => {
  return (
    <>
      <button
        className="flex gap-2 items-center text-blue-gray hover:text-blue-light hover:bg-blue-light/5 px-3 py-1.5 w-full rounded-md disabled:opacity-50 disabled:bg-white disabled:text-blue-gray"
        onClick={handleMoveToTop}
      >
        <ActionText>Move to top</ActionText>
      </button>
      <button
        className="flex gap-2 items-center text-blue-gray hover:text-orange-light hover:bg-orange-light/5 px-3 py-1.5 w-full rounded-md disabled:opacity-50 disabled:bg-white disabled:text-blue-gray"
        onClick={handleMoveForward}
      >
        <ActionText>Move Forward</ActionText>
      </button>
      <button
        className="flex gap-2 items-center text-blue-gray hover:text-orange-light hover:bg-orange-light/5 px-3 py-1.5 w-full rounded-md disabled:opacity-50 disabled:bg-white disabled:text-blue-gray"
        onClick={handleMoveBackward}
      >
        <ActionText>Move Backward</ActionText>
      </button>
      <button
        className="flex gap-2 items-center text-blue-gray hover:text-red hover:bg-red/5 px-3 py-1.5 w-full rounded-md disabled:opacity-50 disabled:bg-white disabled:text-blue-gray"
        onClick={handleMoveToBottom}
      >
        <ActionText>Move to bottom</ActionText>
      </button>
    </>
  );
};

export const AuthoringContextMenuStandardActions = ({
  handleCopy,
  handlePaste,
  handleDuplicate,
  handleRemove,
}) => {
  return (
    <>
      <button
        className="flex justify-between gap-2 items-center text-blue-gray hover:text-cyan hover:text-cyan hover:bg-cyan/5 px-3 py-1.5 w-full rounded-md"
        onClick={handleCopy}
      >
        <ActionText>Copy</ActionText>
        <KeyboardShortcutText>{keyboardShortcutsOS.COPY}</KeyboardShortcutText>
      </button>

      <button
        className="flex justify-between gap-2 items-center text-blue-gray hover:text-blue-light hover:bg-blue-light/5 px-3 py-1.5 w-full rounded-md disabled:opacity-50 disabled:bg-white disabled:text-blue-gray"
        onClick={handlePaste}
      >
        <ActionText>Paste</ActionText>
        <KeyboardShortcutText>{keyboardShortcutsOS.PASTE}</KeyboardShortcutText>
      </button>
      <button
        className="flex justify-between gap-2 items-center text-blue-gray hover:text-purple hover:bg-purple/5 px-3 py-1.5 w-full rounded-md"
        onClick={handleDuplicate}
      >
        <ActionText>Duplicate</ActionText>
        {/* NOT IMPLEMENTED YET <KeyboardShortcutText>
          {keyboardShortcutsOS.DUPLICATE}
        </KeyboardShortcutText> */}
      </button>
      <button
        className="flex justify-between gap-2 items-center text-blue-gray hover:text-red hover:bg-red/5 px-3 py-1.5 w-full rounded-md"
        onClick={handleRemove}
      >
        <ActionText>Remove</ActionText>
        <KeyboardShortcutText>
          {keyboardShortcutsOS.REMOVE}
        </KeyboardShortcutText>
      </button>
    </>
  );
};

export const ContextMenu = ({
  children,
  className,
  onClose,
  parentRef,
  containerId,
  style,
}) => {
  const containerRef = useRef(null);

  useClickOutside(containerRef, onClose);

  if (containerId) {
    const { y, x } = parentRef.current.getBoundingClientRect();

    return ReactDOM.createPortal(
      <div
        ref={containerRef}
        className={`absolute z-10 bg-white rounded-md p-2 shadow-200 space-y-2 ${className}`}
        style={{
          top: y,
          left: x,
          ...style,
        }}
      >
        {children}
      </div>,
      document.getElementById(containerId)
    );
  } else {
    return (
      <div
        ref={containerRef}
        className={`absolute z-10 w-full -translate-x-[4px] bg-white rounded-md p-2 shadow-200 space-y-2 ${className}`}
      >
        {children}
      </div>
    );
  }
};
