import styled, { css } from 'styled-components/macro';
import { SimpleButton } from '../../Button';
import { H3Text, H4Text } from '../../common/Text';
import { scrollbarCss } from '../../../helper/css';

export const InviteTabWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)}px;

  * {
    box-sizing: border-box;
  }

  ${() => H3Text} {
    color: white;
  }
`;

export const AttendeeEditContainer = styled.div`
  ${() => H4Text} {
    color: ${({ theme }) => theme.palette.text.primaryContrast};
  }

  ${() => AddRoleButton} {
    width: 26px;
    height: 26px;
  }

  ${() => RoleTagDiv} {
    cursor: default;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
  }

  ${() => AddRoleInputWrapper} {
    height: 34px;
    position: sticky;
    top: 0;
    background: white;
  }

  ${() => AddRolePopupWindowWrapper} {
    max-height: 180px;
    height: unset;
    padding: 10px;
    font-family: Poppins;
    z-index: 50;
  }
`;

export const AttendeeEditActions = styled.div`
  display: flex;
  gap: 8px;
  margin: 8px 12px;
  justify-content: flex-start;
`;

export const H4 = styled.h4`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.15px;
  text-align: left;
  margin: 0;
`;

export const H5 = styled.h5`
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  margin-bottom: 15px;
`;

export const commonButtonHoverStyles = css`
  :hover,
  :focus {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const commonInviteButtonsStyles = css`
  border: none;
  background: none;
  padding: 9px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;

  ${commonButtonHoverStyles}
`;

export const ButtonInvitePeople = styled.button`
  ${commonInviteButtonsStyles};
  margin-top: 24px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
  color: #17c2c0;
`;

export const ButtonAddTestUser = styled.button`
  ${commonInviteButtonsStyles};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
  color: #3d3d46;

  svg {
    color: #a8a8a8;
  }
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${({ color }) => color || 'rgba(224, 224, 224, 1)'};
  height: 1px;
  border-collapse: collapse;
  margin: ${({ margin }) => (margin ? margin : '20px')} 0;
`;

export const AttendeeContainerClickable = styled.div`
  ${commonButtonHoverStyles};

  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border: none;

  transition: all 120ms ease;
  background: ${({ active }) =>
    active ? 'rgba(255, 255, 255, 0.05)' : 'none'};
  border-radius: 4px;
  cursor: pointer;

  svg path {
    fill: ${({ theme }) => theme.palette.text.primaryContrast};
  }
`;

export const AttendeeDetailsSummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const AttendeeContainerDiv = styled.div`
  padding: 8px;
  display: flex;
  width: 100%;
`;

export const AttendeeAvatar = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  height: ${({ width }) => (width ? width : '32px')};
  width: ${({ width }) => (width ? width : '32px')};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

export const AttendeeInitials = styled.span`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '10px')};
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 1.5px;
  text-align: center;
  color: #fff;
`;

export const AttendeeUserDetailsContainer = styled.div`
  position: relative;
  padding-left: 1em;
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: visible;
`;

export const AttendeeUserName = styled.span`
  display: block;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: ${({ theme }) => theme.palette.text.primaryContrast};
`;

export const AttendeeId = styled.span`
  display: block;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-align: left;
  color: ${({ theme }) => theme.palette.text.secondaryContrast};
  user-select: text;

  :hover {
    text-decoration: underline;
  }
`;

export const AttendeeUserRoles = styled.span`
  display: block;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  text-align: left;
  color: ${({ theme }) => theme.palette.text.secondaryContrast};
`;

export const IconPositionContainer = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  display: flex;
  justify-content: center;
`;

export const AttendeeEditSpaceBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  padding-left: ${({ theme }) => theme.spacing(1.5)}px;
`;

export const ModalSectionHeader = styled.h6`
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin: 0 0 8px 0;
`;

export const EjectButton = styled.button`
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  background: none;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
  color: ${({ disabled }) => (disabled ? '#bbb' : '#db2929')};
  padding: 0;
  margin-left: ${({ theme }) => theme.spacing(1.5)}px;
`;

export const RoleTagGroupDiv = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;

  margin-left: 0;
  ${({ darkTheme }) =>
    darkTheme &&
    css`
      ${() => AddRoleButton} {
        background: #3d3d46;
        border-color: ${({ theme }) => theme.palette.text.primaryContrast};
        color: ${({ theme }) => theme.palette.text.primaryContrast};
      }
    `}
`;

export const RoleTagDiv = styled.div`
  cursor: default;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 4px 8px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ fontColor }) => fontColor};
  margin-right: 8px;
  margin-bottom: 8px;
  max-width: 100%;
  word-break: break-word;
`;

export const RoleTagRemoveButton = styled.button`
  background: none;
  border: none;
  color: ${({ fontColor }) => fontColor};
  cursor: pointer;
  padding-right: 0;
`;

export const AddRoleButton = styled.button`
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.25);
  border: 2px solid rgba(0, 0, 0, 0.25);
  height: 20px;
  width: 20px;
  font-size: 20px;
  cursor: pointer;
`;

export const AddRolePopupWindowWrapper = styled.div`
  box-sizing: border-box;
  background-color: #fff;
  position: absolute;
  height: 180px;
  left: 0;
  margin-top: 10px;
  border-radius: 8px;
  overflow-y: auto;
  padding: 24px;
  box-shadow: 0 0 10px 0 rgba(102, 102, 102, 0.25);
  z-index: 10;
  ${scrollbarCss(true, false)}
`;

export const AddRoleInputWrapper = styled.div`
  position: sticky;
  top: 0;
  background: white;
  width: 100%;
  border: 1px solid #e0e0e0;
  height: 48px;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  &:focus-within {
    border: 1px solid #848484;
  }

  :hover {
    border: 1px solid #e0e0e0;
  }
`;

export const AddRoleInput = styled.input`
  flex: 1;
  border: none;
  background: none;
  outline: none;
  padding-left: 8px;
  padding-right: 0;
  width: 100%;
`;

export const InviteModalHeader = styled(H4)`
  margin-top: -1.5em;
  margin-bottom: 24px;
`;

export const InviteModalInput = styled.input`
  box-sizing: border-box;
  border: 1px solid rgba(224, 224, 224, 1);
  width: 100%;
  height: 48px;
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 10px;
`;

export const InviteModalButton = styled(SimpleButton)`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const UrlLinkWrapper = styled(AddRoleInputWrapper)`
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;

  &:focus-within {
    border-color: #00a3a5;
  }
`;

export const UrlLinkLabel = styled.p`
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.005em;
`;

export const AnimatedSlideDownDiv = styled.div`
  transition: all 310ms ease;
  opacity: ${({ active }) => (active ? 1 : 0)};
  max-height: ${({ active }) => (active ? '100vh' : 0)};
  overflow: hidden;
`;

const expandStyles = css`
  :hover:before,
  :hover:after {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }

  :hover:after {
    transition: all 0.2s 0.1s ease-in-out;
  }
`;
export const ExpandingTooltip = styled.div`
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.tooltip};

  :before,
  :after {
    display: block;
    opacity: 0;
    pointer-events: none;
    position: absolute;
  }

  :after {
    border-right: 6px solid transparent;
    border-bottom: 6px solid ${({ theme }) => theme.palette.primary.main};
    border-left: 6px solid transparent;
    content: '';
    height: 0;
    top: 20px;
    left: 20px;
    width: 0;
  }

  :before {
    background-color: ${({ theme }) => theme.palette.primary.main};
    border-radius: ${({ theme }) => theme.borderRadius.tooltip}px;
    color: ${({ theme }) => theme.palette.primary.contrastText};
    content: ${({ tooltipText }) => `"${tooltipText}"`};
    font-size: ${({ theme }) => theme.typography.fontSize.small}px;
    padding: 6px 10px;
    top: 26px;
    white-space: nowrap;
  }

  :before {
    transform: scale3d(0.2, 0.2, 1);
    transition: all 0.2s ease-in-out;
  }

  :after {
    transform: translate3d(0, 6px, 0);
    transition: all 0.1s ease-in-out;
  }

  ${({ show }) => show && expandStyles};
`;
