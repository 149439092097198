import classNames from '../../helper/classNames';
import { ArrowRightIcon } from '../icons/ArrowRightIcon';

export const StageArrows = ({
  handlePreviousClick,
  handleNextClick,
  previousButtonStyling,
  nextButtonStyling,
  showPreviousButton,
}) => {
  return (
    <div className="flex justify-between border border-transparent border-t-gray/50">
      {showPreviousButton ? (
        <button
          onClick={handlePreviousClick}
          type="button"
          className={classNames(
            'pl-10 py-4 font-semibold',
            previousButtonStyling
          )}
        >
          <ArrowRightIcon
            className={classNames(
              'inline-block rotate-180',
              previousButtonStyling
            )}
          />{' '}
          Previous
        </button>
      ) : (
        <div />
      )}
      <button
        onClick={handleNextClick}
        type="button"
        className={classNames(
          'pr-10 py-4 flex items-center gap-2 font-semibold',
          nextButtonStyling
        )}
      >
        Next{' '}
        <ArrowRightIcon
          className={classNames(
            'inline-block items-center gap-2',
            nextButtonStyling
          )}
        />
      </button>
    </div>
  );
};
