import React from 'react';

export const NotebookIcon = ({
  width = '21px',
  height = '21px',
  className = 'fill-purple',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 21 21"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.66667 9.80078V5.63411H3.41667C3.18655 5.63411 3 5.44757 3 5.21745V4.38411C3 4.154 3.18655 3.96745 3.41667 3.96745H4.66667C4.66667 3.04697 5.41286 2.30078 6.33333 2.30078H16.3333C17.2538 2.30078 18 3.04697 18 3.96745V17.3008C18 18.2213 17.2538 18.9674 16.3333 18.9674H6.33333C5.41286 18.9674 4.66667 18.2213 4.66667 17.3008H3.41667C3.18655 17.3008 3 17.1142 3 16.8841V16.0508C3 15.8207 3.18655 15.6341 3.41667 15.6341H4.66667V11.4674H3.41667C3.18655 11.4674 3 11.2809 3 11.0508V10.2174C3 9.98733 3.18655 9.80078 3.41667 9.80078H4.66667ZM11.75 3.96745C11.5199 3.96745 11.3333 4.154 11.3333 4.38411V10.2174C11.3315 10.3724 11.4159 10.5156 11.5524 10.5891C11.6889 10.6626 11.8549 10.6543 11.9833 10.5674L13.6 9.48411C13.7423 9.3932 13.9244 9.3932 14.0667 9.48411L15.6833 10.5674C15.8118 10.6543 15.9778 10.6626 16.1142 10.5891C16.2507 10.5156 16.3351 10.3724 16.3333 10.2174V4.38411C16.3333 4.154 16.1468 3.96745 15.9167 3.96745H11.75Z"
    />
  </svg>
);
