import React from 'react';
import classNames from '../../helper/classNames';
import { useState } from 'react';
import Lottie from 'react-lottie';
import { animationOptions } from './meetingControlAnimationOptions';
import attendeesAnimation from './Live meeting icons/Attendees.json';

export const MeetingControlButton = ({
  buttonTitle,
  meetingAction,
  selectedMeetingControlButton,
  setSelectedMeetingControlButton,
}) => {
  const handleButtonClick = () => {
    selectedMeetingControlButton !== meetingAction
      ? setSelectedMeetingControlButton(meetingAction)
      : setSelectedMeetingControlButton('');
  };

  const isAttendeesTabSelected = selectedMeetingControlButton === 'people';
  const [isAttendeeAnimationStopped, setIsAttendeeAnimationStopped] =
    useState(true);

  return (
    <button
      onMouseEnter={() => setIsAttendeeAnimationStopped(false)}
      onMouseLeave={() => setIsAttendeeAnimationStopped(true)}
      className={classNames(
        'cursor-pointer flex justify-center flex-col items-center active:translate-y-1 duration-100 h-14 w-14 rounded-xl hover:bg-opacity-10 hover:bg-purple',
        isAttendeesTabSelected &&
          'border-2 border-purple bg-opacity-10 bg-purple'
      )}
      onClick={handleButtonClick}
    >
      <div className="w-8 h-8 text-purple">
        <Lottie
          options={{ ...animationOptions, animationData: attendeesAnimation }}
          isStopped={isAttendeeAnimationStopped}
        />
      </div>
      <div className="text-xxs text-purple font-medium scale-95">
        {buttonTitle}
      </div>
    </button>
  );
};
