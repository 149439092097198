import { useClickOutside } from '../../../../helper/useClickOutside';
import { DropdownSelect } from '../../../../components/common/Dropdown';
import {
  ImageFileInput,
  NumberInput,
  SelectInput,
  TextInput,
} from '../../../../components/Input';
import React from 'react';

export const ImageSceneUpdate = ({
  posterSlideTemplate,
  setPosterSlideTemplate,
  setUpdate,
  onConfirm,
  onCancel,
  updateOnClose,
  series,
  hostNotesComponent,
}) => {
  const mainSpeakerBlock = posterSlideTemplate.blocks?.findLast(
    (block) => block.blockId === 'singlevideo'
  );
  const imageBlock = posterSlideTemplate.blocks?.findLast(
    (block) => block.blockId === 'image'
  );

  const handleConfirm = (shouldOpenNewlyCreated) =>
    onConfirm(posterSlideTemplate, undefined, shouldOpenNewlyCreated);

  useClickOutside({ current: document.querySelector('.modal') }, () => {
    updateOnClose && handleConfirm(false);
    onCancel();
  });

  const handleMainSpeakerBlockChange = (settingsKey, value) => {
    setPosterSlideTemplate((template) => {
      if (mainSpeakerBlock) {
        mainSpeakerBlock.settings[settingsKey] = value;
      }

      return template;
    });

    setUpdate((x) => x + 1);
  };

  const handleImageBlockChange = (settingsKey, value) => {
    setPosterSlideTemplate((template) => {
      const block = template.slideConfig.slideBlocks?.findLast(
        (block) => block.blockId === 'image'
      );

      if (block) {
        block.settings[settingsKey] = value;
      }

      return template;
    });

    setUpdate((x) => x + 1);
  };

  const eventPresenters = series.settings.eventPresenters;

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="flex flex-col gap-3">
        <div>
          <h4 className="text-sm font-medium text-blue-dark mb-1">
            Choose a Presenter
          </h4>
          <DropdownSelect
            selectedKey={
              mainSpeakerBlock.settings.userId ||
              eventPresenters.find((ep) => ep.type === 'host')?.emailAddress
            }
            options={eventPresenters.map((ep) => ({
              key: ep.emailAddress,
              title: ep.fullName,
            }))}
            onSelect={(value) => {
              handleMainSpeakerBlockChange('userId', value);
              handleConfirm();
            }}
          />
        </div>
        <div>
          <h4 className="text-sm font-medium text-blue-dark mb-1">Image URL</h4>
          <TextInput
            value={imageBlock.settings.imageUrl}
            onChange={(event) =>
              handleImageBlockChange('imageUrl', event.nativeEvent.target.value)
            }
            className="w-full p-2 border border-gray rounded"
            placeholder="Please include the URL of your image"
            onBlur={handleConfirm}
          />
          <ImageFileInput
            showInput={false}
            value={imageBlock.settings.imageUrl}
            onChange={(newImageUrl) => {
              handleImageBlockChange('imageUrl', newImageUrl);
              handleConfirm();
            }}
          />
        </div>
        <div>
          <h4 className="text-sm font-medium text-blue-dark mb-1">Opacity</h4>
          <NumberInput
            max="1"
            min="0"
            step={0.05}
            value={imageBlock.settings.opacity}
            onChange={(event) => {
              const value = parseFloat(event.nativeEvent.target.value);
              handleImageBlockChange(
                'opacity',
                value > 1 ? 1 : value <= 0 ? 0 : value
              );
            }}
            onBlur={handleConfirm}
          />
        </div>
        <div>
          <h4 className="text-sm font-medium text-blue-dark mb-1">Rotation</h4>
          <NumberInput
            max="360"
            min="0"
            step={15}
            value={parseInt(imageBlock.settings.rotation) || '0'}
            onChange={(event) => {
              const value = parseFloat(event.nativeEvent.target.value);
              handleImageBlockChange('rotation', value + 'deg');
            }}
            onBlur={handleConfirm}
          />
        </div>
        <div>
          <h4 className="text-sm font-medium text-blue-dark mb-1">Fill</h4>
          <SelectInput
            horizontal={true}
            flat={true}
            options={['cover', 'contain', 'fill']}
            defaultValue={imageBlock.settings.sizingMode}
            onChange={(value) => {
              handleImageBlockChange('sizingMode', value);
              handleConfirm();
            }}
          />
        </div>
        <div>
          <h4 className="text-sm font-medium text-blue-dark mb-1">Shape</h4>
          <SelectInput
            horizontal={true}
            flat={true}
            options={['rectangle', 'circle']}
            defaultValue={imageBlock.settings.shape}
            onChange={(value) => {
              handleImageBlockChange('shape', value);
              handleConfirm();
            }}
          />
        </div>
      </div>
      <div>{hostNotesComponent}</div>
    </div>
  );
};
