import React, { useCallback, useRef, useState } from 'react';
import { css } from 'styled-components';

import styled from 'styled-components/macro';
import { getStaticAssetUrl } from '../../helper/getStaticAssetUrl';
import { useClickOutside } from '../../helper/useClickOutside';

import { baseButtonStyles } from '../../theme/baseStyles';
import { ActionMenu } from './ActionMenu';

const threeDotIcon = getStaticAssetUrl('threedot.svg');

export const BUTTON_VARIANTS = {
  minimal: 'minimal',
  white: 'white',
};

export const OPENING_DIRECTIONS = {
  TOP_TO_BOTTOM: 'topToBottom',
  BOTTOM_TO_TOP: 'bottomToTop',
};

export const ThreeDotMenuTrigger = ({
  variant = BUTTON_VARIANTS.minimal,
  items = [],
  openDirection = OPENING_DIRECTIONS.TOP_TO_BOTTOM,
}) => {
  const rootRef = useRef(null);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setIsMenuOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  useClickOutside(rootRef, handleClose);

  return (
    <Container ref={rootRef}>
      <Clickable
        variant={variant}
        onClick={isMenuOpen ? handleClose : handleOpen}
      >
        <img
          width="3px"
          className="mx-auto"
          src={threeDotIcon}
          alt="Click to open context menu"
        />
      </Clickable>
      {items.length > 0 && (
        <FloatingWrapper isOpen={isMenuOpen} openDirection={openDirection}>
          <ActionMenu actionItems={items} />
        </FloatingWrapper>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: inline-block;
  width: min-content;
  position: relative;
`;

const clickableVariants = {
  minimal: css`
    background-color: transparent;
  `,
  white: css`
    background-color: ${({ theme }) => theme.palette.common.white};
  `,
};

const Clickable = styled.button`
  ${baseButtonStyles};
  border-radius: 50px;
  box-sizing: border-box;
  width: 34px;
  height: 34px;
  padding: 0;
  text-align: center;
  ${({ variant }) => clickableVariants[variant]};
`;

const getTransformOrigin = (openDirection) => {
  switch (openDirection) {
    case OPENING_DIRECTIONS.BOTTOM_TO_TOP: {
      return 'right bottom';
    }
    case OPENING_DIRECTIONS.TOP_TO_BOTTOM:
    default: {
      return 'right top';
    }
  }
};

const getTransform = (openDirection, isOpen) => {
  switch (openDirection) {
    case OPENING_DIRECTIONS.BOTTOM_TO_TOP: {
      return `translate(-15px, -25%) ${isOpen ? 'scale(1)' : 'scale(0)'}`;
    }
    case OPENING_DIRECTIONS.TOP_TO_BOTTOM:
    default: {
      return `translate(-15px, 100%) ${isOpen ? 'scale(1)' : 'scale(0)'}`;
    }
  }
};

const FloatingWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid #ebeaed;
  box-sizing: border-box;
  border-radius: 8px;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
  transform-origin: ${({ openDirection }) => getTransformOrigin(openDirection)};
  transform: ${({ openDirection, isOpen }) =>
    getTransform(openDirection, isOpen)};
  transition: transform 300ms
    ${({ isOpen }) => (isOpen ? 'cubic-bezier(.17,.67,.19,1.33)' : 'ease-in')};
  will-change: transform;
  z-index: 100;
`;
