import React from 'react';

import { RichTextUnorderedListIcon } from '../../icons/RichTextUnorderedListIcon';
import { RichTextOrderedListIcon } from '../../icons/RichTextOrderedList';

import ToolbarButton from './ToolbarButton';

const BLOCK_TYPES = [
  {
    label: 'Unordered List',
    Icon: RichTextUnorderedListIcon,
    style: 'unordered-list-item',
  },
  {
    label: 'Ordered List',
    Icon: RichTextOrderedListIcon,
    style: 'ordered-list-item',
  },
];

/* This component renders block style controls. Blocks can be headers, blockquotes, code formatters and so on */
export const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <>
      {BLOCK_TYPES.map((type) => (
        <ToolbarButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
          Icon={type.Icon}
        />
      ))}
    </>
  );
};
