import React, { useEffect, useState } from 'react';

export const ColorSelectInput = ({ label, value, onChange, onBlur, width }) => {
  const [color, setColor] = useState(value || '#000000');

  useEffect(() => {
    setColor(value);
  }, [value]);

  const handleChange = (event) => {
    const value = event.target?.value?.substr(0, 7);
    setColor(value);
    onChange(value);
  };

  return (
    <div className="flex-1">
      <label className="text-blue-dark text-sm font-medium whitespace-nowrap">
        {label}
      </label>
      <div className="relative p-2 flex items-center border border-[#EBEAED] rounded-sm bg-white mt-3">
        <div className="absolute w-5 h-5 overflow-hidden">
          <input
            type="color"
            value={color || '#000000'}
            className="cursor-pointer h-12 w-12 block relative left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
            onChange={handleChange}
          />
        </div>
        <input
          type="text"
          className="pl-7 outline-none w-full text-blue-gray text-sm"
          value={color}
          onChange={handleChange}
          onBlur={onBlur}
          style={{
            width,
          }}
        />
      </div>
    </div>
  );
};
