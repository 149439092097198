import { useCallback, useEffect, useState } from 'react';
import { notifyUser } from '../components/authoring/hooks';
import { getSpeakersByWorkspaceIdApi } from '../helper/api';
const getWorkspaceSpeakers = (workspaceId) => {
  return getSpeakersByWorkspaceIdApi(workspaceId);
};
export const useSpeakersCrm = (workspaceId) => {
  const [speakers, setSpeakers] = useState([]);
  const [, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const refreshSpeakers = useCallback(() => {
    if (!workspaceId) {
      return;
    }

    setIsLoading(true);

    getWorkspaceSpeakers(workspaceId)
      .then((fetchedSpeakers) => {
        setError('');
        setSpeakers(fetchedSpeakers);
      })
      .catch((error) => {
        setError(error.message);
        notifyUser(
          'We could not fetch workspace speakers. Please refresh the page'
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [workspaceId]);

  useEffect(() => {
    refreshSpeakers();
  }, [refreshSpeakers]);

  return {
    speakers,
    isLoading,
    refreshSpeakers,
  };
};
