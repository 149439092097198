import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { updateSeriesApi, uploadPosterImage } from '../../../helper/api';
import { fromError, logerror } from '../../../helper/contextualLogger';
import { getStaticAssetUrl } from '../../../helper/getStaticAssetUrl';
import { Button } from '../../common/Button';
import { AttachmentIcon } from '../../icons/AttachmentIcon';
import { ImageUploader } from '../../ImageUploader';

const defaultPosterFileName = 'default_rsvp_poster.png';
const defaultPosterSrc = getStaticAssetUrl(defaultPosterFileName);

const fileNameWithoutSeriesId = (fileName) => fileName.split('/').at(-1);

export const PosterUploader = ({
  series,
  localDispatch,
  showPoster = true,
  showLabel = true,
  onChangeEnd,
  onSubmitStart,
  onSubmitEnd,
  disabled,
}) => {
  const { meetingSeriesId, settings } = series;

  const { posterImageUrl } = settings || {};

  const [isSubmittingFile, setIsSubmittingFile] = useState(false);

  const handleChange = async (newValue) => {
    try {
      const result = await updateSeriesApi(meetingSeriesId, {
        settings: { posterImageUrl: newValue },
      });
      localDispatch({ type: 'REFRESH_SERIES', series: result });
      onChangeEnd && onChangeEnd(false);
    } catch (error) {
      logerror(fromError(error));
    }
  };

  const handleSubmitPoster = async (buffer, type, name) => {
    setIsSubmittingFile(true);
    onSubmitStart && onSubmitStart(true);

    try {
      const uploadResult = await uploadPosterImage(
        meetingSeriesId + '/' + name,
        buffer,
        type
      );

      if (uploadResult) {
        const newUrl =
          'https://storage.googleapis.com/zync-media/' +
          uploadResult?.result?.metadata?.name;

        await handleChange(newUrl, true);
      }
    } catch (error) {
      logerror(fromError(error));
    }
    onSubmitEnd && onSubmitEnd(false);
    setIsSubmittingFile(false);
  };

  const handleRemovePoster = async () => {
    handleChange(defaultPosterSrc, false);
  };

  return (
    <div className="flex flex-col gap-2">
      {showLabel && <div className="font-medium text-sm">Poster</div>}
      {showPoster && !!posterImageUrl && posterImageUrl !== defaultPosterSrc && (
        <>
          <img
            className="max-h-[180px] self-start object-contain"
            src={posterImageUrl}
            alt="Your poster"
          />
          <div className="flex items-center gap-2">
            <div className="shrink-0">
              <AttachmentIcon />
            </div>
            <div className="text-[#717480]">
              {fileNameWithoutSeriesId(posterImageUrl)}
            </div>
            <button
              onClick={handleRemovePoster}
              className="border border-red rounded-full w-5 h-5 flex items-center justify-center scale-80"
            >
              <span className="sr-only">Click to remove the uploaded logo</span>
              <div className="shrink-0 w-5 h-5  flex items-center justify-center">
                <FontAwesomeIcon icon="times" size="xs" color="#fa2a66" />
              </div>
            </button>
          </div>
        </>
      )}
      <div className="w-40 -mt-1">
        <ImageUploader
          disabled={isSubmittingFile || disabled}
          loading={isSubmittingFile}
          label={
            <div className="flex gap-2 items-center justify-center">
              <AttachmentIcon className="stroke-white" />
              Upload Poster
            </div>
          }
          buttonColor={Button.colors.PURPLE}
          onImageSubmit={handleSubmitPoster}
          appendTimeStampToFile={true}
        />
      </div>
    </div>
  );
};
