import React from 'react';

export const DisabledCameraIcon = ({ width = 35, height = 35 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.41 2H19.5C19.7761 2 20 2.22386 20 2.5V11.5C20 11.7761 19.7761 12 19.5 12H19.41C19.1477 11.9989 18.8963 11.8947 18.71 11.71L16 9L16 12C16 13.1046 15.1046 14 14 14L2 14C0.89543 14 0 13.1046 0 12L0 2C0 0.89543 0.89543 0 2 0L14 0C15.1046 0 16 0.89543 16 2V5L18.71 2.29C18.8963 2.10526 19.1477 2.0011 19.41 2ZM3 5.38C3 5.65614 3.22386 5.88 3.5 5.88L7.5 5.88C7.77614 5.88 8 5.65614 8 5.38V4.62C8 4.34386 7.77614 4.12 7.5 4.12L3.5 4.12C3.22386 4.12 3 4.34386 3 4.62V5.38Z"
        fill="#696F8C"
      />
    </svg>
  );
};
