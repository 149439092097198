import { useState } from 'react';
import { register } from '../auth';

export const errorMessages = {
  networkError: 'Network Error: No internet connection',
  internalError: 'Internal error',
  invalidRegistrationEmail: 'Invalid email format',
  jsonParsingError: 'Failed to parse JSON',
  emailAlreadyRegistered: 'Email address is already in use',
};

export const useUserAppEntry = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [emailAddress, setEmailAddress] = useState('');

  const registerUser = async (redirectUrl, registrationSourceId) => {
    const { error, status } = await register({
      emailAddress,
      redirectUrl,
      registrationSourceId,
    });
    return { error, status };
  };

  return {
    emailAddress,
    setEmailAddress,
    isLoading,
    setIsLoading,
    error,
    setError,
    registerUser,
    errorMessages,
  };
};
