import React, { useEffect, useRef, useState } from 'react';
import { getStaticAssetUrl } from '../helper/getStaticAssetUrl';

const TIME_BEFORE_NUDGE = 10_000;
const PULSE_INTERVAL_MS = 800;

const transformStepClasses = [
  '-translate-y-1',
  'translate-x-1',
  'translate-y-1',
  '-translate-x-1',
];

const Nudge = () => {
  const [shouldNudge, setShouldNudge] = useState(false);
  const [transformStep, setTransformStep] = useState(0);

  const timeout = useRef(null);
  const interval = useRef(null);

  useEffect(() => {
    timeout.current = setTimeout(() => {
      setShouldNudge(true);
    }, TIME_BEFORE_NUDGE);

    return () => {
      clearTimeout(timeout.current);
    };
  }, []);

  useEffect(() => {
    interval.current = setInterval(() => {
      setTransformStep((prevStep) =>
        prevStep + 1 < transformStepClasses.length ? prevStep + 1 : 0
      );
    }, PULSE_INTERVAL_MS);

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, []);

  if (!shouldNudge) {
    return null;
  }

  return (
    <>
      <img
        alt="click to join the event"
        className="absolute animate-[grow_1000ms_0ms_infinite_both_alternate] left-1/2 lg:left-[60%] top-[40%]"
        src={getStaticAssetUrl('clicking_hand.png')}
      />
      <div
        className={`absolute inset-0 -z-10 ${transformStepClasses[transformStep]} rounded-md`}
        style={{
          background: 'linear-gradient(90deg, #FFE600 0.7%, #FF00E5 100%)',
        }}
      />
    </>
  );
};

export const Nudger = ({ children }) => {
  return (
    <div className="w-full relative flex isolate">
      <Nudge />
      {children}
    </div>
  );
};
