export const UpdateTabOnScroll = ({
  mainBoxRef,
  boxToShow,
  setBoxToShow,
  boxRefs,
}) => {
  const mainBoxSpace =
    mainBoxRef?.current?.getBoundingClientRect().height + 140;

  window.onscroll = () => {
    const remainingBoxOffsetsAndPositioning = boxRefs.map((ref) =>
      ref?.current?.getBoundingClientRect()
    );

    remainingBoxOffsetsAndPositioning.every((currentBox, index) => {
      if (currentBox && currentBox.y + currentBox.height > mainBoxSpace) {
        if (boxToShow !== index) {
          setBoxToShow(index);
        }
        return false;
      }
      return true;
    });
  };

  return null;
};

export const updateTabOnClick = (
  boxToShow,
  setBoxToShow,
  tabIndex,
  ref,
  mainBoxRef
) => {
  const currentBoxDisplayData = ref?.current?.getBoundingClientRect();
  const mainBoxDisplayData = mainBoxRef?.current?.getBoundingClientRect();

  const elementOffset =
    currentBoxDisplayData?.top +
    window.scrollY -
    mainBoxDisplayData?.height -
    110;

  if (tabIndex !== boxToShow) {
    if (!tabIndex) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      window.scrollTo({
        top: elementOffset,
        behavior: 'smooth',
      });
    }
  }
  setBoxToShow(tabIndex);

  return null;
};
