import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { PLANS } from 'zync-common/zyncCustomerPlans';
import { SubscriptionActionsContext } from '../../../context/SubscriptionActionsContextProvider';
import useConfirmationModal from '../UpgradeButton/useConfirmationModal';

import Card from './Card';

const CardColorVariants = {
  PURPLE: 'PURPLE',
  ORANGE: 'ORANGE',
};

/** A component for displaying available plans for upgrade as colorful cards */
const Cards = ({ plan, isSubscriptionActive }) => {
  const history = useHistory();

  const { changePlan, targetCustomerPlanName } = useContext(
    SubscriptionActionsContext
  );

  const handleNavigateToWorkspaceMembersPage = useCallback(() => {
    history.push('/workspace/members');
  }, [history]);

  const { Modal, handleOpen } = useConfirmationModal({
    modalTitle: (
      <h4 className="font-semibold text-xl -ml-2 leading-0">
        We had a problem with the subscription and you cannot upgrade it now
      </h4>
    ),
    modalText: (
      <p className="-mt-2 mb-3">
        Would you like to go to the Workspace Settings in order to verify your
        payment methods?
      </p>
    ),
    onConfirm: handleNavigateToWorkspaceMembersPage,
  });

  const upgradePlanHandler = isSubscriptionActive ? changePlan : handleOpen;

  const higherPlans = Object.values(PLANS).filter(
    (PLAN) => PLAN.order > plan.order
  );

  return (
    <div className="flex space-x-4">
      {higherPlans.map((higherPlan, i) => (
        <Card
          key={higherPlan.id}
          plan={higherPlan}
          color={i === 0 ? CardColorVariants.PURPLE : CardColorVariants.ORANGE}
          upgradePlan={upgradePlanHandler}
          targetCustomerPlanName={targetCustomerPlanName}
        />
      ))}
      {Modal}
    </div>
  );
};

export default Cards;
