import React, { useEffect, useRef, useState } from 'react';
import classNames from '../../helper/classNames';
import { AngleIcon } from '../icons/AngleIcon';
import { useClickOutside } from '../../helper/useClickOutside';
import { LoadingSpinner } from '../LoadingSpinner';

const FONT_SIZE_VARIANTS = {
  RESPONSIVE: 'RESPONSIVE',
  SMALL: 'SMALL',
};

const FONT_SIZE_CLASSNAMES = {
  RESPONSIVE: '2xl:text-base text-sm',
  SMALL: 'text-sm',
};

const BACKGROUND_COLOR_VARIANTS = {
  PURPLE_10: 'PURPLE_10',
  WHITE: 'WHITE',
};

const BACKGROUND_COLOR_CLASSNAMES = {
  PURPLE_10: 'bg-purple/10',
  WHITE: 'bg-white',
};

const FONT_COLOR_VARIANTS = {
  BLUE_GRAY: 'BLUE_GRAY',
  BLUE_DARK: 'BLUE_DARK',
  PURPLE: 'PURPLE',
};

const FONT_COLOR_CLASSNAMES = {
  BLUE_GRAY: 'text-blue-gray',
  BLUE_DARK: 'text-blue-dark',
  PURPLE: 'text-purple',
};

const HOVER_OPTION_FONT_COLOR_CLASSNAMES = {
  PURPLE: 'hover:text-purple',
};

const BORDER_SIZE_VARIANTS = {
  NONE: 'NONE',
  ONE: 'ONE',
};

const BORDER_SIZE_CLASSNAMES = {
  NONE: 'border-0',
  ONE: 'border',
};

const BORDER_COLOR_VARIANTS = {
  WHITE: 'WHITE',
  BLUE_GRAY: 'BLUE_GRAY',
  GRAY: 'GRAY',
};

const BORDER_COLOR_CLASSNAMES = {
  WHITE: 'border-white',
  BLUE_GRAY: 'border-blue-gray',
  GRAY: 'border-gray',
};

const OPTION_WIDTH_VARIANTS = {
  FULL: 'FULL',
  SMALL: 'SMALL',
};

const OPTION_WIDTH_CLASSNAMES = {
  FULL: 'w-full',
  SMALL: 'w-80',
};

export const DropdownSelect = ({
  selectedKey,
  onSelect,
  options,
  emptyOptionsMessage,
  error,
  fontSize = DropdownSelect.fontSize.RESPONSIVE,
  backgroundColor = DropdownSelect.backgroundColor.PURPLE_10,
  fontColor = DropdownSelect.fontColor.PURPLE,
  hoverOptionFontColor = DropdownSelect.fontColor.PURPLE,
  borderSize = DropdownSelect.borderSize.NONE,
  borderColor = DropdownSelect.borderColor.WHITE,
  angleColor = '#8B33F7',
  optionWidth = DropdownSelect.optionWidth.FULL,
  ellipsis = true,
  autoScrollToSelection = true,
  disabled = false,
  className,
  isAsync = false,
  menuWidth,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const scrollElementRef = useRef(null);
  const [showOptions, setShowOptions] = useState(false);
  const selected = options.find((o) => o.key === selectedKey) || options[0];

  const optionsRef = useRef(null);

  useClickOutside(optionsRef, () => setShowOptions((wasShown) => !wasShown));

  useEffect(() => {
    if (!autoScrollToSelection) return;
    let timeout;

    timeout = setTimeout(() => {
      if (showOptions && selectedKey && scrollElementRef.current) {
        const scrollTop = scrollElementRef.current.offsetTop;
        optionsRef.current.scrollTo({ behavior: 'smooth', top: scrollTop });
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [showOptions, selectedKey, autoScrollToSelection]);

  return (
    <div
      className={classNames(
        'w-full p-2 rounded relative flex items-center cursor-pointer',
        error
          ? 'border border-red border-solid bg-white'
          : `${BACKGROUND_COLOR_CLASSNAMES[backgroundColor]} ${BORDER_SIZE_CLASSNAMES[borderSize]} ${BORDER_COLOR_CLASSNAMES[borderColor]}`,
        FONT_SIZE_CLASSNAMES[fontSize],
        FONT_COLOR_CLASSNAMES[fontColor],
        disabled && 'bg-gray/30 opacity-50 pointer-events-none',
        className
      )}
      onClick={() => setShowOptions(!showOptions)}
    >
      {isLoading ? (
        <LoadingSpinner width="20px" thickness="2px" />
      ) : (
        <div className="overflow-hidden whitespace-nowrap text-ellipsis w-11/12">
          {(selected || {}).title ?? emptyOptionsMessage}
        </div>
      )}
      {showOptions && (
        <div
          ref={optionsRef}
          className={classNames(
            'absolute top-11 right-0 bg-white z-[100] shadow-md rounded overflow-hidden -ml-4 max-h-72 overflow-y-auto purple-scrollbar',
            OPTION_WIDTH_CLASSNAMES[optionWidth]
          )}
          style={{
            width: menuWidth,
          }}
        >
          {options.map((option, index) => (
            <div
              className={classNames(
                'p-2 cursor-pointer hover:bg-purple/10',
                FONT_COLOR_CLASSNAMES[fontColor],
                HOVER_OPTION_FONT_COLOR_CLASSNAMES[hoverOptionFontColor],
                ellipsis && 'overflow-hidden whitespace-nowrap text-ellipsis',
                selected?.key === option.key && 'bg-purple/5 text-purple'
              )}
              key={index}
              onClick={async () => {
                if (isAsync) {
                  setIsLoading(true);

                  try {
                    await onSelect(option.key);
                  } catch (error) {
                  } finally {
                    setIsLoading(false);
                  }
                } else {
                  onSelect(option.key);
                }
              }}
              ref={selected?.key === option.key ? scrollElementRef : undefined}
            >
              {option.title}
            </div>
          ))}
        </div>
      )}
      <div
        className={classNames(
          'absolute right-6 flex w-[12px] h-[12px]',
          showOptions ? 'rotate-90' : 'rotate-[270deg]',
          isLoading ? 'invisible' : ''
        )}
      >
        <AngleIcon width="12px" className={angleColor} />
      </div>
    </div>
  );
};

DropdownSelect.fontSize = FONT_SIZE_VARIANTS;
DropdownSelect.backgroundColor = BACKGROUND_COLOR_VARIANTS;
DropdownSelect.fontColor = FONT_COLOR_VARIANTS;
DropdownSelect.borderSize = BORDER_SIZE_VARIANTS;
DropdownSelect.borderColor = BORDER_COLOR_VARIANTS;
DropdownSelect.optionWidth = OPTION_WIDTH_VARIANTS;
