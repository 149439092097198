import React, { useCallback, useState } from 'react';
import { isMobile } from '../helper';
import { updateUserApi } from '../helper/api';
import { refreshUser } from '../auth';
import { useDispatch } from 'react-redux';
import { getStaticAssetUrl } from '../helper/getStaticAssetUrl';
import { CheckBox } from './common/CheckBox';
import { Button } from './common/Button';
import classNames from '../helper/classNames';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';

const fakeUser1 = getStaticAssetUrl('fake-user1.PNG');
const fakeUser2 = getStaticAssetUrl('fake-user2.PNG');
const fakeUser3 = getStaticAssetUrl('fake-user3.PNG');
const fakeUser4 = getStaticAssetUrl('fake-user4.PNG');
const fakeUser5 = getStaticAssetUrl('fake-user5.PNG');

const images = [fakeUser1, fakeUser2, fakeUser3, fakeUser4, fakeUser5];

export const PreviewInstructionsModal = ({
  title,
  description = 'This lets you see how your meeting would look with other participants. These fake users will be joining you in this meeting to help you out.',
  meetingPreviewButtonText,
  imageSrc,
  imageAltText = '',
  buttonText = 'Got it',
  handleClickPreview,
  user,
  allowDisablingFutureModalDisplay = true,
  onCancel,
  showCopyLink = false,
  showBotsCheckbox,
  copyLink,
}) => {
  return (
    <div className="fixed inset-0 z-[1001] h-full flex justify-center items-center bg-black bg-opacity-40">
      <div
        className={classNames(
          'rounded-lg bg-white p-4 absolute shadow-lg max-w-6xl',
          imageSrc ? 'w-5/6' : 'w-128'
        )}
      >
        <div className="flex flex-col h-full gap-8">
          {onCancel && (
            <div
              onClick={() => {
                onCancel();
              }}
              className="absolute top-3 right-5 text-blue-gray font-bold cursor-pointer"
            >
              &#10005;
            </div>
          )}

          <div
            className={classNames(
              'title text-center font-bold',
              imageSrc ? 'text-2xl' : 'text-xl'
            )}
          >
            {title ? (
              <span>{title}</span>
            ) : (
              <span>
                You are about to enter your meeting in
                <br />"{meetingPreviewButtonText}" mode
              </span>
            )}
          </div>
          <div className="flex gap-5 flex-col md:flex-row">
            {imageSrc && (
              <PreviewInstructionsImage
                imageSrc={imageSrc}
                imageAltText={imageAltText}
              />
            )}
            {isMobile ? (
              <div className="text-orange-light bg-orange-light bg-opacity-5 p-5 rounded-xl">
                Please open this page on a desktop. You will have special
                moderator privileges in this meeting that are not supported on
                mobile devices.
              </div>
            ) : (
              <PreviewInstructionsInformation
                isImagePresent={!!imageSrc}
                description={description}
                user={user}
                handleClickPreview={handleClickPreview}
                buttonText={buttonText}
                allowDisablingFutureModalDisplay={
                  allowDisablingFutureModalDisplay
                }
                showCopyLink={showCopyLink}
                showBotsCheckbox={showBotsCheckbox}
                copyLink={copyLink}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const PreviewInstructionsInformation = ({
  isImagePresent,
  description,
  user,
  handleClickPreview,
  buttonText,
  allowDisablingFutureModalDisplay,
  showCopyLink,
  showBotsCheckbox,
  copyLink,
}) => {
  const [showPreviewInstructions, setShowPreviewInstructions] = useState(true);
  const [enableBots, setEnableBots] = useState(true);
  const dispatch = useDispatch();

  const handleStartPreview = useCallback(
    async (enableBots) => {
      if (!showPreviewInstructions) {
        await updateUserApi(user?.userId, {
          userPreferences: {
            showPreviewInstructions,
          },
        });
        await refreshUser(dispatch, user.userId);
      }
      handleClickPreview(enableBots);
    },
    [handleClickPreview, showPreviewInstructions, user, dispatch]
  );
  return (
    <div
      className={classNames(
        'flex flex-col justify-between',
        isImagePresent ? 'w-2/5 gap-5' : 'w-full gap-8'
      )}
    >
      <span
        className={classNames(
          'block text-blue-gray line max-h-fit -mt-1 text-left'
        )}
      >
        {description}
      </span>
      <div
        className={classNames(
          'gap-4 grid grid-cols-3',
          isImagePresent ? ' max-w-sm' : 'justify-items-center'
        )}
      >
        {images.map((image, index) => (
          <img
            src={image}
            alt="fake user"
            key={`fake user ${index}`}
            className="w-24 h-24 object-cover rounded-full"
          />
        ))}
      </div>

      {allowDisablingFutureModalDisplay && (
        <CheckBox
          label="Please do not show me this message again"
          id="doNotShowMessage"
          value={showPreviewInstructions}
          onChange={() => setShowPreviewInstructions((prevState) => !prevState)}
        />
      )}
      <div className="space-y-2">
        {showBotsCheckbox && (
          <CheckBox
            label="Enable Bots"
            id="enableBots"
            value={enableBots}
            onChange={() => setEnableBots((prevState) => !prevState)}
          />
        )}
      </div>
      <div className="flex justify-between space-x-5">
        <div className={classNames(showCopyLink ? 'grow-[20]' : 'grow-[1]')}>
          <Button
            color={Button.colors.PURPLE}
            size={Button.sizes.FULL}
            onClick={() => handleStartPreview(enableBots)}
          >
            <span className="text-sm">{buttonText}</span>
          </Button>
        </div>
        {showCopyLink && (
          <div className="grow-[1]">
            <ToastContainer style={{ minWidth: 'max-content' }} />
            <CopyToClipboard
              text={copyLink}
              onCopy={() =>
                toast('Successfully copied the meeting link! 🎉', {
                  autoClose: 2000,
                  pauseOnHover: false,
                })
              }
            >
              <Button
                color={Button.colors.PURPLE_LITE}
                size={Button.sizes.FULL}
              >
                <span className="text-sm">Share Link</span>
              </Button>
            </CopyToClipboard>
          </div>
        )}
      </div>
    </div>
  );
};

const PreviewInstructionsImage = ({ imageSrc, imageAltText }) => {
  return (
    <div className="sm:w-3/5 flex items-center shrink-0">
      <div className="bg-purple bg-opacity-10 rounded-lg p-5">
        <img
          className="rounded-lg object-contain"
          src={imageSrc}
          alt={imageAltText}
        />
      </div>
    </div>
  );
};
