import { useState, useEffect, useCallback } from 'react';
import { Clickable, Textarea } from './Settings.styled';
import { updateSeriesApi } from '../../helper/api';
import { fromError, logerror } from '../../helper/contextualLogger';

export const RawConfig = ({ series, localDispatch, user }) => {
  const { meetingSeriesId, autoLaunchConfig, workspace } = series;
  const [updatedConfig, setUpdatedConfig] = useState({});
  useEffect(() => {
    setUpdatedConfig(JSON.stringify(autoLaunchConfig || {}, null, 2));
  }, [autoLaunchConfig]);

  const [showJsonConfig, setShowJsonConfig] = useState(false);
  const [errorString, setErrorString] = useState('');

  const updateAutoLaunchSettings = useCallback(async () => {
    try {
      const config = JSON.parse(updatedConfig);
      const result = await updateSeriesApi(meetingSeriesId, {
        autoLaunchConfig: config,
        workspace,
      });
      localDispatch({ type: 'REFRESH_SERIES', series: result });
      setErrorString('');
    } catch (err) {
      setErrorString('Invalid JSON');
      logerror(fromError(err, errorString));
      return;
    }
  }, [localDispatch, meetingSeriesId, updatedConfig, errorString, workspace]);

  if (!user?.privileges?.allowForceTerminateMeeting) {
    return null;
  }

  return (
    <div className="relative">
      <Clickable
        onClick={() => {
          setShowJsonConfig(!showJsonConfig);
        }}
      >
        {showJsonConfig ? 'HIDE' : 'SHOW'} CONFIG
      </Clickable>
      <div style={{ display: showJsonConfig ? 'block' : 'none' }}>
        <div className="text-red">{errorString}</div>
        <Textarea
          rows="25"
          cols="150"
          value={updatedConfig}
          onKeyDown={(e) =>
            (e.key === 'Enter' || e.key === 13) && updateAutoLaunchSettings()
          }
          onBlur={() => updateAutoLaunchSettings()}
          onChange={(e) => setUpdatedConfig(e.target.value)}
        />
      </div>
    </div>
  );
};
