import { shallowEqual, useSelector } from 'react-redux';
import React, { useState } from 'react';
import { generateTextApi } from '../../../../helper/api';
import { getEventTopicPrompt } from 'zync-common/helper/promptBuilders';
import { useClickOutside } from '../../../../helper/useClickOutside';
import { TextArea } from '../../../../components/common/TextArea';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { MagicHatIcon } from '../../../../components/icons/MagicHatIcon';

const MAX_DISCUSSION_POINT_CHARACTERS = 200;

export const DiscussionPointUpdate = ({
  posterSlideTemplate,
  setPosterSlideTemplate,
  setUpdate,
  onConfirm,
  updateOnClose,
  onCancel,
  series,
  hostNotesComponent,
}) => {
  const { user } = useSelector((_st) => _st.auth, shallowEqual);
  const { userId } = user || {};
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const questionBlock = posterSlideTemplate.blocks.find(
    (block) => block.blockId === 'singleQuestion'
  );

  const handleSuggestQuestion = async () => {
    setIsLoading(true);
    const { error, text } = await generateTextApi(
      getEventTopicPrompt(series),
      userId
    );
    setIsLoading(false);
    if (error) {
      setError('Error generating discussion point, please try again.');
      return;
    }

    const slicedText = text?.slice(0, MAX_DISCUSSION_POINT_CHARACTERS) || '';

    setPosterSlideTemplate((template) => {
      template.slideName = `${slicedText?.replaceAll('"', '')}`;
      questionBlock.settings.questionText = slicedText?.replaceAll('"', '');
      return template;
    });

    onConfirm(posterSlideTemplate, `${slicedText?.replaceAll('"', '')}`);

    setUpdate((x) => x + 1);
  };

  const handleConfirm = (shouldOpenNewlyCreated) =>
    onConfirm(
      posterSlideTemplate,
      `${questionBlock.settings.questionText}`,
      shouldOpenNewlyCreated
    );

  useClickOutside({ current: document.querySelector('.modal') }, () => {
    updateOnClose && handleConfirm(false);
    onCancel();
  });

  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <h4 className="text-sm font-medium text-blue-dark mb-4">
          What points would you like to discuss?
        </h4>
        <TextArea
          value={questionBlock?.settings?.questionText}
          onChange={(value) => {
            setPosterSlideTemplate((template) => {
              template.slideName = `${value}`;
              questionBlock.settings.questionText = value;
              return template;
            });

            setUpdate((x) => x + 1);
          }}
          className="w-full mb-4 p-2"
          rows={4}
          placeholder="Enter the discussion point"
          onBlur={handleConfirm}
          maxLength={MAX_DISCUSSION_POINT_CHARACTERS}
        />
        <div>
          <button
            className="flex gap-1 items-center font-medium text-xs text-blue-light underline decoration-blue-light"
            onClick={handleSuggestQuestion}
            disabled={isLoading}
          >
            {isLoading ? <LoadingSpinner width="16px" /> : <MagicHatIcon />}
            Suggest Discussion Point
          </button>
          {error && (
            <p className="text-red font-normal text-xs p-0 mt-0 mb-8">
              {error}
            </p>
          )}
        </div>
      </div>
      <div>{hostNotesComponent}</div>
    </div>
  );
};
