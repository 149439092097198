import { shallowEqual, useSelector } from 'react-redux';
import { useTrackActivity } from './track';
import {
  getLinkedinTokenStatusApi,
  publishWorkspaceApi,
  refreshLinkedinTokenApi,
  requestLinkedinAccessTokenApi,
  updateWorkspaceLinkedinIntegrationApi,
} from '../helper/api';
import { notifyUser } from '../components/authoring/hooks';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { useEffect, useState } from 'react';
import { Modal, ModalTitle, ModalWindow } from '../components/Modal';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { loginfo } from '../helper/contextualLogger';

const linkedinCreds = process.env.REACT_APP_LINKEDIN_OAUTH_CREDENTIAL
  ? JSON.parse(process.env.REACT_APP_LINKEDIN_OAUTH_CREDENTIAL)
  : null;

export const LinkedInReauthModal = ({ setShowReauthModal, workspaceId }) => {
  useEffect(() => {
    loginfo({
      message: `LinkedIn Reauth Modal shown for workspace: ${workspaceId}`,
    });
  }, [workspaceId]);

  return (
    <ModalWindow
      showCancel
      onCancel={() => setShowReauthModal(false)}
      size={Modal.size.md}
      zyncLogo={true}
    >
      <ModalTitle>
        <div className="text-center">
          Please disconnect and reauthenticate your Linkedin Account in your{' '}
          <Link
            href={`/workspace/integrations?workspaceId=${workspaceId}`}
            className="text-xl font-semibold leading-8 text-blue-light decoration-blue-light underline"
          >
            workspace settings
          </Link>
          .
        </div>
      </ModalTitle>
    </ModalWindow>
  );
};

export const useLinkedInIntegration = ({
  workspace,
  onWorkspaceUpdated,
  refreshSeries,
  localDispatch,
}) => {
  const userId = useSelector((st) => st.auth?.user?.userId, shallowEqual);
  const { integrations, workspaceId } = workspace || {};
  const { linkedin } = integrations || {};
  const { auth } = linkedin || {};
  const { access_token, refresh_token } = auth || {};
  const { client_id, redirect_uri } = linkedinCreds;
  const [reauthNeeded, setReauthNeeded] = useState(false);

  const track = useTrackActivity();

  useEffect(() => {
    if (!workspaceId) return;
    if (!access_token) return;
    const checkAccessToken = async () => {
      const { active: isAccessTokenActive } = await getLinkedinTokenStatusApi(
        workspaceId,
        'access'
      );

      if (isAccessTokenActive) return;

      const { active: isRefreshTokenActive } = await getLinkedinTokenStatusApi(
        workspaceId,
        'refresh'
      );

      if (!isAccessTokenActive && isRefreshTokenActive) {
        loginfo({
          message: `[Linkedin] Access token is inactive and refresh token is active for workspace: ${workspaceId}`,
        });

        const refreshResponse = await refreshLinkedinTokenApi(workspaceId);

        if (refreshResponse?.error) {
          setReauthNeeded(true);
          return;
        }

        const { result: refreshResult } = refreshResponse || {};
        const { auth } = refreshResult || {};

        loginfo({
          message:
            '[Linkedin] Got back refresh token and sending request to update workspace',
        });

        const result = await updateWorkspaceLinkedinIntegrationApi(
          workspaceId,
          {
            auth,
          }
        );

        publishWorkspaceApi(workspaceId);

        onWorkspaceUpdated && onWorkspaceUpdated(result.workspace);

        if (refreshSeries) {
          await refreshSeries(undefined, (series) => {
            if (localDispatch) {
              localDispatch({
                type: 'REFRESH_SERIES',
                series,
              });
            }
          });
        }

        return;
      }

      setReauthNeeded(true);
    };
    checkAccessToken();
  }, [
    access_token,
    localDispatch,
    onWorkspaceUpdated,
    refreshSeries,
    refresh_token,
    workspaceId,
  ]);

  const { linkedInLogin } = useLinkedIn({
    clientId: client_id,
    scope: 'r_basicprofile w_member_social',
    redirectUri: redirect_uri, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: async (code) => {
      try {
        const {
          data: { auth, profile },
        } = await requestLinkedinAccessTokenApi(code);

        const result = await updateWorkspaceLinkedinIntegrationApi(
          workspace.workspaceId,
          {
            auth,
            profile,
            authenticatedUserId: userId,
          }
        );

        track('Settings: LinkedIn Integration Connected');

        publishWorkspaceApi(workspace.workspaceId);

        onWorkspaceUpdated && onWorkspaceUpdated(result.workspace);
      } catch (error) {
        notifyUser('We could not connect to Linkedin');
      } finally {
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return {
    linkedInLogin,
    reauthNeeded,
    setReauthNeeded,
  };
};
