import { fromRawContentState } from '../../../../components/richText/helpers';
import { useClickOutside } from '../../../../helper/useClickOutside';
import { TextArea } from '../../../../components/common/TextArea';
import React from 'react';

export const WordCloudUpdate = ({
  posterSlideTemplate,
  setPosterSlideTemplate,
  setUpdate,
  onConfirm,
  onCancel,
  updateOnClose,
  hostNotesComponent,
}) => {
  const wordCloudBlock = posterSlideTemplate.blocks.find(
    (block) => block.blockId === 'textbox'
  );

  const handleConfirm = (shouldOpenNewlyCreated) =>
    onConfirm(
      posterSlideTemplate,
      `Word Cloud - ${fromRawContentState(
        wordCloudBlock.settings.text
      ).getPlainText()}`,
      shouldOpenNewlyCreated
    );

  useClickOutside({ current: document.querySelector('.modal') }, () => {
    updateOnClose && handleConfirm(false);
    onCancel();
  });

  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <h4 className="text-sm font-medium text-blue-dark mb-4">
          Wordcloud prompt
        </h4>
        <TextArea
          value={fromRawContentState(
            wordCloudBlock.settings.text
          ).getPlainText()}
          onChange={(value) => {
            setPosterSlideTemplate((template) => {
              const richTextContentState = JSON.parse(
                wordCloudBlock.settings.text
              );
              template.slideName = `Word Cloud - ${value}`;
              richTextContentState.blocks[0].text = value;
              wordCloudBlock.settings.text =
                JSON.stringify(richTextContentState);
              return template;
            });

            setUpdate((x) => x + 1);
          }}
          className="w-full mb-4 p-2 border border-gray rounded"
          rows={4}
          placeholder="What are the top 3 concerns for you right now?"
          onBlur={handleConfirm}
          maxLength={155}
        />
      </div>
      <div>{hostNotesComponent}</div>
    </div>
  );
};
