import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { sendEvent } from '../helper/api';
import { GLOBAL_UNMUTED_LIMIT } from '../helper/audio';
import {
  getUserWithLeastActivity,
  USER_ACTIVITY,
} from '../reducers/userActivity';
import { useDailyControls } from './useDailyControls';

// TODO: Move this to server settings.
const GLOBAL_MAX_UNMUTED_USERS = 1000;

export const useMeetingControls = () => {
  const dispatch = useDispatch();
  const { localMicOn, toggleMic, isLocalMicUpdating } = useDailyControls();
  const {
    tracks: { allUsers: userIdToUserTrackStates },
  } = useSelector((st) => st.callState);
  const { lastUserActivity } = useSelector((st) => st.userActivity);
  const { user: registeredUser } = useSelector((_st) => _st.auth);
  const { userId } = registeredUser || {};

  const meetingId = useSelector((state) => state.meetingState?.meetingId);
  const users = useSelector(
    (state) => Object.keys(state.meetingState.state?.users || {}),
    shallowEqual
  );

  const selfToggleMic = useCallback(() => {
    if (!toggleMic) return;

    if (!localMicOn) {
      // Mic turning on.

      // Find all other unmuted users.
      const unmutedUserIds = Object.entries(userIdToUserTrackStates)
        .filter(
          ([uId, callTrackStates]) => callTrackStates.micOn && userId !== uId
        )
        .map(([uId]) => uId);

      if (unmutedUserIds.length >= GLOBAL_MAX_UNMUTED_USERS) {
        // Too many users are unmuted. Mute someone.
        const userIdToMute = getUserWithLeastActivity(
          unmutedUserIds,
          lastUserActivity
        );
        if (userIdToMute) {
          sendEvent(userIdToMute, meetingId, {
            type: 'MUTE_USER',
            targetUserId: userIdToMute,
            reason: GLOBAL_UNMUTED_LIMIT,
          });
        }
      }
    }

    toggleMic();
  }, [
    toggleMic,
    userIdToUserTrackStates,
    lastUserActivity,
    meetingId,
    localMicOn,
    userId,
  ]);

  const handleActiveSpeakerChange = useCallback(
    (activeSpeakerInfo) => {
      dispatch({
        type: USER_ACTIVITY,
        userId: activeSpeakerInfo.userId,
        users,
      });

      // Only send active speaker events to the server if the current user is the
      // one speaking.
      if (!activeSpeakerInfo.local) return;

      // Don't send this event to the server as it currently triggers a pointless
      // state save / broadcast cycle.
      // sendEvent(userId, meetingId, {
      //   type: 'ACTIVE_SPEAKER_CHANGE',
      // });
    },
    [dispatch, users]
  );

  return { selfToggleMic, handleActiveSpeakerChange, isLocalMicUpdating };
};
