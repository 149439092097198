import React from 'react';

export const BackdropIcon = ({
  width = '19px',
  height = '19px',
  className = 'fill-purple',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.28 5.51L10.61 0.26C10.3625 0.090805 10.0698 0.000191595 9.77 0H9.23C8.93022 0.000191595 8.63747 0.090805 8.39 0.26L0.720002 5.51C0.58232 5.60091 0.499633 5.75501 0.500001 5.92V6.08C0.499633 6.24499 0.58232 6.39909 0.720002 6.49L8.39 11.74C8.63747 11.9092 8.93022 11.9998 9.23 12H9.77C10.0698 11.9998 10.3625 11.9092 10.61 11.74L18.28 6.49C18.4177 6.39909 18.5004 6.24499 18.5 6.08V5.92C18.5004 5.75501 18.4177 5.60091 18.28 5.51ZM9.61 10H9.39L3.5 6L9.35 2H9.57L15.5 6L9.61 10ZM16.17 10.06L18.28 11.51C18.4177 11.6009 18.5004 11.755 18.5 11.92V12.08C18.5004 12.245 18.4177 12.3991 18.28 12.49L10.61 17.74C10.3625 17.9092 10.0698 17.9998 9.77 18H9.23C8.93022 17.9998 8.63747 17.9092 8.39 17.74L0.720002 12.49C0.58232 12.3991 0.499633 12.245 0.500001 12.08V11.92C0.499633 11.755 0.58232 11.6009 0.720002 11.51L2.83 10.06L4.56 11.27L3.5 12L9.43 16H9.65L15.5 12L14.4 11.27L16.17 10.06Z"
      />
    </svg>
  );
};
