export const PersonInCircleIcon = ({
  width = 26,
  height = 26,
  color = '#DFE1E6',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2916 0.441406C6.32836 0.441406 0.707868 6.03765 0.683913 12.9414C0.659959 19.8351 6.33466 25.4664 13.2916 25.4801C20.2485 25.4939 25.9118 19.7864 25.8992 12.9414C25.8866 6.03765 20.2548 0.441406 13.2916 0.441406ZM13.2916 2.9414C18.8616 2.9414 23.3777 7.4189 23.3777 12.9414C23.3777 15.7539 22.1963 18.2889 20.3115 20.1064C19.3823 18.1151 17.3891 16.6914 15.1033 16.6914C14.6507 16.6914 11.9325 16.6914 11.4786 16.6914C9.19661 16.6914 7.20712 18.0976 6.27794 20.0901C4.39436 18.2726 3.20544 15.7539 3.20544 12.9414C3.20544 7.4189 7.7215 2.9414 13.2916 2.9414ZM13.2916 5.4414C10.5065 5.4414 8.2485 7.68015 8.2485 10.4414C8.2485 13.2026 10.5065 15.4414 13.2916 15.4414C16.0766 15.4414 18.3346 13.2026 18.3346 10.4414C18.3346 7.68015 16.0766 5.4414 13.2916 5.4414ZM13.2916 7.9414C14.6847 7.9414 15.8131 9.06015 15.8131 10.4414C15.8131 11.8226 14.6847 12.9414 13.2916 12.9414C11.8984 12.9414 10.77 11.8226 10.77 10.4414C10.77 9.06015 11.8984 7.9414 13.2916 7.9414ZM11.4786 19.1914C11.9325 19.1914 14.6507 19.1914 15.1033 19.1914C16.5103 19.1914 17.7837 20.2089 18.23 21.6426C16.4296 22.6964 14.4187 22.9414 13.2916 22.9414C12.1342 22.9414 10.214 22.6839 8.36828 21.6751C8.8108 20.2289 10.0842 19.1914 11.4786 19.1914Z"
        fill={color}
      />
    </svg>
  );
};
