export const categories = {
  COMPANY_EVENT: 'company event',
  TEAM: 'team',
  SOCIAL: 'social',
  MARKETING: 'marketing',
  SALES: 'sales',
  GENERAL_PURPOSE: 'general purpose',
  GETTING_STARTED: 'getting started',
  THEME: 'theme',
  EDUCATIONAL: 'educational',
  PROJECT_PRODUCT_PROGRAM: 'project (Product / Program)',
  COMMUNITY: 'community',
  EVENTS: 'events',
};

export const userPersonas = {
  EVENT_ORGANIZER: {
    name: 'Event-Organizer',
    templateCategories: [
      categories.EVENTS,
      categories.COMMUNITY,
      categories.SOCIAL,
      categories.MARKETING,
    ],
  },
  MARKETING: {
    name: 'Marketing',
    templateCategories: [
      categories.MARKETING,
      categories.SALES,
      categories.TEAM,
      categories.EVENTS,
    ],
  },
  COMMUNITY_MANAGER: {
    name: 'Community Manager',
    templateCategories: [
      categories.SOCIAL,
      categories.EVENTS,
      categories.COMPANY_EVENT,
      categories.EDUCATIONAL,
    ],
  },
  SALES_MANAGER: {
    name: 'Sales Manager',
    templateCategories: [
      categories.SALES,
      categories.MARKETING,
      categories.TEAM,
      categories.EDUCATIONAL,
    ],
  },
  ONLINE_EDUCATOR: {
    name: 'Online Educator',
    templateCategories: [
      categories.EDUCATIONAL,
      categories.EVENTS,
      categories.SOCIAL,
      categories.COMMUNITY,
      categories.TEAM,
    ],
  },
  PROGRAM_PRODUCT_MANAGER: {
    name: 'Program / Product Manager',
    templateCategories: [
      categories.PROJECT_PRODUCT_PROGRAM,
      categories.MARKETING,
      categories.TEAM,
      categories.COMPANY_EVENT,
      categories.EDUCATIONAL,
    ],
  },
  CHIEF_OF_STAFF: {
    name: 'Chief of Staff',
    templateCategories: [
      categories.COMPANY_EVENT,
      categories.TEAM,
      categories.SOCIAL,
      categories.EVENTS,
      categories.COMMUNITY,
    ],
  },
  HUMAN_RESOURCES: {
    name: 'Human Resources',
    templateCategories: [
      categories.SOCIAL,
      categories.COMMUNITY,
      categories.COMPANY_EVENT,
      categories.EDUCATIONAL,
    ],
  },
  LEGAL: {
    name: 'Legal',
    templateCategories: [
      categories.EDUCATIONAL,
      categories.COMPANY_EVENT,
      categories.PROJECT_PRODUCT_PROGRAM,
    ],
  },
};
