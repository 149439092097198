import React, { useState } from 'react';
import { InputExplanation, InputLabel, FieldContainer } from '../SettingsInput';
import { DropdownSelect } from '../common/Dropdown';

export const DropdownInput = ({
  label,
  explanation,
  defaultValue,
  options,
  onChange,
}) => {
  const [currentValue, setCurrentValue] = useState(defaultValue);
  return (
    <FieldContainer>
      {label && <InputLabel>{label}</InputLabel>}
      <DropdownSelect
        options={options.map((o) => {
          return {
            key: o.value,
            title: o.title,
          };
        })}
        emptyOptionsMessage="No options detected"
        selectedKey={currentValue}
        onSelect={(selectKey) => {
          setCurrentValue(selectKey);
          onChange && onChange(selectKey);
        }}
        borderColor={DropdownSelect.borderColor.GRAY}
        borderSize={DropdownSelect.borderSize.ONE}
        backgroundColor={DropdownSelect.backgroundColor.WHITE}
        fontSize={DropdownSelect.fontSize.SMALL}
        fontColor={DropdownSelect.fontColor.BLUE_GRAY}
        angleColor={'fill-blue-gray'}
      />
      {explanation && <InputExplanation> {explanation}</InputExplanation>}
    </FieldContainer>
  );
};
