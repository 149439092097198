import { useMemo } from 'react';
import { isHighlightTypeMontage } from './PublishMenus';
import { SocialMediaPost } from './SocialMediaPost';

export const CompositeHighlights = ({ highlights }) => {
  const compositeHighlights = useMemo(
    () =>
      highlights
        .filter((h) => isHighlightTypeMontage(h.type))
        .map((h) => ({
          highlightId: h.highlightId,
          title: h.title,
          thumbnailUrl: h.HIGHLIGHT_THUMBNAIL.imageUrl,
          playbackUrl: h.HIGHLIGHT_RAW_LANDSCAPE.playbackUrl,
          postTitle:
            h.HIGHLIGHT_SOCIAL_MEDIA_POSTS.socialMediaPosts.linkedIn.text,
          files: h.HIGHLIGHT_RAW_LANDSCAPE.files,
        })),
    [highlights]
  );

  return (
    <div className="flex flex-col gap-5 justify-center items-center w-full">
      {compositeHighlights.map((h, index) => (
        <SocialMediaPost
          key={h.highlightId}
          playbackUrl={h.playbackUrl}
          thumbnailUrl={h.thumbnailUrl}
          title={h.title}
          postTitle={h.postTitle}
          files={h.files}
          startCollapsed={index === 0 ? false : true}
        />
      ))}
    </div>
  );
};
