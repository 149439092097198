import { fromError, logerror, logwarn } from './contextualLogger';

const NETWORK_ERRORS_CODES = {
  'Failed to fetch': 106,
};

export const delay = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

/**
 * Exponential backoff on fetch requests.
 * 5 timed (2^n * 100)ms retries such that 0 <= n <= 5.
 */
export const exponentialFetchRetry = async ({
  fetchHandler,
  jsonResponseHandler,
  attributesToLog = {},
}) => {
  const retryDurations = [100, 200, 400, 800, 1600, 3200];
  let data;

  const validateAndProcessJsonResponse = async (response) => {
    const contentType = response.headers.get('Content-Type');

    if (contentType && contentType.indexOf('application/json') !== -1) {
      return await jsonResponseHandler(response);
    }
    const error = await response.text();
    logerror({
      message: error,
      ...fromError(error),
      ...attributesToLog,
    });
    return { status: 500, error: 'Internal server error' };
  };

  const processApiFetch = async () => {
    try {
      const response = await fetchHandler();
      return await validateAndProcessJsonResponse(response);
    } catch (error) {
      let logger = logerror;

      if (NETWORK_ERRORS_CODES[error.message] === 106) {
        logger = logwarn;
      }

      logger({
        message: error.message,
        stacktrace: error.stack,
        ...fromError(error),
        ...attributesToLog,
      });
      return {
        name: error.name,
        error: error.message,
        status: NETWORK_ERRORS_CODES[error.message] || 500,
      };
    }
  };

  /*
   * Retry if the status code is >= 500 or TimeoutError is returned from fetchCb.
   */
  for (let i = 0; i < retryDurations.length; i++) {
    data = await processApiFetch();
    const { status = 0 } = data || {};
    if (status < 500) {
      break;
    }
    await delay(retryDurations[i]);
  }
  return data;
};
