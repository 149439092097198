import React from 'react';
import { UserVideo, AuthoringUserVideo } from './UserVideo';

/**
 * Similar in functionality to UserVideo, but circular and with different layout and styles.
 */
export const CircleVideo = (props) => <UserVideo {...props} circle />;

/**
 * A standardized minimal circle video with label below the circle,
 * and a diameter of 160px.
 */
export const MinimalCircleVideo = (props) => (
  <CircleVideo {...props} minimal size={props.size || 160} />
);

export const AuthoringCircleVideo = (props) => (
  <AuthoringUserVideo {...props} circle />
);

export const AuthoringMinimalCircleVideo = (props) => (
  <AuthoringCircleVideo {...props} minimal size={props.size || 160} />
);
