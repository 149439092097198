import React from 'react';

export const PresentTextIcon = ({
  width = 18,
  height = 19,
  className = 'fill-[#8B33F7]',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_18425_77078)">
      <path d="M17.625 0.5H15.375C15.2755 0.5 15.1802 0.539509 15.1098 0.609835C15.0395 0.680161 15 0.775544 15 0.875V1.625H3V0.875C3 0.775544 2.96049 0.680161 2.89016 0.609835C2.81984 0.539509 2.72446 0.5 2.625 0.5H0.375C0.275544 0.5 0.180161 0.539509 0.109835 0.609835C0.0395088 0.680161 0 0.775544 0 0.875L0 3.125C0 3.22446 0.0395088 3.31984 0.109835 3.39016C0.180161 3.46049 0.275544 3.5 0.375 3.5H1.125V15.5H0.375C0.275544 15.5 0.180161 15.5395 0.109835 15.6098C0.0395088 15.6802 0 15.7755 0 15.875L0 18.125C0 18.2245 0.0395088 18.3198 0.109835 18.3902C0.180161 18.4605 0.275544 18.5 0.375 18.5H2.625C2.72446 18.5 2.81984 18.4605 2.89016 18.3902C2.96049 18.3198 3 18.2245 3 18.125V17.375H15V18.125C15 18.2245 15.0395 18.3198 15.1098 18.3902C15.1802 18.4605 15.2755 18.5 15.375 18.5H17.625C17.7245 18.5 17.8198 18.4605 17.8902 18.3902C17.9605 18.3198 18 18.2245 18 18.125V15.875C18 15.7755 17.9605 15.6802 17.8902 15.6098C17.8198 15.5395 17.7245 15.5 17.625 15.5H16.875V3.5H17.625C17.7245 3.5 17.8198 3.46049 17.8902 3.39016C17.9605 3.31984 18 3.22446 18 3.125V0.875C18 0.775544 17.9605 0.680161 17.8902 0.609835C17.8198 0.539509 17.7245 0.5 17.625 0.5ZM0.75 2.75V1.25H2.25V2.75H0.75ZM2.25 17.75H0.75V16.25H2.25V17.75ZM15 15.875V16.625H3V15.875C3 15.7755 2.96049 15.6802 2.89016 15.6098C2.81984 15.5395 2.72446 15.5 2.625 15.5H1.875V3.5H2.625C2.72446 3.5 2.81984 3.46049 2.89016 3.39016C2.96049 3.31984 3 3.22446 3 3.125V2.375H15V3.125C15 3.22446 15.0395 3.31984 15.1098 3.39016C15.1802 3.46049 15.2755 3.5 15.375 3.5H16.125V15.5H15.375C15.2755 15.5 15.1802 15.5395 15.1098 15.6098C15.0395 15.6802 15 15.7755 15 15.875ZM17.25 16.25V17.75H15.75V16.25H17.25ZM17.25 2.75H15.75V1.25H17.25V2.75Z" />
      <path d="M12 5.375H6C5.90054 5.375 5.80516 5.41451 5.73484 5.48484C5.66451 5.55516 5.625 5.65054 5.625 5.75V6.4025C5.625 6.50196 5.66451 6.59734 5.73484 6.66767C5.80516 6.73799 5.90054 6.7775 6 6.7775C6.09946 6.7775 6.19484 6.73799 6.26517 6.66767C6.33549 6.59734 6.375 6.50196 6.375 6.4025V6.125H8.625V12.875H7.6665C7.56704 12.875 7.47166 12.9145 7.40133 12.9848C7.33101 13.0552 7.2915 13.1505 7.2915 13.25C7.2915 13.3495 7.33101 13.4448 7.40133 13.5152C7.47166 13.5855 7.56704 13.625 7.6665 13.625H10.3335C10.433 13.625 10.5283 13.5855 10.5987 13.5152C10.669 13.4448 10.7085 13.3495 10.7085 13.25C10.7085 13.1505 10.669 13.0552 10.5987 12.9848C10.5283 12.9145 10.433 12.875 10.3335 12.875H9.375V6.125H11.625V6.4025C11.625 6.50196 11.6645 6.59734 11.7348 6.66767C11.8052 6.73799 11.9005 6.7775 12 6.7775C12.0995 6.7775 12.1948 6.73799 12.2652 6.66767C12.3355 6.59734 12.375 6.50196 12.375 6.4025V5.75C12.375 5.65054 12.3355 5.55516 12.2652 5.48484C12.1948 5.41451 12.0995 5.375 12 5.375Z" />
    </g>
    <defs>
      <clipPath id="clip0_18425_77078">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
