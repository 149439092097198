import React from 'react';
import { Button } from '../../common/Button';
import {
  Modal,
  ModalWindow,
  ModalBody,
  ModalButtonsGroup,
  ModalButton,
} from '../../Modal';

export const EjectUsersPopup = ({
  handleSubmit,
  handleCancel,
  isMultipleUsers,
}) => {
  return (
    <ModalWindow zyncLogo={false} size={Modal.size.xs} showCancel={true}>
      <ModalBody>
        <p className="text-center">
          Are you sure you want to eject the selected{' '}
          {isMultipleUsers ? 'users' : 'user'}?
        </p>
      </ModalBody>
      <ModalButtonsGroup>
        <ModalButton
          handleOnClick={handleCancel}
          buttonText={'Cancel'}
          color={ModalButton.color.WHITE}
        />
        <ModalButton
          color={Button.colors.RED}
          handleOnClick={handleSubmit}
          buttonText={`Eject ${isMultipleUsers ? 'Users' : 'User'}`}
        />
      </ModalButtonsGroup>
    </ModalWindow>
  );
};
