import React from 'react';

export const AttachmentIcon = ({
  width = 13,
  height = 14,
  className = 'stroke-[#76808B]',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1661 6.68336L6.80529 12.0442C6.14855 12.7009 5.25782 13.0699 4.32904 13.0699C3.40027 13.0699 2.50953 12.7009 1.85279 12.0442C1.19605 11.3874 0.827095 10.4967 0.827095 9.56794C0.827095 8.63917 1.19605 7.74843 1.85279 7.09169L7.21363 1.73086C7.65145 1.29303 8.24528 1.04706 8.86446 1.04706C9.48364 1.04706 10.0775 1.29303 10.5153 1.73086C10.9531 2.16868 11.1991 2.76251 11.1991 3.38169C11.1991 4.00087 10.9531 4.59469 10.5153 5.03252L5.14863 10.3934C4.92971 10.6123 4.6328 10.7353 4.32321 10.7353C4.01362 10.7353 3.71671 10.6123 3.49779 10.3934C3.27888 10.1744 3.15589 9.87753 3.15589 9.56794C3.15589 9.25835 3.27888 8.96144 3.49779 8.74252L8.45029 3.79586"
        strokeWidth="1.16667"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      />
    </svg>
  );
};
