import { createContext, useCallback, useMemo } from 'react';

import noop from 'lodash/noop';
import { useDispatch, useSelector } from 'react-redux';
import { refreshUser } from '../../auth';
import { updateUserApi } from '../../helper/api';

export const UserSettingsContext = createContext({
  handleUpdateSettings: noop,
  isDeleteSceneHidden: false,
  isDeleteBlockHidden: false,
});

export const UserSettingsContextProvider = ({ children }) => {
  const dispatch = useDispatch();

  const userId = useSelector((_st) => _st.auth?.user?.userId);
  const isDeleteBlockHidden = useSelector(
    (_st) => _st.auth?.user?.userPreferences?.hideDeleteBlock
  );
  const isDeleteSceneHidden = useSelector(
    (_st) => _st.auth?.user?.userPreferences?.hideDeleteScene
  );

  const handleUpdateSettings = useCallback(
    async (newSettings) => {
      await updateUserApi(userId, {
        userPreferences: {
          ...newSettings,
        },
      });
      await refreshUser(dispatch, userId);
    },
    [dispatch, userId]
  );

  const value = useMemo(
    () => ({
      handleUpdateSettings,
      isDeleteBlockHidden,
      isDeleteSceneHidden,
    }),
    [handleUpdateSettings, isDeleteBlockHidden, isDeleteSceneHidden]
  );

  return (
    <UserSettingsContext.Provider value={value}>
      {children}
    </UserSettingsContext.Provider>
  );
};
