import { throttle } from 'lodash/fp';
import React, { useCallback } from 'react';
import {
  BooleanInput,
  ColorSelectInput,
  NumberInput,
  SelectInput,
  TextAreaInput,
  TextInput,
  TextArrayInput,
  ImageFileInput,
  defaultColorSelectOptions,
  SvgFileInput,
} from './Input';
import { isEmail } from '../helper/roles';
import { DropdownInput } from './Input/DropdownInput';

/* a helper for determining if event value is a new one */
export const hasChanged = (event, previousValue) => {
  return event?.currentTarget?.value !== previousValue;
};

/**
 * Styling for the explanation prop in SettingsInput.
 */
export const InputExplanation = ({ children }) => {
  return (
    <div className="font-light mt-1 ml-1 text-blue-gray text-left text-xs opacity-70">
      {children}
    </div>
  );
};

export const InputLabel = ({ children }) => {
  return (
    <div className="font-medium text-[#84818A] text-xs mb-1 leading-[18px]">
      {children}
    </div>
  );
};

export const FieldContainer = ({ children }) => {
  return (
    <div className="relative flex flex-col items-stretch mb-3 sm-landscape:w-full">
      {children}
    </div>
  );
};

/**
 * An input to be used in the settings panel. Supports several common HTML and custom input types.
 */
export const SettingsInput = ({
  /** Whether the input is disabled. */
  disabled,
  settings,
  updateSettings,
  /** The field name. */
  field,
  /** The label for the input. */
  label,
  /** The input type. One of text, select, textarea, url, color, boolean. Defaults to text. */
  type = 'text',
  /** The placeholder field for the input. Not applicable for select type. */
  placeholder,
  /** Rows for textarea. */
  rows = undefined,
  /** Additional style to be applied to the input element. */
  style = undefined,
  /**
   * Options for a type[select] component. An array of { value, title } objects, or array of strings
   * which will be used as both value and title.
   */
  selectOptions = undefined,
  /** A readable explanation for what the input does. */
  explanation = undefined,
  /** The unit of measure (for type "text"), e.g. "%" or "deg". */
  unit,
  /** Unique key to ensure that the value is changed if the root entity has changed. */
  uniqueKey,

  /** Boolean. If true, updateSettings will be called when text input is out of focus. To update intermediary data, component will use internal state */
  updateOnBlur,

  ...props
}) => {
  const currentValue = settings[field];
  const endsWithUnit =
    typeof currentValue === 'string' && unit && currentValue.endsWith(unit);

  const defaultValue = endsWithUnit
    ? parseFloat(currentValue.replace(unit, '')).toFixed(0) + unit
    : currentValue;

  const handleBlur = useCallback(
    (event) => {
      updateSettings && updateSettings(event.target.value, field);
    },
    [field, updateSettings]
  );

  // TODO: Remove this when we debug it.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(
    throttle(100, (value) => updateSettings && updateSettings(value, field)),
    [field, updateSettings]
  );

  const key = `${uniqueKey ?? ''}-${field}`;

  switch (type) {
    case 'dropdown': {
      return (
        <DropdownInput
          key={key}
          {...props}
          label={label}
          explanation={explanation}
          onChange={handleChange}
          defaultValue={defaultValue}
          options={selectOptions}
        />
      );
    }
    case 'boolean': {
      return (
        <BooleanInput
          key={key}
          {...props}
          label={label}
          style={style}
          explanation={explanation}
          onChange={handleChange}
          defaultValue={defaultValue}
        />
      );
    }
    case 'select': {
      return (
        <SelectInput
          key={key}
          {...props}
          custom={props.custom}
          options={selectOptions}
          defaultValue={defaultValue}
          onChange={handleChange}
          label={label}
          style={style}
          explanation={explanation}
        />
      );
    }

    case 'color':
    case 'color-select': {
      return (
        <ColorSelectInput
          key={key}
          {...props}
          enableCustomInput={Boolean(props.custom)}
          colors={props.colors ?? defaultColorSelectOptions}
          defaultValue={defaultValue || props.defaultValue || ''}
          onChange={handleChange}
          label={label}
          explanation={explanation}
        />
      );
    }

    case 'textarea': {
      return (
        <TextAreaInput
          key={key}
          {...props}
          label={label}
          placeholder={placeholder}
          value={defaultValue}
          onChange={
            updateOnBlur ? undefined : (event, value) => handleChange(value)
          }
          rows={rows}
          onBlur={handleBlur}
          style={style}
          explanation={explanation}
        />
      );
    }

    case 'number': {
      return (
        <NumberInput
          key={key}
          {...props}
          label={label}
          unit={unit}
          onBlur={(event) =>
            updateSettings &&
            updateSettings(
              unit
                ? `${event.target.value}${unit}`
                : parseFloat(event.target.value),
              field
            )
          }
          value={
            unit ? `${defaultValue}`.replace(/[^-0-9.]/g, '') : defaultValue
          }
          onChange={(event, value) =>
            updateSettings &&
            updateSettings(unit ? `${value}${unit}` : parseFloat(value), field)
          }
          style={style}
          explanation={explanation}
        />
      );
    }

    case 'user-array':
    case 'text-array': {
      return (
        <TextArrayInput
          key={key}
          {...props}
          defaultValue={defaultValue}
          explanation={explanation}
          label={label}
          onChange={(value) => handleChange(value, field)}
          onValidate={
            type === 'user-array'
              ? (value) => (isEmail(value) ? null : 'Must be a valid email.')
              : undefined
          }
          addLabel={type === 'user-array' ? 'Add User' : undefined}
        />
      );
    }

    case 'image': {
      return (
        <ImageFileInput
          key={key}
          {...props}
          explanation={explanation}
          label={label}
          value={defaultValue}
          onChange={(value) => handleChange(value, field)}
        />
      );
    }

    case 'svg': {
      return (
        <SvgFileInput
          key={key}
          {...props}
          explanation={explanation}
          label={label}
          value={defaultValue}
          onChange={(value) => handleChange(value, field)}
        />
      );
    }

    case 'url':
    default: {
      return (
        <TextInput
          key={key}
          {...props}
          unit={unit}
          label={label}
          placeholder={placeholder}
          onBlur={handleBlur}
          defaultValue={defaultValue}
          onChange={
            updateOnBlur ? undefined : (event, value) => handleChange(value)
          }
          style={style}
          explanation={explanation}
        />
      );
    }
  }
};
