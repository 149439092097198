import { useState, useEffect, useRef } from 'react';
import {
  Modal,
  ModalWindow,
  ModalTitle,
  ModalBody,
  ModalButtonsGroup,
  ModalButton,
} from '../components/Modal';
import { logerror } from '../helper/contextualLogger';
import { LogoSpinner } from './LogoSpinner';
import { useUserAppEntry } from '../hooks/useUserAppEntry';
import { updateUserApi } from '../helper/api';
import mixpanel from 'mixpanel-browser';

const mixpanelEmailLoggingEvent = {
  title: 'QuickStart Onboarding - User Signed Up With Email',
};

export const MeetingRegistrationCTA = ({
  currentSceneIndex,
  campaignId,
  templateKey,
  slides,
}) => {
  const {
    emailAddress,
    setEmailAddress,
    isLoading,
    setIsLoading,
    error,
    setError,
    registerUser,
    errorMessages,
  } = useUserAppEntry();

  const [showBaseModal, setShowBaseModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isRegistrationComplete, setIsRegistrationComplete] = useState(false);
  const baseModalSceneIndex = 3;
  const [successModalMessage, setSuccessModalMessage] = useState('');
  const isBaseModalScene = currentSceneIndex >= baseModalSceneIndex;

  const emailInputRef = useRef(null);
  const [cursorPos, setCursorPos] = useState(null);

  const handleRegistration = async (e, emailAddress) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    if (!emailAddress) {
      setIsLoading(false);
      setError(errorMessages.invalidRegistrationEmail);
      return;
    }

    try {
      const newSearchQuery = new URLSearchParams();

      if (templateKey) {
        newSearchQuery.append('templateKey', templateKey);
      }

      const { error, status } = await registerUser(
        `/portal?${newSearchQuery.toString()}`,
        campaignId
      );

      if (status === 200) {
        mixpanel.track(mixpanelEmailLoggingEvent.title, {
          userId: emailAddress,
          templateKey,
          isExistingEmail: false,
          currentSceneIndex,
          campaignId,
        });
        setShowBaseModal(false);
        setIsRegistrationComplete(true);
        setEmailAddress('');
        setError('');
        setShowSuccessModal(true);
        setSuccessModalMessage(
          'We have emailed you instructions to complete registration, please close this window and check your inbox.'
        );
        handleUpdateUserWithTemplate();
      } else if (status === 106) {
        setError(errorMessages.networkError);
        logerror({
          userId: emailAddress,
          message: errorMessages.networkError,
          error,
        });
      } else if (status === 400) {
        setError(errorMessages.invalidRegistrationEmail);
      } else if (status === 422) {
        mixpanel.track(mixpanelEmailLoggingEvent.title, {
          userId: emailAddress,
          templateKey,
          isExistingEmail: true,
          currentSceneIndex,
          campaignId,
        });
        setShowBaseModal(false);
        setIsRegistrationComplete(true);
        setEmailAddress('');
        setError('');
        setShowSuccessModal(true);
      } else {
        setError(errorMessages.internalError);
        logerror({
          userId: emailAddress,
          message: `Error attempting to register user. status: ${status} error: ${error}`,
        });
      }
    } catch (err) {
      logerror({
        userId: emailAddress,
        message: 'Caught exception attempting to register user: ' + err.message,
        stacktrace: err.stack || err,
      });
      setError(errorMessages.internalError);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateUserWithTemplate = async () => {
    try {
      const { error } = await updateUserApi(emailAddress, {
        userPreferences: {
          quickStartOnboardingTemplateKey: templateKey ?? 'zync_firstmeeting',
        },
      });
      if (error) {
        logerror({
          userId: emailAddress,
          message: 'Error attempting to update user: ' + error,
        });
      }
    } catch (err) {
      logerror({
        userId: emailAddress,
        message: 'Caught exception attempting to update user: ' + err.message,
        stacktrace: err.stack || err,
      });
    }
  };

  const handleCloseBaseModal = () => {
    mixpanel.track('QuickStart Onboarding - User Closed Email Prompt', {
      templateKey,
      currentSceneIndex,
      campaignId,
    });
    setShowBaseModal(false);
  };

  const handleCloseSuccessModal = () => {
    mixpanel.track('QuickStart Onboarding - User Closed Success Prompt', {
      templateKey,
      currentSceneIndex,
      campaignId,
    });
    setShowSuccessModal(false);
  };

  const handleChangeEmailAddress = (e) => {
    setEmailAddress(e.target.value);
    setCursorPos(e.target.selectionStart);
  };

  useEffect(() => {
    if (emailInputRef && emailInputRef?.current) {
      emailInputRef.current.selectionStart = cursorPos;
      emailInputRef.current.selectionEnd = cursorPos;
    }
  }, [cursorPos]);

  //Reset state when scene is changed.
  useEffect(() => {
    setError('');
    setIsLoading(false);
    setEmailAddress('');
  }, [currentSceneIndex, setEmailAddress, setIsLoading, setError]);

  // Determine if the scene should display the base modal.
  useEffect(() => {
    const showBaseModal = isBaseModalScene && !showSuccessModal;
    setShowBaseModal(showBaseModal);
  }, [currentSceneIndex, isBaseModalScene, showSuccessModal]);

  // Log when the base modal is shown to the user.
  useEffect(() => {
    if (showBaseModal && templateKey && slides) {
      mixpanel.track('QuickStart Email Prompt Loaded', {
        templateKey,
        currentSceneIndex,
        campaignId,
      });
    }
  }, [showBaseModal, campaignId, currentSceneIndex, templateKey, slides]);

  if (isRegistrationComplete && !showSuccessModal) {
    return null;
  }

  if (showBaseModal) {
    return (
      <ModalWindow
        size={Modal.size.md}
        showCancel={true}
        onCancel={handleCloseBaseModal}
      >
        <ModalTitle>
          <p className="text-center">
            <span>Enjoying Zync?</span>
          </p>
        </ModalTitle>
        <ModalBody>
          <div className="flex justify-center">
            <div className="flex flex-col items-center min-w-fit space-y-3">
              <p className="text-center w-full text-black text-opacity-60">
                <span>Enter your email to explore all Zync has to offer!</span>
              </p>
              <div className="w-full">
                <input
                  className="p-2 bg-white border-solid border rounded text-blue-gray font-normal text-sm w-full box-border border-gray focus:outline-none outline-purple focus:border-purple hover:border-purple"
                  ref={emailInputRef}
                  placeholder="Please enter your email address"
                  onChange={handleChangeEmailAddress}
                  value={emailAddress}
                  showErrorStyle={!!error}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <div className="px-40">
          {isLoading ? (
            <div className="flex justify-center mb-4">
              <LogoSpinner />
            </div>
          ) : (
            <ModalButtonsGroup>
              <ModalButton
                handleOnClick={(e) => {
                  handleRegistration(e, emailAddress);
                }}
                buttonText={'Submit'}
                color={ModalButton.color.PURPLE}
              />
            </ModalButtonsGroup>
          )}
        </div>
        {error && (
          <div className="pb-8">
            <div className="text-center">
              <span className="w-fit text-red bg-red bg-opacity-10 rounded-md px-3 py-1">
                {error}
              </span>
            </div>
          </div>
        )}
      </ModalWindow>
    );
  } else if (showSuccessModal) {
    return (
      <ModalWindow
        size={Modal.size.md}
        zyncLogo={true}
        showCancel={true}
        onCancel={handleCloseSuccessModal}
      >
        <ModalTitle>
          <p className="text-center">Thanks for Registering!</p>
        </ModalTitle>
        {successModalMessage && (
          <ModalBody>
            <div className="flex justify-center">
              <div className="flex flex-col items-center min-w-fit ">
                <p className="text-center w-full text-black text-opacity-40 text-sm">
                  {successModalMessage}
                </p>
              </div>
            </div>
          </ModalBody>
        )}
        <div className="px-40">
          <ModalButtonsGroup>
            <ModalButton
              handleOnClick={handleCloseSuccessModal}
              buttonText={'Okay'}
            />
          </ModalButtonsGroup>
        </div>
      </ModalWindow>
    );
  }
  return null;
};
