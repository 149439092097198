import React from 'react';
import { RoleTagRemoveButton } from './InviteTab.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from '../../../helper/classNames';

export const RoleTag = ({
  roleName,
  onTagRemoveClick,
  canEditRole,
  roleBackgroundColor,
  roleBackgroundOpacity,
}) => {
  return (
    <div
      className={classNames(
        'text-black flex text-center rounded-lg cursor-pointer text-sm px-2 py-1 max-w-full space-x-2 break-words mr-2 my-1 bg-opacity-20 items-center',
        roleBackgroundColor,
        roleBackgroundOpacity
      )}
    >
      <span>{roleName}</span>
      {canEditRole && (
        <RoleTagRemoveButton onClick={onTagRemoveClick} fontColor="#515151">
          <FontAwesomeIcon icon="times" />
        </RoleTagRemoveButton>
      )}
    </div>
  );
};
